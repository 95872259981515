import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';

@Component({
  selector: 'ib-dynamic-value-formatter',
  templateUrl: './dynamic-value-formatter.component.html',
  styleUrls: ['./dynamic-value-formatter.component.css']
})
export class DynamicValueFormatterComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() value: any = undefined;
  @Input() dateFormat:
    "short" | "medium" | "long" | "full" |
    "shortDate" | "mediumDate" | "longDate" | "fullDate" |
    "shortTime" | "mediumTime" | "longTime" | "fullTime" |
    string = "medium";

  public type: "unknown" | "number" | "date" | "boolean" | "string" | "object" = "unknown";

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.configure();
  }

  configure() {
    if (Helper.isDate(this.value)) {
      // Check isDate before isString because value might be an iso date string
      this.type = "date";
    } else if (Helper.isString(this.value)) {
      this.type = "string";
    } else if (typeof this.value === "number") {
      this.type = "number";
    } else if (typeof this.value === "boolean") {
      this.type = "boolean";
    } else if (Helper.isObject(this.value)) {
      this.type = "object";
    } else {
      this.type = "unknown";
    }
  }

}
