import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { ButtonItem, EventModel, EventElementModel, Action } from 'projects/common-lib/src/lib/ux-models';
import { NgModel } from '@angular/forms';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { SystemService } from 'projects/core-lib/src/lib/services/system.service';
import { ApiCall, ApiOperationType, ApiProperties, IApiResponseWrapperTyped, Query } from 'projects/core-lib/src/lib/api/ApiModels';
import { ApiModuleWeb } from 'projects/core-lib/src/lib/api/Api.Module.Web';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TaskService } from 'projects/core-lib/src/lib/services/task.service';
import { Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'ib-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent implements OnInit, OnDestroy {
  @ViewChild('dd') ngbDropdown: NgbDropdown;

  @Input() public button: ButtonItem;
  @Input() public buttonStyle: string;
  @Input() public data: any;
  @Input() buttonId: number = 0;
  /**
  Control this action button is attached to (if any).
  */
  @Input() public control: NgModel;
  /**
  Cargo to pass with event (if any);
  */
  @Input() public cargo: any;
  @Input() public allowSearch: boolean = false;
  @Output() public click: EventEmitter<EventModel> = new EventEmitter();

  // Stores the names of the user's favorites as they are returned from the api
  userFavorites: number[] = [];
  // Stores a custom object containing the favoriteId and resourceId
  favoriteIds: any[] = [];
  // Stores the original order that the button options are passed in so that
  // when we sort the favorites to the top, they can be returned to the
  // original place in the dropdown when unfavorited
  buttonOptionsCache: Action[] = [];

  searchText: string = "";

  public isButtonVisible: boolean = true;

  protected originalLabel: string = "";
  protected originalIcon: string = "";

  /** Lets us monitor clicks and have control over closing the menu based on the buttonId */
  protected clickSubscription: Subscription;

  get blockClass(): string {
    if (!this.button || !this.button.fullWidth) {
      return "";
    }
    return "btn-block";
  }

  get scrollHeightClass(): string {
    if (!this.button || !this.button.scrollHeight || this.button.scrollHeight === "none") {
      return "";
    }
    if (this.button.scrollHeight === "normal") {
      return "ib-dropdown-scroll";
    } else if (this.button.scrollHeight === "large") {
      return "ib-dropdown-scroll-large";
    } else if (this.button.scrollHeight === "auto") {
      // TODO make this more dynamic.  Can we get the position of the button compared to item count and
      // view height and determine which scroll class to apply (if any)?
      if (this.button.options && this.button.options.length > 10) {
        // console.error("max component height", Helper.getMaxComponentHeight());
        if (Helper.getMaxComponentHeight() > 550) {
          return "ib-dropdown-scroll-large";
        } else {
          return "ib-dropdown-scroll";
        }
      }
    }
    return "";
  }

  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected systemService: SystemService,
    protected taskService: TaskService) { }


  ngOnInit() {
    if (this.button) {
      this.originalLabel = this.button.label;
      this.originalIcon = this.button.icon;
      if (this.button.favoriteResourceType) {
        this.getFavoritesList();
      }
      if (this.button.allowSearch) {
        this.allowSearch = true;
      }
    } else {
      this.isButtonVisible = false;
    }
    this.update();

    /**
     * We need to listen for clicks because the consumer of this button needs better control over when it closes.
     * Most likely due to event propagation issues. By setting a buttonId, they signify to us they want some
     * control over closing the button.
     */
    if (this.buttonId) {
      this.clickSubscription = this.taskService.click$.subscribe((value) => {
        if (value !== this.buttonId) {
          this.ngbDropdown.close();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.button && this.button) {
      this.originalLabel = this.button.label;
      this.originalIcon = this.button.icon;
    }
    this.update();
    if (this.button) {
      this.isButtonVisible = this.isVisible(this.button);
    } else {
      this.isButtonVisible = false;
    }
  }

  ngOnDestroy() {
    if (this.buttonId) {
      this.clickSubscription.unsubscribe();
    }
  }


  // In order to display favorite options at the top of the dropdown list, the array of options
  // is sorted.  Items with 'pinned' set on the cargo object are sorted to the very top, followed
  // by options that are favorited, and lastly all other options in their original order. The
  // original order is saved on buttonOptionsCache so that items can be returned to original position
  // when they are un-favorited
  sortFavoritesToTop() {
    const sorted: Action[] = [];
    if (this.buttonOptionsCache.length === this.button.options.length) {
      this.button.options = this.buttonOptionsCache;
    } else {
      this.buttonOptionsCache = this.button.options;
    }
    try {
      this.button.options.forEach(item => {
        if (item?.cargo?.pinned) {
          sorted.push(item);
        }
      });
      this.button.options.forEach(item => {
        let actionId = item.actionId;
        if (actionId) {
          while (actionId.indexOf('-') !== -1) {
            actionId = actionId.slice(actionId.indexOf('-') + 1);
          }
          if (this.userFavorites.includes(Number(actionId))) {
            sorted.push(item);
          }
        }
      });
      this.button.options.forEach(item => {
        let actionId = item.actionId;
        if (actionId && !item?.cargo?.pinned) {
          while (actionId.indexOf('-') !== -1) {
            actionId = actionId.slice(actionId.indexOf('-') + 1);
          }
          if (!this.userFavorites.includes(Number(actionId))) {
            sorted.push(item);
          }
        }
      });
    } catch (err) {
      Log.errorMessage(err);
    }
    this.button.options = sorted;
  }

  getFavoritesList() {
    const apiProp: ApiProperties = ApiModuleWeb.Favorite();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);
    const contactId = this.appService.user.ContactId;
    const query = new Query();
    query.Filter = `ContactId == ${contactId} && FavoriteResourceType == "${this.button.favoriteResourceType}"`;
    this.apiService.execute(apiCall, query).subscribe((response: IApiResponseWrapperTyped<m5web.FavoriteEditViewModel[]>) => {
      if (response.Data.Success) {
        this.userFavorites = [];
        this.favoriteIds = [];
        response.Data.Data.forEach(element => {
          if (element.FavoriteResourceId) {
            this.favoriteIds.push({ favoriteId: element.FavoriteId, resourceId: element.FavoriteResourceId });
            this.userFavorites.push(element.FavoriteResourceId);
          }
        });
        if (this.button.favoritedOptionsListedFirst) {
          this.sortFavoritesToTop();
        }
      } else {
        this.appService.alertManager.addAlertFromApiResponse(response, apiCall);
        return;
      }
    });
  }

  update() {

    if (!this.button) {
      return;
    }

    if (this.button.getLabel) {
      try {
        this.button.label = this.button.getLabel(this.data, this.cargo);
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    if (this.button.getIcon) {
      try {
        this.button.icon = this.button.getIcon(this.data, this.cargo);
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    if (this.button.update) {
      try {
        this.button.update(this.button, this.data, this.cargo);
      } catch (err) {
        Log.errorMessage(err);
      }
    }

  }


  isVisible(action: Action): boolean {
    return this.systemService.actionIsVisible(action, this.data, this.cargo);
  }

  isActive(action: Action): boolean {
    return this.systemService.actionIsActive(action, this.data, this.cargo);
  }

  isFavorite(actionId: string): boolean {
    if (actionId) {
      const resourceId = this.convertActionIdToResourceId(actionId);
      return this.userFavorites.includes(resourceId);
    } else {
      return false;
    }
  }

  /*
    If the user clicks favorite, we either need to add it
    to the list if it doesn't already exist, otherwise we
    know we need to delete it from the favorites list.
  */
  favoriteClicked($event) {
    const resourceId = this.convertActionIdToResourceId($event.actionId);
    if (!this.userFavorites.includes(resourceId)) {
      this.userFavorites.push(resourceId);
      this.addFavorite($event.actionId);
    } else {
      const index = this.userFavorites.indexOf(resourceId, 0);
      if (index > -1) {
        this.userFavorites.splice(index, 1);
      }
      this.deleteFavorite($event.actionId);
    }
  }

  deleteFavorite(actionId) {
    let favoriteIdToDelete = 0;
    const resourceId = this.convertActionIdToResourceId(actionId);
    this.favoriteIds.forEach(idPair => {
      if (idPair.resourceId === resourceId) {
        favoriteIdToDelete = idPair.favoriteId;
      }
    });
    const apiProp: ApiProperties = ApiModuleWeb.Favorite();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Delete);
    apiCall.silent = true;
    if (favoriteIdToDelete !== 0) {
      this.apiService.execute(apiCall, { FavoriteId: favoriteIdToDelete }).subscribe(result => {
        if (result.Data.Success) {
          // Remove this from the list now that we have it!
          this.favoriteIds = this.favoriteIds.filter(x => x.resourceId !== resourceId);
          if (this.button.favoritedOptionsListedFirst) {
            this.sortFavoritesToTop();
          }
        } else {
          console.error("Unable to delete favorite with id: " + favoriteIdToDelete + " from favorite table");
        }
      });
    }
  }

  addFavorite(actionId) {
    const apiProp: ApiProperties = ApiModuleWeb.Favorite();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
    apiCall.silent = true;
    const model: m5web.FavoriteEditViewModel = new m5web.FavoriteEditViewModel();
    model.FavoriteResourceType = this.button.favoriteResourceType;
    model.FavoriteResourceId = this.convertActionIdToResourceId(actionId);
    model.FavoriteRank = 1;
    model.ContactId = this.appService.user.ContactId;
    this.apiService.execute(apiCall, model).subscribe((response: IApiResponseWrapperTyped<any>) => {
      if (response.Data.Success) {
        this.favoriteIds.push({ favoriteId: response.Data.Data.FavoriteId, resourceId: response.Data.Data.FavoriteResourceId });
        if (this.button.favoritedOptionsListedFirst) {
          this.sortFavoritesToTop();
        }
      }
    }
    );
  }

  // ActionId is returned from an option with the format "pick-20" or "build-21" etc.
  // Where the numbers at the end are equal to the resourceId stored on the favorite table
  // Removing the letters and hyphen lets us use the Id to access the favorites table
  convertActionIdToResourceId(actionId): number {
    if (actionId) {
      while (actionId.indexOf('-') !== -1) {
        actionId = actionId.slice(actionId.indexOf('-') + 1);
      }
      return Number(actionId);
    } else {
      return 0;
    }
  }

  public fireClick(event: any, button: ButtonItem, action: Action, index: number) {
    Helper.fireClickVibration();
    let payload: EventModel;
    let cargo: any = {};
    if (this.cargo) {
      cargo = this.cargo;
    }
    cargo.action = action;
    cargo.button = button;
    cargo.actionIndex = index;
    cargo.control = this.control;
    if (action) {
      payload = new EventModel("click", event, this.data, new EventElementModel("action", action.actionId, "", action.label, action.icon), cargo);
      if (action.action) {
        action.action(payload);
      }
    } else if (button) {
      payload = new EventModel("click", event, this.data, new EventElementModel("button", button.actionId, "", button.label, button.icon), cargo);
      if (button.action) {
        button.action(payload);
      }
    }
    this.click.emit(payload);
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      // Log.errorMessage(err);
    }
  }

}
