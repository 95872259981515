import { Injectable } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticPickList } from '../models/model-helpers';
import { differenceInDays, format, parseISO } from 'date-fns';
import { Api } from '../api/Api';
import { ApiHelper } from '../api/ApiHelper';
import { ApiOperationType, IApiResponseWrapper, IApiResponseWrapperTyped } from '../api/ApiModels';
import { AsyncSubject, Observable, of, takeUntil } from 'rxjs';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';

@Injectable({
  providedIn: 'root'
})
export class QueryService extends BaseService {


  sharedDataSourcePickList: m5core.PickListSelectionViewModel[] = [];

  reportExportFormatPickList: m5core.PickListSelectionViewModel[] = [];
  reportExportDataFormatPickList: m5core.PickListSelectionViewModel[] = [];
  reportExportPageFormatPickList: m5core.PickListSelectionViewModel[] = [];
  reportExportImageFormatPickList: m5core.PickListSelectionViewModel[] = [];

  /**
   * Hide Free Form Copy Targets option until we check our settings to see if it is supported.
   */
  supportFreeFormCopyTargets: boolean = false;


  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super();
    this.init();
  }



  protected init(): void {

    this.apiService.loadPickList(Constants.PickList._DataSource).subscribe(result => {
      if (result.Data.Success) {
        this.sharedDataSourcePickList = result.Data.Data || [];
      } else {
        console.error(result);
        // this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList._ReportExportDataFormats).subscribe(result => {
      if (result.Data.Success) {
        this.reportExportDataFormatPickList = result.Data.Data || [];
      } else {
        console.error(result);
        // this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList._ReportExportPageFormats).subscribe(result => {
      if (result.Data.Success) {
        this.reportExportPageFormatPickList = result.Data.Data || [];
      } else {
        console.error(result);
        // this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList._ReportExportImageFormats).subscribe(result => {
      if (result.Data.Success) {
        this.reportExportImageFormatPickList = result.Data.Data || [];
      } else {
        console.error(result);
        // this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList._ReportExportFormats).subscribe(result => {
      if (result.Data.Success) {
        this.reportExportFormatPickList = result.Data.Data || [];
      } else {
        console.error(result);
        // this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.appService.systemSettingsTryGetOne("Report", "SupportFreeFormCopyTargets", "false").pipe(takeUntil(this.ngUnsubscribe)).subscribe((setting: m5.SettingEditViewModel) => {
      this.supportFreeFormCopyTargets = Helper.equals(setting.Value, "true", true);
      // console.error(this.supportFreeFormCopyTargets, setting);
    });

  }



  public buildExecutionRequest(query: m5.QueryEditViewModel, defaults: m5.QueryExecutionRequestOptionsViewModel): m5.QueryExecutionRequestOptionsViewModel {

    const request = new m5.QueryExecutionRequestOptionsViewModel();
    request.Variables = {};

    if (query) {
      request.QueryId = query.QueryId;
      if (query.Variables && (query.Variables.DateType === m5.QueryDateType.DateRange || query.Variables.DateType === m5.QueryDateType.DateTimeRange)) {
        request.DateRange = Constants.DateRange.LAST_WEEK.Value;
      }
      if (query.Variables && query.Variables.Variables) {
        query.Variables.Variables.forEach((value) => {
          request.Variables[value.PropertyName] = value.DefaultValue;
        });
      }
    }

    if (defaults) {
      request.FileTypes = defaults.FileTypes;
      if (defaults.DateRange) {
        request.DateRange = defaults.DateRange;
        if (Helper.equals(defaults.DateRange, Constants.DateRange.CUSTOM.Value, true)) {
          request.BeginningDateTime = defaults.BeginningDateTime;
          request.EndingDateTime = defaults.EndingDateTime;
        }
      }
      request.AsOfDateTime = defaults.AsOfDateTime;
      request.IncludeCoverPage = defaults.IncludeCoverPage;
      request.IncludeRowShading = defaults.IncludeRowShading;
      request.IncludeDailySubtotal = defaults.IncludeDailySubtotal;
      request.IncludeCharts = defaults.IncludeCharts;
      request.ReinitializeSummaryData = defaults.ReinitializeSummaryData;
      request.UploadTargets = defaults.UploadTargets || [];
      request.EmailMessageAssetId = defaults.EmailMessageAssetId;
      request.EmailSubject = defaults.EmailSubject;
      request.EmailBody = defaults.EmailBody;
      request.EmailRecipients = defaults.EmailRecipients || [];
    }

    return request;

  }


  public getOutputConfiguration(query: m5.QueryEditViewModel, selectedQueryOutputConfigurationId: number): m5.QueryOutputConfigurationEditViewModel {

    // Find the selected output configuration or the default output configuration
    let output: m5.QueryOutputConfigurationEditViewModel = null;

    if (selectedQueryOutputConfigurationId) {
      output = Helper.firstOrDefault(query.Output.Configurations, x => x.QueryOutputConfigurationId === selectedQueryOutputConfigurationId, null);
    }
    if (!output && query.Output.Configurations) {
      output = Helper.firstOrDefault(query.Output.Configurations, x => x.Default, null);
    }
    if (!output && query.Output.Configurations && query.Output.Configurations.length === 1) {
      output = query.Output.Configurations[0];
    }

    return output;

  }


  public getOutputTemplate(query: m5.QueryEditViewModel, selectedQueryOutputConfigurationId: number, selectedReportTemplateId: number): m5.ReportTemplateEditViewModel {

    // Find the selected output configuration or the default output configuration
    const output: m5.QueryOutputConfigurationEditViewModel = this.getOutputConfiguration(query, selectedQueryOutputConfigurationId);

    // If we have an output configuration see if it dictates the template to use
    if (output?.ReportTemplateId && !selectedReportTemplateId) {
      selectedReportTemplateId = output.ReportTemplateId;
    } else if (output?.SystemReportTemplateId && !selectedReportTemplateId) {
      console.error("TODO: get report template by the system report template id.");
    }

    // Find the selected report template or the default report template
    let template: m5.ReportTemplateEditViewModel = null;
    if (selectedReportTemplateId) {
      template = Helper.firstOrDefault(query.Output.Templates, x => x.ReportTemplateId === selectedReportTemplateId, null);
    }
    if (!template && query.Output.Templates) {
      template = Helper.firstOrDefault(query.Output.Templates, x => x.Default, null);
    }
    if (!template && query.Output.Templates && query.Output.Templates.length === 1) {
      template = query.Output.Templates[0];
    }

    return template;

  }




  public buildQueryDataSourcePickList(query: m5.QueryEditViewModel): m5core.PickListSelectionViewModel[] {
    const pickList: m5core.PickListSelectionViewModel[] = [];
    if (Helper.tryGetValue(query, x => x.DataSources, null)) {
      query.DataSources.forEach((dataSource) => {
        const item: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
        item.PickListId = Constants.PickList._DataSource;
        item.Value = (dataSource.DataSourceId || 0).toString();
        item.DisplayText = dataSource.Description;
        pickList.push(item);
      });
    }
    if (this.sharedDataSourcePickList) {
      return this.sharedDataSourcePickList.concat(pickList);
    } else {
      return pickList;
    }
  }


  public buildQueryConfigurationPickList(query: m5.QueryEditViewModel): m5core.PickListSelectionViewModel[] {
    const pickList: m5core.PickListSelectionViewModel[] = [];
    if (Helper.tryGetValue(query, x => x.Output.Configurations, null)) {
      query.Output.Configurations.forEach((config) => {
        const item: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
        item.PickListId = "QueryOutputConfiguration";
        item.Value = config.QueryOutputConfigurationId.toString();
        item.DisplayText = config.Description;
        pickList.push(item);
      });
    }
    return pickList;
  }

  public buildQueryTemplatePickList(query: m5.QueryEditViewModel): m5core.PickListSelectionViewModel[] {
    const pickList: m5core.PickListSelectionViewModel[] = [];
    if (Helper.tryGetValue(query, x => x.Output.Templates, null)) {
      query.Output.Templates.forEach((template) => {
        const item: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
        item.PickListId = "QueryOutputTemplate";
        item.Value = template.ReportTemplateId.toString();
        item.DisplayText = template.Description;
        pickList.push(item);
      });
    }
    return pickList;
  }


  public buildQueryFileFormatPickList(query: m5.QueryEditViewModel, selectedQueryOutputConfigurationId: number, selectedReportTemplateId: number): m5core.PickListSelectionViewModel[] {

    // Find the selected output configuration or the default output configuration
    const output: m5.QueryOutputConfigurationEditViewModel = this.getOutputConfiguration(query, selectedQueryOutputConfigurationId);
    // If we didn't have a selected output config id but we had a default then use that default for our id
    if (!selectedQueryOutputConfigurationId && output) {
      selectedQueryOutputConfigurationId = output.QueryOutputConfigurationId;
    }

    // Find the selected report template or the default report template
    const template: m5.ReportTemplateEditViewModel = this.getOutputTemplate(query, selectedQueryOutputConfigurationId, selectedReportTemplateId);

    if (!output && template) {
      // No configuration selected but we have a template so all export formats are valid
      return this.reportExportFormatPickList;
    } else if (!output && !template) {
      // No configuration and no template selected so run with data export formats
      return this.reportExportDataFormatPickList;
    } else if (output && (!output.SupportedFileFormats || output.SupportedFileFormats.length === 0)) {
      // Our output template is not restricting the file formats we support
      if (template) {
        // We have a template so run with all export formats
        return this.reportExportFormatPickList;
      } else {
        // We don't have a template so run with data export formats
        return this.reportExportDataFormatPickList;
      }
    }

    // If we got this far then our file format pick list is filtered by what the configuration output says is supported
    // Get our starting point based on if we have a template or not.
    let possibleOptions: m5core.PickListSelectionViewModel[] = [];
    if (template) {
      // We have a template so use all export formats as our starting point (use spread operator as we will be editing this array)
      possibleOptions = [...this.reportExportFormatPickList];
    } else {
      // We don't have a template so use data export formats as our starting point (use spread operator as we will be editing this array)
      possibleOptions = [...this.reportExportDataFormatPickList];
    }

    const pickList: m5core.PickListSelectionViewModel[] = [];
    if (output?.SupportedFileFormats && output.SupportedFileFormats.length > 0) {
      output.SupportedFileFormats.forEach(formatItem => {
        pickList.push(...possibleOptions.filter(x => Helper.equals(x.Value, formatItem, true)));
      });
    }

    if (!pickList || pickList.length === 0) {
      // Should not be possible that we have nothing in our list at this point but if it happens use the data export formats
      return this.reportExportDataFormatPickList;
    }


    return pickList;

  }



  getVisibilityWarningFlag(query: m5.QueryListViewModel | m5.QueryEditViewModel): boolean {
    if (!query) {
      console.error("Visibility method called without a query object.  Returning default value.");
      return false;
    }
    if (query.ApprovalPending && query.Visibility !== "D" && query.Visibility !== "V") {
      // Approval is pending and visibility is not draft or private so this query is not visible
      return true;
    }
    if (query.Visibility === "H") {
      // Hidden queries are not visible
      return true;
    }
    if (query.Visibility !== "P" && query.Visibility !== "I" && query.ContactId !== this.appService.user.ContactId) {
      // Query is not public or internal and the user is not the owner so this query is not visible
      return true;
    }
    if (!query.ValidForAdHocQuery && !query.ValidForDataExport && !query.ValidForDrillDownTarget && !query.ValidForReport && !query.ValidForVisualComponent) {
      // Not valid for any scenario
      return true;
    }
    // No warning so return false
    return false;
  }

  getVisibilityIcons(query: m5.QueryListViewModel | m5.QueryEditViewModel): string {

    if (!query) {
      console.error("Visibility method called without a query object.  Returning default value.");
      return "";
    }

    let html: string = "";

    if (query.Visibility === "P" || query.Visibility === "I") {
      if (query.ApprovalPending) {
        html += IconHelper.iconDataFromIconDescription("question", false, true, "Visible to all users once approved", "me-2").html;
      } else {
        html += IconHelper.iconDataFromIconDescription("check", false, true, "Visible to all users", "me-2").html;
      }
    } else if (query.Visibility === "H") {
      html += IconHelper.iconDataFromIconDescription("ban", false, true, "Hidden", "me-2").html;
    } else if (query.Visibility === "V" || query.Visibility === "D") {
      html += IconHelper.iconDataFromIconDescription("user-secret", false, true, `Visible to ${this.appService.user.ContactName}`, "me-2").html;
    }

    if (query.ValidForReport) {
      html += IconHelper.iconDataFromIconDescription("file-lines", false, true, "Valid For Reports", "me-2").html;
    }
    if (query.ValidForAdHocQuery) {
      html += IconHelper.iconDataFromIconDescription("database", false, true, "Valid For Ad-Hoc Queries", "me-2").html;
    }
    if (query.ValidForDataExport) {
      html += IconHelper.iconDataFromIconDescription("download", false, true, "Valid For Data Export", "me-2").html;
    }
    if (query.ValidForDrillDownTarget) {
      html += IconHelper.iconDataFromIconDescription("search", false, true, "Valid For Drill-Down", "me-2").html;
    }
    if (query.ValidForVisualComponent) {
      html += IconHelper.iconDataFromIconDescription("desktop", false, true, "Valid For Visuals", "me-2").html;
    }

    return html;

  }

  getVisibilityTooltip(query: m5.QueryListViewModel | m5.QueryEditViewModel): string {

    if (!query) {
      console.error("Visibility method called without a query object.  Returning default value.");
      return "";
    }

    let tooltip: string = "";

    if (query.Visibility === "P" || query.Visibility === "I") {
      if (query.ApprovalPending) {
        tooltip += (tooltip ? ", " : "") + "Visible to all users once approved";
      } else {
        tooltip += (tooltip ? ", " : "") + "Visible to all users";
      }
    } else if (query.Visibility === "H") {
      tooltip += (tooltip ? ", " : "") + "Hidden";
    } else if (query.Visibility === "V" || query.Visibility === "D") {
      tooltip += (tooltip ? ", " : "") + `Visible to ${this.appService.user.ContactName}`;
    }

    let validFor: string = "";
    if (query.ValidForReport) {
      validFor += (validFor ? ", " : "") + `Reports`;
    }
    if (query.ValidForAdHocQuery) {
      validFor += (validFor ? ", " : "") + `Ad-Hoc Queries`;
    }
    if (query.ValidForDataExport) {
      validFor += (validFor ? ", " : "") + `Data Export`;
    }
    if (query.ValidForDrillDownTarget) {
      validFor += (validFor ? ", " : "") + `Drill-Down`;
    }
    if (query.ValidForVisualComponent) {
      validFor += (validFor ? ", " : "") + `Visuals`;
    }
    if (validFor) {
      tooltip += (tooltip ? ".  " : "") + `Valid for ${validFor}.`;
    }

    return tooltip;

  }










  queryExecuteAndDownload(queryId: number, fileType: string) {

    const request: m5.QueryExecutionRequestOptionsViewModel = new m5.QueryExecutionRequestOptionsViewModel();
    request.RealTime = true;
    request.QueryId = queryId;
    request.FileTypes.push(Helper.getFirstDefinedString(fileType, "xlsx"));
    // request.Variables.Title = action.QueryResultsTitle;
    // request.Variables.FileName = action.QueryResultsFileName;
    // request.Variables.IncludeAsOf = action.QueryResultsIncludeAsOfTime;
    const apiProp = Api.Query();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Call);
    this.apiService.execute(apiCall, request).subscribe((result: IApiResponseWrapperTyped<any[]>) => {
      if (result.Data.Success) {
        // Successfully ran the query real-time now trigger a document download
        if (!result.Data?.Meta?.Event) {
          console.error("No event data returned from query execution.", result.Data?.Meta);
          this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Error getting the file to download.", 0);
          return;
        }
        const event: m5.QueryEventViewModel = result.Data.Meta.Event;
        if (!event.Files || event.Files.length === 0) {
          console.error("No files returned from query execution.", event);
          this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "Error getting the file to download.", 0);
          return;
        }
        const file: m5.QueryEventFileViewModel = event.Files[0];
        this.downloadQueryEventFile(file);
      } else {
        let error: string = `Query Error: ${result.Data.Message}`;
        if (result.Data.Errors && result.Data.Errors.length > 0) {
          error += ` ${result.Data.Errors[0].Message}`;
        }
        this.appService.alertManager.addAlertMessage(AlertItemType.Danger, error, 0);
        // this.appService.alertManager.addAlertFromApiResponse(result, this.apiCallExecute);
      }
    });
  }


  public downloadQueryEventFile(file: m5.QueryEventFileViewModel) {
    if (!file) {
      console.error("Download query event file requested without a file object.");
    }
    if (file.ResultCode && file.ResultMessage) {
      // File had an error then don't attempt to open it as it won't exist
      this.appService.alertManager.addAlertMessage(AlertItemType.Danger, file.ResultMessage, 0);
      return;
    }
    const apiPropFileDownload = Api.QueryEventFileDownload();
    const apiCallFileDownload = ApiHelper.createApiCall(apiPropFileDownload, ApiOperationType.Get);
    // Build URL including token since we're redirecting to the URL for download and there will be no auth headers.
    // Pass in url string in case it was partially resolved via the pathProperties object.
    let url = ApiHelper.buildApiAbsoluteUrl(apiCallFileDownload, file, apiCallFileDownload.url);
    url = ApiHelper.addQueryStringToUrl(url, `token=${apiCallFileDownload.token}`);
    // Assign location and file will download.
    window.location.href = url;
  }






  public getEventStatusIcon(event: m5.QueryEventViewModel): string {
    let icon: string = "question"; // unknown
    if (event) {
      if (event.ResultCode === m5.QueryEventStatus.Success) {
        icon = "check";
      } else if (event.ResultCode === m5.QueryEventStatus.Pending) {
        icon = "spinner fa-spin";
      } else if (event.ResultCode === m5.QueryEventStatus.Started) {
        icon = "spinner fa-spin";
      } else if (event.ResultCode === m5.QueryEventStatus.Querying) {
        icon = "database faa-tada animated";
      } else if (event.ResultCode === m5.QueryEventStatus.Exporting) {
        icon = "file-alt faa-shake animated";
      } else if (event.ResultCode === m5.QueryEventStatus.Delivering) {
        icon = "envelope faa-passing animated";
      } else {
        icon = "ban"; // error
      }
    }
    return icon;
  }

  public getEventStatusText(event: m5.QueryEventViewModel): string {
    if (!event) {
      return "Unknown";
    } else if (event.ResultMessage) {
      return event.ResultMessage;
    } else if (event.ResultCode === m5.QueryEventStatus.Success) {
      return `Success`;
    } else if (event.ResultCode === m5.QueryEventStatus.Pending) {
      return `Pending`;
    } else if (event.ResultCode === m5.QueryEventStatus.Started) {
      return `Started`;
    } else if (event.ResultCode === m5.QueryEventStatus.Querying) {
      return `Querying`;
    } else if (event.ResultCode === m5.QueryEventStatus.Exporting) {
      return `Exporting`;
    } else if (event.ResultCode === m5.QueryEventStatus.Delivering) {
      return `Delivering`;
    } else {
      return `Error`;
    }
  }

  public getEventFileIcon(file: m5.QueryEventFileViewModel): string {
    let icon: string = "download";
    if (file && file.FileRole === "C") {
      icon = "file"; // Control file
    } else if (file && file.FileRole === "U") {
      icon = "file-upload"; // Upload file
    } else if (file && file.FileRole === "R") {
      icon = "file-download (duotone)"; // Raw data file
    } else if (file && file.FileType) {
      if (Helper.equals(file.FileType, "xlsx", true) || Helper.equals(file.FileType, "xls", true)) {
        icon = "file-excel";
      } else if (Helper.equals(file.FileType, "docx", true) || Helper.equals(file.FileType, "doc", true)) {
        icon = "file-word";
      } else if (Helper.equals(file.FileType, "pptx", true) || Helper.equals(file.FileType, "ppt", true)) {
        icon = "file-powerpoint";
      } else if (Helper.equals(file.FileType, "html", true) || Helper.equals(file.FileType, "mhtml", true) || Helper.equals(file.FileType, "htm", true)) {
        // This html5 brand icon is maybe too nerdy for most users
        //icon = "html5 (brand)";
        //icon = "file-alt (duo)";
        //icon = "text:H over file";
        icon = "file-code";
      } else if (Helper.equals(file.FileType, "ods", true)) {
        icon = "file-spreadsheet";
      } else if (Helper.equals(file.FileType, "odt", true) || Helper.equals(file.FileType, "rtf", true)) {
        icon = "file-lines";
      } else if (Helper.equals(file.FileType, "csv", true)) {
        icon = "file-csv";
      } else if (Helper.equals(file.FileType, "csv", true)) {
        icon = "file-csv";
      } else if (Helper.equals(file.FileType, "tsv", true)) {
        icon = "text:t over file";
      } else if (Helper.equals(file.FileType, "psv", true)) {
        icon = "text:| over file";
      } else if (Helper.equals(file.FileType, "txt", true)) {
        icon = "file";
      } else if (Helper.equals(file.FileType, "xml", true)) {
        icon = "file-code";
      } else if (Helper.equals(file.FileType, "pdf", true)) {
        icon = "file-pdf";
      } else if (Helper.equals(file.FileType, "json", true)) {
        icon = "text:{} over file";
      } else if (Helper.equals(file.FileType, "bmp", true)) {
        icon = "file-image";
      } else if (Helper.equals(file.FileType, "gif", true)) {
        icon = "file-image"; // after v6.5 => "file-gif";
      } else if (Helper.equals(file.FileType, "jpg", true) || Helper.equals(file.FileType, "jpeg", true)) {
        icon = "file-image"; // after v6.5 => "file-jpg";
      } else if (Helper.equals(file.FileType, "pcx", true)) {
        icon = "file-image";
      } else if (Helper.equals(file.FileType, "png", true)) {
        icon = "file-image"; // after v6.5 => "file-png";
      } else if (Helper.equals(file.FileType, "tiff", true)) {
        icon = "file-image";
      } else if (Helper.equals(file.FileType, "emf", true)) {
        icon = "file-image";
      } else if (Helper.equals(file.FileType, "svg", true) || Helper.equals(file.FileType, "svgz", true)) {
        icon = "file-image"; // after v6.5 => "file-svg";
      } else if (Helper.equals(file.FileType, "zip", true)) {
        icon = "file-archive";
      }
    }
    return icon;
  }

  public getEventExpirationBadge(event: m5.QueryEventViewModel): string {

    if (!event?.ExpirationDateTime) {
      return "";
    }

    let days: number = 0;
    if (Helper.isString(event.ExpirationDateTime)) {
      const iso: Date = parseISO(event.ExpirationDateTime as unknown as string);
      days = differenceInDays(iso, new Date());
    } else {
      days = differenceInDays(event.ExpirationDateTime, new Date());
    }
    const expires = Helper.formatDateTime(event.ExpirationDateTime, "relative");

    if (days < 0) {
      return `<span class='badge bg-danger'>Expired ${expires}</span>`;
    } else if (days < 7) {
      return `<span class='badge bg-danger'>Expires ${expires}</span>`;
    } else if (days < 14) {
      return `<span class='badge bg-warning'>Expires ${expires}</span>`;
    } else {
      return `<span class='badge bg-info'>Expires ${expires}</span>`;
    }

  }


  public getEventFileDescriptionTooltipText(file: m5.QueryEventFileViewModel): string {
    if (!file) {
      return "";
    }
    if (file.ResultCode > 0 && file.ResultMessage) {
      // Something went sideways so show the error message
      let html1: string = `<center><strong><em>Error</em></strong></center>`;
      html1 += `<br/><strong>Error Code:</strong> ${file.ResultCode}`;
      html1 += `<br/><strong>Error Message:</strong> ${file.ResultMessage}`;
      return html1;
    }
    let html2: string = `<center><strong><em>Click to Download</em></strong></center>`;
    html2 += `<br/><strong>File Name:</strong> ${file.FriendlyName}`;
    if (file.FileRole === "C") {
      html2 += `<br/><strong>Control File:</strong> ${file.FileType}`;
    } else if (file.FileRole === "U") {
      html2 += `<br/><strong>Uploaded File:</strong> ${file.FileType}`;
    } else if (file.FileRole === "R") {
      html2 += `<br/><strong>Raw Data File:</strong> ${file.FileType}`;
    } else if (file.FileType) {
      html2 += `<br/><strong>File Type:</strong> ${file.FileType}`;
    }
    if (file.SizeRows !== undefined && file.SizeRows !== null) {
      // Can't just use truthy test as 0 is valid value
      html2 += `<br/><strong>Rows:</strong> ${file.SizeRows.toLocaleString()}`;
    }
    if (file.SizePages !== undefined && file.SizePages !== null) {
      // Can't just use truthy test as 0 is valid value but if our row count is > 0 and pages = 0 then really pages is unknown.
      if (file.SizeRows && file.SizePages === 0) {
        html2 += `<br/><strong>Pages:</strong> unknown`;
      } else {
        html2 += `<br/><strong>Pages:</strong> ${file.SizePages.toLocaleString()}`;
      }
    }
    if (file.SizeBytes) {
      html2 += `<br/><strong>Size:</strong> ${(file.SizeBytes / 1024).toFixed(1)} KB`;
    }
    if (file.HitCount) {
      html2 += `<br/><strong>Delivery Count:</strong> ${file.HitCount}`;
    }
    if (file.LastHitDateTime) {
      html2 += `<br/><strong>Last Delivery:</strong> ${file.LastHitDateTime}`;
    }
    return html2;
  }

  public getEventDescriptionTooltipText(event: m5.QueryEventViewModel): string {
    if (!event) {
      return "";
    }
    let html: string = `<strong>Description:</strong> ${event.Description}`;
    const queryDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.QueryDescription, null);
    if (queryDescription) {
      html += `<br/><strong>Query:</strong> ${queryDescription}`;
    }
    const filterDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.FilterDescription, null);
    if (filterDescription) {
      html += `<br/><strong>Filter:</strong> ${filterDescription}`;
    }
    const dataSourceDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.DataSourceDescription, null);
    if (dataSourceDescription) {
      html += `<br/><strong>Data Source:</strong> ${dataSourceDescription}`;
    }
    const outputDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.OutputConfigurationDescription, null);
    if (outputDescription) {
      html += `<br/><strong>Configuration:</strong> ${outputDescription}`;
    }
    const templateDescription: string = Helper.tryGetValue(event, x => x.Properties.Information.ReportTemplateDescription, null);
    if (templateDescription) {
      html += `<br/><strong>Template:</strong> ${templateDescription}`;
    }
    if (event.BeginningDateTime && event.EndingDateTime) {
      html += `<br/><strong>Date Range:</strong> ${event.BeginningDateTime} - ${event.EndingDateTime}`;
    }
    if (event.AsOfDateTime) {
      html += `<br/><strong>As-Of:</strong> ${event.AsOfDateTime}`;
    }
    if (event.ExpirationDateTime) {
      html += `<br/><strong>Expires:</strong> ${event.ExpirationDateTime}`;
      html += ` (${Helper.formatDateTime(event.ExpirationDateTime, "relative")})`;
    }
    const variables: any = Helper.tryGetValue(event, x => x.Properties.Variables, null);
    if (variables && !Helper.isEmpty(variables)) {
      html += `<br/><br/><strong>Variables:</strong>`;
      const properties: string[] = Helper.objectGetPropertyNameList(variables);
      properties.forEach(property => {
        if (Helper.isObject(variables[property])) {
          html += `<br/>${property}: ${JSON.stringify(variables[property])}`;
        } else {
          html += `<br/>${property}: ${variables[property]}`;
        }
      });
    }
    return html;
  }






  public hasFilterConditions(filter: m5.FilterConditionGroupViewModel): boolean {
    if (!filter) {
      return false;
    }
    if (filter.Conditions && filter.Conditions.length > 0) {
      return true;
    }
    if (filter.Groups && filter.Groups.length > 0) {
      return true;
    }
    return false;
  }

  filterConditionIsContactId(condition: m5.FilterConditionViewModel) {
    if (!condition) {
      return false;
    }
    if (condition.DataType !== m.System.TypeCode.Int64 && condition.DataType !== m.System.TypeCode.Int32) {
      return false;
    }
    if (!Helper.endsWith(condition.PropertyName, "ContactId", true)) {
      return false;
    }
    return true;
  }

  public filterConditionsWireUpMeta(filter: m5.FilterConditionGroupViewModel, dataModel: any): void {
    if (!filter || !dataModel) {
      return;
    }
    // Step through any conditions for this filter and wire up meta for the property
    if (filter.Conditions) {
      filter.Conditions.forEach((condition) => {
        const col = Helper.firstOrDefault<any>(dataModel.Columns, x => Helper.equals(x.Name, condition.PropertyName, true));
        if (col) {
          // Stick some data in a meta object which we won't save to help with ux
          if (!(condition as any).Meta) {
            (condition as any).Meta = {};
          }
          // (condition as any).Meta.Column = col;
          (condition as any).Meta.Description = col.Notes;
          (condition as any).Meta.PickListOptions = StaticPickList.DataModelColumnOptions(col.Options);
          (condition as any).Meta.PickListId = col.PickListId;
        } else {
          Log.errorMessage(`Unable to find column named '${condition.PropertyName}' in object documentation for '${dataModel.Name}'.`);
        }
      });
    }
    // Step through any groups for this filter and make recursive calls
    if (filter.Groups) {
      filter.Groups.forEach((group) => {
        this.filterConditionsWireUpMeta(group, dataModel);
      });
    }
    return;
  }

  public filterConditionsWireUpMetaOne(filter: m5.FilterConditionGroupViewModel, dataModel: any, propertyName: string): void {
    if (!filter || !dataModel || !propertyName || !filter.Conditions) {
      return;
    }
    const col = Helper.firstOrDefault<any>(dataModel.Columns, x => Helper.equals(x.Name, propertyName, true));
    if (!col) {
      Log.errorMessage(`Unable to find column named '${propertyName}' in object documentation for '${dataModel.Name}'.`);
      return;
    }
    filter.Conditions.forEach((condition) => {
      if (Helper.equals(condition.PropertyName, propertyName, true)) {
        // Save our data type since the column selected may have a new data type
        condition.DataType = col.DataTypeCode;
        // Stick some data in a meta object which we won't save to help with ux
        if (!(condition as any).Meta) {
          (condition as any).Meta = {};
        }
        // (condition as any).Meta.Column = col;
        (condition as any).Meta.Description = col.Notes;
        (condition as any).Meta.PickListOptions = StaticPickList.DataModelColumnOptions(col.Options);
        (condition as any).Meta.PickListId = col.PickListId;
        // Save value meta data if needed
        if (condition.DataType === m.System.TypeCode.DateTime && !condition.ValueMetaData) {
          condition.ValueMetaData = "CUSTOM";
        } else if (this.filterConditionIsContactId(condition) && !condition.ValueMetaData) {
          condition.ValueMetaData = "Me";
        }
      }
    });
    return;
  }

  public filterConditionsPurgeMeta(filter: m5.FilterConditionGroupViewModel): void {
    if (!filter) {
      return;
    }
    // Step through any conditions for this filter and delete meta
    if (filter.Conditions) {
      filter.Conditions.forEach((condition) => {
        delete (condition as any).Meta;
      });
    }
    // Step through any groups for this filter and make recursive calls
    if (filter.Groups) {
      filter.Groups.forEach((group) => {
        this.filterConditionsPurgeMeta(group);
      });
    }
    return;
  }



  // TS versions of C# helpers in Sql.cs and FilterService.cs that we need to convert filter builder object tree
  // to filter string which we can submit with queries when the filter is not saved and, therefore, no FilterId
  // is available to submit.

  public buildFilterExpressionFromConditionModel(filter: m5.FilterConditionGroupViewModel): string {

    if (!filter) {
      return "";
    }

    let sql = "";
    const conditionsSql = this.buildFilterExpressionFromConditionModelList(filter.ConditionBooleanOperator, filter.Conditions);

    if (conditionsSql) {
      if (!filter.Groups || filter.Groups.length === 0) {
        sql += conditionsSql;
      } else {
        sql += `( ${conditionsSql} )`;
      }
    }

    if (filter.Groups && filter.Groups.length > 0) {
      filter.Groups.forEach((group) => {
        const groupSql = this.buildFilterExpressionFromConditionModel(group);
        if (sql && groupSql) {
          sql += ` ${Helper.getFirstDefinedString(filter.GroupBooleanOperator, "And").toUpperCase()} `;
        }
        if (groupSql) {
          sql += `( ${groupSql} )`;
        }
      });
    }

    return sql;

  }

  public buildFilterExpressionFromConditionModelList(conditionBooleanOperator: string, conditions: m5.FilterConditionViewModel[]): string {

    if (!conditions || conditions.length === 0) {
      return "";
    }
    if (!conditionBooleanOperator) {
      conditionBooleanOperator = "And";
    }

    let sql = "";
    conditions.forEach((condition) => {
      if (sql) {
        sql += ` ${conditionBooleanOperator.toUpperCase()} `;
      }
      if (condition.DataType === m.System.TypeCode.DateTime && condition.ValueMetaData && !Helper.equals(condition.ValueMetaData, "Custom", true)) {
        sql += this.buildWhereClauseExpression(condition.PropertyName, condition.DataType, condition.ComparisonOperator, condition.ValueMetaData, true, false);
      } else if ((condition.DataType === m.System.TypeCode.Int64 || condition.DataType === m.System.TypeCode.Int32) &&
        Helper.endsWith(condition.PropertyName, "ContactId", true) &&
        !Helper.equals(condition.ValueMetaData, "Other", true)) {
        sql += this.buildWhereClauseExpression(condition.PropertyName, condition.DataType, condition.ComparisonOperator, condition.ValueMetaData, false, true);
      } else {
        sql += this.buildWhereClauseExpression(condition.PropertyName, condition.DataType, condition.ComparisonOperator, condition.Value, false, false);
      }
    });

    return sql;

  }

  public buildWhereClauseExpression(propertyName: string, dataType: m.System.TypeCode, compare: string, value: any, isDateRange: boolean, isContactId: boolean) {

    if (!propertyName) {
      return "";
    }

    // console.error(propertyName, dataType, compare, value);

    if (value === undefined || value === null) {
      if (Helper.equals(compare, "NotEquals", true)) {
        return `${propertyName} IS NOT NULL`;
      } else {
        return `${propertyName} IS NULL`;
      }
    }

    if (dataType === m.System.TypeCode.String) {
      if (this.isLikeOperator(compare)) {
        return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(this.prepareValueForLikeOperator(compare, value.toString()), dataType, compare)}`;
      } else {
        return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(value, dataType, compare)}`;
      }
    }

    if (dataType === m.System.TypeCode.Boolean) {
      return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(value, dataType, compare)}`;
    }

    if (dataType === m.System.TypeCode.DateTime) {
      if (isDateRange) {
        const range = Helper.getDateRange(value);
        if (this.isBetweenOperator(compare)) {
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep([range.beginningDateTime, range.endingDateTime], dataType, compare)}`;
        } else {
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(range.beginningDateTime, dataType, compare)}`;
        }
      } else {
        // console.error(value);
        if (this.isBetweenOperator(compare) && Helper.isArray(value)) {
          const d1 = new Date(value[0]);
          const date1: string = Helper.replaceAll(format(d1, Constants.DateFormat.FnsIsoDateTime), "T", " ");
          const d2 = new Date(value[1]);
          const date2: string = Helper.replaceAll(format(d2, Constants.DateFormat.FnsIsoDateTime), "T", " ");
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep([date1, date2], dataType, compare)}`;
        } else {
          const d = new Date(value);
          const date: string = Helper.replaceAll(format(d, Constants.DateFormat.FnsIsoDateTime), "T", " ");
          return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(date, dataType, compare)}`;
        }
      }
    }

    if (isContactId) {
      if (Helper.equals(value, "NotMe", true)) {
        if (this.isNotOperator(compare)) {
          // Not Not Me = Me?
          return `${propertyName} = ${this.appService.userOrDefault.ContactId}`;
        } else {
          return `${propertyName} <> ${this.appService.userOrDefault.ContactId}`;
        }
      } else if (Helper.equals(value, "MyGroups", true) || Helper.equals(value, "MeAndMyGroups", true)) {
        const subselect = "SELECT GroupContactId FROM ContactGroupMembership WITH (NOLOCK) " +
          `WHERE MemberContactId = ${this.appService.userOrDefault.ContactId} ` +
          "AND MarkedForDeletionDateTime IS NULL AND Status IN ( 'A' , 'I' , 'L' ) " +
          "AND ( ValidStartDateTime IS NULL OR ValidStartDateTime < GetDate() ) " +
          "AND ( ValidEndDateTime IS NULL OR ValidEndDateTime > GetDate() ) " +
          `AND PartitionId = ${this.appService.userOrDefault.PartitionId} `;
        const notsubselect = "SELECT Contact_Id_2 FROM Contact WITH (NOLOCK) " +
          "WHERE Contact_Type = 'G' AND Marked_For_Deletion_Date_Time IS NULL " +
          `AND Contact_Id_2 NOT IN ( ${subselect} ) ` +
          `AND Partition_Id = ${this.appService.userOrDefault.PartitionId} `;
        if (Helper.equals(value, "MyGroups", true)) {
          if (this.isNotOperator(compare)) {
            // IN instead of NOT IN because subselect is already negated with it's own NOT IN subselect
            return `${propertyName} IN ( ${notsubselect} )`;
          } else {
            return `${propertyName} IN ( ${subselect} )`;
          }
        } else { // MeAndMyGroups
          if (this.isNotOperator(compare)) {
            return `( ${propertyName} <> ${this.appService.userOrDefault.ContactId} AND ${propertyName} NOT IN ( ${subselect} ) )`;
          } else {
            return `( ${propertyName} = ${this.appService.userOrDefault.ContactId} OR ${propertyName} IN ( ${subselect} ) )`;
          }
        }
      } else { // Me
        if (this.isNotOperator(compare)) {
          return `${propertyName} <> ${this.appService.userOrDefault.ContactId}`;
        } else {
          return `${propertyName} = ${this.appService.userOrDefault.ContactId}`;
        }
      }
    }

    // Mostly what is left is numbers but if there are any other odd data types here they'll get treated the same
    return `${propertyName} ${this.convertOperatorNameToOperatorExpression(compare)} ${this.sqlPrep(value, dataType, compare)}`;

  }

  public sqlPrep(value: string | string[] | number | number[] | boolean | boolean[] | Date | Date[], dataType: m.System.TypeCode, operator: string): string {

    // TODO what if value is an array?  check operator In/NotIn / Between are we first or last instance?

    if (dataType === m.System.TypeCode.Boolean) {
      if (this.isBetweenOperator(operator)) {
        if (Helper.isArray(value) && (value as any).length >= 2) {
          return `${(value[0] ? "1" : "0")} AND ${(value[1] ? "1" : "0")}`;
        } else {
          return `${(value ? "1" : "0")} AND ${(value ? "1" : "0")}`;
        }
      } else {
        return (value ? "1" : "0");
      }
    }

    if (dataType === m.System.TypeCode.DateTime && (value instanceof Date || (Helper.isArray(value) && (value as any).length >= 1 && value[0] instanceof Date))) {
      if (value) {
        if (this.isBetweenOperator(operator)) {
          if (Helper.isArray(value) && (value as any).length >= 2) {
            // console.error("array of dates", value);
            // console.error(Helper.formatDateTime(value[0], "YYYY-MM-DD HH:mm:ss"));
            // console.error(Helper.formatDateTime(value[1], "YYYY-MM-DD HH:mm:ss"));
            return `Convert( DateTime , '${Helper.formatDateTime(value[0], "yyyy-MM-dd HH:mm:ss")}' , 120 ) AND Convert( DateTime , '${Helper.formatDateTime(value[1], "yyyy-MM-dd HH:mm:ss")}' , 120 )`;
          } else {
            return `Convert( DateTime , '${Helper.formatDateTime(value as Date, "yyyy-MM-dd HH:mm:ss")}' , 120 ) AND Convert( DateTime , '${Helper.formatDateTime(value as Date, "yyyy-MM-dd HH:mm:ss")}' , 120 )`;
          }
        } else {
          return `Convert( DateTime , '${Helper.formatDateTime(value as Date, "yyyy-MM-dd HH:mm:ss")}' , 120 )`;
        }
      } else {
        return "null";
      }
    } else if (dataType === m.System.TypeCode.DateTime) {
      if (value) {
        if (this.isBetweenOperator(operator)) {
          if (Helper.isArray(value) && (value as any).length >= 2) {
            return `Convert( DateTime , ${this.sqlPrep(value[0].toString(), m.System.TypeCode.String, "")} , 120 ) AND Convert( DateTime , ${this.sqlPrep(value[1].toString(), m.System.TypeCode.String, "")} , 120 )`;
          } else {
            return `Convert( DateTime , ${this.sqlPrep(value.toString(), m.System.TypeCode.String, "")} , 120 ) AND Convert( DateTime , ${this.sqlPrep(value.toString(), m.System.TypeCode.String, "")} , 120 )`;
          }
        } else {
          return `Convert( DateTime , ${this.sqlPrep(value.toString(), m.System.TypeCode.String, "")} , 120 )`;
        }
      } else {
        return "null";
      }
    }

    if (dataType === m.System.TypeCode.String) {
      if (this.isBetweenOperator(operator)) {
        if (Helper.isArray(value) && (value as any).length >= 2) {
          return `'${Helper.replaceAll(value[0].toString(), "'", "''")}' AND '${Helper.replaceAll(value[1].toString(), "'", "''")}'`;
        } else {
          return `'${Helper.replaceAll(value.toString(), "'", "''")}' AND '${Helper.replaceAll(value.toString(), "'", "''")}'`;
        }
      } else if (this.isInOperator(operator)) {
        let values: string[] = [];
        if (Helper.isArray(value)) {
          values = value as string[];
        } else {
          values = value.toString().split(",");
        }
        let sql = "";
        values.forEach((one) => {
          if (one) {
            if (sql) {
              sql += " , ";
            }
            sql += this.sqlPrep(one, m.System.TypeCode.String, "");
          }
        });
        // console.error("IN", values, sql);
        return `( ${sql} )`;
      } else {
        return `'${Helper.replaceAll(value.toString(), "'", "''")}'`;
      }
    }

    // Catch all ... works for numbers and hope it works anything else that got this far
    if (this.isBetweenOperator(operator)) {
      if (Helper.isArray(value) && (value as any).length >= 2) {
        return `${value[0]} AND ${value[1]}`;
      } else {
        return `${value} AND ${value}`;
      }
    } else if (this.isInOperator(operator)) {
      let values: string[] = [];
      if (Helper.isArray(value)) {
        values = value as string[];
      } else {
        values = value.toString().split(",");
      }
      let sql = "";
      values.forEach((one) => {
        if (one) {
          if (sql) {
            sql += " , ";
          }
          sql += one.toString();
        }
      });
      // console.error("IN", values, sql);
      return `( ${sql} )`;
    } else {
      return value.toString();
    }

  }

  public convertOperatorNameToOperatorExpression(operator: string): string {
    if (Helper.equals(operator, "Equals", true)) {
      return "=";
    } else if (Helper.equals(operator, "NotEquals", true)) {
      return "<>";
    } else if (Helper.equals(operator, "LessThan", true)) {
      return "<";
    } else if (Helper.equals(operator, "LessThanOrEqualTo", true)) {
      return "<=";
    } else if (Helper.equals(operator, "GreaterThan", true)) {
      return ">";
    } else if (Helper.equals(operator, "GreaterThanOrEqualTo", true)) {
      return ">=";
    } else if (Helper.equals(operator, "StartsWith", true) || Helper.equals(operator, "EndsWith", true) || Helper.equals(operator, "Contains", true)) {
      return "LIKE";
    } else if (Helper.equals(operator, "NotStartsWith", true) || Helper.equals(operator, "NotEndsWith", true) || Helper.equals(operator, "NotContains", true)) {
      return "NOT LIKE";
    } else if (Helper.equals(operator, "In", true)) {
      return "IN";
    } else if (Helper.equals(operator, "NotIn", true)) {
      return "NOT IN";
    } else if (Helper.equals(operator, "Between", true)) {
      return "BETWEEN";
    } else if (Helper.equals(operator, "NotBetween", true)) {
      return "NOT BETWEEN";
    } else {
      Log.errorMessage(`Unable to convert ${operator} to a sql operation string.`);
      return operator;
    }
  }

  public isLikeOperator(operator: string): boolean {
    return (Helper.contains(operator, "StartsWith", true) || Helper.contains(operator, "EndsWith", true) || Helper.contains(operator, "Contains", true));
  }

  public isInOperator(operator: string): boolean {
    return (Helper.equals(operator, "In", true) || Helper.equals(operator, "NotIn", true));
  }

  public isNotOperator(operator: string): boolean {
    return Helper.startsWith(operator, "Not", true);
  }

  public isBetweenOperator(operator: string): boolean {
    return Helper.contains(operator, "Between", true);
  }

  public prepareValueForLikeOperator(operator: string, value: string): string {
    // See https://stackoverflow.com/a/6030564
    const escaped: string = Helper.replaceAll(Helper.replaceAll((value || ""), "[", "[[]"), "%", "[%]");
    if (Helper.contains(operator, "StartsWith", true)) {
      return `${escaped}%`;
    } else if (Helper.contains(operator, "EndsWith", true)) {
      return `%${escaped}`;
    } else if (Helper.contains(operator, "Contains", true)) {
      return `%${escaped}%`;
    } else {
      return value;
    }
  }

  public isNumericDataType(dataType: m.System.TypeCode): boolean {

    const type = m.System.TypeCode[dataType]; // TypeCode enum as string

    if (Helper.startsWith(type, "Int", true) || Helper.startsWith(type, "UInt", true)) {
      return true;
    } else if (dataType === m.System.TypeCode.Byte) {
      return true;
    } else if (dataType === m.System.TypeCode.SByte) {
      return true;
    } else if (dataType === m.System.TypeCode.Double) {
      return true;
    } else if (dataType === m.System.TypeCode.Single) {
      return true;
    } else if (dataType === m.System.TypeCode.Decimal) {
      return true;
    }

    return false;

  }



  /**
   * Filter expressions can be provided in API format or SQL format but not a mix of both
   * since we need to convert from API format to SQL format server side and when it detects
   * to do that it escapes ' as '' , etc. which doesn't work when we're using sql format
   * in some of the filter expression.  Our filter builder produces SQL format and our table
   * header filters and hard coded filters often produce API format.  This method will
   * translate SQL format to API format we can submit a filter expression in a single format.
   * @param expression
   * @returns
   */
  public translateFilterFromSqlFormatToApiFormat(expression: string): string {

    if (!expression) {
      return "";
    }

    let output: string = expression;

    output = Helper.replaceAll(output, "'", '"');
    output = Helper.replaceAll(output, " AND ", " && ");
    output = Helper.replaceAll(output, " OR ", " || ");

    // We will be ok not translating LIKE to StartsWith, EndsWith, or Contains since server side can consume both

    return output;

  }


  /**
   * Makes an api call to DocumentationRawDataModel.
   * @param objectName ContactEditViewModel would be "Contact", BillingAccountEditViewModel would be "BillingAccount", etc
   * @returns the data model.
   */
  public loadDataModel(objectName: string = ""): Observable<any> {
    if (!objectName) {
      return of(null);
    }

    const subject = new AsyncSubject<any>();
    const apiProp = Api.DocumentationRawDataModel();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.cacheUseStorage = true;

    this.apiService.execute(apiCall, objectName).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        subject.next(result.Data.Data);
        subject.complete();
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();
  }


  /**
   * @param dataModel DataDictionaryTable or DataDictionaryView
   * @param displayColDescription If true, the col.Description will display. If false, the col.Name will display.
   * @returns A picklist of all the columns for the dataModel
   */
  public parseDataModelColumns(dataModel: any, displayColDescription: boolean = true): m5core.PickListSelectionViewModel[] {

    if (!dataModel) {
      console.error("unable to parse null data model");
      return;
    }
    if (!dataModel.Columns || dataModel.Columns.length === 0) {
      console.error("unable to parse data model without columns");
      return;
    }

    return StaticPickList.DataModelColumns(dataModel.Columns, true, true, displayColDescription);

  }



  /**
   * Converts a filter object into a query string for an api call or a user friendly query string for viewing in the UI.
   * Expected object example = {
   *  Filter1: {
   *    matchMode: "contains"
   *    value: "43"
   *  }
   *  Filter2: {
   *    matchMode: "startsWith"
   *    value: "tacoBell"
   *  }
   * }
   * @param userFriendly if true, it will be a viewer friendly string, meant to be shown in the UI to the user.
   */
  public convertTableDefinedFiltersToQueryFilter(filters: Filters, userFriendly: boolean = false): string {

    let filter = "";

    if (filters) {

      Object.keys(filters).forEach(key => {
        const oneFilter = filters[key];

        // The global key doesn't get attached to the query filter.
        // If it's userFriendly, it gets added to let the user know they have something typed in the search bar.
        if (Helper.equals(key, "global", true) && !userFriendly) {
          return; // move to the next key
        }

        if (filter) {
          if (userFriendly) {
            filter += " and\n";
          } else {
            filter += " && ";
          }
        }

        if (Helper.equals(key, "global", true)) {
          // This is the value in the table's 'Search' bar.
          filter += `Search: "${oneFilter.value}"`;
        } else if (Helper.equals(oneFilter.matchMode, "contains", true)) {
          if (userFriendly) {
            filter += `${key} contains "${oneFilter.value}"`;
          } else {
            filter += `${key}.Contains("${oneFilter.value}")`;
          }
        } else if (Helper.equals(oneFilter.matchMode, "startsWith", true)) {
          if (userFriendly) {
            filter += `${key} starts with "${oneFilter.value}"`;
          } else {
            filter += `${key}.StartsWith("${oneFilter.value}")`;
          }
        } else if (Helper.equals(oneFilter.matchMode, "endsWith", true)) {
          if (userFriendly) {
            filter += `${key} ends with "${oneFilter.value}"`;
          } else {
            filter += `${key}.EndsWith("${oneFilter.value}")`;
          }

        } else if (Helper.equals(oneFilter.matchMode, "in", true)) {
          if (Array.isArray(oneFilter.value)) {
            let segment: string = "";
            if (oneFilter.value && oneFilter.value.length > 0) {
              oneFilter.value.forEach(value => {
                if (userFriendly) {
                  if (segment) {
                    segment += " or\n";
                  }
                  segment += `${key} equals "${value}"`;
                } else {
                  if (segment) {
                    segment += " || ";
                  }
                  segment += `${key} == "${value}"`;
                }
              });
            }
            if (segment) {
              filter += `( ${segment} )`;
            }
          } else {
            console.error("Attempted to add filter values for a multi-select but the value is not an array.");
          }

        } else if (Helper.equals(oneFilter.matchMode, "equals", true)) {
          if (userFriendly) {
            filter += `${key} equals "${oneFilter.value}"`;
          } else {
            filter += `${key} == "${oneFilter.value}"`;
          }
        } else if (Helper.equals(oneFilter.matchMode, "notEquals", true)) {
          if (userFriendly) {
            filter += `${key} is not equal to "${oneFilter.value}"`;
          } else {
            filter += `${key} != "${oneFilter.value}"`;
          }
        } else if (Helper.equals(oneFilter.matchMode, "lt", true)) {
          if (userFriendly) {
            filter += `${key} is less than "${oneFilter.value}"`;
          } else {
            filter += `${key} < "${oneFilter.value}"`;
          }
        } else if (Helper.equals(oneFilter.matchMode, "lte", true)) {
          if (userFriendly) {
            filter += `${key} is less than or equal to "${oneFilter.value}"`;
          } else {
            filter += `${key} <= "${oneFilter.value}"`;
          }
        } else if (Helper.equals(oneFilter.matchMode, "gt", true)) {
          if (userFriendly) {
            filter += `${key} is greater than "${oneFilter.value}"`;
          } else {
            filter += `${key} > "${oneFilter.value}"`;
          }
        } else if (Helper.equals(oneFilter.matchMode, "gte", true)) {
          if (userFriendly) {
            filter += `${key} is greater than or equal to "${oneFilter.value}"`;
          } else {
            filter += `${key} >= "${oneFilter.value}"`;
          }
        }
      });
    }

    return filter;
  }

}

type Filters = {
  [filterName: string]: {
    matchMode: string;
    value: string | string[]; // Array of strings if they use a multi-select for a column's filter
  };
};
