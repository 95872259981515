import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { Action, EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Dictionary } from 'projects/core-lib/src/lib/models/dictionary';
import { MenuItem } from 'primeng/api';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { MenuService } from 'projects/core-lib/src/lib/services/menu.service';

@Component({
  selector: 'ib-menu-standard',
  templateUrl: './menu-standard.component.html',
  styleUrls: ['./menu-standard.component.css']
})
export class MenuStandardComponent implements OnInit, OnChanges {

  @Input() menu: m.MenuItem[] = [];
  @Input() parent: m.MenuItem;
  @Input() depth: number = 0;
  @Input() developerMode: boolean = false;
  @Input() contextMenuActions: Action[] = [];

  @Output() menuItemClick: EventEmitter<EventModel> = new EventEmitter();
  @Output() contextMenuSelection: EventEmitter<EventModel> = new EventEmitter();


  /**
  Dictionary with MenuItem.Id as key and boolean as value where boolean value
  of true indicates the menu item's children are expanded (i.e. visible) and
  absence of the Id or value of false means menu item's children (if any) are
  not expanded/visible.
  */
  expanded: Dictionary<boolean> = new Dictionary<boolean>();

  /**
  Prime object formatted context menu.
  */
  primeContextMenu: MenuItem[];

  /**
  Item that was selected when context menu was displayed.
  */
  contextMenuSelectedItem: m.MenuItem;

  /**
  Style applied to menu items.  This is a calculated value based on inputs.
  */
  style: string = "";

  constructor(protected menuService: MenuService) { }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  configure() {

    // Padding based on menu depth ... 0 = root
    this.style = `padding-left:${(this.depth * .5)}rem;`;

    if (this.contextMenuActions && this.contextMenuActions.length > 0) {
      // Make sure all context menu actions have an id
      this.contextMenuActions.forEach(action => {
        if (!action.actionId) {
          action.actionId = (action.label || Helper.createBase36Guid());
        }
      });
      // Convert to context menu actions to prime menu model
      this.primeContextMenu = this.menuService.fromActionListToPrimeMenu(this.contextMenuActions, this.onContextMenuClick);
    }


  }

  isExpanded(item: m.MenuItem) {
    if (!item) {
      return false;
    }
    if (!this.expanded.containsKey(item.Id)) {
      return false;
    }
    return this.expanded.item(item.Id);
  }

  trackByFn(index: number, item: m.MenuItem) {
    return item.Id;
  }

  onMenuItemClick($event, item: m.MenuItem) {

    // If we have children then click also flips the expanded flag
    if (item && item.Children && item.Children.length > 0) {
      if (this.expanded.containsKey(item.Id)) {
        this.expanded.add(item.Id, !this.expanded.item(item.Id));
      } else {
        this.expanded.add(item.Id, true);
      }
    }

    // Now fire event in case our caller wants to know what we clicked on.
    const payload: EventModel = new EventModel("menu-item-click", $event, item, null, { parent: this.parent, ancestors: [this.parent] });
    this.menuItemClick.emit(payload);

    if (!this.developerMode) {
      // Not in developer mode so perform the menu action called for
      // TODO right now this only used in developer mode but we need to expand that support
    }

    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // We want to fire click for our menu item not any parent menu item that may also have a click event
      $event.stopPropagation();
      $event.preventDefault();
    } catch (err) {
      // Log.errorMessage(err);
    }

  }

  // Fat arrow function so we keep this context to this class
  onContextMenuClick = ($event) => {
    // Find the action that matches the id for the internal context menu item
    const matches = this.contextMenuActions.filter(x => x.actionId === $event.item.id);
    let action: Action = null;
    if (matches && matches.length > 0) {
      action = matches[0];
    }
    const cargo: any = { parent: this.parent, ancestors: [this.parent], contextMenuItem: action, internalContextMenuItem: $event.item, originalEvent: $event.originalEvent };
    // Now fire event in case our caller wants to know what we clicked on.
    const payload: EventModel = new EventModel("context-menu-click", $event, this.contextMenuSelectedItem, new EventElementModel("context-menu-item", action.actionId, action.label, action.label, action.icon), cargo);
    this.contextMenuSelection.emit(payload);
    // If we have an action to perform for this context menu then perform it with the same payload passed in as the event
    if (action.action) {
      action.action(payload);
    }
  };

  onMouseDown($event, item: m.MenuItem) {
    let isRightButton = false;
    if ("which" in $event) {
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightButton = $event.which === 3;
    } else if ("button" in $event) {
      // IE, Opera
      isRightButton = $event.button === 2;
    }
    // console.error(`Right Button = ${isRightButton}`, item , $event);
    if (isRightButton) {
      this.contextMenuSelectedItem = item;
    } else {
      this.contextMenuSelectedItem = null;
    }
  }


  // Pass any child menu events up the food chain
  onChildMenuItemClick($event) {
    if (this.parent) {
      let cargo = $event.cargo;
      if (!cargo) {
        cargo = { ancestors: [this.parent] };
      } else if (!cargo.ancestors) {
        cargo.ancestors = [this.parent];
      } else {
        cargo.ancestors.push(this.parent);
      }
      $event.cargo = cargo;
    }
    this.menuItemClick.emit($event);
  }
  onChildContextMenuSelection($event) {
    if (this.parent) {
      let cargo = $event.cargo;
      if (!cargo) {
        cargo = { ancestors: [this.parent] };
      } else if (!cargo.ancestors) {
        cargo.ancestors = [this.parent];
      } else {
        cargo.ancestors.push(this.parent);
      }
      $event.cargo = cargo;
    }
    this.contextMenuSelection.emit($event);
  }

}
