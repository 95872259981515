import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ButtonItem, Action, EventModelTyped, EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { AsyncSubject, Observable, of, Subject } from 'rxjs';
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { DataEditorFormModalWrapperBaseClass } from 'projects/common-lib/src/lib/ux/data-editor/data-editor-form-modal-wrapper-base-class';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { UxService } from '../../services/ux.service';
import { TableColumnConfigurationModalComponent } from '../table-column-configuration-modal/table-column-configuration-modal.component';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiOperationType, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';

@Component({
  selector: 'ib-table-configuration-modal',
  templateUrl: './table-configuration-modal.component.html',
  styleUrls: ['./table-configuration-modal.component.css']
})
export class TableConfigurationModalComponent extends DataEditorFormModalWrapperBaseClass<m5web.TableConfigurationViewModel> implements OnInit, OnChanges, OnDestroy {

  @Output() saveView: EventEmitter<any> = new EventEmitter();
  @Input() listModel: any = null;
  @Input() listModelName: string = "";
  @Input() fullModel: any = null;
  @Input() fullModelName: string = "";

  rowActionProperties: m.PropertyMetaDataViewModel[] = [];

  dataModel: any = null; // DataDictionaryTable or DataDictionaryView
  dataModelLoadedName: string = "";

  /*
   * Gets toggled when we select to upload if upload support is enabled.
   */
  showFileUploadArea: boolean = false;

  canDownloadUpload(): boolean {
    return this.appService.optInFeatures.ObjectExportImportSupport;
  }

  sortModePickList: m5core.PickListSelectionViewModel[] = [];
  columnResizeModePickList: m5core.PickListSelectionViewModel[] = [];
  themePickList: m5core.PickListSelectionViewModel[] = [];
  rowActionIdsPickList: m5core.PickListSelectionViewModel[] = [];
  rowActionDisplayModePickList: m5core.PickListSelectionViewModel[] = [];

  dateRangePickList: m5core.PickListSelectionViewModel[] = StaticPickList.DateRangeOptions();

  columnsTableOptions: TableOptions = null;
  columnsTableReloadCount: number = 0;
  getRowStyleEditorActions: ButtonItem = null;


  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer,
    public modal: NgbActiveModal) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setupPickListsStatic();
    this.setupPickListsDataDriven();
    this.setupGetRowStyleEditorActions();
    this.setupColumnsTableOptions();
    this.setupRowActionProperties();
  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.data && this.data) {
      this.setupPickListsDataDriven();
    }
  }
  // ngOnDestroy() {
  //  super.ngOnDestroy();
  // }

  setupPickListsStatic() {
    this.sortModePickList = StaticPickList.stringItemsToPickList("none", "single", "multiple");
    this.columnResizeModePickList = StaticPickList.stringItemsToPickList("none", "fit", "expand");
    this.themePickList = StaticPickList.stringItemsToPickList("default", "plain", "striped");
    this.rowActionDisplayModePickList = StaticPickList.stringItemsToPickList("icons", "menu", "overflow");
  }

  setupPickListsDataDriven() {
    if (!this.data) {
      return;
    }
    this.rowActionIdsPickList = StaticPickList.stringArrayToPickList(this.data.RowActionIds);
  }

  setupGetRowStyleEditorActions() {
    this.getRowStyleEditorActions = new ButtonItem("Example", "js-square (brand)", "secondary");
    this.getRowStyleEditorActions.action = (event: EventModel) => {
      const code: string =
        `(row) => {
    let style = "";
    let alert = false;
    // Some condition that would result in us wanting to alert the row
    if (false) {
      alert = true;
    }
    if (alert) {
      style = "color:red;";
    }
    //console.error("row style", alert, style, row);
    return style;
};`;
      if (this.data.GetRowStyle) {
        this.data.GetRowStyle = code + "\n\n\n" + this.data.GetRowStyle;
      } else {
        this.data.GetRowStyle = code;
      }
    };
  }

  setupColumnsTableOptions() {

    const options = new TableOptions();

    options.tableId = null; // no save/load customizations
    options.rowsPerPage = 10;
    options.loadDataFromServer = false;
    options.theme = "striped";
    options.reorderRows = true;
    options.selectRows = false;
    // options.orderPropertyName = "DisplayOrder";
    options.globalSearch = false;
    options.selectRowViaRowClick = true;

    options.actionButtonRight1 = new ButtonItem(`Add Column`, "plus", "primary", (event) => {
      // this.data.Columns.push(this.uxService.tableService.newTableColumnConfig());
      this.addColumns();
    });
    options.actionButtonRight1.size = "sm";
    options.actionButtonRight1.options = [];
    options.actionButtonRight1.options.push(new Action("add", "Add Column", "plus", "", (event) => {
      // this.data.Columns.push(this.uxService.tableService.newTableColumnConfig());
      this.addColumns();
    }));
    options.actionButtonRight1.options.push(new Action()); // separator
    options.actionButtonRight1.options.push(new Action("add-all", "Add All Columns", "plus-square", "", (event) => {
      this.addAllMissingColumns();
    }));
    // let's do this via local event since we are passing in a filtered list... options.orderPropertyName = "DisplayOrder";
    // We have a display order in our data so we don't allow sorting on the table
    // options.sort = "Title";
    options.columns = [];
    options.columns.push(new TableColumnOptions("Visible", "Visible", "boolean"));
    options.columns.slice(-1)[0].sortable = false;
    options.columns.push(new TableColumnOptions("PropertyName", "Property Name"));
    options.columns.slice(-1)[0].sortable = false;
    options.columns.push(new TableColumnOptions("HeaderLabel", "Header Text"));
    options.columns.slice(-1)[0].sortable = false;
    options.columns.push(new TableColumnOptions("HeaderIcon", "Header Icon", "function"));
    options.columns.slice(-1)[0].sortable = false;
    options.columns.slice(-1)[0].render = (row: m5web.TableColumnConfigurationViewModel) => {
      if (!row.HeaderIcon) {
        return this.sanitizer.bypassSecurityTrustHtml("");
      }
      const html = IconHelper.iconDataFromIconDescription(row.HeaderIcon, false, true, "", "").html;
      return this.sanitizer.bypassSecurityTrustHtml(html);
    };

    options.rowSelectedAction = (row: any, selectedRows: any[], allRows: any[], cargo: any) => {
      const index = allRows.indexOf(row);
      // console.error("edit", row);
      this.onColumnConfig(row, index);
    };

    options.rowActionButton = new ButtonItem("", "bars", "default");
    options.rowActionButton.size = "xs";
    options.rowActionButton.options.push(new Action("edit", "Edit", "pencil", "", (event) => {
      // console.error("context menu event inside action button handler", event);
      if (!event?.data || !event.cargo || event.cargo.index === undefined) {
        Log.errorMessage("Edit was called with null event data object or cargo index.");
        return;
      }
      this.onColumnConfig(event.data, event.cargo.index);
    }));
    options.rowActionButton.options.push(new Action("divider"));
    options.rowActionButton.options.push(new Action("delete", "Delete", "times", "", (event) => {
      if (!event?.data) {
        Log.errorMessage("Delete was called with null event data object.");
        return;
      }
      let question = "Delete this column configuration?";
      if (event.data.PropertyName || event.data.HeaderLabel) {
        question = `Delete the ${Helper.getFirstDefinedString(event.data.PropertyName, event.data.HeaderLabel)} column?`;
      }
      const promise = this.uxService.modal.confirmDelete(question, null);
      promise.then((answer) => {
        const index = this.data.Columns.indexOf(event.data);
        if (index > -1) {
          this.data.Columns.splice(index, 1);
        }
      }, (cancelled) => {
        // No action
      });
    }));

    this.columnsTableOptions = options;

  }

  setupRowActionProperties() {

    this.rowActionProperties.push(new m.PropertyMetaDataViewModel());
    this.rowActionProperties[0].PropertyName = "ActionId";
    this.rowActionProperties[0].PropertyNumber = 1;
    this.rowActionProperties[0].InputLabel = "Action Id";
    this.rowActionProperties[0].DataType = m.System.TypeCode.String;
    this.rowActionProperties[0].PickList = this.rowActionIdsPickList;
    this.rowActionProperties[0].InputControlType = m.InputControlTypeOption.DropDown;
    this.rowActionProperties[0].IsRequired = true;
    this.rowActionProperties.push(new m.PropertyMetaDataViewModel());
    this.rowActionProperties[1].PropertyName = "RequiresRole";
    this.rowActionProperties[1].PropertyNumber = 2;
    this.rowActionProperties[1].InputLabel = "Requires Role";
    this.rowActionProperties[1].DataType = m.System.TypeCode.String;
    this.rowActionProperties[1].PickListId = this.Constants.PickList._Role;
    this.rowActionProperties[1].PickListIncludeOptionForNone = true;
    this.rowActionProperties[1].InputControlType = m.InputControlTypeOption.DropDown;
    this.rowActionProperties.push(new m.PropertyMetaDataViewModel());
    this.rowActionProperties[2].PropertyName = "RequiresPermissionAccessArea";
    this.rowActionProperties[2].PropertyNumber = 3;
    this.rowActionProperties[2].InputLabel = "Requires Permission Area";
    this.rowActionProperties[2].DataType = m.System.TypeCode.String;
    this.rowActionProperties[2].PickListId = this.Constants.PickList.__RoleDetail_SecurityAreaTable;
    this.rowActionProperties[2].PickListIncludeOptionForNone = true;
    this.rowActionProperties[2].InputControlType = m.InputControlTypeOption.SingleLineEditor;
    this.rowActionProperties.push(new m.PropertyMetaDataViewModel());
    this.rowActionProperties[3].PropertyName = "RequiresPermissionRight";
    this.rowActionProperties[3].PropertyNumber = 4;
    this.rowActionProperties[3].InputLabel = "Requires Permission Right";
    this.rowActionProperties[3].DataType = m.System.TypeCode.String;
    this.rowActionProperties[3].PickList = [];
    this.rowActionProperties[3].PickList.push({ DisplayText: "Read", Value: "R" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickList.push({ DisplayText: "Read Single", Value: "S" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickList.push({ DisplayText: "Add", Value: "A" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickList.push({ DisplayText: "Edit", Value: "E" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickList.push({ DisplayText: "Delete", Value: "D" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickList.push({ DisplayText: "Output", Value: "O" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickList.push({ DisplayText: "Execute", Value: "X" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickList.push({ DisplayText: "Full", Value: "F" } as m.PickListOptionViewModel);
    this.rowActionProperties[3].PickListIncludeOptionForNone = true;
    this.rowActionProperties[3].InputControlType = m.InputControlTypeOption.DropDown;

  }


  addColumns() {
    this.loadDataModel().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(dataModel => {
        if (dataModel?.Columns) {
          const pickList: m5core.PickListSelectionViewModel[] = StaticPickList.DataModelColumns(dataModel.Columns);
          const promise = this.uxService.modal.showModalEditMultiplePickListValues("Columns", [], "", "large", "Add Columns", pickList);
          promise.then((event: EventModelTyped<{ Input: { Item: string[] } }>) => {
            // value can be found here: event.data.Input.Item
            // Get list of column objects from our data model that match what was selected
            const cols: any[] = dataModel.Columns.filter(x => event.data.Input.Item.includes(x.Name));
            cols.forEach((col: any, index: number, cols1: any[]) => {
              if (Helper.equals(col.Name, "MarkedForDeletionDateTime", true) ||
                Helper.equals(col.Name, "DeletedAt", true) ||
                Helper.equals(col.Name, "MarkedForDeletionByContactId", true) ||
                Helper.equals(col.Name, "DeletedBy", true) ||
                Helper.equals(col.Name, "CurrentRowVersion", true) ||
                Helper.equals(col.Name, "ObjVersion", true) ||
                Helper.equals(col.Name, "PartitionId", true) ||
                Helper.equals(col.Name, "Attributes", true) ||
                Helper.equals(col.Name, "TimeZone", true) ||
                Helper.equals(col.Name, "MetaData", true)) {
                // These columns are excluded from the list of columns that can be rendered
              } else {
                if (!this.data.Columns.find(x => Helper.equals(x.PropertyName, col.Name, true))) {
                  this.data.Columns.push(this.uxService.tableService.newTableColumnConfigFromDataModelColumn(col));
                }
              }
            });
          }, (reason) => {
            // User hit cancel so nothing to save
          });
        } else {
          this.data.Columns.push(this.uxService.tableService.newTableColumnConfig());
        }
      });
  }


  addAllMissingColumns() {
    this.loadDataModel().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(dataModel => {
        if (dataModel?.Columns) {
          dataModel.Columns.forEach((col: any, index: number, cols: any[]) => {
            if (Helper.equals(col.Name, "MarkedForDeletionDateTime", true) ||
              Helper.equals(col.Name, "DeletedAt", true) ||
              Helper.equals(col.Name, "MarkedForDeletionByContactId", true) ||
              Helper.equals(col.Name, "DeletedBy", true) ||
              Helper.equals(col.Name, "CurrentRowVersion", true) ||
              Helper.equals(col.Name, "ObjVersion", true) ||
              Helper.equals(col.Name, "PartitionId", true) ||
              Helper.equals(col.Name, "Attributes", true) ||
              Helper.equals(col.Name, "TimeZone", true) ||
              Helper.equals(col.Name, "MetaData", true)) {
              // These columns are excluded from the list of columns that can be rendered
            } else {
              if (!this.data.Columns.find(x => Helper.equals(x.PropertyName, col.Name, true))) {
                this.data.Columns.push(this.uxService.tableService.newTableColumnConfigFromDataModelColumn(col));
              }
            }
          });
        }
      });
  }


  loadDataModel(): Observable<any> {

    if (!this.fullModelName && !this.listModelName) {
      // Can't do this without a model name to work with
      return of(null);
    }

    // Figure out what model name we'll be using
    let modelName = this.listModelName || this.fullModelName;
    if (this.data.Expand && this.fullModelName) {
      // We have a value in expand so assume that will result in us getting a full model instead of list model
      modelName = this.fullModelName;
    }

    if (this.dataModel && Helper.equals(this.dataModelLoadedName, modelName, true)) {
      // We already have a data model loaded and it matches what we would be loading here
      return of(this.dataModel);
    }

    const subject = new AsyncSubject<any>();

    const apiProp = Api.DocumentationDataModel(); // .DocumentationRawDataModel();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.cacheUseStorage = true;
    this.apiService.execute(apiCall, modelName).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        this.dataModel = result.Data.Data;
        this.dataModelLoadedName = modelName;
        // Push out our response
        subject.next(this.dataModel);
        subject.complete();
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        subject.next(null);
        subject.complete();
      }
    });

    return subject.asObservable();

  }

  onRowSelected(event) {
    if (!event?.data || !event.cargo || event.cargo.index === undefined) {
      Log.errorMessage("Edit was called with null event data object or cargo index.");
      return;
    }
    this.onColumnConfig(event.data, event.cargo.index);
  }

  onColumnConfig(col: m5web.TableColumnConfigurationViewModel, index: number) {

    if (!col) {
      return;
    }

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.titleIcon = "cog";
    options.title = `Column Configuration`;

    const modalRef = this.uxService.modal.ngbModalService.open(TableColumnConfigurationModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    // Always work with a copy of the object in case the user makes changes but then hits cancel button
    modalRef.componentInstance.data = Helper.deepCopy(col);

    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModelTyped<m5web.TableColumnConfigurationViewModel>) => {
      // User hit ok so value.data is the data object.
      if (index > -1) {
        this.data.Columns[index] = value.data;
      } else {
        this.data.Columns.push(value.data);
      }
    }, (reason) => {
      // User hit cancel so nothing to save but maybe the config got delete and we should remove it
    });

  }

  onSaveViewButtonClick($event) {
    this.saveView.emit();
  }

  onDelete($event) {
    const promise = this.uxService.modal.confirmDelete("Delete this table configuration?", null);
    promise.then((answer) => {
      this.uxService.tableService.deleteTableConfig(this.data);
      const payload: EventModel = new EventModel("delete", $event, "delete", new EventElementModel("modal", null, "DeleteButton"));
      payload.cargo = { ok: false, cancel: false, actionId: "delete", button: null };
      this.modal.dismiss(payload);
    }, (cancelled) => {
      // No action
    });
  }

  onDownload($event) {
    const fileName = `Table Configuration ${this.data.Description} as-of ${Helper.formatDateTime(new Date(), "yyyy-MM-dd-HH-mm-ss")}.json`;
    Helper.saveJSON(this.data, fileName);
  }

  onUpload($event) {

    // Validate and parse file contents
    if (!$event?.data?.contents) {
      this.uxService.modal.alertDanger("Error", `Unable to read table configuration file contents.`);
      return;
    }

    let obj: m5web.TableConfigurationViewModel = null;
    try {
      obj = JSON.parse($event.data.contents);
    } catch (ex) {
      this.uxService.modal.alertDanger("Error", `Unable to parse table configuration file contents.<br/><br/>${ex}`);
      return;
    }

    // Fire off change detection which for some reason seems needed at this point otherwise we don't seem to
    // progress without a mouse click or other event which I assume is triggering change detection.
    this.uxService.detectChanges();

    // Now check for properties that are required to know this is valid JSON
    const requiredProperties: string[] = ["Description", "Columns"];
    let hasRequiredProperty: boolean = true;
    requiredProperties.forEach(property => {
      if (!obj[property]) {
        hasRequiredProperty = false;
        Log.debugMessage(`Uploaded JSON file is missing required property ${property}.`);
      }
    });
    if (!hasRequiredProperty) {
      this.uxService.modal.alertDanger("Error", `This file does not appear to be a properly formatted table configuration object.`);
      return;
    }

    if (!Helper.equals(this.data.Description, obj.Description, true)) {
      this.uxService.modal.alertDanger("Error", `This file is for table config ${obj.Description} which does not match the current table config ${this.data.Description}.`);
      return;
    }

    // We keep the upload area visible until now so it's clear we're working on the upload
    this.showFileUploadArea = false;

    // Warn user and if they confirm then take action to have this be our new object
    const message = `<strong class="text-danger">Warning: Your current table configuration will be replaced.</strong>  ` +
      `If you have not already done so you should back it up by downloading it first.<br/><br/>` +
      `You should only import files that are from a trusted source.  Are you sure you want to replace your current table configuration with the uploaded configuration?`;
    const options = new ModalCommonOptions();
    options.titleContextColor = "danger";
    options.titleIcon = "exclamation-triangle";
    options.title = `Replace Table Configuration`;
    options.message = message;
    options.okButtonContextColor = "danger";
    options.okButtonText = "Yes";
    options.cancelButtonContextColor = "default";
    options.cancelButtonText = "No";
    options.size = "larger";
    const promise = this.uxService.modal.showSimpleModal(options);
    promise.then((answer) => {
      // console.error('modal result', answer);
      obj.TableConfigurationId = this.data.TableConfigurationId;
      obj.MetaData = this.data.MetaData;
      this.data = obj;
      // Fire off change detection
      this.uxService.detectChanges();
      // const payload: EventModel = new EventModel("change", event, this.data);
      // this.change.emit(payload);
    }, (cancelled) => {
      // No action
    });

  }


  onRowActionChange($event) {
    if ($event?.data) {
      this.data.RowActions = $event.data;
    }
  }



}
