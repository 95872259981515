// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { AppStyles, ConfigMenuItem, DockBackgroundTheme, DockPosition, IAppConfig, ImageDockThemeSettings, StandardThemeSettings } from "../config/AppConfig";

export class ConfigHelpers {

  /**
   * @returns {string} - the current app style
   */
  public static getAppStyle(): AppStyles {
    if (AppConfig && AppConfig.style) {
      if (AppConfig.style !== "standard" && AppConfig.style !== "image-dock") {
        console.error("Invalid app style.  Valid styles are 'standard' and 'image-dock'.  Defaulting to 'standard'.");
        return "standard";
      }
      return AppConfig.style;
    } else {
      return "standard";
    }
  }

  /**
   * Looks at the AppConfig and returns an ngStyle object for the background image based on styleSettings.backgroundImageStyle
   * @returns { [key: string]: string }
   */
  public static getBackgroundImageStyle(): { [key: string]: string } {

    // No path for a background image, then we don't apply any styling
    if (!this.appConfigHasBackgroundImagePath()) {
      return {};
    }

    if (AppConfig && AppConfig.style && AppConfig.styleSettings && AppConfig.styleSettings.backgroundImageStyle === "fill") {
      return ConfigHelpers.getBackGroundImageStyleFill();
    } else if (AppConfig && AppConfig.style && AppConfig.styleSettings && AppConfig.styleSettings.backgroundImageStyle === "center") {
      return ConfigHelpers.getBackGroundImageStyleCenter();
    } else if (AppConfig && AppConfig.style && AppConfig.styleSettings && AppConfig.styleSettings.backgroundImageStyle === "repeat") {
      return ConfigHelpers.getBackGroundImageStyleRepeat();
    } else {
      // They defined a background image path in the config but didn't declare a style so we will default to fill
      return ConfigHelpers.getBackGroundImageStyleFill();
    }
  }

  /**
   * This is good for a really big image as it will size it down to fill the space.
   * (Or make a smaller image bigger to fit the space)
   * @returns { [key: string]: string } - the ngStyle object for the background image
   */
  public static getBackGroundImageStyleFill(): { [key: string]: string } {
    if (!this.appConfigHasBackgroundImagePath()) {
      return {};
    }

    return {
      'backgroundImage': `url(${AppConfig.styleSettings.backgroundImagePath})`,
      'background-size': 'cover',
      'background-position': 'center',
      'min-height': '100vh',
    };
  }

  /**
   * This is good for a smaller image and will place it in the center of the screen.
   * @returns { [key: string]: string } - the ngStyle object for the background image
   */
  public static getBackGroundImageStyleCenter(): { [key: string]: string } {
    if (!this.appConfigHasBackgroundImagePath()) {
      return {};
    }

    return {
      'backgroundImage': `url(${AppConfig.styleSettings.backgroundImagePath})`,
      'background-size': 'contain',
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'min-height': '100vh',
    };
  }

  /**
   * This is good for a smaller image and will repeat it over and over to fill the space.
   * @returns { [key: string]: string } - the ngStyle object for the background image
   */
  public static getBackGroundImageStyleRepeat(): { [key: string]: string } {
    if (!this.appConfigHasBackgroundImagePath()) {
      return {};
    }

    return {
      'backgroundImage': `url(${AppConfig.styleSettings.backgroundImagePath})`,
      'background-size': 'contain',
      'background-position': 'center',
      'min-height': '100vh',
    };
  }

  protected static appConfigHasBackgroundImagePath(): boolean {
    if (!AppConfig) {
      return false;
    }
    if (!AppConfig.styleSettings) {
      return false;
    }
    if (!AppConfig.styleSettings.backgroundImagePath) {
      return false;
    }
    return true;
  }

  // #region Image Dock Theme Settings
  public static getImageDockPositionSetting(): DockPosition {
    if (AppConfig && AppConfig.style && AppConfig.style === "image-dock" && ConfigHelpers.isImageDockSettings(AppConfig.styleSettings)) {
      return AppConfig.styleSettings.dockPosition;
    }
    return "top";
  }

  public static getCdnUrl(): string {
    return AppConfig.cdnUrl;
  }

  public static getImageDockBackgroundTheme(): DockBackgroundTheme {
    if (AppConfig && AppConfig.style === "image-dock" && ConfigHelpers.isImageDockSettings(AppConfig.styleSettings)) {
      if (AppConfig.styleSettings.dockBackgroundTheme !== "dark" &&
          AppConfig.styleSettings.dockBackgroundTheme !== "light" &&
          AppConfig.styleSettings.dockBackgroundTheme !== "transparent-dark" &&
          AppConfig.styleSettings.dockBackgroundTheme !== "transparent-light") {
        console.error("Invalid dock background theme.  Valid themes are 'dark', 'light', 'transparent-dark', and 'transparent-light'.  Defaulting to 'transparent-dark'.");
        return "transparent-dark";
      } else {
        return AppConfig.styleSettings.dockBackgroundTheme;
      }

    }

    console.error("Returning default dock background theme. Style was not image-dock or styleSettings was not defined " +
                  "or could not be asserted as ImageDockThemeSettings.");
    return "transparent-dark";
  }

  public static getImageDockExtendedFooterSetting(): boolean {
    if (AppConfig && AppConfig.style && ConfigHelpers.isImageDockSettings(AppConfig.styleSettings)) {
      return AppConfig.styleSettings.hasOwnProperty('extendedFooter')
        ? AppConfig.styleSettings.extendedFooter
        : false;
    }

    return false;
  }

  public static getImageDockBackgroundThemeSetting(): DockBackgroundTheme {
    if (AppConfig && AppConfig.style && ConfigHelpers.isImageDockSettings(AppConfig.styleSettings)) {
      return AppConfig.styleSettings.hasOwnProperty('dockBackgroundTheme')
        ? AppConfig.styleSettings.dockBackgroundTheme
        : "transparent-dark";
    }

    return "transparent-dark";
  }

  /**
   * The root level for image docks are svgs. This uses css filters to change the color of the svgs.
   * @returns {ImageFilters} - the filter to apply to the dock icons.
   */
  public static getImageDockIconColorSetting(): ImageFilters {
    if (AppConfig && AppConfig.style && ConfigHelpers.isImageDockSettings(AppConfig.styleSettings)) {
      if (AppConfig.styleSettings.dockColor === "red") {
        return IMAGE_FILTERS.red;
      } else if (AppConfig.styleSettings.dockColor === "darkBlue") {
        return IMAGE_FILTERS.darkBlue;
      } else if (AppConfig.styleSettings.dockColor === "lightBlue") {
        return IMAGE_FILTERS.lightBlue;
      } else if (AppConfig.styleSettings.dockColor === "darkGreen") {
        return IMAGE_FILTERS.darkGreen;
      } else if (AppConfig.styleSettings.dockColor === "lightGreen") {
        return IMAGE_FILTERS.lightGreen;
      } else if (AppConfig.styleSettings.dockColor === "purple") {
        return IMAGE_FILTERS.purple;
      } else if (AppConfig.styleSettings.dockColor === "pink") {
        return IMAGE_FILTERS.pink;
      }
    }

    return IMAGE_FILTERS.black;
  }

  /** Type assertion since settings can be StandardThemeSettings or ImageDockThemeSettings */
  protected static isImageDockSettings(settings: StandardThemeSettings | ImageDockThemeSettings): settings is ImageDockThemeSettings {
    if (!settings) {
      return false;
    }

    return (
      (settings as ImageDockThemeSettings).dockPosition !== undefined ||
      (settings as ImageDockThemeSettings).dockBackgroundTheme !== undefined ||
      (settings as ImageDockThemeSettings).extendedFooter !== undefined
    );
  }
  // #endregion
}


export type ImageFilters = typeof IMAGE_FILTERS[keyof typeof IMAGE_FILTERS];
export const IMAGE_FILTERS = {
    red: "invert(50%) sepia(100%) saturate(10000%) hue-rotate(16deg)",
    darkBlue: "invert(50%) sepia(100%) saturate(10000%) hue-rotate(233deg)",
    lightBlue: "invert(50%) sepia(100%) saturate(1000%) hue-rotate(180deg)",
    darkGreen: "invert(50%) sepia(100%) saturate(10000%) hue-rotate(131deg)",
    lightGreen: "invert(50%) sepia(100%) saturate(1000%) hue-rotate(60deg)",
    purple: "invert(50%) sepia(100%) saturate(10000%) hue-rotate(277deg)",
    pink: "invert(50%) sepia(100%) saturate(1000%) hue-rotate(274deg)",
    black: ""
} as const;