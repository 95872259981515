// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.json-view a {
  cursor: pointer;
  text-decoration: none;
}
.json-view .toggler {
  width: 1em;
  padding: 0;
  display: block;
  text-align: center;
  float: left;
}
.json-view .collapse::before {
  content: "-";
}
.json-view .expand::before {
  content: "+";
}
.json-view .key {
  color: #92278f;
}
.json-view .value {
  color: #000000;
}
.json-view .value .string {
  color: #3ab54a;
}
.json-view .value .number {
  color: #25aae2;
}
.json-view .value .boolean {
  color: #aa0d91;
}
.json-view .value .null {
  color: #f1592a;
}
.json-view .children {
  margin-left: 1em;
}`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/json-view/json-view.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,qBAAA;AAAJ;AAGE;EACE,UAAA;EACA,UAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAME;EACE,cAAA;AAJJ;AAOE;EACE,cAAA;AALJ;AAOI;EACE,cAAA;AALN;AAQI;EACE,cAAA;AANN;AASI;EACE,cAAA;AAPN;AAUI;EACE,cAAA;AARN;AAYE;EACE,gBAAA;AAVJ","sourcesContent":[".json-view {\r\n  a {\r\n    cursor: pointer;\r\n    text-decoration: none;\r\n  }\r\n\r\n  .toggler {\r\n    width: 1em;\r\n    padding: 0;\r\n    display: block;\r\n    text-align: center;\r\n    float: left;\r\n  }\r\n\r\n  .collapse::before {\r\n    content: \"-\";\r\n  }\r\n\r\n  .expand::before {\r\n    content: \"+\";\r\n  }\r\n\r\n  .key {\r\n    color: #92278f;\r\n  }\r\n\r\n  .value {\r\n    color: #000000;\r\n\r\n    .string {\r\n      color: #3ab54a;\r\n    }\r\n\r\n    .number {\r\n      color: #25aae2;\r\n    }\r\n\r\n    .boolean {\r\n      color: #aa0d91;\r\n    }\r\n\r\n    .null {\r\n      color: #f1592a;\r\n    }\r\n  }\r\n\r\n  .children {\r\n    margin-left: 1em;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
