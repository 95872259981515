import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { EventModelTyped } from '../../ux-models';

@Component({
  selector: 'ib-grid-size-selector',
  templateUrl: './grid-size-selector.component.html',
  styleUrls: ['./grid-size-selector.component.css']
})

/**
 * A component that displays a grid of squares. As the mouse hovers over squares, every
 * square within that row and column section will be highlighted. When a
 * grid square is clicked, the number of rows and columns that were selected is emitted.
 * Basically our version of how Microsoft Word does their table size selector.
 */
export class GridSizeSelectorComponent extends BaseComponent implements OnInit {

  protected _numColumns: number = 5;
  /** The number of columns the grid will have. */
  @Input() set numColumns(value: number) {
    if (value <= 0) {
      // Protect against / and * by zero error.
      this._numColumns = 1;
    } else {
      this._numColumns = value;
    }
  };
  get numColumns(): number {
    return this._numColumns;
  }
  protected _numRows: number = 5;
  /** The number of rows the grid will have. */
  @Input() set numRows(value: number) {
    if (value <= 0) {
      // Protect against / and * by zero error.
      this._numRows = 1;
    } else {
      this._numRows = value;
    }
  };
  get numRows(): number {
    return this._numRows;
  }

  /** The width of each grid square. */
  @Input() width: string = '25px';
  /** The height of each grid square. */
  @Input() height: string = '25px';
  /** The border width of each grid square. */
  @Input() borderWidth: string = '2px';
  /** The background color of each grid square. */
  @Input() backgroundColor: string = 'white';
  /** The background color of a grid square to show its part of the selection. */
  @Input() highlightedBackgroundColor: string = "#0d6efd";
  /** The border color of each grid square. */
  @Input() borderColor: string = "black";
  /** The border color of a grid square to show its part of the selection. */
  @Input() highlightedBorderColor: string = "#0d6efd";
  /** A label to go after '3 x 2' or whatever the selection is */
  @Input() gridLabel: string = "Grid";

  /** Emits that a grid square was clicked. Passes the number of rows and columns that were selected. */
  @Output() gridSquareClick: EventEmitter<EventModelTyped<GridSquareClickEventData>> = new EventEmitter();


  /** The array that the html grid populates from. */
  gridSquares: number[] = Array(this.numColumns * this.numRows).fill(0);
  /** The index that the mouse is currently over, or was last over if they mouse out of the grid. */
  highlightedIndex: number | null = null;

  /** 0 Based index. Used to determine what should be highlighted.  */
  columnIndex: number = 0;
  /** 1 Based index to display to the user how many columns are selected. */
  columnIndexUI: number = 1;
  /** 0 Based index. Used to determine what should be highlighted.  */
  rowIndex: number = 0;
  /** 1 Based index to display to the user how many rows are selected. */
  rowIndexUI: number = 1;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.numRows || changes.numColumns) {
      this.gridSquares = Array(this.numColumns * this.numRows).fill(0);
    }
  }

  highlightSquares(index: number) {
    this.highlightedIndex = index;
    this.columnIndex = this.highlightedIndex % this.numColumns;
    this.columnIndexUI = this.columnIndex + 1;
    this.rowIndex = Math.floor(index / this.numColumns);
    this.rowIndexUI = this.rowIndex + 1;
  }

  onGridSquareClick($event) {
    const data: GridSquareClickEventData = { NumRows: this.rowIndexUI, NumColumns: this.columnIndexUI };
    const payload = new EventModelTyped<GridSquareClickEventData>("gridSquareClick", $event, data, null, null);
    this.gridSquareClick.emit(payload);
  }
}

export interface GridSquareClickEventData {
  NumRows: number,
  NumColumns: number
};
