import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { EventModel, EventElementModel } from '../../ux-models';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCheckboxesFromPickListComponent),
  multi: true
};

@Component({
  selector: 'ib-input-checkboxes-from-pick-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-checkboxes-from-pick-list.component.html',
  styleUrls: ['./input-checkboxes-from-pick-list.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputCheckboxesFromPickListComponent extends InputBaseComponent implements OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  /**
   * Bind to data instead of ngModel and pick up changes via (change) event's $event.data property.
   */
  @Input() data: string[] = [];

  /**
   * When true inline checkboxes are used.
   */
  @Input() inline: boolean = false;

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  // ngOnInit() {
  //   super.ngOnInit();
  // }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    // console.error("pick list id", this.optionsPickListId);

    if (!this.vertical) {
      this.inputWrapperClass += " form-check";
    }

    if (this.inline) {
      // For inline remove any other classes
      this.inputWrapperClass = "form-check form-check-inline";
    }

  }


  onCheckboxClick($event, value: string) {
    // console.error("click", $event.target.checked, value);
    if ($event && $event.target) {
      if (!this.data) {
        this.data = [];
      }
      if ($event.target.checked) {
        if (this.data.indexOf(value) === -1) {
          this.data.push(value);
          const payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
          this.change.emit(payload);
        }
      } else {
        if (this.data.indexOf(value) > -1) {
          this.data = this.data.filter(x => x !== value);
          const payload: EventModel = new EventModel("change", $event, this.data, new EventElementModel("input", this.inputControlId, this.name, this.label, this.placeholder));
          this.change.emit(payload);
        }
      }
    }
  }

}
