import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ContentChild } from '@angular/core';
import { EventModel } from '../../ux-models';

@Component({
  selector: 'ib-peek-with-header',
  templateUrl: './peek-with-header.component.html',
  styleUrls: ['./peek-with-header.component.css']
})
export class PeekWithHeaderComponent implements OnInit {

  @Input() class: string = "";
  @Input() visible: boolean = false;
  @Input() clearFloats: boolean = true;
  @Input() buttonWrapperClass: string = "";
  @Input() buttonWrapperStyle: string = "";
  @Input() buttonClass: string = "";
  @Input() buttonStyle: string = "";

  @Output() click: EventEmitter<EventModel> = new EventEmitter();

  constructor() { }

  @ContentChild('header', { static: false }) header: ElementRef;

  ngOnInit() {
  }

  onClick($event) {
    if ($event.data != null) {
      const payload: EventModel = new EventModel("change", $event, this.header.nativeElement);
      this.click.emit(payload);
    }
  }

}
