// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';


/**
 * @name ApiAccessClientEditViewModel
 * @description
 * The ApiAccessClient table contains ip address and domain names that are valid for a given api access.  If no clients are
 * available for a given api access only valid partition domains are granted access.
 */
export class ApiAccessClientEditViewModel {

    /**
     * @name ApiAccessClientId
     * @description
     * Unique identifier for this api access client.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApiAccessClientId: number = null;

    /**
     * @name ApiAccessId
     * @description
     * The api access id this client belongs to.
     * @servertype Int64
     * @type {number}
     */
    ApiAccessId: number = null;

    /**
     * @name Description
     * @description
     * The description of this api access client.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ClientIdentifierType
     * @description
     * The type of client identifier.  Possible values include:
     * D = Domain Name
     * 4 = IP v4 Address
     * 6 = IP v6 Address
     * N = No Identifier Required (not recommended)
     * @servertype String
     * @type {string}
     */
    ClientIdentifierType: string = "D";

    /**
     * @name ClientIdentifier
     * @description
     * The ip address or domain name of this api client.  API calls from clients not in this client table will be rejected unless
     * there is a row with client identifier type of not required.  Note that wildcard values are accepted as are ip address ranges
     * when separated by a hyphen.  When client identifier type is domain name an empty value here means all valid partition domains
     * are accepted for access.
     * @servertype String
     * @type {string}
     */
    ClientIdentifier: string = "";

    /**
     * @name ClientType
     * @description
     * The type of client.  Possible values include:
     * D = Development
     * T = Test
     * P = Production
     * M = Mixed Use
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ClientType: string = "U";

    /**
     * @name DebugUntilDateTime
     * @description
     * When not null include debug information in api responses.  Note that clients with client type of production will only be
     * allowed to set this for a limited period of time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DebugUntilDateTime: Date = null;

    /**
     * @name Flags
     * @description
     * A list of flags regarding the api access.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name SuspendedOnDateTime
     * @description
     * The date and time this api access was suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedOnDateTime: Date = null;

    /**
     * @name SuspendedByContactId
     * @description
     * The Contact Id of the contact suspending this api access.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SuspendedByContactId: number = null;

    /**
     * @name SuspendedByContactName
     * @servertype String
     * @type {string}
     */
    SuspendedByContactName: string = "";

    /**
     * @name SuspendedUntilDateTime
     * @description
     * When not null the api key is suspended until this date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedUntilDateTime: Date = null;

    /**
     * @name SuspendedReason
     * @description
     * When the suspended until date time is not null this is the reason.
     * @servertype String
     * @type {string}
     */
    SuspendedReason: string = "";

    /**
     * @name Enabled
     * @description
     * When true the api access is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Properties
     * @description
     * Properties related to this api access client.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApiAccessEditViewModel
 * @description
 * The ApiAccess table contains api keys and parameters regarding api access.
 */
export class ApiAccessEditViewModel {

    /**
     * @name ApiAccessId
     * @description
     * Unique identifier for this api access.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApiAccessId: number = null;

    /**
     * @name ApiKey
     * @description
     * The key used to access the application programming interface.  This is typically in the format x-y where x is the base36
     * encoded partition id and y is a unique random string.
     * @servertype String
     * @type {string}
     */
    ApiKey: string = "";

    /**
     * @name Description
     * @description
     * The description of this api access.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ContactId
     * @description
     * The Contact Id of the contact owning this API key.  It dictates the security context for the API calls unless a role id is
     * provided.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name RoleId
     * @description
     * When specified this role id is used for security context instead of the roles defined by the contact id.  This is useful
     * for special purpose API keys for specific apps, anonymous access, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name Anonymous
     * @description
     * When true this api key is used when anonymous access is requested and grants the rights associated with the specified role
     * id or contact id when no role id is specified.  Anonymous access is needed for access to basic branding or anonymous sign
     * up, registration, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

    /**
     * @name ShortTermMinutes
     * @description
     * When not zero the api key is a short term api key valid for the specified number of minutes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ShortTermMinutes: number = 0;

    /**
     * @name EncryptionType
     * @description
     * The encryption type used for API calls that support encryption.  Possible values include:
     * N = None
     * S = Symmetric
     * @servertype String
     * @type {string}
     */
    EncryptionType: string = "N";

    /**
     * @name EncryptionKey
     * @description
     * The encryption key used for API calls that support encryption.
     * @servertype String
     * @type {string}
     */
    EncryptionKey: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags regarding the api access.  Possible uses include:
     * For api keys utilized in web hook targets the flags must to contain 'webhook' as well as the allowed webhook types. For example,
     * if using this for Amazon Payment API IPN the url for posts might look like this: '/webhooks/target/78-sapfvi-3f8sfz-sa8fubg8/amazon-ipn'
     * and the flags for api key 78-sapfvi-3f8sfz-sa8fubg8 would contain: 'webhook,amazon-ipn'.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name RateLimitPerMinute
     * @description
     * The maximum number of API calls allowed per minute.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RateLimitPerMinute: number = 0;

    /**
     * @name RateLimitPerHour
     * @description
     * The maximum number of API calls allowed per hour.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RateLimitPerHour: number = 0;

    /**
     * @name SuspendedOnDateTime
     * @description
     * The date and time this api access was suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedOnDateTime: Date = null;

    /**
     * @name SuspendedByContactId
     * @description
     * The Contact Id of the contact suspending this api access.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SuspendedByContactId: number = null;

    /**
     * @name SuspendedByContactName
     * @servertype String
     * @type {string}
     */
    SuspendedByContactName: string = "";

    /**
     * @name SuspendedUntilDateTime
     * @description
     * When not null the api key is suspended until this date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedUntilDateTime: Date = null;

    /**
     * @name SuspendedReason
     * @description
     * When the suspended until date time is not null this is the reason.
     * @servertype String
     * @type {string}
     */
    SuspendedReason: string = "";

    /**
     * @name Enabled
     * @description
     * When true the api access is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Properties
     * @description
     * Properties related to this api access.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Clients
     * @description
     * List of clients for the api access.
     * @servertype ApiAccessClientEditViewModel array
     * @type {ApiAccessClientEditViewModel[]}
     */
    Clients: ApiAccessClientEditViewModel[] = [];

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApplicationInformationModel
 */
export class ApplicationInformationModel {

    /**
     * @name PartitionId
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionDescription
     * @servertype String
     * @type {string}
     */
    PartitionDescription: string = "";

    /**
     * @name Partition
     * @servertype IB.Data.Model.Wallet.v5.PartitionEditViewModel
     * @type {PartitionEditViewModel}
     */
    Partition: PartitionEditViewModel = null;

    /**
     * @name Domain
     * @servertype System.Object
     * @type {any}
     */
    Domain: any = {};

    /**
     * @name Branding
     * @servertype IB.Data.Model.PartitionBrandModel
     * @type {m.PartitionBrandModel}
     */
    Branding: m.PartitionBrandModel = null;

    /**
     * @name Tags
     * @servertype Dictionary<string, List<string>>
     * @type {{[key: string]:  string[]}}
     */
    Tags: {[key: string]:  string[]} = {};

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name AnonymousApiKey
     * @servertype String
     * @type {string}
     */
    AnonymousApiKey: string = "";

    /**
     * @name Theme
     * @servertype String
     * @type {string}
     */
    Theme: string = "";

    /**
     * @name LogoUrl
     * @servertype String
     * @type {string}
     */
    LogoUrl: string = "";

    /**
     * @name DomainName
     * @servertype String
     * @type {string}
     */
    DomainName: string = "";

    /**
     * @name SslEnabled
     * @servertype Boolean
     * @type {boolean}
     */
    SslEnabled: boolean = false;

    /**
     * @name SslRequired
     * @servertype Boolean
     * @type {boolean}
     */
    SslRequired: boolean = false;

    /**
     * @name Services
     * @servertype ApplicationInformationServiceModel array
     * @type {ApplicationInformationServiceModel[]}
     */
    Services: ApplicationInformationServiceModel[] = [];

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

    /**
     * @name Routes
     * @servertype ApplicationInformationRouteModel array
     * @type {ApplicationInformationRouteModel[]}
     */
    Routes: ApplicationInformationRouteModel[] = [];

    /**
     * @name Logos
     * @servertype ApplicationInformationLogoModel array
     * @type {ApplicationInformationLogoModel[]}
     */
    Logos: ApplicationInformationLogoModel[] = [];

    /**
     * @name Settings
     * @servertype System.Object
     * @type {any}
     */
    Settings: any = {};

}



/**
 * @name PartitionEditViewModel
 * @description
 * The Partition table contains information about table partitions typically done in a multi-company scenario where multiple
 * databases is not desired but data needs to be partitioned or in a multi-tenant environment where each partition represents
 * a client.  Note that this represents a logical and not necessarily a physical partition.
 */
export class PartitionEditViewModel {

    /**
     * @name PartitionId
     * @description
     * Unique value that represents this partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Description
     * @description
     * The description of this partition.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Brand
     * @description
     * The brand for this partition.
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name CustomerName
     * @description
     * The customer name for this partition.
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name ContactName
     * @description
     * The contact name for this partition.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactPhone
     * @description
     * The contact phone for this partition.
     * @servertype String
     * @type {string}
     */
    ContactPhone: string = "";

    /**
     * @name ContactEmail
     * @description
     * The contact email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name ContactAlternateEmail
     * @description
     * The contact alternate email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactAlternateEmail: string = "";

    /**
     * @name ProductionPartitionId
     * @description
     * The partition id used as production partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProductionPartitionId: number = null;

    /**
     * @name SandboxPartitionId
     * @description
     * The partition id used as sandbox partition for testing.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SandboxPartitionId: number = null;

    /**
     * @name PrimaryShardId
     * @description
     * The shard id for the primary table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PrimaryShardId: number = 0;

    /**
     * @name ReportShardId
     * @description
     * The shard id for the report table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReportShardId: number = 0;

    /**
     * @name CacheShardId
     * @description
     * The cache id for the cache table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CacheShardId: number = 0;

    /**
     * @name LogShardId
     * @description
     * The shard id for the log table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LogShardId: number = 0;

    /**
     * @name ActivityShardId
     * @description
     * The shard id for the activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActivityShardId: number = 0;

    /**
     * @name ArchiveActivityShardId
     * @description
     * The shard id for the archive activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveActivityShardId: number = 0;

    /**
     * @name ArchiveShardId
     * @description
     * The shard id for the archive table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveShardId: number = 0;

    /**
     * @name Properties
     * @description
     * Properties for the partition including:
     * AllowAnonymousAccess - when true unauthenticated users are not forced to the login page.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApplicationInformationServiceModel
 */
export class ApplicationInformationServiceModel {

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Class
     * @servertype String
     * @type {string}
     */
    Class: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

}



/**
 * @name ApplicationInformationRouteModel
 */
export class ApplicationInformationRouteModel {

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RouteUrl
     * @servertype String
     * @type {string}
     */
    RouteUrl: string = "";

    /**
     * @name Template
     * @servertype String
     * @type {string}
     */
    Template: string = "";

    /**
     * @name TemplateUrl
     * @servertype String
     * @type {string}
     */
    TemplateUrl: string = "";

    /**
     * @name RedirectToUrl
     * @servertype String
     * @type {string}
     */
    RedirectToUrl: string = "";

    /**
     * @name Controller
     * @servertype String
     * @type {string}
     */
    Controller: string = "";

    /**
     * @name ControllerAs
     * @servertype String
     * @type {string}
     */
    ControllerAs: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name HideStandardSiteElements
     * @servertype Boolean
     * @type {boolean}
     */
    HideStandardSiteElements: boolean = false;

}



/**
 * @name ApplicationInformationLogoModel
 */
export class ApplicationInformationLogoModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Url
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name Height
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name Width
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

}



/**
 * @name Authenticated User
 * @description
 * This holds information about an authenticated user.
 */
export class AuthenticatedUserViewModel {

    /**
     * @name ContactType
     * @description
     * The contact type of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactId
     * @description
     * The contact id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @description
     * The name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactFirstName
     * @description
     * The first name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactFirstName: string = "";

    /**
     * @name ContactLastName
     * @description
     * The last name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactLastName: string = "";

    /**
     * @name ContactEmail
     * @description
     * The email address of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name PreferredLanguage
     * @description
     * The preferred language for the authenticated user.
     * @servertype String
     * @type {string}
     */
    PreferredLanguage: string = "";

    /**
     * @name ParentContactType
     * @description
     * The parent contact type of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ParentContactType: string = "";

    /**
     * @name ParentContactId
     * @description
     * The parent contact id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ParentContactName
     * @description
     * The parent name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ParentContactName: string = "";

    /**
     * @name PartitionId
     * @description
     * This partition id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionDescription
     * @description
     * A description of the authenticated user partition.
     * @servertype String
     * @type {string}
     */
    PartitionDescription: string = "";

    /**
     * @name Login
     * @description
     * The login name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Token
     * @description
     * The token for the authenticated user.
     * @servertype String
     * @type {string}
     */
    Token: string = "";

    /**
     * @name ApiKey
     * @description
     * If an api-key was used this is the api-key of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ApiKey: string = "";

    /**
     * @name Settings
     * @description
     * The user settings for the authenticated user.
     * @servertype IB.Data.Model.Wallet.v5.UserSettings
     * @type {UserSettings}
     */
    Settings: UserSettings = null;

    /**
     * @name Roles
     * @description
     * A collection of roles for the authenticated user.
     * @servertype AuthenticatedUserRoleViewModel array
     * @type {AuthenticatedUserRoleViewModel[]}
     */
    Roles: AuthenticatedUserRoleViewModel[] = [];

    /**
     * @name Permissions
     * @description
     * A collection of permissions for the authenticated user.
     * @servertype AuthenticatedUserPermissionViewModel array
     * @type {AuthenticatedUserPermissionViewModel[]}
     */
    Permissions: AuthenticatedUserPermissionViewModel[] = [];

    /**
     * @name AuthenticationData
     * @description
     * The authentication data that represents the user authentication results.
     * @servertype IB.Data.Model.v5.AuthenticationDataViewModel
     * @type {m.AuthenticationDataViewModel}
     */
    AuthenticationData: m.AuthenticationDataViewModel = null;

}



/**
 * @name User Settings
 * @description
 * This holds user settings information.
 */
export class UserSettings {

    /**
     * @name AsOfUtc
     * @description
     * A UTC timestamp that represents the age of the user settings data.  This can be used to determine if cached settings should
     * be refreshed.
     * @servertype DateTime
     * @type {Date}
     */
    AsOfUtc: Date = null;

    /**
     * @name Menu
     * @description
     * A collection of menu items appropriate for the user.  This is internal use data.
     * @servertype MenuItem array
     * @type {m.MenuItem[]}
     */
    Menu: m.MenuItem[] = [];

}



/**
 * @name Authenticated User Role
 * @description
 * This holds information about a role for an authenticated user.
 */
export class AuthenticatedUserRoleViewModel {

    /**
     * @name RoleId
     * @description
     * The role id of the role.
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = 0;

    /**
     * @name Description
     * @description
     * A description of the role.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RoleRatio
     * @description
     * A ratio that can be used to assign a 'weight' to the role for this user.
     * @servertype Int32
     * @type {number}
     */
    RoleRatio: number = 0;

    /**
     * @name Reasons
     * @description
     * A list of reasons the user was assigned this role.
     * @servertype AuthenticatedUserRoleReason array
     * @type {AuthenticatedUserRoleReason[]}
     */
    Reasons: AuthenticatedUserRoleReason[] = [];

}



/**
 * @name AuthenticatedUserRoleReason
 */
export class AuthenticatedUserRoleReason {

    /**
     * @name RoleReason
     * @description
     * The reason the user has this role.
     * @servertype IB.Data.Model.Wallet.v5.AuthenticatedUserReasonHasRole Enum
     * @type {AuthenticatedUserReasonHasRole}
     */
    RoleReason: AuthenticatedUserReasonHasRole = null;

    /**
     * @name RoleReasonDescription
     * @description
     * A description explaining why the user has this role.
     * @servertype String
     * @type {string}
     */
    RoleReasonDescription: string = "";

    /**
     * @name RoleReasonContactId
     * @description
     * If the role is assigned to this user or a group this user belongs to this is the contact id of the user or group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleReasonContactId: number = null;

}



/**
 * @name AuthenticatedUserReasonHasRole Enum
 * @readonly
 * @enum {number}
 */
export enum AuthenticatedUserReasonHasRole {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Assigned To Contact
     */
    AssignedToContact = 1,

    /**
     * Assigned To Group Contact Belongs To
     */
    AssignedToGroupContactBelongsTo = 2,

    /**
     * Assigned To Contact Type Automatically
     */
    AssignedToContactTypeAutomatically = 3

}



/**
 * @name Authenticated User Permission
 * @description
 * This holds information about a permission for an authenticated user.
 */
export class AuthenticatedUserPermissionViewModel {

    /**
     * @name AreaType
     * @servertype String
     * @type {string}
     */
    AreaType: string = "";

    /**
     * @name Area
     * @servertype String
     * @type {string}
     */
    Area: string = "";

    /**
     * @name Rights
     * @servertype String
     * @type {string}
     */
    Rights: string = "";

    /**
     * @name Roles
     * @description
     * A list of roles that contributed to this permission setting.
     * @servertype AuthenticatedUserRoleViewModel array
     * @type {AuthenticatedUserRoleViewModel[]}
     */
    Roles: AuthenticatedUserRoleViewModel[] = [];

    /**
     * @name ContributingRoles
     * @description
     * A string of comma separated roles that contributed to this permission setting.
     * @servertype String
     * @type {string}
     */
    ContributingRoles: string = "";

    /**
     * @name ImplicitAccess
     * @description
     * When true the permission was granted as part of implicit access rights.
     * @servertype Boolean
     * @type {boolean}
     */
    ImplicitAccess: boolean = false;

    /**
     * @name Comments
     * @description
     * Comments regarding this security area assignment.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

}



/**
 * @name ContactAccessEditViewModel
 * @description
 * The Contact Access table contains a list of access identifiers used by a contact when accessing the system.  It can be used
 * to provide historical context to a system access request and allow an application to decide to accept a token based access
 * request or to ask for login or password authentication again.  Then depending on desired security settings existing tokens
 * can be revoked, access can be challenged, or a notification of access can be sent.
 */
export class ContactAccessEditViewModel {

    /**
     * @name ContactAccessId
     * @description
     * The id of the Contact Access record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactAccessId: number = null;

    /**
     * @name ContactId
     * @description
     * The id of the contact this access record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name AccessIdentifierType
     * @description
     * The identifier type for this access record.  Possible values include:
     * I = Public IP Address
     * D = Device Id
     * @servertype String
     * @type {string}
     */
    AccessIdentifierType: string = "";

    /**
     * @name AccessIdentifier
     * @description
     * The access identifier of the specified type.
     * @servertype String
     * @type {string}
     */
    AccessIdentifier: string = "";

    /**
     * @name AccessLocation
     * @description
     * If the access identifier has an identifiable location the location is stored here.
     * @servertype String
     * @type {string}
     */
    AccessLocation: string = "";

    /**
     * @name AccessLocationDetail
     * @servertype String
     * @type {string}
     */
    AccessLocationDetail: string = "";

    /**
     * @name ActionLinkDateTime
     * @description
     * If system access required additional user action this is the date and time of that action.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActionLinkDateTime: Date = null;

    /**
     * @name ActionLinkId
     * @description
     * If system access required additional user action this is the id of the action link.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ActionLinkId: number = null;

    /**
     * @name LastUsedToken
     * @servertype String
     * @type {string}
     */
    LastUsedToken: string = "";

    /**
     * @name AccessCount
     * @description
     * The number of times this access identifier has been used by a login.  Note that token based access does not increment this
     * count.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AccessCount: number = 0;

    /**
     * @name LastAccessDateTime
     * @description
     * The date and time when this access identifier was last used by a login.  Note that token based access does not update this
     * value.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAccessDateTime: Date = null;

    /**
     * @name Suspect
     * @description
     * When true this access identifier has been identified as suspect by the system.
     * @servertype Boolean
     * @type {boolean}
     */
    Suspect: boolean = false;

    /**
     * @name BlockedAutomatically
     * @description
     * When true access from this access identifier has been blocked automatically by the system.
     * @servertype Boolean
     * @type {boolean}
     */
    BlockedAutomatically: boolean = false;

    /**
     * @name BlockedManually
     * @description
     * When true access from this access identifier has been manually blocked.  This can be used to black list an access point.
     * @servertype Boolean
     * @type {boolean}
     */
    BlockedManually: boolean = false;

    /**
     * @name Whitelisted
     * @description
     * When true this access identifier has been whitelisted to prevent any automatic blocking the system may attempt.
     * @servertype Boolean
     * @type {boolean}
     */
    Whitelisted: boolean = false;

    /**
     * @name NotificationDateTime
     * @description
     * If system access triggered a notification to the contact this is the date and time of that notification.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationDateTime: Date = null;

    /**
     * @name NotificationEventId
     * @description
     * If system access triggered a notification to the contact this is the notification event id for that notification.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationEventId: number = null;

    /**
     * @name Properties
     * @description
     * Properties related to this access.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactEditViewModel
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class ContactEditViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ParentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ParentContactName
     * @servertype String
     * @type {string}
     */
    ParentContactName: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ExternalContactId
     * @servertype String
     * @type {string}
     */
    ExternalContactId: string = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name Title
     * @description
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Country
     * @description
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Extension
     * @description
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Fax
     * @description
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name Cellular
     * @description
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name Email
     * @description
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name Login
     * @description
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Password
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name PasswordChangedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    PasswordChangedDateTime: Date = null;

    /**
     * @name RevokeAuthenticationDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RevokeAuthenticationDateTime: Date = null;

    /**
     * @name LoginFailedAttempts
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LoginFailedAttempts: number = 0;

    /**
     * @name LoginBlockedUntilDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LoginBlockedUntilDateTime: Date = null;

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name SystemTimeZoneId
     * @servertype String
     * @type {string}
     */
    SystemTimeZoneId: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Properties
     * @description
     * Properties for this contact.  The format is dependent on the contact type of the contact.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactExternalAuthenticationEditViewModel
 * @description
 * The Contact External Authentication table contains a list of values for various pick lists used in the system.
 */
export class ContactExternalAuthenticationEditViewModel {

    /**
     * @name ContactExternalAuthenticationId
     * @description
     * The id of the Contact External Authentication.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactExternalAuthenticationId: number = null;

    /**
     * @name ContactId
     * @description
     * The id of the contact this external authentication record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name AuthenticationService
     * @description
     * The authentication service for this authentication record.  Possible values include:
     * TOTP (Time-Based One-Time Password)
     * MFA (used for MFA purposes to provide up to 5 additional phone numbers and email addresses)
     * Partner:* (A partner auth service where * represents the partner name up to 12 characters long)
     * OperatingSystem (windows domain controller, windows server, LDAP, etc.)
     * Google
     * Facebook
     * Microsoft
     * Twitter
     * LinkedIn
     * Yahoo
     * Dropbox
     * Instagram
     * Foursquare
     * Flickr
     * @servertype String
     * @type {string}
     */
    AuthenticationService: string = "";

    /**
     * @name UserId
     * @description
     * The id of the user.  This is provided by the external authentication service.
     * When service is TOTP this is the base32 shared secret that must be supplied to the authenticator client.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    UserId: string = "";

    /**
     * @name UserName
     * @description
     * The user name.
     * When service is TOTP this is the user name to use for the user.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    UserName: string = "";

    /**
     * @name DisplayName
     * @description
     * The display name.
     * When service is TOTP this is the application display name to use which is typically the app brand.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name FirstName
     * @description
     * The first name.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @description
     * The last name.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Email
     * @description
     * The email address.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @description
     * The second email address.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name Language
     * @description
     * The user language setting.
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name ProfileUrl
     * @description
     * The URL of the user profile.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    ProfileUrl: string = "";

    /**
     * @name ImageUrl
     * @description
     * The URL of the user image.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    ImageUrl: string = "";

    /**
     * @name ThumbnailUrl
     * @description
     * The URL of the user thumbnail.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    ThumbnailUrl: string = "";

    /**
     * @name AuthenticationCount
     * @description
     * The number of times this authentication has been performed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AuthenticationCount: number = 0;

    /**
     * @name LastAuthenticationDateTime
     * @description
     * The date and time when this authentication was last done.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAuthenticationDateTime: Date = null;

    /**
     * @name Properties
     * @description
     * Properties related to this external authentication.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactRoleEditViewModel
 * @description
 * The Contact Role table contains roles for contacts.  Roles are used in the assignment of security credentials, role-based
 * tasks for issues, access to reports, etc.
 */
export class ContactRoleEditViewModel {

    /**
     * @name ContactRoleId
     * @description
     * The id of the Contact Role.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactRoleId: number = null;

    /**
     * @name ContactId
     * @description
     * The id of the contact this role record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name RoleId
     * @description
     * The id of the role assigned to the contact.
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name Enabled
     * @description
     * When true the role is enabled.  Setting to false provides an easy way to cause a role to be temporarily ignored until later
     * enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this role assignment is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this role assignment is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name RoleRatio
     * @description
     * Integer that represents the ratio of time spent in this role.  When doing role based assignments a person is chosen from
     * the role list using a load balancing method.  For individuals who have more than one role in an organization a ratio is used
     * to determine how much time is spent in any given role.  For example, an individual who had 2 roles, role A and role B but
     * spent 75% of his time doing work in role A would have his role A ratio set to 3 while his role B ratio was set to 1.  Note
     * that null and zero ratio values are treated as 1.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RoleRatio: number = 1;

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Encryption Echo
 * @description
 * This contains information about an encryption echo request or response.
 */
export class EncryptionEchoEditViewModel {

    /**
     * @name Tag
     * @description
     * The tag that represents the encryption key.
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name PlainText
     * @description
     * In a request this is the plain text to echo back as encrypted text.  In a response this is the plain text version of submitted
     * encrypted text.
     * @servertype String
     * @type {string}
     */
    PlainText: string = "";

    /**
     * @name EncryptedText
     * @description
     * In a request this is the encrypted text to echo back as plain text.  In a response this is the encrypted text version of
     * submitted plain text.
     * @servertype String
     * @type {string}
     */
    EncryptedText: string = "";

}



/**
 * @name Encryption Key
 * @description
 * This contains information about an encryption key.
 */
export class EncryptionViewModel {

    /**
     * @name Tag
     * @description
     * The tag that represents this encryption key.
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name Description
     * @description
     * A description for this encryption key.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name KeyType
     * @description
     * The encryption key type.  Possible values include: RSA, AES, Secret, and VisaCheckout.
     * @servertype String
     * @type {string}
     */
    KeyType: string = "";

    /**
     * @name KeyLength
     * @description
     * The length of the encryption key.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    KeyLength: number = 0;

    /**
     * @name PublicKey
     * @description
     * The public key.
     * @servertype String
     * @type {string}
     */
    PublicKey: string = "";

    /**
     * @name PrivateKey
     * @description
     * The private key.  This is null for RSA and VisaCheckout key types.
     * @servertype String
     * @type {string}
     */
    PrivateKey: string = "";

    /**
     * @name Comments
     * @description
     * Any comments regarding the encryption key.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name AddedDateTime
     * @description
     * The date and time this key was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AddedDateTime: Date = null;

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name MessageExchangeEditViewModel
 * @description
 * The message exchange table contains security protocols, keys, etc. used for message exchange.
 */
export class MessageExchangeEditViewModel {

    /**
     * @name MessageExchangeId
     * @description
     * The id of the message exchange row.
     * @servertype String
     * @type {string}
     */
    MessageExchangeId: string = null;

    /**
     * @name Description
     * @description
     * Description for this message exchange data.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Configuration
     * @description
     * The configuration (if any) for the specified protocol.
     * For PGP this is a json representation of an EncryptionPgpConfigurationModel object.
     * @servertype String
     * @type {string}
     */
    Configuration: string = "";

    /**
     * @name KeyType
     * @description
     * Encryption or signing key type.  Possible values include:
     * RSA = RSA private/public key encryption.
     * AES = AES symmetric encryption.
     * SECRET = shared secret encryption with corresponding shared secret key.
     * PGP = OpenPGP encryption.
     * VisaCheckout = Visa Checkout api key / shared secret encryption.
     * @servertype String
     * @type {string}
     */
    KeyType: string = "";

    /**
     * @name KeyLength
     * @description
     * The length of the key (e.g. 2048).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    KeyLength: number = 0;

    /**
     * @name PublicKey
     * @description
     * The public key.
     * For RSA the public key is stored in the format: <RSAKeyValue><Modulus>xyz/mod==</Modulus><Exponent>exp</Exponent></RSAKeyValue>.
     * For PGP the public key is stored in ASCII Armor format.
     * For key and shared secret combinations the key is stored here.
     * @servertype String
     * @type {string}
     */
    PublicKey: string = "";

    /**
     * @name PrivateKey
     * @description
     * The private key stored as a base64 encoded string.
     * For symmetric encryption types the key is stored here.
     * For PGP the secret key is stored in ASCII Armor format.
     * For key and shared secret combinations the shared secret is stored here.
     * @servertype String
     * @type {string}
     */
    PrivateKey: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name MetaDataViewModel
 */
export class MetaDataViewModel {

    /**
     * @name AddedAt
     * @description
     * The date and time when this object was added.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AddedAt: Date = null;

    /**
     * @name AddedBy
     * @description
     * The id of the contact who added this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AddedBy: number = 0;

    /**
     * @name EditedAt
     * @description
     * The date and time when this object was last edited.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EditedAt: Date = null;

    /**
     * @name EditedBy
     * @description
     * The id of the contact who last edited this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EditedBy: number = 0;

    /**
     * @name DeletedAt
     * @description
     * The date and time when this object was marked for deletion.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeletedAt: Date = null;

    /**
     * @name DeletedBy
     * @description
     * The id of the contact who marked this object for deletion.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DeletedBy: number = 0;

    /**
     * @name ObjVersion
     * @description
     * The current row version for this object.
     * @servertype String
     * @type {string}
     */
    ObjVersion: string = "";

    /**
     * @name ObjId
     * @description
     * An identifier for this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ObjId: number = 0;

    /**
     * @name ObjHashCode
     * @description
     * The current hash code for this object.
     * @servertype String
     * @type {string}
     */
    ObjHashCode: string = "";

    /**
     * @name ObjSource
     * @description
     * The name of the data source table for this object.
     * @servertype String
     * @type {string}
     */
    ObjSource: string = "";

    /**
     * @name ObjTypesIncluded
     * @description
     * A list of included data object types provided with this object.  This can be used to decide if certain child objects need
     * to be lazy loaded.
     * @servertype string array
     * @type {string[]}
     */
    ObjTypesIncluded: string[] = [];

    /**
     * @name DeletedDataObjects
     * @description
     * A list of types and ids that represent child objects that were deleted as part of this object.  The object type is a string
     * representation of the child object type and the object id is the key of the child object that was deleted.
     * @servertype IDeletedDataObject array
     * @type {m.DeletedDataObject[]}
     */
    DeletedDataObjects: m.DeletedDataObject[] = [];

    /**
     * @name PartitionId
     * @description
     * A partition identifier for this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name ReadOnly
     * @description
     * When true this object is read-only for the current user and context.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Properties
     * @description
     * Additional meta data properties about this object.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name MetaDataLightViewModel
 */
export class MetaDataLightViewModel {

    /**
     * @name ObjId
     * @description
     * An identifier for this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ObjId: number = 0;

    /**
     * @name ObjVersion
     * @description
     * The current row version for this object.
     * @servertype String
     * @type {string}
     */
    ObjVersion: string = "";

    /**
     * @name ObjHashCode
     * @description
     * The current hash code for this object.
     * @servertype String
     * @type {string}
     */
    ObjHashCode: string = "";

    /**
     * @name ObjSource
     * @description
     * The name of the data source table for this object.
     * @servertype String
     * @type {string}
     */
    ObjSource: string = "";

    /**
     * @name PartitionId
     * @description
     * A partition identifier for this object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name ReadOnly
     * @description
     * When true this object is read-only for the current user and context.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Properties
     * @description
     * Additional meta data properties about this object.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name PaymentMethodEditViewModel
 * @description
 * The payment method table contains information about payment methods used by customers.
 */
export class PaymentMethodEditViewModel {

    /**
     * @name PaymentMethodId
     * @description
     * The id of the payment method.
     * @servertype String
     * @type {string}
     */
    PaymentMethodId: string = null;

    /**
     * @name Tokens
     * @description
     * A json string of key value pairs where the key is the tokenization provider and the value is the corresponding token.  Tokens
     * for an unlimited number of tokenization providers are supported.
     * For example:
     * {
     *   "Chase":"d0c70929-606f-4237-8f00-3da4bd884234",
     *   "TSYS":"3476681192357195838",
     *   "Nubill":"1gfl1jbdym2jt"
     *   "Subset":"0183"
     *   "Wallet":"Visa Checkout"
     * }
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Tokens: {[key: string]:  string} = {};

    /**
     * @name Type
     * @description
     * The payment method type.  Possible values include:
     * C = Credit Card
     * D = Debit Card
     * B = Bank Account (Checking)
     * S = Bank Account (Savings)
     * P = PayPal
     * M = Venmo
     * A = Amazon
     * Y = Apple Pay
     * G = Google Pay
     * W = WeChat Pay
     * L = Alipay
     * V = Visa Checkout
     * O = Other
     * Note that Visa Checkout is a wallet and the type is effectively the same as Credit Card but is assigned a unique type to
     * provide some distinction for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Type: string = "C";

    /**
     * @name Status
     * @description
     * The payment method status.  Possible values include:
     * D = Default payment method
     * C = Current/Active payment method
     * E = Expired payment method
     * R = Retired payment method
     * H = Hidden payment method
     * B = Blacklisted payment method
     * Retired payment methods are payment methods where the owner asked to have the payment method deleted.  This triggers removal
     * of all sensitive information and tokenization and retires it from use and view but maintains the scrubbed record for historical
     * and audit trail purposes.
     * Hidden payment methods are payment methods where the owner did not ask for it to be saved.  The payment engine requires it
     * be saved long enough to process the transaction after which all sensitive information and tokenization is removed and it
     * is hidden from use and view but maintains the scrubbed record for historical and audit trail purposes.
     * Blacklisted payment methods are payment methods that are black listed from use in the system.
     * @servertype String
     * @type {string}
     */
    Status: string = "C";

    /**
     * @name NextValidationDateTime
     * @description
     * The date and time when the payment method is next scanned, validated, etc.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextValidationDateTime: Date = null;

    /**
     * @name LastUsedDateTime
     * @description
     * The date and time when the payment method was last used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name TransactionCount
     * @description
     * The number of transactions that used this payment method.  This includes purchases, refunds, etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TransactionCount: number = 0;

    /**
     * @name PurchaseCount
     * @description
     * The number of purchases that used this payment method.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PurchaseCount: number = 0;

    /**
     * @name RetentionDays
     * @description
     * The number of days after last use to retain payment method details.  After this number of days has elapsed the payment method
     * details can be cleared to limit security scope or tokenization provider storage fees.  The default of 0 means retention is
     * being set by system wide business rules or an external process using the remove sensitive information flag.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetentionDays: number = 0;

    /**
     * @name RemoveSensitiveInformation
     * @description
     * True indicates the payment method should have sensitive information removed.  This can be used for dynamic data retention
     * when retention days can't be utilized.  For example, business policy or governmental regulation may require sensitive data
     * to be removed after an account is closed, etc.  The processes used to enforce that can set this true indicating sensitive
     * data is ready to be removed.
     * @servertype Boolean
     * @type {boolean}
     */
    RemoveSensitiveInformation: boolean = false;

    /**
     * @name SensitiveInformationRemoved
     * @description
     * True indicates that sensitive information about the payment method has been removed.  This is typically done in response
     * to the retention days having been reached or the remove sensitive information flag being set.  When this value is true this
     * payment method can no longer be used for new purchases without the payment details being provided again.
     * @servertype Boolean
     * @type {boolean}
     */
    SensitiveInformationRemoved: boolean = false;

    /**
     * @name PaymentMethodTypeId
     * @description
     * A known static identifier that represents this payment method type in a non-volatile manner.  Possible values include:
     * 0 = Unknown
     * 101 = VISA
     * 201 = MasterCard
     * 301 = AmericanExpress
     * 401 = Discover
     * 501 = DinersClub
     * 502 = DinersClubCarteBlanche
     * 503 = DinersClubEnRoute
     * 504 = DinersClubInternational
     * 505 = DinersClubUnitedStatesAndCanada
     * 601 = JCB
     * 1201 = ChinaUnionPay
     * 1301 = InterPayment
     * 1401 = InstaPayment
     * 2201 = Maestro
     * 2301 = Dankort
     * 2401 = MIR
     * 2501 = Verve
     * 3101 = Troy
     * 3501 = BcCard
     * 6101 = Elo
     * 6201 = Aura
     * 70101 = UATP
     * 80101 = PayPal
     * 80201 = Amazon
     * 80301 = ApplePayCardTypeUnknown
     * 80401 = GooglePayCardTypeUnknown
     * 80501 = WeChatPay
     * 80601 = Alipay
     * 80701 = Venmo
     * 80801 = MagensaCardTypeUnknown
     * 89101 = BankAccountChecking
     * 89201 = BankAccountSavings
     * 90101 = StoreCard
     * 91101 = Other
     * 92101 = Custom
     * @servertype Int32
     * @type {number}
     */
    PaymentMethodTypeId: number = 0;

    /**
     * @name Description
     * @description
     * A description provided by the account holder (e.g. Work AmEx, Backup VISA, Checking, etc.).
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name AccountNumberReferenceSubset
     * @description
     * The subset of the account number that can be used for a reference.  Typically the last four digits.
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubset: string = "";

    /**
     * @name BankName
     * @description
     * For bank accounts this is the bank name as determined by the routing number.  For non-bank account payment methods this can
     * hold other information related to the payment method like wallet type (e.g. Visa Checkout).
     * @servertype String
     * @type {string}
     */
    BankName: string = "";

    /**
     * @name RoutingNumber
     * @description
     * Routing number for this payment method.
     * @servertype String
     * @type {string}
     */
    RoutingNumber: string = "";

    /**
     * @name AccountNumber
     * @description
     * Account number for this payment method.
     * @servertype String
     * @type {string}
     */
    AccountNumber: string = "";

    /**
     * @name Expiration
     * @description
     * Account expiration for this payment method expressed in yyyy-mm-dd format.
     * @servertype String
     * @type {string}
     */
    Expiration: string = "";

    /**
     * @name AccountId
     * @description
     * For credit cards this value will be credit card security id (e.g. CVV2) until the card has been authorized and then will
     * be null (PCI DSS).
     * For debit cards this value will be the PIN until the card has been authorized and then will be null (PCI DSS).
     * @servertype String
     * @type {string}
     */
    AccountId: string = "";

    /**
     * @name Value001
     * @description
     * Account value 1 for this payment method.  This will vary depending on payment method type or use case.
     * For Apple Pay this is the Apple Pay event.payment.token object stored as a JSON string.
     * For Braintree this is the customer id.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description
     * Account value 2 for this payment method.  This will vary depending on payment method type or use case.
     * For Apple Pay if debundling was done this is the debundle result object stored as JSON string.
     * For Braintree this is the payment method token.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description
     * Account value 3 for this payment method.  This will vary depending on payment method type or use case.
     * For Apple Pay if debundling was done this is the cryptogram included with the debundle result.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description
     * Account value 4 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description
     * Account value 5 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description
     * Account value 6 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description
     * Account value 7 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description
     * Account value 8 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description
     * Account value 9 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description
     * Account value 10 for this payment method.  This will vary depending on payment method type or use case.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name BillingName
     * @description
     * Billing name for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingName: string = "";

    /**
     * @name BillingCompanyName
     * @description
     * Billing company name for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingCompanyName: string = "";

    /**
     * @name BillingAddress1
     * @description
     * Billing address 1 for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAddress1: string = "";

    /**
     * @name BillingAddress2
     * @description
     * Billing address 2 for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAddress2: string = "";

    /**
     * @name BillingAddress3
     * @description
     * Billing address 3 for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingAddress3: string = "";

    /**
     * @name BillingCity
     * @description
     * Billing city for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingCity: string = "";

    /**
     * @name BillingState
     * @description
     * Billing state for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingState: string = "";

    /**
     * @name BillingPostalCode
     * @description
     * Billing postal code for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingPostalCode: string = "";

    /**
     * @name BillingCountry
     * @description
     * Billing country for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingCountry: string = "";

    /**
     * @name BillingPhone
     * @description
     * Billing phone for this payment method.
     * @servertype String
     * @type {string}
     */
    BillingPhone: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Payment Notification
 * @description
 * This contains information about a payment notification.
 */
export class PaymentNotificationAddViewModel {

    /**
     * @name PaymentNotificationId
     * @description
     * A sender defined id for this notification.
     * @servertype String
     * @type {string}
     */
    PaymentNotificationId: string = "";

    /**
     * @name NotificationDateTime
     * @description
     * The date and time of the notification.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationDateTime: Date = null;

    /**
     * @name NotificationType
     * @description
     * The notification type.  Examples: CaptureFailed, CaptureRejected, RefundFailed, RefundRejected, RefundForced, RequestForInformation,
     * DisputeOpened, DisputeClosed, Chargeback, ChargebackReversed, Fraud, Report, Other, etc.  Each expected notification type
     * should be registered as a payment provider trigger.
     * @servertype String
     * @type {string}
     */
    NotificationType: string = "";

    /**
     * @name NotificationCode
     * @description
     * A code related to this notification.
     * @servertype String
     * @type {string}
     */
    NotificationCode: string = "";

    /**
     * @name NotificationMessage
     * @description
     * A short message regarding this notification.
     * @servertype String
     * @type {string}
     */
    NotificationMessage: string = "";

    /**
     * @name NotificationDetails
     * @description
     * Details regarding this notification.
     * @servertype String
     * @type {string}
     */
    NotificationDetails: string = "";

    /**
     * @name IsoCurrencyCode
     * @description
     * The ISO Currency Code if there is a fee specified with this notification.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Fee
     * @description
     * A fee associated with the notification.  This is optional but if provided will override any fee defined in payment provider
     * triggers for this notification type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Fee: number = 0;

    /**
     * @name PaymentProviderId
     * @description
     * The id for the payment provider triggering this notification.  This can be left empty if there is a reference to a specific
     * payment transaction for the notification.
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = "";

    /**
     * @name PaymentMethodId
     * @description
     * If the notification is regarding a payment method this is the payment method id.  This can be left null if the notification
     * is not related to a payment method or if there is a reference to a specific payment transaction for this notification.
     * @servertype String
     * @type {string}
     */
    PaymentMethodId: string = "";

    /**
     * @name PaymentTransactionId
     * @description
     * If the notification is regarding a specific payment transaction this is the internal payment transaction id for that transaction.
     *  This can be left blank if the transaction can be found by the transaction order reference or payment provider issued transaction
     * payment id.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = "";

    /**
     * @name TransactionDateTime
     * @description
     * If the notification is regarding a payment transaction this is the date and time of the transaction.  This can be left null
     * if the internal payment transaction id is provided or if the transaction order reference or payment provider issued transaction
     * payment id are known to be globally unique identifiers and do not need a date time reference.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDateTime: Date = null;

    /**
     * @name TransactionOrderReference
     * @description
     * If the notification is regarding a specific payment transaction this is the transaction order reference for that transaction.
     *  This can be left blank if the transaction can be found by the internal payment transaction id or payment provider issued
     * transaction payment id.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name TransactionPaymentId
     * @description
     * If the notification is regarding a specific payment transaction this is the payment provider issued transaction payment id
     * for that transaction.  This can be left blank if the transaction can be found by the transaction order reference or internal
     * payment transaction id.
     * @servertype String
     * @type {string}
     */
    TransactionPaymentId: string = "";

    /**
     * @name Properties
     * @description
     * Properties that contain payment provider defined information regarding the notification.  The format is dynamic and can contain
     * any information the sender desires to convey.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Value001
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Value011
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value011: string = "";

    /**
     * @name Value012
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value012: string = "";

    /**
     * @name Value013
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value013: string = "";

    /**
     * @name Value014
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value014: string = "";

    /**
     * @name Value015
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value015: string = "";

    /**
     * @name Value016
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value016: string = "";

    /**
     * @name Value017
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value017: string = "";

    /**
     * @name Value018
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value018: string = "";

    /**
     * @name Value019
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value019: string = "";

    /**
     * @name Value020
     * @description
     * A custom value for this notification.
     * @servertype String
     * @type {string}
     */
    Value020: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name PaymentProviderEditViewModel
 * @description
 * The Payment Provider table contains payment account information.  Every payment merchant account, payment gateway, or tokenization
 * provider will be recorded here for processing purposes.
 */
export class PaymentProviderEditViewModel {

    /**
     * @name PaymentProviderId
     * @description
     * The id of the payment provider.
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = null;

    /**
     * @name Tag
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name SharedSecret
     * @servertype String
     * @type {string}
     */
    SharedSecret: string = "";

    /**
     * @name SharedSecret2
     * @servertype String
     * @type {string}
     */
    SharedSecret2: string = "";

    /**
     * @name ExternalPaymentProviderId
     * @description
     * An optional external payment provider id for this payment provider.
     * @servertype String
     * @type {string}
     */
    ExternalPaymentProviderId: string = "";

    /**
     * @name Description
     * @description
     * The description for this payment provider.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PaymentEngineName
     * @description
     * The payment engine name for this payment provider.  Possible values include:
     * Simulator = Simulator
     * PayPal = PayPal
     * BraintreePayPal = Braintree PayPal
     * BraintreeVenmo = Braintree Venmo
     * Amazon = Pay with Amazon
     * MagensaPaymentProtectionGatewayChase = Magensa Payment Protection Gateway (Chase)
     * ApplePayChaseOrbitalCommonWallet = Apple Pay with Chase Orbital Wallet
     * ApplePayChaseOrbitalCommonWalletV2 = Apple Pay with Chase Orbital Wallet v2
     * Adyen = Adyen
     * Cielo = Cielo eCommerce
     * Nestpay = Nestpay / Isbank / Payten / Asseco
     * NubillWallet = Nubill Wallet
     * Custom = Custom Provider Implementation
     * E-Payment Integrator = E-Payment Integrator
     * @servertype String
     * @type {string}
     */
    PaymentEngineName: string = "";

    /**
     * @name PaymentProviderName
     * @description
     * The payment provider name for this payment provider record.  When the payment engine is anything other than E-Payment Integrator
     * this is the same value since those engines only support a single payment provider.  Otherwise this is the name of the gateway
     * being used with engines that support multiple providers.
     * @servertype String
     * @type {string}
     */
    PaymentProviderName: string = "";

    /**
     * @name PaymentProviderPlatform
     * @description
     * This is an optional platform indicator for the payment provider.  For example, with Chase Orbital this is the BIN number.
     * @servertype String
     * @type {string}
     */
    PaymentProviderPlatform: string = "";

    /**
     * @name TokenizationProvider
     * @description
     * Not all payment providers provide tokenization but tokens can only be submitted a payment processor that supports a tokenization
     * provider which is often only their own.  This setting specifies the tokenization provider name to use in order to select
     * the correct payment method token.
     * @servertype String
     * @type {string}
     */
    TokenizationProvider: string = "";

    /**
     * @name MerchantNumber
     * @description
     * The merchant number used with this payment provider.
     * @servertype String
     * @type {string}
     */
    MerchantNumber: string = "";

    /**
     * @name MerchantAccountNumber
     * @description
     * The merchant account number used with this payment provider.
     * @servertype String
     * @type {string}
     */
    MerchantAccountNumber: string = "";

    /**
     * @name MerchantAlternateNumber
     * @description
     * The merchant alternate number used with this payment provider.
     * For MagensaPaymentProtectionGatewayChase this is the Magensa provided Customer Code.
     * @servertype String
     * @type {string}
     */
    MerchantAlternateNumber: string = "";

    /**
     * @name MerchantName
     * @description
     * The merchant name used with this payment provider.
     * @servertype String
     * @type {string}
     */
    MerchantName: string = "";

    /**
     * @name VendorId
     * @description
     * The vendor id used with this payment provider.
     * @servertype String
     * @type {string}
     */
    VendorId: string = "";

    /**
     * @name SoftwareId
     * @description
     * The software id used with this payment provider.
     * @servertype String
     * @type {string}
     */
    SoftwareId: string = "";

    /**
     * @name TerminalId
     * @description
     * The terminal id used with this payment provider.
     * @servertype String
     * @type {string}
     */
    TerminalId: string = "";

    /**
     * @name Enabled
     * @description
     * When true the payment provider is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidForTestTransactions
     * @description
     * True indicates the payment provider is will recognize test payment method account numbers and process them using internal
     * test logic instead of submitting to the payment provider for processing.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForTestTransactions: boolean = false;

    /**
     * @name IsoCurrencyCodeList
     * @description
     * A comma delimited list of ISO currency codes (e.g. USD, CAD, HKD, MXN, etc.) that are allowed for this payment provider.
     *  Any transactions with any other currency will not select this payment provider unless it is also the default payment provider.
     *  When no currencies are listed all currencies are allowed.
     * @servertype string array
     * @type {string[]}
     */
    IsoCurrencyCodeList: string[] = [];

    /**
     * @name PaymentProviderFlags
     * @description
     * A list of flags sent to the payment provider.  These will differ depending on the payment engine.
     * For example:
     * For E-Payment Integrator this could be config strings like 'AllowPartialAuths=false' or 'TerminalId=425567118'.
     * For Apple Pay this might include a flag 'debundle' if debundling should happen.
     * @servertype string array
     * @type {string[]}
     */
    PaymentProviderFlags: string[] = [];

    /**
     * @name PaymentProviderSettings
     * @description
     * An object that contains settings sent to the payment provider.  These will differ depending on the payment engine.
     * @servertype System.Object
     * @type {any}
     */
    PaymentProviderSettings: any = {};

    /**
     * @name PaymentProcessingFlags
     * @description
     * A list of flags used internally for payment processing.  Possible values include:
     * Sandbox = Use Sandbox (When testing with sandbox in production the Sandbox property will be set to false so this flag is
     * used to force sandbox use.)
     * Failover = Use Failover Connection
     * @servertype string array
     * @type {string[]}
     */
    PaymentProcessingFlags: string[] = [];

    /**
     * @name PaymentProcessingSettings
     * @description
     * An object that contains settings used internally for payment processing.
     * @servertype System.Object
     * @type {any}
     */
    PaymentProcessingSettings: any = {};

    /**
     * @name ContactInformationCapture
     * @description
     * A flag indicating if any contact information should be captured from the payment provider.  Some payment providers can provide
     * contact information that can be used to populate the contact record that owns the payment transaction.  Possible values include:
     * N = Never
     * A = Always (will overwrite any contact information already on file)
     * W = When Needed (will only capture contact information missing)
     * @servertype String
     * @type {string}
     */
    ContactInformationCapture: string = "W";

    /**
     * @name PaymentModel
     * @description
     * A flag indicating the payment model to use for this epayment account when a payment is requested.  Possible values include:
     * S = Sale
     * A = Authorization followed by immediate capture
     * B = Authorization followed later by batch capture
     * @servertype String
     * @type {string}
     */
    PaymentModel: string = "S";

    /**
     * @name VerificationModel
     * @description
     * A flag indicating the verification model to use presale address and/or card id verification.  Possible values include:
     * N = None.  No presale verification is performed.
     * Z = Zero amount authorization followed by sale or cancelled transaction based on response.
     * A = Authorization followed by capture or void based on response.
     * @servertype String
     * @type {string}
     */
    VerificationModel: string = "N";

    /**
     * @name AddressVerificationRequired
     * @description
     * When true, address verification is required for this payment provider.
     * @servertype Boolean
     * @type {boolean}
     */
    AddressVerificationRequired: boolean = false;

    /**
     * @name AddressVerificationFailList
     * @description
     * A comma delimited list of address verification codes that should result in a failed transaction.  Possible values include:
     * A = Street address matches, but 5-digit and 9-digit postal code do not match. (Standard domestic)
     * B = Street address matches, but postal code not verified. (Standard international)
     * C = Street address and postal code do not match. (Standard international)
     * D = Street address and postal code match. Code 'M' is equivalent. (Standard international)
     * E = AVS data is invalid or AVS is not allowed for this card type. (Standard domestic)
     * F = Card member name does not match, but billing postal code matches. (American Express only)
     * G = Non-U.S. issuing bank does not support AVS. (Standard international)
     * H = Card member name does not match. Street address and postal code match. (American Express only)
     * I = Address not verified. (Standard international)
     * J = Card member name, billing address, and postal code match. (American Express only)
     * K = Card member name matches but billing address and billing postal code do not match. (American Express only)
     * L = Card member name and billing postal code match, but billing address does not match. (American Express only)
     * M = Street address and postal code match. Code 'D' is equivalent. (Standard international)
     * N = Street address and postal code do not match. (Standard domestic)
     * O = Card member name and billing address match, but billing postal code does not match. (American Express only)
     * P = Postal code matches, but street address not verified. (Standard international)
     * Q = Card member name, billing address, and postal code match. (American Express only)
     * R = System unavailable. (Standard domestic)
     * S = Bank does not support AVS. (Standard domestic)
     * T = Card member name does not match, but street address matches. (American Express only)
     * U = Address information unavailable. Bank does not support non-U.S. AVS or the AVS is not functioning. (Standard domestic)
     * V = Card member name, billing address, and billing postal code match. (American Express only)
     * W = Street address does not match, but 9-digit postal code matches. (Standard domestic)
     * X = Street address and 9-digit postal code match. (Standard domestic)
     * Y = Street address and 5-digit postal code match. (Standard domestic)
     * Z = Street address does not match, but 5-digit postal code matches. (Standard domestic)
     * @servertype string array
     * @type {string[]}
     */
    AddressVerificationFailList: string[] = [];

    /**
     * @name CardIdVerificationRequired
     * @description
     * When true, card id verification is required for this payment provider.
     * @servertype Boolean
     * @type {boolean}
     */
    CardIdVerificationRequired: boolean = false;

    /**
     * @name CardIdVerificationFailList
     * @description
     * A comma delimited list of card id verification codes that should result in a failed transaction.  Possible values include:
     * D = Transaction determined suspicious by issuing bank.
     * I = Card verification number failed processor's data validation check.
     * M = Card verification number matched.
     * Y = Card verification number matched (Amex).
     * N = Card verification number not matched.
     * P = Card verification number not processed by processor for unspecified reason.
     * S = Card verification number is on the card but was not included in the request.
     * U = Card verification is not supported by the issuing bank.
     * X = Card verification is not supported by the card association.
     * 1 = Card verification is not supported for this processor or card type.
     * 2 = Unrecognized result code returned by processor for card verification response.
     * 3 = No result code returned by processor.
     * @servertype string array
     * @type {string[]}
     */
    CardIdVerificationFailList: string[] = [];

    /**
     * @name Encoding
     * @description
     * The encoding to use when submitting to the payment engine.  Typically customer information is the only information that is
     * encoded (e.g. name, email, etc.).  Possible values include:
     *  = No special encoding is performed.
     * ASCII = ASCII Encoding
     * EASCII = Extended ASCII Encoding
     * CP1252 = Code Page 1252 Encoding
     * Latin1 = ISO 8859-1 [Latin 1] Encoding
     * Note that EASCII, Latin1, and CP1252 may all use Code Page 1252 internally as it's a compatible superset of ISO 8859-1.
     * @servertype String
     * @type {string}
     */
    Encoding: string = "";

    /**
     * @name PaymentProcessingRegion
     * @description
     * An optional region for payment processing.  Appropriate values to use here is payment engine dependent.
     * @servertype String
     * @type {string}
     */
    PaymentProcessingRegion: string = "";

    /**
     * @name SystemTimeZoneId
     * @servertype String
     * @type {string}
     */
    SystemTimeZoneId: string = "";

    /**
     * @name Login1
     * @description
     * The login 1 for this payment provider.  This is stored as an encrypted value.
     * For Nubill Wallet this is the Wallet API Key.
     * For Amazon this is the AWS Access Key Id.
     * For Braintree this is the merchant id (not merchant account id).
     * For Adyen this is the merchant account name.
     * For engine ApplePayChaseOribtalCommonWallet this is the login for the common wallet api.
     * For MagensaPaymentProtectionGatewayChase this is the Magensa username.
     * @servertype String
     * @type {string}
     */
    Login1: string = "";

    /**
     * @name Login2
     * @description
     * The login 2 for this payment provider.  This is stored as an encrypted value.
     * For Amazon this is the Seller Id.
     * For Braintree this is the public key.
     * For Authorize.Net this is the x_Trans_Key.
     * For engine ApplePayChaseOribtalCommonWallet if this matches the merchant id then that is used for CDPT transactions and if
     * empty then Login1 is used for OrbitalConnectionUsername.
     * For MagensaPaymentProtectionGatewayChase this is the OrbitalConnectionUsername.
     * @servertype String
     * @type {string}
     */
    Login2: string = "";

    /**
     * @name Password1
     * @description
     * The password 1 for this payment provider.  This is stored as an encrypted value.
     * For Nubill Wallet this is the Wallet Payment Provider Id.
     * For Amazon this is the AWS Secret Key.
     * For Braintree this is the private key.
     * For Adyen this is the api key.
     * For engine ApplePayChaseOribtalCommonWallet this is the password for the common wallet api.
     * For MagensaPaymentProtectionGatewayChase this is the Magensa password.
     * @servertype String
     * @type {string}
     */
    Password1: string = "";

    /**
     * @name Password2
     * @description
     * The password 2 for this payment provider.  This is stored as an encrypted value.
     * For Amazon this is the MWS Auth Token (if any).
     * For Braintree this is an optional client token when using a stored client token.
     * For engine ApplePayChaseOribtalCommonWallet if this matches the BIN (PaymentProviderPlatform) then that is used for CDPT
     * transactions and if empty then Password1 is used for OrbitalConnectionPassword.
     * For MagensaPaymentProtectionGatewayChase this is the OrbitalConnectionPassword.
     * @servertype String
     * @type {string}
     */
    Password2: string = "";

    /**
     * @name Sandbox
     * @description
     * True indicates this is a sandbox account for the payment provider.  Note that sandbox and production accounts for a payment
     * provider should be entered as two separate payment providers.  Switching between sandbox and production environments by changing
     * this flag and/or the current url will break things like refund processing, etc. and should not be done.
     * @servertype Boolean
     * @type {boolean}
     */
    Sandbox: boolean = false;

    /**
     * @name CurrentUrl
     * @description
     * The current url used for this payment provider.  In some scenarios like testing sandbox merchant accounts in production code
     * we need the sandbox setting to be false and this current url is used to determine if the payment provider sandbox is being
     * utilized or not.  In scenarios where the url is hard coded in but we need the current url to provide a sandbox indicator
     * the text 'sandbox' should be entered.
     * @servertype String
     * @type {string}
     */
    CurrentUrl: string = "";

    /**
     * @name ProductionUrl1
     * @description
     * The primary production url for this payment provider.  This should only be utilized in the current url property when the
     * payment provider is a production entry as providers should not typically switch between production and sandbox configurations
     * since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    ProductionUrl1: string = "";

    /**
     * @name ProductionUrl2
     * @description
     * The secondary production url for this payment provider.  This should only be utilized in the current url property when the
     * payment provider is a production entry as providers should not typically switch between production and sandbox configurations
     * since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    ProductionUrl2: string = "";

    /**
     * @name SandboxUrl1
     * @description
     * The primary sandbox url for this payment provider.  This is for informational purposes only and should only be utilized in
     * the current url property when the payment provider is a sandbox entry as providers should not typically switch between production
     * and sandbox configurations since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    SandboxUrl1: string = "";

    /**
     * @name SandboxUrl2
     * @description
     * The secondary sandbox url for this payment provider.  This is for informational purposes only and should only be utilized
     * in the current url property when the payment provider is a sandbox entry as providers should not typically switch between
     * production and sandbox configurations since that can break things like refund processing, etc.
     * @servertype String
     * @type {string}
     */
    SandboxUrl2: string = "";

    /**
     * @name TransactionDescription
     * @description
     * The description used when posting transactions with this payment provider.  In some scenarios this can be used for other
     * purposes.  For example, in an Amazon Payment Sandbox scenario it can be used to force responses from Amazon to have certain
     * state and reason code values:
     * {"SandboxSimulation": {"State":"Declined", "ReasonCode":"InvalidPaymentMethod", "PaymentMethodUpdateTimeInMins":5}}
     * {"SandboxSimulation": {"State":"Declined", "ReasonCode":"AmazonRejected"}}
     * {"SandboxSimulation": {"State":"Declined", "ReasonCode":"TransactionTimedOut"}}
     * {"SandboxSimulation": {"State":"Closed", "ReasonCode":"ExpiredUnused", "ExpirationTimeInMins":1}}
     * {"SandboxSimulation": {"State":"Closed", "ReasonCode":"AmazonClosed"}}
     * See https://payments.amazon.com/documentation/lpwa/201956480 for more information.
     * @servertype String
     * @type {string}
     */
    TransactionDescription: string = "";

    /**
     * @name OrderNumberType
     * @description
     * For payment providers who accept a merchant order number or invoice number, this is an indication of what value(s) to submit
     * as that merchant order number or invoice number.  Possible values include:
     * TransactionOrderReference = The order reference attached to the payment transaction being processed.  (This is the default
     * used when no other is specified.)
     * PaymentTransactionId = The unique payment transaction id for the payment transaction being processed.
     * PaymentTransactionIdOfPayment = The unique payment transaction id tied to payment request.  (For payments this is the same
     * as PaymentTransactionId but for refunds, etc. this is the unique payment transaction id of the original payment record being
     * refunded, etc. not the id of the refund transaction itself.)
     * @servertype String
     * @type {string}
     */
    OrderNumberType: string = "";

    /**
     * @name PaymentMaximumAttempts
     * @description
     * The maximum number of times to try processing a payment request.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentMaximumAttempts: number = 0;

    /**
     * @name PaymentRetryIntervalSeconds
     * @description
     * When retrying a payment this is the number of seconds to wait before retrying.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentRetryIntervalSeconds: number = 0;

    /**
     * @name PaymentMonitorTimeoutSeconds
     * @description
     * When monitoring the status of a payment request real-time this is the number of seconds after which to timeout.  Note that
     * this does not timeout the actual payment request which may still be pending or processing it simply stops the real-time monitoring
     * of the payment transaction.  The eventual success or failure of the payment transaction can still be accessed later.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentMonitorTimeoutSeconds: number = 0;

    /**
     * @name PaymentProviderTimeoutReportedStatus
     * @description
     * This only applies to authorization, capture, sale transactions and never to refund or void transactions.  When monitoring
     * of a payment transaction times out this can help the monitoring application decide how the still pending status of the transaction
     * should be reported.  Possible values include:
     * W = Waiting (transaction pending)
     * S = Success (transaction assumed success)
     * F = Failed (transaction assumed failed)
     * In some limited real-time delivery of soft goods that have no cost of good sold it's preferred to treat a timeout as a success
     * to limit customer friction.  In those limited scenarios this should be set to success.  In all other cases this should be
     * waiting or failed.  For payment providers used in both scenarios two separate payment provider entries need to be created
     * one for each scenario.  Note that this setting does not impact the actual result of the transaction it is only used as information
     * for the monitoring process to decide how to react to the time out.  The payment provider status adjustment rules can be used
     * to decide how to handle scenarios where the reported status does not match the eventual actual status.
     * @servertype String
     * @type {string}
     */
    PaymentProviderTimeoutReportedStatus: string = "W";

    /**
     * @name PaymentProviderErrorReportedStatus
     * @description
     * This only applies to authorization, capture, sale transactions and never to refund or void transactions.  When monitoring
     * of a payment transaction results in an error that is attributed to a payment provider error (e.g. off-line, etc. but not
     * a decline) this can help the monitoring application decide how the provider error should be reported.  Possible values include:
     * W = Waiting (transaction pending)
     * S = Success (transaction assumed success)
     * F = Failed (transaction assumed failed)
     * In some limited real-time delivery of soft goods that have no cost of good sold it's preferred to treat a provider error
     * as a success to limit customer friction.  In those limited scenarios this should be set to success.  In all other cases this
     * should be waiting (if retries are configured) or failed.  For payment providers used in both scenarios two separate payment
     * provider entries need to be created one for each scenario.  Note that this setting does not impact the actual result of the
     * transaction it is only used as information for the monitoring process to decide how to react to the provider error.  The
     * payment provider status adjustment rules can be used to decide how to handle scenarios where the reported status does not
     * match the eventual actual status.
     * @servertype String
     * @type {string}
     */
    PaymentProviderErrorReportedStatus: string = "F";

    /**
     * @name RefundTransactionThresholdDays
     * @description
     * The number of days within which a refund can be processed against a transaction.  Typically within this window no credit
     * card details are needed and refunds can be issued against the purchase transaction record.  After this threshold refunds
     * typically need to be processed as a blind credit to a credit card.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RefundTransactionThresholdDays: number = 0;

    /**
     * @name PaymentErrorThreshold
     * @description
     * The maximum number of payment errors within the window to be tolerated before assuming the payment provider is off-line.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentErrorThreshold: number = 0;

    /**
     * @name PaymentErrorWindow
     * @description
     * The number of seconds for the payment error window when counting payment errors.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentErrorWindow: number = 0;

    /**
     * @name PaymentErrorDuration
     * @description
     * The number of seconds for the payment provider to be off-line due to exceeding the payment error threshold.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PaymentErrorDuration: number = 0;

    /**
     * @name UrlChangedDateTime
     * @description
     * The date and time the provider current url was changed.  Typically this is as a result of excessive payment errors and is
     * taken as a first step before disabling the payment provider when multiple production URLs are available.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UrlChangedDateTime: Date = null;

    /**
     * @name DisabledUntilDateTime
     * @description
     * The date and time the provider is disabled until.  Typically this is as a result of excessive payment errors.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DisabledUntilDateTime: Date = null;

    /**
     * @name CustomTransactionProcessingLogic
     * @description
     * Custom transaction processing logic for this payment provider.
     * @servertype IB.Core.Scripting.ScriptModel
     * @type {m.ScriptModel}
     */
    CustomTransactionProcessingLogic: m.ScriptModel = null;

    /**
     * @name CustomNotificationProcessingLogic
     * @description
     * Custom notification processing logic for this payment provider.
     * @servertype IB.Core.Scripting.ScriptModel
     * @type {m.ScriptModel}
     */
    CustomNotificationProcessingLogic: m.ScriptModel = null;

    /**
     * @name Value001
     * @description
     * Value 1 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Amazon this is the Region (defaults to 'us').
     * For Braintree PayPal or Venmo this is the merchant id (not merchant account id that is stored in merchant number).
     * For Cielo this is the country in which payment will be processed (defaults to 'BRA').
     * For Orbital Gateway Common Wallet Service this is the vendor id.
     * For E-Payment Integrator this is a json collection of strings to submit as config strings (e.g. ["AllowPartialAuths=false","TerminalId=425567118"]).
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description
     * Value 2 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Orbital Gateway Common Wallet Service this is the software id.
     * For E-Payment Integrator this is a json object of key value pairs to submit as special fields (e.g. {"x_Trans_Key":"90223491","Method":"Standard"}).
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description
     * Value 3 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For E-Payment Integrator and others who accept a merchant order number or invoice number, this is an instruction regarding
     * what value(s) to submit as that merchant order number or invoice number.  Possible values include:
     * TransactionOrderReference = The order reference attached to the payment transaction being processed.  This is the default
     * used when no other is specified.
     * PaymentTransactionId = The unique payment transaction id for the payment transaction being processed.
     * PaymentTransactionIdOfPayment = The unique payment transaction id tied to payment request.  For payments this is the same
     * as PaymentTransactionId but for refunds, etc. this is the unique payment transaction id of the original payment record being
     * refunded, etc. not the id of the refund transaction itself.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description
     * Value 4 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Chase Orbital this is the BIN.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description
     * Value 5 for this payment provider.  Specific to the payment provider specified.
     * For Legacy Purposes:
     * For Apple Pay if debundling is desired this value contains within CSV a value of 'debundle' (without the quotes).
     * For Apple Pay if sandbox should be used this value contains within CSV a value of 'sandbox' (without the quotes).
     * For Apple Pay if failover host should be used this value contains within CSV a value of 'failover' (without the quotes).
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description
     * Value 6 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description
     * Value 7 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description
     * Value 8 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description
     * Value 9 for this payment provider.  Specific to the payment provider specified.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description
     * Value 10 for this payment provider.
     * For Legacy Purposes:
     * This is typically a CSV of values that may impact how payments are submitted or processed.  Possible values include:
     * ASCII = Encode name, email, etc. as ASCII before submitting for processing.
     * EASCII = Encode name, email, etc. as extended ASCII before submitting for processing.
     * CP1252 = Encode name, email, etc. as Code Page 1252 before submitting for processing.
     * Latin1 = Encode name, email, etc. as ISO 8859-1 (e.g. ISO Latin 1) before submitting for processing.
     * Note that EASCII, Latin1, and CP1252 may all use Code Page 1252 internally as it's a compatible superset of ISO 8859-1.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Triggers
     * @description
     * List of triggers for the payment provider.
     * @servertype PaymentProviderTriggerEditViewModel array
     * @type {PaymentProviderTriggerEditViewModel[]}
     */
    Triggers: PaymentProviderTriggerEditViewModel[] = [];

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PaymentProviderTriggerEditViewModel
 * @description
 * The payment provider trigger table contains actions that should be taken when a triggering event (e.g. status different than
 * reported status, notification, etc.) occurs.
 */
export class PaymentProviderTriggerEditViewModel {

    /**
     * @name PaymentProviderTriggerId
     * @description
     * The id of the payment provider trigger.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentProviderTriggerId: number = null;

    /**
     * @name PaymentProviderId
     * @description
     * The id of the payment provider.
     * @servertype Int64
     * @type {number}
     */
    PaymentProviderId: number = null;

    /**
     * @name Description
     * @description
     * Description for this payment provider trigger.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the payment provider trigger is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name EventType
     * @description
     * The event type that initiates this trigger.  Possible values include:
     * N = Notification
     * R = Reported Status Mismatch
     * S = Status Change
     * @servertype String
     * @type {string}
     */
    EventType: string = "N";

    /**
     * @name EventValue
     * @description
     * The event value that initiates this trigger.
     * When the event type is notification this is the notification type.
     * When the event type is reported status mismatch this is a colon separated status pair where the first value is the reported
     * status and the second value is the actual status.
     * When the event type is status change this is a colon separated status pair where the first value is the previous status and
     * the second value is the new status.
     * Reported Status Mismatch Examples:
     * S:F - The reported status was success but the transaction failed.
     * F:S - The reported status was failed but the transaction succeeded.
     * Status Change Examples:
     * W:F - The status was waiting and then was updated to failed.
     * Notification Examples:
     * CaptureFailed
     * CaptureRejected
     * RefundFailed
     * RefundRejected
     * RefundForced
     * RequestForInformation
     * DisputeOpened
     * DisputeClosed
     * Chargeback
     * ChargebackReversed
     * Fraud
     * Other
     * @servertype String
     * @type {string}
     */
    EventValue: string = "";

    /**
     * @name EventValueLookup
     * @description
     * Notification payload jpath query for selecting the event value when not otherwise known.
     * @servertype String
     * @type {string}
     */
    EventValueLookup: string = "";

    /**
     * @name EventFilter
     * @description
     * Filter that can be applied against the notification payload for notification event type or applied against the payment transaction
     * record for status event type to determine if the trigger is valid.
     * @servertype String
     * @type {string}
     */
    EventFilter: string = "";

    /**
     * @name PaymentTransactionIdLookup
     * @description
     * Notification payload jpath query for selecting the internal payment transaction id the event is related to.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionIdLookup: string = "";

    /**
     * @name TransactionPaymentIdLookup
     * @description
     * Notification payload jpath query for selecting the payment provider supplied transaction payment id the event is related
     * to.
     * @servertype String
     * @type {string}
     */
    TransactionPaymentIdLookup: string = "";

    /**
     * @name WebhookId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WebhookId: number = 0;

    /**
     * @name RetryTransaction
     * @description
     * When true the referenced transaction should be retried.
     * @servertype Boolean
     * @type {boolean}
     */
    RetryTransaction: boolean = false;

    /**
     * @name ChangeAttributes
     * @description
     * Change payment transaction attributes to those provided (in json format).
     * @servertype String
     * @type {string}
     */
    ChangeAttributes: string = "";

    /**
     * @name ChangeStatus
     * @description
     * Change the payment transaction status to the value provided.
     * @servertype String
     * @type {string}
     */
    ChangeStatus: string = "";

    /**
     * @name TransactionMap
     * @description
     * Mapping from notification payload to payment transaction model for the payment transaction.
     * @servertype String
     * @type {string}
     */
    TransactionMap: string = "";

    /**
     * @name NotificationTransaction
     * @description
     * When true and event type is notification the notification is added to the payment transaction table (recommended).
     * @servertype Boolean
     * @type {boolean}
     */
    NotificationTransaction: boolean = true;

    /**
     * @name NotificationStatus
     * @description
     * The status to set for the notification transaction.
     * @servertype String
     * @type {string}
     */
    NotificationStatus: string = "";

    /**
     * @name IsoCurrencyCode
     * @description
     * ISO Currency Code for notification fee (if any).
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name NotificationFee
     * @description
     * The fee to assign as the amount for the notification transaction.
     * @servertype Double (nullable)
     * @type {number}
     */
    NotificationFee: number = 0;

    /**
     * @name NotificationReason
     * @description
     * A reason to post for the notification transaction.  If not specified the notification type is used.
     * @servertype String
     * @type {string}
     */
    NotificationReason: string = "";

    /**
     * @name NotificationComments
     * @description
     * Comments to post with the notification transaction.
     * @servertype String
     * @type {string}
     */
    NotificationComments: string = "";

    /**
     * @name NotificationMap
     * @description
     * Mapping from notification payload to payment transaction model for the notification.
     * @servertype String
     * @type {string}
     */
    NotificationMap: string = "";

    /**
     * @name InitiateRefund
     * @description
     * When true a refund is initiated for the associated transaction.
     * @servertype Boolean
     * @type {boolean}
     */
    InitiateRefund: boolean = false;

    /**
     * @name RecordRefund
     * @description
     * When true the associated transaction has a refund recorded as successfully completed.
     * @servertype Boolean
     * @type {boolean}
     */
    RecordRefund: boolean = false;

    /**
     * @name RefundPercent
     * @description
     * The percent to refund as decimal (i.e. 0.8 for 80%).  If not specified 100% is refunded.
     * @servertype Double (nullable)
     * @type {number}
     */
    RefundPercent: number = 0;

    /**
     * @name RefundReason
     * @description
     * A reason to post for the refund.
     * @servertype String
     * @type {string}
     */
    RefundReason: string = "";

    /**
     * @name RefundComments
     * @description
     * Comments to post for the refund.
     * @servertype String
     * @type {string}
     */
    RefundComments: string = "";

    /**
     * @name RefundMap
     * @description
     * Mapping from notification payload to payment transaction model for the refund.
     * @servertype String
     * @type {string}
     */
    RefundMap: string = "";

    /**
     * @name TriggerOrder
     * @description
     * The order that triggers should be processed in for scenarios where the order matters.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TriggerOrder: number = 0;

    /**
     * @name StopProcessingTriggers
     * @description
     * When true the trigger processing is halted and no further triggers are processed.
     * @servertype Boolean
     * @type {boolean}
     */
    StopProcessingTriggers: boolean = false;

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PaymentTransactionEditViewModel
 * @description
 * The Payment Transaction table contains electronic payment transactions that have are pending, succeeded or failed.
 */
export class PaymentTransactionEditViewModel {

    /**
     * @name PaymentTransactionId
     * @description
     * The id of the payment transaction.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = null;

    /**
     * @name RequestType
     * @description
     * Flag used to indicate the type of request.  Possible values include:
     * P = Payment
     * R = Refund
     * N = Notification
     * @servertype String
     * @type {string}
     */
    RequestType: string = "P";

    /**
     * @name RequestStatus
     * @description
     * Flag used to indicate the status of the request.  Possible values include:
     * P = Pending (only valid as starting state)
     * C = Cancelled (only valid when previous state was P)
     * R = Running (only valid when previous state was P)
     * W = Waiting (only valid when previous state was R) (submitted to payment provider and still waiting for their result or notification
     * waiting for resolution)
     * A = Authorized (only valid when previous state was R or W)
     * S = Success (only valid when previous state was R, W or A)
     * T = Success, Taxation Pending (transaction was successful; taxation is pending)
     * U = Success, Taxation Running (transaction was successful; taxation is running)
     * V = Voided (only valid when previous state was A)
     * F = Failed (only valid when previous state was R or W)
     * @servertype String
     * @type {string}
     */
    RequestStatus: string = "P";

    /**
     * @name ReportedStatus
     * @description
     * In some configurations payment providers may report an assumed status prior knowing the actual transaction status.  When
     * that happens the reported status is recorded here so any subsequent reconciliation, reporting, etc. that is needed can be
     * performed.
     * @servertype String
     * @type {string}
     */
    ReportedStatus: string = "";

    /**
     * @name RequestResultCode
     * @description
     * The request result code provides more specific information about the outcome of the request beyond that shown in the request
     * status.  Possible values include:
     * 0 = Unspecified
     * 999 = Waiting
     * 1000 = Success
     * 1010 = IncludedTaxCalculationPending
     * 1998 = Cancelled
     * 1999 = Voided
     * 3000 = Failure
     * 3008 = FailureInvalidConfiguration
     * 3009 = FailureInvalidRequest
     * 3010 = FailureInvalidCardNumber
     * 3011 = FailureInvalidExpirationDate
     * 3012 = FailureInvalidCardId
     * 3013 = FailureInvalidBillingAddress
     * 3014 = FailureInvalidAmount
     * 3020 = FailureExpired
     * 3080 = FailureDeclined
     * 3081 = FailureDoNotHonor
     * 3082 = FailureLostOrStolen
     * 3083 = FailureCreditLimitExceeded
     * 3989 = FailureInternalException
     * 3996 = FailureProviderTimeout
     * 3997 = FailureProviderOffline
     * 3998 = FailureProviderError
     * 3999 = FailureUnknown
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RequestResultCode: number = 0;

    /**
     * @name RequestByContactId
     * @description
     * The Contact Id who requested this transaction.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RequestByContactId: number = null;

    /**
     * @name RequestByContactName
     * @servertype String
     * @type {string}
     */
    RequestByContactName: string = "";

    /**
     * @name RequestDateTime
     * @description
     * The date and time this request was initially submitted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RequestDateTime: Date = null;

    /**
     * @name QueueDateTime
     * @description
     * The date and time this record is queued for.  This defaults to the same as request date and time but can be adjusted in situations
     * where the record needs to be processed later.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = null;

    /**
     * @name PaymentProviderId
     * @description
     * The id of the payment provider used for this transaction.
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = null;

    /**
     * @name PaymentMethodId
     * @description
     * The id of the payment method used for this transaction.
     * @servertype String
     * @type {string}
     */
    PaymentMethodId: string = null;

    /**
     * @name PaymentMethodType
     * @description
     * The payment method type.  Possible values include:
     * C = Credit Card
     * D = Debit Card
     * B = Bank Account (Checking)
     * S = Bank Account (Savings)
     * P = PayPal
     * M = Venmo
     * A = Amazon
     * Y = Apple Pay
     * G = Google Pay
     * W = WeChat Pay
     * L = Alipay
     * V = Visa Checkout
     * O = Other
     * Note that Visa Checkout is a wallet and the type is effectively the same as Credit Card but is assigned a unique type to
     * provide some distinction for reporting purposes.
     * @servertype String
     * @type {string}
     */
    PaymentMethodType: string = "C";

    /**
     * @name PaymentMethodTypeId
     * @description
     * A known static identifier that represents this payment method type in a non-volatile manner.  Possible values include:
     * 0 = Unknown
     * 101 = VISA
     * 201 = MasterCard
     * 301 = AmericanExpress
     * 401 = Discover
     * 501 = DinersClub
     * 502 = DinersClubCarteBlanche
     * 503 = DinersClubEnRoute
     * 504 = DinersClubInternational
     * 505 = DinersClubUnitedStatesAndCanada
     * 601 = JCB
     * 1201 = ChinaUnionPay
     * 1301 = InterPayment
     * 1401 = InstaPayment
     * 2201 = Maestro
     * 2301 = Dankort
     * 2401 = MIR
     * 2501 = Verve
     * 3101 = Troy
     * 3501 = BcCard
     * 6101 = Elo
     * 6201 = Aura
     * 70101 = UATP
     * 80101 = PayPal
     * 80201 = Amazon
     * 80301 = ApplePayCardTypeUnknown
     * 80401 = GooglePayCardTypeUnknown
     * 80501 = WeChatPay
     * 80601 = Alipay
     * 80701 = Venmo
     * 80801 = MagensaCardTypeUnknown
     * 89101 = BankAccountChecking
     * 89201 = BankAccountSavings
     * 90101 = StoreCard
     * 91101 = Other
     * 92101 = Custom
     * @servertype Int32
     * @type {number}
     */
    PaymentMethodTypeId: number = 0;

    /**
     * @name AccountNumberReferenceSubset
     * @description
     * The subset of the account number that can be used for a reference.  Typically the last four digits for credit cards and bank
     * accounts.  For payment methods like PayPal or Amazon it is the name of the payment method (e.g. PayPal).
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubset: string = "";

    /**
     * @name IsoCurrencyCode
     * @description
     * The ISO Currency Code for this transaction.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description
     * Amount of authorization, payment or refund.  For notification transaction types this is the fee, if any, associated with
     * the notification type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name RefundedAmount
     * @description
     * Amount of purchase that has been refunded.  For purchase request types this is the total of all refunds that referenced this
     * purchase for the refund.  For other request types this value is always 0.
     * @servertype Double (nullable)
     * @type {number}
     */
    RefundedAmount: number = 0;

    /**
     * @name Priority
     * @description
     * The priority for this request.  A lower value has higher priority.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name RequestAttempt
     * @description
     * The attempt for this request.  Typically 1 but when an attempt fails it may be automatically re-queued in a new row for additional
     * attempts.  In that case, the request attempt value is incremented until it reaches the request attempt maximum.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RequestAttempt: number = 0;

    /**
     * @name RunningApplicationServer
     * @description
     * The application server running this payment request.
     * @servertype String
     * @type {string}
     */
    RunningApplicationServer: string = "";

    /**
     * @name RunningDateTime
     * @description
     * Date and time when the payment server started processing this payment request.  This can be used to help determine if there
     * are any transaction that should have posted success or failure but haven't and may be orphaned.  In that scenario the RunningApplicationServer
     * should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RunningDateTime: Date = null;

    /**
     * @name TransactionDateTime
     * @description
     * The date and time this transaction was processed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDateTime: Date = null;

    /**
     * @name TransactionContactReference
     * @description
     * The contact reference for this transaction.  This is used in cases where a contact needs to be referenced by the payment
     * provider as may be the case when the payment provider has tokenized payment information for the contact and that tokenized
     * payment information is being used as the source of payment.
     * For Braintree PayPal or Venmo this is the payer_id value.
     * For Nubill Wallet this is the Nubill Wallet PaymentTransactionId and can be used to uniquely identify the transaction.
     * @servertype String
     * @type {string}
     */
    TransactionContactReference: string = "";

    /**
     * @name TransactionOrderReference
     * @description
     * The order reference for this transaction.  This is typically a reference to the order or invoice being paid but in could
     * be some other identifier provided when the payment transaction is submitted.
     * For Amazon this is the AmazonOrderReferenceId.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name TransactionBatch
     * @description
     * The batch for this transaction.
     * @servertype String
     * @type {string}
     */
    TransactionBatch: string = "";

    /**
     * @name TransactionAuthorizationCode
     * @description
     * The authorization code for this transaction.
     * For PayPal this is the post-approval payer_id value.
     * For Braintree PayPal or Venmo this is the nonce value.
     * For Amazon this is the AmazonOrderReferenceId.
     * For payment notifications this may be a reference to the payment provider triggering the notification.
     * @servertype String
     * @type {string}
     */
    TransactionAuthorizationCode: string = "";

    /**
     * @name TransactionResultCode
     * @description
     * The result code for this transaction.  This is typically a payment gateway specific result code.
     * For PayPal this is the post-approval token value.
     * @servertype String
     * @type {string}
     */
    TransactionResultCode: string = "";

    /**
     * @name TransactionResultSubCode
     * @description
     * The result sub-code for this transaction.  This is typically a payment gateway specific result code.
     * For PayPal this is the preapproval payment id.
     * @servertype String
     * @type {string}
     */
    TransactionResultSubCode: string = "";

    /**
     * @name TransactionResultMessage
     * @description
     * The result message for this transaction.  This is typically a payment gateway specific result message.
     * @servertype String
     * @type {string}
     */
    TransactionResultMessage: string = "";

    /**
     * @name TransactionRequestData
     * @servertype String
     * @type {string}
     */
    TransactionRequestData: string = "";

    /**
     * @name TransactionResultData
     * @description
     * The result value for this transaction.  This is typically the raw results from the payment gateway.
     * @servertype String
     * @type {string}
     */
    TransactionResultData: string = "";

    /**
     * @name TransactionPaymentId
     * @description
     * The transaction payment id used by the payment provider to identify the payment.
     * For PayPal this is the post-execution sale id.
     * For Braintree this is the transaction id.
     * For Amazon this is the capture id.
     * @servertype String
     * @type {string}
     */
    TransactionPaymentId: string = "";

    /**
     * @name AddressVerificationResult
     * @description
     * Address verification result for this transaction.
     * @servertype String
     * @type {string}
     */
    AddressVerificationResult: string = "";

    /**
     * @name CardIdVerificationResult
     * @description
     * Card Id verification result for this transaction.
     * @servertype String
     * @type {string}
     */
    CardIdVerificationResult: string = "";

    /**
     * @name PaymentBatchId
     * @description
     * A unique computer generated id for all transaction requests of a given batch.  For example, requesting a batch of payment
     * requests for all accounts set to auto-pay their balance will result in each of those payment requests having the same value
     * for this field.  This is NOT related to billing transaction batch id.
     * @servertype String
     * @type {string}
     */
    PaymentBatchId: string = "";

    /**
     * @name LinkedTransactionId
     * @description
     * The id of the payment transaction linked to this transaction.  This is used for retries, refunds and notifications to point
     * back to the original payment transaction to assist with processing and record keeping.  Note that for retires of a refund
     * this is the payment transaction being refunded and never the original refund request being retried.
     * @servertype String
     * @type {string}
     */
    LinkedTransactionId: string = null;

    /**
     * @name LinkedTransactionRequestDateTime
     * @description
     * The request date and time of the linked transaction.  Since transactions are stored in monthly tables this provides a pointer
     * to which monthly table the linked transaction can be found in.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LinkedTransactionRequestDateTime: Date = null;

    /**
     * @name Reason
     * @description
     * For refunds this is the reason for refund.  For notifications this is the notification type.
     * @servertype String
     * @type {string}
     */
    Reason: string = "";

    /**
     * @name Comments
     * @description
     * Comments entered explaining the reason for the refund or details about the notification.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Value001
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Value011
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value011: string = "";

    /**
     * @name Value012
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value012: string = "";

    /**
     * @name Value013
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value013: string = "";

    /**
     * @name Value014
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value014: string = "";

    /**
     * @name Value015
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value015: string = "";

    /**
     * @name Value016
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value016: string = "";

    /**
     * @name Value017
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value017: string = "";

    /**
     * @name Value018
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value018: string = "";

    /**
     * @name Value019
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value019: string = "";

    /**
     * @name Value020
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value020: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Refund
 * @description
 * This contains information for a refund request.
 */
export class PaymentTransactionRefundAddViewModel {

    /**
     * @name PaymentTransactionId
     * @description
     * The id of the payment transaction being refunded.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = "";

    /**
     * @name TransactionOrderReference
     * @description
     * An order reference to include with the transaction.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name IsoCurrencyCode
     * @description
     * The ISO currency code for this refund request.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description
     * The amount to refund.  This cannot exceed the un-refunded portion of the specified payment transaction.  If null the entire
     * un-refunded balance of the payment will be refunded.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name Reason
     * @description
     * The reason for the refund.
     * @servertype String
     * @type {string}
     */
    Reason: string = "";

    /**
     * @name Comments
     * @description
     * Comments regarding the refund.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Value001
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Value011
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value011: string = "";

    /**
     * @name Value012
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value012: string = "";

    /**
     * @name Value013
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value013: string = "";

    /**
     * @name Value014
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value014: string = "";

    /**
     * @name Value015
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value015: string = "";

    /**
     * @name Value016
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value016: string = "";

    /**
     * @name Value017
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value017: string = "";

    /**
     * @name Value018
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value018: string = "";

    /**
     * @name Value019
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value019: string = "";

    /**
     * @name Value020
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value020: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.  If null, the user's configured time
     * zone (not the user's computer time zone) will be used to determine the UTC offset.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

}



/**
 * @name Sale
 * @description
 * This contains information for a sale request.
 */
export class PaymentTransactionSaleAddViewModel {

    /**
     * @name PaymentProviderId
     * @description
     * The id of the payment provider to use for processing this sale.
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = "";

    /**
     * @name PaymentMethodId
     * @description
     * The id of the payment method to use for this sale.
     * @servertype String
     * @type {string}
     */
    PaymentMethodId: string = "";

    /**
     * @name TransactionOrderReference
     * @description
     * An order reference to include with the transaction.
     * @servertype String
     * @type {string}
     */
    TransactionOrderReference: string = "";

    /**
     * @name IsoCurrencyCode
     * @description
     * The ISO currency code for this sale request.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description
     * The amount of the sale.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name Comments
     * @description
     * Comments regarding the sale.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Value001
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Value011
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value011: string = "";

    /**
     * @name Value012
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value012: string = "";

    /**
     * @name Value013
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value013: string = "";

    /**
     * @name Value014
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value014: string = "";

    /**
     * @name Value015
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value015: string = "";

    /**
     * @name Value016
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value016: string = "";

    /**
     * @name Value017
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value017: string = "";

    /**
     * @name Value018
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value018: string = "";

    /**
     * @name Value019
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value019: string = "";

    /**
     * @name Value020
     * @description
     * User defined value for this transaction.  This is ignored by the payment engine but can be used for reporting purposes.
     * @servertype String
     * @type {string}
     */
    Value020: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.  If null, the user's configured time
     * zone (not the user's computer time zone) will be used to determine the UTC offset.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

}



/**
 * @name PaymentTransactionTraceDataViewModel
 */
export class PaymentTransactionTraceDataViewModel {

    /**
     * @name PaymentTransactionId
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = "";

    /**
     * @name RequestDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RequestDateTime: Date = null;

    /**
     * @name TransactionRequestData
     * @servertype String
     * @type {string}
     */
    TransactionRequestData: string = "";

    /**
     * @name TransactionResultData
     * @servertype String
     * @type {string}
     */
    TransactionResultData: string = "";

}



/**
 * @name ProcessStatusDetailEditViewModel
 * @description
 * The ProcessStatusDetailYYYYMM table contains detailed status information about processes.  A table is created for each calendar
 * month using the template ProcessStatusDetailYYYYMM as table name.
 */
export class ProcessStatusDetailEditViewModel {

    /**
     * @name ProcessStatusDetailId
     * @description
     * Process Status Detail Id uniquely identifies this process status row.
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusDetailId: number = null;

    /**
     * @name ProcessStatusId
     * @description
     * The process status id this detail belongs to.
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusId: number = null;

    /**
     * @name StartDateTime
     * @description
     * The process status start date and time.  See process status interval minutes for more information.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name Description
     * @description
     * The description for this process status detail item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Value
     * @description
     * Value for this process status detail item it typically (but not always) equates to a subset of the process count value in
     * the process status table.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value: number = 0;

    /**
     * @name Information
     * @description
     * Information about the value.
     * @servertype String
     * @type {string}
     */
    Information: string = "";

    /**
     * @name DisplayOrder
     * @description
     * The order to display the value in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name DetailGroup
     * @description
     * An optional group for displaying process status details.
     * @servertype String
     * @type {string}
     */
    DetailGroup: string = "";

    /**
     * @name DetailGroupDisplayOrder
     * @description
     * The order to display the group in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DetailGroupDisplayOrder: number = 0;

    /**
     * @name ValueIsDenominator
     * @description
     * The value is denominator and represents 100% of the other values in the group.  This is used to aid in rendering other values
     * a percentage of the total either in grid or chart form.
     * @servertype Boolean
     * @type {boolean}
     */
    ValueIsDenominator: boolean = false;

    /**
     * @name LastUpdateDateTime
     * @description
     * The date and time when the process status detail was last updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdateDateTime: Date = null;

    /**
     * @name TimerCount
     * @description
     * The number of timed events available.  Typically the same as the process count.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerCount: number = 0;

    /**
     * @name TimerTotalMilliseconds
     * @description
     * The total number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerTotalMilliseconds: number = 0;

    /**
     * @name TimerMaximumMilliseconds
     * @description
     * The maximum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMaximumMilliseconds: number = 0;

    /**
     * @name TimerMinimumMilliseconds
     * @description
     * The minimum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMinimumMilliseconds: number = 0;

    /**
     * @name TimerAverageMilliseconds
     * @description
     * The average number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerAverageMilliseconds: number = 0;

    /**
     * @name TimerMedianMilliseconds
     * @description
     * The median number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMedianMilliseconds: number = 0;

    /**
     * @name TimerPercentile90Milliseconds
     * @description
     * The 90th percentile number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerPercentile90Milliseconds: number = 0;

    /**
     * @name TimerStandardDeviationMilliseconds
     * @description
     * The standard deviation number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerStandardDeviationMilliseconds: number = 0;

    /**
     * @name TimerEventsMilliseconds
     * @description
     * A CSV of timer events in milliseconds.  This is the data source for other timer information provided here.
     * @servertype String
     * @type {string}
     */
    TimerEventsMilliseconds: string = "";

    /**
     * @name TimerStatsAsOfDateTime
     * @description
     * The date and time the timer stats were last updated.  For performance reasons these stats are not updated real-time and if
     * this is earlier than the last update date time value the timer stats may be stale.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TimerStatsAsOfDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name ProcessStatusEditViewModel
 * @description
 * The ProcessStatusYYYYMM table contains status information used by processes to convey information about their status.  A
 * table is created for each calendar month using the template ProcessStatusYYYYMM as table name.
 */
export class ProcessStatusEditViewModel {

    /**
     * @name ProcessStatusId
     * @description
     * Process Status Id uniquely identifies this process status row.
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusId: number = null;

    /**
     * @name Description
     * @description
     * The description for this process status.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ProcessType
     * @description
     * The process type for this status.  This will frequently be 'Job' or 'Server' but can be other types.
     * @servertype String
     * @type {string}
     */
    ProcessType: string = "";

    /**
     * @name ProcessTypeId
     * @description
     * The process type id is the key of the process type for this process.  For example, if the process type is Job this value
     * would be the JobId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTypeId: number = null;

    /**
     * @name ProcessTypeId2
     * @description
     * The process type id 2 is the key of the process type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    ProcessTypeId2: string = null;

    /**
     * @name SessionId
     * @description
     * The session id identifies the process type session.  For jobs this is the start count.
     * @servertype String
     * @type {string}
     */
    SessionId: string = "";

    /**
     * @name StatusIntervalMinutes
     * @description
     * The number of minutes after which a process will spin off a new process status record.  A value of 0 means never.  Most non-real-time
     * jobs will have a value of 0 and an incrementing SessionId value which results in one process status record for each execution
     * of the job.  Most real-time jobs or other processes will a value that will cause process status information to be recorded
     * for given time intervals.  For example, a value of 60 would result in a new process status record being generated every hour
     * on the hour.  Settings other than zero are not moving windows but are fixed windows based on the start of the month, week,
     * day or hour depending on if the value is in range for a month, week, day, or hour.  Examples: When set to 60, stats are for
     * the hour (e.g. 08:00:00).  When set to 15, stats are for the 15 minute window (e.g. 12:15:00).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatusIntervalMinutes: number = 0;

    /**
     * @name ServerName
     * @description
     * The name of the server running this process.
     * @servertype String
     * @type {string}
     */
    ServerName: string = "";

    /**
     * @name ServerProcessId
     * @description
     * The server process id is the process identifier assigned by the server.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ServerProcessId: number = 0;

    /**
     * @name ProcessCount
     * @description
     * Process count represents the portion of ProcessTotal that has been processed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessCount: number = 0;

    /**
     * @name ProcessTotal
     * @description
     * Process total represents the total number of items to be processed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTotal: number = 0;

    /**
     * @name ProcessTime
     * @description
     * Number of seconds this process has been actively processing data.  This is different than simply the number of seconds from
     * the start time until now since there could be long periods of inactivity while waiting for data to process.  This number
     * more accurately represents the actual processing time that is involved and when combined with ProcessCount can be used to
     * calculate speed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTime: number = 0;

    /**
     * @name StartDateTime
     * @description
     * The process status start date and time.  See status interval minutes for more information.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name LastUpdateDateTime
     * @description
     * The date and time when the process status was last updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdateDateTime: Date = null;

    /**
     * @name DataProcessedDateTime
     * @description
     * The date and time of the last time data was processed by the process.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DataProcessedDateTime: Date = null;

    /**
     * @name FinishDateTime
     * @description
     * The process status finish date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishDateTime: Date = null;

    /**
     * @name CancelNow
     * @description
     * When true the process has been requested to cancel itself.  It's up to the actual process to handle this request.
     * @servertype Boolean
     * @type {boolean}
     */
    CancelNow: boolean = false;

    /**
     * @name Status
     * @description
     * Status of the process.  Possible values include:
     * R = Running
     * C = Cancelled
     * F = Finished
     * M = Moved to new Process Status table and/or row
     * S = Suspect (was running but not updated in a timely manner)
     * U = Unknown
     * N = N/A
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name ProcessMemoryUsedKb
     * @description
     * Amount of memory used by this process (expressed in KB).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ProcessMemoryUsedKb: number = 0;

    /**
     * @name ServerMemoryFreeKb
     * @description
     * Amount of memory free on the server (expressed in KB).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ServerMemoryFreeKb: number = 0;

    /**
     * @name ServerMemoryTotalKb
     * @description
     * Total amount of memory on the server (expressed in KB).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ServerMemoryTotalKb: number = 0;

    /**
     * @name ErrorCount
     * @description
     * Count of errors.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ErrorCount: number = 0;

    /**
     * @name ErrorMessage
     * @description
     * Error Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    ErrorMessage: string = "";

    /**
     * @name WarningCount
     * @description
     * Count of warnings.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WarningCount: number = 0;

    /**
     * @name WarningMessage
     * @description
     * Warning Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    WarningMessage: string = "";

    /**
     * @name OmittedCount
     * @description
     * Count of omissions.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OmittedCount: number = 0;

    /**
     * @name OmittedMessage
     * @description
     * Omitted Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    OmittedMessage: string = "";

    /**
     * @name AlarmCount
     * @description
     * Count of alarms.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AlarmCount: number = 0;

    /**
     * @name AlarmMessage
     * @description
     * Alarm Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    AlarmMessage: string = "";

    /**
     * @name Feedback
     * @description
     * Feedback from process (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    Feedback: string = "";

    /**
     * @name ResultCode
     * @description
     * Result code for completed processes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description
     * Result message for completed processes.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name TimerCount
     * @description
     * The number of timed events available.  Typically the same as the process count.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerCount: number = 0;

    /**
     * @name TimerTotalMilliseconds
     * @description
     * The total number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerTotalMilliseconds: number = 0;

    /**
     * @name TimerMaximumMilliseconds
     * @description
     * The maximum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMaximumMilliseconds: number = 0;

    /**
     * @name TimerMinimumMilliseconds
     * @description
     * The minimum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMinimumMilliseconds: number = 0;

    /**
     * @name TimerAverageMilliseconds
     * @description
     * The average number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerAverageMilliseconds: number = 0;

    /**
     * @name TimerMedianMilliseconds
     * @description
     * The median number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMedianMilliseconds: number = 0;

    /**
     * @name TimerPercentile90Milliseconds
     * @description
     * The 90th percentile number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerPercentile90Milliseconds: number = 0;

    /**
     * @name TimerStandardDeviationMilliseconds
     * @description
     * The standard deviation number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerStandardDeviationMilliseconds: number = 0;

    /**
     * @name TimerEventsMilliseconds
     * @description
     * A CSV of timer events in milliseconds.  This is the data source for other timer information provided here.
     * @servertype String
     * @type {string}
     */
    TimerEventsMilliseconds: string = "";

    /**
     * @name TimerStatsAsOfDateTime
     * @description
     * The date and time the timer stats were last updated.  For performance reasons these stats are not updated real-time and if
     * this is earlier than the last update date time value the timer stats may be stale.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TimerStatsAsOfDateTime: Date = null;

    /**
     * @name Details
     * @servertype IProcessStatusDetailModel array
     * @type {m.IProcessStatusDetailModel[]}
     */
    Details: m.IProcessStatusDetailModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name RoleDetailEditViewModel
 * @description
 * The Role Detail table contains information about security access areas and rights for the specified role.
 */
export class RoleDetailEditViewModel {

    /**
     * @name RoleDetailId
     * @description
     * The id of the Role Detail.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleDetailId: number = null;

    /**
     * @name RoleId
     * @description
     * The id of the role this detail belongs to.
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name SecurityAreaType
     * @description
     * This is the security area type.  Possible values include:
     * TB = Table
     * TG = Table Group
     * RP = Report
     * RG = Report Group
     * PA = Permission Area
     * SI = Sensitive Information
     * @servertype String
     * @type {string}
     */
    SecurityAreaType: string = "PA";

    /**
     * @name SecurityArea
     * @description
     * The security area that is part of this role.  This is based on the security area type and could be a table name, table group,
     * report, report group or permission area (standard or custom).  For security area type of sensitive information this is a
     * category of sensitive information as defined in a pick list and used in the data table support object's sensitive information
     * handler data.
     * @servertype String
     * @type {string}
     */
    SecurityArea: string = "";

    /**
     * @name SecurityRights
     * @description
     * This is a comma delimited list of rights for the specified security access area.  Values in the comma delimited list can
     * include:
     * S = Read Single
     * R = Read
     * A = Add
     * E = Edit
     * D = Delete
     * O = Output (print/export)
     * X = Execute
     * F = Full Access
     * Note that a security right flag can be prefixed with a ! operator to deny access.  The deny operator has first priority so,
     * for example, values of R,A,E,!D which would result in deny delete access on the security area.  This is different than simply
     * excluding the delete (D) access since an explicit deny delete (!D) would have priority over other roles that may include
     * delete (D) access for the same area.
     * Each security flag can have one or more scope indicators applied as a suffix.  If no scope indicator is specified owner scoping
     * is applied.  Note that not all security areas utilize scoping.  Possible scope indicators include:
     * 1 = Owner (objects the user 'owns')
     * 2 = Supervisor (objects the user has supervisory responsibility for)
     * 3 = Nexus (objects the user has nexus to)
     * 6 = Group (objects owned by groups this user is belongs to)
     * 7 = Child Group (objects owned by groups descended from groups this user belongs to)
     * 8 = Group Members (objects owned by users in the same groups as this user)
     * 9 = Child Group Members (objects owned by users in groups descended from groups this user belongs to)
     * 0 = None (no scoping is applied)
     * Note that some scope indicators are inclusive of other, more narrow, scope indicators by nature.  For example, nexus scope
     * (3) by it's nature would include supervisory scope (2) and ownership scope (1).  For example, if the table group 'Case' had
     * security rights R38,E12,D1 the user would be able to read (R) cases where the user had nexus (3) or was in a group with the
     * case owner (8); could edit (E) cases where the user owned (1) or was supervisor (2) for the case; could delete (D) cases
     * where the user owned (1) the case.
     * For sensitive information categories the following mutually exclusive values are used:
     * X = Sensitive information is never unmasked
     * R = Sensitive information is always unmasked
     * S = Sensitive information is unmasked upon request
     * @servertype String
     * @type {string}
     */
    SecurityRights: string = "";

    /**
     * @name AllowRead
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowRead: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowAdd
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowAdd: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowEdit
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowEdit: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowDelete
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowDelete: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowOutput
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowOutput: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowExecute
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowExecute: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowFull
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowFull: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyRead
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyRead: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyAdd
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyAdd: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyEdit
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyEdit: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyDelete
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyDelete: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyOutput
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyOutput: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyExecute
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyExecute: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyFull
     * @servertype IB.Data.Model.Wallet.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyFull: RoleDetailPermissionEditViewModel = null;

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RoleDetailPermissionEditViewModel
 */
export class RoleDetailPermissionEditViewModel {

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ScopeNone
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeNone: boolean = false;

    /**
     * @name ScopeOwner
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeOwner: boolean = false;

    /**
     * @name ScopeSupervisor
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeSupervisor: boolean = false;

    /**
     * @name ScopeNexus
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeNexus: boolean = false;

    /**
     * @name ScopeGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeGroup: boolean = false;

    /**
     * @name ScopeChildGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeChildGroup: boolean = false;

}



/**
 * @name RoleEditViewModel
 * @description
 * The Role table contains information about roles.  Roles are used to manage security credentials.
 */
export class RoleEditViewModel {

    /**
     * @name RoleId
     * @description
     * The id of the Role.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name Description
     * @description
     * The description for this role.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the role is enabled.  Setting to false provides an easy way to cause a role to be temporarily ignored until later
     * enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Anonymous
     * @description
     * When true the role is used to define access for anonymous users.
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

    /**
     * @name ValidContactTypes
     * @description
     * A comma delimited list of contact types that this role is valid for.  This prevents accidental assignment to contact types
     * that are not appropriate for this role.  Possible values include all contact types.
     * @servertype String
     * @type {string}
     */
    ValidContactTypes: string = "";

    /**
     * @name AutomaticContactTypes
     * @description
     * A comma delimited list of contact types that this role is automatically applied to.  This provides a quick mechanism for
     * applying roles to all contacts of the specified type(s).
     * @servertype String
     * @type {string}
     */
    AutomaticContactTypes: string = "";

    /**
     * @name Properties
     * @description
     * Additional properties relevant to the role.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Details
     * @description
     * List of details for the role.
     * @servertype RoleDetailEditViewModel array
     * @type {RoleDetailEditViewModel[]}
     */
    Details: RoleDetailEditViewModel[] = [];

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name SettingEditViewModel
 * @description
 * The Setting table contains system settings.
 */
export class SettingEditViewModel {

    /**
     * @name SettingId
     * @description
     * Setting Id uniquely identifies this setting.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SettingId: number = null;

    /**
     * @name Category
     * @description
     * Category.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Attribute
     * @description
     * The attribute.
     * @servertype String
     * @type {string}
     */
    Attribute: string = "";

    /**
     * @name Value
     * @description
     * Value for this attribute.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Protected
     * @description
     * True indicates the setting is protected from anonymous reading.  Since settings are frequently needed for various configuration
     * settings including those needed by anonymous users for portal branding, etc. this table is frequently accessed without any
     * permission checks.  If Protected is true then the user is required to have specific read access before this setting value
     * can be returned.
     * @servertype Boolean
     * @type {boolean}
     */
    Protected: boolean = false;

    /**
     * @name Hidden
     * @description
     * True indicates the value is hidden from setting configuration screens and reports.  This can also be used when the setting
     * is private to the user who added it.
     * @servertype Boolean
     * @type {boolean}
     */
    Hidden: boolean = false;

    /**
     * @name ReadOnly
     * @description
     * True indicates the value is read-only and cannot be configured.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name Encrypted
     * @description
     * True indicates the value is encrypted.
     * @servertype Boolean
     * @type {boolean}
     */
    Encrypted: boolean = false;

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name WalletProxyViewModel
 */
export class WalletProxyViewModel {

    /**
     * @name PostUrl
     * @servertype String
     * @type {string}
     */
    PostUrl: string = "";

    /**
     * @name PostTimeoutSeconds
     * @servertype Int32
     * @type {number}
     */
    PostTimeoutSeconds: number = 0;

    /**
     * @name PostHeaders
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    PostHeaders: {[key: string]:  string} = {};

    /**
     * @name PassThroughHeaders
     * @servertype string array
     * @type {string[]}
     */
    PassThroughHeaders: string[] = [];

    /**
     * @name WebExceptionTriggerEmail
     * @servertype Boolean
     * @type {boolean}
     */
    WebExceptionTriggerEmail: boolean = false;

    /**
     * @name TokenPropertyName
     * @servertype String
     * @type {string}
     */
    TokenPropertyName: string = "";

    /**
     * @name TokenKeyName
     * @servertype String
     * @type {string}
     */
    TokenKeyName: string = "";

    /**
     * @name TokenType
     * @servertype IB.Data.Model.Wallet.v5.WalletProxyTokenPropertyType Enum
     * @type {WalletProxyTokenPropertyType}
     */
    TokenType: WalletProxyTokenPropertyType = null;

    /**
     * @name PaymentMethodTypeIdPropertyName
     * @servertype String
     * @type {string}
     */
    PaymentMethodTypeIdPropertyName: string = "";

    /**
     * @name CreditCardTypePropertyName
     * @servertype String
     * @type {string}
     */
    CreditCardTypePropertyName: string = "";

    /**
     * @name DescriptionPropertyName
     * @servertype String
     * @type {string}
     */
    DescriptionPropertyName: string = "";

    /**
     * @name AccountNumberReferenceSubsetPropertyName
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubsetPropertyName: string = "";

    /**
     * @name BankNamePropertyName
     * @servertype String
     * @type {string}
     */
    BankNamePropertyName: string = "";

    /**
     * @name RoutingNumberPropertyName
     * @servertype String
     * @type {string}
     */
    RoutingNumberPropertyName: string = "";

    /**
     * @name AccountNumberPropertyName
     * @servertype String
     * @type {string}
     */
    AccountNumberPropertyName: string = "";

    /**
     * @name ExpirationDatePropertyName
     * @servertype String
     * @type {string}
     */
    ExpirationDatePropertyName: string = "";

    /**
     * @name AccountIdPropertyName
     * @servertype String
     * @type {string}
     */
    AccountIdPropertyName: string = "";

    /**
     * @name Value001PropertyName
     * @servertype String
     * @type {string}
     */
    Value001PropertyName: string = "";

    /**
     * @name Value002PropertyName
     * @servertype String
     * @type {string}
     */
    Value002PropertyName: string = "";

    /**
     * @name Value003PropertyName
     * @servertype String
     * @type {string}
     */
    Value003PropertyName: string = "";

    /**
     * @name Value004PropertyName
     * @servertype String
     * @type {string}
     */
    Value004PropertyName: string = "";

    /**
     * @name Value005PropertyName
     * @servertype String
     * @type {string}
     */
    Value005PropertyName: string = "";

    /**
     * @name Value006PropertyName
     * @servertype String
     * @type {string}
     */
    Value006PropertyName: string = "";

    /**
     * @name Value007PropertyName
     * @servertype String
     * @type {string}
     */
    Value007PropertyName: string = "";

    /**
     * @name Value008PropertyName
     * @servertype String
     * @type {string}
     */
    Value008PropertyName: string = "";

    /**
     * @name Value009PropertyName
     * @servertype String
     * @type {string}
     */
    Value009PropertyName: string = "";

    /**
     * @name Value010PropertyName
     * @servertype String
     * @type {string}
     */
    Value010PropertyName: string = "";

    /**
     * @name BillingNamePropertyName
     * @servertype String
     * @type {string}
     */
    BillingNamePropertyName: string = "";

    /**
     * @name BillingFirstNamePropertyName
     * @servertype String
     * @type {string}
     */
    BillingFirstNamePropertyName: string = "";

    /**
     * @name BillingLastNamePropertyName
     * @servertype String
     * @type {string}
     */
    BillingLastNamePropertyName: string = "";

    /**
     * @name BillingCompanyNamePropertyName
     * @servertype String
     * @type {string}
     */
    BillingCompanyNamePropertyName: string = "";

    /**
     * @name BillingAddress1PropertyName
     * @servertype String
     * @type {string}
     */
    BillingAddress1PropertyName: string = "";

    /**
     * @name BillingAddress2PropertyName
     * @servertype String
     * @type {string}
     */
    BillingAddress2PropertyName: string = "";

    /**
     * @name BillingAddress3PropertyName
     * @servertype String
     * @type {string}
     */
    BillingAddress3PropertyName: string = "";

    /**
     * @name BillingCityPropertyName
     * @servertype String
     * @type {string}
     */
    BillingCityPropertyName: string = "";

    /**
     * @name BillingStatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingStatePropertyName: string = "";

    /**
     * @name BillingPostalCodePropertyName
     * @servertype String
     * @type {string}
     */
    BillingPostalCodePropertyName: string = "";

    /**
     * @name BillingCountryPropertyName
     * @servertype String
     * @type {string}
     */
    BillingCountryPropertyName: string = "";

    /**
     * @name BillingPhonePropertyName
     * @servertype String
     * @type {string}
     */
    BillingPhonePropertyName: string = "";

    /**
     * @name BillingNameAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingNameAlternatePropertyName: string = "";

    /**
     * @name BillingFirstNameAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingFirstNameAlternatePropertyName: string = "";

    /**
     * @name BillingLastNameAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingLastNameAlternatePropertyName: string = "";

    /**
     * @name BillingCompanyNameAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingCompanyNameAlternatePropertyName: string = "";

    /**
     * @name BillingAddress1AlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingAddress1AlternatePropertyName: string = "";

    /**
     * @name BillingAddress2AlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingAddress2AlternatePropertyName: string = "";

    /**
     * @name BillingAddress3AlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingAddress3AlternatePropertyName: string = "";

    /**
     * @name BillingCityAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingCityAlternatePropertyName: string = "";

    /**
     * @name BillingStateAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingStateAlternatePropertyName: string = "";

    /**
     * @name BillingPostalCodeAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingPostalCodeAlternatePropertyName: string = "";

    /**
     * @name BillingCountryAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingCountryAlternatePropertyName: string = "";

    /**
     * @name BillingPhoneAlternatePropertyName
     * @servertype String
     * @type {string}
     */
    BillingPhoneAlternatePropertyName: string = "";

    /**
     * @name VisaCheckoutCheckPropertyName
     * @servertype String
     * @type {string}
     */
    VisaCheckoutCheckPropertyName: string = "";

    /**
     * @name VisaCheckoutCheckValue
     * @servertype String
     * @type {string}
     */
    VisaCheckoutCheckValue: string = "";

    /**
     * @name VisaCheckoutCallIdPropertyName
     * @servertype String
     * @type {string}
     */
    VisaCheckoutCallIdPropertyName: string = "";

    /**
     * @name VisaCheckoutEncPaymentDataPropertyName
     * @servertype String
     * @type {string}
     */
    VisaCheckoutEncPaymentDataPropertyName: string = "";

    /**
     * @name VisaCheckoutEncryptionKeyTagPropertyName
     * @servertype String
     * @type {string}
     */
    VisaCheckoutEncryptionKeyTagPropertyName: string = "";

    /**
     * @name VisaCheckoutApiKeyPropertyName
     * @servertype String
     * @type {string}
     */
    VisaCheckoutApiKeyPropertyName: string = "";

    /**
     * @name VisaCheckoutDynamicKeyPropertyName
     * @servertype String
     * @type {string}
     */
    VisaCheckoutDynamicKeyPropertyName: string = "";

    /**
     * @name SampleRequest
     * @servertype System.Object
     * @type {any}
     */
    SampleRequest: any = {};

    /**
     * @name SampleResponse
     * @servertype System.Object
     * @type {any}
     */
    SampleResponse: any = {};

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

}



/**
 * @name WalletProxyTokenPropertyType Enum
 * @readonly
 * @enum {number}
 */
export enum WalletProxyTokenPropertyType {

    /**
     * String
     */
    String = 0,

    /**
     * Key Value Pair
     */
    KeyValuePair = 1

}



/**
 * @name WebhookEditViewModel
 * @description
 * The Webhook table contains information about webhooks that enable external systems to be notified of events.
 */
export class WebhookEditViewModel {

    /**
     * @name WebhookId
     * @description
     * Webhook Id uniquely identifies this webhook.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WebhookId: number = null;

    /**
     * @name Description
     * @description
     * Description for this webhook.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Type
     * @description
     * The webhook type.  This is especially important for incoming webhooks where the type is part of the target url.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Enabled
     * @description
     * True indicates the webhook is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Incoming
     * @description
     * True indicates the webhook is an incoming webhook.
     * @servertype Boolean
     * @type {boolean}
     */
    Incoming: boolean = false;

    /**
     * @name Url
     * @description
     * A list of one or more urls to which the webhook event data will be delivered.  For security reasons this should be a secure
     * endpoint either via https, vpn, or private lan.  Because anyone can theoretically POST a payload to the url, we sign messages.
     * Event headers for messages contain the signature and information that you can use to validate the signature as described
     * in the documentation.
     * Webhook endpoints may occasionally receive the same event more than once.  Guard against duplicated event receipts by making
     * event processing idempotent. One way of doing this is logging the events processed, and then not processing already-logged
     * events.
     * Note that this is N/A for incoming webhook events as incoming webhook events are all sent to a fixed url that is distinguished
     * from other incoming webhooks by the type property.
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name EventTypes
     * @description
     * A list of event types this webhook is triggered for.  Use * for wildcards.  Formatting generally follows a pattern of dot
     * delimited sections in format {category}.{item}.{operation}.{success} to identify the event type.
     * For api endpoints the pattern is api.{endpoint-path}.{type}.{result} as shown in these examples:
     * api.* = all api events
     * api.billing/purchases.* = all api billing purchases events
     * api.billing/purchases.add.* = all api billing purchases add events
     * api.billing/purchases.add.success = successful api billing purchases add events
     * api.billing/purchases.add.fail = failed api billing purchases add events
     * @servertype string array
     * @type {string[]}
     */
    EventTypes: string[] = [];

    /**
     * @name ContentType
     * @description
     * The content type to use for serializing data.  Possible values include:
     * JSON = json
     * XML = xml
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name Secret
     * @description
     * An optional string used to create a signature for webhook posts.  If no secret is provided a public/private key is used for
     * signing messages as provided for with the signature encryption key tag.
     * @servertype String
     * @type {string}
     */
    Secret: string = "";

    /**
     * @name Login
     * @description
     * An optional login to use for basic access authentication.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Password
     * @description
     * An optional password to use for basic access authentication.
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name SignatureEncryptionKeyServerId
     * @description
     * The id of the encryption key management server used for signature keys.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SignatureEncryptionKeyServerId: number = 0;

    /**
     * @name SignatureEncryptionKeyTag
     * @description
     * The encryption key tag provided by the key management server used for signature keys.
     * @servertype String
     * @type {string}
     */
    SignatureEncryptionKeyTag: string = "";

    /**
     * @name Configuration
     * @description
     * A list of key/value pairs that provide settings for this webhook.  For example:
     * SourceIpAddress = A CSV of ip addresses that can be used as the source ip address for outgoing webhooks.
     * @servertype String
     * @type {string}
     */
    Configuration: string = "";

    /**
     * @name Headers
     * @description
     * A list of key/value pairs that are included as headers in messages sent to webhook url.
     * @servertype String
     * @type {string}
     */
    Headers: string = "";

    /**
     * @name Format
     * @description
     * For custom payload formats this is the format mapping.  When used this typically requires this webhook to subscribe to a
     * limited number of event types that support the same format map.
     * @servertype String
     * @type {string}
     */
    Format: string = "";

    /**
     * @name FormatDataSource
     * @description
     * When format mapping is used this documents the data source for that mapping.
     * @servertype String
     * @type {string}
     */
    FormatDataSource: string = "";

    /**
     * @name ResponseFormat
     * @description
     * This is the response format mapping.  For incoming webhooks this represents the format of the response to send back.  For
     * outgoing webhooks this is typically N/A since the http status code is used to determine the results but in complex scenarios
     * this can be used to help interpret the response body.
     * @servertype String
     * @type {string}
     */
    ResponseFormat: string = "";

    /**
     * @name ResponseFormatDataSource
     * @description
     * When response format mapping is used this documents the data source for that mapping.
     * @servertype String
     * @type {string}
     */
    ResponseFormatDataSource: string = "";

    /**
     * @name SignatureData
     * @description
     * The signature data to use.  Possible values include:
     *  = None
     * Body = Body
     * Keys = Keys (TransmissionId|TransmissionTime|WebhookId|PayloadCrc32)
     * @servertype String
     * @type {string}
     */
    SignatureData: string = "";

    /**
     * @name SignatureAlgorithm
     * @description
     * The signature algorithm to use.  The url receiving the post via this webhook must verify that messages originated from the
     * system, were not altered or corrupted during transmission, were targeted for the receiver, and contain a valid signature.
     * To validate the signature, use the information in event headers.  Possible signature algorithm values include:
     * HMAC = HMAC
     * @servertype String
     * @type {string}
     */
    SignatureAlgorithm: string = "";

    /**
     * @name NotificationGroupId
     * @description
     * Notification group to notify when webhook event occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name CaseTemplateId
     * @description
     * Case template for case to create when webhook event occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = null;

    /**
     * @name TriggerAssetId
     * @description
     * The Asset Id to use for trigger to execute for processing event (incoming) or event response (outgoing).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = null;

    /**
     * @name RealTimeDelivery
     * @description
     * True indicates the webhook requests real-time delivery when possible.
     * @servertype Boolean
     * @type {boolean}
     */
    RealTimeDelivery: boolean = false;

    /**
     * @name RetryIntervalMinutes
     * @description
     * The number of minutes to wait between retry for failed delivery.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryIntervalMinutes: number = 0;

    /**
     * @name RetryLimitMinutes
     * @description
     * The maximum number of minutes to attempt delivery.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryLimitMinutes: number = 0;

    /**
     * @name Trace
     * @description
     * True indicates trace information should be logged with the webhook event.
     * @servertype Boolean
     * @type {boolean}
     */
    Trace: boolean = false;

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name UtcOffset
     * @description
     * The UTC Offset (e.g. -5, +8.5, etc.) that all date time properties are expressed in.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



