import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { ApiDocsSettings } from '../config/api-docs-settings';

import * as m from "projects/core-lib/src/lib/api/ApiModels";
import * as mcore from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5pay from "projects/core-lib/src/lib/models/ngModelsPayment5";
import * as m5rc from "projects/core-lib/src/lib/models/ngModelsReportCompiler5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5wallet from "projects/core-lib/src/lib/models/ngModelsWallet5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5usage from "projects/core-lib/src/lib/models/ngModelsUsage5";
import * as m5telecom from "projects/core-lib/src/lib/models/ngModelsTelecom5";

import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiModuleCore } from 'projects/core-lib/src/lib/api/Api.Module.Core';
import { ApiModulePayment } from 'projects/core-lib/src/lib/api/Api.Module.Payment';
import { ApiModuleReportCompiler } from 'projects/core-lib/src/lib/api/Api.Module.ReportCompiler';
import { ApiModuleSecurity } from 'projects/core-lib/src/lib/api/Api.Module.Security';
import { ApiModuleWeb } from 'projects/core-lib/src/lib/api/Api.Module.Web';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { Observable, of, AsyncSubject } from 'rxjs';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiModuleUsage } from '../api/Api.Module.Usage';
import { ApiModuleTelecom } from '../api/Api.Module.Telecom';

@Injectable({
  providedIn: 'root'
})
export class ApiDocsService {

  apiNamesStandard: string[] = [];
  apiNamesCore: string[] = [];
  apiNamesPayment: string[] = [];
  apiNamesRC: string[] = [];
  apiNamesSecurity: string[] = [];
  apiNamesWeb: string[] = [];
  apiNamesUsage: string[] = [];
  apiNamesTelecom: string[] = [];

  get apiNames(): string[] {
    const data: string[] = [];
    data.push(...this.apiNamesStandard);
    data.push(...this.apiNamesCore);
    data.push(...this.apiNamesPayment);
    data.push(...this.apiNamesRC);
    data.push(...this.apiNamesSecurity);
    data.push(...this.apiNamesWeb);
    data.push(...this.apiNamesUsage);
    data.push(...this.apiNamesTelecom);
    return data;
  }

  validEndpointsStandard: m.ApiEndpointViewModel[] = [];
  validEndpointsCore: m.ApiEndpointViewModel[] = [];
  validEndpointsPayment: m.ApiEndpointViewModel[] = [];
  validEndpointsRC: m.ApiEndpointViewModel[] = [];
  validEndpointsSecurity: m.ApiEndpointViewModel[] = [];
  validEndpointsWeb: m.ApiEndpointViewModel[] = [];
  validEndpointsUsage: m.ApiEndpointViewModel[] = [];
  validEndpointsTelecom: m.ApiEndpointViewModel[] = [];

  get validEndpoints(): m.ApiEndpointViewModel[] {
    const data: m.ApiEndpointViewModel[] = [];
    data.push(...this.validEndpointsStandard);
    data.push(...this.validEndpointsCore);
    data.push(...this.validEndpointsPayment);
    data.push(...this.validEndpointsRC);
    data.push(...this.validEndpointsSecurity);
    data.push(...this.validEndpointsWeb);
    data.push(...this.validEndpointsUsage);
    data.push(...this.validEndpointsTelecom);
    return data;
  }

  protected dataModelCategoryList: m.DataModelList[] = [];
  protected dataModelTableList: m.DataModelList[] = [];
  protected dataModelViewList: m.DataModelList[] = [];
  protected dataModelTableAndViewList: m.DataModelList[] = [];


  protected apiProperties: m.ApiProperties = null;
  protected apiList: m.ApiCall = null;
  protected apiGet: m.ApiCall = null;

  protected apiPropertiesRaw: m.ApiProperties = null;
  protected apiListRaw: m.ApiCall = null;
  protected apiGetRaw: m.ApiCall = null;

  constructor(
    protected appService: AppService,
    protected apiService: ApiService) {

    this.loadApiNames();
    this.loadValidEndpoints();

    this.apiProperties = Api.DocumentationDataModel();
    this.apiList = ApiHelper.createApiCall(this.apiProperties, m.ApiOperationType.List);
    this.apiGet = ApiHelper.createApiCall(this.apiProperties, m.ApiOperationType.Get);

    this.apiPropertiesRaw = Api.DocumentationRawDataModel();
    this.apiListRaw = ApiHelper.createApiCall(this.apiPropertiesRaw, m.ApiOperationType.List);
    this.apiGetRaw = ApiHelper.createApiCall(this.apiPropertiesRaw, m.ApiOperationType.Get);

  }


  protected loadApiNames() {

    this.apiNamesStandard = Api.GetListOfApiPropertiesMethods();
    this.apiNamesCore = ApiModuleCore.GetListOfApiPropertiesMethods();
    this.apiNamesPayment = ApiModulePayment.GetListOfApiPropertiesMethods();
    this.apiNamesRC = ApiModuleReportCompiler.GetListOfApiPropertiesMethods();
    this.apiNamesSecurity = ApiModuleSecurity.GetListOfApiPropertiesMethods();
    this.apiNamesWeb = ApiModuleWeb.GetListOfApiPropertiesMethods();
    this.apiNamesUsage = ApiModuleUsage.GetListOfApiPropertiesMethods();
    this.apiNamesTelecom = ApiModuleTelecom.GetListOfApiPropertiesMethods();

  }

  protected loadValidEndpoints() {

    if (!this.apiNamesStandard || this.apiNamesStandard.length === 0) {
      this.loadApiNames();
    }

    this.validEndpointsStandard = this.getValidEndpoints(this.apiNamesStandard, Api);
    this.validEndpointsCore = this.getValidEndpoints(this.apiNamesCore, ApiModuleCore);
    this.validEndpointsPayment = this.getValidEndpoints(this.apiNamesPayment, ApiModulePayment);
    this.validEndpointsRC = this.getValidEndpoints(this.apiNamesRC, ApiModuleReportCompiler);
    this.validEndpointsSecurity = this.getValidEndpoints(this.apiNamesSecurity, ApiModuleSecurity);
    this.validEndpointsWeb = this.getValidEndpoints(this.apiNamesWeb, ApiModuleWeb);
    this.validEndpointsUsage = this.getValidEndpoints(this.apiNamesUsage, ApiModuleUsage);
    this.validEndpointsTelecom = this.getValidEndpoints(this.apiNamesTelecom, ApiModuleTelecom);

  }


  protected getValidEndpoints(apiNames: string[], apiModule: any): m.ApiEndpointViewModel[] {

    const data: m.ApiEndpointViewModel[] = [];

    apiNames.forEach(name => {

      // If the entire api is not valid then move on to the next one
      if (!this.isValidApiEndpoint(name)) {
        return;
      }

      const api: m.ApiProperties = apiModule.GetApi(name);
      if (!api) {
        console.error(`Unable to find api properties for ${name}`);
        return;
      }

      // If this api is brand specific and we're not looking for that brand then exclude from our endpoint list
      if (api.brands && api.brands.length > 0 && AppConfig.brand && !Helper.equals(AppConfig.brand, "all", true)) {
        const brand: string = Helper.firstOrDefault(api.brands, x => Helper.equals(x, AppConfig.brand, true));
        if (!brand) {
          return;
        }
      }

      // Wallet brand is opt-in only
      if (AppConfig.brand && Helper.equals(AppConfig.brand, "Wallet", true)) {
        const brand: string = Helper.firstOrDefault(api.brands, x => Helper.equals(x, AppConfig.brand, true));
        if (!brand) {
          return;
        }
      }

      // If this api is tied to a module and we have 1+ modules loaded then verify we have that module or exclude from our endpoint list
      if (api.modules && api.modules.length > 0 && this.appService?.appInfoOrDefault?.Modules && this.appService.appInfoOrDefault.Modules.length > 0) {
        let hasModule = false;
        api.modules.forEach(module => {
          if (this.appService.hasModule(module)) {
            hasModule = true;
          }
        });
        if (!hasModule) {
          return;
        }
      }

      // Include each endpoint that isn't private (i.e. has an api docs url base property defined)
      api.endpoints.forEach((endpoint: m.ApiEndpoint, index: number, array: m.ApiEndpoint[]) => {
        if (!this.isValidApiEndpoint(api.id, endpoint.type, endpoint.id)) {
          // Skip this endpoint and continue on with the next one in the list
          return;
        }
        const model: m.ApiEndpointViewModel = new m.ApiEndpointViewModel();
        model.apiName = name;
        model.objectName = api.documentation.objectName;
        model.objectDescription = api.documentation.objectDescription;
        model.endpointDescription = m.ApiOperationType[endpoint.type] + " " + api.documentation.objectDescription;
        if (endpoint.type === m.ApiOperationType.List || endpoint.type === m.ApiOperationType.Report) {
          model.endpointDescription = m.ApiOperationType[endpoint.type] + " " + api.documentation.objectDescriptionPlural;
        }
        if (endpoint.documentation) {
          if (endpoint.documentation.objectName) {
            model.objectName = endpoint.documentation.objectName;
          }
          if (endpoint.documentation.objectDescription) {
            model.objectDescription = endpoint.documentation.objectDescription;
            model.endpointDescription = m.ApiOperationType[endpoint.type] + " " + api.documentation.objectDescriptionPlural;
          }
          if (endpoint.documentation.title) {
            model.endpointDescription = endpoint.documentation.title;
          }
        }
        model.documentationUrl = ApiHelper.getApiDocumentationUrl(api, endpoint);
        model.path = endpoint.path;
        model.operationType = endpoint.type;
        model.operationId = endpoint.id;
        model.method = m.ApiMethodType[endpoint.method];
        model.type = m.ApiOperationType[endpoint.type];
        data.push(model);

      });

    });

    return data;

  }


  public findApiFromDocumentationUrl(url: string, method: string, id: string): { found: boolean, apiProp: m.ApiProperties, apiCall: m.ApiCall, apiEndpoint: m.ApiEndpoint } {

    if (!url || !method) {
      return { found: false, apiProp: null, apiCall: null, apiEndpoint: null };
    }

    if (!Helper.startsWith(url, "/")) {
      url = "/" + url;
    }
    if (!Helper.endsWith(url, "/")) {
      url += "/";
    }
    if (method && id) {
      url += `${method}-${id}`;
    } else if (method) {
      url += method;
    }

    const endpoint = Helper.firstOrDefault(this.validEndpoints, x => Helper.equals(x.documentationUrl, url, true));
    if (!endpoint) {
      console.error(`No endpoint found with doc url "${url}".  Perhaps the endpoint requires a module we don't have? (${Helper.buildCsvString(this.appService.appInfoOrDefault.Modules)})`);
      return { found: false, apiProp: null, apiCall: null, apiEndpoint: null };
    }

    const apiProp: m.ApiProperties = this.findApiProperties(endpoint.apiName);
    if (!apiProp) {
      console.error(`No api properties object found with name "${endpoint.apiName}"`);
      return { found: false, apiProp: null, apiCall: null, apiEndpoint: null };
    }

    const apiCall: m.ApiCall = ApiHelper.createApiCall(apiProp, endpoint.operationType, id);
    if (!apiCall) {
      return { found: false, apiProp: null, apiCall: null, apiEndpoint: null };
    }

    const apiEndpoint: m.ApiEndpoint = ApiHelper.getApiEndpoint(apiProp, endpoint.operationType, id);

    return { found: true, apiProp: apiProp, apiCall: apiCall, apiEndpoint: apiEndpoint };

  }

  public getModelObject(name: string, apiVersion: number): any {
    let obj: any = null;
    try {
      obj = new m5[name]();
    } catch (err) { }
    if (!obj) {
      try {
        obj = new m5core[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new m5web[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new m5pay[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new m5sec[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new m5telecom[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new m5usage[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new m5rc[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new m5wallet[name]();
      } catch (err) { }
    }
    if (!obj) {
      try {
        obj = new mcore[name]();
      } catch (err) { }
    }
    if (!obj) {
      obj = {};
    }
    return obj;
  }

  public findApiProperties(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = true): m.ApiProperties {
    let apiProp: m.ApiProperties = Api.GetApi(apiName, version, suppressErrorReporting);
    if (!apiProp) {
      apiProp = ApiModuleCore.GetApi(apiName, version, suppressErrorReporting);
    }
    if (!apiProp) {
      apiProp = ApiModulePayment.GetApi(apiName, version, suppressErrorReporting);
    }
    if (!apiProp) {
      apiProp = ApiModuleSecurity.GetApi(apiName, version, suppressErrorReporting);
    }
    if (!apiProp) {
      apiProp = ApiModuleWeb.GetApi(apiName, version, suppressErrorReporting);
    }
    if (!apiProp) {
      apiProp = ApiModuleUsage.GetApi(apiName, version, suppressErrorReporting);
    }
    if (!apiProp) {
      apiProp = ApiModuleTelecom.GetApi(apiName, version, suppressErrorReporting);
    }
    if (!apiProp) {
      apiProp = ApiModuleReportCompiler.GetApi(apiName, version, suppressErrorReporting);
    }
    return apiProp;
  }

  public isValidApiEndpoint(apiId: string, endpointType?: m.ApiOperationType, endpointId?: string): boolean {

    // String representation of the enum
    let type: string = "";
    if (endpointType || endpointType === 0) {
      type = m.ApiOperationType[endpointType];
    }

    const settings: ApiDocsSettings = AppConfig.settings;

    if (settings.showApiList && settings.showApiList.length > 0) {
      // First see if entire api is in show list
      if (settings.showApiList.indexOf(apiId) > -1) {
        return true;
      }
      // Second see if our api + endpoint type is in show list
      if (type) {
        if (settings.showApiList.indexOf(`${apiId}.${type}`) > -1) {
          return true;
        }
      }
      // Finally see if our api + endpoint type + endpointId is in show list
      if (endpointId) {
        if (settings.showApiList.indexOf(`${apiId}.${type}.${endpointId}`) > -1) {
          return true;
        }
      }
      // If we have a show list and we're not in it then the default is hide it
      return false;
    }

    if (settings.hideApiList && settings.hideApiList.length > 0) {
      // First see if entire api is in hide list
      if (settings.hideApiList.indexOf(apiId) > -1) {
        return false;
      }
      // Second see if our api + endpoint type is in hide list
      if (type) {
        if (settings.hideApiList.indexOf(`${apiId}.${type}`) > -1) {
          return false;
        }
      }
      // Finally see if our api + endpoint type + endpointId is in hide list
      if (endpointId) {
        if (settings.hideApiList.indexOf(`${apiId}.${type}.${endpointId}`) > -1) {
          return false;
        }
      }
      // If we have a hide list and we're not in it then the default is show it
      return true;
    }

    // Default when we don't have any lists is show it
    return true;

  }


  public getDataModelCategoryList(): Observable<m.DataModelList[]> {
    return this.getDataModelList("category");
  }

  public getDataModelTableList(): Observable<m.DataModelList[]> {
    return this.getDataModelList("table");
  }

  public getDataModelViewList(): Observable<m.DataModelList[]> {
    return this.getDataModelList("view");
  }

  public getDataModelTableAndViewList(): Observable<m.DataModelList[]> {
    return this.getDataModelList("all");
  }

  public getDataModelList(type: "category" | "table" | "view" | "all"): Observable<m.DataModelList[]> {

    // First see if we already have loaded this data and can just return that
    if (type === "category") {
      if (this.dataModelCategoryList && this.dataModelCategoryList.length > 0) {
        return of(this.dataModelCategoryList);
      }
    } else if (type === "table") {
      if (this.dataModelTableList && this.dataModelTableList.length > 0) {
        return of(this.dataModelTableList);
      }
    } else if (type === "view") {
      if (this.dataModelViewList && this.dataModelViewList.length > 0) {
        return of(this.dataModelViewList);
      }
    } else if (type === "all") {
      if (this.dataModelTableAndViewList && this.dataModelTableAndViewList.length > 0) {
        return of(this.dataModelTableAndViewList);
      }
    }

    // Build query for the request
    const query = new m.Query();
    query.Page = 1;
    query.Size = Constants.RowsToReturn.All;
    query.Expand = type;

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<m.DataModelList[]>();

    this.apiService.execute(this.apiList, query).subscribe((result: m.IApiResponseWrapperTyped<m.DataModelList[]>) => {
      if (result.Data.Success) {
        // Cache results for future access
        if (type === "category") {
          this.dataModelCategoryList = result.Data.Data;
        } else if (type === "table") {
          this.dataModelTableList = result.Data.Data;
        } else if (type === "view") {
          this.dataModelViewList = result.Data.Data;
        } else if (type === "all") {
          this.dataModelTableAndViewList = result.Data.Data;
        }
      } else {
        this.appService.alertManager.addAlertFromApiResponse(result, this.apiList);
      }
      subject.next(result.Data.Data);
      subject.complete();
    });

    return subject.asObservable();

  }

  public getDataModel(dataModelName: string, raw: boolean = false): Observable<m.DataModel> {

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<m.DataModel>();

    if (raw) {
      this.apiService.execute(this.apiGetRaw, { ModelName: dataModelName }).subscribe((result: m.IApiResponseWrapperTyped<m.DataModel>) => {
        if (result.Data.Success) {
          subject.next(result.Data.Data);
          subject.complete();
        } else {
          this.appService.alertManager.addAlertFromApiResponse(result, this.apiGetRaw);
          subject.next(null);
          subject.complete();
        }
      });
    } else {
      this.apiService.execute(this.apiGet, { ModelName: dataModelName }).subscribe((result: m.IApiResponseWrapperTyped<m.DataModel>) => {
        if (result.Data.Success) {
          subject.next(result.Data.Data);
          subject.complete();
        } else {
          this.appService.alertManager.addAlertFromApiResponse(result, this.apiGet);
          subject.next(null);
          subject.complete();
        }
      });
    }

    return subject.asObservable();

  }


}
