import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputContactAddressBlockComponent),
  multi: true
};

@Component({
  selector: 'ib-input-contact-address-block',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-contact-address-block.component.html',
  styleUrls: ['./input-contact-address-block.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputContactAddressBlockComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  /**
  When true (the default) there is a border around the content so it looks like an input control.
  */
  @Input() border: boolean = true;

  /**
   * If a map provider is specified (default is none - i.e. "") then a map button is displayed before the label (if any)
   * that will open a new browser tab with the address for the contact that was provided as the ngModel for the input.
   */
  @Input() mapProvider: "" | "google" | "bing" = "";

  @Input() includePhone: boolean = true;
  @Input() includeMobile: boolean = true;
  @Input() includeEmail: boolean = true;
  @Input() includeEmailAlternate: boolean = true;

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
    // console.error(`border = ${this.border}`);
  }

  public configure() {

    super.configure();

    // With undefined label we don't have proper alignment
    if (!this.label) {
      this.label = "   ";
    }

  }

}
