import * as Enumerable from "linq";


// For keeping shortcut list of recently used items (e.g. customers, etc.)
export class RecentlyUsedList {

  protected maximumLength: number = 10;
  protected localStorageName: string = "";
  public list: RecentlyUsedItem[] = [];

  /**
  Constructor
  @param {number} maxLength The maximum number of items to keep in the recently used list.
  @param {string} storageName The local storage name to use for persisting this list.
  */
  constructor(maxLength: number, storageName: string) {
    this.maximumLength = maxLength;
    this.localStorageName = storageName;
    if (this.localStorageName !== "") {
      const listString = localStorage[this.localStorageName];
      if (listString) {
        this.list = JSON.parse(listString);
      }
    }
  }

  /**
   * Removes the first item from the recently used list.
   * @returns
   */
  public shift() {
    if (!this.list || this.list.length === 0) {
      return null;
    }

    return this.list.shift();
  }

  /**
  Clears the recently used item list.
  */
  public clear() {
    this.list = [];
    // If we're persisting this to local storage then delete it now
    if (this.localStorageName && this.localStorageName !== "") {
      delete localStorage[this.localStorageName];
    }
  }

  /**
  Adds an item to the recently used list.  If the item is already in our list we move it to the top of the list.
  @param {any} id The id of the item being added.
  @param {string} label The label to use to describe the item.
  @param {string} url The url to use when accessing the item.
  */
  public add(id: any, label: string, url: string): void {
    const model: RecentlyUsedItem = new RecentlyUsedItem();
    model.id = id;
    model.label = label;
    model.url = url;
    // If this object is already in our array then remove it so we can push it to the top of the list
    let listIndex = -1;
    this.list.forEach((value, index, array) => {
      if (model.id === value.id) {
        listIndex = index;
      }
    });
    if (listIndex > -1) {
      this.list.splice(listIndex, 1);
    }
    // Make room in array of recent items by limiting list to maximum count
    if (this.list.length >= this.maximumLength) {
      this.list.pop();
    }
    // Push into the top of our list
    this.list.unshift(model);
    // If we're persisting this to local storage then update it now
    if (this.localStorageName && this.localStorageName !== "") {
      localStorage[this.localStorageName] = JSON.stringify(this.list);
    }
  }

}

/**
A class of recently used items including an id, a label, and a url to access the item.
*/
export class RecentlyUsedItem {
  id: any;
  label: string = "";
  url: string = "";
}

