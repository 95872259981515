import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { EventModel, EventElementModel } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { QueryService } from 'projects/core-lib/src/lib/services/query.service';
import { TableNativeBaseClass } from '../table-native-base-class';
import { UxService } from '../../services/ux.service';
import { SystemService } from 'projects/core-lib/src/lib/services/system.service';

@Component({
  selector: 'ib-native-table',
  templateUrl: './native-table.component.html',
  styleUrls: ['./native-table.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class NativeTableComponent extends TableNativeBaseClass implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  @Input() allowDragOutsideTable: boolean = false;
  @Output() rowSelect: EventEmitter<any> = new EventEmitter();

  /**
   * By default we only allow rows to be dragged up or down (along the y axis) but
   * if allowDragOutsideTable gets set to true we remove that to allow rows to be
   * dragged outside of the table.
   * @type y or x is expected by cdkDragLockAxis but it works with "" to ignore the lock
   * although type checking fails on that so we type this as y, x, "", or any.  Obviously
   * we could just type as any but the others are listed here for documentation purposes
   * as expected values.
   */
  dragLockAxis: "y" | "x" | "" | any = "y";

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected systemService: SystemService,
    protected queryService: QueryService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef) {
    super(apiService, appService, systemService, queryService, uxService, sanitizer, cdr, elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.allowDragOutsideTable) {
      this.dragLockAxis = "";
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.allowDragOutsideTable) {
      if (this.allowDragOutsideTable) {
        this.dragLockAxis = "";
      } else {
        this.dragLockAxis = "y";
      }
    }
  }
  // ngAfterViewInit() {
  //   super.ngAfterViewInit();
  // }
  // ngAfterViewChecked() {
  //   super.ngAfterViewChecked();
  // }

  public fireRowSelect(event) {
    const data = Helper.tryGetValue(event, x => x.data, null);
    const cargo: any = { index: Helper.tryGetValue(event, x => x.index, -1), type: event.type };
    if (Helper.isArray(this.selectedData)) {
      cargo.selectedRows = this.selectedData;
    } else {
      cargo.selectedRows = [this.selectedData];
    }
    const payload: EventModel = new EventModel("rowSelect", event, data, new EventElementModel("table", this.options.tableId), cargo);
    this.rowSelect.emit(payload);
  }

  public fireTableRowClick(event, rowIndex: number, row: any) {
    const isRightButton = Helper.htmlEventIsRightMouseButton(event);

    if (!isRightButton && this.options.selectRowViaRowClick) {
      if (rowIndex === -1) {
        Log.errorMessage("Unable to turn row click into row (un)select without row index.");
        return;
      }
      if (!row) {
        row = this.data[rowIndex];
      }
      const isLink: boolean = this.isLink(event);
      if (isLink) {
        // Trying to click a link so don't do other events here
        return;
      }
      const isMenu: boolean = this.isRowMenuCell(event);
      const isCheckbox: boolean = this.isRowCheckboxCell(event);
      const isExpand: boolean = this.isRowExpandCell(event);
      const isDragHandle: boolean = this.isRowDragHandle(event);
      const isDropdown: boolean = this.isRowDropdownCell(event);

      // Click on the menu, checkbox, dropdown, or drag handle should not trigger the below action
      if (!isMenu && !isCheckbox && !isExpand && !isDragHandle && !isDropdown) {
        // row event which we will fire expects index, data and type of "checkbox" or "row" so create a new event object
        const rowEvent = { index: rowIndex, data: row, type: "row", originalEvent: event };
        // Determine if clicked row needs to be added or removed from selected data
        if (this.options.selectRowViaRowClick && this.options.dataSelectedBooleanPropertyName && !this.options.rowSelectedAction) {
          // If we have rowSelectedAction assume that will take care of this with any other logic it needs to perform
          row[this.options.dataSelectedBooleanPropertyName] = true;
          this.data[rowIndex][this.options.dataSelectedBooleanPropertyName] = true;
        }
        if (Helper.isArray(this.selectedData)) {
          this.selectedData.push(row);
        } else {
          this.selectedData = row;
        }
        this.fireRowSelect(rowEvent);
      }
    }
  }


}
