import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { EventModelTyped } from '../../ux-models';
import { UxService } from '../../services/ux.service';
import { KeyFilterPattern } from 'primeng/keyfilter';
// import { CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCurrencyComponent),
  multi: true
};

@Component({
  selector: 'ib-input-currency',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputCurrencyComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public min: number;
  @Input() public max: number;
  @Input() public step: number;

  @Input() public decimalPlaces: number;

  @Input() public allowEditCurrency: boolean = false;

  @Input() public isoCurrencyCode: string = "";
  @Output() public isoCurrencyCodeChange: EventEmitter<string> = new EventEmitter<string>();

  // @Input() public type: string = "number"; // number, integer, float
  public keyFilterType: KeyFilterPattern = "num";

  // config: CurrencyMaskConfig;
  info: any;

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    // configure is called from ngOnChanges in the InputBaseComponent
    // this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    // Needs to be called before we check to see if there is a prefix, since this sets a currency prefix.
    this.info = this.getCurrencyInfo();

    // Our base class does this but it doesn't know about allowEditCurrency so we check again the same logic here with allowEditCurrency in mind.
    // If we have prefix and/or suffix we have additional wrapper class to insert
    if (this.allowEditCurrency || this.prefixText || this.prefixIcon || this.suffixText || this.suffixIcon || this.suffixText2 || this.suffixIcon2) {
      if (this.inputLibrary === "primeng") {
        this.inputGroupClass = "ui-inputgroup";
      } else {
        this.inputGroupClass = "input-group";
      }
    }

    // if (this.type) {
    //  if (Helper.equals(this.type, "number", true)) {
    //    this.inputType = "number";
    //    this.keyFilterType = "num";
    //  } else if (Helper.equals(this.type, "integer", true)) {
    //    this.inputType = "number";
    //    this.keyFilterType = "int";
    //  } else if (Helper.equals(this.type, "float", true)) {
    //    this.inputType = "number";
    //    this.keyFilterType = "num";
    //  } else {
    //    Log.errorMessage(`Unexpected number input type setting ${this.type}.  Expected values include: "number", "integer", "float".  If no other appropriate input control exists please use "number".`);
    //    this.inputType = "number";
    //    this.keyFilterType = "num";
    //  }
    // } else {
    //  this.inputType = "number";
    //  this.keyFilterType = "num";
    // }

    // this.config = this.buildCurrencyConfig();

  }


  getCurrencyInfo(): any {

    const info: any = { currency: this.isoCurrencyCode, symbol: this.isoCurrencyCode };

    if (this.isoCurrencyCode) {
      try {
        // const symbol = new Intl.NumberFormat('en', { style: 'currency', currency: this.isoCurrencyCode }).formatToParts(0).find(x => x.type === 'currency');
        const test = 0;
        // const symbol = test.toLocaleString("en", { style: "currency", currency: "EUR" }).replace("0.00", "");
        // https://stackoverflow.com/a/19374099/3313877
        const symbol = test.toLocaleString("en", { style: "currency", currency: this.isoCurrencyCode }).replace(/\d+([,.]\d+)?/g, "");
        info.symbol = symbol || this.isoCurrencyCode;
        // Not used and parts is not a property of the info object above.
        // info.parts = new Intl.NumberFormat('en', { style: 'currency', currency: this.isoCurrencyCode }).formatToParts(0);
      } catch (err) {
        console.log(err);
        // Not used and error is not a property of the info object above.
        // info.error = err;
        info.symbol = info.symbol || this.isoCurrencyCode;
      }
    } else {
      info.symbol = "";
    }

    this.prefixText = info.symbol;
    // console.error(info);

  }

  onIsoCurrencyCodeChange($event: EventModelTyped<string>) {
    // console.error($event);
    if (!$event) {
      return;
    }
    this.isoCurrencyCode = $event.data;
    this.getCurrencyInfo();
    this.isoCurrencyCodeChange.emit($event.data);
  }

  // buildCurrencyConfig(): CurrencyMaskConfig {

  //  const config: CurrencyMaskConfig = {
  //    align: "right",
  //    allowNegative: true,
  //    allowZero: true,
  //    decimal: ".",
  //    precision: 2,
  //    prefix: "$ ",
  //    suffix: "",
  //    thousands: ",",
  //    nullable: false,
  //    min: this.min,
  //    max: this.max,
  //    inputMode: CurrencyMaskInputMode.NATURAL
  //  };

  //  // TODO adjust based on iso currency code

  //  return config;

  // }


}
