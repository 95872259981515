import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, PreloadAllModules } from '@angular/router';
import { CanDeactivateGuard } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { HomeComponent } from 'projects/shared-lib/src/lib/shared-core/home/home.component';
import { LoginComponent } from 'projects/shared-lib/src/lib/shared-core/login/login.component';
import { AppPreloaderService } from 'projects/core-lib/src/lib/services/app-preloader.service';
import { LoginRecoverComponent } from 'projects/common-lib/src/lib/ux/security/login-recover/login-recover.component';
import { PasswordResetLinkRequestComponent } from 'projects/common-lib/src/lib/ux/security/password-reset-link-request/password-reset-link-request.component';
import { PasswordResetComponent } from 'projects/common-lib/src/lib/ux/security/password-reset/password-reset.component';
import { MultiFactorAuthenticationStepOneComponent } from 'projects/common-lib/src/lib/ux/security/multi-factor-authentication-step-one/multi-factor-authentication-step-one.component';
import { MultiFactorAuthenticationStepTwoComponent } from 'projects/common-lib/src/lib/ux/security/multi-factor-authentication-step-two/multi-factor-authentication-step-two.component';

const appRoutes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },

  // Security routes not lazy loaded
  { path: 'login', component: LoginComponent },
  { path: 'login/forgot', component: LoginRecoverComponent },
  { path: 'login/password-reset', component: PasswordResetLinkRequestComponent },
  { path: 'login/password-change', component: PasswordResetComponent, data: { reason: "change" } },
  { path: 'login/password-expired', component: PasswordResetComponent, data: { reason: "expired" } },
  { path: 'login/mfa-step-1', component: MultiFactorAuthenticationStepOneComponent },
  { path: 'login/mfa-step-2', component: MultiFactorAuthenticationStepTwoComponent },

  { path: 'dashboard', loadChildren: () => import('./loaders/dashboard-loader.module').then(m => m.DashboardLoaderModule), data: { preload: true } },

  // Contact
  { path: 'profile', loadChildren: () => import('./loaders/profile-loader.module').then(m => m.ProfileLoaderModule) },
  { path: 'customers', loadChildren: () => import('./loaders/contact-loader.module').then(m => m.ContactLoaderModule), data: { preload: true } },
  { path: 'prospects', loadChildren: () => import('./loaders/prospect-loader.module').then(m => m.ProspectLoaderModule), data: { preload: true } },
  { path: 'marketing-contacts', loadChildren: () => import('./loaders/marketing-contact-loader.module').then(m => m.MarketingContactLoaderModule), data: { preload: true } },
  { path: 'directory', loadChildren: () => import('./loaders/directory-loader.module').then(m => m.DirectoryLoaderModule) },
  { path: 'locations', loadChildren: () => import('./loaders/location-loader.module').then(m => m.LocationLoaderModule) },
  { path: 'vendors', loadChildren: () => import('./loaders/vendor-loader.module').then(m => m.VendorLoaderModule) },
  { path: 'warehouses', loadChildren: () => import('./loaders/warehouse-loader.module').then(m => m.WarehouseLoaderModule) },
  { path: 'groups', loadChildren: () => import('./loaders/group-loader.module').then(m => m.GroupLoaderModule) },

  { path: 'tasks', loadChildren: () => import('./loaders/task-loader.module').then(m => m.TaskLoaderModule) },
  { path: 'asset', loadChildren: () => import('./loaders/asset-loader.module').then(m => m.AssetLoaderModule) },
  { path: 'attachments', loadChildren: () => import('./loaders/attachment-loader.module').then(m => m.AttachmentLoaderModule) },
  { path: 'alarm-rules', loadChildren: () => import('./loaders/alarm-rule-loader.module').then(m => m.AlarmRuleLoaderModule) },
  { path: 'articles', loadChildren: () => import('./loaders/article-loader.module').then(m => m.ArticleLoaderModule) },
  { path: 'cases', loadChildren: () => import('./loaders/case-loader.module').then(m => m.CaseLoaderModule) },
  { path: 'case-templates', loadChildren: () => import('./loaders/case-template-loader.module').then(m => m.CaseTemplateLoaderModule) },
  { path: 'query', loadChildren: () => import('./loaders/query-loader.module').then(m => m.QueryLoaderModule) },
  { path: 'data', loadChildren: () => import('./loaders/data-loader.module').then(m => m.DataLoaderModule) },
  { path: 'reports', loadChildren: () => import('./loaders/report-loader.module').then(m => m.ReportLoaderModule) },
  { path: 'report-viewer', loadChildren: () => import('./loaders/report-viewer-loader.module').then(m => m.ReportViewerLoaderModule) },
  { path: 'report-designer', loadChildren: () => import('./loaders/report-designer-loader.module').then(m => m.ReportDesignerLoaderModule) },
  { path: 'inventory', loadChildren: () => import('./loaders/inventory-loader.module').then(m => m.InventoryLoaderModule) },
  { path: 'notification', loadChildren: () => import('./loaders/notification-loader.module').then(m => m.NotificationLoaderModule) },
  { path: 'security', loadChildren: () => import('./loaders/security-loader.module').then(m => m.SecurityLoaderModule) },
  { path: 'subscriptions', loadChildren: () => import('./loaders/subscription-loader.module').then(m => m.SubscriptionLoaderModule) },
  { path: 'sales', loadChildren: () => import('./loaders/sales-loader.module').then(m => m.SalesLoaderModule) },
  { path: 'web', loadChildren: () => import('./loaders/web-loader.module').then(m => m.WebLoaderModule) },
  { path: 'search', loadChildren: () => import('./loaders/search-loader.module').then(m => m.SearchLoaderModule) },
  { path: 'translations', loadChildren: () => import('./loaders/translation-loader.module').then(m => m.TranslationLoaderModule) },
  { path: 'pick-lists', loadChildren: () => import('./loaders/pick-list-loader.module').then(m => m.PickListLoaderModule) },
  { path: 'settings', loadChildren: () => import('./loaders/settings-loader.module').then(m => m.SettingsLoaderModule) },
  { path: 'system', loadChildren: () => import('./loaders/system-loader.module').then(m => m.SystemLoaderModule) },
  { path: 'telecom', loadChildren: () => import('./loaders/telecom-loader.module').then(m => m.TelecomLoaderModule) },
  { path: 'logs', loadChildren: () => import('./loaders/log-loader.module').then(m => m.LogLoaderModule) },
  { path: 'jobs', loadChildren: () => import('./loaders/job-loader.module').then(m => m.JobLoaderModule) },
  { path: 'attribute', loadChildren: () => import('./loaders/attribute-loader.module').then(m => m.AttributeLoaderModule) },
  { path: 'forms', loadChildren: () => import('./loaders/form-loader.module').then(m => m.FormLoaderModule) },
  { path: 'partitions', loadChildren: () => import('./loaders/partition-loader.module').then(m => m.PartitionLoaderModule) },

  // Usage
  { path: 'usage', loadChildren: () => import('./loaders/usage-loader.module').then(m => m.UsageLoaderModule) },
  { path: 'network', loadChildren: () => import('./loaders/network-loader.module').then(m => m.NetworkLoaderModule) },

  // Billing
  { path: 'items', loadChildren: () => import('./loaders/item-loader.module').then(m => m.ItemLoaderModule) },
  { path: 'packages', loadChildren: () => import('./loaders/package-loader.module').then(m => m.PackageLoaderModule) },
  { path: 'link', loadChildren: () => import('./loaders/action-link-loader.module').then(m => m.ActionLinkLoaderModule) },
  { path: 'billing', loadChildren: () => import('./loaders/billing-profile-loader.module').then(m => m.BillingProfileLoaderModule) },
  { path: 'tax', loadChildren: () => import('./loaders/tax-loader.module').then(m => m.TaxLoaderModule) },
  { path: 'payment', loadChildren: () => import('./loaders/payment-loader.module').then(m => m.PaymentLoaderModule) },
  { path: 'voucher', loadChildren: () => import('./loaders/voucher-loader.module').then(m => m.VoucherLoaderModule) },
  { path: 'invoice', loadChildren: () => import('./loaders/invoice-loader.module').then(m => m.InvoiceLoaderModule) },

  // Table Management
  { path: 'table-management', loadChildren: () => import('./loaders/table-management-loader.module').then(m => m.TableManagementLoaderModule) },


  // Report Compiler
  { path: 'rc/library', loadChildren: () => import('./loaders/report-compiler-library-loader.module').then(m => m.ReportCompilerLibraryLoaderModule) },
  { path: 'rc/config', loadChildren: () => import('./loaders/report-compiler-configuration-loader.module').then(m => m.ReportCompilerConfigurationLoaderModule) },
  { path: 'rc/cases', loadChildren: () => import('./loaders/report-compiler-case-loader.module').then(m => m.ReportCompilerCaseLoaderModule) },
  { path: 'rc/case-templates', loadChildren: () => import('./loaders/report-compiler-case-template-loader.module').then(m => m.ReportCompilerCaseTemplateLoaderModule) },

  // Custom App Integration
  { path: 'custom-app', loadChildren: () => import('./loaders/custom-app-loader.module').then(m => m.CustomAppLoaderModule) },

  { path: '**', loadChildren: () => import('./loaders/dynamic-loader.module').then(m => m.DynamicLoaderModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: AppPreloaderService })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(protected router: Router) {

    // Add any wildcard catch all
    // e.g. { path: '**', component: ComponentBuilder }
    // Could parse url for form id or asset id and do dynamic content
    // if not found could go to 404 page or to dashboard
    // dynamic components seem tricky with AOT could we have window.ibapp.[appname]
    // object with code that we then hook to form events that gets copy of
    // form, data, etc. passed into the methods for actions?  The form
    // would just know the "appname" and the event method to fire.
    // Or maybe "appname" is just "formid39811" so events that need to execute
    // live at window.ibapp.formid39811.eventname(form, data)? will we be able
    // to attach events and pass data dynamically in form?  Maybe data if referenced
    // at window.ibapp.formid39811.data ?

    // Reset routes with what we've added via helpers
    // this.router.resetConfig(appRoutes);

  }

}
