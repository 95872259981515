// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* See https://stackoverflow.com/a/39273698 */
* {
  box-sizing: border-box;
}

.header-wrapper {
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.header-description {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.header-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-button-wrapper {
  flex: 0 0 auto;
  display: inline-flex;
  padding-left: .5em;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/data-editor/data-editor-header/data-editor-header.component.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,OAAO;EACP,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":["/* See https://stackoverflow.com/a/39273698 */\r\n* {\r\n  box-sizing: border-box;\r\n}\r\n\r\n.header-wrapper {\r\n  margin: auto;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.header-description {\r\n  display: flex;\r\n  align-items: center;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n}\r\n\r\n.header-text {\r\n  flex: 1;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.header-button-wrapper {\r\n  flex: 0 0 auto;\r\n  display: inline-flex;\r\n  padding-left: .5em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
