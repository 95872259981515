import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UxService } from '../../services/ux.service';
import { EventModel } from '../../ux-models';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputRichTextComponent),
  multi: true
};

@Component({
  selector: 'ib-input-rich-text',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-rich-text.component.html',
  styleUrls: ['./input-rich-text.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputRichTextComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() rows: number = 5; // default
  @Input() fullWidth: boolean = false;
  @Input() wrap: string = "soft";
  @Input() autoExpand: boolean = false;
  @Input() maxRows: number = 10; // this is used if autoExpand is enabled
  @Input() richText: boolean = false;
  @Input() richTextMode: "never" | "always" | "optional" = "optional";
  @Input() useBasicRichText: boolean = false;



  /*
   * The type of upload being done.  If set to 'None' then the
   * image is saved as an in-line blob with the html.
   */
  @Input() uploadType: "Asset" | "Attachment" | "None" = "None";

  /**
   * When upload type is set to asset the presence of a parentAssetId will
   * result in the asset being a child asset to this parentAssetId.
   * Other asset values are typically N/A when this is set.
   */
  @Input() parentAssetId: number = null;
  /**
   * When upload type is set to asset this is the system asset group.
   */
  @Input() systemAssetGroup: string = null;
  /**
   * When upload type is asset this controls the visibility.  The default
   * of "P" (public) is typically correct as images embedded in html need public
   * visibility to be served up anonymously.
   */
  @Input() assetVisibility: string = "P";

  /**
   * When uploading images this is the owner resource type.
   */
  @Input() ownerResourceType: string = null;
  /**
   * When uploading images this is the owner resource id.
   */
  @Input() ownerResourceId: number | string = null;
  /**
   * When uploading images this is the secondary owner resource type.
   */
  @Input() secondaryOwnerResourceType: string = null;
  /**
   * When uploading images this is the secondary owner resource id.
   */
  @Input() secondaryOwnerResourceId: number | string = null;
  /**
   * Passes along the event from the html-editor when a file upload is successful.
   */
  @Output() fileUploadSuccess: EventEmitter<EventModel> = new EventEmitter();
  /**
   * Passes along the event from the html-editor when a file upload has an error.
   */
  @Output() fileUploadError: EventEmitter<EventModel> = new EventEmitter();


  get richComponentHeightPx(): number {
    if (this.rows) {
      return (this.rows * 30);
    }
    return 100;
  }

  style: SafeStyle;

  constructor(
    protected apiService: ApiService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
    if (this.richTextMode === "never") {
      this.richText = false;
    } else if (this.richTextMode === "always") {
      this.richText = true;
    } else {
      // Detect based on current value being rich text or not
      // Note: this is handled in writeValue()
      // this.richText = Helper.isHtml(this.value);
    }
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    // If we're full width then reset some CSS
    if (this.fullWidth) {
      this.inputWrapperClass = this.Constants.Layout.fullWidth;
      // If we have prefix and/or suffix we have additional wrapper class to insert
      if (this.prefixText || this.prefixIcon || this.suffixText || this.suffixIcon) {
        this.inputWrapperClass += " input-group";
      }
    }

    // Forms created from database may have 0 for max length which should be interpreted as no max
    if (!this.maxlength) {
      this.maxlength = Number.MAX_SAFE_INTEGER;
    }

    if (this.autoExpand) {
      const height = Math.max(50, (this.rows * 25));
      this.style = this.sanitizer.bypassSecurityTrustStyle(`min-height: ${height}px; height: auto; transition: height 0.5s ease; overflow: hidden;`);
    } else {
      this.style = this.sanitizer.bypassSecurityTrustStyle("");
    }

  }

  autoExpandTextArea($event: any, textArea: HTMLTextAreaElement) {
    if (!this.autoExpand) {
      return;
    }
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
  }


  writeValue(value: any) {
    super.writeValue(value);
    if (this.richTextMode !== "never" && !this.richText) {
      this.richText = Helper.isHtml(value);
      // console.error("setter rich text", this.richText, value);
    }
  }

  onFileUploadSuccess($event) {
    this.fileUploadSuccess.emit($event);
  }

  onFileUploadError($event) {
    this.fileUploadError.emit($event);
  }



}
