// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control-tags {
  height: unset;
}
.form-control {
  padding: 0%;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-tags/input-tags.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb","sourcesContent":[".form-control-tags {\r\n  height: unset;\r\n}\r\n.form-control {\r\n  padding: 0%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
