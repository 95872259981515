// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';
import * as m5core from './ngModelsCore5';


/**
 * @name NetworkElementEditViewModel
 * @description
 * The network element table contains information about various network elements referenced in usage data.
 */
export class NetworkElementEditViewModel {

    /**
     * @name NetworkElementId
     * @description
     * Network Element Id uniquely identifies this network element.
     * @servertype Int64
     * @type {number}
     */
    NetworkElementId: number = null;

    /**
     * @name ExternalNetworkElementId
     * @description
     * An optional external network element id.
     * @servertype String
     * @type {string}
     */
    ExternalNetworkElementId: string = "";

    /**
     * @name Description
     * @description
     * Description for this network element.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Grouping
     * @description
     * Grouping this network element belongs to.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Grouping: string = "";

    /**
     * @name Type
     * @description
     * Type for this network element.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Role
     * @description
     * Role for this network element.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Role: string = "";

    /**
     * @name Network
     * @description
     * Network of the network element.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Network: string = "";

    /**
     * @name OnNetGroup
     * @description
     * Network elements in the same on-net group are considered on-net with each other.  Some rating profiles may be configured
     * to recognize a usage record as being on-net when both the source and target network elements are in the same on-net group.
     *  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    OnNetGroup: string = "";

    /**
     * @name LocationContactId
     * @description
     * An optional Contact Id of the location for this network equipment.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LocationContactId: number = null;

    /**
     * @name LocationContactName
     * @servertype String
     * @type {string}
     */
    LocationContactName: string = "";

    /**
     * @name Location
     * @description
     * Location of the network element.  This could be room #, row #, rack #, shelf #, etc.
     * @servertype String
     * @type {string}
     */
    Location: string = "";

    /**
     * @name Latitude
     * @description
     * Latitude of this network element location.
     * @servertype Double (nullable)
     * @type {number}
     */
    Latitude: number = 0;

    /**
     * @name Longitude
     * @description
     * Longitude of this network element location.
     * @servertype Double (nullable)
     * @type {number}
     */
    Longitude: number = 0;

    /**
     * @name TimeZoneId
     * @description
     * Time Zone Id representing the time zone where this network element is located.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = null;

    /**
     * @name DomainName
     * @description
     * Domain name of this network element.
     * @servertype String
     * @type {string}
     */
    DomainName: string = "";

    /**
     * @name EquipmentId
     * @description
     * Equipment Id of this network element.
     * @servertype String
     * @type {string}
     */
    EquipmentId: string = "";

    /**
     * @name IpAddressV4
     * @description
     * The v4 IP address of this network element.  Dynamic IP addresses are generally not noted here although they may be present
     * when network elements are automatically added.
     * @servertype String
     * @type {string}
     */
    IpAddressV4: string = "";

    /**
     * @name IpAddressV6
     * @description
     * The v6 IP address of this network element.  Dynamic IP addresses are generally not noted here although they may be present
     * when network elements are automatically added.
     * @servertype String
     * @type {string}
     */
    IpAddressV6: string = "";

    /**
     * @name Capacity
     * @description
     * Capacity for this network element reported as seconds per hour.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Capacity: number = 0;

    /**
     * @name Bandwidth
     * @description
     * Bandwidth for this network element reported as bits per second (BPS).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Bandwidth: number = 0;

    /**
     * @name QosShortUsageThreshold
     * @description
     * QOS Usage Call Threshold expressed in seconds.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QosShortUsageThreshold: number = 0;

    /**
     * @name QosJitterThreshold
     * @description
     * QOS Jitter Threshold.  Records that have a jitter value exceeding this threshold will be included in QOS reports.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QosJitterThreshold: number = 0;

    /**
     * @name QosLatencyThreshold
     * @description
     * QOS Latency Threshold.  Records that have a latency value exceeding this threshold will be included in QOS reports.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QosLatencyThreshold: number = 0;

    /**
     * @name QosPacketLossThreshold
     * @description
     * QOS Packet Loss Threshold expressed as a percentage value (i.e. 10 is 10%).  Records that have a packet loss % exceeding
     * this threshold will be included in QOS reports.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QosPacketLossThreshold: number = 0;

    /**
     * @name IsoCurrencyCode
     * @description
     * ISO Currency Code for this cost center.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name TelecomLocationProfileId
     * @description
     * An optional Telecom Location Profile Id that identifies the location profile for this network element.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationProfileId: number = null;

    /**
     * @name TelecomHomeCountryCode
     * @description
     * An optional Telecom Home Country Code.
     * @servertype String
     * @type {string}
     */
    TelecomHomeCountryCode: string = "";

    /**
     * @name TelecomHomeNpa
     * @description
     * An optional Telecom Home NPA (Area Code).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNpa: string = "";

    /**
     * @name TelecomHomeNxx
     * @description
     * An optional Telecom Home NXX (Exchange).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNxx: string = "";

    /**
     * @name TelecomHomeLata
     * @description
     * An optional Telecom Home LATA for this cost center.  Used to help determine if a call is intraLATA type.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TelecomHomeLata: number = 0;

    /**
     * @name TelecomHomeState
     * @description
     * An optional Telecom Home state for this cost center.  Used to help determine if a call is intrastate type.
     * @servertype String
     * @type {string}
     */
    TelecomHomeState: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags for this network element.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this network element.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NetworkPortEditViewModel
 * @description
 * The network port table contains information about network ports referenced in usage data.
 */
export class NetworkPortEditViewModel {

    /**
     * @name NetworkPortId
     * @description
     * Network Port Id uniquely identifies this network port.
     * @servertype Int64
     * @type {number}
     */
    NetworkPortId: number = null;

    /**
     * @name ExternalNetworkPortId
     * @description
     * An optional external network port id.
     * @servertype String
     * @type {string}
     */
    ExternalNetworkPortId: string = "";

    /**
     * @name Description
     * @description
     * Description for this network port.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name NetworkPortGroupId
     * @description
     * The id of the network port group this port belongs to.
     * @servertype Int64
     * @type {number}
     */
    NetworkPortGroupId: number = null;

    /**
     * @name NetworkElementId
     * @description
     * The id of the network element this port belongs to.
     * @servertype Int64
     * @type {number}
     */
    NetworkElementId: number = null;

    /**
     * @name Circuit
     * @description
     * Circuit number or phone number for this port.
     * @servertype String
     * @type {string}
     */
    Circuit: string = "";

    /**
     * @name Route
     * @description
     * Route for this network port.
     * @servertype String
     * @type {string}
     */
    Route: string = "";

    /**
     * @name Type
     * @description
     * Type for this network port.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Role
     * @description
     * Role for this network port.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Role: string = "";

    /**
     * @name LastInPath
     * @description
     * When true port is last in path.  This information is used to determine which traffic calculations should be used.  Typically,
     * Erlang B is used for ports and port groups that roll over to other ports and port groups.  Extended Erlang B includes a retry
     * factor and is appropriate for ports and port groups that do not overflow to other ports and port groups when busy.
     * @servertype Boolean
     * @type {boolean}
     */
    LastInPath: boolean = false;

    /**
     * @name Capacity
     * @description
     * Capacity for this network port reported as seconds per hour.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Capacity: number = 0;

    /**
     * @name Bandwidth
     * @description
     * Bandwidth for this network port reported as bits per second (BPS).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Bandwidth: number = 0;

    /**
     * @name IsoCurrencyCode
     * @description
     * ISO Currency Code for this cost center.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name TelecomLocationProfileId
     * @description
     * An optional Telecom Location Profile Id that identifies the location profile for this network port.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationProfileId: number = null;

    /**
     * @name TelecomHomeCountryCode
     * @description
     * An optional Telecom Home Country Code.
     * @servertype String
     * @type {string}
     */
    TelecomHomeCountryCode: string = "";

    /**
     * @name TelecomHomeNpa
     * @description
     * An optional Telecom Home NPA (Area Code).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNpa: string = "";

    /**
     * @name TelecomHomeNxx
     * @description
     * An optional Telecom Home NXX (Exchange).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNxx: string = "";

    /**
     * @name TelecomHomeLata
     * @description
     * An optional Telecom Home LATA for this cost center.  Used to help determine if a call is intraLATA type.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TelecomHomeLata: number = 0;

    /**
     * @name TelecomHomeState
     * @description
     * An optional Telecom Home state for this cost center.  Used to help determine if a call is intrastate type.
     * @servertype String
     * @type {string}
     */
    TelecomHomeState: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags for this network port.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this network port.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NetworkPortGroupEditViewModel
 * @description
 * The network port group table contains information about network port groups referenced in usage data.
 */
export class NetworkPortGroupEditViewModel {

    /**
     * @name NetworkPortGroupId
     * @description
     * Network Port Group Id uniquely identifies this network port group.
     * @servertype Int64
     * @type {number}
     */
    NetworkPortGroupId: number = null;

    /**
     * @name ExternalNetworkPortGroupId
     * @description
     * An optional external network port group id.
     * @servertype String
     * @type {string}
     */
    ExternalNetworkPortGroupId: string = "";

    /**
     * @name Description
     * @description
     * Description for this network port group.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name NetworkElementId
     * @description
     * The id of the network element this port group belongs to.
     * @servertype Int64
     * @type {number}
     */
    NetworkElementId: number = null;

    /**
     * @name Circuit
     * @description
     * Circuit number or phone number for this port group.
     * @servertype String
     * @type {string}
     */
    Circuit: string = "";

    /**
     * @name Route
     * @description
     * Route for this network port group.
     * @servertype String
     * @type {string}
     */
    Route: string = "";

    /**
     * @name Type
     * @description
     * Type for this network port group.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Role
     * @description
     * Role for this network port group.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Role: string = "";

    /**
     * @name LastInPath
     * @description
     * When true port group is last in path.  This information is used to determine which traffic calculations should be used.
     * Typically, Erlang B is used for ports and port groups that roll over to other ports and port groups.  Extended Erlang B includes
     * a retry factor and is appropriate for ports and port groups that do not overflow to other ports and port groups when busy.
     * @servertype Boolean
     * @type {boolean}
     */
    LastInPath: boolean = false;

    /**
     * @name PortCount
     * @description
     * The total number of ports in the port group.  This is a declared value instead of using a count of assigned ports since ports
     * do not always get reported or may be auto added as encountered which may never actually match the capacity.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PortCount: number = 0;

    /**
     * @name Capacity
     * @description
     * Capacity for this network port group reported as seconds per hour.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Capacity: number = 0;

    /**
     * @name Bandwidth
     * @description
     * Bandwidth for this network port group reported as bits per second (BPS).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Bandwidth: number = 0;

    /**
     * @name IsoCurrencyCode
     * @description
     * ISO Currency Code for this cost center.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name TelecomLocationProfileId
     * @description
     * An optional Telecom Location Profile Id that identifies the location profile for this network port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationProfileId: number = null;

    /**
     * @name TelecomHomeCountryCode
     * @description
     * An optional Telecom Home Country Code.
     * @servertype String
     * @type {string}
     */
    TelecomHomeCountryCode: string = "";

    /**
     * @name TelecomHomeNpa
     * @description
     * An optional Telecom Home NPA (Area Code).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNpa: string = "";

    /**
     * @name TelecomHomeNxx
     * @description
     * An optional Telecom Home NXX (Exchange).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNxx: string = "";

    /**
     * @name TelecomHomeLata
     * @description
     * An optional Telecom Home LATA for this cost center.  Used to help determine if a call is intraLATA type.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TelecomHomeLata: number = 0;

    /**
     * @name TelecomHomeState
     * @description
     * An optional Telecom Home state for this cost center.  Used to help determine if a call is intrastate type.
     * @servertype String
     * @type {string}
     */
    TelecomHomeState: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags for this network port group.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this network port group.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RatingGroupEditViewModel
 * @description
 * The Rating Group table supports grouping of multiple usage types into one group to help facilitate identification of the
 * appropriate rate steps (see rating step table) to use to identify the correct rate (see rating rate table) to use.
 */
export class RatingGroupEditViewModel {

    /**
     * @name RatingGroupId
     * @description
     * Rating Group Id uniquely identifies this rating group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RatingGroupId: number = null;

    /**
     * @name RatingProfileId
     * @description
     * The Id of the Rating Profile this group belongs to.
     * @servertype Int64
     * @type {number}
     */
    RatingProfileId: number = null;

    /**
     * @name Description
     * @description
     * Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name UsageTypeList
     * @description
     * A comma delimited list of usage types for this rating group.  Note that each usage type can only be specified in one group
     * for each rating profile since the usage type is used to determine which rating group to use.
     * @servertype string array
     * @type {string[]}
     */
    UsageTypeList: string[] = [];

    /**
     * @name GroupUsage
     * @description
     * A list of group usage qualifiers that are used to determine when a rate group is to be used.  Possible values include:
     * I = Incoming
     * O = Outgoing
     * E = Extension/Internal (i.e. neither incoming or outgoing)
     * D = DISA/Pass-Thru/External (i.e. both incoming and outgoing)
     * @servertype string array
     * @type {string[]}
     */
    GroupUsage: string[] = [];

    /**
     * @name Enabled
     * @description
     * When true the rating group is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name RateDescriptionUse
     * @description
     * Use rate description within usage tables:
     * A = Append rate description to existing description
     * R = Replace existing description with rate description
     * N = No rate description recorded
     * @servertype String
     * @type {string}
     */
    RateDescriptionUse: string = "N";

    /**
     * @name RateDescriptionSeparator
     * @description
     * Separator used when appending rate description to existing description.
     * @servertype String
     * @type {string}
     */
    RateDescriptionSeparator: string = "";

    /**
     * @name RateCode
     * @description
     * User defined rate code stored in usage tables to relate information regarding the rate used.
     * @servertype String
     * @type {string}
     */
    RateCode: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags for this rating group.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this rating group.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Steps
     * @servertype RatingStepEditViewModel array
     * @type {RatingStepEditViewModel[]}
     */
    Steps: RatingStepEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RatingStepEditViewModel
 * @description
 * The Rating Step table indicates what values to use when attempting to identify a rate and in what order to use them.
 */
export class RatingStepEditViewModel {

    /**
     * @name RatingStepId
     * @description
     * Rating Step Id uniquely identifies this rating step.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RatingStepId: number = null;

    /**
     * @name RatingGroupId
     * @description
     * The Id of the Rating Group this step belongs to.
     * @servertype Int64
     * @type {number}
     */
    RatingGroupId: number = null;

    /**
     * @name SequenceNumber
     * @description
     * Sequence number which controls the order of rating steps used when attempting to identify a rate.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name Description
     * @description
     * Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RateCode
     * @description
     * User defined rate code stored in usage tables to relate information regarding the rate used.
     * @servertype String
     * @type {string}
     */
    RateCode: string = "";

    /**
     * @name Enabled
     * @description
     * When true the rating step is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name RatingStepCode
     * @description
     * Rating step code that identifies what method to use when attempting to identify a rate.  Note that the same rating step code
     * may be specified more than once to facilitate things like multiple inheritance of rates, searching for rates with different
     * check flag combinations (see various check properties), etc.  Possible values include:
     * CCNXX = Country Code + NPA + NXX
     * CCNPA = Country Code + NPA
     * CC = Country Code
     * C2 = 2 Character ISO Country Code
     * C3 = 3 Character ISO Country Code
     * LATA = LATA
     * STATE = State
     * OCN = OCN
     * COS = Class of Service
     * MILES = Distance - Miles
     * KM = Distance - KM
     * TIER = Tier
     * SID = Service Id
     * FALL = Fallback
     * RP = Inherited Rating Profile (see inherit profile rate property)
     *  = No step code defined (only effective when using step code source column)
     * @servertype String
     * @type {string}
     */
    RatingStepCode: string = "";

    /**
     * @name RatingStepCodeSource
     * @description
     * Rating step code that identifies what method to use when attempting to identify a rate.  This property contains the source
     * value to use for rate identification.  Note that this is typically only defined when rating toll free calls based on source
     * (ANI) or when rating in a point-to-point scenario (i.e. different rates to target depending on source).  Note that the same
     * rating step code may be specified more than once to facilitate things like multiple inheritance of rates, searching for rates
     * with different check flag combinations (see check properties), etc.  Possible values include:
     * CCNXX = Country Code + NPA + NXX
     * CCNPA = Country Code + NPA
     * CC = Country Code
     * C2 = 2 Character ISO Country Code
     * C3 = 3 Character ISO Country Code
     * LATA = LATA
     * STATE = State
     * OCN = OCN
     * FALL = Fallback
     *  = No source step code defined (default)
     * @servertype String
     * @type {string}
     */
    RatingStepCodeSource: string = "";

    /**
     * @name TierFromZoneSourceFlag
     * @description
     * When finding rating step code TIER the tier can be found using the rating zone table.  If the tier number should be taken
     * from the rating zone table using a source value specify the source value to use here.  Possible values include:
     * C = Country Code
     * N = Country Code + NPA
     * X = Country Code + NPA + NXX
     * L = LATA
     * S = State
     * O = OCN
     * 2 = 2 Character ISO Country Code
     * 3 = 3 Character ISO Country Code
     * @servertype String
     * @type {string}
     */
    TierFromZoneSourceFlag: string = "";

    /**
     * @name TierFromZoneTargetFlag
     * @description
     * When finding rating step code TIER the tier can be found using the rating zone table.  If the tier number should be taken
     * from the rating zone table using a target value specify the target value to use here.  Possible values include:
     * C = Country Code
     * N = Country Code + NPA
     * X = Country Code + NPA + NXX
     * L = LATA
     * S = State
     * O = OCN
     * 2 = 2 Character ISO Country Code
     * 3 = 3 Character ISO Country Code
     * @servertype String
     * @type {string}
     */
    TierFromZoneTargetFlag: string = "";

    /**
     * @name InheritZoneRatingProfileId
     * @description
     * Inherit zones from this Rating Profile.  Note that the rating profile specified here only supports inheritance of the parent
     * while the inherit setting in the rating profile can support full rating profile ancestry (i.e. not only parent but also grandparent,
     * great-grandparent, etc.) because it does not have the same lookup restrictions.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InheritZoneRatingProfileId: number = null;

    /**
     * @name InheritRateRatingProfileId
     * @description
     * Inherit rates from this Rating Profile (only applicable when rating step code is RP).  Care must be taken to ensure that
     * there are no circular references with rating profile inheritance.  For example, Profile A -> Profile B -> Profile C -> Profile
     * A could result in rating issues.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InheritRateRatingProfileId: number = null;

    /**
     * @name CheckRatingStepCodeRange
     * @description
     * When true check value outlined in rating step code as a range (i.e. between rating rate beginning and ending code) instead
     * of as a simple look up (i.e. equal to rating rate beginning code).  Note that only numeric step codes support range checks.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckRatingStepCodeRange: boolean = false;

    /**
     * @name CheckRatingStepCodeSourceRange
     * @description
     * When true check value outlined in rating step code source as a range (i.e. between rating rate beginning and ending code)
     * instead of as a simple look up (i.e. equal to rating rate beginning code source).  Note that only numeric step codes support
     * range checks.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckRatingStepCodeSourceRange: boolean = false;

    /**
     * @name CheckAccessCode
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckAccessCode: boolean = false;

    /**
     * @name CheckQualityOfService
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckQualityOfService: boolean = false;

    /**
     * @name CheckClassOfService
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckClassOfService: boolean = false;

    /**
     * @name CheckSourceOperatingCompanyNumber
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckSourceOperatingCompanyNumber: boolean = false;

    /**
     * @name CheckTargetOperatingCompanyNumber
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTargetOperatingCompanyNumber: boolean = false;

    /**
     * @name CheckSourceLata
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckSourceLata: boolean = false;

    /**
     * @name CheckTargetLata
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTargetLata: boolean = false;

    /**
     * @name CheckSourceLocationType
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckSourceLocationType: boolean = false;

    /**
     * @name CheckTargetLocationType
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTargetLocationType: boolean = false;

    /**
     * @name CheckSourceNetworkElement
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckSourceNetworkElement: boolean = false;

    /**
     * @name CheckTargetNetworkElement
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTargetNetworkElement: boolean = false;

    /**
     * @name CheckSourceNetwork
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckSourceNetwork: boolean = false;

    /**
     * @name CheckTargetNetwork
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTargetNetwork: boolean = false;

    /**
     * @name CheckServiceId
     * @description
     * When true check service id when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckServiceId: boolean = false;

    /**
     * @name CheckClassificationType
     * @description
     * When true check classification type when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckClassificationType: boolean = false;

    /**
     * @name CheckDayOfWeek
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckDayOfWeek: boolean = false;

    /**
     * @name CheckTimeOfDay
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTimeOfDay: boolean = false;

    /**
     * @name CheckValidDates
     * @description
     * When true check this value range when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckValidDates: boolean = false;

    /**
     * @name CheckFilter
     * @description
     * When true check filter when looking for a valid rate.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckFilter: boolean = false;

    /**
     * @name ImportRatesDtsFileName
     * @description
     * File name of the DTS package to use when importing rates.
     * @servertype String
     * @type {string}
     */
    ImportRatesDtsFileName: string = "";

    /**
     * @name ImportRatesSourceFileName
     * @description
     * File name of the source file (file that contains rates to import) to use when importing rates.
     * @servertype String
     * @type {string}
     */
    ImportRatesSourceFileName: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags for this rating step.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this rating step.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Rates
     * @servertype RatingRateEditViewModel array
     * @type {RatingRateEditViewModel[]}
     */
    Rates: RatingRateEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RatingRateEditViewModel
 * @description
 * The Rating Rate table contains information related to rates for different rating profiles, groups and steps.  See the rating
 * profile, rating group, and rating step tables for more details.
 */
export class RatingRateEditViewModel {

    /**
     * @name RatingRateId
     * @description
     * Rating Rate Id uniquely identifies this rating rate.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RatingRateId: number = null;

    /**
     * @name RatingStepId
     * @description
     * The Id of the Rating Step this rate belongs to.
     * @servertype Int64
     * @type {number}
     */
    RatingStepId: number = null;

    /**
     * @name Description
     * @description
     * Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RateCode
     * @description
     * User defined rate code stored in usage tables to relate information regarding the rate used.
     * @servertype String
     * @type {string}
     */
    RateCode: string = "";

    /**
     * @name Enabled
     * @description
     * When true the rating step is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name BeginningCode
     * @description
     * Beginning of a range of codes that is used to look up rates based on a desired code.  See rating step code column for an
     * indication of what type of value is stored in this column.
     * @servertype String
     * @type {string}
     */
    BeginningCode: string = "";

    /**
     * @name EndingCode
     * @description
     * Ending of a range of codes that is used to look up rates based on a desired code.  See rating step code column for an indication
     * of what type of value is stored in this column.
     * @servertype String
     * @type {string}
     */
    EndingCode: string = "";

    /**
     * @name BeginningCodeSource
     * @description
     * Beginning of a range of codes that is used to look up rates based on a desired code.  See rating step code source column
     * for an indication of what type of value is stored in this column.
     * @servertype String
     * @type {string}
     */
    BeginningCodeSource: string = "";

    /**
     * @name EndingCodeSource
     * @description
     * Ending of a range of codes that is used to look up rates based on a desired code.  See rating step code source column for
     * an indication of what type of value is stored in this column.
     * @servertype String
     * @type {string}
     */
    EndingCodeSource: string = "";

    /**
     * @name BeginningAccessCode
     * @description
     * Beginning of a range of codes that is used to look up rates based on a desired access code.
     * @servertype String
     * @type {string}
     */
    BeginningAccessCode: string = "";

    /**
     * @name EndingAccessCode
     * @description
     * Ending of a range of codes that is used to look up rates based on a desired access code.
     * @servertype String
     * @type {string}
     */
    EndingAccessCode: string = "";

    /**
     * @name BeginningQualityOfService
     * @description
     * Beginning of a range of QOS that is used to look up rates based on a desired quality of service.
     * @servertype String
     * @type {string}
     */
    BeginningQualityOfService: string = "";

    /**
     * @name EndingQualityOfService
     * @description
     * Ending of a range of QOS that is used to look up rates based on a desired quality of service.
     * @servertype String
     * @type {string}
     */
    EndingQualityOfService: string = "";

    /**
     * @name BeginningClassOfService
     * @description
     * Beginning of a range of COS that is used to look up rates based on a desired class of service.
     * @servertype String
     * @type {string}
     */
    BeginningClassOfService: string = "";

    /**
     * @name EndingClassOfService
     * @description
     * Ending of a range of COS that is used to look up rates based on a desired class of service.
     * @servertype String
     * @type {string}
     */
    EndingClassOfService: string = "";

    /**
     * @name BeginningSourceOperatingCompanyNumber
     * @description
     * Beginning of a range of source OCN that is used to look up rates based on a desired operating company number.
     * @servertype String
     * @type {string}
     */
    BeginningSourceOperatingCompanyNumber: string = "";

    /**
     * @name EndingSourceOperatingCompanyNumber
     * @description
     * Ending of a range of source OCN that is used to look up rates based on a desired operating company number.
     * @servertype String
     * @type {string}
     */
    EndingSourceOperatingCompanyNumber: string = "";

    /**
     * @name BeginningTargetOperatingCompanyNumber
     * @description
     * Beginning of a range of target OCN that is used to look up rates based on a desired operating company number.
     * @servertype String
     * @type {string}
     */
    BeginningTargetOperatingCompanyNumber: string = "";

    /**
     * @name EndingTargetOperatingCompanyNumber
     * @description
     * Ending of a range of target OCN that is used to look up rates based on a desired operating company number.
     * @servertype String
     * @type {string}
     */
    EndingTargetOperatingCompanyNumber: string = "";

    /**
     * @name BeginningSourceLata
     * @description
     * Beginning of a range of source LATA that is used to look up rates based on a desired LATA.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BeginningSourceLata: number = 0;

    /**
     * @name EndingSourceLata
     * @description
     * Ending of a range of source LATA that is used to look up rates based on a desired LATA.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EndingSourceLata: number = 0;

    /**
     * @name BeginningTargetLata
     * @description
     * Beginning of a range of target LATA that is used to look up rates based on a desired LATA.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BeginningTargetLata: number = 0;

    /**
     * @name EndingTargetLata
     * @description
     * Ending of a range of target LATA that is used to look up rates based on a desired LATA.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EndingTargetLata: number = 0;

    /**
     * @name BeginningSourceLocationType
     * @description
     * Beginning of a range of source Location Type that is use to look up rates based on a desired location type.
     * @servertype String
     * @type {string}
     */
    BeginningSourceLocationType: string = "";

    /**
     * @name EndingSourceLocationType
     * @description
     * Ending of a range of source Location Type that is use to look up rates based on a desired location type.
     * @servertype String
     * @type {string}
     */
    EndingSourceLocationType: string = "";

    /**
     * @name BeginningTargetLocationType
     * @description
     * Beginning of a range of target Location Type that is use to look up rates based on a desired location type.
     * @servertype String
     * @type {string}
     */
    BeginningTargetLocationType: string = "";

    /**
     * @name EndingTargetLocationType
     * @description
     * Ending of a range of target Location Type that is use to look up rates based on a desired location type.
     * @servertype String
     * @type {string}
     */
    EndingTargetLocationType: string = "";

    /**
     * @name BeginningSourceExternalNetworkElementId
     * @description
     * Beginning of a range of source network element that is used to look up rates based on a desired source network element.
     * @servertype String
     * @type {string}
     */
    BeginningSourceExternalNetworkElementId: string = "";

    /**
     * @name EndingSourceExternalNetworkElementId
     * @description
     * Ending of a range of source network element that is used to look up rates based on a desired source network element.
     * @servertype String
     * @type {string}
     */
    EndingSourceExternalNetworkElementId: string = "";

    /**
     * @name BeginningTargetExternalNetworkElementId
     * @description
     * Beginning of a range of target network element that is used to look up rates based on a desired target network element.
     * @servertype String
     * @type {string}
     */
    BeginningTargetExternalNetworkElementId: string = "";

    /**
     * @name EndingTargetExternalNetworkElementId
     * @description
     * Ending of a range of target network element that is used to look up rates based on a desired target network element.
     * @servertype String
     * @type {string}
     */
    EndingTargetExternalNetworkElementId: string = "";

    /**
     * @name BeginningSourceNetwork
     * @description
     * Beginning of a range of source network that is used to look up rates based on a desired source network.
     * @servertype String
     * @type {string}
     */
    BeginningSourceNetwork: string = "";

    /**
     * @name EndingSourceNetwork
     * @description
     * Ending of a range of source network that is used to look up rates based on a desired source network.
     * @servertype String
     * @type {string}
     */
    EndingSourceNetwork: string = "";

    /**
     * @name BeginningTargetNetwork
     * @description
     * Beginning of a range of target network that is used to look up rates based on a desired target network.
     * @servertype String
     * @type {string}
     */
    BeginningTargetNetwork: string = "";

    /**
     * @name EndingTargetNetwork
     * @description
     * Ending of a range of target network that is used to look up rates based on a desired target network.
     * @servertype String
     * @type {string}
     */
    EndingTargetNetwork: string = "";

    /**
     * @name BeginningServiceId
     * @description
     * Beginning of a range of service id that is used to look up rates based on a desired service id.
     * @servertype String
     * @type {string}
     */
    BeginningServiceId: string = "";

    /**
     * @name EndingServiceId
     * @description
     * Ending of a range of service id that is used to look up rates based on a desired service id.
     * @servertype String
     * @type {string}
     */
    EndingServiceId: string = "";

    /**
     * @name ClassificationType
     * @description
     * Classification type flags for this rate record in no particular order.  All specified must exist for rate to match.  If none
     * are specified then column is not checked (i.e. it is not required that usage record classification type also be empty).
     * Possible flags include:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * F = Fax
     * W = Wireless
     * R = Roaming
     * T = Transferred
     * X = Conference
     * ^ = Call Forwarding
     * . = Call Waiting
     * + = Premium Service
     * - = Discount Service
     * < = Low Priority
     * > = High Priority
     * A = Assisted (operator, csr, etc.)
     * # = Voice Mail or IVR
     * N = No Charge (i.e. free)
     * : = Preempted
     * 3 = Multi-party Transmission (i.e. 3-way call)
     * = = On-Net (i.e. internal)
     * @ = Virtual Private Network
     * H = Hospitality
     * $ = Payphone
     * % = Rate Adjustment Applied
     * ? = Unknown
     * @servertype string array
     * @type {string[]}
     */
    ClassificationType: string[] = [];

    /**
     * @name Filter
     * @description
     * User defined filter to determine if a usage row should use this rate or not.
     * @servertype String
     * @type {string}
     */
    Filter: string = "";

    /**
     * @name Days1
     * @description
     * A string of codes that represent the days included with this rate where
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * H = Holiday
     * @servertype string array
     * @type {string[]}
     */
    Days1: string[] = [];

    /**
     * @name StartTime1
     * @description
     * A string in the format HH:MM representing the start time when this rate is valid.
     * @servertype String
     * @type {string}
     */
    StartTime1: string = "";

    /**
     * @name EndTime1
     * @description
     * A string in the format HH:MM representing the end time when this rate is valid.
     * @servertype String
     * @type {string}
     */
    EndTime1: string = "";

    /**
     * @name Days2
     * @description
     * A string of codes that represent the days included with this rate where
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * H = Holiday
     * @servertype string array
     * @type {string[]}
     */
    Days2: string[] = [];

    /**
     * @name StartTime2
     * @description
     * A string in the format HH:MM representing the start time when this rate is valid.
     * @servertype String
     * @type {string}
     */
    StartTime2: string = "";

    /**
     * @name EndTime2
     * @description
     * A string in the format HH:MM representing the end time when this rate is valid.
     * @servertype String
     * @type {string}
     */
    EndTime2: string = "";

    /**
     * @name Days3
     * @description
     * A string of codes that represent the days included with this rate where
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * H = Holiday
     * @servertype string array
     * @type {string[]}
     */
    Days3: string[] = [];

    /**
     * @name StartTime3
     * @description
     * A string in the format HH:MM representing the start time when this rate is valid.
     * @servertype String
     * @type {string}
     */
    StartTime3: string = "";

    /**
     * @name EndTime3
     * @description
     * A string in the format HH:MM representing the end time when this rate is valid.
     * @servertype String
     * @type {string}
     */
    EndTime3: string = "";

    /**
     * @name SecondsPerPulse
     * @description
     * Usually if we're rating based on pulse (see unit type) we have a pulse value to work with.  On occasion, however, we may
     * have to calculate the number of pulses involved.  Use this property to specify the number of seconds per pulse for that calculation.
     * @servertype Double (nullable)
     * @type {number}
     */
    SecondsPerPulse: number = 0;

    /**
     * @name UnitType
     * @description
     * Unit type for this rate calculation.  Possible values include:
     *  = None
     * M = Minutes
     * S = Seconds
     * P = Pulses
     * U = Units
     * FP = Fax Pages
     * BSS = Bytes Sent Source
     * BRS = Bytes Received Source
     * BTS = Bytes Total Source
     * BST = Bytes Sent Target
     * BRT = Bytes Received Target
     * BTT = Bytes Total Target
     * BT = Bytes Total Source and Target
     * KBSS = KiloBytes Sent Source
     * KBRS = KiloBytes Received Source
     * KBTS = KiloBytes Total Source
     * KBST = KiloBytes Sent Target
     * KBRT = KiloBytes Received Target
     * KBTT = KiloBytes Total Target
     * KBT = KiloBytes Total Source and Target
     * MBSS = MegaBytes Sent Source
     * MBRS = MegaBytes Received Source
     * MBTS = MegaBytes Total Source
     * MBST = MegaBytes Sent Target
     * MBRT = MegaBytes Received Target
     * MBTT = MegaBytes Total Target
     * MBT = MegaBytes Total Source and Target
     * GBSS = GigaBytes Sent Source
     * GBRS = GigaBytes Received Source
     * GBTS = GigaBytes Total Source
     * GBST = GigaBytes Sent Target
     * GBRT = GigaBytes Received Target
     * GBTT = GigaBytes Total Target
     * GBT = GigaBytes Total Source and Target
     * PKSS = Packets Sent Source
     * PKRS = Packets Received Source
     * PKTS = Packets Total Source
     * PKST = Packets Sent Target
     * PKRT = Packets Received Target
     * PKTT = Packets Total Target
     * PKT = Packets Total Source and Target
     * OSS = Octets Sent Source
     * ORS = Octets Received Source
     * OTS = Octets Total Source
     * OST = Octets Sent Target
     * ORT = Octets Received Target
     * OTT = Octets Total Target
     * OT = Octets Total Source and Target
     * @servertype String
     * @type {string}
     */
    UnitType: string = "";

    /**
     * @name RateStep1Units
     * @description
     * Units for the first rate step.  For example, if the unit type is M for Minutes, and billing is based on a one minute minimum
     * with six second increments then this field would be 1.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep1Units: number = 0;

    /**
     * @name RateStep1Rate
     * @description
     * Rate for the first rate step.  Note that this is the rate per rate step 1 units not the rate per unit type.  For example,
     * if the unit type is M for Minutes and billing is based on a 30 second minimum with six second increments, the rate step 1
     * rate is the rate per 30 seconds (.5 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep1Rate: number = 0;

    /**
     * @name RateStep1MaximumUnits
     * @description
     * The maximum number of units to be included in rate step one.  For example, if the unit type is M for Minutes and the rate
     * step 1 maximum units is set to 5 then the first 5 minutes would be rated using rate step one and any remaining time would
     * be rated by the remaining rate steps.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep1MaximumUnits: number = 0;

    /**
     * @name RateStep2Units
     * @description
     * Units for the second rate step.  For example, if the unit type is M for Minutes, and billing is based on a one minute minimum
     * with six second increments then this field would be .1 (.1 minute is 6 seconds).
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep2Units: number = 0;

    /**
     * @name RateStep2Rate
     * @description
     * Rate for the second rate step.  Note that this is the rate per rate step 2 units not the rate per unit type.  For example,
     * if the unit type is M for Minutes and billing is based on a 30 second minimum with six second increments, the rate step 2
     * rate is the rate per 6 seconds (.1 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep2Rate: number = 0;

    /**
     * @name RateStep2MaximumUnits
     * @description
     * The maximum number of units to be included in rate step two.  For example, if the unit type is M for Minutes and the rate
     * step 2 maximum units is set to 5 then the first 5 minutes that rolled over from rate step one would be rated using rate step
     * two and any remaining time would be rated by the remaining rate steps.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep2MaximumUnits: number = 0;

    /**
     * @name RateStep3Units
     * @description
     * Units for the third rate step.  For example, if the unit type is M for Minutes, and billing for this third rate step is based
     * on six second increments then this field would be .1 (.1 minute is 6 seconds).
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep3Units: number = 0;

    /**
     * @name RateStep3Rate
     * @description
     * Rate for the third rate step.  Note that this is the rate per rate step 3 units not the rate per unit type.  For example,
     * if the unit type is M for Minutes and billing for this rate step is based on six second increments, the rate step 3 rate
     * is the rate per 6 seconds (.1 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep3Rate: number = 0;

    /**
     * @name RateStep3MaximumUnits
     * @description
     * The maximum number of units to be included in rate step three.  For example, if the unit type is M for Minutes and the rate
     * step 3 maximum units is set to 5 then the first 5 minutes that rolled over from rate step two would be rated using rate step
     * three and any remaining time would be rated by the remaining rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep3MaximumUnits: number = 0;

    /**
     * @name RateStep4Units
     * @description
     * Units for the fourth rate step.  For example, if the unit type is M for Minutes, and billing for this fourth rate step is
     * based on six second increments then this field would be .1 (.1 minute is 6 seconds).
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep4Units: number = 0;

    /**
     * @name RateStep4Rate
     * @description
     * Rate for the fourth rate step.  Note that this is the rate per rate step 4 units not the rate per unit type.  For example,
     * if the unit type is M for Minutes and billing for this rate step is based on six second increments, the rate step 4 rate
     * is the rate per 6 seconds (.1 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep4Rate: number = 0;

    /**
     * @name RateStep4MaximumUnits
     * @description
     * This field is not currently being used.  All remaining units that were not rated using the first three rate steps will be
     * rated using this fourth rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep4MaximumUnits: number = 0;

    /**
     * @name CostSetupRate
     * @description
     * Cost setup rate is a flat rate applied to each call in addition to unit rates involved.
     * @servertype Double (nullable)
     * @type {number}
     */
    CostSetupRate: number = 0;

    /**
     * @name CostMinimum
     * @description
     * If a calculated cost is less than the cost minimum then the cost minimum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    CostMinimum: number = 0;

    /**
     * @name CostMaximum
     * @description
     * If a calculated cost is more than the cost maximum then the cost maximum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    CostMaximum: number = 0;

    /**
     * @name SurchargeUnitType
     * @description
     * Unit type for this surcharge calculation.  Possible values include:
     *  = None
     * M = Minutes
     * S = Seconds
     * P = Pulses
     * U = Units
     * FP = Fax Pages
     * BSS = Bytes Sent Source
     * BRS = Bytes Received Source
     * BTS = Bytes Total Source
     * BST = Bytes Sent Target
     * BRT = Bytes Received Target
     * BTT = Bytes Total Target
     * BT = Bytes Total Source and Target
     * KBSS = KiloBytes Sent Source
     * KBRS = KiloBytes Received Source
     * KBTS = KiloBytes Total Source
     * KBST = KiloBytes Sent Target
     * KBRT = KiloBytes Received Target
     * KBTT = KiloBytes Total Target
     * KBT = KiloBytes Total Source and Target
     * MBSS = MegaBytes Sent Source
     * MBRS = MegaBytes Received Source
     * MBTS = MegaBytes Total Source
     * MBST = MegaBytes Sent Target
     * MBRT = MegaBytes Received Target
     * MBTT = MegaBytes Total Target
     * MBT = MegaBytes Total Source and Target
     * GBSS = GigaBytes Sent Source
     * GBRS = GigaBytes Received Source
     * GBTS = GigaBytes Total Source
     * GBST = GigaBytes Sent Target
     * GBRT = GigaBytes Received Target
     * GBTT = GigaBytes Total Target
     * GBT = GigaBytes Total Source and Target
     * PKSS = Packets Sent Source
     * PKRS = Packets Received Source
     * PKTS = Packets Total Source
     * PKST = Packets Sent Target
     * PKRT = Packets Received Target
     * PKTT = Packets Total Target
     * PKT = Packets Total Source and Target
     * OSS = Octets Sent Source
     * ORS = Octets Received Source
     * OTS = Octets Total Source
     * OST = Octets Sent Target
     * ORT = Octets Received Target
     * OTT = Octets Total Target
     * OT = Octets Total Source and Target
     * @servertype String
     * @type {string}
     */
    SurchargeUnitType: string = "";

    /**
     * @name SurchargeStep1Units
     * @description
     * Units for the first rate step.  For example, if the unit type is M for Minutes, and billing is based on a one minute minimum
     * with six second increments then this field would be 1.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep1Units: number = 0;

    /**
     * @name SurchargeStep1Rate
     * @description
     * Surcharge for the first rate step.  Note that this is the surcharge per surcharge step 1 units not the rate per surcharge
     * unit type.  For example, if the surcharge unit type is M for Minutes and billing is based on a 30 second minimum with six
     * second increments, the surcharge step 1 rate is the rate per 30 seconds (.5 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep1Rate: number = 0;

    /**
     * @name SurchargeStep1MaximumUnits
     * @description
     * The maximum number of units to be included in rate step one.  For example, if the surcharge unit type is M for Minutes and
     * the surcharge step 1 maximum units is set to 5 then the first 5 minutes would be rated using rate step one and any remaining
     * time would be rated by the remaining rate steps.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep1MaximumUnits: number = 0;

    /**
     * @name SurchargeStep2Units
     * @description
     * Units for the second rate step.  For example, if the surcharge unit type is M for Minutes, and billing is based on a one
     * minute minimum with six second increments then this field would be .1 (.1 minute is 6 seconds).
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep2Units: number = 0;

    /**
     * @name SurchargeStep2Rate
     * @description
     * Surcharge for the second rate step.  Note that this is the surcharge per surcharge step 2 units not the rate per surcharge
     * unit type.  For example, if the surcharge unit type is M for Minutes and billing is based on a 30 second minimum with six
     * second increments, the surcharge step 2 rate is the rate per 6 seconds (.1 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep2Rate: number = 0;

    /**
     * @name SurchargeStep2MaximumUnits
     * @description
     * The maximum number of units to be included in rate step two.  For example, if the surcharge unit type is M for Minutes and
     * the surcharge step 2 maximum units is set to 5 then the first 5 minutes that rolled over from rate step one would be rated
     * using rate step two and any remaining time would be rated by the remaining rate steps.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep2MaximumUnits: number = 0;

    /**
     * @name SurchargeStep3Units
     * @description
     * Units for the third rate step.  For example, if the surcharge unit type is M for Minutes, and billing for this third rate
     * step is based on six second increments then this field would be .1 (.1 minute is 6 seconds).
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep3Units: number = 0;

    /**
     * @name SurchargeStep3Rate
     * @description
     * Surcharge for the third rate step.  Note that this is the surcharge per surcharge step 3 units not the rate per surcharge
     * unit type.  For example, if the surcharge unit type is M for Minutes and billing for this rate step is based on six second
     * increments, the surcharge step 3 rate is the rate per 6 seconds (.1 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep3Rate: number = 0;

    /**
     * @name SurchargeStep3MaximumUnits
     * @description
     * The maximum number of units to be included in rate step three.  For example, if the surcharge unit type is M for Minutes
     * and the surcharge step 3 maximum units is set to 5 then the first 5 minutes that rolled over from rate step two would be
     * rated using rate step three and any remaining time would be rated by the remaining rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep3MaximumUnits: number = 0;

    /**
     * @name SurchargeStep4Units
     * @description
     * Units for the fourth rate step.  For example, if the surcharge unit type is M for Minutes, and billing for this fourth rate
     * step is based on six second increments then this field would be .1 (.1 minute is 6 seconds).
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep4Units: number = 0;

    /**
     * @name SurchargeStep4Rate
     * @description
     * Surcharge for the fourth rate step.  Note that this is the surcharge per surcharge step 4 units not the rate per surcharge
     * unit type.  For example, if the surcharge unit type is M for Minutes and billing for this rate step is based on six second
     * increments, the surcharge step 4 rate is the rate per 6 seconds (.1 minutes) not the rate per minute.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep4Rate: number = 0;

    /**
     * @name SurchargeStep4MaximumUnits
     * @description
     * This field is not currently being used.  All remaining units that were not rated using the first three rate steps will be
     * rated using this fourth rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep4MaximumUnits: number = 0;

    /**
     * @name SurchargeSetupRate
     * @description
     * Surcharge setup rate is a flat rate applied to each call in addition to unit rates involved.  It can be used as a flat surcharge
     * per record.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeSetupRate: number = 0;

    /**
     * @name SurchargePercent
     * @description
     * Surcharge percent that is added to other surcharges.  It is calculated by multiplying this value by the calculated cost.
     *  Note that surcharge percent is entered as a percent not decimal.  For example, a twenty percent surcharge would be entered
     * as 20 not .20.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargePercent: number = 0;

    /**
     * @name SurchargeMinimum
     * @description
     * If a calculated surcharge is less than the surcharge minimum then the surcharge minimum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeMinimum: number = 0;

    /**
     * @name SurchargeMaximum
     * @description
     * If a calculated surcharge is more than the surcharge maximum then the surcharge maximum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeMaximum: number = 0;

    /**
     * @name Flags
     * @description
     * A list of flags for this rating rate.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this rating rate.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RatingHolidayEditViewModel
 * @description
 * The Rating Holiday table contains dates that should be identified by the rating engine has a holiday.  Frequently, special
 * rates may apply on holidays.  This table is used to determine if a specific date qualifies as a holiday.
 */
export class RatingHolidayEditViewModel {

    /**
     * @name RatingHolidayId
     * @description
     * Rating Holiday Id uniquely identifies this rating holiday.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RatingHolidayId: number = null;

    /**
     * @name RatingProfileId
     * @description
     * The Id of the Rating Profile this holiday belongs to.
     * @servertype Int64
     * @type {number}
     */
    RatingProfileId: number = null;

    /**
     * @name Description
     * @description
     * Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the holiday is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name HolidayStartDateTime
     * @description
     * The holiday start date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    HolidayStartDateTime: Date = null;

    /**
     * @name HolidayEndDateTime
     * @description
     * The holiday end date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    HolidayEndDateTime: Date = null;

    /**
     * @name UsageTypeList
     * @description
     * A list of usage types that qualify for the holiday.  If the list is empty then all usage types are considered valid for the
     * holiday.
     * @servertype string array
     * @type {string[]}
     */
    UsageTypeList: string[] = [];

    /**
     * @name Flags
     * @description
     * A list of flags for this rating holiday.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this rating holiday.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RatingProfileEditViewModel
 * @description
 * The Rating Profile table contains information related to rating of usage detail records.  This table provides basic information
 * related to rating, several other tables are related to the rating profile table and contain specific details of how the rating
 * is performed.  See rating group, step, rate, surcharge discount, zone and holiday tables.
 */
export class RatingProfileEditViewModel {

    /**
     * @name RatingProfileId
     * @description
     * Rating Profile Id uniquely identifies this rating profile.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RatingProfileId: number = null;

    /**
     * @name ExternalRatingProfileId
     * @description
     * Rating Profile Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalRatingProfileId: string = "";

    /**
     * @name Description
     * @description
     * Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ProfileType
     * @description
     * Profile type indicates how this rating profile may be inherited by other profiles.  Possible values include:
     * A = Abstract (Cannot be used directly by a cost center.  Can only be inherited by other rating profiles.)
     * S = Sealed (Can only be used by a cost center.  Cannot be inherited by other rating profiles.)
     * N = Normal (Can be used by a cost center and inherited by other rating profiles.)
     * @servertype String
     * @type {string}
     */
    ProfileType: string = "N";

    /**
     * @name TimeZoneId
     * @description
     * Time Zone Id specifying the time zone used for day of week and time of day settings on rates for this profile.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name RateCode
     * @description
     * User defined rate code stored in usage tables to relate information regarding the rate used.
     * @servertype String
     * @type {string}
     */
    RateCode: string = "";

    /**
     * @name VisibilityCostCenterType
     * @description
     * A list of cost center types that this location profile is valid for.  Possible values include:
     * R1 = Retail 1
     * R2 = Retail 2
     * A1 = Agent 1
     * A2 = Agent 2
     * W1 = Wholesale 1
     * W2 = Wholesale 2
     * W3 = Wholesale 3
     * T1 = Alternate 1
     * @servertype string array
     * @type {string[]}
     */
    VisibilityCostCenterType: string[] = [];

    /**
     * @name VisibilityAccountType
     * @description
     * Account type that this item is valid for.  Empty indicates valid for all account types.
     * @servertype String
     * @type {string}
     */
    VisibilityAccountType: string = "";

    /**
     * @name VisibilityAccountClass
     * @description
     * Account class that this item is valid for.  Empty indicates valid for all account classes.
     * @servertype String
     * @type {string}
     */
    VisibilityAccountClass: string = "";

    /**
     * @name VisibilityMarketType
     * @description
     * Market type that this item is valid for.  Empty indicates valid for all market types.
     * @servertype String
     * @type {string}
     */
    VisibilityMarketType: string = "";

    /**
     * @name VisibilityContactId
     * @description
     * A Contact Id that represents the only contact that this item is valid for.  Null indicates valid for all contacts.  If this
     * contact is an agent the item is valid for all customers assigned to this agent.  If this contact is a primary customer the
     * item is valid for all secondary customers assigned to this primary customer.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityContactId: number = null;

    /**
     * @name VisibilityContactName
     * @servertype String
     * @type {string}
     */
    VisibilityContactName: string = "";

    /**
     * @name IsoCurrencyCode
     * @description
     * ISO Currency Code.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name InheritLocationRatingProfileId
     * @description
     * Inherit custom locations from this Rating Profile.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InheritLocationRatingProfileId: number = null;

    /**
     * @name InheritSurchargeRatingProfileId
     * @description
     * Inherit universal surcharges and discounts from this Rating Profile.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InheritSurchargeRatingProfileId: number = null;

    /**
     * @name InheritZoneRatingProfileId
     * @description
     * Inherit zones from this Rating Profile.  Note that the rating profile specified here supports inheritance of the full rating
     * profile ancestry (i.e. not only parent but also grandparent, great-grandparent, etc.) while the rating step inherit zone
     * specified profile can only inherit from the parent due to lookup restrictions.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InheritZoneRatingProfileId: number = null;

    /**
     * @name InheritHolidayRatingProfileId
     * @description
     * Inherit holidays from this Rating Profile.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InheritHolidayRatingProfileId: number = null;

    /**
     * @name RoundUnits
     * @description
     * Unit rounding information.  Empty/Null means no rounding of units.  Other Examples:
     * UCI = Use Costing Increments
     * T4 = True rounding to 4 decimal places
     * U2 = Round up to 2 decimal places
     * D6 = Round down to 6 decimal places
     * @servertype String
     * @type {string}
     */
    RoundUnits: string = "UCI";

    /**
     * @name RoundBaseCost
     * @description
     * Base cost rounding information.  Empty/Null means no rounding.  Other Examples:
     * T4 = True rounding to 4 decimal places
     * U2 = Round up to 2 decimal places
     * D6 = Round down to 6 decimal places
     * @servertype String
     * @type {string}
     */
    RoundBaseCost: string = "";

    /**
     * @name RoundFullCost
     * @description
     * Full cost rounding information.  Empty/Null means no rounding.  Other Examples:
     * T4 = True rounding to 4 decimal places
     * U2 = Round up to 2 decimal places
     * D6 = Round down to 6 decimal places
     * @servertype String
     * @type {string}
     */
    RoundFullCost: string = "";

    /**
     * @name DelayedRatingMethod
     * @description
     * Delayed rating method to use.  Possible values include:
     * N = None (No delayed rating is performed)
     * M = Multiplier (The rate calculated in rate center outlined in delayed rating basis is multiplied by the value specified
     * in delayed rating multiplier and the result is used for the rate.)
     * A = Add (The rate calculated in rate center outlined in delayed rating basis is added to the rate calculated by this rating
     * profile and that sum is used for the rate.)
     * B = Add then Multiply (The rate calculated in rate center outlined in delayed rating basis is added to the rate calculated
     * by this rating profile and that sum is multiplied by the value specified in delayed rating multiplier and the result is used
     * for the rate.)
     * @servertype String
     * @type {string}
     */
    DelayedRatingMethod: string = "N";

    /**
     * @name DelayedRatingBasis
     * @description
     * Basis for this profile to delay rating until later.  For example, if retail 1 (R1) rating is to be 15% above the wholesale
     * 1 (W1) cost and this rating profile was for R1 then this field would contain W1 and the delayed rating multiplier field would
     * contain 1.15.  Possible values include:
     * R1 = Retail 1
     * R2 = Retail 2
     * A1 = Agent 1
     * A2 = Agent 2
     * W1 = Wholesale 1
     * W2 = Wholesale 2
     * W3 = Wholesale 3
     * T1 = Alternate 1
     * @servertype String
     * @type {string}
     */
    DelayedRatingBasis: string = "";

    /**
     * @name DelayedRatingMultiplier
     * @description
     * Multiplier for this profile to delay rating until later.  For example, if retail 1 (R1) rating is to be 15% above the wholesale
     * 1 (W1) cost and this rating profile was for R1 then the delayed rating basis field would contain W1 and this field would
     * contain 1.15.
     * @servertype Double (nullable)
     * @type {number}
     */
    DelayedRatingMultiplier: number = 1;

    /**
     * @name RateUsageIncoming
     * @description
     * When true Incoming usage records should be rated.
     * @servertype Boolean
     * @type {boolean}
     */
    RateUsageIncoming: boolean = false;

    /**
     * @name RateUsageInternal
     * @description
     * When true Internal usage records should be rated.
     * @servertype Boolean
     * @type {boolean}
     */
    RateUsageInternal: boolean = false;

    /**
     * @name RateUsageIncomplete
     * @description
     * When true Not completed usage records should be rated.
     * @servertype Boolean
     * @type {boolean}
     */
    RateUsageIncomplete: boolean = false;

    /**
     * @name CheckHoliday
     * @description
     * When true Check if usage date is a holiday (see rating holiday table).
     * @servertype Boolean
     * @type {boolean}
     */
    CheckHoliday: boolean = false;

    /**
     * @name CheckSurchargeDiscount
     * @description
     * When true Check if universal surcharge/discount exists (see rating surcharge discount table).
     * @servertype Boolean
     * @type {boolean}
     */
    CheckSurchargeDiscount: boolean = false;

    /**
     * @name CheckOnNetStatusByNumber
     * @description
     * Check if usage is considered on-net using one of the following settings.  If the usage is considered on-net then the classification
     * type I (internal) is added.  Possible values include:
     * Y = If source and target number are in association table.
     * F = If source and target number are in association table with same package occurrence.
     * O = If source and target number are in association table and package occurrence is owned by same entity (i.e. both numbers
     * are part of package occurrences that are both assigned to the same customer).
     * N = On-net status is not checked by number.
     * @servertype String
     * @type {string}
     */
    CheckOnNetStatusByNumber: string = "N";

    /**
     * @name CheckOnNetStatusByNetworkElement
     * @description
     * When true check if usage is considered on-net if source and target network element are in the network element table and have
     * the same on-net group setting.  If so, classification type I (internal) is added.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckOnNetStatusByNetworkElement: boolean = false;

    /**
     * @name CheckTypeForVoiceInternal
     * @description
     * When true check if usage is voice (service type V) and internal (classification type I) and, if so, set usage type to EI.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTypeForVoiceInternal: boolean = false;

    /**
     * @name CheckTypeForVoiceNoCharge
     * @description
     * When true check if usage is voice (service type V) and no-charge (classification type N) and, if so, set usage type to FR.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckTypeForVoiceNoCharge: boolean = false;

    /**
     * @name IdentifySourceNumber
     * @description
     * When true identification of source number should be performed.
     * @servertype Boolean
     * @type {boolean}
     */
    IdentifySourceNumber: boolean = false;

    /**
     * @name IdentifyCostCenterLocation
     * @description
     * When true identification of cost center location should be performed.
     * @servertype Boolean
     * @type {boolean}
     */
    IdentifyCostCenterLocation: boolean = false;

    /**
     * @name ReportSourceLocationIncoming
     * @description
     * When true report the location of source number for incoming usage.
     * @servertype Boolean
     * @type {boolean}
     */
    ReportSourceLocationIncoming: boolean = false;

    /**
     * @name CustomLocationDomesticFullNumberSearch
     * @description
     * When true will search for a full phone number on domestic calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationDomesticFullNumberSearch: boolean = false;

    /**
     * @name CustomLocationDomesticRangeSearch
     * @description
     * When true will search for a full phone number that may fall within a range of numbers on domestic calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationDomesticRangeSearch: boolean = false;

    /**
     * @name CustomLocationDomesticDigitSearch
     * @description
     * The range of digits to search for domestic number search.  For example, if 3-6 search for a match using phone number digits
     * starting at 6 and going down to 3.
     * @servertype String
     * @type {string}
     */
    CustomLocationDomesticDigitSearch: string = "";

    /**
     * @name CustomLocationInternationalFullNumberSearch
     * @description
     * When true will search for a full phone number on international calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationInternationalFullNumberSearch: boolean = false;

    /**
     * @name CustomLocationInternationalRangeSearch
     * @description
     * When true will search for a full phone number that may fall within a range of numbers on international calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationInternationalRangeSearch: boolean = false;

    /**
     * @name CustomLocationInternationalDigitSearch
     * @description
     * The range of digits to search for international number search.  For example, if 1-5 search for a match using phone number
     * digits starting at 5 and going down to 1.
     * @servertype String
     * @type {string}
     */
    CustomLocationInternationalDigitSearch: string = "";

    /**
     * @name FlagMissingFullTarget
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingFullTarget: string = "I";

    /**
     * @name FlagMissingPartialTarget
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingPartialTarget: string = "E";

    /**
     * @name FlagMissingFullSource
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingFullSource: string = "I";

    /**
     * @name FlagMissingPartialSource
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingPartialSource: string = "E";

    /**
     * @name FlagMissingFullCostCenter
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingFullCostCenter: string = "I";

    /**
     * @name FlagMissingPartialCostCenter
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingPartialCostCenter: string = "E";

    /**
     * @name FlagMissingServiceIdentificationForData
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingServiceIdentificationForData: string = "E";

    /**
     * @name FlagMissingServiceIdentificationForVideo
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingServiceIdentificationForVideo: string = "E";

    /**
     * @name FlagMissingServiceIdentificationForContent
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingServiceIdentificationForContent: string = "E";

    /**
     * @name FlagMissingHolidayRate
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagMissingHolidayRate: string = "E";

    /**
     * @name FlagZeroCostIncoming
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagZeroCostIncoming: string = "I";

    /**
     * @name FlagZeroCostOutgoing
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagZeroCostOutgoing: string = "W";

    /**
     * @name FlagZeroCostInternal
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagZeroCostInternal: string = "I";

    /**
     * @name FlagZeroCostExternal
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagZeroCostExternal: string = "W";

    /**
     * @name FlagZeroCostCompleted
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagZeroCostCompleted: string = "W";

    /**
     * @name FlagZeroCostIncomplete
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagZeroCostIncomplete: string = "I";

    /**
     * @name FlagZeroCostNoUnits
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagZeroCostNoUnits: string = "I";

    /**
     * @name Flags
     * @description
     * A list of flags for this rating profile.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this rating profile.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RatingSurchargeDiscountEditViewModel
 * @description
 * The Rating Surcharge Discount table contains information related to surcharges and/or discounts to be applied for a specific
 * rating profile.  Surcharges and/or discounts can be defined based on flexible criteria and are applied on a per usage basis.
 *  Surcharges and/or discounts that need to be applied to an invoice for an entire billing period should be done via the billing
 * interface.
 */
export class RatingSurchargeDiscountEditViewModel {

    /**
     * @name RatingSurchargeDiscountId
     * @description
     * Rating Surcharge Discount Id uniquely identifies this rating surcharge/discount.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RatingSurchargeDiscountId: number = null;

    /**
     * @name RatingProfileId
     * @description
     * The Id of the Rating Profile this surcharge/discount belongs to.
     * @servertype Int64
     * @type {number}
     */
    RatingProfileId: number = null;

    /**
     * @name Description
     * @description
     * Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the rating group is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Filter
     * @description
     * User defined filter to determine if a usage row should use this surcharge/discount or not.
     * @servertype String
     * @type {string}
     */
    Filter: string = "";

    /**
     * @name ValueFlatRate
     * @description
     * Flat rate applied to each usage item.
     * @servertype Double (nullable)
     * @type {number}
     */
    ValueFlatRate: number = 0;

    /**
     * @name ValuePerMinute
     * @description
     * Rate applied for each minute of usage.
     * @servertype Double (nullable)
     * @type {number}
     */
    ValuePerMinute: number = 0;

    /**
     * @name ValueDurationRoundingFactor
     * @description
     * Duration rounding factor applied for authorization of usage that include a value per minute.  This enables the authorization
     * routines to return a duration rounded down to the appropriate factor.  For example, if this value was 60 then a return value
     * that would normally read 314 would now read 300.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValueDurationRoundingFactor: number = 0;

    /**
     * @name ValuePercent
     * @description
     * Value percent applied to each call.  It is calculated by multiplying this value by the calculated cost.  Note that value
     * percent is entered as a percent not decimal.  For example, a twenty percent value would be entered as 20 not .20.
     * @servertype Double (nullable)
     * @type {number}
     */
    ValuePercent: number = 0;

    /**
     * @name ValueMinimum
     * @description
     * If a calculated value is less than the value minimum then the value minimum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    ValueMinimum: number = 0;

    /**
     * @name ValueMaximum
     * @description
     * If a calculated value is greater than the value maximum then the value maximum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    ValueMaximum: number = 0;

    /**
     * @name RateCodeUse
     * @description
     * Use rate code within usage tables:
     * A = Append rate code to existing rate code
     * R = Replace existing rate code with rate code
     * N = No rate code recorded
     * @servertype String
     * @type {string}
     */
    RateCodeUse: string = "N";

    /**
     * @name RateCodeSeparator
     * @description
     * Separator used when appending rate code to existing rate code.
     * @servertype String
     * @type {string}
     */
    RateCodeSeparator: string = "";

    /**
     * @name RateCode
     * @description
     * User defined rate code stored in usage tables to relate information regarding the rate used.
     * @servertype String
     * @type {string}
     */
    RateCode: string = "";

    /**
     * @name Flags
     * @description
     * A list of flags for this rating surcharge/discount.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this rating surcharge/discount.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RatingZoneEditViewModel
 * @description
 * The Rating Zone table contains a matrix of source and target locations and the associated tier number for each entry in the
 * matrix.  Since tier numbers can also be assigned from several other sources, use of this table is optional.
 */
export class RatingZoneEditViewModel {

    /**
     * @name RatingZoneId
     * @description
     * Rating Zone Id uniquely identifies this rating zone.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RatingZoneId: number = null;

    /**
     * @name RatingProfileId
     * @description
     * The Id of the Rating Profile this zone belongs to.
     * @servertype Int64
     * @type {number}
     */
    RatingProfileId: number = null;

    /**
     * @name Description
     * @description
     * Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the rating group is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name SourceLocationType
     * @description
     * Code used to define what source location type to use for zone lookup.  Possible values include:
     * C = Country Code
     * N = Country Code + NPA
     * X = Country Code + NPA + NXX
     * L = LATA
     * S = State
     * O = OCN
     * 2 = 2 Character ISO Country Code
     * 3 = 3 Character ISO Country Code
     * @servertype String
     * @type {string}
     */
    SourceLocationType: string = "";

    /**
     * @name SourceLocation
     * @description
     * Source location value for source location type.
     * @servertype String
     * @type {string}
     */
    SourceLocation: string = "";

    /**
     * @name TargetLocationType
     * @description
     * Code used to define what target location type to use for zone lookup.  Possible values include:
     * C = Country Code
     * N = Country Code + NPA
     * X = Country Code + NPA + NXX
     * L = LATA
     * S = State
     * O = OCN
     * 2 = 2 Character ISO Country Code
     * 3 = 3 Character ISO Country Code
     * @servertype String
     * @type {string}
     */
    TargetLocationType: string = "";

    /**
     * @name TargetLocation
     * @description
     * Target location value for the target location type.
     * @servertype String
     * @type {string}
     */
    TargetLocation: string = "";

    /**
     * @name Tier
     * @description
     * Tier number to use for this source and target zone.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Tier: number = 0;

    /**
     * @name Flags
     * @description
     * A list of flags for this rating zone.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this rating zone.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name UsageBillingEditViewModel
 * @description
 * The Usage billing table contains information about billing for usage.
 */
export class UsageBillingEditViewModel {

    /**
     * @name UsageId
     * @description
     * Usage Id uniquely identifies this usage data record but is also the id of the usage row this usage data belongs to.
     * @servertype String
     * @type {string}
     */
    UsageId: string = null;

    /**
     * @name CostCenterType
     * @description
     * Flag that indicates the cost center type for usage billing.  Possible values include:
     * R = Retail
     * A = Agent
     * W = Wholesale
     * T = Alternate
     * @servertype String
     * @type {string}
     */
    CostCenterType: string = null;

    /**
     * @name CostCenterLevel
     * @description
     * The cost center level for this usage billing where 1 = primary, 2 = secondary, 3 = tertiary.
     * @servertype Int32
     * @type {number}
     */
    CostCenterLevel: number = null;

    /**
     * @name UsageDateTime
     * @description
     * The date and time for the usage record.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageDateTime: Date = null;

    /**
     * @name SerialNumber
     * @description
     * Usage serial number.  Some usage generate multiple accounting, usage, or statistics records that are then mediated or consolidated
     * into a single usage record using this serial number.
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name UsageType
     * @description
     * The Usage Type to assign to the usage detail record prior to the usage detail record being rated.  This is unique per service
     * type.  Possible values can include:
     * LO = Voice - Local
     * IT = Voice - IntraLATA
     * IS = Voice - Intrastate
     * LD = Voice - Interstate
     * IN = Voice - International
     * FR = Voice - Free
     * EI = Voice - Extension/Internal
     * MO = Voice - Mobile
     * EX = Voice - Exception
     * UN = Voice - Unknown
     * EM = Voice - Emergency
     * OP = Voice - Operator
     * IO = Voice - International Operator
     * LI = Voice - Local Information
     * OI = Voice - Other Information (i.e. long distance)
     * DW = Data - Web
     * DE = Data - Email
     * DF = Data - Ftp
     * DN = Data - Nntp
     * DI = Data - IM
     * DS = Data - SMS
     * DM = Data - MMS
     * DA = Data - Analytics
     * DU = Data - Usage
     * DO = Data - Other
     * VC = Video - Conference
     * VM = Video - Movie
     * VT = Video - TV
     * VO = Video - Other
     * CA = Content - Application
     * CG = Content - Gaming
     * CD = Content - Download
     * CP = Content - Pay Per View/Use
     * CM = Content - Media
     * CS = Content - Services
     * CO = Content - Other
     * @servertype String
     * @type {string}
     */
    UsageType: string = "";

    /**
     * @name ContactId
     * @description
     * Contact Id for this cost center rating.  Note this can be null when doing rating but not billing.  The type of contact assigned
     * is based on cost center type.  For example, retail cost center type could assign customers, directory users, or groups.
     * Wholesale cost center type could assign service providers, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name DurationSeconds
     * @description
     * The usage duration represented in seconds.  Note that this may be a rounded value based on rating configuration.  See the
     * usage table for the actual unrounded duration.
     * @servertype Double (nullable)
     * @type {number}
     */
    DurationSeconds: number = 0;

    /**
     * @name IsoCurrencyCode
     * @description
     * ISO Currency Code for this usage billing record.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name BaseCost
     * @description
     * The base cost associated with this usage billing record.  Base cost is before surcharges and discounts are applied.
     * @servertype Double (nullable)
     * @type {number}
     */
    BaseCost: number = 0;

    /**
     * @name FullCost
     * @description
     * The full cost associated with this usage billing record.  This is the base cost plus surcharges and minus discounts.
     * @servertype Double (nullable)
     * @type {number}
     */
    FullCost: number = 0;

    /**
     * @name RateCode
     * @description
     * Rate code or tier taken from rate records used to rate this usage.
     * @servertype String
     * @type {string}
     */
    RateCode: string = "";

    /**
     * @name RatingResult
     * @description
     * The rating result code indicates the result of the rating process.  Possible values include:
     * 0 = Hold (Usage record is not ready to be rated yet)
     * 1 = Pending (Usage record is ready to be rated)
     * 2 = Rating (Usage record is currently being rated)
     * 1031 = No Retail Level 1 Rating Profile (Info)
     * 1032 = No Retail Level 2 Rating Profile (Info)
     * 1041 = No Agent Level 1 Rating Profile (Info)
     * 1042 = No Agent Level 2 Rating Profile (Info)
     * 1051 = No Wholesale Level 1 Rating Profile (Info)
     * 1052 = No Wholesale Level 2 Rating Profile (Info)
     * 1053 = No Wholesale Level 3 Rating Profile (Info)
     * 2001 = No Retail Level 1 Customer associated with usage record (Warning)
     * 2002 = No Retail Level 2 Customer associated with usage record (Warning)
     * 2009 = No directory associated with usage record (Warning)
     * 2011 = No Agent Level 1 Agent associated with usage record (Warning)
     * 2012 = No Agent Level 2 Agent associated with usage record (Warning)
     * 2021 = No Wholesale Level 1 Service Provider associated with usage record (Warning)
     * 2022 = No Wholesale Level 2 Service Provider associated with usage record (Warning)
     * 2023 = No Wholesale Level 3 Service Provider associated with usage record (Warning)
     * 2031 = No Retail Level 1 Rating Profile (Warning)
     * 2032 = No Retail Level 2 Rating Profile (Warning)
     * 2041 = No Agent Level 1 Rating Profile (Warning)
     * 2042 = No Agent Level 2 Rating Profile (Warning)
     * 2051 = No Wholesale Level 1 Rating Profile (Warning)
     * 2052 = No Wholesale Level 2 Rating Profile (Warning)
     * 2053 = No Wholesale Level 3 Rating Profile (Warning)
     * 2071 = Unable to find full cost center location (Warning)
     * 2072 = Unable to find partial cost center location (Warning)
     * 3001 = No Retail Level 1 Customer associated with usage record (Error)
     * 3002 = No Retail Level 2 Customer associated with usage record (Error)
     * 3009 = No directory associated with usage record (Error)
     * 3011 = No Agent Level 1 Agent associated with usage record (Error)
     * 3012 = No Agent Level 2 Agent associated with usage record (Error)
     * 3021 = No Wholesale Level 1 Service Provider associated with usage record (Error)
     * 3022 = No Wholesale Level 2 Service Provider associated with usage record (Error)
     * 3023 = No Wholesale Level 3 Service Provider associated with usage record (Error)
     * 3031 = No Retail Level 1 Rating Profile (Error)
     * 3032 = No Retail Level 2 Rating Profile (Error)
     * 3041 = No Agent Level 1 Rating Profile (Error)
     * 3042 = No Agent Level 2 Rating Profile (Error)
     * 3051 = No Wholesale Level 1 Rating Profile (Error)
     * 3052 = No Wholesale Level 2 Rating Profile (Error)
     * 3053 = No Wholesale Level 3 Rating Profile (Error)
     * 3071 = Unable to find full cost center location (Error)
     * 3072 = Unable to find partial cost center location (Error)
     * 3093 = Cost center has no rating profile available
     * 3901 = No usage data source identified
     * 3902 = No telecom numbering plan identified
     * @servertype Int16 (nullable)
     * @type {number}
     */
    RatingResult: number = 0;

    /**
     * @name PackageOccurrenceId
     * @description
     * Package Occurrence Id this usage is associated with for billing purposes.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name BillingAccountId
     * @description
     * The Billing Account Id this usage is associated with for billing purposes.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name BillingTransactionId
     * @description
     * Billing Transaction Id this usage is included with for billing purposes.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name ExceptionBillingTransactionId
     * @description
     * The Exception Billing Transaction Id represents a transaction that was used to record an exception to the billing process
     * for this usage record.  That is typically due to usage being excluded or waived by an adjustment.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ExceptionBillingTransactionId: number = null;

    /**
     * @name BillingResult
     * @description
     * The billing result code indicates the result of the billing process.  Possible values include:
     * 0 = Hold (Usage record is not ready to be billed yet)
     * 1 = Pending (Usage record is ready to be billed)
     * 2 = Billing (Usage record is currently being billed)
     * 1000 = Success
     * 2000 = Warning
     * 3000 = Error
     * 4000 = Excluded
     * 5000 = Waived
     * @servertype Int16 (nullable)
     * @type {number}
     */
    BillingResult: number = 0;

    /**
     * @name ProcessingDateTime
     * @description
     * Date and time when an engine started processing this usage.  This can be used to help determine if there are any usage records
     * that should have posted success or failure but haven't and may be orphaned.  In that scenario the logs should be checked
     * for additional information.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name UsageCostCenterRatingEditViewModel
 * @description
 * The usage cost center rating table contains rating profile selection information for cost centers.
 */
export class UsageCostCenterRatingEditViewModel {

    /**
     * @name UsageCostCenterRatingId
     * @description
     * Usage Cost Center Rating Id uniquely identifies this usage cost center rating record.
     * @servertype Int64
     * @type {number}
     */
    UsageCostCenterRatingId: number = null;

    /**
     * @name OwnerResourceType
     * @description
     * The owner resource type (e.g. table, etc.) for this usage cost center rating record.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description
     * The owner resource id is the key of the owner resource type for this usage cost center rating record.  For example, if the
     * owner resource type is Package this value would be the PackageId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name CostCenterType
     * @description
     * Flag that indicates the cost center type for the rating.  Possible values include:
     * R = Retail
     * A = Agent
     * W = Wholesale
     * T = Alternate
     * @servertype String
     * @type {string}
     */
    CostCenterType: string = "R";

    /**
     * @name CostCenterLevel
     * @description
     * The cost center level for this rating where 1 = primary, 2 = secondary, 3 = tertiary.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CostCenterLevel: number = 1;

    /**
     * @name RatingProfileId
     * @description
     * The rating profile id for this cost center.
     * @servertype Int64
     * @type {number}
     */
    RatingProfileId: number = null;

    /**
     * @name ValidStartBillingPeriod
     * @description
     * The billing period number when this item is valid.  Used in conjunction with valid end billing period to determine when the
     * item is valid.  This is only valid for package cost centers since packages are tied to billing.  A value of 0 indicates no
     * starting billing period for validity.  This can be used to offer introductory discounts or loyalty discounts.  For example,
     * months 0-3 may represent an introductory discount, months 4-12 the standard price, and months 13-0 (unlimited) a loyalty
     * discount.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidStartBillingPeriod: number = 0;

    /**
     * @name ValidEndBillingPeriod
     * @description
     * The billing period number when this item is valid.  Used in conjunction with valid start billing period to determine when
     * the item is valid.  This is only valid for package cost centers since packages are tied to billing.  A value of 0 indicates
     * no ending billing period for validity.  This can be used to offer introductory discounts or loyalty discounts.  For example,
     * months 0-3 may represent an introductory discount, months 4-12 the standard price, and months 13-0 (unlimited) a loyalty
     * discount.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidEndBillingPeriod: number = 0;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Flags
     * @description
     * A list of flags for this usage cost center rating.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this usage cost center rating.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name UsageDataEditViewModel
 * @description
 * The Usage data table contains information about usage that is less frequently utilized or accessed and, therefore, is kept
 * in a separate table linked to a usage table.
 */
export class UsageDataEditViewModel {

    /**
     * @name UsageId
     * @description
     * Usage Id uniquely identifies this usage data record but is also the id of the usage row this usage data belongs to.
     * @servertype String
     * @type {string}
     */
    UsageId: string = null;

    /**
     * @name UsageDateTime
     * @description
     * The date and time for the usage record.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageDateTime: Date = null;

    /**
     * @name SerialNumber
     * @description
     * Usage serial number.  Some usage generate multiple accounting, usage, or statistics records that are then mediated or consolidated
     * into a single usage record using this serial number.
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name SourceIdentifierDescription
     * @description
     * Description of the source identifier.
     * @servertype String
     * @type {string}
     */
    SourceIdentifierDescription: string = "";

    /**
     * @name SourceIdentifierInformation
     * @description
     * Source identifier information.  For phone numbers this is the ANI Information Indicator Digits.  Typical values in use in
     * North America range from 00 to 99 and are outlined at the North American Numbering Plan Administration web site at http://www.nanpa.com.
     * @servertype String
     * @type {string}
     */
    SourceIdentifierInformation: string = "";

    /**
     * @name OtherIdentifier1
     * @description
     * Data source dependent other identifier.
     * @servertype String
     * @type {string}
     */
    OtherIdentifier1: string = "";

    /**
     * @name OtherIdentifier2
     * @description
     * Data source dependent other identifier.
     * @servertype String
     * @type {string}
     */
    OtherIdentifier2: string = "";

    /**
     * @name OriginalTargetIdentifier
     * @description
     * The original target identifier is the DNIS (Dialed Number Information Service) number or ip address/url prior to redirect.
     * @servertype String
     * @type {string}
     */
    OriginalTargetIdentifier: string = "";

    /**
     * @name TargetIdentifierDetails
     * @description
     * Additional details regarding the target identifier.  For example, voice records may use this for the city, state, country,
     * etc.
     * @servertype String
     * @type {string}
     */
    TargetIdentifierDetails: string = "";

    /**
     * @name AccessCode
     * @description
     * The Access Code represents a value that is used to look up appropriate rates on a per usage basis.  The Access Code can override
     * the default rate found in a rating profile.  For example, for voice calls in North America 101-XXXX codes can be used to
     * select a specific carrier.  This could also be used for ITSP domain name look ups.
     * @servertype String
     * @type {string}
     */
    AccessCode: string = "";

    /**
     * @name TransferredExtension
     * @description
     * Transferred extension associated with this usage record.  Depending upon the system in use, this property might be empty,
     * contain the extension number the usage was transferred to, or contain the extension number the usage was transferred from.
     * @servertype String
     * @type {string}
     */
    TransferredExtension: string = null;

    /**
     * @name Flags
     * @description
     * The flag property contains one or more data source dependent values.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name NetworkId
     * @description
     * Network Id - data source dependent.
     * @servertype String
     * @type {string}
     */
    NetworkId: string = "";

    /**
     * @name AccountingId
     * @description
     * The Accounting Id - data source dependent.
     * @servertype String
     * @type {string}
     */
    AccountingId: string = "";

    /**
     * @name GroupId
     * @description
     * The Group Id is frequently data source dependent but for conference calls (where classification type includes X) the group
     * id contains the conference id that uniquely identifies the conference and is common between all legs or participants of the
     * conference.
     * @servertype String
     * @type {string}
     */
    GroupId: string = "";

    /**
     * @name ClassOfService
     * @description
     * Class of service - data source dependent.
     * @servertype String
     * @type {string}
     */
    ClassOfService: string = "";

    /**
     * @name EncodingType
     * @description
     * Encoding type - data source dependent.
     * @servertype String
     * @type {string}
     */
    EncodingType: string = "";

    /**
     * @name SourceDisconnectCode
     * @description
     * The source disconnect code is a data source dependent value specifying the disconnect status and/or resolution for this usage
     * record (i.e. success, failure, etc.).
     * @servertype String
     * @type {string}
     */
    SourceDisconnectCode: string = "";

    /**
     * @name TargetDisconnectCode
     * @description
     * The target disconnect code is a data source dependent value specifying the disconnect status and/or resolution for this usage
     * record (i.e. success, failure, etc.).
     * @servertype String
     * @type {string}
     */
    TargetDisconnectCode: string = "";

    /**
     * @name ConnectDurationSeconds
     * @description
     * Connect duration represented in seconds.  This is the number of seconds required to setup the connection (while the phone
     * rang, etc.).
     * @servertype Double (nullable)
     * @type {number}
     */
    ConnectDurationSeconds: number = 0;

    /**
     * @name HoldDurationSeconds
     * @description
     * Hold and/or queue duration represented in seconds.  Represents the amount of time the usage was on hold or waiting in queue.
     * @servertype Double (nullable)
     * @type {number}
     */
    HoldDurationSeconds: number = 0;

    /**
     * @name SourceInPortGroupId
     * @description
     * Port Group Id of the source inbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceInPortGroupId: number = null;

    /**
     * @name SourceInPortNumberId
     * @description
     * Port Number Id of the source inbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceInPortNumberId: number = null;

    /**
     * @name SourceOutPortGroupId
     * @description
     * Port Group Id of the source outbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceOutPortGroupId: number = null;

    /**
     * @name SourceOutPortNumberId
     * @description
     * Port Number Id of the source outbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceOutPortNumberId: number = null;

    /**
     * @name SourceInformation
     * @description
     * This property is data source dependent source information.
     * @servertype String
     * @type {string}
     */
    SourceInformation: string = "";

    /**
     * @name SourceType
     * @description
     * Nature of the source end of the usage.  Possible values are:
     * VP = Type of usage on source end is Voice PSTN.
     * VI = Type of usage on source end is Voice IP network.
     * VF = Type of usage on source end is Voice Frame Relay network.
     * VS = Type of usage on source end is Voice SIP message.
     * VW = Type of usage on source end is Voice Wireless.
     * ?? = Type of usage on source end is not available or unknown.
     * @servertype String
     * @type {string}
     */
    SourceType: string = "??";

    /**
     * @name TargetInPortGroupId
     * @description
     * Port Group Id of the target inbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetInPortGroupId: number = null;

    /**
     * @name TargetInPortNumberId
     * @description
     * Port Number Id of the target inbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetInPortNumberId: number = null;

    /**
     * @name TargetOutPortGroupId
     * @description
     * Port Group Id of the target outbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetOutPortGroupId: number = null;

    /**
     * @name TargetOutPortNumberId
     * @description
     * Port Number Id of the target outbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetOutPortNumberId: number = null;

    /**
     * @name TargetInformation
     * @description
     * This property is data source dependent target information.
     * @servertype String
     * @type {string}
     */
    TargetInformation: string = "";

    /**
     * @name TargetType
     * @description
     * Nature of the target end of the usage.  Possible values are:
     * VP = Type of usage on source end is Voice PSTN.
     * VI = Type of usage on source end is Voice IP network.
     * VF = Type of usage on source end is Voice Frame Relay network.
     * VS = Type of usage on source end is Voice SIP message.
     * VW = Type of usage on source end is Voice Wireless.
     * ?? = Type of usage on source end is not available or unknown.
     * @servertype String
     * @type {string}
     */
    TargetType: string = "??";

    /**
     * @name DisconnectNetworkElementId
     * @description
     * Network Element Id of the disconnect network element.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DisconnectNetworkElementId: number = null;

    /**
     * @name PacketsSentSource
     * @description
     * Packets sent from source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsSentSource: number = 0;

    /**
     * @name PacketsReceivedSource
     * @description
     * Packets received to source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsReceivedSource: number = 0;

    /**
     * @name PacketsSentTarget
     * @description
     * Packets sent from target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsSentTarget: number = 0;

    /**
     * @name PacketsReceivedTarget
     * @description
     * Packets received to target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsReceivedTarget: number = 0;

    /**
     * @name PacketsLostSource
     * @description
     * Packets lost on source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsLostSource: number = 0;

    /**
     * @name PacketsLostTarget
     * @description
     * Packets lost on target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsLostTarget: number = 0;

    /**
     * @name JitterSource
     * @description
     * Jitter on source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    JitterSource: number = 0;

    /**
     * @name JitterTarget
     * @description
     * Jitter on target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    JitterTarget: number = 0;

    /**
     * @name LatencySource
     * @description
     * Latency on Source Node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LatencySource: number = 0;

    /**
     * @name LatencyTarget
     * @description
     * Latency on Target Node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LatencyTarget: number = 0;

    /**
     * @name OctetsSentSource
     * @description
     * Octets sent from source node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsSentSource: number = 0;

    /**
     * @name OctetsReceivedSource
     * @description
     * Octets received to source node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsReceivedSource: number = 0;

    /**
     * @name OctetsSentTarget
     * @description
     * Octets sent from target node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsSentTarget: number = 0;

    /**
     * @name OctetsReceivedTarget
     * @description
     * Octets received to target node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsReceivedTarget: number = 0;

    /**
     * @name Stat01SentSource
     * @description
     * Data source dependent statistic sent from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01SentSource: number = 0;

    /**
     * @name Stat01ReceivedSource
     * @description
     * Data source dependent statistic received from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01ReceivedSource: number = 0;

    /**
     * @name Stat01SentTarget
     * @description
     * Data source dependent statistic sent from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01SentTarget: number = 0;

    /**
     * @name Stat01ReceivedTarget
     * @description
     * Data source dependent statistic received from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01ReceivedTarget: number = 0;

    /**
     * @name Stat02SentSource
     * @description
     * Data source dependent statistic sent from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02SentSource: number = 0;

    /**
     * @name Stat02ReceivedSource
     * @description
     * Data source dependent statistic received from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02ReceivedSource: number = 0;

    /**
     * @name Stat02SentTarget
     * @description
     * Data source dependent statistic sent from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02SentTarget: number = 0;

    /**
     * @name Stat02ReceivedTarget
     * @description
     * Data source dependent statistic received from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02ReceivedTarget: number = 0;

    /**
     * @name Stat03
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat03: number = 0;

    /**
     * @name Stat04
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat04: number = 0;

    /**
     * @name Stat05
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat05: number = 0;

    /**
     * @name Stat06
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat06: number = 0;

    /**
     * @name Stat07
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat07: number = 0;

    /**
     * @name Stat08
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat08: number = 0;

    /**
     * @name Stat09
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat09: number = 0;

    /**
     * @name Stat10
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat10: number = 0;

    /**
     * @name BandwidthSpeed
     * @description
     * Bandwidth speed expressed in bits per second.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BandwidthSpeed: number = 0;

    /**
     * @name BandwidthConsumed
     * @description
     * Bandwidth consumed expressed in bytes.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BandwidthConsumed: number = 0;

    /**
     * @name ContentPageCount
     * @description
     * Number of pages included in the content (e.g. pages in fax, document, etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ContentPageCount: number = 0;

    /**
     * @name Properties
     * @description
     * Other properties related to this usage.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name UsageDataFeedEditViewModel
 * @description
 * The Usage Data Feed table is a holding table for usage records or sub-records that are fed into the database from any source
 * and are then parsed, mediated, and posted to one of our usage tables.
 */
export class UsageDataFeedEditViewModel {

    /**
     * @name UsageDataFeedId
     * @description
     * Usage Data Feed Id uniquely identifies this usage record.
     * @servertype String
     * @type {string}
     */
    UsageDataFeedId: string = null;

    /**
     * @name UsageDataSourceId
     * @description
     * The Id of the Usage Data Source this usage record belongs to.  Can be null if external usage data source id is specified
     * or if the usage data has custom processing routine.
     * @servertype Int64
     * @type {number}
     */
    UsageDataSourceId: number = null;

    /**
     * @name ExternalUsageDataSourceId
     * @description
     * External Usage Data Source Id of the data source this usage record belongs to.  This is optional if usage data source id
     * is provided.
     * @servertype String
     * @type {string}
     */
    ExternalUsageDataSourceId: string = "";

    /**
     * @name MediationStatus
     * @description
     * The mediation status for this usage record.  Possible values are:
     * P = Pending (mediation not started yet)
     * R = Running (mediation is running)
     * N = None (no mediation match found)
     * S = Success (mediation successful)
     * F = Failure (mediation failed)
     * @servertype String
     * @type {string}
     */
    MediationStatus: string = "";

    /**
     * @name QueueDateTime
     * @description
     * The date and time for the usage record to dequeue for processing.  This is typically the time the record was inserted but
     * could differ based on processing needs.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = null;

    /**
     * @name Status
     * @description
     * Status of the import for this usage record.  Possible values are:
     * P = Pending (import not started yet)
     * R = Running (import is running)
     * S = Success (import successful)
     * F = Failure (import failed)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name ResultCode
     * @description
     * The result code for the import.  Possible values include standard result code plus these options:
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description
     * Result message for the import.  More details can be found the logs if needed.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name UsageImportDateTime
     * @description
     * The date and time for the import session identified by usage import log id.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageImportDateTime: Date = null;

    /**
     * @name UsageImportLogId
     * @description
     * Usage Data Import Log Id that processed this usage record.
     * @servertype String
     * @type {string}
     */
    UsageImportLogId: string = null;

    /**
     * @name UsageRecordType
     * @description
     * The usage record type.  Possible values include:
     * 0 = Normal
     * 1 = Start
     * 2 = Stop
     * 3 = Update
     * 4 = Partial
     * 255 = Unknown
     * @servertype Byte (nullable)
     * @type {number}
     */
    UsageRecordType: number = 0;

    /**
     * @name UsageDateTime
     * @description
     * The date and time for the usage record.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageDateTime: Date = null;

    /**
     * @name UsageEndDateTime
     * @description
     * The date and time for the usage record ending.  This may be optional when the duration is provided.  When the duration is
     * not provided this can be used to calculate the duration.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageEndDateTime: Date = null;

    /**
     * @name UsageTimeZone
     * @description
     * The name of the time zone that usage time is expressed in.  Typically the internal time zone name but could also be: IANA
     * time zone name, internal time zone description, internal time zone id, system time zone id, or UTC offset in +99:99 format.
     * @servertype String
     * @type {string}
     */
    UsageTimeZone: string = "";

    /**
     * @name SerialNumber
     * @description
     * Usage serial number.  Some usage generate multiple accounting, usage, or statistics records that are then mediated or consolidated
     * into a single usage record using this serial number.
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name GroupSerialNumber
     * @description
     * Group serial number for usage that requires mediation based on an available group serial number.
     * @servertype String
     * @type {string}
     */
    GroupSerialNumber: string = "";

    /**
     * @name SourceIdentifier
     * @description
     * Source phone number (ANI), ip address, url, or other identifier associated with the source for this usage.
     * @servertype String
     * @type {string}
     */
    SourceIdentifier: string = "";

    /**
     * @name SourceIdentifierDescription
     * @description
     * Description of the source identifier.
     * @servertype String
     * @type {string}
     */
    SourceIdentifierDescription: string = "";

    /**
     * @name SourceIdentifierInformation
     * @description
     * Source identifier information.  For phone numbers this is the ANI Information Indicator Digits.  Typical values in use in
     * North America range from 00 to 99 and are outlined at the North American Numbering Plan Administration web site at http://www.nanpa.com.
     * @servertype String
     * @type {string}
     */
    SourceIdentifierInformation: string = "";

    /**
     * @name OtherIdentifier1
     * @description
     * Data source dependent other identifier.
     * @servertype String
     * @type {string}
     */
    OtherIdentifier1: string = "";

    /**
     * @name OtherIdentifier2
     * @description
     * Data source dependent other identifier.
     * @servertype String
     * @type {string}
     */
    OtherIdentifier2: string = "";

    /**
     * @name OriginalTargetIdentifier
     * @description
     * The original target identifier is the DNIS (Dialed Number Information Service) number or ip address/url prior to redirect.
     * @servertype String
     * @type {string}
     */
    OriginalTargetIdentifier: string = "";

    /**
     * @name TargetIdentifier
     * @description
     * Target phone number, ip address, url, or other target for this usage.
     * @servertype String
     * @type {string}
     */
    TargetIdentifier: string = "";

    /**
     * @name TargetIdentifierDescription
     * @description
     * Description of the target identifier.  For example, voice records may use this for the city, state, country, etc.
     * @servertype String
     * @type {string}
     */
    TargetIdentifierDescription: string = "";

    /**
     * @name TargetIdentifierDetails
     * @description
     * Additional details regarding the target identifier.  For example, voice records may use this for the city, state, country,
     * etc.
     * @servertype String
     * @type {string}
     */
    TargetIdentifierDetails: string = "";

    /**
     * @name AccessCode
     * @description
     * The Access Code represents a value that is used to look up appropriate rates on a per usage basis.  The Access Code can override
     * the default rate found in a rating profile.  For example, for voice calls in North America 101-XXXX codes can be used to
     * select a specific carrier.  This could also be used for ITSP domain name look ups.
     * @servertype String
     * @type {string}
     */
    AccessCode: string = "";

    /**
     * @name Extension
     * @description
     * Extension associated with this usage record.
     * @servertype String
     * @type {string}
     */
    Extension: string = null;

    /**
     * @name TransferredExtension
     * @description
     * Transferred extension associated with this usage record.  Depending upon the system in use, this property might be empty,
     * contain the extension number the usage was transferred to, or contain the extension number the usage was transferred from.
     * @servertype String
     * @type {string}
     */
    TransferredExtension: string = null;

    /**
     * @name AccountCode
     * @description
     * Account code.
     * @servertype String
     * @type {string}
     */
    AccountCode: string = "";

    /**
     * @name AuthorizationCode
     * @description
     * Authorization code.
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name OtherCode
     * @description
     * Other code is a data source dependent value associated with the usage record.
     * @servertype String
     * @type {string}
     */
    OtherCode: string = "";

    /**
     * @name ClassificationType
     * @description
     * Classification type contains one or more flags detailing the type of transmissions and services involved with this record.
     *  Possible flags include:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * F = Fax
     * W = Wireless
     * R = Roaming
     * T = Transferred
     * X = Conference
     * ^ = Call Forwarding
     * . = Call Waiting
     * + = Premium Service
     * - = Discount Service
     * < = Low Priority
     * > = High Priority
     * A = Assisted (operator, csr, etc.)
     * # = Voice Mail or IVR
     * N = No Charge (i.e. free)
     * : = Preempted
     * 3 = Multi-party Transmission (i.e. 3-way call)
     * = = On-Net (i.e. internal)
     * @ = Virtual Private Network
     * H = Hospitality
     * $ = Payphone
     * % = Rate Adjustment Applied
     * ? = Unknown
     * @servertype String
     * @type {string}
     */
    ClassificationType: string = "";

    /**
     * @name Phase
     * @description
     * The phase property contains indicators of the types of records that are included in this usage record.  The specific values
     * are data source dependent.
     * @servertype String
     * @type {string}
     */
    Phase: string = "";

    /**
     * @name Flags
     * @description
     * The flag property contains one or more data source dependent values.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name ServiceType
     * @description
     * Service type for this usage record:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceId
     * @description
     * Service Id uniquely identifies the service for rating purposes.  See usage service identification table for more information.
     * @servertype String
     * @type {string}
     */
    ServiceId: string = "";

    /**
     * @name NetworkId
     * @description
     * Network Id - data source dependent.
     * @servertype String
     * @type {string}
     */
    NetworkId: string = "";

    /**
     * @name SubscriberId
     * @description
     * Subscriber Id - data source dependent.
     * @servertype String
     * @type {string}
     */
    SubscriberId: string = "";

    /**
     * @name AccountingId
     * @description
     * The Accounting Id - data source dependent.
     * @servertype String
     * @type {string}
     */
    AccountingId: string = "";

    /**
     * @name GroupId
     * @description
     * The Group Id is frequently data source dependent but for conference calls (where classification type includes X) the group
     * id contains the conference id that uniquely identifies the conference and is common between all legs or participants of the
     * conference.
     * @servertype String
     * @type {string}
     */
    GroupId: string = "";

    /**
     * @name ClassOfService
     * @description
     * Class of service - data source dependent.
     * @servertype String
     * @type {string}
     */
    ClassOfService: string = "";

    /**
     * @name Quality
     * @description
     * Quality - data source dependent.
     * @servertype String
     * @type {string}
     */
    Quality: string = "";

    /**
     * @name EncodingType
     * @description
     * Encoding type - data source dependent.
     * @servertype String
     * @type {string}
     */
    EncodingType: string = "";

    /**
     * @name SourceDisconnectCode
     * @description
     * The source disconnect code is a data source dependent value specifying the disconnect status and/or resolution for this usage
     * record (i.e. success, failure, etc.).
     * @servertype String
     * @type {string}
     */
    SourceDisconnectCode: string = "";

    /**
     * @name TargetDisconnectCode
     * @description
     * The target disconnect code is a data source dependent value specifying the disconnect status and/or resolution for this usage
     * record (i.e. success, failure, etc.).
     * @servertype String
     * @type {string}
     */
    TargetDisconnectCode: string = "";

    /**
     * @name ContactType
     * @description
     * Type for contact properties that may be used for auto-populating database.  Possible values include:
     * C = Customer
     * D = Directory
     * S = Service Provider
     *  = N/A
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactFullName
     * @description
     * Contact full name.
     * @servertype String
     * @type {string}
     */
    ContactFullName: string = "";

    /**
     * @name ContactFirstName
     * @description
     * Contact first name.
     * @servertype String
     * @type {string}
     */
    ContactFirstName: string = "";

    /**
     * @name ContactLastName
     * @description
     * Contact last name.
     * @servertype String
     * @type {string}
     */
    ContactLastName: string = "";

    /**
     * @name ContactEmail
     * @description
     * Contact email address.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name TotalUnits
     * @description
     * Total number of units for the usage.
     * @servertype Double (nullable)
     * @type {number}
     */
    TotalUnits: number = 0;

    /**
     * @name ConnectDurationSeconds
     * @description
     * Connect duration represented in seconds.  This is the number of seconds required to setup the connection (while the phone
     * rang, etc.).
     * @servertype Double (nullable)
     * @type {number}
     */
    ConnectDurationSeconds: number = 0;

    /**
     * @name HoldDurationSeconds
     * @description
     * Hold and/or queue duration represented in seconds.  Represents the amount of time the usage was on hold or waiting in queue.
     * @servertype Double (nullable)
     * @type {number}
     */
    HoldDurationSeconds: number = 0;

    /**
     * @name UsageDurationSeconds
     * @description
     * The usage duration represented in seconds.  Note that as part of processing some duration values may be rounded by the rating
     * engine depending upon rating profile configuration.  This property reports the original (unrounded) usage duration.
     * @servertype Double (nullable)
     * @type {number}
     */
    UsageDurationSeconds: number = 0;

    /**
     * @name Completed
     * @description
     * When true the usage is considered completed.  For voice traffic, accurate reporting for this value may depend on answer supervision
     * control in the switch.
     * @servertype Boolean
     * @type {boolean}
     */
    Completed: boolean = false;

    /**
     * @name Direction
     * @description
     * I = Incoming
     * O = Outgoing
     * E = Extension/Internal (i.e. neither incoming or outgoing)
     * B = Both (i.e. both incoming and outgoing like DISA)
     * @servertype String
     * @type {string}
     */
    Direction: string = "B";

    /**
     * @name SourceNetworkElementId
     * @description
     * Network Element Id of the source network element.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceNetworkElementId: number = null;

    /**
     * @name SourceExternalNetworkElementId
     * @description
     * External Network Element Id of the source network element.
     * @servertype String
     * @type {string}
     */
    SourceExternalNetworkElementId: string = "";

    /**
     * @name SourceDomainName
     * @description
     * Domain name of the source network element.
     * @servertype String
     * @type {string}
     */
    SourceDomainName: string = "";

    /**
     * @name SourceEquipmentId
     * @description
     * Equipment Id of the source network element.
     * @servertype String
     * @type {string}
     */
    SourceEquipmentId: string = "";

    /**
     * @name SourceIpAddress
     * @description
     * IP address of the source network element.
     * @servertype String
     * @type {string}
     */
    SourceIpAddress: string = "";

    /**
     * @name SourceGroup
     * @description
     * Group for source network element.
     * @servertype String
     * @type {string}
     */
    SourceGroup: string = "";

    /**
     * @name SourceInPortGroupId
     * @description
     * Port Group Id of the source inbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceInPortGroupId: number = null;

    /**
     * @name SourceInExternalPortGroupId
     * @description
     * External port group id of the source inbound port group.
     * @servertype String
     * @type {string}
     */
    SourceInExternalPortGroupId: string = "";

    /**
     * @name SourceInPortNumberId
     * @description
     * Port Number Id of the source inbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceInPortNumberId: number = null;

    /**
     * @name SourceInExternalPortNumberId
     * @description
     * External port Number id of the source inbound port number.
     * @servertype String
     * @type {string}
     */
    SourceInExternalPortNumberId: string = "";

    /**
     * @name SourceOutPortGroupId
     * @description
     * Port Group Id of the source outbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceOutPortGroupId: number = null;

    /**
     * @name SourceOutExternalPortGroupId
     * @description
     * External port group id of the source outbound port group.
     * @servertype String
     * @type {string}
     */
    SourceOutExternalPortGroupId: string = "";

    /**
     * @name SourceOutPortNumberId
     * @description
     * Port Number Id of the source outbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceOutPortNumberId: number = null;

    /**
     * @name SourceOutExternalPortNumberId
     * @description
     * External port Number id of the source outbound port number.
     * @servertype String
     * @type {string}
     */
    SourceOutExternalPortNumberId: string = "";

    /**
     * @name SourceInformation
     * @description
     * This property is data source dependent source information.
     * @servertype String
     * @type {string}
     */
    SourceInformation: string = "";

    /**
     * @name SourceType
     * @description
     * Nature of the source end of the usage.  Possible values are:
     * VP = Type of usage on source end is Voice PSTN.
     * VI = Type of usage on source end is Voice IP network.
     * VF = Type of usage on source end is Voice Frame Relay network.
     * VS = Type of usage on source end is Voice SIP message.
     * VW = Type of usage on source end is Voice Wireless.
     * ?? = Type of usage on source end is not available or unknown.
     * @servertype String
     * @type {string}
     */
    SourceType: string = "??";

    /**
     * @name TargetNetworkElementId
     * @description
     * Network Element Id of the target network element.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetNetworkElementId: number = null;

    /**
     * @name TargetExternalNetworkElementId
     * @description
     * External Network Element Id of the target network element.
     * @servertype String
     * @type {string}
     */
    TargetExternalNetworkElementId: string = "";

    /**
     * @name TargetDomainName
     * @description
     * Domain name of the target network element.
     * @servertype String
     * @type {string}
     */
    TargetDomainName: string = "";

    /**
     * @name TargetEquipmentId
     * @description
     * Equipment Id of the target network element.
     * @servertype String
     * @type {string}
     */
    TargetEquipmentId: string = "";

    /**
     * @name TargetIpAddress
     * @description
     * IP address of the target network element.
     * @servertype String
     * @type {string}
     */
    TargetIpAddress: string = "";

    /**
     * @name TargetGroup
     * @description
     * Group for target network element.
     * @servertype String
     * @type {string}
     */
    TargetGroup: string = "";

    /**
     * @name TargetInPortGroupId
     * @description
     * Port Group Id of the target inbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetInPortGroupId: number = null;

    /**
     * @name TargetInExternalPortGroupId
     * @description
     * External port group id of the target inbound port group.
     * @servertype String
     * @type {string}
     */
    TargetInExternalPortGroupId: string = "";

    /**
     * @name TargetInPortNumberId
     * @description
     * Port Number Id of the target inbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetInPortNumberId: number = null;

    /**
     * @name TargetInExternalPortNumberId
     * @description
     * External port Number id of the target inbound port number.
     * @servertype String
     * @type {string}
     */
    TargetInExternalPortNumberId: string = "";

    /**
     * @name TargetOutPortGroupId
     * @description
     * Port Group Id of the target outbound port group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetOutPortGroupId: number = null;

    /**
     * @name TargetOutExternalPortGroupId
     * @description
     * External port group id of the target outbound port group.
     * @servertype String
     * @type {string}
     */
    TargetOutExternalPortGroupId: string = "";

    /**
     * @name TargetOutPortNumberId
     * @description
     * Port Number Id of the target outbound port number.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetOutPortNumberId: number = null;

    /**
     * @name TargetOutExternalPortNumberId
     * @description
     * External port Number id of the target outbound port number.
     * @servertype String
     * @type {string}
     */
    TargetOutExternalPortNumberId: string = "";

    /**
     * @name TargetInformation
     * @description
     * This property is data source dependent target information.
     * @servertype String
     * @type {string}
     */
    TargetInformation: string = "";

    /**
     * @name TargetType
     * @description
     * Nature of the target end of the usage.  Possible values are:
     * VP = Type of usage on source end is Voice PSTN.
     * VI = Type of usage on source end is Voice IP network.
     * VF = Type of usage on source end is Voice Frame Relay network.
     * VS = Type of usage on source end is Voice SIP message.
     * VW = Type of usage on source end is Voice Wireless.
     * ?? = Type of usage on source end is not available or unknown.
     * @servertype String
     * @type {string}
     */
    TargetType: string = "??";

    /**
     * @name DisconnectNetworkElementId
     * @description
     * Network Element Id of the disconnect network element.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DisconnectNetworkElementId: number = null;

    /**
     * @name DisconnectExternalNetworkElementId
     * @description
     * External Network Element Id of the disconnect network element.
     * @servertype String
     * @type {string}
     */
    DisconnectExternalNetworkElementId: string = "";

    /**
     * @name DisconnectDomainName
     * @description
     * Domain name of the disconnect network element.
     * @servertype String
     * @type {string}
     */
    DisconnectDomainName: string = "";

    /**
     * @name DisconnectEquipmentId
     * @description
     * Equipment Id of the disconnect network element.
     * @servertype String
     * @type {string}
     */
    DisconnectEquipmentId: string = "";

    /**
     * @name DisconnectIpAddress
     * @description
     * IP address of the disconnect network element.
     * @servertype String
     * @type {string}
     */
    DisconnectIpAddress: string = "";

    /**
     * @name PacketsSentSource
     * @description
     * Packets sent from source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsSentSource: number = 0;

    /**
     * @name PacketsReceivedSource
     * @description
     * Packets received to source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsReceivedSource: number = 0;

    /**
     * @name PacketsSentTarget
     * @description
     * Packets sent from target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsSentTarget: number = 0;

    /**
     * @name PacketsReceivedTarget
     * @description
     * Packets received to target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsReceivedTarget: number = 0;

    /**
     * @name PacketsLostSource
     * @description
     * Packets lost on source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsLostSource: number = 0;

    /**
     * @name PacketsLostTarget
     * @description
     * Packets lost on target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsLostTarget: number = 0;

    /**
     * @name JitterSource
     * @description
     * Jitter on source node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    JitterSource: number = 0;

    /**
     * @name JitterTarget
     * @description
     * Jitter on target node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    JitterTarget: number = 0;

    /**
     * @name LatencySource
     * @description
     * Latency on Source Node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LatencySource: number = 0;

    /**
     * @name LatencyTarget
     * @description
     * Latency on Target Node.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LatencyTarget: number = 0;

    /**
     * @name OctetsSentSource
     * @description
     * Octets sent from source node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsSentSource: number = 0;

    /**
     * @name OctetsReceivedSource
     * @description
     * Octets received to source node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsReceivedSource: number = 0;

    /**
     * @name OctetsSentTarget
     * @description
     * Octets sent from target node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsSentTarget: number = 0;

    /**
     * @name OctetsReceivedTarget
     * @description
     * Octets received to target node.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsReceivedTarget: number = 0;

    /**
     * @name Stat01SentSource
     * @description
     * Data source dependent statistic sent from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01SentSource: number = 0;

    /**
     * @name Stat01ReceivedSource
     * @description
     * Data source dependent statistic received from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01ReceivedSource: number = 0;

    /**
     * @name Stat01SentTarget
     * @description
     * Data source dependent statistic sent from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01SentTarget: number = 0;

    /**
     * @name Stat01ReceivedTarget
     * @description
     * Data source dependent statistic received from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01ReceivedTarget: number = 0;

    /**
     * @name Stat02SentSource
     * @description
     * Data source dependent statistic sent from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02SentSource: number = 0;

    /**
     * @name Stat02ReceivedSource
     * @description
     * Data source dependent statistic received from source node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02ReceivedSource: number = 0;

    /**
     * @name Stat02SentTarget
     * @description
     * Data source dependent statistic sent from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02SentTarget: number = 0;

    /**
     * @name Stat02ReceivedTarget
     * @description
     * Data source dependent statistic received from target node.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02ReceivedTarget: number = 0;

    /**
     * @name Stat03
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat03: number = 0;

    /**
     * @name Stat04
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat04: number = 0;

    /**
     * @name Stat05
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat05: number = 0;

    /**
     * @name Stat06
     * @description
     * Data source dependent statistic.
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat06: number = 0;

    /**
     * @name Stat07
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat07: number = 0;

    /**
     * @name Stat08
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat08: number = 0;

    /**
     * @name Stat09
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat09: number = 0;

    /**
     * @name Stat10
     * @description
     * Data source dependent statistic.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat10: number = 0;

    /**
     * @name BandwidthSpeed
     * @description
     * Bandwidth speed expressed in bits per second.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BandwidthSpeed: number = 0;

    /**
     * @name BandwidthConsumed
     * @description
     * Bandwidth consumed expressed in bytes.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BandwidthConsumed: number = 0;

    /**
     * @name ContentPageCount
     * @description
     * Number of pages included in the content (e.g. pages in fax, document, etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ContentPageCount: number = 0;

    /**
     * @name Value001
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Value011
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value011: string = "";

    /**
     * @name Value012
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value012: string = "";

    /**
     * @name Value013
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value013: string = "";

    /**
     * @name Value014
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value014: string = "";

    /**
     * @name Value015
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value015: string = "";

    /**
     * @name Value016
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value016: string = "";

    /**
     * @name Value017
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value017: string = "";

    /**
     * @name Value018
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value018: string = "";

    /**
     * @name Value019
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value019: string = "";

    /**
     * @name Value020
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value020: string = "";

    /**
     * @name Value021
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value021: string = "";

    /**
     * @name Value022
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value022: string = "";

    /**
     * @name Value023
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value023: string = "";

    /**
     * @name Value024
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value024: string = "";

    /**
     * @name Value025
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value025: string = "";

    /**
     * @name Value026
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value026: string = "";

    /**
     * @name Value027
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value027: string = "";

    /**
     * @name Value028
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value028: string = "";

    /**
     * @name Value029
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value029: string = "";

    /**
     * @name Value030
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value030: string = "";

    /**
     * @name Value031
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value031: string = "";

    /**
     * @name Value032
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value032: string = "";

    /**
     * @name Value033
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value033: string = "";

    /**
     * @name Value034
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value034: string = "";

    /**
     * @name Value035
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value035: string = "";

    /**
     * @name Value036
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value036: string = "";

    /**
     * @name Value037
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value037: string = "";

    /**
     * @name Value038
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value038: string = "";

    /**
     * @name Value039
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value039: string = "";

    /**
     * @name Value040
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value040: string = "";

    /**
     * @name Value041
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value041: string = "";

    /**
     * @name Value042
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value042: string = "";

    /**
     * @name Value043
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value043: string = "";

    /**
     * @name Value044
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value044: string = "";

    /**
     * @name Value045
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value045: string = "";

    /**
     * @name Value046
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value046: string = "";

    /**
     * @name Value047
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value047: string = "";

    /**
     * @name Value048
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value048: string = "";

    /**
     * @name Value049
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value049: string = "";

    /**
     * @name Value050
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value050: string = "";

    /**
     * @name Value051
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value051: string = "";

    /**
     * @name Value052
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value052: string = "";

    /**
     * @name Value053
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value053: string = "";

    /**
     * @name Value054
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value054: string = "";

    /**
     * @name Value055
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value055: string = "";

    /**
     * @name Value056
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value056: string = "";

    /**
     * @name Value057
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value057: string = "";

    /**
     * @name Value058
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value058: string = "";

    /**
     * @name Value059
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value059: string = "";

    /**
     * @name Value060
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value060: string = "";

    /**
     * @name Value061
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value061: string = "";

    /**
     * @name Value062
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value062: string = "";

    /**
     * @name Value063
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value063: string = "";

    /**
     * @name Value064
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value064: string = "";

    /**
     * @name Value065
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value065: string = "";

    /**
     * @name Value066
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value066: string = "";

    /**
     * @name Value067
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value067: string = "";

    /**
     * @name Value068
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value068: string = "";

    /**
     * @name Value069
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value069: string = "";

    /**
     * @name Value070
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value070: string = "";

    /**
     * @name Value071
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value071: string = "";

    /**
     * @name Value072
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value072: string = "";

    /**
     * @name Value073
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value073: string = "";

    /**
     * @name Value074
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value074: string = "";

    /**
     * @name Value075
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value075: string = "";

    /**
     * @name Value076
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value076: string = "";

    /**
     * @name Value077
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value077: string = "";

    /**
     * @name Value078
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value078: string = "";

    /**
     * @name Value079
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value079: string = "";

    /**
     * @name Value080
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value080: string = "";

    /**
     * @name Value081
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value081: string = "";

    /**
     * @name Value082
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value082: string = "";

    /**
     * @name Value083
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value083: string = "";

    /**
     * @name Value084
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value084: string = "";

    /**
     * @name Value085
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value085: string = "";

    /**
     * @name Value086
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value086: string = "";

    /**
     * @name Value087
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value087: string = "";

    /**
     * @name Value088
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value088: string = "";

    /**
     * @name Value089
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value089: string = "";

    /**
     * @name Value090
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value090: string = "";

    /**
     * @name Value091
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value091: string = "";

    /**
     * @name Value092
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value092: string = "";

    /**
     * @name Value093
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value093: string = "";

    /**
     * @name Value094
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value094: string = "";

    /**
     * @name Value095
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value095: string = "";

    /**
     * @name Value096
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value096: string = "";

    /**
     * @name Value097
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value097: string = "";

    /**
     * @name Value098
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value098: string = "";

    /**
     * @name Value099
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value099: string = "";

    /**
     * @name Value100
     * @description
     * A dynamic value for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Value100: string = "";

    /**
     * @name RawData
     * @description
     * This property is typically not used but when stored, the property contains a copy of the raw data that was processed to generate
     * this record.
     * @servertype String
     * @type {string}
     */
    RawData: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name UsageDataSourceEditViewModel
 * @description
 * The usage data source table contains information about data sources for usage data including information about how that usage
 * data is processed.
 */
export class UsageDataSourceEditViewModel {

    /**
     * @name UsageDataSourceId
     * @description
     * Usage Data Source Id uniquely identifies this usage data source.
     * @servertype Int64
     * @type {number}
     */
    UsageDataSourceId: number = null;

    /**
     * @name ExternalUsageDataSourceId
     * @description
     * An optional external usage data source id.
     * @servertype String
     * @type {string}
     */
    ExternalUsageDataSourceId: string = "";

    /**
     * @name Description
     * @description
     * Description for this usage data source.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DataCaptureMethod
     * @description
     * The capture method used to retrieve usage detail records.  Possible values are:
     * Push = Usage data is pushed directly into database.
     * File = Usage data is accessed from a log file.
     * Other = Usage data is captured in some other method.
     * @servertype String
     * @type {string}
     */
    DataCaptureMethod: string = "";

    /**
     * @name DataCaptureInformation
     * @description
     * Information related to data capture in JSON format based on the data capture method.  For example:
     * {
     * 
     * }
     * @servertype String
     * @type {string}
     */
    DataCaptureInformation: string = "";

    /**
     * @name UsageLogFile
     * @description
     * This is the usage data log file(s) that the import engine is to process.  It can specify an exact file or a file spec (i.e.
     * T:\Data\*.*).
     * @servertype String
     * @type {string}
     */
    UsageLogFile: string = "";

    /**
     * @name ExternalUsageDataLayoutId
     * @description
     * The id of usage data layout format to use for processing usage data.
     * @servertype String
     * @type {string}
     */
    ExternalUsageDataLayoutId: string = null;

    /**
     * @name UsageStorageType
     * @description
     * The usage storage type to use:
     * Standard = Standard Usage Table
     * Extended = Extended Usage Table
     * Custom = Custom Storage (The import processor will be responsible for handling storage of the usage data.)
     * Legacy = Legacy Usage Table
     * @servertype String
     * @type {string}
     */
    UsageStorageType: string = "Standard";

    /**
     * @name TimeZoneId
     * @description
     * Time Zone Id specifying the time zone usage data date time values are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name ServiceType
     * @description
     * Service type of usage data from this data source:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name OutgoingConnectSeconds
     * @description
     * In scenarios where usage includes a duration and the duration includes time taken to establish a connection this setting
     * can be used to reduce the reported duration of outgoing connections by the specified number of seconds.  This obviously will
     * not be accurate on a per usage record basis but can be used to provide an average adjustment.
     * For example, some usage for phone systems may report duration as the total time from when the phone was picked up until the
     * phone was hung up, while carriers generally bill based on the time from when the phone call was answered until the call was
     * completed.  This connect seconds property is used to reduce each outgoing phone call to adjust for this difference.  While
     * it will not produce an exact match to the duration billed by the carrier (some calls take 5 seconds to answer, some calls
     * may ring for 30 seconds or more before they are answered), this will provide an average number of seconds that each call
     * can be reduced to help bridge the difference.  If exact call duration and costing are essential, contact the system vendor
     * to check on the availability of answer supervision equipment.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    OutgoingConnectSeconds: number = 0;

    /**
     * @name DurationAdjustmentFactor
     * @description
     * In situations where hardware problems result in an inaccurate duration being reported, this property can be used to adjust
     * the reported duration up or down by a given percentage to make the duration more accurate.  Values are represented in decimal
     * format.  For example, a 5% increase in duration would be represented by 0.05 and a 10% decrease in duration would be represented
     * by -0.10.
     * @servertype Double (nullable)
     * @type {number}
     */
    DurationAdjustmentFactor: number = 0;

    /**
     * @name ImportMinimumSeconds
     * @description
     * Some systems report usage every time a connection is attempted even if not successful.  For example, every time the phone
     * is picked up even if no call is placed or the target number is busy.  All usage records with a duration that is lower then
     * the value entered in this import minimum seconds property will be omitted.  Note that setting this value too high may result
     * in some short length usage records not being imported.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ImportMinimumSeconds: number = 0;

    /**
     * @name MinimumSeconds
     * @description
     * The number of seconds specified in this property will be used for the minimum duration for all usage records imported.  For
     * example, if this value was set to 10 seconds and a record was imported where the duration was 5 seconds, the duration would
     * be changed from 5 seconds to 10 seconds.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumSeconds: number = 0;

    /**
     * @name ForceCompletedSeconds
     * @description
     * If this property is set to a non-zero value then all usage records imported with a duration longer than the value in this
     * property will have the completed flag forced to true.  This is useful in situations where answer supervision is not available
     * or where usage over a certain duration is always considered billable.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ForceCompletedSeconds: number = 0;

    /**
     * @name InferUsageType
     * @description
     * When true and the usage type cannot be determined the import engine will, when possible, try to infer the usage type using
     * available information.  For example, for voice usage if an NPA+NXX pair cannot be found and the infer usage type setting
     * is true then the system will try to determine the usage type based only on the NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    InferUsageType: boolean = true;

    /**
     * @name CheckDuplicates
     * @description
     * When true check for duplicate usage data records when importing.  If a duplicate is found the record will be ignored as a
     * duplicate.  Note that this feature will slow down the import process and, therefore, it should only be used when necessary.
     * @servertype Boolean
     * @type {boolean}
     */
    CheckDuplicates: boolean = false;

    /**
     * @name SupportDirectionInternal
     * @description
     * When true internal direction usage is supported in the usage data.  For example, extension-to-extension calls are internal
     * calls and flagged as internal when identifiable.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportDirectionInternal: boolean = false;

    /**
     * @name ExtensionLength
     * @description
     * Length of extension numbers used when extension-to-extension calling is reported so usage can be identified as internal.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ExtensionLength: number = 0;

    /**
     * @name BaseExtension
     * @description
     * Base extension number that is added to every extension number.  For example, if this value is 3000 and a record is imported
     * with extension 115, the resulting extension number will be 3115.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BaseExtension: number = 0;

    /**
     * @name MaximumTargetIdentifierLength
     * @description
     * Maximum number target identifier length.  For voice traffic this can be used to specify the maximum number of digits to allow
     * to block undesired digits from IVR systems like credit card numbers, etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumTargetIdentifierLength: number = 0;

    /**
     * @name AssociateInProgressUsage
     * @description
     * When true perform association of in progress usage.  Association is normally performed as part of the rating process which
     * does not happen until the usage record has been completed.  For systems that report data at the start of usage if association
     * is desired while the usage is in progress set this flag to true.
     * @servertype Boolean
     * @type {boolean}
     */
    AssociateInProgressUsage: boolean = false;

    /**
     * @name AssociationLookupOptions
     * @description
     * Association lookup option flags.  The rating engine can perform a number of look ups in an attempt to locate associations
     * with packages, directory entries, etc.  Since each look up has a cost in terms of database access and CPU time, setting flags
     * for only the required look ups will keep performance tuned.  The lookups are performed in the order specified in this column.
     *  The options are comma delimited and can include:
     * EXT = Extension
     * TRE = Transferred Extension
     * ACC = Account Code
     * AUT = Authorization Code
     * NET = Network Id
     * SUB = Subscriber Id
     * SID = Source Identifier
     * TID = Target Identifier
     * OTI = Original Target Identifier
     * OI1 = Other Identifier 1
     * OI2 = Other Identifier 2
     * SNE = Source Network Element
     * SGI = Source Port Group In
     * SPI = Source Port Number In
     * SGO = Source Port Group Out
     * SPO = Source Port Number Out
     * TNE = Target Network Element
     * TGI = Target Port Group In
     * TPI = Target Port Number In
     * TGO = Target Port Group Out
     * TPO = Target Port Number Out
     * @servertype String
     * @type {string}
     */
    AssociationLookupOptions: string = "EXT,ACC,AUT,SID";

    /**
     * @name AutoAddServiceIdentification
     * @description
     * When true and unable to find a service identification the system will add one to the usage service identification table.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoAddServiceIdentification: boolean = false;

    /**
     * @name AutoAddTelecomLocation
     * @description
     * When true and unable to find a location the system will add it to the telecom location table.  Note that this feature will
     * only work for numbering plans with fixed length NPA and NXX digits.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoAddTelecomLocation: boolean = false;

    /**
     * @name AutoAddNetworkElement
     * @description
     * Defines how network elements are possibly auto added for this usage data.  Possible values are:
     * D = Domain Name (New domain names result in new network elements being added)
     * E = Equipment Id (New equipment ids result in new network elements being added)
     * I = IP Address (New ip addresses result in new network elements being added - strongly discouraged for dynamic ip addresses)
     * B = Both Domain Name and Equipment Id (New domain names or equipment ids result in new network elements being added)
     * N = No Action (New network elements are not automatically added)
     * @servertype String
     * @type {string}
     */
    AutoAddNetworkElement: string = "N";

    /**
     * @name AutoAddPortGroupAndPort
     * @description
     * When true and the system encounters a new port group or port it is automatically added to the port group or port table.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoAddPortGroupAndPort: boolean = true;

    /**
     * @name AutoAddAccountCode
     * @description
     * Defines how account codes are possibly auto added for this usage data.  Possible values are:
     * C = Auto Add Customer (Account codes are associated with customers and new account codes are automatically added and associated
     * with a new customer)
     * D = Auto Add Directory (Account codes are associated with directory entries and new account codes are automatically added
     * and associated with a new directory record)
     * S = Auto Add Service Provider (Account codes are associated with service providers and new account codes are automatically
     * added and associated with a new service provider record)
     * A = Auto Add Account Codes (New account codes are automatically added but are not associated with anyone and can later be
     * associated manually)
     * N = No Action (New account codes are not automatically added)
     * @servertype String
     * @type {string}
     */
    AutoAddAccountCode: string = "N";

    /**
     * @name AutoAddAuthorizationCode
     * @description
     * Defines how authorization codes are possibly auto added for this usage data.  Possible values are:
     * C = Auto Add Customer (Authorization codes are associated with customers and new authorization codes are automatically added
     * and associated with a new customer)
     * D = Auto Add Directory (Authorization codes are associated with directory entries and new authorization codes are automatically
     * added and associated with a new directory record)
     * S = Auto Add Service Provider (Authorization codes are associated with service providers and new authorization codes are
     * automatically added and associated with a new service provider record)
     * A = Auto Add Authorization Codes (New authorization codes are automatically added but are not associated with anyone and
     * can later be associated manually)
     * N = No Action (New authorization codes are not automatically added)
     * @servertype String
     * @type {string}
     */
    AutoAddAuthorizationCode: string = "N";

    /**
     * @name AutoAddExtension
     * @description
     * Defines how extensions are possibly auto added for this usage data.  Possible values are:
     * C = Auto Add Customer (Extensions are associated with customers and new extensions are automatically added and associated
     * with a new customer)
     * D = Auto Add Directory (Extensions are associated with directory entries and new extensions are automatically added and associated
     * with a new directory record)
     * S = Auto Add Service Provider (Extensions are associated with service providers and new extensions are automatically added
     * and associated with a new service provider record)
     * A = Auto Add Extensions (New extensions are automatically added but are not associated with anyone and can later be associated
     * manually)
     * N = No Action (New extensions are not automatically added)
     * @servertype String
     * @type {string}
     */
    AutoAddExtension: string = "N";

    /**
     * @name AutoAddSourceIdentifier
     * @description
     * Defines how source identifiers are possibly auto added for this usage data.  Possible values are:
     * C = Auto Add Customer (Source identifiers are associated with customers and new source identifiers are automatically added
     * and associated with a new customer)
     * D = Auto Add Directory (Source identifiers are associated with directory entries and new source identifiers are automatically
     * added and associated with a new directory record)
     * S = Auto Add Service Provider (Source identifiers are associated with service providers and new source identifiers are automatically
     * added and associated with a new service provider record)
     * A = Auto Add Source Identifiers (New source identifiers are automatically added but are not associated with anyone and can
     * later be associated manually)
     * N = No Action (New source identifiers are not automatically added)
     * @servertype String
     * @type {string}
     */
    AutoAddSourceIdentifier: string = "N";

    /**
     * @name FormattingOptions
     * @description
     * Setting that can contain formatting options for the usage data.  Possible values include:
     * {
     * 
     * }
     * @servertype String
     * @type {string}
     */
    FormattingOptions: string = "";

    /**
     * @name ImportEngineOptions
     * @description
     * List of strings that can contain options for the import engine.  Possible values include:
     * Usage-End-Date-Time = Usage Date Time Represents Session End
     * Usage-Data-Source-Is-Source-Network-Element = Usage Data Source Is The Source Network Element
     * Usage-Data-Source-Is-Target-Network-Element = Usage Data Source Is The Target Network Element
     * Serial-Number-Normalize-4s8 = Normalize Serial Number as 4 Space Delimited Sections of 8 Characters Each
     * Serial-Number-Normalize-RemoveSpaceLowerCase = Normalize Serial Number as Lower Case with No Spaces
     * VoiceDisableNanpTenDigitDialing = Voice Disable NANP 10 Digit Dialing
     * @servertype string array
     * @type {string[]}
     */
    ImportEngineOptions: string[] = [];

    /**
     * @name MediationOptions
     * @description
     * List of strings that dictate how mediation should be performed.  Possible values include:
     * CiscoRadius = Cisco Radius Data
     * Nortel-SXLE = Nortel SXLE
     * Broadworks = Broadworks
     * Phase = Phase
     * Phase-Counter = Phase Counter
     * Phase-Unique = Phase Unique
     * SipQuest = SipQuest
     * Transferred-Extension = Transfer Extension
     * Sum-Duration = Sum Durations
     * Calculate-Duration = Calculate Durations
     * @servertype string array
     * @type {string[]}
     */
    MediationOptions: string[] = [];

    /**
     * @name UsageDataBackupRetentionDays
     * @description
     * The number of days to use for the retention period for usage data backups.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    UsageDataBackupRetentionDays: number = 365;

    /**
     * @name LogRawUsageDataSampleSize
     * @description
     * A sample size to use for storing raw usage data in the log files with a link to the imported usage record.  This makes it
     * easier to debug usage import issues but slows down the import process and can significantly increase database size and, therefore,
     * should be used sparingly.  A value of 0 will result in no raw data being logged.  A value of X will result in every X usage
     * record having raw data logged.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LogRawUsageDataSampleSize: number = 0;

    /**
     * @name TraceImportProcessSampleSize
     * @description
     * A sample size to use for storing import process trace in the log files with a link to the imported usage record.  This makes
     * it easier to debug usage import issues but slows down the import process and can significantly increase database size and,
     * therefore, should be used sparingly.  A value of 0 will result in no import process trace being logged.  A value of X will
     * result in every X usage record having trace import data logged.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TraceImportProcessSampleSize: number = 0;

    /**
     * @name TraceRatingProcessSampleSize
     * @description
     * A sample size to use for storing rating process trace in the log files with a link to the rated usage record.  This makes
     * it easier to debug usage rating issues but slows down the rating process and can significantly increase database size and,
     * therefore, should be used sparingly.  A value of 0 will result in no rating process trace being logged.  A value of X will
     * result in every X usage record having trace rating data logged.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TraceRatingProcessSampleSize: number = 0;

    /**
     * @name UsageDataBatchSize
     * @description
     * The size of batches for usage data batching when batching is possible.  Depending on usage data source type and configuration
     * batch processing is not always possible.  When it is possible, batch processing is more efficient but has the side effect
     * of losing some per record processing trace information.  Take this setting into account when considering the trace import
     * process sample size setting.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    UsageDataBatchSize: number = 20;

    /**
     * @name IsoCurrencyCode
     * @description
     * ISO Currency Code for this cost center.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name TelecomLocationProfileId
     * @description
     * An optional Telecom Location Profile Id that identifies the location profile to use.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationProfileId: number = null;

    /**
     * @name TelecomHomeCountryCode
     * @description
     * An optional Telecom Home Country Code.
     * @servertype String
     * @type {string}
     */
    TelecomHomeCountryCode: string = "";

    /**
     * @name TelecomHomeNpa
     * @description
     * An optional Telecom Home NPA (Area Code).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNpa: string = "";

    /**
     * @name TelecomHomeNxx
     * @description
     * An optional Telecom Home NXX (Exchange).
     * @servertype String
     * @type {string}
     */
    TelecomHomeNxx: string = "";

    /**
     * @name TelecomHomeLata
     * @description
     * An optional Telecom Home LATA for this cost center.  Used to help determine if a call is intraLATA type.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TelecomHomeLata: number = 0;

    /**
     * @name TelecomHomeState
     * @description
     * An optional Telecom Home state for this cost center.  Used to help determine if a call is intrastate type.
     * @servertype String
     * @type {string}
     */
    TelecomHomeState: string = "";

    /**
     * @name TelecomNumberingPlanId
     * @description
     * The id of the numbering plan to use for formatting and recognizing phone numbers.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomNumberingPlanId: number = null;

    /**
     * @name NumberFormat
     * @description
     * Format for telecom phone numbers.  Possible values are:
     * Native = Native format for specified numbering plan.  This is the default.
     * E164FromAniLocation = E.164 format using ANI as preferred method for source country identification.
     * E164FromNetworkElementLocation = E.164 format using source network element as preferred method for source country identification.
     * Note that when using E.164 format identification of the source country is important to determine if the target phone number
     * is domestic or international.
     * @servertype String
     * @type {string}
     */
    NumberFormat: string = "Native";

    /**
     * @name AccessCodesLongDistance
     * @description
     * Comma delimited list of long distance access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesLongDistance: string = "";

    /**
     * @name AccessCodesInternational
     * @description
     * Comma delimited list of international access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesInternational: string = "";

    /**
     * @name AccessCodesFree
     * @description
     * Comma delimited list of free access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesFree: string = "";

    /**
     * @name AccessCodesOperator
     * @description
     * Comma delimited list of operator access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesOperator: string = "";

    /**
     * @name AccessCodesInternationalOperator
     * @description
     * Comma delimited list of international operator access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesInternationalOperator: string = "";

    /**
     * @name AccessCodesLocalInformation
     * @description
     * Comma delimited list of local information access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesLocalInformation: string = "";

    /**
     * @name AccessCodesLongDistanceInformation
     * @description
     * Comma delimited list of long distance information access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesLongDistanceInformation: string = "";

    /**
     * @name AccessCodesEmergency
     * @description
     * Comma delimited list of emergency access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesEmergency: string = "";

    /**
     * @name AccessCodesException
     * @description
     * Comma delimited list of exception access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesException: string = "";

    /**
     * @name DropDigits
     * @description
     * Comma delimited list of digits to drop from phone number.  This could be automatic route selection (ARS) digits or other
     * digits to drop.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    DropDigits: string = "";

    /**
     * @name FlagNoDirectoryEntry
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoDirectoryEntry: string = "I";

    /**
     * @name FlagNoCustomer1
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoCustomer1: string = "I";

    /**
     * @name FlagNoCustomer2
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoCustomer2: string = "I";

    /**
     * @name FlagCustomer12Mismatch
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagCustomer12Mismatch: string = "E";

    /**
     * @name FlagNoAgent1
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoAgent1: string = "I";

    /**
     * @name FlagNoAgent2
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoAgent2: string = "I";

    /**
     * @name FlagNoServiceProvider1
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoServiceProvider1: string = "I";

    /**
     * @name FlagNoServiceProvider2
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoServiceProvider2: string = "I";

    /**
     * @name FlagNoServiceProvider3
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoServiceProvider3: string = "I";

    /**
     * @name FlagNoR1RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoR1RatingProfile: string = "W";

    /**
     * @name FlagNoR2RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoR2RatingProfile: string = "I";

    /**
     * @name FlagNoA1RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoA1RatingProfile: string = "I";

    /**
     * @name FlagNoA2RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoA2RatingProfile: string = "I";

    /**
     * @name FlagNoW1RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoW1RatingProfile: string = "I";

    /**
     * @name FlagNoW2RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoW2RatingProfile: string = "I";

    /**
     * @name FlagNoW3RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoW3RatingProfile: string = "I";

    /**
     * @name FlagNoT1RatingProfile
     * @description
     * Flag that indicates how this situation should be handled.  Possible values include:
     * I = Ignore
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    FlagNoT1RatingProfile: string = "I";

    /**
     * @name LastImportDateTime
     * @description
     * This is the date and time stamp from the last imported usage record.  Some usage formats don't report date with each usage
     * record but on a separate and sometimes infrequent date header line.  In these instances we need to know the date of the last
     * usage record in case the next processed file doesn't start with a date header line.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastImportDateTime: Date = null;

    /**
     * @name Flags
     * @description
     * A list of flags for this usage data source.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this usage data source.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name UsageEditViewModel
 * @description
 * The Usage table contains information about various types of usage.  Usage is stored by calendar month using this table as
 * a template where YYYY is the year and MM is the month.
 */
export class UsageEditViewModel {

    /**
     * @name UsageId
     * @description
     * Usage Id uniquely identifies this usage record.
     * @servertype String
     * @type {string}
     */
    UsageId: string = null;

    /**
     * @name UsageDateTime
     * @description
     * The date and time for the usage record.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageDateTime: Date = null;

    /**
     * @name SerialNumber
     * @description
     * Usage serial number.  Some usage generate multiple accounting, usage, or statistics records that are then mediated or consolidated
     * into a single usage record using this serial number.
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name SourceIdentifier
     * @description
     * Source phone number (ANI), ip address, url, or other identifier associated with the source for this usage.
     * @servertype String
     * @type {string}
     */
    SourceIdentifier: string = "";

    /**
     * @name SourceLocation
     * @description
     * Location information associated with the source identifier.  For voice this is often the country code, npa, nxx, etc.
     * @servertype String
     * @type {string}
     */
    SourceLocation: string = "";

    /**
     * @name TargetIdentifier
     * @description
     * Target phone number, ip address, url, or other target for this usage.
     * @servertype String
     * @type {string}
     */
    TargetIdentifier: string = "";

    /**
     * @name TargetLocation
     * @description
     * Location information associated with the target identifier.  For voice this is often the country code, npa, nxx, etc.
     * @servertype String
     * @type {string}
     */
    TargetLocation: string = "";

    /**
     * @name TargetIdentifierDescription
     * @description
     * Description of the target identifier.  For example, voice records may use this for the city, state, country, etc.
     * @servertype String
     * @type {string}
     */
    TargetIdentifierDescription: string = "";

    /**
     * @name Extension
     * @description
     * Extension associated with this usage record.
     * @servertype String
     * @type {string}
     */
    Extension: string = null;

    /**
     * @name AccountCode
     * @description
     * Account code.
     * @servertype String
     * @type {string}
     */
    AccountCode: string = "";

    /**
     * @name AuthorizationCode
     * @description
     * Authorization code.
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name OtherCode
     * @description
     * Other code is a data source dependent value associated with the usage record.
     * @servertype String
     * @type {string}
     */
    OtherCode: string = "";

    /**
     * @name SubscriberId
     * @description
     * Subscriber Id - data source dependent.
     * @servertype String
     * @type {string}
     */
    SubscriberId: string = "";

    /**
     * @name Quality
     * @description
     * Quality - data source dependent.
     * @servertype String
     * @type {string}
     */
    Quality: string = "";

    /**
     * @name ClassificationType
     * @description
     * Classification type contains one or more flags detailing the type of transmissions and services involved with this record.
     *  Possible flags include:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * F = Fax
     * W = Wireless
     * R = Roaming
     * T = Transferred
     * X = Conference
     * ^ = Call Forwarding
     * . = Call Waiting
     * + = Premium Service
     * - = Discount Service
     * < = Low Priority
     * > = High Priority
     * A = Assisted (operator, csr, etc.)
     * # = Voice Mail or IVR
     * N = No Charge (i.e. free)
     * : = Preempted
     * 3 = Multi-party Transmission (i.e. 3-way call)
     * = = On-Net (i.e. internal)
     * @ = Virtual Private Network
     * H = Hospitality
     * $ = Payphone
     * % = Rate Adjustment Applied
     * ? = Unknown
     * @servertype String
     * @type {string}
     */
    ClassificationType: string = "";

    /**
     * @name Phase
     * @description
     * The phase property contains indicators of the types of records that are included in this usage record.  The specific values
     * are data source dependent.
     * @servertype String
     * @type {string}
     */
    Phase: string = "";

    /**
     * @name ServiceType
     * @description
     * Service type for this usage record:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceId
     * @description
     * Service Id uniquely identifies the service for rating purposes.  See usage service identification table for more information.
     * @servertype String
     * @type {string}
     */
    ServiceId: string = "";

    /**
     * @name DirectoryId
     * @description
     * Directory Id of the user this usage is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DirectoryId: number = null;

    /**
     * @name DirectoryName
     * @servertype String
     * @type {string}
     */
    DirectoryName: string = "";

    /**
     * @name UsageContactId
     * @description
     * The Contact Id of the usage contact this usage is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    UsageContactId: number = null;

    /**
     * @name UsageContactName
     * @servertype String
     * @type {string}
     */
    UsageContactName: string = "";

    /**
     * @name TotalUnits
     * @description
     * Total number of units for the usage.
     * @servertype Double (nullable)
     * @type {number}
     */
    TotalUnits: number = 0;

    /**
     * @name UsageDurationSeconds
     * @description
     * The usage duration represented in seconds.  Note that as part of processing some duration values may be rounded by the rating
     * engine depending upon rating profile configuration.  This property reports the original (unrounded) usage duration.
     * @servertype Double (nullable)
     * @type {number}
     */
    UsageDurationSeconds: number = 0;

    /**
     * @name Completed
     * @description
     * When true the usage is considered completed.  For voice traffic, accurate reporting for this value may depend on answer supervision
     * control in the switch.
     * @servertype Boolean
     * @type {boolean}
     */
    Completed: boolean = false;

    /**
     * @name Direction
     * @description
     * I = Incoming
     * O = Outgoing
     * E = Extension/Internal (i.e. neither incoming or outgoing)
     * B = Both (i.e. both incoming and outgoing like DISA)
     * @servertype String
     * @type {string}
     */
    Direction: string = "B";

    /**
     * @name SourceNetworkElementId
     * @description
     * Network Element Id of the source network element.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceNetworkElementId: number = null;

    /**
     * @name TargetNetworkElementId
     * @description
     * Network Element Id of the target network element.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetNetworkElementId: number = null;

    /**
     * @name Data001
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data001: string = "";

    /**
     * @name Data002
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data002: string = "";

    /**
     * @name Data003
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data003: string = "";

    /**
     * @name Data004
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data004: string = "";

    /**
     * @name Data005
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data005: string = "";

    /**
     * @name Data006
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data006: string = "";

    /**
     * @name Data007
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data007: string = "";

    /**
     * @name Data008
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data008: string = "";

    /**
     * @name Data009
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data009: string = "";

    /**
     * @name Data010
     * @description
     * A dynamic data for the usage.  See the data source for definition.
     * @servertype String
     * @type {string}
     */
    Data010: string = "";

    /**
     * @name UsageDataSourceId
     * @description
     * The Id of the Usage Data Source this usage record belongs to.  Could be null in some situations where usage is directly added.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    UsageDataSourceId: number = 0;

    /**
     * @name RatingStatus
     * @description
     * The rating status of this usage record.  Possible values include:
     * H = Hold (Usage record is still being mediated and is not complete)
     * P = Pending Rating (Usage record is complete and pending rating)
     * R = Rating (Usage record is currently being rated)
     * S = Success (Usage record successfully rated)
     * W = Warning (Usage record rated with one or more warnings)
     * E = Error (Usage record rated with one or more errors)
     * @servertype String
     * @type {string}
     */
    RatingStatus: string = "H";

    /**
     * @name ProcessingDateTime
     * @description
     * Date and time when an engine started processing this usage.  This can be used to help determine if there are any usage records
     * that should have posted success or failure but haven't and may be orphaned.  In that scenario the logs should be checked
     * for additional information.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name Data
     * @servertype IB.Data.Model.v5.UsageDataEditViewModel
     * @type {UsageDataEditViewModel}
     */
    Data: UsageDataEditViewModel = null;

    /**
     * @name Billing
     * @servertype UsageBillingEditViewModel array
     * @type {UsageBillingEditViewModel[]}
     */
    Billing: UsageBillingEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name UsageImportLogEditViewModel
 * @description
 * The usage import log table contains a record for each usage data import session detailing the results of the import session.
 */
export class UsageImportLogEditViewModel {

    /**
     * @name UsageImportLogId
     * @description
     * Usage Data Import Log Id uniquely identifies this usage import log session.
     * @servertype String
     * @type {string}
     */
    UsageImportLogId: string = null;

    /**
     * @name Description
     * @description
     * Description for this log session.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name UsageDataSourceId
     * @description
     * The Id of the Usage Data Source this log entry belongs to.
     * @servertype Int64
     * @type {number}
     */
    UsageDataSourceId: number = null;

    /**
     * @name JobId
     * @description
     * If the import session was initiated by a job this is the id of the job.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    JobId: number = null;

    /**
     * @name JobSessionId
     * @description
     * If the import session was initiated by a job this is the id of the job session.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    JobSessionId: number = 0;

    /**
     * @name ImportDateTime
     * @description
     * The date and time for the import session.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ImportDateTime: Date = null;

    /**
     * @name ImportType
     * @description
     * The type for this import.
     * @servertype String
     * @type {string}
     */
    ImportType: string = "";

    /**
     * @name LogFileName
     * @description
     * When the import was for a log file this is the file name for the log file.
     * @servertype String
     * @type {string}
     */
    LogFileName: string = "";

    /**
     * @name Status
     * @description
     * Status of this import session.  Possible values are:
     * P = Pending (import not started yet)
     * R = Running (import is running)
     * S = Success (import successful)
     * F = Failure (import failed)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name ResultCode
     * @description
     * The result code for the import session.  Possible values include standard result code plus these options:
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description
     * Result message for this import session.  More details can be found the logs if needed.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name Cancelled
     * @description
     * When true the import session was cancelled.
     * @servertype Boolean
     * @type {boolean}
     */
    Cancelled: boolean = false;

    /**
     * @name PercentDone
     * @description
     * The percent done for this import session.  Not all import types are able to update this value and updates may represent percent
     * done with parts of the session and not necessarily percent done from a time to complete perspective.
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentDone: number = 0;

    /**
     * @name ProcessingServer
     * @description
     * The server processing this import session.  If an import engine starts importing but never updates to reflect the results
     * there was most likely a serious failure that should have been logged on the processing server.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description
     * Date and time when the import engine started the import or last refreshed that work is still in process.  This can be used
     * to help determine if there are any import sessions that should have posted success or failure but haven't and may be orphaned.
     *  In that scenario the processing server should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name ArchiveFileName
     * @description
     * Name of the archive file for this session.
     * @servertype String
     * @type {string}
     */
    ArchiveFileName: string = "";

    /**
     * @name BackupFileName
     * @description
     * Name of the backup file for this session.
     * @servertype String
     * @type {string}
     */
    BackupFileName: string = "";

    /**
     * @name ErrorFileName
     * @description
     * Name of the error file for this session.
     * @servertype String
     * @type {string}
     */
    ErrorFileName: string = "";

    /**
     * @name WarningFileName
     * @description
     * Name of the warning file for this session.
     * @servertype String
     * @type {string}
     */
    WarningFileName: string = "";

    /**
     * @name OmittedFileName
     * @description
     * Name of the omitted file for this session.
     * @servertype String
     * @type {string}
     */
    OmittedFileName: string = "";

    /**
     * @name AlarmFileName
     * @description
     * Name of the alarm file for this session.
     * @servertype String
     * @type {string}
     */
    AlarmFileName: string = "";

    /**
     * @name InfoFileName
     * @description
     * Name of the info file for this session.
     * @servertype String
     * @type {string}
     */
    InfoFileName: string = "";

    /**
     * @name TotalRecordCount
     * @description
     * Total number of records encountered by the import session.  Note that this could be less than the number of records actually
     * processed because the user may have cancelled the session.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TotalRecordCount: number = 0;

    /**
     * @name ImportedRecordCount
     * @description
     * Number of records imported.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ImportedRecordCount: number = 0;

    /**
     * @name BlankRecordCount
     * @description
     * Number of records that were blank.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BlankRecordCount: number = 0;

    /**
     * @name ErrorRecordCount
     * @description
     * Number of records that were classified as errors.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ErrorRecordCount: number = 0;

    /**
     * @name WarningRecordCount
     * @description
     * Number of records that were classified as warnings.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    WarningRecordCount: number = 0;

    /**
     * @name OmittedRecordCount
     * @description
     * Number of records that were omitted by the import engine.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    OmittedRecordCount: number = 0;

    /**
     * @name AlarmRecordCount
     * @description
     * Number of records that were classified as alarms.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AlarmRecordCount: number = 0;

    /**
     * @name CompletedRecordCount
     * @description
     * Number of records that were classified as completed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CompletedRecordCount: number = 0;

    /**
     * @name IncompleteRecordCount
     * @description
     * Number of records that were classified as not completed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    IncompleteRecordCount: number = 0;

    /**
     * @name DirectionIncomingRecordCount
     * @description
     * Number of records that were classified as incoming.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DirectionIncomingRecordCount: number = 0;

    /**
     * @name DirectionOutgoingRecordCount
     * @description
     * Number of records that were classified as outgoing.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DirectionOutgoingRecordCount: number = 0;

    /**
     * @name DirectionInternalRecordCount
     * @description
     * Number of records that were classified as internal (i.e. extension).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DirectionInternalRecordCount: number = 0;

    /**
     * @name DirectionExternalRecordCount
     * @description
     * Number of records that were classified as external (i.e. both incoming and outgoing like DISA).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DirectionExternalRecordCount: number = 0;

    /**
     * @name AverageImportSpeedRecordsPerSecond
     * @description
     * Average import speed for this import session specified in number of records per second.
     * @servertype Double (nullable)
     * @type {number}
     */
    AverageImportSpeedRecordsPerSecond: number = 0;

    /**
     * @name Flags
     * @description
     * A list of flags for this import session.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this import session.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name UsageLegacyEditViewModel
 * @description
 * The Usage table contains information about various types of usage.  Usage is stored by calendar month using this table as
 * a template where YYYY is the year and MM is the month.
 */
export class UsageLegacyEditViewModel {

    /**
     * @name UsageId
     * @description
     * Usage Id uniquely identifies this usage record.
     * @servertype String
     * @type {string}
     */
    UsageId: string = null;

    /**
     * @name UsageDateTime
     * @description
     * The date and time for the usage record.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageDateTime: Date = null;

    /**
     * @name UtcOffset
     * @servertype String
     * @type {string}
     */
    UtcOffset: string = "";

    /**
     * @name TargetIdentifier
     * @description
     * Target phone number, ip address, url, or other target for this usage.
     * @servertype String
     * @type {string}
     */
    TargetIdentifier: string = "";

    /**
     * @name TargetLocation
     * @description
     * Location information associated with the target identifier.  For voice this is often the country code, npa, nxx, etc.
     * @servertype String
     * @type {string}
     */
    TargetLocation: string = "";

    /**
     * @name TargetIdentifierDescription
     * @description
     * Description of the target identifier.  For example, voice records may use this for the city, state, country, etc.
     * @servertype String
     * @type {string}
     */
    TargetIdentifierDescription: string = "";

    /**
     * @name TargetIdentifierDetails
     * @servertype String
     * @type {string}
     */
    TargetIdentifierDetails: string = "";

    /**
     * @name SourceIdentifier
     * @description
     * Source phone number (ANI), ip address, url, or other identifier associated with the source for this usage.
     * @servertype String
     * @type {string}
     */
    SourceIdentifier: string = "";

    /**
     * @name SourceLocation
     * @description
     * Location information associated with the source identifier.  For voice this is often the country code, npa, nxx, etc.
     * @servertype String
     * @type {string}
     */
    SourceLocation: string = "";

    /**
     * @name SourceIdentifierInformation
     * @servertype String
     * @type {string}
     */
    SourceIdentifierInformation: string = "";

    /**
     * @name AccessCode
     * @servertype String
     * @type {string}
     */
    AccessCode: string = "";

    /**
     * @name TotalUnits
     * @description
     * Total number of units for the usage.
     * @servertype Double (nullable)
     * @type {number}
     */
    TotalUnits: number = 0;

    /**
     * @name ConnectDurationSeconds
     * @servertype Double (nullable)
     * @type {number}
     */
    ConnectDurationSeconds: number = 0;

    /**
     * @name HoldDurationSeconds
     * @servertype Double (nullable)
     * @type {number}
     */
    HoldDurationSeconds: number = 0;

    /**
     * @name UsageDurationSeconds
     * @description
     * The usage duration represented in seconds.  Note that as part of processing some duration values may be rounded by the rating
     * engine depending upon rating profile configuration.  This property reports the original (unrounded) usage duration.
     * @servertype Double (nullable)
     * @type {number}
     */
    UsageDurationSeconds: number = 0;

    /**
     * @name SerialNumber
     * @description
     * Usage serial number.  Some usage generate multiple accounting, usage, or statistics records that are then mediated or consolidated
     * into a single usage record using this serial number.
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name SourceExternalNetworkElementId
     * @servertype String
     * @type {string}
     */
    SourceExternalNetworkElementId: string = "";

    /**
     * @name SourceInExternalNetworkPortId
     * @servertype String
     * @type {string}
     */
    SourceInExternalNetworkPortId: string = "";

    /**
     * @name SourceInExternalNetworkPortGroupId
     * @servertype String
     * @type {string}
     */
    SourceInExternalNetworkPortGroupId: string = "";

    /**
     * @name SourceOutExternalNetworkPortId
     * @servertype String
     * @type {string}
     */
    SourceOutExternalNetworkPortId: string = "";

    /**
     * @name SourceOutExternalNetworkPortGroupId
     * @servertype String
     * @type {string}
     */
    SourceOutExternalNetworkPortGroupId: string = "";

    /**
     * @name SourceType
     * @servertype String
     * @type {string}
     */
    SourceType: string = "";

    /**
     * @name TargetExternalNetworkElementId
     * @servertype String
     * @type {string}
     */
    TargetExternalNetworkElementId: string = "";

    /**
     * @name TargetInExternalNetworkPortId
     * @servertype String
     * @type {string}
     */
    TargetInExternalNetworkPortId: string = "";

    /**
     * @name TargetInExternalNetworkPortGroupId
     * @servertype String
     * @type {string}
     */
    TargetInExternalNetworkPortGroupId: string = "";

    /**
     * @name TargetOutExternalNetworkPortId
     * @servertype String
     * @type {string}
     */
    TargetOutExternalNetworkPortId: string = "";

    /**
     * @name TargetOutExternalNetworkPortGroupId
     * @servertype String
     * @type {string}
     */
    TargetOutExternalNetworkPortGroupId: string = "";

    /**
     * @name TargetType
     * @servertype String
     * @type {string}
     */
    TargetType: string = "";

    /**
     * @name Extension
     * @description
     * Extension associated with this usage record.
     * @servertype String
     * @type {string}
     */
    Extension: string = null;

    /**
     * @name TransferredExtension
     * @servertype String
     * @type {string}
     */
    TransferredExtension: string = "";

    /**
     * @name DirectoryId
     * @description
     * Directory Id of the user this usage is linked to.
     * @servertype String
     * @type {string}
     */
    DirectoryId: string = null;

    /**
     * @name DirectoryName
     * @servertype String
     * @type {string}
     */
    DirectoryName: string = "";

    /**
     * @name UsageContactId
     * @description
     * The Contact Id of the usage contact this usage is linked to.
     * @servertype String
     * @type {string}
     */
    UsageContactId: string = null;

    /**
     * @name UsageContactName
     * @servertype String
     * @type {string}
     */
    UsageContactName: string = "";

    /**
     * @name ExternalUsageDataSourceId
     * @servertype String
     * @type {string}
     */
    ExternalUsageDataSourceId: string = "";

    /**
     * @name Direction
     * @description
     * I = Incoming
     * O = Outgoing
     * E = Extension/Internal (i.e. neither incoming or outgoing)
     * B = Both (i.e. both incoming and outgoing like DISA)
     * @servertype String
     * @type {string}
     */
    Direction: string = "B";

    /**
     * @name Completed
     * @description
     * When true the usage is considered completed.  For voice traffic, accurate reporting for this value may depend on answer supervision
     * control in the switch.
     * @servertype Boolean
     * @type {boolean}
     */
    Completed: boolean = false;

    /**
     * @name AccountCode
     * @description
     * Account code.
     * @servertype String
     * @type {string}
     */
    AccountCode: string = "";

    /**
     * @name AuthorizationCode
     * @description
     * Authorization code.
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name ClassificationType
     * @description
     * Classification type contains one or more flags detailing the type of transmissions and services involved with this record.
     *  Possible flags include:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * F = Fax
     * W = Wireless
     * R = Roaming
     * T = Transferred
     * X = Conference
     * ^ = Call Forwarding
     * . = Call Waiting
     * + = Premium Service
     * - = Discount Service
     * < = Low Priority
     * > = High Priority
     * A = Assisted (operator, csr, etc.)
     * # = Voice Mail or IVR
     * N = No Charge (i.e. free)
     * : = Preempted
     * 3 = Multi-party Transmission (i.e. 3-way call)
     * = = On-Net (i.e. internal)
     * @ = Virtual Private Network
     * H = Hospitality
     * $ = Payphone
     * % = Rate Adjustment Applied
     * ? = Unknown
     * @servertype String
     * @type {string}
     */
    ClassificationType: string = "";

    /**
     * @name OtherCode
     * @description
     * Other code is a data source dependent value associated with the usage record.
     * @servertype String
     * @type {string}
     */
    OtherCode: string = "";

    /**
     * @name Phase
     * @description
     * The phase property contains indicators of the types of records that are included in this usage record.  The specific values
     * are data source dependent.
     * @servertype String
     * @type {string}
     */
    Phase: string = "";

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name ServiceType
     * @description
     * Service type for this usage record:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceId
     * @description
     * Service Id uniquely identifies the service for rating purposes.  See usage service identification table for more information.
     * @servertype String
     * @type {string}
     */
    ServiceId: string = "";

    /**
     * @name GroupId
     * @servertype String
     * @type {string}
     */
    GroupId: string = "";

    /**
     * @name AccountingId
     * @servertype String
     * @type {string}
     */
    AccountingId: string = "";

    /**
     * @name Quality
     * @description
     * Quality - data source dependent.
     * @servertype String
     * @type {string}
     */
    Quality: string = "";

    /**
     * @name Disconnect
     * @servertype String
     * @type {string}
     */
    Disconnect: string = "";

    /**
     * @name CustomerId1
     * @servertype String
     * @type {string}
     */
    CustomerId1: string = "";

    /**
     * @name RetailIsoCurrencyCode1
     * @servertype String
     * @type {string}
     */
    RetailIsoCurrencyCode1: string = "";

    /**
     * @name RetailBaseCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailBaseCost1: number = 0;

    /**
     * @name RetailFullCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailFullCost1: number = 0;

    /**
     * @name RetailDuration1
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailDuration1: number = 0;

    /**
     * @name RetailRateResult1
     * @servertype Int16 (nullable)
     * @type {number}
     */
    RetailRateResult1: number = 0;

    /**
     * @name RetailRateCode1
     * @servertype String
     * @type {string}
     */
    RetailRateCode1: string = "";

    /**
     * @name RetailType1
     * @servertype String
     * @type {string}
     */
    RetailType1: string = "";

    /**
     * @name RetailPackageOccurrenceId1
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RetailPackageOccurrenceId1: number = 0;

    /**
     * @name RetailBillingResult1
     * @servertype Int16 (nullable)
     * @type {number}
     */
    RetailBillingResult1: number = 0;

    /**
     * @name RetailTransactionId1
     * @servertype String
     * @type {string}
     */
    RetailTransactionId1: string = "";

    /**
     * @name RetailExTransactionId1
     * @servertype String
     * @type {string}
     */
    RetailExTransactionId1: string = "";

    /**
     * @name CustomerId2
     * @servertype String
     * @type {string}
     */
    CustomerId2: string = "";

    /**
     * @name RetailIsoCurrencyCode2
     * @servertype String
     * @type {string}
     */
    RetailIsoCurrencyCode2: string = "";

    /**
     * @name RetailBaseCost2
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailBaseCost2: number = 0;

    /**
     * @name RetailFullCost2
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailFullCost2: number = 0;

    /**
     * @name RetailDuration2
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailDuration2: number = 0;

    /**
     * @name RetailRateResult2
     * @servertype Int16 (nullable)
     * @type {number}
     */
    RetailRateResult2: number = 0;

    /**
     * @name RetailRateCode2
     * @servertype String
     * @type {string}
     */
    RetailRateCode2: string = "";

    /**
     * @name RetailType2
     * @servertype String
     * @type {string}
     */
    RetailType2: string = "";

    /**
     * @name RetailPackageOccurrenceId2
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RetailPackageOccurrenceId2: number = 0;

    /**
     * @name RetailBillingResult2
     * @servertype Int16 (nullable)
     * @type {number}
     */
    RetailBillingResult2: number = 0;

    /**
     * @name RetailTransactionId2
     * @servertype String
     * @type {string}
     */
    RetailTransactionId2: string = "";

    /**
     * @name RetailExTransactionId2
     * @servertype String
     * @type {string}
     */
    RetailExTransactionId2: string = "";

    /**
     * @name AgentId1
     * @servertype String
     * @type {string}
     */
    AgentId1: string = "";

    /**
     * @name AgentIsoCurrencyCode1
     * @servertype String
     * @type {string}
     */
    AgentIsoCurrencyCode1: string = "";

    /**
     * @name AgentBaseCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    AgentBaseCost1: number = 0;

    /**
     * @name AgentFullCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    AgentFullCost1: number = 0;

    /**
     * @name AgentDuration1
     * @servertype Double (nullable)
     * @type {number}
     */
    AgentDuration1: number = 0;

    /**
     * @name AgentRateResult1
     * @servertype Int16 (nullable)
     * @type {number}
     */
    AgentRateResult1: number = 0;

    /**
     * @name AgentRateCode1
     * @servertype String
     * @type {string}
     */
    AgentRateCode1: string = "";

    /**
     * @name AgentType1
     * @servertype String
     * @type {string}
     */
    AgentType1: string = "";

    /**
     * @name AgentPackageOccurrenceId1
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPackageOccurrenceId1: number = 0;

    /**
     * @name AgentBillingResult1
     * @servertype Int16 (nullable)
     * @type {number}
     */
    AgentBillingResult1: number = 0;

    /**
     * @name AgentTransactionId1
     * @servertype String
     * @type {string}
     */
    AgentTransactionId1: string = "";

    /**
     * @name AgentExTransactionId1
     * @servertype String
     * @type {string}
     */
    AgentExTransactionId1: string = "";

    /**
     * @name AgentId2
     * @servertype String
     * @type {string}
     */
    AgentId2: string = "";

    /**
     * @name AgentIsoCurrencyCode2
     * @servertype String
     * @type {string}
     */
    AgentIsoCurrencyCode2: string = "";

    /**
     * @name AgentBaseCost2
     * @servertype Double (nullable)
     * @type {number}
     */
    AgentBaseCost2: number = 0;

    /**
     * @name AgentFullCost2
     * @servertype Double (nullable)
     * @type {number}
     */
    AgentFullCost2: number = 0;

    /**
     * @name AgentDuration2
     * @servertype Double (nullable)
     * @type {number}
     */
    AgentDuration2: number = 0;

    /**
     * @name AgentRateResult2
     * @servertype Int16 (nullable)
     * @type {number}
     */
    AgentRateResult2: number = 0;

    /**
     * @name AgentRateCode2
     * @servertype String
     * @type {string}
     */
    AgentRateCode2: string = "";

    /**
     * @name AgentType2
     * @servertype String
     * @type {string}
     */
    AgentType2: string = "";

    /**
     * @name AgentPackageOccurrenceId2
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPackageOccurrenceId2: number = 0;

    /**
     * @name AgentBillingResult2
     * @servertype Int16 (nullable)
     * @type {number}
     */
    AgentBillingResult2: number = 0;

    /**
     * @name AgentTransactionId2
     * @servertype String
     * @type {string}
     */
    AgentTransactionId2: string = "";

    /**
     * @name AgentExTransactionId2
     * @servertype String
     * @type {string}
     */
    AgentExTransactionId2: string = "";

    /**
     * @name ServiceProviderId1
     * @servertype String
     * @type {string}
     */
    ServiceProviderId1: string = "";

    /**
     * @name WholesaleIsoCurrencyCode1
     * @servertype String
     * @type {string}
     */
    WholesaleIsoCurrencyCode1: string = "";

    /**
     * @name WholesaleBaseCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleBaseCost1: number = 0;

    /**
     * @name WholesaleFullCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleFullCost1: number = 0;

    /**
     * @name WholesaleDuration1
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleDuration1: number = 0;

    /**
     * @name WholesaleRateResult1
     * @servertype Int16 (nullable)
     * @type {number}
     */
    WholesaleRateResult1: number = 0;

    /**
     * @name WholesaleRateCode1
     * @servertype String
     * @type {string}
     */
    WholesaleRateCode1: string = "";

    /**
     * @name WholesaleType1
     * @servertype String
     * @type {string}
     */
    WholesaleType1: string = "";

    /**
     * @name WholesalePackageOccurrenceId1
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WholesalePackageOccurrenceId1: number = 0;

    /**
     * @name WholesaleBillingResult1
     * @servertype Int16 (nullable)
     * @type {number}
     */
    WholesaleBillingResult1: number = 0;

    /**
     * @name WholesaleTransactionId1
     * @servertype String
     * @type {string}
     */
    WholesaleTransactionId1: string = "";

    /**
     * @name WholesaleExTransactionId1
     * @servertype String
     * @type {string}
     */
    WholesaleExTransactionId1: string = "";

    /**
     * @name ServiceProviderId2
     * @servertype String
     * @type {string}
     */
    ServiceProviderId2: string = "";

    /**
     * @name WholesaleIsoCurrencyCode2
     * @servertype String
     * @type {string}
     */
    WholesaleIsoCurrencyCode2: string = "";

    /**
     * @name WholesaleBaseCost2
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleBaseCost2: number = 0;

    /**
     * @name WholesaleFullCost2
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleFullCost2: number = 0;

    /**
     * @name WholesaleDuration2
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleDuration2: number = 0;

    /**
     * @name WholesaleRateResult2
     * @servertype Int16 (nullable)
     * @type {number}
     */
    WholesaleRateResult2: number = 0;

    /**
     * @name WholesaleRateCode2
     * @servertype String
     * @type {string}
     */
    WholesaleRateCode2: string = "";

    /**
     * @name WholesaleType2
     * @servertype String
     * @type {string}
     */
    WholesaleType2: string = "";

    /**
     * @name WholesalePackageOccurrenceId2
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WholesalePackageOccurrenceId2: number = 0;

    /**
     * @name WholesaleBillingResult2
     * @servertype Int16 (nullable)
     * @type {number}
     */
    WholesaleBillingResult2: number = 0;

    /**
     * @name WholesaleTransactionId2
     * @servertype String
     * @type {string}
     */
    WholesaleTransactionId2: string = "";

    /**
     * @name WholesaleExTransactionId2
     * @servertype String
     * @type {string}
     */
    WholesaleExTransactionId2: string = "";

    /**
     * @name ServiceProviderId3
     * @servertype String
     * @type {string}
     */
    ServiceProviderId3: string = "";

    /**
     * @name WholesaleIsoCurrencyCode3
     * @servertype String
     * @type {string}
     */
    WholesaleIsoCurrencyCode3: string = "";

    /**
     * @name WholesaleBaseCost3
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleBaseCost3: number = 0;

    /**
     * @name WholesaleFullCost3
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleFullCost3: number = 0;

    /**
     * @name WholesaleDuration3
     * @servertype Double (nullable)
     * @type {number}
     */
    WholesaleDuration3: number = 0;

    /**
     * @name WholesaleRateResult3
     * @servertype Int16 (nullable)
     * @type {number}
     */
    WholesaleRateResult3: number = 0;

    /**
     * @name WholesaleRateCode3
     * @servertype String
     * @type {string}
     */
    WholesaleRateCode3: string = "";

    /**
     * @name WholesaleType3
     * @servertype String
     * @type {string}
     */
    WholesaleType3: string = "";

    /**
     * @name WholesalePackageOccurrenceId3
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WholesalePackageOccurrenceId3: number = 0;

    /**
     * @name WholesaleBillingResult3
     * @servertype Int16 (nullable)
     * @type {number}
     */
    WholesaleBillingResult3: number = 0;

    /**
     * @name WholesaleTransactionId3
     * @servertype String
     * @type {string}
     */
    WholesaleTransactionId3: string = "";

    /**
     * @name WholesaleExTransactionId3
     * @servertype String
     * @type {string}
     */
    WholesaleExTransactionId3: string = "";

    /**
     * @name AlternateIsoCurrencyCode1
     * @servertype String
     * @type {string}
     */
    AlternateIsoCurrencyCode1: string = "";

    /**
     * @name AlternateBaseCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    AlternateBaseCost1: number = 0;

    /**
     * @name AlternateFullCost1
     * @servertype Double (nullable)
     * @type {number}
     */
    AlternateFullCost1: number = 0;

    /**
     * @name AlternateDuration1
     * @servertype Double (nullable)
     * @type {number}
     */
    AlternateDuration1: number = 0;

    /**
     * @name AlternateRateResult1
     * @servertype Int16 (nullable)
     * @type {number}
     */
    AlternateRateResult1: number = 0;

    /**
     * @name AlternateRateCode1
     * @servertype String
     * @type {string}
     */
    AlternateRateCode1: string = "";

    /**
     * @name AlternateType1
     * @servertype String
     * @type {string}
     */
    AlternateType1: string = "";

    /**
     * @name RecordStatus
     * @servertype Byte (nullable)
     * @type {number}
     */
    RecordStatus: number = 0;

    /**
     * @name RecordFlag
     * @servertype Byte (nullable)
     * @type {number}
     */
    RecordFlag: number = 0;

}



/**
 * @name UsageRatingRequest
 * @description
 * This contains information about a rating request.
 */
export class UsageRatingRequestViewModel {

    /**
     * @name UsageTableType
     * @description
     * The usage table type for the request.  If legacy type then this should be set to Legacy.  Any other value (including empty)
     * is considered to be the non-legacy usage table.
     * @servertype String
     * @type {string}
     */
    UsageTableType: string = "";

    /**
     * @name UsageDateTime
     * @description
     * When requesting rating of a single usage row this is the date and time for the usage record to rate. For single row rating
     * the compound UsageId or the UsageDateTime and UsageIdNative are required.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    UsageDateTime: Date = null;

    /**
     * @name UsageIdNative
     * @description
     * When requesting rating of a single usage row this is the native id for the usage record to rate. For single row rating the
     * compound UsageId or the UsageDateTime and UsageIdNative are required.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    UsageIdNative: number = 0;

    /**
     * @name UsageId
     * @description
     * When requesting rating of a single usage row this is the compound id (date + id) for the usage record to rate. For single
     * row rating the compound UsageId or the UsageDateTime and UsageIdNative are required.
     * @servertype String
     * @type {string}
     */
    UsageId: string = "";

    /**
     * @name Async
     * @description
     * When true the rating happens asynchronously.  For single row rating requests this can be set to false in which case the process
     * will wait up to the specified number of timeout seconds for the results so they can be returned to the caller.
     * @servertype Boolean
     * @type {boolean}
     */
    Async: boolean = false;

    /**
     * @name TimeoutSeconds
     * @description
     * The number of seconds before a timeout occurs when waiting for single row rating requests to finish when async is set to
     * false.
     * @servertype Int32
     * @type {number}
     */
    TimeoutSeconds: number = 0;

    /**
     * @name BeginningDateTime
     * @description
     * When requesting rating for a batch of usage rows this is the beginning date time to include in the batch.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BeginningDateTime: Date = null;

    /**
     * @name EndingDateTime
     * @description
     * When requesting rating for a batch of usage rows this is the ending date time to include in the batch.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndingDateTime: Date = null;

    /**
     * @name RateSucess
     * @description
     * When requesting rating for a batch of usage rows this flag indicates if rows marked as rating success are included.
     * @servertype Boolean
     * @type {boolean}
     */
    RateSucess: boolean = false;

    /**
     * @name RateWarning
     * @description
     * When requesting rating for a batch of usage rows this flag indicates if rows marked as rating warning are included.
     * @servertype Boolean
     * @type {boolean}
     */
    RateWarning: boolean = false;

    /**
     * @name RateError
     * @description
     * When requesting rating for a batch of usage rows this flag indicates if rows marked as rating error are included.
     * @servertype Boolean
     * @type {boolean}
     */
    RateError: boolean = false;

    /**
     * @name RateZeroCostOnly
     * @description
     * When requesting rating for a batch of usage rows this flag indicates if only rows that had zero cost associated with the
     * usage are included.
     * @servertype Boolean
     * @type {boolean}
     */
    RateZeroCostOnly: boolean = false;

    /**
     * @name FilterId
     * @description
     * When requesting rating for a batch of usage rows this is an optional filter id of the filter to apply for selecting rows
     * to rate.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FilterId: number = 0;

    /**
     * @name Filter
     * @description
     * When requesting rating for a batch of usage rows this is an optional filter string of the filter to apply for selecting rows
     * to rate.
     * @servertype String
     * @type {string}
     */
    Filter: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone date time values are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name UsageServiceIdentificationEditViewModel
 * @description
 * The Usage Service Identification table contains information related to identification of services posted to usage tables.
 */
export class UsageServiceIdentificationEditViewModel {

    /**
     * @name UsageServiceIdentificationId
     * @description
     * Usage Service Identification Id uniquely identifies this usage service.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    UsageServiceIdentificationId: number = null;

    /**
     * @name Description
     * @description
     * Service Identification Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ServiceType
     * @description
     * Service type for this service id:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceId
     * @description
     * Serviced Id uniquely identifies the service for identification, description, and rating purposes.
     * @servertype String
     * @type {string}
     */
    ServiceId: string = "";

    /**
     * @name UsageDescription
     * @description
     * Description to use for this service type and service id if there is no description already provided for in the usage detail
     * record.  For voice this will typically be the city, state, and/or country of the target number and/or source number.  For
     * each usage detail record macros provided here will be converted to the appropriate location information.  Any literal text
     * will be included as-is.  A conditional macro may be used to provide literal text that will only appear when the macro to
     * the left ({{CL:x}}), right ({{CR:x}}), or both ({{CB:x}}) left and right of the conditional macro were populated with a non-blank
     * value.  For example, the macro {{CB:, }} will cause a comma followed by a space to be entered only if the macro to both the
     * left and the right of the conditional macro had values.
     * @servertype String
     * @type {string}
     */
    UsageDescription: string = "";

    /**
     * @name UsageDetail
     * @description
     * Additional details to use for this service type and service id if there is no description already provided for in the usage
     * detail record.  For voice this will typically be the city, state, and/or country of the target number and/or source number.
     *  For each usage detail record macros provided here will be converted to the appropriate location information.  Any literal
     * text will be included as-is.  A conditional macro may be used to provide literal text that will only appear when the macro
     * to the left ({{CL:x}}), right ({{CR:x}}), or both ({{CB:x}}) left and right of the conditional macro were populated with
     * a non-blank value.  For example, the macro {{CB:, }} will cause a comma followed by a space to be entered only if the macro
     * to both the left and the right of the conditional macro had values.
     * @servertype String
     * @type {string}
     */
    UsageDetail: string = "";

    /**
     * @name UsageType
     * @description
     * The Usage Type to assign to the usage detail record prior to the usage detail record being rated.  This is unique per service
     * type.  Possible values can include:
     * LO = Voice - Local
     * IT = Voice - IntraLATA
     * IS = Voice - Intrastate
     * LD = Voice - Interstate
     * IN = Voice - International
     * FR = Voice - Free
     * EI = Voice - Extension/Internal
     * MO = Voice - Mobile
     * EX = Voice - Exception
     * UN = Voice - Unknown
     * EM = Voice - Emergency
     * OP = Voice - Operator
     * IO = Voice - International Operator
     * LI = Voice - Local Information
     * OI = Voice - Other Information (i.e. long distance)
     * DW = Data - Web
     * DE = Data - Email
     * DF = Data - Ftp
     * DN = Data - Nntp
     * DI = Data - IM
     * DS = Data - SMS
     * DM = Data - MMS
     * DA = Data - Analytics
     * DU = Data - Usage
     * DO = Data - Other
     * VC = Video - Conference
     * VM = Video - Movie
     * VT = Video - TV
     * VO = Video - Other
     * CA = Content - Application
     * CG = Content - Gaming
     * CD = Content - Download
     * CP = Content - Pay Per View/Use
     * CM = Content - Media
     * CS = Content - Services
     * CO = Content - Other
     * @servertype String
     * @type {string}
     */
    UsageType: string = "";

    /**
     * @name ClassificationType
     * @description
     * A list of one or more flags detailing the type of transmissions and services involved.  The values specified here will be
     * merged with values provided with the usage detail record prior to the usage detail record being rated.  Possible flags include:
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * F = Fax
     * W = Wireless
     * R = Roaming
     * T = Transferred
     * X = Conference
     * ^ = Call Forwarding
     * . = Call Waiting
     * + = Premium Service
     * - = Discount Service
     * < = Low Priority
     * > = High Priority
     * A = Assisted (operator, csr, etc.)
     * # = Voice Mail or IVR
     * N = No Charge (i.e. free)
     * : = Preempted
     * 3 = Multi-party Transmission (i.e. 3-way call)
     * = = On-Net (i.e. internal)
     * @ = Virtual Private Network
     * H = Hospitality
     * $ = Payphone
     * % = Rate Adjustment Applied
     * ? = Unknown
     * @servertype string array
     * @type {string[]}
     */
    ClassificationType: string[] = [];

    /**
     * @name Tier
     * @description
     * Tier number to use for rate identification purposes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Tier: number = 0;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this record is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Flags
     * @description
     * A list of flags for this service identification.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description
     * Custom properties for this service identification.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



