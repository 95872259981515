import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BehaviorSubject, merge, Observable, of as observableOf } from 'rxjs';
import * as m5rc from "projects/core-lib/src/lib/models/ngModelsReportCompiler5";
import { CollectionViewer, DataSource, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl, TreeControl } from '@angular/cdk/tree';
import { map, take } from 'rxjs/operators';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { EventModel } from '../../../ux-models';
import { UxService } from '../../../services/ux.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { TreeNode, TreeFlatNode } from 'projects/core-lib/src/lib/models/model-helpers';

/**
 * My biggest concern when building this new component and integrating it into cases and case templates is efficiency.
 * I had to do a lot of hand holding with change detection, DOM refreshing, and the managing of both attachments and
 * attachmentNodes to make sure they are in sync. Maybe there is a better way than how I did it, and I welcome any suggestions.
 *
 * One example of inefficiency, and this is probably the worst, is when I add 2 way binding between the heading in the form
 * and the label in the tree. I had to do this on keyup so it reflects like you would expect 2 way binding to. But in data-nav-tree
 * ngOnChanges, when the selectedNodeNameChange, it has to updateView on every keyup because otherwise the DOM won't reflect the change.
 *
 * Another example is dealing with attachmentSelected. The challenge here is when the form is using autosave, which fires often,
 * each time the autosave happens, the reference for attachmentSelected changes. So I ran into the challenge where attachmentSelected
 * could be referencing the old object, but I'm checking it for new changes. Because of this, onChanges, I need to manually find
 * it's index and reassign it to that index in attachments. Otherwise there are problems down the line because attachmentSelected
 * needs to be an accurate reference for the sake of 'editing by reference' and other things.
 *
 *
 * This component works by taking an array of TreeNodes, passed in by the parent, which are then
 * converted to a flat list, and the nesting is represented by padding according to the level.
 * It utilizes the open source MatTreeFlattener and MatTreeFlatDataSource to help with the
 * conversions.
 *
 * Quoted from MatTreeFlattener:
 * "For example, the input data of type `T` is nested, and contains its children data:
 *   SomeNode: {
 *     key: 'Fruits',
 *     children: [
 *       NodeOne: {
 *         key: 'Apple',
 *       },
 *       NodeTwo: {
 *        key: 'Pear',
 *      }
 *    ]
 *  }
 *  After flattener flatten the tree, the structure will become
 *  SomeNode: {
 *    key: 'Fruits',
 *    expandable: true,
 *    level: 1
 *  },
 *  NodeOne: {
 *    key: 'Apple',
 *    expandable: false,
 *    level: 2
 *  },
 *  NodeTwo: {
 *   key: 'Pear',
 *   expandable: false,
 *   level: 2
 * }"
 *
 * This means, that Fruits would have the designated padding for level 1, likely 30-40px, and anything level
 * 2 would have an additional 30-40px to simulate that it is nested. The DOM is just reading a flat list and
 * padding appropriately. Otherwise it doesn't know what is a child of what and that is up to us to determine
 * when dealing with drops.
 *
 * Why not just use some ngFor's in the HTML and use a nested tree approach?
 *  - Drop issues with nested lists: https://github.com/angular/components/issues/16671
 *  - Trouble determining which drop zone the pointer is positioned in: https://keyholesoftware.com/2022/05/05/angular-material-drag-and-drop-strengths-and-limitations/
 *  - 'Drag and drop + cdk/material tree don't work out of the box because you have to manipulate the data based
 *     on the input from the drag and drop event' https://blog.briebug.com/blog/angular-how-to-implement-drag-and-drop-in-a-material-tree
 *
 * This component is modified from https://blog.briebug.com/blog/angular-how-to-implement-drag-and-drop-in-a-material-tree
 * with tips from https://stackblitz.com/edit/mat-tree-with-drag-and-drop-lguvrr?file=app%2Ftree-flat-overview-example.ts
 * which inspired the 'dropping to a parent with no children' approach used here.
 *
 * Challenges faced when building this component:
 * - The dom doesn't rebuild when adding a child: https://github.com/angular/components/issues/11381
 * - Toggle Icons don't change automatically. If you remove the last child, the icon to toggle remains.
 * - Dropping to a node with no children.
 * - After making change like adding new node, all nodes would collapse.
 */


@Component({
  selector: 'ib-data-nav-tree',
  templateUrl: './data-nav-tree.component.html',
  styleUrls: ['./data-nav-tree.component.css'],
})

export class DataNavTreeComponent extends BaseComponent implements OnInit, OnChanges {

  /** The array of nodes from which the drag and drop list will populate. */
  @Input() nodes: TreeNode[] = [];

  /**
   * Experimental. Need to double check logic but I think it locks it so you can only drop something
   * where it already is, within it's current group of siblings, not necessarily with another parent
   * so it ends up at the same level. Needs better name probably.
   */
  // @Input() sameLevelDropsOnly: boolean = false;

  /** The indent in pixels. Each new level will add an additional amount of this many pixels. 40 is the CDK default */
  @Input() indentInPixels: string = "40";

  /**
   * Used primarily to control CSS so the selected node can be expressed to the user via styling.
   */
  @Input() selectedNode: TreeNode = null;
  @Output() selectedNodeChange: EventEmitter<TreeNode> = new EventEmitter();

  /**
   * If true, the toggle icon will be visible, even when there are no children for that node. This component
   * will manage the state of the children, as icons display based on whether or not the children property is null.
   * An empty but initialized children property will cause an icon to be displayed.
   */
  @Input() alwaysShowToggleIcon: boolean = true;

  /**
   * As the parent changes the input in their form that pairs with the selected node name, this can be
   * updated so the changes can be updated in the respective node.
   */
  @Input() selectedNodeNameChange: string = "";

  /**
   * When hovering a node with no children, this is the amount of milliseconds before it will push the drop as a child
   * of that node, instead of trying to splice it at the same level of the node being hovered.
   */
  @Input() millisecondsToNestAsChild: number = 1500;

  /**
   * If true, the nodes will have a checkbox property that will appear checked if no
   */
  @Input() enableCheckboxes: boolean = false;

  /**
   * Allows the parent to let us know if nodes changed, because otherwise they would be forced to use
   * the spread operator to do this but it introduces problems since it changes references
   */
  @Input() nodesChange: number = 0;

  /**
   * If this is incremented, the currently selected node will expand (if it has any children).
   */
  @Input() expandSelectedNode: number = 0;

  /**
   * If parent returns 'push' or 'splice', it can be deemed valid. If parent returns 'notValid' then it will not drop. This is
   * because if the parent sets millisecondsToNestAsChild, then this won't care what the action is, since it already decides,
   * as long as it doesn't receive 'notValid'.
   */
  @Input() validateDropCallback: (args: { Action: string, Level: number, DragNodeId: string }) => "push" | "splice" | "notValid";

  @Output() nodeClick: EventEmitter<EventModel> = new EventEmitter();
  @Output() nodeDrop: EventEmitter<EventModel> = new EventEmitter();
  @Output() checkBoxClick: EventEmitter<EventModel> = new EventEmitter();

  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
  treeControl: FlatTreeControl<TreeFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;

  /**
   * This is managed by the mat tree classes, but we do some manual manipulation to remove which nodes are expanded when appropriate.
   */
  expansionModel = new SelectionModel<string>(true);

  dragging = false;
  expandTimeout: any;
  expandDelay = 1000;
  width = Constants.Layout.fullWidth;

  /**
   * If true, this signals that when a node is dropped, it should be pushed as a child, instead of
   * spliced as a sibling.
   */
  dragNodePushAsChild = false;

  /**
   * This gets set to true if the millisecondsToNestAsChild === 0. In this case, we default the decision to
   * push or splice to the parent, rather than determining if we should push based on hover time.
   */
  parentDecidesPushOrSplice: boolean = false;

  /** Each time a new node is hovered, this will hold the Date.now() for when that happened. */
  dragHoverStartTime: number = null;

  constructor(
    protected uxService: UxService
  ) {
    super();
  }

  ngOnInit(): void {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<TreeFlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, this.nodes);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.error('data nav tree changes: ', changes);
    if (changes && changes.nodes || changes.nodesChange) {
      if (this.nodes && this.nodes.length > 0) {

        // Pass true so it will update from the passed in nodes change.
        this.updateView(true);
      } else if (this.nodes.length === 0) {
        // This is annoying but for scenarios where user deletes all nodes, it was still showing last node after
        // final delete. Tried setting this.dataSource.data = [] but that didn't fix. The annoying thing is now
        // on first fire of ngOnChanges, before data comes in, it will do this unnecessarily. Since the number of
        // calls to ngOnChanges is predictable on load, maybe I could gate this with a counter?
        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl<TreeFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, this.nodes);
      }
    }

    if (changes && changes.expandSelectedNode) {
      if (this.selectedNode && this.treeControl && this.treeControl.dataNodes && this.treeControl.dataNodes.length > 0) {

        // It's easy to find like this because dataNodes is flat, and no recursion is needed to iterate down children.
        const nodeToExpand = this.treeControl.dataNodes.find(x => x.id === this.selectedNode.id);
        if (nodeToExpand) {

          this.treeControl.expand(nodeToExpand);
          // For some reason, it wasn't adding this to the expansion model, which caused unexpected collapsing in some scenarios.
          this.expansionModel.select(nodeToExpand.id);
          let parent = this.getParentNode(nodeToExpand);

          while (parent) {
            this.treeControl.expand(parent);
            // For some reason, it wasn't adding this to the expansion model, which caused unexpected collapsing in some scenarios.
            this.expansionModel.select(parent.id);
            parent = this.getParentNode(parent);
          }
        }
      }
    }

    if (changes && changes.millisecondsToNestAsChild) {
      if (this.millisecondsToNestAsChild === 0) {
        this.parentDecidesPushOrSplice = true;
      }
    }


    if (changes && changes.selectedNodeNameChange && this.dataSource?.data && this.dataSource.data.length > 0) {

      const name = this.selectedNodeNameChange;
      // Selected node acts as a reference to the position in this.dataSource.data that we would need to edit.
      this.selectedNode.name = name;

      // TODO:  Optimize? This could get expensive because it's likely called on keyup, but it works for now.
      // Related to the issue of the DOM just not updating: https://github.com/angular/components/issues/11381
      this.updateView(true, false);
    }
  }


  /**
   * Performs all steps (tricks) required to make sure the view is updated since there are currently
   * bugs with the dom refreshing for cdk tree when using drag drop.
   * @param updateFromNodesInput
   * @param enforceToggleIcon
   */
  updateView(updateFromNodesInput: boolean = false, enforceToggleIcon = true) {

    if (!this.dataSource) {
      return;
    }

    // Now update datasource
    if (updateFromNodesInput) {
      this.dataSource.data = this.nodes;
    }

    // It's null onInit, so once our data comes in we need to set it, but only the first time.
    if (Helper.equals(this.selectedNode.id, "") && this.dataSource.data && this.dataSource.data.length > 0) {
      this.selectedNode = this.dataSource.data[0];
      this.selectedNodeChange.emit(this.selectedNode);
    }

    // Now close any empty children properties
    if (enforceToggleIcon) {
      this.enforceAlwaysShowToggleIcon();
    }
    this.rebuildTreeForData(this.dataSource.data);
  }


  /**
   *
   * https://github.com/angular/components/issues/11381#issuecomment-562935253
   * @param from
   * @param to
   */
  moveExpansionState(from: TreeFlatNode, to: TreeFlatNode) {
    if (this.treeControl.isExpanded(from)) {
      this.treeControl.collapse(from);
      this.treeControl.expand(to);
    }
  }

  getEnabled() {
    return false;
  }

  /**
   * Toggle the state of the checkbox and emit the event to the parent component.
   */
  toggleEnabled(node: TreeFlatNode) {
    // TODO: check if [disabled] or [mode] is view. The disabled input on drag drop seems broken so this would
    // be a good fix to just exit here if either of those happen

    node.checkBoxEnabled = !node.checkBoxEnabled;

    const mapNode = this.mapNodeLocation(node.id);
    const cargo = { Map: mapNode };
    const payload: EventModel = new EventModel("checkboxClick", null, node, null, cargo);

    this.checkBoxClick.emit(payload);
  }

  /**
   * Expand the node
   * @param id
   */
  onNodeIconClick(id: string) {
    this.expansionModel.toggle(id);
  }


  /**
   * If a node is clicked, map it's location so we can send that to the parent and they can
   * do whatever they need to given that information.
   * @param node
   * @returns
   */
  onNodeClick(node: TreeFlatNode) {
    // console.error('on node click: ', node);

    this.selectedNode = this.findFileNodeById(this.dataSource.data, node.id);
    this.selectedNodeChange.emit(this.selectedNode);
    // Map the location so we can express what level it is on
    const mapNode = this.mapNodeLocation(this.selectedNode.id);

    if (!this.selectedNode) {
      console.error("Unable to find location of the node that was clicked.");
      return;
    }

    const cargo = { Map: mapNode };

    const payload: EventModel = new EventModel("nodeClick", null, this.selectedNode.id, null, cargo);
    this.nodeClick.emit(payload);
  }


  findFileNodeById(fileNodes: TreeNode[], id: string): TreeNode {
    let match = fileNodes.find(x => Helper.equals(x.id, id, true));
    if (match) {
      return match;
    }

    for (let i = 0; i < fileNodes.length; i++) {
      if (fileNodes[i].children && fileNodes[i].children.length > 0) {
        match = this.findFileNodeById(fileNodes[i].children, id);

        if (match) {
          break;
        }
      }
    }

    return match;
  }


  // Transforms a node to a flat node.
  transformer = (node: TreeNode, level: number) => {
    return new TreeFlatNode(!!node.children, node.name, level, node.type, node.id, node.checkBoxEnabled);
  };

  private _getLevel = (node: TreeFlatNode) => {
    return node.level;
  };
  private _isExpandable = (node: TreeFlatNode) => node.expandable;
  private _getChildren = (node: TreeNode): Observable<TreeNode[]> => observableOf(node.children);
  hasChild = (_: number, _nodeData: TreeFlatNode) => _nodeData.expandable;

  // shouldValidate(event: MatCheckboxChange): void {
  //   this.validateDrop = event.checked;
  // }

  /**
   * This constructs an array of nodes that matches the DOM
   */
  visibleNodes(): TreeNode[] {
    const result = [];

    function addExpandedChildren(node: TreeNode, expanded: string[]) {
      result.push(node);
      if (expanded.includes(node.id)) {
        if (node.children && node.children.length > 0) {
          node.children.map((child) => addExpandedChildren(child, expanded));
        }
      }
    }
    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected);
    });
    return result;
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    // console.error('drop event: ', event);

    // TODO: check if [disabled] or [mode] is view. The disabled input on drag drop seems broken so this would
    // be a good fix to just exit here if either of those happen

    // They dropped it into the same position, we don't need to do anything
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    // The parent component needs to mimic this drop in their own data set, so these are used
    // to map the drop so it's possible.
    let startMap: number[] = [];
    let endMap: number[] = [];

    startMap = this.mapNodeLocation(event.item.data.id);

    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) { return; }

    // construct a list of visible nodes, this will match the DOM. the cdkDragDrop event.currentIndex jives with
    // visible nodes. it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes();

    // deep clone the data source so we can mutate it
    const changedData = JSON.parse(JSON.stringify(this.dataSource.data));

    // recursive find function to find siblings of node
    function findNodeSiblings(arr: Array<any>, id: string): Array<any> {
      let result, subResult;
      arr.forEach((item, i) => {
        if (item.id === id) {
          result = arr;
        } else if (item.children) {
          subResult = findNodeSiblings(item.children, id);
          if (subResult) { result = subResult; }
        }
      });
      return result;
    }

    // determine where to insert the node
    const nodeAtDest = visibleNodes[event.currentIndex];
    const newSiblings = findNodeSiblings(changedData, nodeAtDest.id);
    if (!newSiblings) { return; }
    const insertIndex = newSiblings.findIndex(s => s.id === nodeAtDest.id);
    const node = event.item.data;
    const siblings = findNodeSiblings(changedData, node.id);
    const siblingIndex = siblings.findIndex(n => n.id === node.id);

    // remove it and hold onto it so we can reinsert in correct location
    const nodeToInsert: TreeNode = siblings.splice(siblingIndex, 1)[0];
    if (nodeAtDest.id === nodeToInsert.id) { return; }

    // ensure validity of drop - must be same level
    let relativeIndex = event.currentIndex; // default if no parent
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find((n) => nodeAtDest.id === n.id);

    // This wasn't 'same level drop' per se, but I think it only let you rearrange in place,
    // not drop it to another parent at the same level.
    // if (this.sameLevelDropsOnly && nodeAtDestFlatNode.level !== node.level) {
    // alert('Items can only be moved within the same level.');
    // return;
    // }

    const parent = this.getParentNode(nodeAtDestFlatNode);
    if (parent) {
      const parentIndex = visibleNodes.findIndex(n => n.id === parent.id) + 1;
      relativeIndex = event.currentIndex - parentIndex;
    }

    // Map the location so we can express what level it is on
    const mapNode = this.mapNodeLocation(newSiblings[0].id);

    // Parent tells us if we should push or splice based on the info we provide
    if (this.parentDecidesPushOrSplice) {
      const validateResult = this.validateDropCallback({ Action: "", Level: mapNode.length, DragNodeId: nodeToInsert.id });

      if (validateResult === "push") {
        const indexOfParent = newSiblings.findIndex(element => element.id === nodeAtDest.id);

        // Since children may be undefined depending on how icons are being handled, make sure we initialize it before pushing
        if (!newSiblings[indexOfParent].children) {
          newSiblings[indexOfParent].children = [];
        }
        newSiblings[indexOfParent].children.push(nodeToInsert);
      } else if (validateResult === "splice") {
        newSiblings.splice(insertIndex, 0, nodeToInsert);
      } else {
        // Parent declined drop
        return;
      }
    } else {
      // Drag was hovered over node for the appropriate time, ask parent if it's okay to push at this location.
      if (this.dragNodePushAsChild) {
        const indexOfParent = newSiblings.findIndex(element => element.id === nodeAtDest.id);

        const validateResult = this.validateDropCallback({ Action: "push", Level: mapNode.length, DragNodeId: nodeToInsert.id });

        // Ask the parent component if we can go through with the drop.
        if (validateResult === "notValid") {
          // Uncomment if needed for testing purposes.
          // this.uxService.modal.alertDanger("Error", "Can't push");
          return;
        }

        // Since children may be undefined depending on how icons are being handled, make sure we initialize it before pushing
        if (!newSiblings[indexOfParent].children) {
          newSiblings[indexOfParent].children = [];
        }

        newSiblings[indexOfParent].children.push(nodeToInsert);
      } else {

        if (!this.validateDropCallback({ Action: "splice", Level: mapNode.length, DragNodeId: nodeToInsert.id })) {
          // Uncomment if needed for testing purposes.
          // this.uxService.modal.alertDanger("Error", "Can't splice.");
          return;
        }

        newSiblings.splice(insertIndex, 0, nodeToInsert);
      }
    }

    this.dragNodePushAsChild = false;
    this.rebuildTreeForData(changedData);
    endMap = this.mapNodeLocation(event.item.data.id);
    this.updateView();

    const cargo = { StartMap: startMap, EndMap: endMap };
    const payload: EventModel = new EventModel("nodeDrop", null, null, null, cargo);
    this.nodeDrop.emit(payload);
  }


  /**
   * This will enforce the 'alwaysShowToggleIcon' input. If it's true, then this will make
   * all children have an initialized array. If it is false, this will make sure that
   * any empty children arrays are nulled, and the expansion model is updated to reflect
   * the change.
   *
   * This is needed because in the HTML, it calls treeControl.isExpanded and passes it the node.
   * If it returns true, then we show an icon. If it returns false, we hide the icon. I tested this
   * and found it will return true if children is initialized, but empty. So that's why to hide
   * or show the toggle icons, we need to manually null or initialize children depending on
   * what the parent wants.
   * @returns
   */
  enforceAlwaysShowToggleIcon() {

    this.dataSource.data.forEach((data) => {

      // If it's initialized, but empty, and parent doesn't want to always show toggle icon
      if (data.children && data.children.length === 0 && !this.alwaysShowToggleIcon) {

        // Setting to undefined will cause toggle icon not to show once UI is refreshed.
        data.children = undefined;

        const flatNode = this.treeControl.dataNodes.find(x => x.id === data.id);
        if (flatNode) {
          // Manually deselect since we know it's not possible for it to be open now.
          // Otherwise it will show up as a 'visibleNode' during the drop method.
          this.expansionModel.deselect(data.id);
        }
      } else if (!data.children && this.alwaysShowToggleIcon) {
        // Setting to empty array will cause toggle icon to show once UI is refreshed.
        data.children = [];
      } else if (data.children && data.children.length > 0) {
        this.recurseAndNullChildren(data.children);
      }
    });
  }


  recurseAndNullChildren(nodes: TreeNode[]) {
    // console.error('recursion check');
    nodes.forEach((node) => {
      if (node.children && node.children.length === 0 && !this.alwaysShowToggleIcon) {
        node.children = undefined;

        const flatNode = this.treeControl.dataNodes.find(x => x.id === node.id);
        if (flatNode) {
          this.expansionModel.deselect(node.id);
        }
      } else if (!node.children && this.alwaysShowToggleIcon) {
        node.children = [];
      } else if (node.children && node.children.length > 0) {
        this.recurseAndNullChildren(node.children);
      }
    });
  }


  /**
   * Finds the location of a node and expresses it as an array of indexes [3, 1, 0] = nodes[3].children[1].children[0].
   * @param id the id of the node to be mapped
   * @returns array of indexes, ex: [3, 1, 0]
   */
  mapNodeLocation(id: string = ""): number[] {
    const mapNode: number[] = [];
    const dropNodeId = id;
    let index = 0;
    let level = null;
    let nodeFound = false;

    // Once Node is found, increment index until a level change. Once level changes, current index
    // becomes it's position at that level.
    for (let i = this.treeControl.dataNodes.length - 1; i >= 0; i--) {

      if (nodeFound) {
        if (this.treeControl.dataNodes[i].level === level) {
          index++;
        } else if (this.treeControl.dataNodes[i].level < level) {
          mapNode.push(index);
          index = 0;
          level = this.treeControl.dataNodes[i].level;
        }
      }

      if (i === 0) {
        mapNode.push(index);
        mapNode.reverse();
      }

      if (!nodeFound && Helper.equals(this.treeControl.dataNodes[i].id, dropNodeId)) {
        level = this.treeControl.dataNodes[i].level;
        nodeFound = true;
      }
    }

    return mapNode;
  }


  /**
   * The original designer was using these to try and have nodes automatically open when hovered, but it was
   * buggy so I didn't use it. Instead it is used to calculate hover time.
   */
  dragStart() {
    this.dragging = true;
    this.dragNodePushAsChild = false;
  }
  dragEnd() {
    this.dragging = false;
    this.dragHoverStartTime = null;
  }

  dragHover(node: TreeFlatNode, recursive: boolean = false) {

    // Not needed if the parent wants control over pushing or splicing.
    if (this.parentDecidesPushOrSplice) {
      return;
    }

    if (!recursive) {
      this.dragHoverStartTime = Date.now();
    }

    // Hovering over a node for 'this.milisecondsToNestAsChild' or more will allow it to nest as a
    // child. This is necessary because otherwise you can't nest into a
    // parent that currently has no children.
    if (this.dragging) {
      const currentTime = Date.now();
      const hoverTime = currentTime - this.dragHoverStartTime;

      if (hoverTime > this.millisecondsToNestAsChild) {
        this.dragNodePushAsChild = true;
        // Now 'node' is whatever we are currently hovering over. SO somehow we need
        // to give it a temporary 'Junk' child or something so it expands.
      } else {
        this.dragNodePushAsChild = false;
      }
    } else {
      // this.dragNodePushAsChild = false;
      return;
    }

    // Recurse every 100 miliseconds so we don't call it thousands of times
    setTimeout(() => {
      this.dragHover(node, true);
    }, 100);
  }

  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }

  /**
   * The following methods are for persisting the tree expand state
   * after being rebuilt
   */
  rebuildTreeForData(data: any) {
    if (data) {
      this.dataSource.data = data;
    }

    this.expansionModel.selected.forEach((id) => {
      const node = this.treeControl.dataNodes.find((n) => n.id === id);
      this.treeControl.expand(node);
    });
  }

  /**
   * Not used but you might need this to programmatically expand nodes
   * to reveal a particular node
   */
  private expandNodesById(flatNodes: TreeFlatNode[], ids: string[]) {
    if (!flatNodes || flatNodes.length === 0) { return; }
    const idSet = new Set(ids);
    return flatNodes.forEach((node) => {
      if (idSet.has(node.id)) {
        this.treeControl.expand(node);
        let parent = this.getParentNode(node);
        while (parent) {
          this.treeControl.expand(parent);
          parent = this.getParentNode(parent);
        }
      }
    });
  }

  private getParentNode(node: TreeFlatNode): TreeFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

}


/**
 * Tree flattener to convert a normal type of node to node with children & level information.
 * Transform nested nodes of type `T` to flattened nodes of type `F`.
 *
 * For example, the input data of type `T` is nested, and contains its children data:
 *   SomeNode: {
 *     key: 'Fruits',
 *     children: [
 *       NodeOne: {
 *         key: 'Apple',
 *       },
 *       NodeTwo: {
 *        key: 'Pear',
 *      }
 *    ]
 *  }
 *  After flattener flatten the tree, the structure will become
 *  SomeNode: {
 *    key: 'Fruits',
 *    expandable: true,
 *    level: 1
 *  },
 *  NodeOne: {
 *    key: 'Apple',
 *    expandable: false,
 *    level: 2
 *  },
 *  NodeTwo: {
 *   key: 'Pear',
 *   expandable: false,
 *   level: 2
 * }
 * and the output flattened type is `F` with additional information.
 */
export class MatTreeFlattener<T, F, K = F> {
  constructor(
    public transformFunction: (node: T, level: number) => F,
    public getLevel: (node: F) => number,
    public isExpandable: (node: F) => boolean,
    public getChildren: (node: T) => Observable<T[]> | T[] | undefined | null,
  ) { }

  _flattenNode(node: T, level: number, resultNodes: F[], parentMap: boolean[]): F[] {
    const flatNode = this.transformFunction(node, level);
    resultNodes.push(flatNode);

    if (this.isExpandable(flatNode)) {
      const childrenNodes = this.getChildren(node);
      if (childrenNodes) {
        if (Array.isArray(childrenNodes)) {
          this._flattenChildren(childrenNodes, level, resultNodes, parentMap);
        } else {
          childrenNodes.pipe(take(1)).subscribe(children => {
            this._flattenChildren(children, level, resultNodes, parentMap);
          });
        }
      }
    }
    return resultNodes;
  }

  _flattenChildren(children: T[], level: number, resultNodes: F[], parentMap: boolean[]): void {
    children.forEach((child, index) => {
      const childParentMap: boolean[] = parentMap.slice();
      childParentMap.push(index !== children.length - 1);
      this._flattenNode(child, level + 1, resultNodes, childParentMap);
    });
  }

  /**
   * Flatten a list of node type T to flattened version of node F.
   * Please note that type T may be nested, and the length of `structuredData` may be different
   * from that of returned list `F[]`.
   */
  flattenNodes(structuredData: T[]): F[] {
    const resultNodes: F[] = [];
    structuredData.forEach(node => this._flattenNode(node, 0, resultNodes, []));
    return resultNodes;
  }

  /**
   * Expand flattened node with current expansion status.
   * The returned list may have different length.
   */
  expandFlattenedNodes(nodes: F[], treeControl: TreeControl<F, K>): F[] {
    const results: F[] = [];
    const currentExpand: boolean[] = [];
    currentExpand[0] = true;

    nodes.forEach(node => {
      let expand = true;
      for (let i = 0; i <= this.getLevel(node); i++) {
        expand = expand && currentExpand[i];
      }
      if (expand) {
        results.push(node);
      }
      if (this.isExpandable(node)) {
        currentExpand[this.getLevel(node) + 1] = treeControl.isExpanded(node);
      }
    });
    return results;
  }
}


/**
 * Data source for flat tree.
 * The data source need to handle expansion/collapsion of the tree node and change the data feed
 * to `MatTree`.
 * The nested tree nodes of type `T` are flattened through `MatTreeFlattener`, and converted
 * to type `F` for `MatTree` to consume.
 */
export class MatTreeFlatDataSource<T, F, K = F> extends DataSource<F> {
  private readonly _flattenedData = new BehaviorSubject<F[]>([]);
  private readonly _expandedData = new BehaviorSubject<F[]>([]);

  get data() {
    return this._data.value;
  }
  set data(value: T[]) {
    this._data.next(value);
    this._flattenedData.next(this._treeFlattener.flattenNodes(this.data));
    this._treeControl.dataNodes = this._flattenedData.value;
  }
  private readonly _data = new BehaviorSubject<T[]>([]);

  constructor(
    private _treeControl: FlatTreeControl<F, K>,
    private _treeFlattener: MatTreeFlattener<T, F, K>,
    initialData?: T[],
  ) {
    super();

    if (initialData) {
      // Assign the data through the constructor to ensure that all of the logic is executed.
      this.data = initialData;
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<F[]> {
    return merge(
      collectionViewer.viewChange,
      this._treeControl.expansionModel.changed,
      this._flattenedData,
    ).pipe(
      map(() => {
        this._expandedData.next(
          this._treeFlattener.expandFlattenedNodes(this._flattenedData.value, this._treeControl),
        );
        return this._expandedData.value;
      }),
    );
  }

  disconnect() {
    // no op
  }
}

