/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { UxService } from '../../services/ux.service';

enum UnitCode {
  Day = 4,
  Hour = 3,
  Minute = 2,
  Second = 1,
  Ms = 0
}

type ChronoUnit = {
  code: UnitCode;
  nextUnitUp: UnitCode;
  nextUnitDown: UnitCode;
  ratioToLargerUnit: number;
};

const MsPerSecond = 1000;
const SecondsPerMinute = 60;
const MinutesPerHour = 60;
const HoursPerDay = 24;

const Units: Map<UnitCode, ChronoUnit> = new Map<UnitCode, ChronoUnit>();
Units.set(UnitCode.Ms, {
  code: UnitCode.Ms,
  nextUnitUp: UnitCode.Second,
  nextUnitDown: null,
  ratioToLargerUnit: MsPerSecond
});
Units.set(UnitCode.Second, {
  code: UnitCode.Second,
  nextUnitUp: UnitCode.Second,
  nextUnitDown: UnitCode.Ms,
  ratioToLargerUnit: SecondsPerMinute
});
Units.set(UnitCode.Second, {
  code: UnitCode.Second,
  nextUnitUp: UnitCode.Minute,
  nextUnitDown: UnitCode.Ms,
  ratioToLargerUnit: SecondsPerMinute
});
Units.set(UnitCode.Minute, {
  code: UnitCode.Minute,
  nextUnitUp: UnitCode.Hour,
  nextUnitDown: UnitCode.Second,
  ratioToLargerUnit: MinutesPerHour
});
Units.set(UnitCode.Hour, {
  code: UnitCode.Hour,
  nextUnitUp: UnitCode.Day,
  nextUnitDown: UnitCode.Minute,
  ratioToLargerUnit: HoursPerDay
});
Units.set(UnitCode.Day, {
  code: UnitCode.Day,
  nextUnitUp: null,
  nextUnitDown: UnitCode.Hour,
  ratioToLargerUnit: null
});

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputDurationComponent),
  multi: true
};

// NOTE: ngb suggests that the probably we want the below providers in our main app module.
@Component({
  selector: 'ib-input-duration',
  templateUrl: './input-duration.component.html',
  styleUrls: ['./input-duration.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputDurationComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  /**
   * A string which determines the base unit for the value. The value is always returned in this unit.
   * No fields will be shown that for units smaller than this one.
   */
  @Input() public targetUnit: "ms" | "second" | "minute" | "hour" | "day" = "ms";

  /**
   * A string which determines the largest unit to show a field for.
   */
  @Input() public largestUnit: "ms" | "second" | "minute" | "hour" | "day";

  targetUnitCode: UnitCode;
  largestUnitCode: UnitCode;

  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;
  ms: number = 0;

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
    this.configure();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    // configure is called from ngOnChanges in the InputBaseComponent
    // this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    // Causes the base class to apply input-group styles to the template, while suffixText is never rendered.
    if (!this.suffixText) {
      this.suffixText = "suffix";
    }

    this.targetUnitCode = this.stringToUnitCode(this.targetUnit, UnitCode.Ms);
    this.largestUnitCode = this.stringToUnitCode(this.largestUnit || this.targetUnit, this.targetUnitCode);

    // Handle largestUnit < targetUnit
    if (this.largestUnitCode < this.targetUnitCode) {
      Log.errorMessage(`Largest unit (${this.largestUnit}) cannot be smaller than target unit (${this.targetUnit}). Setting largest to target.`);
      this.largestUnitCode = this.targetUnitCode;
    }
  }

  // get accessor
  get value(): any {
    // make all values available by UnitCode
    const unitQtys: Map<UnitCode, number> = new Map();
    unitQtys.set(UnitCode.Day, this.days || 0);
    unitQtys.set(UnitCode.Hour, this.hours || 0);
    unitQtys.set(UnitCode.Minute, this.minutes || 0);
    unitQtys.set(UnitCode.Second, this.seconds || 0);
    unitQtys.set(UnitCode.Ms, this.ms || 0);

    // convert each unit to next unit until reaching target, summing along the way.
    let currentUnit = Units.get(UnitCode.Day);
    let sum = 0;
    do {
      sum += unitQtys.get(currentUnit.code);
      if (currentUnit.code > this.targetUnitCode) {
        // move to lower unit for conversion
        currentUnit = Units.get(currentUnit.nextUnitDown);
        sum *= currentUnit.ratioToLargerUnit;
      } else { break; }
    } while (currentUnit.code >= this.targetUnitCode);

    return sum;
  };

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.handleNewValue(v);
      this.reportChanges();
    }
  }

  writeValue(v: number) {
    super.writeValue(v);
    this.handleNewValue(v);
  }


  handleNewValue(val: number) {
    // Find the conversion from target unit to largest unit
    let conversion = 1;
    let currentUnit = Units.get(this.targetUnitCode);
    while (currentUnit.nextUnitUp && currentUnit.code < this.largestUnitCode) {
      conversion *= currentUnit.ratioToLargerUnit;
      currentUnit = Units.get(currentUnit.nextUnitUp);
    }

    // Iteratively pull out the next largest unit from the total without passing the target unit.
    // Use absolute value of val to get correct units.
    let remainder = Math.abs(val);
    const unitQtys: Map<UnitCode, number> = new Map();
    while (currentUnit.code > this.targetUnitCode) {
      // extract the qty of full units
      const unitQty = Math.floor(remainder / conversion);
      remainder = remainder % conversion;
      unitQtys.set(currentUnit.code, unitQty);

      // get conversion from next largest unit to smallest unit
      currentUnit = Units.get(currentUnit.nextUnitDown);
      conversion /= currentUnit.ratioToLargerUnit;
    }

    // We have arrived at the target unit, so dump remaining into target unit
    unitQtys.set(currentUnit.code, remainder);

    // Set component variables to match unit qtys
    this.days = this.normalizeUnitValue(val, unitQtys.get(UnitCode.Day));
    this.hours = this.normalizeUnitValue(val, unitQtys.get(UnitCode.Hour));
    this.minutes = this.normalizeUnitValue(val, unitQtys.get(UnitCode.Minute));
    this.seconds = this.normalizeUnitValue(val, unitQtys.get(UnitCode.Second));
    this.ms = this.normalizeUnitValue(val, unitQtys.get(UnitCode.Ms));

  };

  private normalizeUnitValue(totalVal: number, unitVal: number) {
    if (!unitVal) { return 0; }
    if (totalVal < 0 && unitVal > 0) { return unitVal * -1; } else { return unitVal; }
  }

  reportChanges() {
    this.onChangeCallback(this.value);
  }

  /**
   * Determines whether or not the field for the given unit should be rendered.
   * @param unit The string representing the unit
  */
  showUnitField(unit: string): boolean {
    const code = this.stringToUnitCode(unit, null);
    if (code != null) {
      return this.largestUnitCode >= code && this.targetUnitCode <= code;
    } else { return false; }
  }

  /**
   * Determines the UnitCode corresponding to val, or fallback if none is found.
   * @param val
   * @param fallback
   */
  private stringToUnitCode(val: string, fallback: UnitCode): UnitCode {
    if (!Helper.equals(val, 'ms', true) && Helper.endsWith(val, 's', true)) {
      val = val.slice(0, -1);
    }
    if (Helper.equals("Ms", val, true)) {
      return UnitCode.Ms;
    } else if (Helper.equals("Second", val, true)) {
      return UnitCode.Second;
    } else if (Helper.equals("Minute", val, true)) {
      return UnitCode.Minute;
    } else if (Helper.equals("Hour", val, true)) {
      return UnitCode.Hour;
    } else if (Helper.equals("Day", val, true)) {
      return UnitCode.Day;
    } else {
      Log.errorMessage("Invalid value for ChronoUnit: " + val + ". Using " + fallback + " as fallback.");
      return fallback;
    }
  }
}

