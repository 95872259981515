import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { UxService } from '../../services/ux.service';

@Component({
  selector: 'ib-diff-display',
  templateUrl: './diff-display.component.html',
  styleUrls: ['./diff-display.component.css']
})
export class DiffDisplayComponent extends BaseComponent implements OnInit, OnChanges {

  // See https://github.com/AXeL-dev/ngx-diff2html/blob/master/projects/ngx-diff2html/src/lib/ngx-diff2html.component.ts
  @Input() private left: string;
  @Input() private right: string;
  @Input() private filename: string = '';

  @Input() format: 'side-by-side' | 'line-by-line' = 'line-by-line';
  @Input() style: 'word' | 'char' = 'word';

  @Input() allowFormatSelection: boolean = true;
  @Input() allowStyleSelection: boolean = true;

  @Output() diffChange: EventEmitter<string> = new EventEmitter();

  private diff: string = null;
  diffHtml: string = null;


  constructor(protected uxService: UxService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.diffGet();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (this.inputHasChanged(changes.left) || this.inputHasChanged(changes.right)) {
      this.diffGet();
    } else if (this.inputHasChanged(changes.style) || this.inputHasChanged(changes.format)) {
      this.refreshDiffHtml();
    }
  }



  diffGet() {
    this.diff = this.uxService.diffGet(this.left, this.right, this.filename);
    this.refreshDiffHtml();
    this.diffChange.emit(this.diff);
    // console.error(this.diff);
  }

  refreshDiffHtml() {
    this.diffHtml = this.uxService.diffToHtml(this.diff, this.format, this.style);
  }


  setFormat(newFormat: 'side-by-side' | 'line-by-line') {
    this.format = newFormat;
    this.refreshDiffHtml();
  }

  setStyle(newStyle: 'word' | 'char') {
    this.style = newStyle;
    this.refreshDiffHtml();
  }

}
