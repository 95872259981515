import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5telecom from "projects/core-lib/src/lib/models/ngModelsTelecom5";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";


export class ApiModuleTelecom {


  // #region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModuleTelecom).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModuleTelecom[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModuleTelecom[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModuleTelecom.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModuleTelecom.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  // #endregion Helper Functions


  // #region System APIs


  public static NumberingPlan(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("NumberingPlan");
    api.version = version;
    api.documentation.objectDescription = "Numbering Plan";
    api.documentation.objectPrimaryKey = "TelecomNumberingPlanId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalTelecomNumberingPlanId", "TelecomCountryCode"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TelecomNumberingPlan;
    api.documentation.requestAndResponseDataModelObject = new m5telecom.TelecomNumberingPlanEditViewModel();
    api.documentation.documentationUrlBase = "/telecom/numbering-plan/";
    api.documentation.securityAccessArea = Constants.AccessArea.TelecomNumberingPlan;
    api.pathVariables = "{telecomNumberingPlanId}";
    api.pathModelProperties = "TelecomNumberingPlanId";
    api.cacheName = "telecom";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.impactedPickListIds = [Constants.PickList._TelecomNumberingPlan];
    api.useStandardEndpoints(`/${m.RouteSegment.Telecom}/${m.RouteSegment.NumberingPlans}`, null, null, true);
    return api;
  };


  public static LocationProfile(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("LocationProfile");
    api.version = version;
    api.documentation.objectDescription = "Location Profile";
    api.documentation.objectPrimaryKey = "TelecomLocationProfileId";
    api.documentation.objectDescriptionPropertyNames = ["Description", "ExternalTelecomNumberingPlanId", "TelecomCountryCode"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TelecomLocationProfile;
    api.documentation.requestAndResponseDataModelObject = new m5telecom.TelecomLocationProfileEditViewModel();
    api.documentation.documentationUrlBase = "/telecom/location-profile/";
    api.documentation.securityAccessArea = Constants.AccessArea.TelecomLocationProfile;
    api.pathVariables = "{telecomLocationProfileId}";
    api.pathModelProperties = "TelecomLocationProfileId";
    api.cacheName = "telecom";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.impactedPickListIds = [Constants.PickList._TelecomLocationProfile];
    api.useStandardEndpoints(`/${m.RouteSegment.Telecom}/${m.RouteSegment.LocationProfiles}`, null, null, true);
    return api;
  };


  public static LocationStandard(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("LocationStandard");
    api.version = version;
    api.documentation.objectDescription = "Standard Location";
    api.documentation.objectPrimaryKey = "TelecomLocationStandardId";
    api.documentation.objectDescriptionPropertyNames = ["Location", "City"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TelecomLocationStandard;
    api.documentation.requestAndResponseDataModelObject = new m5telecom.TelecomLocationStandardEditViewModel();
    api.documentation.documentationUrlBase = "/telecom/location/standard/";
    api.documentation.securityAccessArea = Constants.AccessArea.TelecomLocationStandard;
    api.pathVariables = "{telecomLocationStandardId}";
    api.pathModelProperties = "TelecomLocationStandardId";
    api.cacheName = "telecom";
    api.cacheLevel = CacheLevel.PseudoStatic;
    api.useStandardEndpoints(`/${m.RouteSegment.Telecom}/${m.RouteSegment.Locations}/${m.RouteSegment.Standard}`, null, null, true);
    return api;
  };


  public static LocationCustom(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("LocationCustom");
    api.version = version;
    api.documentation.objectDescription = "Custom Location";
    api.documentation.objectPrimaryKey = "TelecomLocationCustomId";
    api.documentation.objectDescriptionPropertyNames = ["Location", "City"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TelecomLocationCustom;
    api.documentation.requestAndResponseDataModelObject = new m5telecom.TelecomLocationCustomEditViewModel();
    api.documentation.documentationUrlBase = "/telecom/location/custom/";
    api.documentation.securityAccessArea = Constants.AccessArea.TelecomLocationCustom;
    api.pathVariables = "{telecomLocationCustomId}";
    api.pathModelProperties = "TelecomLocationCustomId";
    api.cacheName = "telecom";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.Telecom}/${m.RouteSegment.Locations}/${m.RouteSegment.Custom}`, null, null, true);
    return api;
  };


  public static LocationGroupList(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("LocationGroupList");
    api.version = version;
    api.documentation.objectDescription = "Location Group List";
    api.documentation.objectPrimaryKey = "TelecomLocationGroupListId";
    api.documentation.objectDescriptionPropertyNames = ["Location", "State"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TelecomLocationGroupList;
    api.documentation.requestAndResponseDataModelObject = new m5telecom.TelecomLocationGroupListEditViewModel();
    api.documentation.documentationUrlBase = "/telecom/location/group/list/";
    api.documentation.securityAccessArea = Constants.AccessArea.TelecomLocationGroupList;
    api.pathVariables = "{telecomLocationGroupListId}";
    api.pathModelProperties = "TelecomLocationGroupListId";
    api.cacheName = "telecom";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.Telecom}/${m.RouteSegment.LocationGroups}/${m.RouteSegment.Lists}`, null, null, true);
    return api;
  };


  public static LocationGroupLink(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("LocationGroupLink");
    api.version = version;
    api.documentation.objectDescription = "Location Group Link";
    api.documentation.objectPrimaryKey = "TelecomLocationGroupLinkId";
    api.documentation.objectDescriptionPropertyNames = ["SourceGroupNumber", "CallingPlan"];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.TelecomLocationGroupLink;
    api.documentation.requestAndResponseDataModelObject = new m5telecom.TelecomLocationGroupLinkEditViewModel();
    api.documentation.documentationUrlBase = "/telecom/location/group/link/";
    api.documentation.securityAccessArea = Constants.AccessArea.TelecomLocationGroupLink;
    api.pathVariables = "{telecomLocationGroupLinkId}";
    api.pathModelProperties = "TelecomLocationGroupLinkId";
    api.cacheName = "telecom";
    api.cacheLevel = CacheLevel.ChangesInfrequently;
    api.useStandardEndpoints(`/${m.RouteSegment.Telecom}/${m.RouteSegment.LocationGroups}/${m.RouteSegment.Links}`, null, null, true);
    return api;
  };





  // #endregion

}
