// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star {
  position: relative;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #d3d3d3;
}

.full {
  color: black;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/star-rating/star-rating.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".star {\r\n  position: relative;\r\n  display: inline-block;\r\n  font-size: 1.25rem;\r\n  line-height: 1.25rem;\r\n  color: #d3d3d3;\r\n}\r\n\r\n.full {\r\n  color: black;\r\n}\r\n\r\n.half {\r\n  position: absolute;\r\n  display: inline-block;\r\n  overflow: hidden;\r\n  color: black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
