// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*attachment management component*/
.attachment-management {
}

  .attachment-management .attachment-title {
    width: 90%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .attachment-management .icon-container {
    display: block;
    margin: 5px;
    text-align: center;
  }

    .attachment-management .icon-container a {
      color: black;
    }

  .attachment-management .icon {
    width: 150px;
    height: 150px;
  }

    .attachment-management .icon img {
      max-width: 150px;
      max-height: 150px;
    }

  .attachment-management .icon-lg {
    width: 250px;
    height: 250px;
  }

    .attachment-management .icon-lg img {
      max-width: 250px;
      max-height: 250px;
    }

  .attachment-management .icon-xl {
    width: 350px;
    height: 350px;
  }

    .attachment-management .icon-xl img {
      max-width: 350px;
      max-height: 350px;
    }
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/file/file-management/file-management.component.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;AACA;;EAEE;IACE,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;EACzB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,kBAAkB;EACpB;;IAEE;MACE,YAAY;IACd;;EAEF;IACE,YAAY;IACZ,aAAa;EACf;;IAEE;MACE,gBAAgB;MAChB,iBAAiB;IACnB;;EAEF;IACE,YAAY;IACZ,aAAa;EACf;;IAEE;MACE,gBAAgB;MAChB,iBAAiB;IACnB;;EAEF;IACE,YAAY;IACZ,aAAa;EACf;;IAEE;MACE,gBAAgB;MAChB,iBAAiB;IACnB","sourcesContent":["/*attachment management component*/\r\n.attachment-management {\r\n}\r\n\r\n  .attachment-management .attachment-title {\r\n    width: 90%;\r\n    text-align: center;\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n  }\r\n\r\n  .attachment-management .icon-container {\r\n    display: block;\r\n    margin: 5px;\r\n    text-align: center;\r\n  }\r\n\r\n    .attachment-management .icon-container a {\r\n      color: black;\r\n    }\r\n\r\n  .attachment-management .icon {\r\n    width: 150px;\r\n    height: 150px;\r\n  }\r\n\r\n    .attachment-management .icon img {\r\n      max-width: 150px;\r\n      max-height: 150px;\r\n    }\r\n\r\n  .attachment-management .icon-lg {\r\n    width: 250px;\r\n    height: 250px;\r\n  }\r\n\r\n    .attachment-management .icon-lg img {\r\n      max-width: 250px;\r\n      max-height: 250px;\r\n    }\r\n\r\n  .attachment-management .icon-xl {\r\n    width: 350px;\r\n    height: 350px;\r\n  }\r\n\r\n    .attachment-management .icon-xl img {\r\n      max-width: 350px;\r\n      max-height: 350px;\r\n    }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
