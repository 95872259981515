import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";

@Component({
  selector: 'ib-input-asset-license-select-display',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-asset-license-select-display.component.html',
  styleUrls: ['./input-asset-license-select-display.component.css']
})
export class InputAssetLicenseSelectDisplayComponent implements OnInit {

  @Input() license: m5.AssetLicenseViewModel = null;

  constructor() { }

  ngOnInit() {
  }

  onOpenUrl($event, url: string) {

    if (!url) {
      return;
    }

    if (!url.startsWith("http")) {
      url = "http://" + url;
    }
    const win = window.open(url, '_blank');
    win.focus();

    // User clicked a url so don't propagate this event since we don't want url click to select the object
    try {
      $event.stopPropagation();
      $event.preventDefault();
    } catch (err) { }

  }

}
