// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pre {
  margin-bottom: 0;
}

code {
  font-size: 90%; /*11px!important;*/
}

  code.small {
    font-size: 80%;
  }
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/dev/code-block/code-block.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc,EAAE,kBAAkB;AACpC;;EAEE;IACE,cAAc;EAChB","sourcesContent":["pre {\r\n  margin-bottom: 0;\r\n}\r\n\r\ncode {\r\n  font-size: 90%; /*11px!important;*/\r\n}\r\n\r\n  code.small {\r\n    font-size: 80%;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
