import { Injectable } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BaseService } from './base.service';
import { ApiService } from '../api/api.service';
import { AppService } from './app.service';
import { AppCacheService } from './app-cache.service';
import { Router } from '@angular/router';
import { StaticPickList } from '../models/model-helpers';
import { AsyncSubject, BehaviorSubject, Observable, of } from 'rxjs';
import { ApiCall, ApiOperationType, ApiProperties, CacheLevel, IApiResponseWrapper, IApiResponseWrapperTyped, Query } from '../api/ApiModels';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { ApiHelper } from '../api/ApiHelper';
import { takeUntil } from 'rxjs/operators';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';

@Injectable({
  providedIn: 'root'
})
export class TableService extends BaseService {

  private tableConfigSubject = new BehaviorSubject<m5web.TableConfigurationViewModel[]>([]);
  getTableConfig() { return this.tableConfigSubject.asObservable(); }
  private tableConfig: m5web.TableConfigurationViewModel[] = [];
  private tableConfigLoaded: boolean = false;
  private tableConfigApiProperties: ApiProperties = null;
  private tableConfigApiReadCall: ApiCall = null;
  private tableConfigApiAddCall: ApiCall = null;
  private tableConfigApiEditCall: ApiCall = null;
  private tableConfigApiDeleteCall: ApiCall = null;
  private tableConfigQuery: Query = null;

  public globalTableConfigurations: m5web.TableConfigurationViewModel[] = [];
  public get hasGlobalTableConfigurations(): boolean {
    if (!this.globalTableConfigurations) {
      this.globalTableConfigurations = [];
    }
    return (this.globalTableConfigurations.length > 0);
  }

  protected cacheName = "TableConfig";
  protected cacheKey = "TableConfigObjectArray";



  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer,
    protected router: Router) {

    super();

    try {
      this.refreshTableConfig();
    } catch (err) {
      Log.errorMessage("Exception refreshing table config from service constructor");
      Log.errorMessage(err);
    }


  }


  configApi() {
    if (!this.tableConfigApiProperties) {
      this.tableConfigApiProperties = ApiModuleWeb.TableConfiguration();
    }
    if (!this.tableConfigApiReadCall) {
      this.tableConfigApiReadCall = ApiHelper.createApiCall(this.tableConfigApiProperties, ApiOperationType.List);
      this.tableConfigApiReadCall.silent = true;
      this.tableConfigApiReadCall.cacheIgnoreOnRead = true;
      this.tableConfigApiReadCall.cacheIgnoreOnWrite = true;
    }
    if (!this.tableConfigApiAddCall) {
      this.tableConfigApiAddCall = ApiHelper.createApiCall(this.tableConfigApiProperties, ApiOperationType.Add);
      this.tableConfigApiAddCall.silent = true;
      this.tableConfigApiAddCall.cacheIgnoreOnWrite = true;
    }
    if (!this.tableConfigApiEditCall) {
      this.tableConfigApiEditCall = ApiHelper.createApiCall(this.tableConfigApiProperties, ApiOperationType.Edit);
      this.tableConfigApiEditCall.silent = true;
      this.tableConfigApiEditCall.cacheIgnoreOnWrite = true;
    }
    if (!this.tableConfigApiDeleteCall) {
      this.tableConfigApiDeleteCall = ApiHelper.createApiCall(this.tableConfigApiProperties, ApiOperationType.Delete);
      this.tableConfigApiDeleteCall.silent = true;
      this.tableConfigApiDeleteCall.cacheIgnoreOnWrite = true;
    }
    if (!this.tableConfigQuery) {
      this.tableConfigQuery = new Query("Description", Constants.RowsToReturn.All);
    }
  }


  /**
   * Loads table config models.  The models are used internally but also provided
   * for subscribers to pick up on these updates.  This method can be called by service users
   * when they know new table configs have been added and the models need to be updated.
   */
  refreshTableConfig(): Observable<m5web.TableConfigurationViewModel[]> {

    // Get a quick response from our cache before we call the API.
    // We still call the API to refresh what we had in our cache.
    const promise: Promise<m5web.TableConfigurationViewModel[]> = this.cache.storedCacheGetValue<m5web.TableConfigurationViewModel[]>(this.cacheName, this.cacheKey);
    promise.then((answer) => {
      if (answer) {
        this.tableConfig = answer;
        this.tableConfigSubject.next(this.tableConfig);
      }
    }, (cancelled) => {
      // No action
    });

    // Config the api calls if not already done
    this.configApi();

    const subject = new AsyncSubject<m5web.TableConfigurationViewModel[]>();

    // Execute
    this.apiService.execute(this.tableConfigApiReadCall, this.tableConfigQuery).subscribe((result: IApiResponseWrapperTyped<m5web.TableConfigurationViewModel[]>) => {
      if (result.Data.Success) {
        this.tableConfigLoaded = true;
        this.tableConfig = result.Data.Data;
        this.tableConfigSubject.next(this.tableConfig);
        subject.next(this.tableConfig);
        subject.complete();
        // Now stick in cache storage with static lifetime.  It may change but this service wants to provide quick response
        // even if it might be stale and then after the api call it will refresh the cache storage.
        this.cache.storedCachePutValue(this.cacheName, this.cacheKey, this.tableConfig, CacheLevel.Static);
      } else {
        this.tableConfigLoaded = true; // Technically not loaded but we're not going to be retrying
        this.tableConfigSubject.next([]);
        subject.next([]);
        subject.complete();
        Log.errorMessage(result);
      }
    });

    return subject.asObservable();

  }



  saveTableConfig(config: m5web.TableConfigurationViewModel,
    reportErrors: boolean = true,
    reportSuccess: boolean = false): Observable<m5web.TableConfigurationViewModel> {

    if (!config) {
      return of(null);
    }

    // Config the api calls if not already done
    this.configApi();

    const subject = new AsyncSubject<m5web.TableConfigurationViewModel>();

    // Based on the existence of an id we know to either edit or add
    if (config.TableConfigurationId) {
      // Edit an existing config
      this.apiService.execute(this.tableConfigApiEditCall, config).subscribe((result: IApiResponseWrapperTyped<m5web.TableConfigurationViewModel>) => {
        if (result.Data.Success) {
          if (reportSuccess) {
            this.appService.alertManager.addAlertFromApiResponse(result, this.tableConfigApiEditCall);
          }
          // Update config subject
          const index = this.tableConfig.findIndex(x => x.TableConfigurationId === result.Data.Data.TableConfigurationId);
          if (index > -1) {
            this.tableConfig[index] = result.Data.Data;
            this.tableConfigSubject.next(this.tableConfig);
          }
          // Update cache
          this.cache.storedCachePutValue(this.cacheName, this.cacheKey, this.tableConfig, CacheLevel.Static);
          // Push out our response
          subject.next(result.Data.Data);
          subject.complete();
        } else {
          if (reportErrors) {
            this.appService.alertManager.addAlertFromApiResponse(result, this.tableConfigApiEditCall);
          }
          Log.errorMessage(result);
          // Push out our response
          subject.next(config);
          subject.complete();
        }
      });
    } else {
      // Add a new config
      this.apiService.execute(this.tableConfigApiAddCall, config).subscribe((result: IApiResponseWrapperTyped<m5web.TableConfigurationViewModel>) => {
        if (result.Data.Success) {
          if (reportSuccess) {
            this.appService.alertManager.addAlertFromApiResponse(result, this.tableConfigApiAddCall);
          }
          // Update config subject
          this.tableConfig.push(result.Data.Data);
          this.tableConfigSubject.next(this.tableConfig);
          // Update cache
          this.cache.storedCachePutValue(this.cacheName, this.cacheKey, this.tableConfig, CacheLevel.Static);
          // Push out our response
          subject.next(result.Data.Data);
          subject.complete();
        } else {
          if (reportErrors) {
            this.appService.alertManager.addAlertFromApiResponse(result, this.tableConfigApiAddCall);
          }
          Log.errorMessage(result);
          // Push out our response
          subject.next(config);
          subject.complete();
        }
      });
    }

    return subject.asObservable();

  }



  deleteTableConfig(config: m5web.TableConfigurationViewModel,
    reportErrors: boolean = true,
    reportSuccess: boolean = false) {

    // console.error("delete", config);
    if (!config?.TableConfigurationId) {
      return;
    }

    // Config the api calls if not already done
    this.configApi();

    this.apiService.execute(this.tableConfigApiDeleteCall, config.TableConfigurationId).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        if (reportSuccess) {
          this.appService.alertManager.addAlertFromApiResponse(result, this.tableConfigApiDeleteCall);
        }
        // Update config subject
        const index = this.tableConfig.findIndex(x => x.TableConfigurationId === config.TableConfigurationId);
        if (index > -1) {
          this.tableConfig.splice(index, 1);
        }
        // Update cache
        this.cache.storedCachePutValue(this.cacheName, this.cacheKey, this.tableConfig, CacheLevel.Static);
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, this.tableConfigApiDeleteCall);
        }
        Log.errorMessage(result);
      }
    });

  }



  findTableConfig(id: string): Observable<m5web.TableConfigurationViewModel> {

    let internalId: number = null;
    let externalId: string = null;

    if (Helper.isNumeric(id)) {
      internalId = parseInt(id, 10);
    } else {
      externalId = id;
    }

    const subject = new AsyncSubject<m5web.TableConfigurationViewModel>();

    // If we haven't loaded search configs we need that to finish first
    if (!this.tableConfigLoaded) {
      this.refreshTableConfig().pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(configs => {
          // Should be loaded now but enforce that so we don't get in a stack overflow recursive loop below
          if (!this.tableConfigLoaded) {
            this.tableConfigLoaded = true;
          }
          // Now loaded we can make a recursive call to get our value
          this.findTableConfig(id).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(config => {
              subject.next(config);
              subject.complete();
            });
        });
      return subject.asObservable();
    }

    // If we have already loaded search configs so find the one we want and we're done.
    let matches: m5web.TableConfigurationViewModel[] = [];
    if (internalId) {
      matches = this.tableConfig.filter(x => x.TableConfigurationId === internalId);
    } else if (externalId) {
      matches = this.tableConfig.filter(x => Helper.equals(x.Description, externalId, true));
    }
    if (matches && matches.length > 0) {
      subject.next(matches[0]);
      subject.complete();
    } else {
      // This is not only not an error it's the most common outcome for a table to not have a saved configuration
      subject.next(null);
      subject.complete();
    }

    return subject.asObservable();

  }



  /**
   * Applies table config to the provided TableOptions.
   * @param config
   * @param options
   * @returns
   */
  applyTableConfig(config: m5web.TableConfigurationViewModel, options: TableOptions): TableOptions {

    if (!options) {
      options = new TableOptions();
      options.tableId = config?.Description;
    }
    if (!config) {
      Log.errorMessage(`Apply table config called with null table configuration object.`);
      return options;
    }
    if (!config.Enabled) {
      Log.errorMessage(`Apply table config called with disabled table configuration object.`);
      return options;
    }
    if (!Helper.equals(config.Description, options.tableId, true)) {
      Log.errorMessage(`Apply table config ${config.Description} does not match the table options table id ${options.tableId}.`);
      return options;
    }

    options.title = config.Title;

    options.rowsPerPage = config.RowsPerPage;
    options.rowsPerPageOptions = config.RowsPerPageOptions;
    options.rowsPerPageSelector = config.RowsPerPageSelector;
    options.rowCounterMessage = config.RowCountMessage;
    options.paging = config.Paging;

    options.globalSearch = config.GlobalSearch;
    options.filterAllowSaved = config.FilterAllowSaved;
    options.filterAllowBuilder = config.FilterAllowBuilder;
    options.filterAllowAdvanced = config.FilterAllowAdvanced;
    options.filterColumns = config.FilterColumns;
    if (config.DateRange) {
      TableHelper.setDateRange(options, config.DateRange);
    }

    options.sortMode = (config.SortMode as "single" | "multiple") || "multiple";
    options.sort = config.Sort;
    options.expand = config.Expand;

    options.showRefreshOption = config.ShowRefreshOption;
    options.headerClickUrl = config.HeaderClickUrl;
    options.headerClickUrlNewWindow = config.HeaderClickUrlNewWindow;
    options.columnResizeMode = (config.ColumnResizeMode as "none" | "fit" | "expand") || "fit";
    options.responsive = config.Responsive;
    options.theme = (config.Theme as "default" | "plain" | "striped") || "striped";
    options.rowStyle = config.RowStyle;

    // If we have a GetRowStyle function then test it and assign via eval
    this.compileScript(options, "getRowStyle", config.GetRowStyle);
    // if (config.GetRowStyle) {
    //   let ok: boolean = true;
    //   // Check for syntax errors
    //   try {
    //     const func: Function = new Function(config.GetRowStyle);
    //   } catch (err) {
    //     console.error(err);
    //     ok = false;
    //   }
    //   if (ok) {
    //     try {
    //       // Eval assigning the code to our variable.  This means the entry point function must be
    //       // at the top of the code since that is what will be executed.
    //       eval(`options.getRowStyle = ${config.GetRowStyle};`);
    //     } catch (err) {
    //       // Runtime error
    //       console.error(err);
    //       ok = false;
    //     }
    //   }
    // }

    options.rowClickUrl = config.RowClickUrl;
    options.rowClickUrlNewWindow = config.RowClickUrlNewWindow;
    options.showLoader = config.ShowLoader;

    options.columns = [];
    if (config.Columns && config.Columns.length > 0) {
      config.Columns.forEach(col => {
        const colOption = this.buildTableColumnOptions(col, options);
        if (colOption) {
          options.columns.push(colOption);
        }
      });
    }

    if (config.RowActions && config.RowActions.length > 0 && options.rowActionButton?.options && options.rowActionButton.options.length > 0) {
      config.RowActions.forEach(actionConfig => {
        const action = options.rowActionButton.options.find(x => Helper.equals(x.actionId, actionConfig.ActionId, true));
        if (action) {
          if (actionConfig.RequiresRole) {
            action.requiresRole = actionConfig.RequiresRole;
          }
          if (actionConfig.RequiresPermissionAccessArea) {
            action.requiresPermissionAccessArea = actionConfig.RequiresPermissionAccessArea;
            action.requiresPermissionRight = actionConfig.RequiresPermissionRight;
          }
        }
      });
    }

    options.rowActionDisplayMode = (config.RowActionDisplayMode as "icons" | "menu" | "overflow") || "menu";
    options.rowActionsForIconDisplay = config.RowActionsForIconDisplay;
    options.rememberLayout = config.RememberLayout;
    options.rememberSort = config.RememberSort;
    options.rememberFilter = config.RememberFilter;
    options.rememberPaging = config.RememberPaging;
    options.rememberDateRange = config.RememberDateRange;

    return options;

  }



  /**
   * This method builds a TableColumnOptions object based on the provided config and TableOptions.
   * @param config
   * @param options
   * @returns
   */
  buildTableColumnOptions(config: m5web.TableColumnConfigurationViewModel, options: TableOptions): TableColumnOptions {

    if (!config) {
      Log.errorMessage(`Build table column options called with null table column configuration object.`);
      return null;
    }
    if (!config.Visible) {
      return null;
    }

    if (options && options.columns && config.TableColumnId) {
      const match: TableColumnOptions = Helper.firstOrDefault(options.columns, x => Helper.equals(config.TableColumnId, x.tableColumnId, true), null);
      if (match) {
        return match;
      }
    }

    const col: TableColumnOptions = new TableColumnOptions();
    col.tableColumnId = config.TableColumnId;
    col.propertyName = config.PropertyName;
    col.supportingPropertyName = config.SupportingPropertyName;
    col.headerIcon = config.HeaderIcon;
    col.header = config.HeaderLabel;
    col.headerTooltip = config.HeaderDescription;
    col.visible = config.Visible;
    col.sortable = config.Sortable;
    col.resizable = config.Resizable;
    col.wrap = config.WordWrap;
    col.allowLineBreaks = config.AllowLineBreaks;
    col.allowHtml = config.AllowHtml;
    col.dataType = config.DataType;
    col.decimals = config.Decimals;
    col.maxCharacterLength = config.MaxCharacterLength;
    col.toolTipWhenMaxCharacterLengthExceeded = config.ToolTipWhenMaxCharacterLengthExceeded;
    col.align = (config.Align as "left" | "center" | "right") || "left";
    col.toolTipType = (config.ToolTipType as "none" | "tooltip" | "popover") || "none";
    col.toolTipTitle = config.ToolTipTitle;
    col.toolTipText = config.ToolTipText;
    this.compileScript(col, "toolTipTitleFunction", config.ToolTipTitleFunction);
    this.compileScript(col, "toolTipTextFunction", config.ToolTipTextFunction);
    col.toolTipAppendToBody = config.ToolTipAppendToBody;
    col.toolTipPlacement = config.ToolTipPlacement || "auto";
    col.width = config.Width;
    col.footerHtml = config.FooterHtml;
    col.headerClickUrl = config.HeaderClickUrl;
    col.headerClickUrlNewWindow = config.HeaderClickUrlNewWindow;
    col.rowClickUrl = config.RowClickUrl;
    col.rowClickUrlNewWindow = config.RowClickUrlNewWindow;
    if (config.Render && !Helper.equals(config.DataType, "function")) {
      console.warn(`Property "${config.PropertyName}" has render function defined but data type is "${config.DataType}" when data type "function" was expected.`);
    } else {
      this.compileScript(col, "render", config.Render);
    }
    col.supportLateRender = config.SupportLateRender;
    col.style = config.Style;
    this.compileScript(col, "getStyle", config.GetStyle);
    col.optionsPickListId = config.OptionsPickListId;
    col.optionsPickList = StaticPickList.dictionaryToPickList(config.OptionsPickList);
    col.optionsIncludeNone = config.OptionsIncludeNone;
    col.optionsNoneLabel = config.OptionsNoneLabel;
    col.includeInGlobalFilter = config.IncludeInGlobalFilter;
    col.filterType = (config.FilterType as "none" | "text" | "select" | "multiselect") || "text";
    col.filterMatchMode = config.FilterMatchMode; // Available match modes are "startsWith", "contains", "endsWith", "equals", "notEquals", "in", "lt", "lte", "gt" and "gte".
    col.filterPickListId = config.FilterPickListId;
    col.filterPickListValues = StaticPickList.dictionaryToPickList(config.FilterPickListValues);

    return col;

  }


  /**
   * This method test a javascript script and then assigns it to the specified object property.
   * @param obj
   * @param propertyName
   * @param script
   * @returns
   */
  compileScript(obj: any, propertyName: string, script: string) {

    // No script so nothing to do
    if (!script) {
      return;
    }
    if (!obj || !propertyName) {
      return;
    }

    // Check for syntax errors
    try {
      const func: Function = new Function(script);
    } catch (err) {
      // if (Helper.contains(err.toString(), "require a function name")) {
      //   console.error("Creating a placeholder function name", err);
      //   script = script.replace("function ", `function ${propertyName}Function `);
      //   try {
      //     const func: Function = new Function(script);
      //   } catch (err) {
      //     console.error(err);
      //     console.error(`Error compiling script for ${propertyName}:`, script);
      //     return;
      //   }
      // } else {
      console.error(err);
      console.error(`Error compiling script for ${propertyName}:`, script);
      return;
    }

    try {
      // Eval assigning the code to our variable.  This means the entry point function must be
      // at the top of the code since that is what will be executed.
      // eslint-disable-next-line no-eval
      eval(`obj.${propertyName} = ${script};`);
    } catch (err) {
      // Runtime error
      console.error(err);
    }

  }


  buildTableConfig(options: TableOptions): m5web.TableConfigurationViewModel {

    const config: m5web.TableConfigurationViewModel = new m5web.TableConfigurationViewModel();
    if (!options) {
      Log.errorMessage(`Build table config called with null table options object.`);
      return config;
    }

    config.Enabled = true;
    config.Description = options.tableId;
    config.Title = options.title;

    config.RowsPerPage = options.rowsPerPage;
    config.RowsPerPageOptions = options.rowsPerPageOptions;
    config.RowsPerPageSelector = options.rowsPerPageSelector;
    config.RowCountMessage = options.rowCounterMessage;
    config.Paging = options.paging;

    config.GlobalSearch = options.globalSearch;
    config.FilterAllowSaved = options.filterAllowSaved;
    config.FilterAllowBuilder = options.filterAllowBuilder;
    config.FilterAllowAdvanced = options.filterAllowAdvanced;
    config.FilterColumns = options.filterColumns;

    config.SortMode = options.sortMode;
    config.Sort = options.sort;
    config.Expand = options.expand;

    config.ShowRefreshOption = options.showRefreshOption;
    config.HeaderClickUrl = options.headerClickUrl;
    config.HeaderClickUrlNewWindow = options.headerClickUrlNewWindow;
    config.ColumnResizeMode = options.columnResizeMode;
    config.Responsive = options.responsive;
    config.Theme = options.theme;
    config.RowStyle = options.rowStyle;
    if (options.getRowStyle) {
      config.GetRowStyle = String(options.getRowStyle);
    }

    config.RowClickUrl = options.rowClickUrl;
    config.RowClickUrlNewWindow = options.rowClickUrlNewWindow;
    config.ShowLoader = options.showLoader;

    config.Columns = [];
    if (options.columns && options.columns.length > 0) {
      options.columns.forEach(col => {
        config.Columns.push(this.buildTableColumnConfig(col));
      });
    }

    if (options.rowActionButton?.options?.length > 0) {
      config.RowActionIds = options.rowActionButton.options.map(x => x.actionId).filter(x => !Helper.equals(x, "divider", true));
    }
    config.RowActionDisplayMode = options.rowActionDisplayMode;
    config.RowActionsForIconDisplay = options.rowActionsForIconDisplay;
    config.RememberLayout = options.rememberLayout;
    config.RememberSort = options.rememberSort;
    config.RememberFilter = options.rememberFilter;
    config.RememberPaging = options.rememberPaging;

    return config;

  }


  buildTableColumnConfig(col: TableColumnOptions): m5web.TableColumnConfigurationViewModel {

    const config: m5web.TableColumnConfigurationViewModel = new m5web.TableColumnConfigurationViewModel();

    if (!col) {
      Log.errorMessage(`Build table column config called with null table column options object.`);
      return config;
    }

    config.TableColumnId = col.tableColumnId;
    config.PropertyName = col.propertyName;
    config.SupportingPropertyName = col.supportingPropertyName;
    config.HeaderIcon = col.headerIcon;
    config.HeaderLabel = col.header;
    config.HeaderDescription = col.headerTooltip;
    config.Visible = col.visible;
    config.Sortable = col.sortable;
    config.Resizable = col.resizable;
    config.WordWrap = col.wrap;
    config.AllowLineBreaks = col.allowLineBreaks;
    config.AllowHtml = col.allowHtml;
    config.DataType = col.dataType;
    config.Decimals = col.decimals;
    config.MaxCharacterLength = col.maxCharacterLength;
    config.ToolTipWhenMaxCharacterLengthExceeded = col.toolTipWhenMaxCharacterLengthExceeded;
    config.Align = col.align;
    config.ToolTipType = col.toolTipType;
    config.ToolTipTitle = col.toolTipTitle;
    config.ToolTipText = col.toolTipText;
    if (col.toolTipTitleFunction) {
      config.ToolTipTitleFunction = String(col.toolTipTitleFunction);
    }
    if (col.toolTipTextFunction) {
      config.ToolTipTextFunction = String(col.toolTipTextFunction);
    }
    config.ToolTipAppendToBody = col.toolTipAppendToBody;
    config.ToolTipPlacement = col.toolTipPlacement.toString();
    config.Width = col.width;
    config.FooterHtml = col.footerHtml;
    config.HeaderClickUrl = col.headerClickUrl;
    config.HeaderClickUrlNewWindow = col.headerClickUrlNewWindow;
    config.RowClickUrl = col.rowClickUrl;
    config.RowClickUrlNewWindow = col.rowClickUrlNewWindow;
    if (col.render) {
      config.Render = String(col.render);
    }
    config.SupportLateRender = col.supportLateRender;
    config.Style = col.style;
    if (col.getStyle) {
      config.GetStyle = String(col.getStyle);
    }
    config.OptionsPickListId = col.optionsPickListId;
    if (col.optionsPickList && col.optionsPickList.length > 0) {
      const pickList: { [index: string]: string } = {};
      col.optionsPickList.forEach(item => {
        pickList[item.Value] = item.DisplayText;
      });
      config.OptionsPickList = pickList;
    }
    config.OptionsIncludeNone = col.optionsIncludeNone;
    config.OptionsNoneLabel = col.optionsNoneLabel;
    config.IncludeInGlobalFilter = col.includeInGlobalFilter;
    config.FilterType = col.filterType;
    config.FilterMatchMode = col.filterMatchMode;
    config.FilterPickListId = col.filterPickListId;
    if (col.filterPickListValues && col.filterPickListValues.length > 0) {
      const pickList: { [index: string]: string } = {};
      col.filterPickListValues.forEach(item => {
        pickList[item.Value] = item.DisplayText;
      });
      config.FilterPickListValues = pickList;
    }

    return config;

  }



  newTableColumnConfig(): m5web.TableColumnConfigurationViewModel {
    const col = new m5web.TableColumnConfigurationViewModel();
    col.Id = Helper.createBase36Guid();
    col.Visible = true;
    col.Sortable = true;
    col.Resizable = true;
    col.WordWrap = true;
    col.AllowLineBreaks = false;
    col.AllowHtml = false;
    col.DataType = "string";
    col.ToolTipWhenMaxCharacterLengthExceeded = true;
    col.Align = "left";
    col.ToolTipType = "none";
    col.ToolTipAppendToBody = true;
    col.ToolTipPlacement = "auto";
    col.SupportLateRender = false;
    col.OptionsPickList = {};
    col.OptionsIncludeNone = true;
    col.OptionsNoneLabel = "<None>";
    col.IncludeInGlobalFilter = true;
    col.FilterType = "text";
    col.FilterMatchMode = "contains";
    col.FilterPickListValues = {};
    return col;
  }


  newTableColumnConfigFromDataModelColumn(column: any): m5web.TableColumnConfigurationViewModel {
    const col = this.newTableColumnConfig();
    if (column) {
      // console.error(column);
      col.PropertyName = column.Name;
      col.HeaderLabel = column.Description;
      col.HeaderDescription = column.Notes;
      // "unknown", "string", "boolean", "number", "int", "float", "currency", "date", "time", "datetime", "picklist", "icon", "email", "avatar", "function", "pie-chart"
      if (column.DataTypeCode === m.System.TypeCode.Boolean) {
        col.DataType = "boolean";
      } else if (column.DataTypeCode === m.System.TypeCode.Int64 || column.DataTypeCode === m.System.TypeCode.Int32 || column.DataTypeCode === m.System.TypeCode.Int16) {
        col.DataType = "int";
      } else if (column.DataTypeCode === m.System.TypeCode.Double || column.DataTypeCode === m.System.TypeCode.Single || column.DataTypeCode === m.System.TypeCode.Decimal) {
        col.DataType = "float";
      } else if (column.DataTypeCode === m.System.TypeCode.DateTime) {
        col.DataType = "datetime";
      } else {
        col.DataType = "string";
      }
    }
    return col;
  }

}
