import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppCacheService } from 'projects/core-lib/src/lib/services/app-cache.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import { CacheLevel, ApiProperties, ApiCall, ApiOperationType, Query, IApiResponseWrapperTyped, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { Observable, of, Subject, timer, BehaviorSubject, AsyncSubject } from 'rxjs';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { map, takeUntil, switchMap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { AppService, UserSetCallback } from './app.service';
import { ApiModuleSecurity } from '../api/Api.Module.Security';
import { Router } from '@angular/router';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { getDate, getYear } from 'date-fns';
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { FormGroupFluentModel } from 'projects/common-lib/src/lib/form/form-fluent-model';
import { EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { UxService } from 'projects/common-lib/src/lib/services/ux.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService implements OnDestroy, UserSetCallback {

  public cacheNameListObject: string = "ContactListObject";
  public cacheNameFullObject: string = "ContactFullObject";
  public cacheLevel: CacheLevel = CacheLevel.ChangesInfrequently;

  protected recentQueryContactIdList: number[] = [];
  protected apiProperties: ApiProperties = Api.Contact();
  protected apiCallList: ApiCall;
  protected apiCallFull: ApiCall;

  private groupsSubject = new BehaviorSubject<m5.GroupEditViewModel[]>([]);
  getGroups() { return this.groupsSubject.asObservable(); }
  private groups: m5.GroupEditViewModel[] = [];
  private groupApiProp: ApiProperties = null;
  private groupApiCall: ApiCall = null;
  private groupQuery: Query = null;

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected router: Router,
    protected uxService: UxService) {

    super();

    this.appService.userSetCallbackRegister(this);

    this.apiCallList = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
    this.apiCallList.silent = true;
    this.apiCallFull = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Get);
    this.apiCallFull.silent = true;

    try {
      this.refreshGroups();
    } catch (err) {
      Log.errorMessage("Exception refreshing groups from service constructor");
      Log.errorMessage(err);
    }

  }


  /**
   * Method defined in UserSetCallback interface.
   */
  public userSet(user: m5sec.AuthenticatedUserViewModel): void {
    // New user set is typically from a login which may impact these items so reload them
    this.refreshGroups();
  }


  public getContactListObject(contactId: number, waitForRecentRequests: boolean = true): Observable<m5.ContactListViewModel> {

    if (this.appService.config.brand === "wallet") {
      // Not valid for wallet
      return;
    }

    const contact = this.cacheGet("ContactListObject", contactId);
    if (contact) {
      return of(<m5.ContactListViewModel>contact);
    }

    const query: Query = new Query();
    query.Filter = `ContactId == ${contactId}`;

    // If we recently submitted a request then wait a second to see if it gets fulfilled
    if (waitForRecentRequests && this.recentQueryContactIdList.includes(contactId)) {
      // Wait 1 second and then try
      const source = timer(1000);
      return source.pipe(
        switchMap((value: number) => {
          // false parameter means we don't wait again
          return this.getContactListObject(contactId, false);
        }));
    }

    this.recentQueryContactIdList.push(contactId);
    return this.apiService.execute(this.apiCallList, query).pipe(
      map((result: IApiResponseWrapperTyped<m5.ContactListViewModel[]>) => {
        if (result.Data.Success && result.Data.Data && result.Data.Data.length > 0) {
          this.cachePut("ContactListObject", contactId, result.Data.Data[0]);
          this.recentQueryContactIdList = this.recentQueryContactIdList.filter(x => x !== contactId);
          return result.Data.Data[0];
        } else {
          return null;
        }
      }),
      takeUntil(this.ngUnsubscribe));

  }

  public getContactFullObject(contactId: number, waitForRecentRequests: boolean = true): Observable<m5.ContactEditViewModel> {

    if (this.appService.config.brand === "wallet") {
      // Not valid for wallet
      return;
    }

    const contact = this.cacheGet("ContactFullObject", contactId);
    if (contact) {
      return of(<m5.ContactEditViewModel>contact);
    }

    // If we recently submitted a request then wait a second to see if it gets fulfilled
    if (waitForRecentRequests && this.recentQueryContactIdList.includes(contactId)) {
      // Wait 1 second and then try
      const source = timer(1000);
      return source.pipe(
        switchMap((value: number) => {
          // false parameter means we don't wait again
          return this.getContactFullObject(contactId, false);
        }));
    }

    this.recentQueryContactIdList.push(contactId);
    return this.apiService.execute(this.apiCallFull, contactId).pipe(
      map((result: IApiResponseWrapperTyped<m5.ContactEditViewModel>) => {
        if (result.Data.Success && result.Data.Data) {
          this.cachePut("ContactFullObject", contactId, result.Data.Data);
          this.recentQueryContactIdList = this.recentQueryContactIdList.filter(x => x !== contactId);
          return result.Data.Data;
        } else {
          return null;
        }
      }),
      takeUntil(this.ngUnsubscribe));

  }

  protected cachePut(cacheName: "ContactListObject" | "ContactFullObject", contactId: number, contact: m5.ContactListViewModel | m5.ContactEditViewModel): void {
    if (contactId && contact) {
      this.cache.cachePutValue(cacheName, contactId.toString(), contact, this.cacheLevel);
    }
  }

  protected cacheGet(cacheName: "ContactListObject" | "ContactFullObject", contactId: number): m5.ContactListViewModel | m5.ContactEditViewModel {
    if (!contactId) {
      return null;
    }
    if (Helper.equals(cacheName, this.cacheNameListObject, true)) {
      return this.cache.cacheGetValue<m5.ContactListViewModel>(cacheName, contactId.toString());
    } else {
      return this.cache.cacheGetValue<m5.ContactEditViewModel>(cacheName, contactId.toString());
    }
  }

  public cacheDump(contactId: number): void {
    if (contactId) {
      this.cache.cacheRemoveValue(this.cacheNameListObject, contactId.toString());
      this.cache.cacheRemoveValue(this.cacheNameFullObject, contactId.toString());
    }
  }


  public goToContact(contactType: string, contactId: number, contactName: string, mode: "edit" | "view" = "edit"): void {
    if (!contactId) {
      Log.warningMessage("Unable to go to contact since no contact id provided.");
      return;
    } else if (!contactType) {
      Log.warningMessage("Unable to go to contact since no contact type provided.");
      return;
    }
    if (contactType === Constants.ContactType.Customer) {
      this.router.navigate(["/", "customers", (mode === "view" ? "view" : "edit"), contactId, Helper.encodeURISlug(contactName || "Customer")]);
    } else if (contactType === Constants.ContactType.Directory) {
      this.router.navigate(["/", "directory", (mode === "view" ? "view" : "edit"), contactId, Helper.encodeURISlug(contactName || "User")]);
    } else if (contactType === Constants.ContactType.Group) {
      this.router.navigate(["/", "groups", (mode === "view" ? "view" : "edit"), contactId, Helper.encodeURISlug(contactName || "Group")]);
    } else if (contactType === Constants.ContactType.Warehouse) {
      this.router.navigate(["/", "warehouses", (mode === "view" ? "view" : "edit"), contactId, Helper.encodeURISlug(contactName || "Warehouse")]);
    } else if (contactType === Constants.ContactType.Vendor) {
      this.router.navigate(["/", "vendors", (mode === "view" ? "view" : "edit"), contactId, Helper.encodeURISlug(contactName || "Vendor")]);
    } else {
      Log.warningMessage(`Unable to go to contact since contact type "${contactType}" as no route defined.`);
    }
  }


  /**
   * Loads group models.  The models are used internally but also provided
   * for subscribers to pick up on these updates.  This method can be called by service users
   * when they know new groups have been added and the models need to be updated.
   * @param reportErrors
   */
  refreshGroups(reportErrors: boolean = undefined) {

    if (this.appService.config.brand === "wallet") {
      // Not valid for wallet
      return;
    }

    if (Helper.isUndefinedOrNull(reportErrors)) {
      // If user is logged in then we default to reporting errors... otherwise, be silent on it.
      reportErrors = this.appService.isLoggedIn();
    }

    if (!this.groupApiProp || !this.groupApiCall || !this.groupQuery) {
      this.groupApiProp = Api.Group();
      this.groupApiCall = ApiHelper.createApiCall(this.groupApiProp, ApiOperationType.List);
      // This method gets called from places like password expired forms where we don't have valid
      // login information so don't trigger a redirect to login on auth error.
      this.groupApiCall.redirectToLoginOnAuthenticationErrors = false;
      this.groupQuery = new Query();
      this.groupQuery.Size = Constants.RowsToReturn.All;
      this.groupQuery.Expand = "full";
    }

    this.apiService.execute(this.groupApiCall, this.groupQuery).subscribe((result: IApiResponseWrapperTyped<m5.GroupEditViewModel[]>) => {
      if (result.Data.Success) {
        this.groups = result.Data.Data;
        this.groupsSubject.next(this.groups);
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, this.groupApiCall);
        }
      }
    });

  }


  getGroup(groupId: number): m5.GroupEditViewModel {
    if (!groupId || !this.groups || this.groups.length === 0) {
      return null;
    }
    const matches = this.groups.filter(x => x.GroupId === groupId);
    if (!matches || matches.length === 0) {
      return null;
    }
    return matches[0];
  }


  findGroup(validForContactType: string, userScope: string): m5.GroupEditViewModel {
    let matches: m5.GroupEditViewModel[] = [];
    if (validForContactType && userScope) {
      matches = this.groups.filter(x =>
        (!x.GroupMembershipAllowedContactTypes ||
          x.GroupMembershipAllowedContactTypes.length === 0 ||
          x.GroupMembershipAllowedContactTypes.includes(validForContactType))
        &&
        Helper.equals(x.Scope, userScope, true));
    } else if (validForContactType) {
      matches = this.groups.filter(x =>
      (!x.GroupMembershipAllowedContactTypes ||
        x.GroupMembershipAllowedContactTypes.length === 0 ||
        x.GroupMembershipAllowedContactTypes.includes(validForContactType)));
    } else if (userScope) {
      matches = this.groups.filter(x => Helper.equals(x.Scope, userScope, true));
    }
    if (!matches || matches.length === 0) {
      return null;
    }
    return matches[0];
  }



  isCurrentUserSysAdmin(): boolean {
    const admin = this.appService.userOrDefault.Permissions.filter(x => Helper.equals(x.Area, "Everything", true));
    if (admin && admin.length > 0) {
      return true;
    }
    return false;
  }


  isCurrentUserGroupOwner(groupId: number): boolean {
    if (!groupId) {
      return false;
    }
    const group = this.getGroup(groupId);
    if (!group) {
      return false;
    }
    const ownership: m5sec.AuthenticatedUserGroupViewModel[] = this.appService.userOrDefault.Groups.filter(x => x.GroupId === groupId && x.GroupOwner);
    if (ownership && ownership.length > 0) {
      return true;
    }
    return false;
  }


  canCurrentUserImpersonateGroup(groupId: number): boolean {
    if (!groupId) {
      return false;
    }
    const group = this.getGroup(groupId);
    if (!group) {
      return false;
    }
    const impersonate: m5sec.AuthenticatedUserGroupViewModel[] = this.appService.userOrDefault.Groups.filter(x => x.GroupId === groupId && x.CanImpersonateGroup);
    if (impersonate && impersonate.length > 0) {
      return true;
    }
    return false;
  }


  canJoinGroup(groupId: number): "true" | "false" | "request" {
    if (!groupId) {
      return "false";
    }
    const group = this.getGroup(groupId);
    if (!group) {
      return "false";
    }
    // The group is open so anyone can join
    if (group.JoiningGroup === m5.GroupMembershipApproval.Open) {
      return "true";
    }
    // If the current user is a sys admin then we allow to function like group owner
    if (this.isCurrentUserSysAdmin()) {
      return "true";
    }
    // If the current user is a group owner then this user can join other users to the group
    if (this.isCurrentUserGroupOwner(groupId)) {
      return "true";
    }
    // Not an owner of this group so if we need owner approval the return value is "request"
    if (group.JoiningGroup === m5.GroupMembershipApproval.OwnerApproval) {
      return "request";
    }
    // We're not open, we're not owner approved, and we're not an owner so all that's left is no
    return "false";
  }


  canLeaveGroup(groupId: number): "true" | "false" | "request" {
    if (!groupId) {
      return "false";
    }
    const group = this.getGroup(groupId);
    if (!group) {
      return "false";
    }
    // The group is open so anyone can leave
    if (group.LeavingGroup === m5.GroupMembershipApproval.Open) {
      return "true";
    }
    // If the current user is a sys admin then we allow to function like group owner
    if (this.isCurrentUserSysAdmin()) {
      return "true";
    }
    // If the current user is a group owner then this user can leave other users from the group
    if (this.isCurrentUserGroupOwner(groupId)) {
      return "true";
    }
    // Not an owner of this group so if we need owner approval the return value is "request"
    if (group.LeavingGroup === m5.GroupMembershipApproval.OwnerApproval) {
      return "request";
    }
    // We're not open, we're not owner approved, and we're not an owner so all that's left is no
    return "false";
  }


  getApiPropertiesForContactType(contactType: string): ApiProperties {
    let apiProp: ApiProperties = null;
    if (contactType === Constants.ContactType.Directory) {
      apiProp = Api.Directory();
    } else if (contactType === Constants.ContactType.Customer) {
      apiProp = Api.Customer();
    } else if (contactType === Constants.ContactType.Vendor) {
      apiProp = Api.Vendor();
    } else if (contactType === Constants.ContactType.Warehouse) {
      apiProp = Api.Warehouse();
    } else if (contactType === Constants.ContactType.Location) {
      apiProp = Api.Location();
    } else if (contactType === Constants.ContactType.Group) {
      apiProp = Api.Group();
    } else if (contactType === Constants.ContactType.Contact) {
      apiProp = Api.Contact();
    }
    if (!apiProp) {
      Log.errorMessage(`No api properties object found for contact type ${contactType}.`);
    }
    return apiProp;
  }


  getContactId(contact: any, contactType: string): number {

    if (!contact) {
      return null;
    }

    let id: number = null;
    if (contactType === Constants.ContactType.Directory) {
      id = contact.DirectoryId;
    } else if (contactType === Constants.ContactType.Customer) {
      id = contact.CustomerId;
    } else if (contactType === Constants.ContactType.Vendor) {
      id = contact.VendorId;
    } else if (contactType === Constants.ContactType.Warehouse) {
      id = contact.WarehouseId;
    } else if (contactType === Constants.ContactType.Location) {
      id = contact.LocationId;
    } else if (contactType === Constants.ContactType.Group) {
      id = contact.GroupId;
    } else {
      id = contact.ContactId;
    }

    return id;

  }


  getContactDescription(contact: any, contactType: string): string {

    if (!contact) {
      return "";
    }

    let name: string = "";
    if (contactType === Constants.ContactType.Directory) {
      name = contact.DirectoryName;
    } else if (contactType === Constants.ContactType.Customer) {
      name = contact.CustomerName;
    } else if (contactType === Constants.ContactType.Vendor) {
      name = contact.VendorName;
    } else if (contactType === Constants.ContactType.Warehouse) {
      name = contact.WarehouseName;
    } else if (contactType === Constants.ContactType.Location) {
      name = contact.LocationName;
    } else if (contactType === Constants.ContactType.Group) {
      name = contact.GroupName;
    } else {
      name = contact.ContactName;
    }

    return Helper.getFirstDefinedString(name, `${contact.FirstName} ${contact.LastName}`.trim(), contact.Email);

  }


  getContactTypePickList(...contactTypes: string[]): m5core.PickListSelectionViewModel[] {

    const possibleTypes: string[] = [Constants.ContactType.Directory, Constants.ContactType.ApiKey, Constants.ContactType.Group,
    Constants.ContactType.Customer, Constants.ContactType.Prospect, Constants.ContactType.Marketing,
    Constants.ContactType.Agent, Constants.ContactType.ServiceProvider, Constants.ContactType.Vendor,
    Constants.ContactType.Warehouse, Constants.ContactType.RetailLocation, Constants.ContactType.Location,
    Constants.ContactType.TaxAuthority, Constants.ContactType.ActivityContact, Constants.ContactType.CollectionAgency,
    Constants.ContactType.System, Constants.ContactType.Contact, Constants.ContactType.Any];

    const pickList: m5core.PickListSelectionViewModel[] = [];

    possibleTypes.forEach(type => {
      if (contactTypes.includes(type)) {
        const picker = new m5core.PickListSelectionViewModel();
        picker.Value = type;
        picker.DisplayText = this.getContactTypeDescription(type);
        pickList.push(picker);
      }
    });

    return pickList;

  }


  getContactTypeDescription(contactType: string): string {

    let description: string = "Contact";

    if (!contactType) {
      description = "Contact";
    } else if (contactType === Constants.ContactType.Directory) {
      description = "Directory";
    } else if (contactType === Constants.ContactType.ApiKey) {
      description = "API Key";
    } else if (contactType === Constants.ContactType.Group) {
      description = "Group";
    } else if (contactType === Constants.ContactType.Customer) {
      description = "Customer";
    } else if (contactType === Constants.ContactType.Prospect) {
      description = "Prospect";
    } else if (contactType === Constants.ContactType.Marketing) {
      description = "Marketing";
    } else if (contactType === Constants.ContactType.Agent) {
      description = "Agent";
    } else if (contactType === Constants.ContactType.ServiceProvider) {
      description = "Service Provider";
    } else if (contactType === Constants.ContactType.Vendor) {
      description = "Vendor";
    } else if (contactType === Constants.ContactType.Warehouse) {
      description = "Warehouse";
    } else if (contactType === Constants.ContactType.RetailLocation) {
      description = "Retail Location";
    } else if (contactType === Constants.ContactType.Location) {
      description = "Location";
    } else if (contactType === Constants.ContactType.TaxAuthority) {
      description = "Tax Authority";
    } else if (contactType === Constants.ContactType.ActivityContact) {
      description = "Activity Contact";
    } else if (contactType === Constants.ContactType.CollectionAgency) {
      description = "Collection Agency";
    } else if (contactType === Constants.ContactType.System) {
      description = "System";
    } else if (contactType === Constants.ContactType.Contact) {
      description = "Contact";
    } else if (contactType === Constants.ContactType.Any) {
      description = "Unknown";
    } else {
      description = "Unknown";
    }

    return description;

  }

  getContactTypeDescriptionLong(contactType: string): string {
    if (contactType === Constants.ContactType.Directory) {
      return "Directory User";
    }
    return this.getContactTypeDescription(contactType);
  }

  doesContactTypeSupportLogin(contactType: string): boolean {

    if (!contactType) {
      return false;
    }

    // Directory contact type always supports login
    if (contactType === Constants.ContactType.Directory) {
      return true;
    }

    // Certain types can optionally support login
    let setting: m5.SettingEditViewModel = null;
    if (contactType === Constants.ContactType.Customer) {
      setting = this.appService.systemSettingsGetOne("ContactSupport", "SupportLoginCustomer", "false");
    } else if (contactType === Constants.ContactType.Agent) {
      setting = this.appService.systemSettingsGetOne("ContactSupport", "SupportLoginAgent", "false");
    } else if (contactType === Constants.ContactType.ServiceProvider) {
      setting = this.appService.systemSettingsGetOne("ContactSupport", "SupportLoginServiceProvider", "false");
    }
    if (setting) {
      return Helper.equals(setting.Value, "true", true);
    }

    // No other contact types support login
    return false;

  }


  sendPasswordResetLink(contact: m5.DirectoryListViewModel | m5.DirectoryEditViewModel | m5.CustomerListViewModel | m5.CustomerEditViewModel | m5.ContactListViewModel | m5.ContactEditViewModel) {
    const apiProp: ApiProperties = ApiModuleSecurity.SecurityPasswordReset();
    const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Call);
    const model = new m5sec.PasswordResetLinkRequestViewModel();
    model.Login = contact.Login;
    model.BaseUrl = `${window.location.protocol}//${window.location.host}/link/`;
    this.apiService.call(apiCall, model).subscribe((response: IApiResponseWrapper) => {
      if (response.Data.Success) {
        this.appService.alertManager.addAlertMessage(AlertItemType.Success, `A password reset link has been emailed to this user.`, 3);
      } else {
        this.appService.alertManager.addAlertFromApiResponse(response, apiCall);
      }
    });
  }


  getSecurityPolicy(contactType: string, defaultToSystemPolicy: boolean = true, reportErrors: boolean = true): Observable<m5sec.SecurityPolicyViewModel> {

    const subject = new AsyncSubject<m5sec.SecurityPolicyViewModel>();
    this.getSecurityPolicies(reportErrors).pipe(takeUntil(this.ngUnsubscribe)).subscribe((policies: m5sec.SecurityPolicyViewModel[]) => {
      if (!policies) {
        policies = [];
      }
      let policy = Helper.firstOrDefault(policies, x => x.ContactType === contactType);
      if (!policy && defaultToSystemPolicy) {
        policy = Helper.firstOrDefault(policies, x => x.ContactType === Constants.ContactType.System);
      }
      if (!policy && defaultToSystemPolicy) {
        // Directory is default #2
        policy = Helper.firstOrDefault(policies, x => x.ContactType === Constants.ContactType.Directory);
      }
      if (!policy) {
        let message = `Unable to find security policy for contact type ${contactType} out of ${policies.length} security policies.`;
        if (defaultToSystemPolicy) {
          message = `Unable to find security policy for contact type ${contactType} (or fallback contact types of ${Constants.ContactType.System} or ${Constants.ContactType.Directory}) out of ${policies.length} security policies.`;
        }
        if (reportErrors) {
          console.error(message);
        } else {
          console.warn(message);
        }
        if (defaultToSystemPolicy) {
          policy = this.getSecurityPolicyDefaultModel();
          console.error("Using default security policy.");
        }
      }
      subject.next(policy);
      subject.complete();
    });

    return subject.asObservable();

  }


  getSecurityPolicyDefaultModel(): m5sec.SecurityPolicyViewModel {
    const model = new m5sec.SecurityPolicyViewModel();
    model.SecurityPolicyId = Helper.randomInteger(true);
    model.ContactType = Constants.ContactType.System;
    model.MinimumPasswordLength = 8;
    model.AlphaCharactersRequired = true;
    model.NumericCharactersRequired = true;
    model.MixedCaseRequired = true;
    model.PunctuationRequired = true;
    model.PasswordCannotContainLogin = true;
    model.PasswordCannotContainName = true;
    model.PasswordCannotContainImportantDates = true;
    return model;
  }



  getSecurityPolicies(reportErrors: boolean = true): Observable<m5sec.SecurityPolicyViewModel[]> {

    const subject = new AsyncSubject<m5sec.SecurityPolicyViewModel[]>();
    const apiProp = ApiModuleSecurity.SecurityPolicy();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.List);
    apiCall.silent = true;
    apiCall.cacheUseStorage = true;
    // This method gets called from places like password expired forms where we don't have valid
    // login information so don't trigger a redirect to login on auth error.
    apiCall.redirectToLoginOnAuthenticationErrors = false;
    const query = new Query();
    this.apiService.execute(apiCall, query).subscribe((response: IApiResponseWrapperTyped<m5sec.SecurityPolicyViewModel[]>) => {
      if (response.Data.Success) {
        subject.next(response.Data.Data);
        subject.complete();
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(response, apiCall);
        }
      }
    });

    return subject.asObservable();

  }


  getSecurityPolicyStatus(policy: m5sec.SecurityPolicyViewModel, doubleEntryPassword: boolean, login: string,
    password1: string, password2: string, contactName: string, firstName: string, lastName: string,
    importantDates: Date[]): SecurityPolicyItemStatus[] {

    const status: SecurityPolicyItemStatus[] = [];

    if (!policy) {
      return status;
    }
    if (!importantDates) {
      importantDates = [];
    }

    if (policy.MinimumPasswordLength) {
      const item: SecurityPolicyItemStatus = { Item: "MinimumPasswordLength", Message: "Must be {{MinimumPasswordLength}} or more characters long", Variables: { MinimumPasswordLength: policy.MinimumPasswordLength }, Valid: true };
      item.Valid = (password1.length >= policy.MinimumPasswordLength);
      status.push(item);
    }
    if (policy.AlphaCharactersRequired) {
      const item: SecurityPolicyItemStatus = { Item: "AlphaCharactersRequired", Message: "Must include one or more alphabetic characters", Variables: {}, Valid: true };
      item.Valid = Helper.containsSomeAlpha(password1);
      status.push(item);
    }
    if (policy.MixedCaseRequired) {
      const item: SecurityPolicyItemStatus = { Item: "MixedCaseRequired", Message: "Must include a mix of upper and lower case characters", Variables: {}, Valid: true };
      item.Valid = (Helper.containsSomeAlphaUpper(password1) && Helper.containsSomeAlphaLower(password1));
      status.push(item);
    }
    if (policy.NumericCharactersRequired) {
      const item: SecurityPolicyItemStatus = { Item: "NumericCharactersRequired", Message: "Must include one or more numbers", Variables: {}, Valid: true };
      item.Valid = Helper.containsSomeNumber(password1);
      status.push(item);
    }
    if (policy.PunctuationRequired) {
      const item: SecurityPolicyItemStatus = { Item: "PunctuationRequired", Message: "Must include one or more special characters", Variables: {}, Valid: true };
      item.Valid = Helper.containsSomeSpecialCharacters(password1);
      status.push(item);
    }
    if (policy.PasswordCannotContainLogin && login) { // If we don't have a login we can't execute this test to report either way
      const item: SecurityPolicyItemStatus = { Item: "PasswordCannotContainLogin", Message: "Must not include the login name", Variables: {}, Valid: true };
      item.Valid = !Helper.contains(password1, login, true);
      status.push(item);
    }
    if (policy.PasswordCannotContainName && (contactName || firstName || lastName)) { // If we don't have a name we can't execute this test to report either way
      const item: SecurityPolicyItemStatus = { Item: "PasswordCannotContainName", Message: "Must not include name", Variables: {}, Valid: true };
      item.Valid = !(Helper.contains(password1, contactName, true) || Helper.contains(password1, firstName, true) || Helper.contains(password1, lastName, true));
      status.push(item);
    }
    if (policy.PasswordCannotContainImportantDates && importantDates && importantDates.length > 0) { // If we don't have important dates we can't execute this test to report either way
      const item: SecurityPolicyItemStatus = { Item: "PasswordCannotContainImportantDates", Message: "Must not include parts of important dates", Variables: {}, Valid: true };
      importantDates.forEach(date => {
        if (Helper.contains(password1, getYear(date).toString())) {
          item.Valid = false;
        } else if (Helper.contains(password1, getDate(date).toString())) {
          item.Valid = false;
        }
      });
      status.push(item);
    }
    if (doubleEntryPassword) {
      const item: SecurityPolicyItemStatus = { Item: "PasswordsMatch", Message: "Both passwords must match", Variables: {}, Valid: true };
      item.Valid = (password1 === password2);
      status.push(item);
    }

    return status;

  }

  public getGroupMembersFilter(groupId: number, filterProperty: string, includeGroupId: boolean = false) {
    const group = this.getGroup(groupId);
    if (group) {
      let queryString = "(";
      if (includeGroupId) {
        queryString += `${filterProperty} == ${groupId} ||`;
      }
      group.Members.forEach(member => {
        queryString += ` ${filterProperty} == ${member.MemberContactId} ||`;
      });
      queryString += `)`;
      return queryString.replace('||)', ')').replace('()', '');
    } else {
      return "";
    }
  }

  public getContactTypeCustomerPickListSelection(): m5core.PickListSelectionViewModel {
    const customer = new m5core.PickListSelectionViewModel();
    customer.DisplayText = "Customer";
    customer.Value = "C";
    return customer;
  }

  public getContactTypeProspectPickListSelection(): m5core.PickListSelectionViewModel {
    const prospect = new m5core.PickListSelectionViewModel();
    prospect.DisplayText = "Prospect";
    prospect.Value = "P";
    return prospect;
  }

  public getContactTypeMarketingPickListSelection(): m5core.PickListSelectionViewModel {
    const marketing = new m5core.PickListSelectionViewModel();
    marketing.DisplayText = "Marketing";
    marketing.Value = "M";
    return marketing;
  }

  /**
   * Presents a modal with an input select so the user can choose a new contact type for the given contact.
   * After the user makes a selection, an api call is made to change the contact type.
   * @param contactId - The contact id of the contact to change the type of
   * @param pickList - The pick list of contact types to choose from
   * @returns Observable<boolean> - True if the contact type was successfully changed, false otherwise
   */
  public changeContactTypeModal(
    contactId: number,
    pickList: m5core.PickListSelectionViewModel[]
  ): Observable<string> {

    const subject = new AsyncSubject<string>();

    const options = ModalCommonOptions.defaultDataEntryModalOptions();
    options.title = "Change Contact Type";
    options.size = "large";

    if (!contactId) {
      console.error("No contact id provided for change contact type modal.");
      subject.next("");
      subject.complete();
      return subject.asObservable();
    }

    if (pickList.length === 0) {
      console.error("No pick list provided for change contact type modal.");
      subject.next("");
      subject.complete();
      return subject.asObservable();
    }

    // Build the form
    const group = new FormGroupFluentModel("block");
    group.HasInputSelect("Contact Type", "data", "ContactType", "", "", false, pickList);
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { data: { ContactType: string } } = { data: { ContactType: "" } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ data: { ContactType: string } }>) => {

      if (!event.data.data.ContactType) {
        // They didn't select anything and then hit 'Ok' so don't do anything
        subject.next("");
        subject.complete();
        return subject.asObservable();
      }

      const apiData = { ContactId: contactId, ContactType: event.data.data.ContactType };

      const apiProp = Api.ContactTypeChange();
      const apiCall: ApiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Edit);
      this.apiService.execute(apiCall, apiData).subscribe(response => {
        if (response.Data.Success) {
          subject.next(event.data.data.ContactType);
        } else {
          this.appService.alertManager.addAlertFromApiResponse(response, apiCall);
          subject.next("");
        }
        subject.complete();
      });
    }, (reason) => {
      // User hit cancel so nothing to save
      subject.next("");
      subject.complete();
    });


    return subject.asObservable();
  };


  /**
   * This will route to a new form after a contact type change. EX: If a user was in a Marketing Contact form and changed the type
   * to prospects, this will route to the prospects form. This is necessary because the form will still have the Marketing header and
   * the url will still be /marketing-contacts, so any edits made will now be incorrect because we should be at the url for the propsect form now.
   * @param contactId
   * @param newContactType
   * @param defaultRouting if for some reason we can't determine the new contact type, this is where we will route as a fallback
   */
  public routeToNewFormAfterContactTypeChange(contactId: number, newContactType: string, defaultRouting: string) {
    // replaceUrl and remove from site history so we don't want the user hitting 'back' or being able to use site history
    // to navigate back to the previous form, which will no longer work if they try to edit it.
    if (newContactType === Constants.ContactType.Prospect) {
      this.appService.pageHistory.shift();
      this.router.navigate([`/prospects/edit/${contactId}`], { replaceUrl: true });
    } else if (newContactType === Constants.ContactType.Customer) {
      this.appService.pageHistory.shift();
      this.router.navigate([`/customers/edit/${contactId}`], { replaceUrl: true });
    } else if (newContactType === Constants.ContactType.Marketing) {
      this.appService.pageHistory.shift();
      this.router.navigate([`/marketing-contacts/edit/${contactId}`], { replaceUrl: true });
    } else {
      this.appService.pageHistory.shift();
      // Not sure, but it was successful so maybe go back to the editor?
      if (defaultRouting) {
        this.router.navigate([`${defaultRouting}`], { replaceUrl: true });
      }
    }
  }


}


export interface SecurityPolicyItemStatus {
  Item: string;
  Message: string;
  Variables: any;
  Valid: boolean;
}
