// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    padding: 0.25rem 1rem;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

.option-button {
  padding: 0rem 0rem;
  width: 90%;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.favorite-button {
  width: 10%;
  clear: both;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.non-favorite-button {
    width: 10%;
    clear: both;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    opacity: 0;
}

.button-container:hover .non-favorite-button {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/action-button/action-button.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;IACd,mBAAmB;IACnB,qBAAqB;IACrB,mBAAmB;IACnB,6BAA6B;IAC7B,SAAS;EACX;;AAEF;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;AACX;;AAEA;EACE,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;AACX;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,6BAA6B;IAC7B,SAAS;IACT,UAAU;AACd;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".button-container {\r\n    display: block;\r\n    width: 100%;\r\n    clear: both;\r\n    font-weight: 400;\r\n    padding: 0.25rem 1rem;\r\n    color: #212529;\r\n    text-align: inherit;\r\n    text-decoration: none;\r\n    white-space: nowrap;\r\n    background-color: transparent;\r\n    border: 0;\r\n  }\r\n\r\n.option-button {\r\n  padding: 0rem 0rem;\r\n  width: 90%;\r\n  clear: both;\r\n  font-weight: 400;\r\n  color: #212529;\r\n  text-align: left;\r\n  text-decoration: none;\r\n  white-space: nowrap;\r\n  background-color: transparent;\r\n  border: 0;\r\n}\r\n\r\n.favorite-button {\r\n  width: 10%;\r\n  clear: both;\r\n  white-space: nowrap;\r\n  background-color: transparent;\r\n  border: 0;\r\n}\r\n\r\n.non-favorite-button {\r\n    width: 10%;\r\n    clear: both;\r\n    white-space: nowrap;\r\n    background-color: transparent;\r\n    border: 0;\r\n    opacity: 0;\r\n}\r\n\r\n.button-container:hover .non-favorite-button {\r\n  opacity: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
