// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.design-mode {
  border: 1px dotted blue;
  margin: 1px;
  min-height: 20px;
}

.design-mode-icon {
  float: right;
  /*visually this looks better but jacks up our clickable area => margin-left: -15px;*/
  line-height: 0.5rem;
  font-size: 0.75rem;
  color: blue;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/form-render/form-control-render/form-control-render.component.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,oFAAoF;EACpF,mBAAmB;EACnB,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".design-mode {\r\n  border: 1px dotted blue;\r\n  margin: 1px;\r\n  min-height: 20px;\r\n}\r\n\r\n.design-mode-icon {\r\n  float: right;\r\n  /*visually this looks better but jacks up our clickable area => margin-left: -15px;*/\r\n  line-height: 0.5rem;\r\n  font-size: 0.75rem;\r\n  color: blue;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
