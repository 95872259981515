// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.less-huge {
  font-size: 24px;
}

.huge {
  font-size: 40px;
}

.tile {
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/tile/tile.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".less-huge {\r\n  font-size: 24px;\r\n}\r\n\r\n.huge {\r\n  font-size: 40px;\r\n}\r\n\r\n.tile {\r\n  margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
