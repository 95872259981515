import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from '../../ux-models';

@Component({
  selector: 'ib-peek',
  templateUrl: './peek.component.html',
  styleUrls: ['./peek.component.css']
})
export class PeekComponent implements OnInit {

  @Input() visible: boolean = false;
  @Input() clearFloats: boolean = true;
  @Input() buttonWrapperClass: string = "";
  @Input() buttonWrapperStyle: string = "";
  @Input() buttonClass: string = "";
  @Input() buttonStyle: string = "";

  @Output() click: EventEmitter<EventModel> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onSubmitClick($event) {
    this.visible = !this.visible;
    const payload: EventModel = new EventModel("change", $event, this.visible);
    this.click.emit(payload);
  }

}
