import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiProperties } from 'projects/core-lib/src/lib/api/ApiModels';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { FilterSelectionData } from '../filter-selection-data';
import { FormStatusService } from '../../../../../core-lib/src/lib/services/form-status.service';

@Component({
  selector: 'ib-filter-builder-modal',
  templateUrl: './filter-builder-modal.component.html',
  styleUrls: ['./filter-builder-modal.component.css'],
  providers: [FormStatusService]
})
export class FilterBuilderModalComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() options: ModalCommonOptions = new ModalCommonOptions();
  @Input() data: FilterSelectionData;
  @Input() objectName: string = "";
  @Input() filter: string = "";
  @Input() allowClearSelection: boolean = true;

  // public selectedFilter: m5.FilterEditViewModel = null;
  // public selectedFilters: m5.FilterEditViewModel[] = [];
  // public tableOptions: TableOptions = null;
  // public tableReloadCount: number = 0;

  protected validFor: string[] = [];
  public validForPickList: m5core.PickListSelectionViewModel[] = [];

  public apiProp: ApiProperties;

  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected sanitizer: DomSanitizer,
    protected ngbModalService: NgbModal,
    public modal: NgbActiveModal) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.apiProp = Api.Filter();
    this.validateParameters();
    this.validFor.push("ValidForReport");
    this.validForPickList.push({ Value: "ValidForReport", DisplayText: "Reports" } as m5core.PickListSelectionViewModel);
    this.validFor.push("ValidForAdHocQuery");
    this.validForPickList.push({ Value: "ValidForAdHocQuery", DisplayText: "Ad Hoc Queries" } as m5core.PickListSelectionViewModel);
    this.validFor.push("ValidForDataExport");
    this.validForPickList.push({ Value: "ValidForDataExport", DisplayText: "Data Exports" } as m5core.PickListSelectionViewModel);
    this.validFor.push("ValidForVisualComponent");
    this.validForPickList.push({ Value: "ValidForVisualComponent", DisplayText: "Tables" } as m5core.PickListSelectionViewModel);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.validateParameters();
  }

  // ngOnDestroy() {
  //  super.ngOnDestroy();
  // }

  /**
   * Validate input parameters to make sure they are supported values.
   */
  protected validateParameters() {

    if (!this.data) {
      this.data = {};
      this.data.FilterConditions = new m5.FilterConditionGroupViewModel();
      this.data.FilterConditions.ConditionBooleanOperator = "AND";
      this.data.FilterConditions.GroupBooleanOperator = "AND";
      this.data.ValidFor = this.validFor;
      this.data.SaveOption = "no";
    }
    if (!this.data.FilterConditions) {
      this.data = {};
      this.data.FilterConditions = new m5.FilterConditionGroupViewModel();
      this.data.FilterConditions.ConditionBooleanOperator = "AND";
      this.data.FilterConditions.GroupBooleanOperator = "AND";
      this.data.ValidFor = this.validFor;
      this.data.SaveOption = "no";
    }

    if (!this.options) {
      this.options = new ModalCommonOptions();
    }

    // We need text or icon on at least one of our buttons
    if (!this.options.okButtonText && !this.options.okButtonIcon && !this.options.cancelButtonText && !this.options.cancelButtonIcon) {
      this.options.okButtonText = "Ok";
    }

    // // If we have a filter id then we have a filter currently selected we want frozen at the top of the table
    // if (this.data && this.data.FilterId) {
    //   const apiCall: ApiCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Get);
    //   apiCall.silent = true;
    //   this.apiService.execute(apiCall, this.data.FilterId).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel>) => {
    //     if (!result.Data.Success) {
    //       // this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
    //     } else {
    //       this.selectedFilter = result.Data.Data;
    //     }
    //   });
    // }

    // this.tableOptions = this.getTableOptions();

    // console.error(this.form);
    // console.error(this.data);

  }

  // onRowSelected($event) {
  //   if ($event.data) {
  //     this.data.FilterId = $event.data.FilterId;
  //     this.data.Description = $event.data.Description;
  //     this.data.FilterConditions = $event.data.FilterConditions;
  //     // Log.errorMessage("Contact Selected", this.data);
  //   } else {
  //     Log.errorMessage("No data included with event", $event);
  //   }
  // }

  // getTableOptions(): TableOptions {

  //   // TODO accept api name and generic model type to see what properties we can turn into columns.
  //   const options = new TableOptions();
  //   options.tableId = null; // Don't save layout changes
  //   options.rowsPerPage = 10;
  //   options.apiName = "Filter";
  //   options.loadDataFromServer = true;
  //   options.theme = "striped";
  //   options.sort = "Description";
  //   const filter = `ObjectName == "${this.objectName}" && Enabled == 1 && ( Shared == 1 || ContactId == ${this.appService.userOrDefault.ContactId} )`;
  //   if (this.filter) {
  //     options.filter = `( ${this.filter} ) && ( ${filter} )`;
  //   } else {
  //     options.filter = filter;
  //   }
  //   options.rowsPerPageOptions = [5, 10, 15];
  //   options.rowSelectedAction = (row: any, selectedRows: any[], allRows: any[], cargo: any) => {
  //     // Set selected flag set off any of the rows visible and set on for the selected row
  //     if (allRows) {
  //       allRows.forEach((one) => {
  //         one.Selected = false;
  //       });
  //     }
  //     if (row) {
  //       row.Selected = true;
  //       this.selectedFilter = row;
  //       this.selectedFilters = [row];
  //     }
  //   };
  //   options.lazyLoadAction = (rows: any[], cargo: any) => {
  //     if (rows) {
  //       rows.forEach((one) => {
  //         one.Selected = (one.FilterId === this.data.FilterId);
  //       });
  //     }
  //   };

  //   // let contactIconData = IconHelper.iconDataFromContactType(contactType, false, true, true);
  //   /// / If we have more than one contact type allowed then we need a button to allow selecting the contact type
  //   // if (this.contactTypes && this.contactTypes.length > 1) {
  //   //  options.actionButtonLeft1 = new ButtonItem(contactIconData.title, contactIconData.iconDescription, "secondary");
  //   //  options.actionButtonLeft1.size = "sm";
  //   //  this.contactTypes.forEach(type => {
  //   //    let iconData = IconHelper.iconDataFromContactType(type, false, true, true);
  //   //    options.actionButtonLeft1.options.push(new Action(type, iconData.title, iconData.iconDescription, "", (event) => {
  //   //      //console.error("changing contact type", event);
  //   //      // In our event the element has information about the action and the action id is our contact type
  //   //      this.tableOptions = this.getTableOptions(event.element.id);
  //   //      // Trigger reloading the table as we have a new contact type selected
  //   //      this.tableReloadCount++;
  //   //    }));
  //   //  });
  //   // }

  //   options.columns.push(new TableColumnOptions("Selected", "", "boolean"));
  //   TableHelper.setColumnHeader(options.columns.slice(-1)[0], "", "check-square", "none", false);
  //   options.columns.push(new TableColumnOptions("ExternalFilterId", "Id"));
  //   options.columns.push(new TableColumnOptions("Description"));
  //   // options.columns.push(new TableColumnOptions("Email", "Email", "email"));
  //   // if (contactType === Constants.ContactType.Directory || contactType === Constants.ContactType.Group) {
  //   //  options.columns.push(new TableColumnOptions("", "Access", "function"));
  //   //  options.columns.slice(-1)[0].filterType = "none";
  //   //  options.columns.slice(-1)[0].sortable = false;
  //   //  options.columns.slice(-1)[0].render = (row: m5.ContactListViewModel) => {
  //   //    let html = "";
  //   //    let scopes: string[] = [];
  //   //    if (row && row.Flags && row.Flags.length > 0) {
  //   //      // Flags include more than just scope information so filter to only scope information for our purposes here
  //   //      scopes = row.Flags.filter(x => Helper.startsWith(x, "Scope:", true));
  //   //    }
  //   //    if (scopes.length === 0) {
  //   //      html = IconHelper.iconDataFromContactScope(Constants.ContactScope.InheritedFromGroup, false, false, true, "me-2").html;
  //   //    } else if (scopes.length === 1) {
  //   //      html = IconHelper.iconDataFromContactScope(scopes[0], false, false, true, "me-2").html;
  //   //    } else {
  //   //      scopes.forEach(scope => {
  //   //        // With more than 1 scope we hide the inherited scope and just focus on what we inherited
  //   //        if (scope === Constants.ContactScope.InheritedFromGroup || Helper.equals(scope, `Scope:${Constants.ContactScope.InheritedFromGroup}`, true)) {
  //   //          return; // return = continue
  //   //        }
  //   //        const one: string = IconHelper.iconDataFromContactScope(scope, false, false, true, "me-2").html;
  //   //        html += one;
  //   //      });
  //   //    }
  //   //    return this.sanitizer.bypassSecurityTrustHtml(html);
  //   //  };
  //   // }
  //   // options.columns.push(new TableColumnOptions("", "", "avatar"));
  //   // TableHelper.setColumnHeader(options.columns.slice(-1)[0], "", "image", "none", false);

  //   if (this.allowClearSelection) {
  //     // event.data => { data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }
  //     options.actionButtonRight1 = new ButtonItem(`Clear Selection`, "ban", "warning", (event) => {
  //       // console.error("clear selection", event);
  //       this.clearFilter(event);
  //     });
  //     options.actionButtonRight1.size = "sm";
  //   }

  //   return options;

  // }



  // addContact(event: any) {

  //  let contactIconData = IconHelper.iconDataFromContactType(this.currentContactType, false, true, true);

  //  let options = new ModalCommonOptions();
  //  options.size = "largest"; // options are: large, small, 80%.  Default is large.
  //  options.titleContextColor = "primary"; // options are: primary, secondary, info, success, warning, danger, light, dark
  //  options.titleIcon = contactIconData.iconDescription;
  //  options.title = `Add ${this.contactService.getContactTypeDescriptionLong(this.currentContactType)}`;
  //  if (this.currentContactType === Constants.ContactType.Contact && this.addressTypeDescription) {
  //    options.title = `Add ${this.addressTypeDescription}`;
  //  }
  //  options.okButtonContextColor = "primary"; // options are: primary, secondary, info, success, warning, danger, light, dark
  //  options.okButtonText = "Ok";
  //  options.cancelButtonContextColor = "secondary";
  //  options.cancelButtonText = "Cancel";
  //  options.validate = (options: ModalCommonOptions, okData: m5.ContactEditViewModel, errors: string[]) => {
  //    //let dates = [];
  //    //if (this.data.BirthDate) {
  //    //  dates.push(moment(this.data.BirthDate));
  //    //}
  //    //if (this.data.AnniversaryDate) {
  //    //  dates.push(moment(this.data.AnniversaryDate));
  //    //}
  //    //let status = this.contactService.getSecurityPolicyStatus(this.policy, true, okData.Login,
  //    //  okData.NewPassword, okData.NewPasswordConfirmed,
  //    //  this.getContactName(), this.data.FirstName, this.data.LastName, dates);
  //    //let bad = status.filter(x => !x.Valid);
  //    //if (bad && bad.length > 0) {
  //    //  let message: string = "<ul>";
  //    //  bad.forEach(err => {
  //    //    message += `<li>${Helper.stringFormat(err.Message, err.Variables)}</li>`;
  //    //  });
  //    //  message += "</ul>";
  //    //  // Show the errors
  //    //  this.uxService.modal.alertDanger("Errors", message);
  //    //  return false;
  //    //}
  //    return true;
  //  };

  //  // Note that since forms allow interacting with different data object types, data is always a container of objects
  //  // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
  //  // like data.Customer.Name, data.Invoice.Date, etc.
  //  const data: m5.ContactEditViewModel = new m5.ContactEditViewModel();
  //  data.ContactType = this.currentContactType;
  //  if (this.currentContactType === Constants.ContactType.Contact) {
  //    data.AddressType = this.getAddressType();
  //  }

  //  const modalRef = this.ngbModalService.open(InputContactFormModalComponent, ModalCommonOptions.toNgbModalOptions(options));
  //  // Set @Input() properties for our component being used as the modal content
  //  modalRef.componentInstance.options = options;
  //  modalRef.componentInstance.data = data;
  //  modalRef.componentInstance.contactType = this.currentContactType;

  //  // Set actions when modal promise is resolved with either ok or cancel
  //  modalRef.result.then((value: EventModelTyped<m5.ContactEditViewModel>) => {
  //    // User hit ok so value.data is the data object.
  //    const apiProp = Api.Contact();
  //    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
  //    this.apiService.call(apiCall, value.data).subscribe((response: IApiResponseWrapperTyped<m5.ContactEditViewModel>) => {
  //      if (response.Data.Success) {
  //        this.clearContact(event);
  //        (<any>response.Data.Data).Selected = true;
  //        this.selectedContact = response.Data.Data;
  //        this.selectedContacts = [response.Data.Data];
  //        this.data.ContactId = this.selectedContact.ContactId;
  //        this.data.ContactName = this.selectedContact.ContactName;
  //        this.data.ContactType = this.selectedContact.ContactType;
  //      } else {
  //        this.appService.alertManager.addAlertFromApiResponse(response, apiCall);
  //      }
  //    });
  //  }, (reason) => {
  //    // User hit cancel so nothing to save
  //  });

  // }



  // clearFilter(event) {
  //   this.data.FilterId = null;
  //   this.data.Description = "";
  //   this.data.FilterConditions = new m5.FilterConditionGroupViewModel();
  //   this.data.ValidFor = this.validFor;
  //   this.data.Icon = "";
  //   this.data.IconColor = "";
  //   this.data.TextColor = "";
  //   //this.selectedFilter = null;
  //   //this.selectedFilters = [];
  //   // if (event.data.selectedData) {
  //   //   event.data.selectedData.Selected = false;
  //   //   event.data.selectedData = null;
  //   // }
  //   // if (event.data.headerData && event.data.headerData.length > 0) {
  //   //   event.data.headerData[0].Selected = false;
  //   //   event.data.headerData = [];
  //   // }
  // }




}
