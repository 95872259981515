import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalConfig, ModalDismissReasons, NgbConfig } from '@ng-bootstrap/ng-bootstrap';
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { EventModel, EventElementModel, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { ModalCommonOptions } from './modal-common-options';
import { ModalSimpleAlertComponent } from './modal-simple-alert/modal-simple-alert.component';
import { ModalDynamicFormComponent } from './modal-dynamic-form/modal-dynamic-form.component';
import { FormGroupFluentModel } from '../form/form-fluent-model';
import { OverlaySimpleAlertComponent } from './overlay-simple-alert/overlay-simple-alert.component';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {


  protected overlayRef: OverlayRef;

  constructor(
    public ngbModalService: NgbModal,
    protected overlay: Overlay,
    public appService: AppService) {
    // protected factoryResolver: ComponentFactoryResolver) {
  }


  public alertInfo(title: string, message: string, onClose: (value: any) => any = null, size: "small" | "large" | "larger" | "largest" = "large"): Promise<any> {
    const options = new ModalCommonOptions();
    options.titleContextColor = "primary";
    options.titleIcon = "info-circle";
    options.okButtonContextColor = "primary";
    options.okButtonText = "Ok";
    options.size = size;
    return this.alert(title, message, onClose, options);
  }

  public alertWarning(title: string, message: string, onClose: (value: any) => any = null, size: "small" | "large" | "larger" | "largest" = "large"): Promise<any> {
    const options = new ModalCommonOptions();
    options.titleContextColor = "warning";
    options.titleIcon = "exclamation-triangle";
    options.okButtonContextColor = "warning";
    options.okButtonText = "Ok";
    options.size = size;
    Helper.fireAlertVibration();
    return this.alert(title, message, onClose, options);
  }

  public alertDanger(title: string, message: string, onClose: (value: any) => any = null, size: "small" | "large" | "larger" | "largest" = "large"): Promise<any> {
    const options = new ModalCommonOptions();
    options.titleContextColor = "danger";
    options.titleIcon = "exclamation-triangle";
    options.okButtonContextColor = "danger";
    options.okButtonText = "Ok";
    options.size = size;
    Helper.fireAlertVibration();
    return this.alert(title, message, onClose, options);
  }

  /**
  Show alert message and take appropriate action.
  onClose is the function that fires when the modal is closed
  @example
  var onClose = (value: any) => {
      // Do whatever we wanted to happen after the close.
  }
  */
  public alert(title: string, message: string, onClose: (value: any) => any = null, options: ModalCommonOptions = null): Promise<any> {

    if (!options) {
      options = new ModalCommonOptions();
      options.okButtonContextColor = "primary";
      options.okButtonText = "Ok";
    }

    // For parameters we were given put them in our options object
    if (title) {
      options.title = title;
    }
    if (message) {
      options.message = message;
    }
    if (onClose) {
      options.ok = onClose;
    }
    if (!options.size) {
      options.size = "large";
    }

    // Now show the modal using our options
    return this.showSimpleModal(options);

  }

  public confirmDelete(
    message: string, onDelete: (value: any) => any, onCancel: (value: any) => any = null, size: "small" | "large" | "larger" | "largest" = "large"
  ): Promise<any> {
    const options = new ModalCommonOptions();
    options.titleContextColor = "danger";
    options.titleIcon = "times";
    options.okButtonContextColor = "danger";
    options.okButtonText = "Delete";
    options.cancelButtonContextColor = "default";
    options.cancelButtonText = "Cancel";
    options.size = size;
    return this.confirm("Delete?", message, onDelete, onCancel, options);
  }

  public confirmRemove(
    message: string, onRemove: (value: any) => any, onCancel: (value: any) => any = null, size: "small" | "large" | "larger" | "largest" = "large"
  ): Promise<any> {
    const options = new ModalCommonOptions();
    options.titleContextColor = "danger";
    options.titleIcon = "times";
    options.okButtonContextColor = "danger";
    options.okButtonText = "Remove";
    options.cancelButtonContextColor = "default";
    options.cancelButtonText = "Cancel";
    options.size = size;
    return this.confirm("Remove?", message, onRemove, onCancel, options);
  }

  public confirmWarningYesNo(
    title: string, message: string, onYes: (value: any) => any, onNo: (value: any) => any = null, size: "small" | "large" | "larger" | "largest" = "large"
  ): Promise<any> {
    const options = new ModalCommonOptions();
    options.titleContextColor = "warning";
    options.titleIcon = "exclamation-triangle";
    options.okButtonContextColor = "warning";
    options.okButtonText = "Yes";
    options.cancelButtonContextColor = "default";
    options.cancelButtonText = "No";
    options.size = size;
    return this.confirm(title, message, onYes, onNo, options);
  }

  public confirmInfoYesNo(
    title: string, message: string, onYes: (value: any) => any, onNo: (value: any) => any = null, size: "small" | "large" | "larger" | "largest" = "large"
  ): Promise<any> {
    const options = new ModalCommonOptions();
    options.titleContextColor = "primary";
    options.titleIcon = "question-circle";
    options.okButtonContextColor = "primary";
    options.okButtonText = "Yes";
    options.cancelButtonContextColor = "default";
    options.cancelButtonText = "No";
    options.size = size;
    return this.confirm(title, message, onYes, onNo, options);
  }

  public confirmUnsavedChanges(title: string = "", message: string = ""): Promise<any> {
    // console.error("confirm unsaved changes modal", Log.getStackTrace());
    const options = new ModalCommonOptions();
    options.titleContextColor = "danger";
    options.titleIcon = "exclamation-circle";
    options.okButtonContextColor = "danger";
    options.okButtonText = "Discard Changes";
    options.cancelButtonContextColor = "default";
    options.cancelButtonText = "Cancel";
    options.size = "large";
    options.title = (title || "Unsaved Changes");
    options.message = (message || "You have changes that have not been saved.  <strong class='text-danger'>If you proceed, all of your changes will be lost</strong>.  Are you sure you want to do this?");
    return this.showSimpleModal(options);
  }

  public confirm(title: string, message: string, onOk: (value: any) => any, onCancel: (value: any) => any = null, options: ModalCommonOptions = null): Promise<any> {

    if (!options) {
      options = new ModalCommonOptions();
      options.okButtonContextColor = "primary";
      options.okButtonText = "Ok";
      options.cancelButtonContextColor = "default";
      options.cancelButtonText = "Cancel";
    }

    // For parameters we were given put them in our options object
    if (title) {
      options.title = title;
    }
    if (message) {
      options.message = message;
    }
    if (onOk) {
      options.ok = onOk;
    }
    if (onCancel) {
      options.cancel = onCancel;
    }
    if (!options.size) {
      options.size = "large";
    }

    // Now show the modal using our options
    return this.showSimpleModal(options);

  }

  public showSimpleModal(options: ModalCommonOptions): Promise<any> {

    if (!options) {
      options = new ModalCommonOptions();
      options.message = "There was no modal common options object provided.<br/><br/><small>";
      const trace = Log.getStackTrace();
      trace.forEach((line) => {
        options.message += line + "<br/>";
      });
      options.message += "</small>";
      options.okButtonContextColor = "primary";
      options.okButtonText = "Ok";
    }

    // Default callback functions
    if (!options.ok) {
      options.ok = (value: any) => { };
    }
    if (!options.cancel) {
      options.cancel = (value: any) => { };
    }

    const ngbConfig: NgbConfig = new NgbConfig();
    const modalOptions: NgbModalOptions = new NgbModalConfig(ngbConfig);

    modalOptions.backdrop = "static";
    if (Helper.equals(options.size, "large", true) || Helper.equals(options.size, "lg", true)) {
      modalOptions.size = "lg";
    } else if (Helper.equals(options.size, "larger", true) || Helper.equals(options.size, "xl", true)) {
      modalOptions.size = "xl";
    } else if (Helper.equals(options.size, "small", true) || Helper.equals(options.size, "sm", true)) {
      modalOptions.size = "sm";
    } else if (Helper.equals(options.size, "largest", true)) {
      (<any>modalOptions).size = "largest"; // Custom size
    }

    // console.error("modal options");
    // console.error(options);

    // Open the modal with our modal simple alert component
    const modalRef = this.ngbModalService.open(ModalSimpleAlertComponent, modalOptions);
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;

    // Example of how we might use the promise we return here
    /*
    let model: EventModel = null;
    modalRef.result.then((value) => {
      model = ModalService.fromSimpleModalResultToEventModel(value);
    }, (reason) => {
      model = ModalService.fromSimpleModalResultToEventModel(reason);
      });
    */

    return modalRef.result;

  }


  public showSimpleOverlay(options: ModalCommonOptions): Promise<any> {

    if (!options) {
      options = new ModalCommonOptions();
      options.message = "There was no modal common options object provided.<br/><br/><small>";
      const trace = Log.getStackTrace();
      trace.forEach((line) => {
        options.message += line + "<br/>";
      });
      options.message += "</small>";
      options.okButtonContextColor = "primary";
      options.okButtonText = "Ok";
    }

    // Default callback functions
    if (!options.ok) {
      options.ok = (value: any) => { };
    }
    if (!options.cancel) {
      options.cancel = (value: any) => { };
    }

    // Try #1
    // Open the modal with our modal simple alert component
    // const overlay = new OverlaySimpleAlertComponent(options);
    // overlay.show();

    // Try #2
    // @ViewChild('placeHolder', {read: ViewContainerRef}) private viewContainer: ElementRef;
    // let factory = this.factoryResolver.resolveComponentFactory(OverlaySimpleAlertComponent);
    // let cmp = this.viewContainer.createComponent(factory);
    // cmp.instance.options = options;

    // Try #3
    // this.appService.alertManager.overlayOptions = options;

    // Try #4
    this.overlayRef = this.overlay.create({
      width: "100%"
    });
    // height: "400px",
    const componentPortal = new ComponentPortal(OverlaySimpleAlertComponent);
    // this.overlayRef.addPanelClass("modal-dialog");
    const componentRef = this.overlayRef.attach(componentPortal);

    options.ok = (value: any) => {
      componentRef.destroy();
    };

    // Set @Input() properties and detect changes
    componentRef.instance.options = options;
    componentRef.changeDetectorRef.detectChanges();

    return;

  }


  public showDynamicFormModal(options: ModalCommonOptions, form: m5web.FormEditViewModel, data: any): Promise<any> {

    if (!options) {
      options = new ModalCommonOptions();
      options.okButtonContextColor = "primary";
      options.okButtonText = "Ok";
    }

    // Default callback functions
    if (!options.ok) {
      options.ok = (value: any) => { };
    }
    if (!options.cancel) {
      options.cancel = (value: any) => { };
    }

    const ngbConfig: NgbConfig = new NgbConfig();
    const modalOptions: NgbModalOptions = new NgbModalConfig(ngbConfig);

    modalOptions.backdrop = "static";
    if (Helper.equals(options.size, "large", true) || Helper.equals(options.size, "lg", true)) {
      modalOptions.size = "lg";
    } else if (Helper.equals(options.size, "small", true) || Helper.equals(options.size, "sm", true)) {
      modalOptions.size = "sm";
    } else if (Helper.equals(options.size, "larger", true) || Helper.equals(options.size, "xl", true)) {
      (modalOptions as any).size = "larger";
    } else if (Helper.equals(options.size, "largest", true)) {
      (modalOptions as any).size = "largest";
    }

    // console.error("modal options");
    // console.error(options);

    // Open the modal with our modal simple alert component
    const modalRef = this.ngbModalService.open(ModalDynamicFormComponent, modalOptions);
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.form = form;
    modalRef.componentInstance.data = data;

    // Example of how we might use the promise we return here
    /*
    let model: EventModel = null;
    modalRef.result.then((value) => {
      model = ModalService.fromSimpleModalResultToEventModel(value);
    }, (reason) => {
      model = ModalService.fromSimpleModalResultToEventModel(reason);
      });
    */

    return modalRef.result;

  }

  /**
   * Show modal to edit a string.
   * @param label
   * @param value
   * @param rows
   * @param size
   * @param title
   * @returns promise that can be used to access edited value.
   * @example
   * let promise = this.uxService.modal.showModalEditString("Name", data.Name);
   * promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
   *  // value can be found here: event.data.Input.Item
   * }, (reason) => {
   *  // User hit cancel so nothing to save
   * });
   */
  public showModalEditString(
    label: string,
    value: string,
    rows: number = 1,
    size: "small" | "large" | "largest" = "large",
    title: string = "",
    required: boolean = false,
    htmlIntroduction: string = "",
    htmlIntroductionClasses: string = ""
  ) {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = size;
    options.title = title || label;

    // Build the form
    const group = new FormGroupFluentModel("block");
    if (htmlIntroduction) {
      // If html introduction is just plain text and we have no classes specified then provide a default
      // class of mb-2 to provide some bottom margin which will provide a better display so the introduction
      // text is not stacked so close to the input.
      if (!Helper.isHtml(htmlIntroduction) && !htmlIntroductionClasses) {
        htmlIntroductionClasses = "mb-2";
      }
      // Add control for the introduction text
      group.HasHtml(htmlIntroduction, "W", htmlIntroductionClasses);
    }
    if (rows <= 1) {
      group.HasInputString(label, label, "Input", "Item", "W", required);
    } else {
      group.HasInputLongString(label, label, "Input", "Item", rows, "W", required);
    }
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Input: { Item: string } } = { Input: { Item: value } };

    const promise = this.showDynamicFormModal(options, form, payload);
    // promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
    //  // value can be found here: event.data.Input.Item
    // }, (reason) => {
    //  // User hit cancel so nothing to save
    // });

    return promise;

  }

  /**
   * Show modal to edit a pick list value.
   * @param label
   * @param value
   * @param pickListId
   * @param size
   * @param title
   * @returns promise that can be used to access edited value.
   * @example
   * let promise = this.uxService.modal.showModalEditPickListValue("Select Value", data.PickListValue, "OptionalPickListIdString", "large", "Title for modal", optionalPickListArray);
   * promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
   *  // value can be found here: event.data.Input.Item
   * }, (reason) => {
   *  // User hit cancel so nothing to save
   * });
   */
  public showModalEditPickListValue(label: string, value: string, pickListId: string,
    size: "small" | "large" | "largest" = "large",
    title: string = "",
    pickList: m5core.PickListSelectionViewModel[] = null,
    htmlIntroduction: string = "",
    htmlIntroductionClasses: string = "",
    pickListIncludeNone: boolean = true,
    suffixIcon: string = "",
    suffixText: string = "",
    suffixHelpText: string = "",
    suffixClickEvent: string = "") {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = size;
    options.title = title || label;

    // Build the form
    const group = new FormGroupFluentModel("block");
    if (htmlIntroduction) {
      group.HasHtml(htmlIntroduction, "W", htmlIntroductionClasses);
    }
    group.HasInputSelect(label, "Input", "Item", pickListId, "W", false, pickList);
    group.LastControl().OptionsIncludeNone = pickListIncludeNone;
    group.LastControl().SuffixIcon = suffixIcon;
    group.LastControl().SuffixText = suffixText;
    group.LastControl().SuffixHelpText = suffixHelpText;
    group.LastControl().SuffixClickEvent = suffixClickEvent;
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Input: { Item: string } } = { Input: { Item: value } };

    const promise = this.showDynamicFormModal(options, form, payload);
    // promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
    //  // value can be found here: event.data.Input.Item
    // }, (reason) => {
    //  // User hit cancel so nothing to save
    // });

    return promise;

  }


  public showModalEditMultiplePickListValues(label: string, value: string[], pickListId: string,
    size: "small" | "large" | "largest" = "large",
    title: string = "",
    pickList: m5core.PickListSelectionViewModel[] = null,
    htmlIntroduction: string = "",
    htmlIntroductionClasses: string = "",
    pickListIncludeNone: boolean = false) {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = size;
    options.title = title || label;

    // Build the form
    const group = new FormGroupFluentModel("block");
    if (htmlIntroduction) {
      group.HasHtml(htmlIntroduction, "W", htmlIntroductionClasses);
    }
    group.HasInputMultiSelect(label, "Input", "Item", pickListId, "W", false, pickList);
    group.LastControl().OptionsIncludeNone = pickListIncludeNone;
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Input: { Item: string[] } } = { Input: { Item: value } };

    const promise = this.showDynamicFormModal(options, form, payload);
    // promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
    //  // value can be found here: event.data.Input.Item
    // }, (reason) => {
    //  // User hit cancel so nothing to save
    // });

    return promise;

  }


  public showModalEditListBoxPickListValue(label: string, value: string, pickListId: string,
    size: "small" | "large" | "largest" = "large",
    title: string = "",
    pickList: m5core.PickListSelectionViewModel[] = null,
    htmlIntroduction: string = "",
    htmlIntroductionClasses: string = "",
    pickListIncludeNone: boolean = true) {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = size;
    options.title = title || label;

    // Build the form
    const group = new FormGroupFluentModel("block");
    if (htmlIntroduction) {
      group.HasHtml(htmlIntroduction, "W", htmlIntroductionClasses);
    }
    group.HasInputOther(label, label, "Input", "Item", "InputListBox", "W", false);
    group.LastControl().OptionsPickList = pickList;
    group.LastControl().OptionsIncludeNone = pickListIncludeNone;
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Input: { Item: string } } = { Input: { Item: value } };

    const promise = this.showDynamicFormModal(options, form, payload);
    // promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
    //  // value can be found here: event.data.Input.Item
    // }, (reason) => {
    //  // User hit cancel so nothing to save
    // });

    return promise;

  }


  dismissAllModals(reason: any = undefined) {
    this.ngbModalService.dismissAll(reason);
  }


  /**
   * This function takes a simple modal result as provided in either promise fulfilled or rejected results.
   * This helper function can be used with the promise returned by the modal methods exposed in the service.
   * For example:
   * let promise = modalService.confirmInfoYesNo( ... );
   * let model: EventModel = null;
   * promise.then((value) => {
   *   model = ModalService.fromSimpleModalResultToEventModel(value);
   * }, (reason) => {
   *   model = ModalService.fromSimpleModalResultToEventModel(reason);
   * });
   * @param result
   */
  public static fromSimpleModalResultToEventModel(result: any): EventModel {

    let model: EventModel = null;

    // Handle undefined or null
    if (result === undefined || result === null) {
      model = new EventModel("unknown", null, false, new EventElementModel("modal", null, "Unknown", "Unknown"));
      return model;
    }

    // Often our result is already an EventModel object
    if (Helper.objectHasProperties(result, "element", "event", "eventType")) {
      return result;
    }

    // Sometimes our result may be a modal dismiss reason enum
    if (result === ModalDismissReasons.ESC) {
      model = new EventModel("keyUp", result, false, new EventElementModel("modal", null, "EscapeKey", "Escape"));
      return model;
      // console.error("escape result");
      // console.error(this.LastModalCloseEvent);
    } else if (result === ModalDismissReasons.BACKDROP_CLICK) {
      // We really shouldn't get this as we typically disable this feature since it's too easy to accidentally close a modal this way
      model = new EventModel("click", result, false, new EventElementModel("modal", null, "BackdropClick", "Backdrop"));
      return model;
    }

    // Unknown....
    model = new EventModel("unknown", result, false, new EventElementModel("modal", null, "Unknown", "Unknown"));
    return model;

  }


}


// TODO simple data entry via modal... probably cannot do dynamic html form with Angular 6 and AOT
// so work up model that will describe form including tabs, columns, controls, etc.
/*

  public static basicDataModalEditor(data: any, options: CommonModalOptions): void {

    if (!options) {
      options = new CommonModalOptions();
      options.okButtonStyle = "primary";
      options.okButtonText = "Ok";
      options.cancelButtonStyle = "default";
      options.cancelButtonText = "Cancel";
    }
    if (!options.size) {
      options.size = "lg";
    }

    // Default callback functions
    if (!options.okAction) {
      options.okAction = (value: any) => { };
    }
    if (!options.cancelAction) {
      options.cancelAction = (value: any) => { };
    }

    let $injector = null; // TODO //Helper.GetInjector();
    let $modal = $injector.get('$modal');
    // TODO fix this
    //let modalInstance = $modal.open({
    //  template: options.template,
    //  templateUrl: options.templateUrl,
    //  controller: "BasicDataEditorModalController as $ctrl",
    //  backdrop: "static",
    //  size: options.size,
    //  resolve: {
    //    data: function () { return Helper.deepCopy(data); }, // Copy object so we're not doing deep edit that prevents ability to cancel the edit
    //    options: function () { return options; }
    //  }
    //});

    // will execute inside the modal when button is clicked... modalInstance.result.then(options.okAction, options.cancelAction);

  }

  // Function to ask for approved flag and comments.  Use onOk to record approval status and comments from the current user.
  public static askForApproval = (data: { approved: boolean, comments: string }, onOk: (value: any) => any, onCancel: (value: any) => any = null) => {
  let options: CommonModalOptions = new CommonModalOptions();
  options.title = "Approval";
  options.okButtonText = "Ok";
  options.cancelButtonText = "Cancel";
  options.template = `
<common-modal-title options="$ctrl.options"></common-modal-title>
<div class="modal-body">
    <form name="$ctrl.appForm" novalidate class="form-horizontal app-form" role="form">
        <div class="{{Constants.Layout.fullWidth}}">
            <input-check-box label="Approved" ng-model="$ctrl.data.approved"></input-check-box>
            <input-text label="Comments" lines="10" ng-model="$ctrl.data.comments"></input-text>
        </div>
    </form>
</div>
<common-modal-buttons options="$ctrl.options" on-ok="$ctrl.ok()" on-cancel="$ctrl.cancel()"></common-modal-buttons>
`;
  options.okAction = onOk;
  options.cancelAction = onCancel;
  Modal.basicDataModalEditor(data, options);
}



*/
