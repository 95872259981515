import { Component, Input } from '@angular/core';
import { NavService } from '../nav/nav.service';
import { TaskListStatusViewModel } from 'projects/core-lib/src/lib/models/ngModels5';
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { takeUntil } from 'rxjs';
import { NgbPlacement } from 'projects/core-lib/src/lib/models/model-helpers';
import { AvatarComponent, AvatarTooltipPlacement } from '../../image/avatar/avatar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  imports: [AvatarComponent, NgStyle, NgIf, NgClass, RouterLink, DecimalPipe, NgbModule],
  selector: 'ib-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.css']
})
export class UserOptionsComponent extends BaseComponent {

  @Input() dropdownPlacement: NgbPlacement = "bottom-right";
  @Input() buttonTooltipPlacement: NgbPlacement = "left";
  @Input() avatarTooltipPlacement: AvatarTooltipPlacement = "top";

  public taskStatus: TaskListStatusViewModel = new TaskListStatusViewModel();

  /** Currently authenticated user. */
  public get user(): m5sec.AuthenticatedUserViewModel {
    return this.appService.user;
  }

  get userName(): string {
    return this.appService.userOrDefault.ContactName;
  }

  /** If true, the user is logged in. */
  isLoggedIn: boolean = false;
  /** Controls showing 'Profile' in the dropdown menu */
  showProfileOption: boolean = false;
  /** Controls showing 'My App Settings' in the dropdown menu */
  showMyAppSettingsOption: boolean = false;
  /** Controls showing 'Tasks' in the dropdown menu */
  showTasksOption: boolean = false;
  /** Controls showing 'Billing' in the dropdown menu */
  showBillingOption: boolean = false;
  /** This is one of the checks for showing a dropdown divider above logout, but I dont see it as a button */
  showBookmarkOption: boolean = false;
  /** Controls showing 'Site Map' in the dropdown menu. This is for when in mobile portrait. */
  showSiteMapOption: boolean = false;

  constructor(
    public nav: NavService,
    protected appService: AppService
  ) {
    super();
  }

  ngOnInit(): void {
    // These don't have a chance to change so we only set them once
    this.showProfileOption = this.nav.showProfileOption;
    this.showMyAppSettingsOption = this.nav.showMyAppSettingsOption;
    this.showBookmarkOption = this.nav.showBookmarkOption;
    this.showTasksOption = this.nav.showTasksOption;
    this.showBillingOption = this.nav.showBillingOption;
    this.showSiteMapOption = this.nav.showSiteMapOption;
  }


  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.appService.taskStatusMonitor().pipe(takeUntil(this.ngUnsubscribe)).subscribe((status: TaskListStatusViewModel) => {
      if (status) {
        this.taskStatus = status;
      }
    });
  }

  logout() {
    this.appService.logout();
  }

  onDropDownOpenChange(open: boolean) {
    // This could potentially change so we check it when they open the dropdown
    if (open) {
      this.isLoggedIn = this.nav.isLoggedIn;
    }
  }
}
