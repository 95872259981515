import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { FilterSelectionData } from '../filter-selection-data';
import { ApiCall, ApiProperties, ApiOperationType, IApiResponseWrapperTyped, Query, IApiResponseWrapper } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ButtonItem, EventModel, Action, EventElementModel, EventModelTyped } from '../../ux-models';
import { Helper, Log } from '../../../../../core-lib/src/lib/helpers/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { FilterBuilderModalComponent } from '../filter-builder-modal/filter-builder-modal.component';
import { QueryService } from '../../../../../core-lib/src/lib/services/query.service';
import { UxService } from '../../services/ux.service';
import { PickListSelectionViewModel } from 'projects/core-lib/src/lib/models/ngModelsCore5';

/** Used as the PickList Description and Value for when there is a Custom Filter with the MultiSelect */
const CUSTOM_FILTER = "Custom Filter";

@Component({
  selector: 'ib-filter-selection-button',
  templateUrl: './filter-selection-button.component.html',
  styleUrls: ['./filter-selection-button.component.css']
})
export class FilterSelectionButtonComponent extends BaseComponent implements OnInit {
  private _containerWidth: number = 300;

  @Input() objectName: string = "";
  @Input() data: FilterSelectionData = {};
  @Input() size: "" | "lg" | "sm" | "xs" | "xxs" = "sm";
  @Input() fullWidth: boolean = false;
  @Input() scope: "ValidForReport" | "ValidForAdHocQuery" | "ValidForDataExport" | "ValidForVisualComponent" | "" = "ValidForVisualComponent";

  // #region multipleFiltersInputs
  /** If true, the button is replaced with a multiselect drop down of filters to pick. */
  @Input() allowMultipleFilters: boolean = false;
  /**
   * For Multiple Filters: this controls the width of the container so choosing multiple filters
   * doesn't cause the container to grow too wide.  Min value is 100px with no upper limit.
   */
  @Input() set containerWidthInPx(value: number) {
    if (value < 100) {
      this._containerWidth = 100;
    } else {
      this._containerWidth = value;
    }
  }
  get containerWidthInPx(): number {
    return this._containerWidth;
  }

  get containerWidth(): string {
    if (this.fullWidth) {
      return "100%";
    } else {
      return `${this.containerWidthInPx}px`;
    }
  }

  /** For Multiple Filters: this is the placeholder for the filter bar when no filters are selected. */
  @Input() placeholder: string = "Select Filters";

  /**
   * For Multiple Filters: this is the max number of labels that will display in the bar. If this value is exceeded,
   * the bar will display the selectedItemsLabel instead. If no maxSelectedLabels is set, then the bar will show
   * the selectedItemsLabel after the first selection. If the maxSelectedLabels is set high, then the bar will
   * expand greatly to accommodate the labels.
   */
  @Input() maxSelectedLabels: number = 4;

  /**
   * For Multiple Filters: Label to display after exceeding max selected labels. Must be in this format:
   * "{0} your phrase here" and the {0} will be replaced with the number of items selected. If this
   * value is an empty string, and the maxSelectedLabels is exceeded, the bar will be empty.
   */
  @Input() public selectedItemsLabel: string = "{0} filters selected";

  /** If this is incremented, the multiselect will be closed. */
  @Input() closeCounter: number = 0;
  // #endregion

  @Output() change: EventEmitter<EventModel> = new EventEmitter();

  /**
   * If 'allowMultipleFilters' is false, this is the button that will be used for displaying the currently selected filter,
   * displaying a list of filters the user can select, and a button for building a new filter.
   */
  singleFilterButton: ButtonItem = null;

  /**
   * If 'allowMultipleFilters' is true, this is the 'Build Filter' button that will appear inside the
   * multiselect header.
   */
  multiSelectBuildFilterButton: ButtonItem = null;

  /*
   * Option to allow search in button drop down menu when it's a long list
   */
  allowSearch: boolean = false;
  /**
   * This is a copy of data.filterIds but as strings. Usually if a multiselect was binding to an array of numbers we'd set
   * [optionsValueIsInteger]="true" and it manages the array for us based on what got checked or unchecked via 2 way binding.
   * Instead, we use selectedFilters because we allow the user to create a custom filter, which we add to the multiselect picklist,
   * but it's not information that should be passed back to the parent, IE we can't let the value for 'Custom Filter' get back
   * into data.filterIds. This adds more work because we need to monitor the multi-select for changes and update data.filterIds
   * accordingly, but now any parent that uses this component doesn't need to worry about ignoring a value in there that's
   * not an actual filter id.
   */
  selectedFilters: string[] = [];

  apiProp: ApiProperties;
  apiCall: ApiCall;
  filters: m5.FilterEditViewModel[] = [];
  /** The filters converted to a picklist for the multiple filters feature. */
  filtersPicklist: PickListSelectionViewModel[] = [];
  filterToggleState: boolean = false;
  enableAllOrAnyToggle: boolean = false;

  /**
   * For Multiple Filters: this holds the custom filter because if the user unselects the custom filter
   * in the multiselect, we have to clear it from this.data.FilterConditions so the parent doesn't apply a custom filter.
   * But if they reselect it, we need to have it stored somewhere so we can reapply it.
   */
  tempCustomFilter: FilterSelectionData = null;

  /**
   * If only one filter is selected, this holds the data for it, that way if the user clicks 'Build Filter' they have a chance
   * to edit that filter, whether it's a custom one or a previously saved filter.
   */
  onlySelectedFilter: FilterSelectionData = null;

  constructor(
    protected appService: AppService,
    protected apiService: ApiService,
    protected uxService: UxService,
    protected queryService: QueryService,
    protected ngbModalService: NgbModal) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.allowMultipleFilters) {
      this.singleFilterButton = this.getSingleFilterButton();
    } else {
      this.multiSelectBuildFilterButton = this.getMultiSelectBuildFilterButton();
      this.tempCustomFilter = this.resetDataToDefault();
      this.onlySelectedFilter = this.resetDataToDefault();
    }
    // this.loadFilters();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.data && !this.data) {
      this.data = {};
      this.data.SaveOption = "no";
    } else if (changes.data && this.data && this.allowMultipleFilters) {
      this.syncSelectedFiltersWithFilterIds();
      if (this.data.FilterIdsBooleanCriteria && this.data.FilterIdsBooleanCriteria === "and") {
        this.filterToggleState = true;
      } else {
        this.filterToggleState = false;
      }

      this.determineIfToggleShouldBeEnabled();
    }

    if (changes.objectName && this.objectName) {
      // TODO: MultipleFilter issue: unsure how to handle. The expected behavior is if there is one filter selected
      // and the user clicks 'Build Filter', it should open the filter builder with the data for the only selected filter.
      // But if the filters aren't loaded yet, we can't do that. So if filters are still loading and the user clicks
      // build filter and one is selected, the filter builder will open with a default state and it may not make sense
      // to the user why they can't edit their selected filter.
      this.loadFilters();
    }
  }

  /**
   * Keeps the selectedFilters array in sync with the data.FilterIds array. There's a conversion because
   * FilterIds is an array of numbers and selectedFilters is an array of strings.
   */
  syncSelectedFiltersWithFilterIds() {
    if (!this.allowMultipleFilters) {
      return;
    }

    // data is an interface where every property is optional so we need to check if a property exists before we try to access it
    if (!this.data || !this.data.FilterIds) {
      this.selectedFilters = [];
      return;
    }


    this.selectedFilters = this.data.FilterIds.map(x => x.toString());
    const customIndex = this.selectedFilters.findIndex(x => x === CUSTOM_FILTER);

    // Since Map returns a new array, we need to check and add Custom Filter if it needs to be there,
    // since when we Sync back to FilterIds, we purposefully exclude letting Custom Filter get in there.
    // We may have just pushed Custom Filter to selectedFilters after a change in the multiselect, but after
    // the parent gets that change, in case it changes the reference on the data, we need to make sure to
    // handle putting the custom filter back in.
    if (this.tempCustomFilter && customIndex === -1) {
      this.selectedFilters.unshift(CUSTOM_FILTER);
      this.selectedFilters = [...this.selectedFilters];
    }

    this.setDataForFilterBuilder();
    // console.log("sync selectedFilters with data.FilterIds: ", this.selectedFilters);
  }

  /**
   * Keeps the data.FilterIds array in sync with the selectedFilters array. There's a conversion because
   * FilterIds is an array of numbers and selectedFilters is an array of strings.
   */
  syncFilterIdsWithSelectedFilters() {
    if (!this.allowMultipleFilters) {
      return;
    }

    // We cant let the Custom Filter get back into FilterIds. The design is to let the parent interpret if there
    // is a custom filter by looking at properties like FilterConditions.
    const noCustomFilters = this.selectedFilters.filter(x => x !== CUSTOM_FILTER);
    this.data.FilterIds = noCustomFilters.map(x => parseInt(x, 10));
    this.tempCustomFilter.FilterIds = this.data.FilterIds;
    // console.log("sync data.FilterIds from selectedFilters: ", this.data.FilterIds);
  }

  loadFilters(ignoreCache: boolean = false) {
    if (!this.objectName) {
      Log.errorMessage("Cannot load filters for filter selection button due to no object name being assigned.");
      return;
    }
    if (!this.apiProp) {
      this.apiProp = Api.Filter();
    }
    if (!this.apiCall) {
      this.apiCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.List);
      this.apiCall.silent = true;
    }
    this.apiCall.cacheIgnoreOnRead = ignoreCache;
    const query = new Query();
    query.Size = this.Constants.RowsToReturn.All;
    if (this.scope) {
      query.Filter = `ObjectName == "${this.objectName}" && Enabled == 1 && ${this.scope} == 1 && ( Shared == 1 || ContactId == ${this.appService.userOrDefault.ContactId} )`;
    } else {
      query.Filter = `ObjectName == "${this.objectName}" && Enabled == 1 && ( Shared == 1 || ContactId == ${this.appService.userOrDefault.ContactId} )`;
    }
    this.apiService.execute(this.apiCall, query).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel[]>) => {
      if (!result.Data.Success) {
        // this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        Log.errorMessage(result);
      } else {
        this.filters = Helper.arraySort(result.Data.Data, "Description", false, true);
        this.convertFiltersToPickList();
        this.singleFilterButton = this.getSingleFilterButton();

        if (this.allowMultipleFilters) {
          this.setDataForFilterBuilder();
        }
      }
    });
  }

  /** The user selected or unselected filters in the multiselect. */
  onMultiFilterChange($event) {
    // console.log("onMultiFilterChange: ", $event)

    this.syncFilterIdsWithSelectedFilters();

    // We can't see what the user changed. We can only see what selectedFilters looks like now.
    // If we see 'Custom Filter', we need 'data' to be set (aside from FilterIds) so the parent can apply a custom filter.
    // If we don't see 'Custom Filter', we need to null out 'data' (aside from FilterIds) so the parent doesn't apply a custom filter when it shouldn't.
    // Maybe this is already set, because they didn't click or unclick 'Custom Filter', but it doesn't hurt regardless
    // to make sure it's set correctly.
    const customFilterIndex = this.selectedFilters.findIndex(x => x === CUSTOM_FILTER);
    if (customFilterIndex === -1) {
      this.data.FilterConditions = null;
      this.data.SaveOption = "no";
    } else if (customFilterIndex !== -1) {
      this.data.FilterConditions = this.tempCustomFilter.FilterConditions;
      this.data.SaveOption = this.tempCustomFilter.SaveOption;
    }

    if (this.filterToggleState) {
      this.data.FilterIdsBooleanCriteria = "and";
    } else {
      this.data.FilterIdsBooleanCriteria = "or";
    }

    if (this.selectedFilters.length > 1) {
      this.enableAllOrAnyToggle = true;
    } else {
      this.enableAllOrAnyToggle = false;
    }

    this.setDataForFilterBuilder();
    this.determineIfToggleShouldBeEnabled();

    const payload: EventModel = new EventModel("change", null, this.data);
    this.change.emit(payload);
  }

  /**
   * If the user clicks the 'Build Filter' button (when multiple filters are enabled), it should open up the filter builder
   * with the data for the only selected filter if there is one. If there is no filter selected, it should open the filter builder
   * with a default state. If there are multiple filters selected, it should open the filter builder with a default state.
   */
  setDataForFilterBuilder() {

    if (this.selectedFilters.length === 0) {
      this.onlySelectedFilter = this.resetDataToDefault();
    } else if (this.selectedFilters.length === 1) {
      // There could be a custom filter selected, which we can't find in the filters array.
      if (this.selectedFilters[0] === CUSTOM_FILTER) {
        this.onlySelectedFilter = Helper.deepCopy(this.tempCustomFilter);
      } else {
        this.onlySelectedFilter = this.filters.find(x => x.FilterId.toString() === this.selectedFilters[0]);
      }
    } else {
      this.onlySelectedFilter = this.resetDataToDefault();
    }
  }

  /**
   * We only want to show the toggle for multiple filters if more than 1 is selected, otherwise it's not needed.
   */
  determineIfToggleShouldBeEnabled() {
    if (this.selectedFilters.length > 1) {
      this.enableAllOrAnyToggle = true;
    } else {
      this.enableAllOrAnyToggle = false;
    }
  }

  /**
   * Converts the filters array to a PickListSelectionViewModel array which can be used with the ib-input-multiselect component.
   * @returns
   */
  convertFiltersToPickList() {
    if (!this.filters || this.filters.length === 0) {
      this.filtersPicklist = [];
      return;
    };

    this.filtersPicklist = this.filters.map((x) => {
      const pick = new PickListSelectionViewModel();
      pick.Value = x.FilterId.toString();
      pick.DisplayText = x.Description;
      // This might be nice at some point, but it's not needed right now.
      // Or maybe we expand upon this idea so Properties can store the settings
      // for the filter when we design it so the user can edit the only filter that is
      // selected in the multiselect.
      pick.Properties.FilterConditions = x.FilterConditions;
      return pick;
    });
  }

  getSingleFilterButton(): ButtonItem {

    // If we have more than limited number of filters we should include a search input to allow user to type to find their filter
    this.allowSearch = false;
    if (this.filters && this.filters.length > 8) {
      this.allowSearch = true;
    }

    const button = new ButtonItem("Filter", "filter", "outline-secondary");
    button.size = this.size;
    button.menuItemSize = this.size;
    button.scrollHeight = "large";
    button.fullWidth = this.fullWidth;

    // Update method allows us to tweak the button label, icon, etc. based on filter selected or not
    button.update = (action: Action, data: FilterSelectionData, cargo: any) => {
      if (!action) {
        return;
      }
      action.label = "Filter";
      action.icon = "filter";
      action.contextColorIcon = "";
      action.description = "";
      if (data) {
        if (data.Description) {
          action.label = Helper.left(data.Description, 30, true);
          action.icon = "filter (solid)";
          action.contextColorIcon = "danger";
          action.description = "";
          if (data.Description.length > 30) {
            action.description = data.Description;
          }
        } else if (this.queryService.hasFilterConditions(data.FilterConditions)) {
          action.label = "Custom Filter";
          action.icon = "filter (solid)";
          action.contextColorIcon = "danger";
          action.description = "";
        }
      }
      return;
    };
    button.action = (event: EventModel) => {
      this.openFilterBuilder(this.data);
    };
    button.menuPlacement = "bottom-right";

    // Menu option to clear any selected filter
    const clear: Action = new Action("clear", "Clear", "ban", "default", (event: EventModel) => {
      this.data = {};
      this.data.SaveOption = "no";
      const payload: EventModel = new EventModel("change", event, this.data);
      this.change.emit(payload);
    });
    clear.visible = (data: FilterSelectionData) => {
      // console.error(data);
      if (data && (data.FilterId || data.FilterConditions)) {
        return true;
      } else {
        return false;
      }
    };
    clear.cargo = { pinned: true };
    button.options.push(clear);

    // Separator after clear action if a separator is called for
    const clearSeparator: Action = new Action();
    clearSeparator.type = "separator";
    clearSeparator.visible = (data: FilterSelectionData) => {
      // console.error(data);
      if (data && (data.FilterId || data.FilterConditions) && this.filters && this.filters.length > 0) {
        return true;
      } else {
        return false;
      }
    };
    button.options.push(clearSeparator);

    // Add each filter to the list of options.  First personal filters then shared filters.
    if (this.filters && this.filters.length > 0) {
      const group = true;
      let countShared = 0;
      let countPrivate = 0;
      if (group) {
        countShared = this.filters.filter(x => x.Shared).length;
        countPrivate = this.filters.filter(x => !x.Shared).length;
      }
      if (group) {
        const header: Action = new Action(`header-favorites`, "My Favorites", "filter (solid)", "default");
        header.contextColorIcon = "primary";
        header.type = "heading";
        header.cargo = { pinned: true };
        button.options.push(header);
        if (countPrivate) {
          const header2: Action = new Action(`header-private`, "My Filters", "filter (solid)", "default");
          header2.contextColorIcon = "primary";
          header2.type = "heading";
          button.options.push(header2);
          this.filters.filter(x => !x.Shared).forEach((filter) => {
            filter.TextColor = this.convertBootstrapColorToHex(filter.TextColor);
            const pick: Action = new Action(`pick-${filter.FilterId}`, Helper.left(filter.Description, 50, true),
              filter.Icon || "filter (duotone)", filter.TextColor, (event: EventModel) => {
                this.data = this.filterToFilterSelectionData(event.cargo.action.cargo);
                const payload: EventModel = new EventModel("change", event, this.data);
                this.change.emit(payload);
              }, filter);
            pick.contextColorIcon = filter.IconColor;
            button.options.push(pick);
          });
        }
        if (countPrivate && countShared) {
          // Separator between private and shared filters
          const sharedSeparator: Action = new Action();
          sharedSeparator.type = "separator";
          button.options.push(sharedSeparator);
        }
        if (countShared) {
          const header3: Action = new Action(`header-shared`, "Shared Filters", "filter (solid)", "default");
          header3.contextColorIcon = "primary";
          header3.type = "heading";
          button.options.push(header3);
          this.filters.filter(x => x.Shared).forEach((filter) => {
            filter.TextColor = this.convertBootstrapColorToHex(filter.TextColor);
            const pick: Action = new Action(`pick-${filter.FilterId}`, Helper.left(filter.Description, 50, true),
              filter.Icon || "filter (duotone)", filter.TextColor, (event: EventModel) => {
                this.data = this.filterToFilterSelectionData(event.cargo.action.cargo);
                const payload: EventModel = new EventModel("change", event, this.data);
                this.change.emit(payload);
              }, filter);
            pick.contextColorIcon = filter.IconColor;
            button.options.push(pick);
          });
        }
      } else {
        this.filters.forEach((filter) => {
          filter.TextColor = this.convertBootstrapColorToHex(filter.TextColor);
          const pick: Action = new Action(`pick-${filter.FilterId}`, Helper.left(filter.Description, 50, true),
            filter.Icon || "filter (duotone)", filter.TextColor, (event: EventModel) => {
              this.data = this.filterToFilterSelectionData(event.cargo.action.cargo);
              const payload: EventModel = new EventModel("change", event, this.data);
              this.change.emit(payload);
            }, filter);
          pick.contextColorIcon = filter.IconColor;
          // if (filter.Shared) {
          //  pick.label = `${Helper.left(filter.Description, 50, true)} (${IconHelper.iconDataFromIconDescription("share-alt").iconHtml})`;
          // }
          button.options.push(pick);
        });
      }
    }

    // Separator before build action if a separator is called for
    const buildSeparator: Action = new Action();
    buildSeparator.type = "separator";
    buildSeparator.visible = (data: FilterSelectionData) => {
      // console.error(data);
      if ((data && (data.FilterId || data.FilterConditions)) || (this.filters && this.filters.length > 0)) {
        return true;
      } else {
        return false;
      }
    };
    button.options.push(buildSeparator);

    // Menu option to build a filter.  Last in the list is ok since button main click action does this as well
    const build: Action = new Action("build", "Build Filter", "filter", "default", (event: EventModel) => {
      this.openFilterBuilder(this.data);
    });
    button.options.push(build);
    button.favoriteResourceType = "Filter";
    button.favoriteActionIdsToSkip = ["build", "clear"];

    return button;

  }

  getMultiSelectBuildFilterButton(): ButtonItem {
    const buildFilterButton = new ButtonItem("Build Filter", "plus", "outline-secondary");
    buildFilterButton.action = (event: EventModel) => {
      // The MultiSelect doesn't close when clicking the Add Filter button so we need to close it manually
      this.closeCounter++;
      // When clicking the 'Build Filter' button, we let them edit the existing custom filter if there is one.
      // This means the state of 'tempCustomFilter' needs to be managed carefully. It needs to reprsent the custom
      // filter if there is one, otherwise it should be in a default state.
      this.openFilterBuilder(this.onlySelectedFilter);
    };
    return buildFilterButton;
  }


  /**
   * Opens the filter builder modal.
   * @param data The FilterSelectionData used to populate the FilterBuilderModalComponent.
   * @returns
   */
  openFilterBuilder(data: FilterSelectionData) {
    if (this.data.DataStoreFilterExpression) {
      this.uxService.modal.alertWarning("Filter", "This is an advanced filter that cannot be edited here.  Please use the advanced filter interface to edit this filter.");
      return;
    }
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.title = "Build Filter";
    options.featureToggleSupport = true;
    options.featureToggleStorageKey = "TaskTemplateAdvancedFeatureDisplay";
    options.featureToggleLabel = "Advanced";
    options.featureToggleTooltip = "Show advanced features";
    // Open the modal
    const modalRef = this.ngbModalService.open(FilterBuilderModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.data = Helper.deepCopy(data);

    // Default save option is no unless we have a filter id then the default is save
    modalRef.componentInstance.data.SaveOption = "no";
    if (this.data.FilterId) {
      modalRef.componentInstance.data.SaveOption = "save";
    }
    modalRef.componentInstance.objectName = this.objectName;

    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModelTyped<FilterSelectionData>) => {
      // User hit ok so value.data is the data object.  Based on save options we will either add or edit.
      this.data = value.data;
      this.data.FilterId = value.data.FilterId;
      this.data.Description = value.data.Description;
      this.data.Shared = value.data.Shared;
      this.data.FilterConditions = value.data.FilterConditions;
      this.data.DataStoreFilterExpression = value.data.DataStoreFilterExpression;
      this.data.Icon = value.data.Icon;
      this.data.IconColor = value.data.IconColor;
      this.data.TextColor = value.data.TextColor;
      this.data.SaveOption = value.data.SaveOption;
      // FilterIds can't be edited in the filter builder. They can only be edited by the user clicking
      // checkboxes in the multiselect, we just need to make sure they get passed along or they will be lost.
      if (this.allowMultipleFilters) {
        this.data.FilterIds = value.data.FilterIds;
      }
      if (!this.data.FilterId && !this.queryService.hasFilterConditions(this.data.FilterConditions)) {
        this.data.FilterConditions = null;
      }
      const payload: EventModel = new EventModel("change", event, value.data, new EventElementModel("filter-picker", null));
      this.change.emit(payload);
      // Make a copy of the conditions being saved and purge meta that is only used for ux
      const conditions = Helper.deepCopy(this.data.FilterConditions);
      this.queryService.filterConditionsPurgeMeta(conditions);
      // Now see if we should be saving the filter that was just built
      const save: boolean = (this.data.SaveOption === "save" && !Helper.isEmpty(this.data.FilterId));
      const saveAs: boolean = (!save && Helper.startsWith(this.data.SaveOption, "save", true));

      if (this.data.SaveOption === "delete" && this.data.FilterId) {
        this.deleteFilter();
      } else if (save) {
        this.saveFilter(conditions);
      } else if (saveAs) {
        this.saveAsFilter(conditions);
      } else if (this.allowMultipleFilters) {

        // Custom Filter scenario. If they are using MultipleFilters, we need to store the
        // custom filter so we don't lose it if they unselect it later.
        this.tempCustomFilter = Helper.deepCopy(value.data);

        // Add 'Custom Filter' to multiselect picklist if not already there
        const customFilterIndex = this.filtersPicklist.findIndex(x => x.Value === CUSTOM_FILTER);

        if (customFilterIndex === -1) {
          const picklist = this.buildPickList(CUSTOM_FILTER, CUSTOM_FILTER, value.data.FilterConditions);
          this.selectedFilters.unshift(CUSTOM_FILTER);
          this.filtersPicklist.unshift(picklist);
        }
        this.fireChangeDetectionForMultiSelect();
      }
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }

  buildPickList(value: string, displayText: string, conditions: m5.FilterConditionGroupViewModel = undefined): PickListSelectionViewModel {
    const picklist = new PickListSelectionViewModel();
    picklist.Value = value;
    picklist.DisplayText = displayText;
    picklist.Properties.FilterConditions = conditions;
    return picklist;
  }

  deleteFilter() {
    const promise = this.uxService.modal.confirmDelete(`Delete filter ${this.data.Description}?`, null);
    promise.then((answer) => {
      const delCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Delete);
      delCall.silent = true;
      this.apiService.execute(delCall, this.data.FilterId).subscribe((result: IApiResponseWrapper) => {
        if (!result.Data.Success) {
          // this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
          Log.errorMessage(result);
        } else {
          // We just deleted our filter so we need to clear it
          this.data = {};
          this.data.SaveOption = "no";
          const payload2: EventModel = new EventModel("change", event, this.data, new EventElementModel("filter-picker", null));
          this.change.emit(payload2);
          this.loadFilters(true);
        }
      });
    }, (cancelled) => {
      // No action
      this.data.SaveOption = "save";
    });
  }

  saveFilter(conditions: m5.FilterConditionGroupViewModel) {
    const getCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Get);
    getCall.silent = true;
    this.apiService.execute(getCall, this.data.FilterId).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel>) => {
      if (!result.Data.Success) {
        // this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        Log.errorMessage(result);
      } else {
        // Assign updated description and filter conditions and save the already existing filter
        result.Data.Data.Description = Helper.getFirstDefinedString(this.data.Description, "Filter");
        result.Data.Data.Shared = this.data.Shared;
        result.Data.Data.ValidForReport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForReport"));
        result.Data.Data.ValidForAdHocQuery = (this.data.ValidFor && this.data.ValidFor.includes("ValidForAdHocQuery"));
        result.Data.Data.ValidForDataExport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForDataExport"));
        result.Data.Data.ValidForVisualComponent = (this.data.ValidFor && this.data.ValidFor.includes("ValidForVisualComponent"));
        result.Data.Data.FilterConditions = conditions;
        result.Data.Data.Icon = this.data.Icon;
        result.Data.Data.IconColor = this.data.IconColor;
        result.Data.Data.TextColor = this.data.TextColor;
        const editCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Edit);
        editCall.silent = true;
        this.apiService.execute(editCall, result.Data.Data).subscribe((result2: IApiResponseWrapperTyped<m5.FilterEditViewModel>) => {
          if (!result2.Data.Success) {
            // this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
            Log.errorMessage(result2);
          } else {
            const payload2: EventModel = new EventModel("change", event, this.data, new EventElementModel("filter-picker", null));
            this.change.emit(payload2);
            this.loadFilters(true);
          }
        });
      }
    });
  }

  saveAsFilter(conditions: m5.FilterConditionGroupViewModel) {
    const model = new m5.FilterEditViewModel();
    model.Description = Helper.getFirstDefinedString(this.data.Description, "Filter");
    model.Enabled = true;
    model.ValidForReport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForReport"));
    model.ValidForAdHocQuery = (this.data.ValidFor && this.data.ValidFor.includes("ValidForAdHocQuery"));
    model.ValidForDataExport = (this.data.ValidFor && this.data.ValidFor.includes("ValidForDataExport"));
    model.ValidForVisualComponent = (this.data.ValidFor && this.data.ValidFor.includes("ValidForVisualComponent"));
    model.Shared = this.data.Shared;
    model.ContactId = this.appService.userOrDefault.ContactId;
    model.ObjectName = this.objectName;
    model.FilterConditions = conditions;
    model.Icon = this.data.Icon;
    model.IconColor = this.data.IconColor;
    model.TextColor = this.data.TextColor;
    // Now flip save option from save-as to save since save-as is a user initiated action not a default
    this.data.SaveOption = "save";
    const addCall = ApiHelper.createApiCall(this.apiProp, ApiOperationType.Add);
    addCall.silent = true;
    this.apiService.execute(addCall, model).subscribe((result: IApiResponseWrapperTyped<m5.FilterEditViewModel>) => {
      if (!result.Data.Success) {
        // this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        Log.errorMessage(result);
      } else {
        // A user could make a custom filter, then come back and save it, so make sure we clean up tempCustomFilter
        // just in case. If they just saved right away, then we are setting it to the same state anyways and it's fine.
        // Also add this to the list of selectedFilters so the multiselect shows it as selected.
        if (this.allowMultipleFilters) {
          const picklist = this.buildPickList(
            result.Data.Data.FilterId.toString(),
            result.Data.Data.Description,
            result.Data.Data.FilterConditions
          );

          // Since the user just saved the filter, it's no longer custom. Remove 'Custom Filter' from the selected filters
          // and the picklist. Remember, it might not actually be there, since they could have just made and saved a filter
          // right away, so check if it exists and then remove it.
          const customFilterIndex = this.selectedFilters.findIndex(x => x === CUSTOM_FILTER);
          const customFilterPicklistIndex = this.filtersPicklist.findIndex(x => x.Value === CUSTOM_FILTER);
          if (customFilterIndex !== -1) {
            this.selectedFilters.splice(customFilterIndex, 1);
          }
          if (customFilterPicklistIndex !== -1) {
            this.filtersPicklist.splice(customFilterPicklistIndex, 1);
          }

          // this.data.FilterIds.push(result.Data.Data.FilterId);
          this.selectedFilters.push(result.Data.Data.FilterId.toString());
          this.syncFilterIdsWithSelectedFilters();
          this.filtersPicklist.push(picklist);
          this.tempCustomFilter = this.resetDataToDefault();
          this.data = this.resetDataToDefault();
          this.fireChangeDetectionForMultiSelect();

        } else {
          this.data.FilterId = result.Data.Data.FilterId;
        }


        const payload3: EventModel = new EventModel("change", event, this.data, new EventElementModel("filter-picker", null));
        this.change.emit(payload3);
        this.loadFilters(true);
      }
    });
  }

  fireChangeDetectionForMultiSelect() {
    // Spread operator on both arrays to fire change detection.
    this.selectedFilters = [...this.selectedFilters];
    this.filtersPicklist = [...this.filtersPicklist];
  }


  /**
   * @returns A FilterSelectionData object with properties cleared out so the filter builder opens without any settings.
   *          Some properties are initialized to prevent errors when the filter builder is opened. FilterIds is passed
   *          along so we can show what filters are currently selected in the multiselect.
   */
  resetDataToDefault(): FilterSelectionData {
    if (!this.allowMultipleFilters) {
      return;
    }
    const data: FilterSelectionData = {};
    data.FilterConditions = new m5.FilterConditionGroupViewModel();
    data.FilterConditions.ConditionBooleanOperator = "And";
    data.FilterConditions.GroupBooleanOperator = "And";
    data.FilterIds = this.data.FilterIds;
    data.SaveOption = "no";

    return data;
  }

  convertBootstrapColorToHex(bootstrapColor: string) {
    if (Helper.equals(bootstrapColor, "default")) {
      // The default text color here should be black
      return "#000000";
    } else {
      return Helper.convertColorToHex(bootstrapColor);
    }
  }



  /**
   * Transforms a FilterEditViewModel into a FilterSelectionData object.
   * @param filter - The FilterEditViewModel to be transformed.
   * @param data - The initial FilterSelectionData object (optional).
   * @returns The transformed FilterSelectionData object.
   */
  filterToFilterSelectionData(filter: m5.FilterEditViewModel, data: FilterSelectionData = null): FilterSelectionData {
    if (!data) {
      data = {};
    }
    if (!filter) {
      return data;
    }
    data.FilterId = filter.FilterId;
    data.Description = filter.Description;
    data.Shared = filter.Shared;
    data.FilterConditions = filter.FilterConditions;
    data.DataStoreFilterExpression = filter.DataStoreFilterExpression;
    data.Icon = filter.Icon;
    data.IconColor = filter.IconColor;
    data.TextColor = filter.TextColor;
    data.SaveOption = "save";
    return data;
  }

  onToggleStateChange($event) {
    if ($event.data === true) {
      this.data.FilterIdsBooleanCriteria = "and";
      // filterToggleState here can be out of sync with the actual state of the toggle, but I don't think it matters.
      // We passed in the intial value and then its emitted each time the user changes it. Maybe at some point it
      // will matter that they are in sync, at which point we could probably have 2 way binding for 'toggleState' where
      // we put an output on the multiselect that emits a boolean, and still keep our toggleStateChange output so we can
      // emit the value to the parent? For now I think it's fine though. (the downside of manually setting it here to stay in sync
      // is triggering change detection in the multiselect even though we are telling it the same value it already has)
    } else {
      this.data.FilterIdsBooleanCriteria = "or";
    }
    const payload: EventModel = new EventModel("change", null, this.data);
    this.change.emit(payload);
  }

}
