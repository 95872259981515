import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { ApiService } from '../api/api.service';
import { AppCacheService } from './app-cache.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import { Helper, Log } from '../helpers/helper';
import { Observable, of, AsyncSubject, forkJoin, Subject } from 'rxjs';
import { ApiProperties, Query, ApiOperationType, IApiResponseWrapperTyped, IApiResponseWrapper } from '../api/ApiModels';
import { Api } from '../api/Api';
import { ApiHelper } from '../api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { BaseService } from './base.service';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { IMetaDataModel } from '../models/ngCoreModels';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {

  protected apiPropTaskListTemplate: ApiProperties = Api.TaskListTemplate();
  protected apiPropTaskTemplate: ApiProperties = Api.TaskTemplate();
  protected apiPropSubtaskTemplate: ApiProperties = Api.TaskSubtaskTemplate();
  protected apiPropTaskList: ApiProperties = Api.TaskList();
  protected apiPropTask: ApiProperties = Api.Task();
  protected apiPropSubtask: ApiProperties = Api.TaskSubtask();

  taskCategoryPickList: m5core.PickListSelectionViewModel[] = [];
  taskStatusPickList: m5core.PickListSelectionViewModel[] = [];
  taskPriorityPickList: m5core.PickListSelectionViewModel[] = [];
  taskSystemTaskProcessStatusPickList: m5core.PickListSelectionViewModel[] = [];

  private clickSubject = new Subject<number>();
  /**
   * This is a workaround to close button menus because cdkDragDrop was messing with click propagation. Clicking on a
   * task, subtask, or another button would not close the menu of a previously opened menu. Now when clicking, we can
   * notify the service what was clicked. We pass a 0 if a non button was clicked. If a button was clicked, we pass
   * the TaskId or SubtaskId the button is for.
   */
  click$ = this.clickSubject.asObservable();

  constructor(protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer) {

    super();

    try {
      this.refreshPickLists();
    } catch (err) {
      Log.errorMessage("Exception refreshing pick lists from service constructor");
      Log.errorMessage(err);
    }

  }

  /**
   * We track clicks so since cdkDragDrop was messing with click propagation. We added click listeners
   * to <li> tags and buttons and we basically signal when something was clicked that previously wouldn't
   * have allowed the menu to know it was clicked, so now it's possible for it to close.
   * @param buttonId the id of the button that was clicked or 0 if a non button was clicked.
   */
  notifyClick(buttonId: number) {
    this.clickSubject.next(buttonId);
  }

  /**
   * Loads task related pick lists into service variables.
   * @param reportErrors
   */
  refreshPickLists(reportErrors: boolean = true) {

    this.apiService.loadPickList(Constants.PickList.TaskCategory).subscribe(result => {
      if (result.Data.Success) {
        this.taskCategoryPickList = result.Data.Data || [];
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList.TaskStatus).subscribe(result => {
      if (result.Data.Success) {
        this.taskStatusPickList = result.Data.Data || [];
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList.__Task_Priority).subscribe(result => {
      if (result.Data.Success) {
        this.taskPriorityPickList = result.Data.Data || [];
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList.__Task_SystemTaskProcessStatus).subscribe(result => {
      if (result.Data.Success) {
        this.taskSystemTaskProcessStatusPickList = result.Data.Data || [];
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

  }



  showNormalCheckbox(task: m5.TaskEditViewModel): boolean {
    if (!task) {
      return false;
    }
    if (task?.SystemTaskParameters?.RequiresUserToApproveOrReject && !task.ActualCloseDateTime) {
      // Requires user to approve or reject so we return false unless the task is already
      // closed in which case we want to return true because it will just show a box that
      // is already checked because the task is done.
      return false;
    }
    // The default
    return true;
  }


  showAcceptRejectCheckboxMenu(task: m5.TaskEditViewModel): boolean {
    if (!task) {
      return false;
    }
    if (task?.SystemTaskParameters?.RequiresUserToApproveOrReject && !task.ActualCloseDateTime) {
      // Requires user to approve or reject so we return true if the task is not done yet.
      return true;
    }
    // The default
    return false;
  }



  /**
   * Sorts tasks lists, tasks, and subtasks by display order.  Should be called after
   * load to ensure presentation is in the expected order.
   * @param taskLists
   * @returns
   */
  sort(taskLists: m5.TaskListTemplateEditViewModel[] | m5.TaskListEditViewModel[]): void {
    if (!taskLists) {
      return;
    }
    if (taskLists.length === 0) {
      return;
    }
    // Sort task lists
    taskLists.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    // Now sort list of tasks within each task list along with the subtasks under each task
    taskLists.forEach((list: m5.TaskListTemplateEditViewModel | m5.TaskListEditViewModel) => {
      list.Tasks.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      list.Tasks.forEach((task: m5.TaskTemplateEditViewModel | m5.TaskEditViewModel) => {
        task.Subtasks.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      });
    });
    return;
  }


  // TODO
  getTaskAndPriorityIconHtml(
    model: m5.CaseTemplateEditViewModel | m5.CaseTemplateEditViewModel | m5.CaseTemplateEditViewModel | m5.CaseTemplateEditViewModel,
    caseTypePickList: m5core.PickListSelectionViewModel[],
    casePriorityPickList: m5core.PickListSelectionViewModel[]
  ): string {

    // Look for type icon but use type text if no icon available
    let typeHtml = model.Type;
    let matches = caseTypePickList.filter(x => Helper.equals(x.Value, model.Type, true));
    if (matches && matches.length > 0) {
      const value = matches[0];
      typeHtml = value.DisplayText;
      if (value.Icon) {
        const icon = IconHelper.iconDataFromIconDescription(value.Icon);
        icon.title = value.DisplayText || value.Value;
        if (value.IconColor) {
          icon.color = value.IconColor;
        }
        icon.fontSize = "1.5em";
        typeHtml = icon.html;
      }
    } else {
      typeHtml = model.Type;
    }

    // Look for priority icon (no text alternate desired here)
    let priorityHtml = "";
    matches = casePriorityPickList.filter(x => Helper.equals(x.Value, model.Priority, true));
    if (matches && matches.length > 0) {
      const value = matches[0];
      if (value.Icon) {
        const icon = IconHelper.iconDataFromIconDescription(value.Icon);
        icon.title = `Priority: ${value.DisplayText || value.Value}`;
        if (value.IconColor) {
          icon.color = value.IconColor;
        }
        icon.fontSize = "1.5em";
        priorityHtml = icon.html;
      }
    }

    // Combine icons
    let html = typeHtml;
    if (priorityHtml) {
      html = `${priorityHtml}&nbsp;${typeHtml}`;
    }

    return html;

  }


  getNewTaskListTemplate(): m5.TaskListTemplateEditViewModel {
    const model = new m5.TaskListTemplateEditViewModel();
    model.AlternateTaskListTemplateId = Helper.createBase36Guid(20);
    model.Enabled = "E";
    model.Required = "N";
    model.CompletedTaskMode = "K";
    model.OwnerCanEstimate = true;
    model.OwnerCanReassign = true;
    model.OwnerCanEdit = true;
    model.SupportSubtasks = true;
    model.SupportNotes = true;
    model.SupportTimeLogs = true;
    model.SupportAddingTasks = true;
    model.DisplayOrder = 100;
    return model;
  }

  /**
   * When possible assign template object to task object .MetaData.Properties property so we can use it
   * to make decisions based on template settings.
   * @param taskLists
   * @param taskListTemplates
   * @returns
   */
  templateAssign(taskLists: m5.TaskListEditViewModel[], taskListTemplates: m5.TaskListTemplateEditViewModel[]) {

    if (!taskLists || taskLists.length === 0 || !taskListTemplates || taskListTemplates.length === 0) {
      return;
    }

    // Step through each task list
    taskLists.forEach(list => {

      if (!list.TaskListTemplateId) {
        // No template id so finding template not possible
        return; // return = continue
      }

      // Find our task list template
      const taskListTemplateIndex = taskListTemplates.findIndex(x => x.TaskListTemplateId === list.TaskListTemplateId);
      if (taskListTemplateIndex > -1) {
        if (!list.MetaData.Properties) {
          list.MetaData.Properties = {};
        }
        list.MetaData.Properties.Template = taskListTemplates[taskListTemplateIndex];
      }

      // Step through each task in the task list (if we have any)
      if (!list.Tasks || list.Tasks.length === 0) {
        return; // return = continue
      }
      list.Tasks.forEach(task => {

        if (!task.TaskTemplateId) {
          // No template id so finding template not possible
          return; // return = continue
        }

        // Find our task template
        const taskTemplateIndex = this.templateTaskFindIndex(taskListTemplates, task.TaskTemplateId, false);
        if (taskTemplateIndex && taskTemplateIndex.taskIndex > -1) {
          if (!task.MetaData.Properties) {
            task.MetaData.Properties = {};
          }
          task.MetaData.Properties.TaskListTemplate = taskListTemplates[taskListTemplateIndex];
          task.MetaData.Properties.Template = taskListTemplates[taskTemplateIndex.taskListIndex].Tasks[taskTemplateIndex.taskIndex];
          if ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).Required === "I") {
            // The task template says required settings are inherited from the task list so update those
            // settings here so we don't have to hunt for them later when using the required information.
            (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).Required = taskListTemplates[taskTemplateIndex.taskListIndex].Required;
            (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).RequiredReason = taskListTemplates[taskTemplateIndex.taskListIndex].RequiredReason;
            (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).RequiredWaivedForContacts = taskListTemplates[taskTemplateIndex.taskListIndex].RequiredWaivedForContacts;
            (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).MetaData.Properties.RequiredWaivedForContactIds = taskListTemplates[taskTemplateIndex.taskListIndex].MetaData.Properties.RequiredWaivedForContactIds;
          }
          // See if it's really required
          if ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).Required !== "N") {
            // Task is required so see if we have a list of groups who are waived from having this required
            const waivedIds: string[] = (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).MetaData?.Properties?.RequiredWaivedForContactIds;
            if (waivedIds && waivedIds.length > 0) {
              waivedIds.forEach(waivedId => {
                if (this.appService.user.Groups.some(x => x.GroupId === parseInt(waivedId, 10))) {
                  // This user belongs to group that is not required to complete this task
                  (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).Required = "N";
                }
              });
            }
          }
          // Some settings can be set at task or task list level so let's flag when either is true
          (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).OwnerCanEstimate =
            ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).OwnerCanEstimate ||
              taskListTemplates[taskTemplateIndex.taskListIndex].OwnerCanEstimate);
          (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).OwnerCanReassign =
            ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).OwnerCanReassign ||
              taskListTemplates[taskTemplateIndex.taskListIndex].OwnerCanReassign);
          (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).OwnerCanEdit =
            ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).OwnerCanEdit ||
              taskListTemplates[taskTemplateIndex.taskListIndex].OwnerCanEdit);
          (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleNoteRequired =
            ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleNoteRequired ||
              taskListTemplates[taskTemplateIndex.taskListIndex].CloseRuleNoteRequired);
          (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleTimeLogRequired =
            ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleTimeLogRequired ||
              taskListTemplates[taskTemplateIndex.taskListIndex].CloseRuleTimeLogRequired);
          (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleTimeLogsAllClosed =
            ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleTimeLogsAllClosed ||
              taskListTemplates[taskTemplateIndex.taskListIndex].CloseRuleTimeLogsAllClosed);
          (task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleAllSubtasksCompleted =
            ((task.MetaData.Properties.Template as m5.TaskTemplateEditViewModel).CloseRuleAllSubtasksCompleted ||
              taskListTemplates[taskTemplateIndex.taskListIndex].CloseRuleAllSubtasksCompleted);
        }

        // Step through each subtask in the task (if we have any)
        if (!task.Subtasks || task.Subtasks.length === 0) {
          return; // return = continue
        }
        task.Subtasks.forEach(subtask => {

          if (!subtask.TaskSubtaskTemplateId) {
            // No template id so finding template not possible
            return; // return = continue
          }

          // Find our subtask template
          const subtaskTemplateIndex = this.templateTaskFindIndex(taskListTemplates, subtask.TaskSubtaskTemplateId, true);
          if (subtaskTemplateIndex && subtaskTemplateIndex.subtaskIndex > -1) {
            if (!subtask.MetaData.Properties) {
              subtask.MetaData.Properties = {};
            }
            subtask.MetaData.Properties.Template = taskListTemplates[subtaskTemplateIndex.taskListIndex].Tasks[subtaskTemplateIndex.taskIndex].Subtasks[subtaskTemplateIndex.subtaskIndex];
          }

        }); // subtask foreach

      }); // task foreach

    }); // task list foreach

  }


  /**
   * Remove template object from task object .MetaData.Properties property since that is a client
   * side helper and we don't want to save it server side.
   * @param taskLists
   * @returns
   */
  templateRemove(taskLists: m5.TaskListEditViewModel[]) {

    if (!taskLists || taskLists.length === 0) {
      return;
    }

    // Step through each task list
    taskLists.forEach(list => {
      if (list.MetaData.Properties) {
        list.MetaData.Properties = null;
      }
      // Step through each task in the task list (if we have any)
      if (!list.Tasks || list.Tasks.length === 0) {
        return; // return = continue
      }
      list.Tasks.forEach(task => {
        if (task.MetaData.Properties) {
          task.MetaData.Properties = null;
        }
        // Step through each subtask in the task (if we have any)
        if (!task.Subtasks || task.Subtasks.length === 0) {
          return; // return = continue
        }
        task.Subtasks.forEach(subtask => {
          if (subtask.MetaData.Properties) {
            subtask.MetaData.Properties = null;
          }
        }); // subtask foreach
      }); // task foreach
    }); // task list foreach

  }


  templateLoad(ownerResourceType: string, ownerResourceId: number, ownerResourceId2: string,
    reportErrors: boolean = true, backgroundCacheLoader: boolean = false): Observable<m5.TaskListTemplateEditViewModel[]> {
    if (!ownerResourceType || (!ownerResourceId && !ownerResourceId2)) {
      Log.errorMessage("Unable to load task lists due to missing owner type or id.");
      return of([]);
    }
    const query: Query = new Query("DisplayOrder", Constants.RowsToReturn.All);
    if (ownerResourceId) {
      query.Filter = `OwnerResourceType == "${ownerResourceType}" && OwnerResourceId == ${ownerResourceId}`;
    } else {
      query.Filter = `OwnerResourceType == "${ownerResourceType}" && OwnerResourceId2 == "${ownerResourceId2}"`;
    }
    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<m5.TaskListTemplateEditViewModel[]>();
    const apiCall = ApiHelper.createApiCall(this.apiPropTaskListTemplate, ApiOperationType.List);
    if (backgroundCacheLoader) {
      // When doing cache load we want these settings
      apiCall.silent = true; // We can do this in the background without eye candy
      apiCall.cacheKey = `TaskListTemplates-${ownerResourceType}-${ownerResourceId}${ownerResourceId2}`;
      apiCall.cacheUseStorage = true; // Keep cached in browser storage
    }
    this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<m5.TaskListTemplateEditViewModel[]>) => {
      if (result.Data.Success) {
        const data = result.Data.Data;
        this.templateMapAfterLoad(data);
        // Sort then we're done
        this.sort(data);
        subject.next(data);
        subject.complete();
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        }
        subject.next([]);
        subject.complete();
      }
    });
    return subject.asObservable();
  }


  /**
   * Perform any mapping needed after loading template objects.  For example, our template object has
   * RequiredWaivedForContacts property which we want mapped to a number[] of contact ids for current
   * UI input usage.  This helper method accepts template objects in a variety of types and handles
   * all needed mapping.
   * Note that typically only one parameter will be passed with all others being null.
   * @param taskLists
   * @param taskList
   * @param tasks
   * @param task
   */
  templateMapAfterLoad(
    taskLists: m5.TaskListTemplateEditViewModel[] = null,
    taskList: m5.TaskListTemplateEditViewModel = null,
    tasks: m5.TaskTemplateEditViewModel[] = null,
    task: m5.TaskTemplateEditViewModel = null
  ) {

    if (taskLists) {
      taskLists.forEach(x => {
        this.templateMapAfterLoad(null, x);
      });
    }

    if (taskList) {
      if (!taskList.MetaData) { taskList.MetaData = {} as IMetaDataModel; }
      if (!taskList.MetaData.Properties) { taskList.MetaData.Properties = {}; }
      // Make sure we don't have any duplicate values here
      taskList.MetaData.Properties.RequiredWaivedForContactIds = Helper.arrayDistinct(taskList.RequiredWaivedForContacts.map(x => x.ContactId.toString()));
      if (taskList.Tasks && taskList.Tasks.length > 0) {
        this.templateMapAfterLoad(null, null, taskList.Tasks);
      }
    }

    if (tasks) {
      tasks.forEach(taskItem => {
        if (!taskItem.MetaData) { taskItem.MetaData = {} as IMetaDataModel; }
        if (!taskItem.MetaData.Properties) { taskItem.MetaData.Properties = {}; }
        // Make sure we don't have any duplicate values here
        taskItem.MetaData.Properties.RequiredWaivedForContactIds = Helper.arrayDistinct(taskItem.RequiredWaivedForContacts.map(x => x.ContactId.toString()));
      });
    }

    if (task) {
      if (!task.MetaData) { task.MetaData = {} as IMetaDataModel; }
      if (!task.MetaData.Properties) { task.MetaData.Properties = {}; }
      // Make sure we don't have any duplicate values here
      task.MetaData.Properties.RequiredWaivedForContactIds = Helper.arrayDistinct(task.RequiredWaivedForContacts.map(x => x.ContactId.toString()));
    }

  }


  /**
   * Perform any mapping needed before saving template objects.  For example, our template object has
   * RequiredWaivedForContacts property which we want mapped to a number[] of contact ids for current
   * UI input usage.  This helper method accepts template objects in a variety of types and handles
   * all needed mapping.
   * Note that typically only one parameter will be passed with all others being null.
   * @param taskLists
   * @param taskList
   * @param tasks
   * @param task
   */
  templateMapBeforeSave(taskLists: m5.TaskListTemplateEditViewModel[] = null,
    taskList: m5.TaskListTemplateEditViewModel = null,
    tasks: m5.TaskTemplateEditViewModel[] = null,
    task: m5.TaskTemplateEditViewModel = null) {

    // Note that we often merge operation for lighter payload on save so we use
    // Helper.arrayContentsMatch to make sure we don't map when nothing changed
    // to avoid payload where none was needed.

    if (taskLists) {
      taskLists.forEach(taskListItem => {
        this.templateMapBeforeSave(null, taskListItem);
      });
    }

    if (taskList) {
      if (taskList.MetaData?.Properties?.RequiredWaivedForContactIds) {
        if (!Helper.arrayContentsMatch(taskList.MetaData.Properties.RequiredWaivedForContactIds, taskList.RequiredWaivedForContacts.map(x => x.ContactId.toString()))) {
          taskList.RequiredWaivedForContacts = taskList.MetaData.Properties.RequiredWaivedForContactIds.map((x: number) => ({ ContactId: x } as m5.ContactReferenceViewModel));
        }
      }
      if (taskList.Tasks && taskList.Tasks.length > 0) {
        this.templateMapBeforeSave(null, null, taskList.Tasks);
      }
    }

    if (tasks) {
      tasks.forEach(taskItem => {
        this.templateMapBeforeSave(null, null, null, taskItem);
      });
    }

    if (task) {
      if (task.MetaData?.Properties?.RequiredWaivedForContactIds) {
        if (!Helper.arrayContentsMatch(task.MetaData.Properties.RequiredWaivedForContactIds, task.RequiredWaivedForContacts.map(x => x.ContactId.toString()))) {
          task.RequiredWaivedForContacts = task.MetaData.Properties.RequiredWaivedForContactIds.map((x: number) => ({ ContactId: x } as m5.ContactReferenceViewModel));
        }
      }
    }

  }


  /**
   * Dumps the cache for task list templates for the specified owner.  On task list template
   * changes this method should be called to dump our cache.
   * @param ownerResourceType
   * @param ownerResourceId
   * @param ownerResourceId2
   * @param reportErrors
   * @returns
   */
  templateDumpCache(ownerResourceType: string, ownerResourceId: number, ownerResourceId2: string = "", reportErrors: boolean = true): void {
    if (!ownerResourceType || (!ownerResourceId && !ownerResourceId2)) {
      return;
    }
    const apiProp = Api.TaskListTemplate();
    const cacheKey: string = `TaskListTemplates-${ownerResourceType}-${ownerResourceId}${ownerResourceId2}`;
    this.cache.storedCacheRemoveValue(apiProp.cacheName, cacheKey);
    return;
  }



  /**
   *
   * @param data
   * @param original
   * @param minimizeApiPayload
   * When saving and this value is false we call edit/put on each task list.
   * When this value is true we inspect objects to only save what has changed
   * and do that via merge instead of put request.
   * @returns
   */
  templateSaveChanges(data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true,
    minimizeApiPayload: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!data || data.length === 0) {
      Log.debugMessage("No task lists provided so nothing to save.");
      return (of({ data: [], original: [] }));
    }

    this.templateMapBeforeSave(data);

    if (minimizeApiPayload && original && original.length === data.length) {
      return this.templateSaveChangesMinimal(data, original, reportErrors);
    } else {
      return this.templateSaveChangesFull(data, reportErrors);
    }

  }


  protected templateSaveChangesFull(data: m5.TaskListTemplateEditViewModel[], reportErrors: boolean = true): Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!data || data.length === 0) {
      Log.debugMessage("No task lists provided so nothing to save.");
      return (of({ data: [], original: [] }));
    }

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskListTemplate, ApiOperationType.Edit);

    // Keep a list of observables from each api call so we can provided the needed aggregate response
    const observables: Observable<IApiResponseWrapperTyped<m5.TaskListTemplateEditViewModel>>[] = [];

    // Step through each task list and call edit
    data.forEach((taskList: m5.TaskListTemplateEditViewModel, index: number, tasksLists: m5.TaskListTemplateEditViewModel[]) => {
      const observable = this.apiService.execute(apiCall, taskList);
      observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskListTemplateEditViewModel>) => {
        if (result.Data.Success) {
          // Update our data array with the response
          data[index] = result.Data.Data;
        } else if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        }
      });
      // Save our observable so we can aggregate our response
      observables.push(observable);
    });

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    // When all observables have completed then sort and make a copy of our data array and output
    // to the subject we expose observable to so consumers can get the data and original arrays.
    forkJoin(observables)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((results: IApiResponseWrapper[]) => {
        // The data and original arrays were updated in the individual helper methods called above
        this.sort(data);
        subject.next({ data: data, original: Helper.deepCopy(data) });
        subject.complete();
      });

    return subject.asObservable();

  }


  protected templateSaveChangesMinimal(data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!data || data.length === 0) {
      Log.debugMessage("No task lists provided so nothing to save.");
      return (of({ data: [], original: [] }));
    }

    const observables: Observable<any>[] = [];

    data.forEach(taskList => {
      // Check tasks
      if (taskList.Tasks && taskList.Tasks.length > 0) {
        taskList.Tasks.forEach(task => {
          // Check subtasks
          if (task.Subtasks && task.Subtasks.length > 0) {
            task.Subtasks.forEach(subtask => {
              // See if subtask changed
              const obsSubtask = this.templateSaveChangesMinimalSubtask(subtask, data, original, reportErrors);
              if (obsSubtask) {
                observables.push(obsSubtask);
              }
            });
          }
          // See if task changed
          const obsTask = this.templateSaveChangesMinimalTask(task, data, original, reportErrors);
          if (obsTask) {
            observables.push(obsTask);
          }
        });
      }
      // See if task list changed
      const obsTaskList = this.templateSaveChangesMinimalTaskList(taskList, data, original, reportErrors);
      if (obsTaskList) {
        observables.push(obsTaskList);
      }
    });

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    // When all observables have completed then we can output our updated lists
    forkJoin(observables)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((results: IApiResponseWrapper[]) => {
        // The data and original arrays were updated in the individual helper methods called above
        this.sort(data);
        subject.next({ data: data, original: original });
        subject.complete();
      });

    return subject.asObservable();

  }


  /**
   * Save a subtask object if it's changed since last load.
   * @param subtask
   * @returns
   */
  protected templateSaveChangesMinimalSubtask(subtask: m5.TaskSubtaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true): Observable<any> {

    if (!subtask) {
      return null;
    }

    // Find obj in data list
    const dataIndex = this.templateTaskFindIndex(data, subtask.TaskSubtaskTemplateId, true);
    if (!dataIndex || dataIndex.subtaskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find data index for subtask id ${subtask.TaskSubtaskTemplateId}.`);
      return null;
    }

    // Find obj in original list
    const origIndex = this.templateTaskFindIndex(original, subtask.TaskSubtaskTemplateId, true);
    if (!origIndex || origIndex.subtaskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find original index for subtask id ${subtask.TaskSubtaskTemplateId}.`);
      return null;
    }
    const orig = original[origIndex.taskListIndex].Tasks[origIndex.taskIndex].Subtasks[origIndex.subtaskIndex];

    // See if the object changed
    if (Helper.objectEquals(orig, subtask, ["MetaData"])) {
      // No changes
      return null;
    }
    const diff = Helper.objectDiff(orig, subtask, "TaskSubtaskTemplateId", "TaskTemplateId", "MetaData");
    const properties: string[] = Helper.objectGetPropertyNameList(diff);
    if (Helper.arrayContentsMatch(properties, ["TaskSubtaskTemplateId", "TaskTemplateId", "MetaData"])) {
      // If our only properties are PK, FK and MetaData then our diff is empty in terms of data to merge
      return null;
    }

    // Our api route also requires the task list id but that's not part of the object so push it in now
    diff.TaskListTemplateId = original[origIndex.taskListIndex].TaskListTemplateId;

    // Save the diff
    const apiCall = ApiHelper.createApiCall(this.apiPropSubtaskTemplate, ApiOperationType.Merge);
    const observable = this.apiService.execute(apiCall, diff);
    observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskSubtaskTemplateEditViewModel>) => {
      if (result.Data.Success) {
        data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex].Subtasks[dataIndex.subtaskIndex] = result.Data.Data;
        original[origIndex.taskListIndex].Tasks[origIndex.taskIndex].Subtasks[origIndex.subtaskIndex] = Helper.deepCopy(result.Data.Data);
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    // Return the observable so we can know when it's complete
    return observable;

  }

  /**
   * Save a task object if it's changed since last load.
   * @param task
   * @returns
   */
  protected templateSaveChangesMinimalTask(task: m5.TaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true): Observable<any> {

    if (!task) {
      return null;
    }

    // Find obj in data list
    const dataIndex = this.templateTaskFindIndex(data, task.TaskTemplateId, false);
    if (!dataIndex || dataIndex.taskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find data index for task id ${task.TaskTemplateId}.`);
      return null;
    }

    // Find obj in original list
    const origIndex = this.templateTaskFindIndex(original, task.TaskTemplateId, false);
    if (!origIndex || origIndex.taskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find original index for task id ${task.TaskTemplateId}.`);
      return null;
    }
    const orig = original[origIndex.taskListIndex].Tasks[origIndex.taskIndex];

    // See if the object changed (ignore subtasks since they are saved separately)
    if (Helper.objectEquals(orig, task, ["Subtasks", "MetaData"])) {
      // No changes
      return null;
    }
    const diff = Helper.objectDiff(orig, task, "TaskTemplateId", "TaskListTemplateId", "MetaData");
    delete diff.Subtasks;  // Subtasks are saved in their own method so remove from diff payload
    const properties: string[] = Helper.objectGetPropertyNameList(diff);
    if (Helper.arrayContentsMatch(properties, ["TaskTemplateId", "TaskListTemplateId", "MetaData"])) {
      // If our only properties are PK, FK and MetaData then our diff is empty in terms of data to merge
      return null;
    }
    if (Helper.arrayContentsMatch(properties, ["TaskTemplateId", "TaskListTemplateId", "Subtasks", "MetaData"])) {
      // If our only properties are PK, FK, Subtasks and MetaData then our diff is empty in terms of data to merge
      return null;
    }

    // console.error("save", diff, task, orig);

    // Save the diff
    const apiCall = ApiHelper.createApiCall(this.apiPropTaskTemplate, ApiOperationType.Merge);
    const observable = this.apiService.execute(apiCall, diff);
    observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskTemplateEditViewModel>) => {
      if (result.Data.Success) {
        this.templateMapAfterLoad(null, null, null, result.Data.Data);
        // console.error(result.Data.Data);
        data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex] = result.Data.Data;
        original[origIndex.taskListIndex].Tasks[origIndex.taskIndex] = Helper.deepCopy(result.Data.Data);
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    // Return the observable so we can know when it's complete
    return observable;

  }


  /**
   * Save a task list object if it's changed since last load.
   * @param taskList
   * @returns
   */
  protected templateSaveChangesMinimalTaskList(taskList: m5.TaskListTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true): Observable<any> {

    if (!taskList) {
      return null;
    }

    // Find obj in data list
    const dataIndex = data.findIndex(x => x.TaskListTemplateId === taskList.TaskListTemplateId);
    if (dataIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find data index for task list id ${taskList.TaskListTemplateId}.`);
      return null;
    }

    // Find obj in original list
    const origIndex = original.findIndex(x => x.TaskListTemplateId === taskList.TaskListTemplateId);
    if (origIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find original index for task list id ${taskList.TaskListTemplateId}.`);
      return null;
    }
    const orig = original[origIndex];

    // See if the object changed (ignore tasks since they are saved separately)
    if (Helper.objectEquals(orig, taskList, ["Tasks", "MetaData"])) {
      // No changes
      return null;
    }
    const diff = Helper.objectDiff(orig, taskList, "TaskListTemplateId", "MetaData");
    delete diff.Tasks; // Tasks are saved in their own method so remove from diff payload
    const properties: string[] = Helper.objectGetPropertyNameList(diff);
    if (Helper.arrayContentsMatch(properties, ["TaskListTemplateId", "MetaData"])) {
      // If our only properties are PK and MetaData then our diff is empty in terms of data to merge
      return null;
    }
    if (Helper.arrayContentsMatch(properties, ["TaskListTemplateId", "Tasks", "MetaData"])) {
      // If our only properties are PK, Tasks and MetaData then our diff is empty in terms of data to merge
      return null;
    }

    // Save the diff
    const apiCall = ApiHelper.createApiCall(this.apiPropTaskListTemplate, ApiOperationType.Merge);
    const observable = this.apiService.execute(apiCall, diff);
    observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskListTemplateEditViewModel>) => {
      if (result.Data.Success) {
        this.templateMapAfterLoad(null, result.Data.Data);
        data[dataIndex] = result.Data.Data;
        original[origIndex] = Helper.deepCopy(result.Data.Data);
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    // Return the observable so we can know when it's complete
    return observable;

  }

  templateTaskListAddNew(
    ownerResourceType: string, ownerResourceId: number, ownerResourceId2: string,
    taskList: m5.TaskListTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!taskList) {
      Log.debugMessage("No task list object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    // Set some values we know we need
    taskList.OwnerResourceType = ownerResourceType;
    taskList.OwnerResourceId = ownerResourceId;
    taskList.OwnerResourceId2 = ownerResourceId2;
    taskList.DisplayOrder = 100;
    if (data.length > 0) {
      taskList.DisplayOrder = (data.slice(-1)[0].DisplayOrder + 100);
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskListTemplate, ApiOperationType.Add);
    this.apiService.execute(apiCall, taskList).subscribe((result: IApiResponseWrapperTyped<m5.TaskListTemplateEditViewModel>) => {
      if (result.Data.Success) {
        this.templateMapAfterLoad(null, result.Data.Data);
        // Add to our list
        data.push(result.Data.Data);
        original.push(Helper.deepCopy(result.Data.Data));
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  templateTaskListAddCopy(
    ownerResourceType: string, ownerResourceId: number, ownerResourceId2: string,
    taskList: m5.TaskListTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!taskList) {
      Log.debugMessage("No task list object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }

    // We may not have been handed a clean copy of an object so make a copy before
    // we start changing values.
    const copy = Helper.deepCopy(taskList);

    // Wipe out PK, FK, and meta data so working with a clean copy to add
    copy.TaskListTemplateId = Helper.randomInteger(true); // PK
    copy.AlternateTaskListTemplateId = Helper.createBase36Guid(20); // SK
    copy.MetaData = new m.MetaDataModel();
    if (copy.Tasks && copy.Tasks.length > 0) {
      copy.Tasks.forEach(task => {
        task.TaskTemplateId = Helper.randomInteger(true); // PK
        task.TaskListTemplateId = copy.TaskListTemplateId; // FK
        task.MetaData = new m.MetaDataModel();
        // Get a new alternate task template id and update any references from old to new value
        const oldAlternateTaskTemplateId: string = task.AlternateTaskTemplateId;
        task.AlternateTaskTemplateId = Helper.createBase36Guid(20); // SK
        copy.Tasks.forEach(related => {
          if (Helper.equals(related.RelatedToAlternateTaskId, oldAlternateTaskTemplateId, true)) {
            related.RelatedToAlternateTaskId = task.AlternateTaskTemplateId;
          }
          if (Helper.contains(related.DependsOnTasks, oldAlternateTaskTemplateId, true)) {
            related.DependsOnTasks = Helper.replaceAll(related.DependsOnTasks, oldAlternateTaskTemplateId, task.AlternateTaskTemplateId);
          }
        });
        if (task.Subtasks && task.Subtasks.length > 0) {
          task.Subtasks.forEach(subtask => {
            subtask.TaskSubtaskTemplateId = Helper.randomInteger(true); // PK
            subtask.TaskTemplateId = task.TaskTemplateId; // FK
            subtask.MetaData = new m.MetaDataModel();
          });
        }
      });
    }

    // Now that we cleaned up the copy for adding we can use our add new method
    return this.templateTaskListAddNew(ownerResourceType, ownerResourceId, ownerResourceId2, copy, data, original, reportErrors);

  }

  templateTaskListDelete(
    taskList: m5.TaskListTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!taskList) {
      Log.debugMessage("No task list object provided so nothing to delete.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskListTemplate, ApiOperationType.Delete);
    this.apiService.execute(apiCall, taskList.TaskListTemplateId).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        // Delete from our list
        const dataIndex = data.findIndex(x => x.TaskListTemplateId === taskList.TaskListTemplateId);
        if (dataIndex > -1) {
          data.splice(dataIndex, 1);
        }
        const origIndex = original.findIndex(x => x.TaskListTemplateId === taskList.TaskListTemplateId);
        if (origIndex > -1) {
          original.splice(origIndex, 1);
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  templateTaskAddNew(
    taskListTemplateId: number,
    task: m5.TaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!task) {
      Log.debugMessage("No task object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    const dataTaskListIndex = data.findIndex(x => x.TaskListTemplateId === taskListTemplateId);
    const origTaskListIndex = original.findIndex(x => x.TaskListTemplateId === taskListTemplateId);

    // Set some values we know we need
    task.TaskListTemplateId = taskListTemplateId;
    task.DisplayOrder = 100;
    if (dataTaskListIndex > -1) {
      if (data[dataTaskListIndex].Tasks.length > 0) {
        task.DisplayOrder = (data[dataTaskListIndex].Tasks.slice(-1)[0].DisplayOrder + 100);
      }
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskTemplate, ApiOperationType.Add);
    this.apiService.execute(apiCall, task).subscribe((result: IApiResponseWrapperTyped<m5.TaskTemplateEditViewModel>) => {
      if (result.Data.Success) {
        this.templateMapAfterLoad(null, null, null, result.Data.Data);
        // Add to our lists
        if (dataTaskListIndex > -1) {
          data[dataTaskListIndex].Tasks.push(result.Data.Data);
        }
        if (origTaskListIndex > -1) {
          original[origTaskListIndex].Tasks.push(Helper.deepCopy(result.Data.Data));
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  templateTaskAddCopy(
    taskListTemplateId: number,
    task: m5.TaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!task) {
      Log.debugMessage("No task object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }

    // We may not have been handed a clean copy of an object so make a copy before
    // we start changing values.
    const copy = Helper.deepCopy(task);

    // Wipe out PK, FK, and meta data so working with a clean copy to add
    copy.TaskTemplateId = Helper.randomInteger(true); // PK
    copy.AlternateTaskTemplateId = Helper.createBase36Guid(20); // SK
    copy.MetaData = new m.MetaDataModel();
    if (copy.Subtasks && copy.Subtasks.length > 0) {
      copy.Subtasks.forEach(subtask => {
        subtask.TaskSubtaskTemplateId = Helper.randomInteger(true); // PK
        subtask.TaskTemplateId = copy.TaskTemplateId; // FK
        subtask.MetaData = new m.MetaDataModel();
      });
    }

    // Now that we cleaned up the copy for adding we can use our add new method
    return this.templateTaskAddNew(taskListTemplateId, copy, data, original, reportErrors);

  }

  templateTaskDelete(
    task: m5.TaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!task) {
      Log.debugMessage("No task object provided so nothing to delete.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskTemplate, ApiOperationType.Delete);
    this.apiService.execute(apiCall, task).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        // Delete from our list
        const dataIndex = this.templateTaskFindIndex(data, task.TaskTemplateId, false);
        if (dataIndex.taskIndex > -1) {
          data[dataIndex.taskListIndex].Tasks.splice(dataIndex.taskIndex, 1);
        }
        const origIndex = this.templateTaskFindIndex(original, task.TaskTemplateId, false);
        if (origIndex.taskIndex > -1) {
          original[origIndex.taskListIndex].Tasks.splice(origIndex.taskIndex, 1);
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  templateSubtaskAddNew(
    taskListTemplateId: number,
    taskTemplateId: number,
    subtask: m5.TaskSubtaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!subtask) {
      Log.debugMessage("No subtask object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    const dataTaskListIndex = this.templateTaskFindIndex(data, taskTemplateId, false);
    const origTaskListIndex = this.templateTaskFindIndex(original, taskTemplateId, false);

    // Set some values we know we need
    subtask.TaskTemplateId = taskTemplateId;
    (subtask as any).TaskListTemplateId = taskListTemplateId; // Needed for our api route
    subtask.DisplayOrder = 100;
    if (dataTaskListIndex.taskIndex > -1) {
      if (data[dataTaskListIndex.taskListIndex].Tasks[dataTaskListIndex.taskIndex].Subtasks.length > 0) {
        subtask.DisplayOrder = (data[dataTaskListIndex.taskListIndex].Tasks[dataTaskListIndex.taskIndex].Subtasks.slice(-1)[0].DisplayOrder + 100);
      }
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropSubtaskTemplate, ApiOperationType.Add);
    this.apiService.execute(apiCall, subtask).subscribe((result: IApiResponseWrapperTyped<m5.TaskSubtaskTemplateEditViewModel>) => {
      if (result.Data.Success) {
        // Add to our lists
        if (dataTaskListIndex.taskIndex > -1) {
          data[dataTaskListIndex.taskListIndex].Tasks[dataTaskListIndex.taskIndex].Subtasks.push(result.Data.Data);
        }
        if (origTaskListIndex.taskIndex > -1) {
          original[origTaskListIndex.taskListIndex].Tasks[origTaskListIndex.taskIndex].Subtasks.push(Helper.deepCopy(result.Data.Data));
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  templateSubtaskAddCopy(
    taskListTemplateId: number,
    taskTemplateId: number,
    subtask: m5.TaskSubtaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!subtask) {
      Log.debugMessage("No subtask object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }

    // We may not have been handed a clean copy of an object so make a copy before
    // we start changing values.
    const copy = Helper.deepCopy(subtask);

    // Wipe out PK, FK, and meta data so working with a clean copy to add
    copy.TaskSubtaskTemplateId = Helper.randomInteger(true); // PK
    copy.MetaData = new m.MetaDataModel();

    // Now that we cleaned up the copy for adding we can use our add new method
    return this.templateSubtaskAddNew(taskListTemplateId, taskTemplateId, copy, data, original, reportErrors);

  }

  templateSubtaskDelete(
    subtask: m5.TaskSubtaskTemplateEditViewModel,
    data: m5.TaskListTemplateEditViewModel[],
    original: m5.TaskListTemplateEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }> {

    if (!subtask) {
      Log.debugMessage("No subtask object provided so nothing to delete.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    const dataIndex = this.templateTaskFindIndex(data, subtask.TaskSubtaskTemplateId, true);
    if (dataIndex.subtaskIndex === -1) {
      Log.errorMessage(`Unable to delete because we could not find the object for subtask id ${subtask.TaskSubtaskTemplateId}.`);
      return (of({ data: data || [], original: original || [] }));
    }

    // Our api route needs task list template id which is not part of the subtask model so push it here
    (subtask as any).TaskListTemplateId = data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex].TaskListTemplateId;

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListTemplateEditViewModel[], original: m5.TaskListTemplateEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropSubtaskTemplate, ApiOperationType.Delete);
    this.apiService.execute(apiCall, subtask).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        // Delete from our list
        data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex].Subtasks.splice(dataIndex.subtaskIndex, 1);
        const origIndex = this.templateTaskFindIndex(original, subtask.TaskTemplateId, true);
        if (origIndex.subtaskIndex > -1) {
          original[origIndex.taskListIndex].Tasks[origIndex.taskIndex].Subtasks.splice(origIndex.subtaskIndex, 1);
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }


  templateTaskFindIndex(data: m5.TaskListTemplateEditViewModel[], taskOrSubtaskId: number, isSubtask: boolean): { taskListIndex: number; taskIndex: number; subtaskIndex: number } {
    const index: { taskListIndex: number; taskIndex: number; subtaskIndex: number } = { taskListIndex: -1, taskIndex: -1, subtaskIndex: -1 };
    data.forEach((list, taskListIndex, lists) => {
      list.Tasks.forEach((task, taskIndex, tasks) => {
        if (task.TaskTemplateId === taskOrSubtaskId && !isSubtask) {
          index.taskListIndex = taskListIndex;
          index.taskIndex = taskIndex;
          return; // exit forEach
        }
        if (task.Subtasks && task.Subtasks.length > 0) {
          task.Subtasks.forEach((subtask, subtaskIndex, subtasks) => {
            if (subtask.TaskSubtaskTemplateId === taskOrSubtaskId && isSubtask) {
              index.taskListIndex = taskListIndex;
              index.taskIndex = taskIndex;
              index.subtaskIndex = subtaskIndex;
              return; // exit forEach
            }
          });
        }
      });
      if (index.taskIndex > -1) {
        return; // exit forEach
      }
    });
    return index;
  }

  templateTaskBuildPickList(excludeTaskId: number, data: m5.TaskListTemplateEditViewModel[], useAlternateTaskTemplateIdAsValue: boolean): m5core.PickListSelectionViewModel[] {
    const pickList: m5core.PickListSelectionViewModel[] = [];
    data.forEach(list => {
      list.Tasks.forEach(task => {
        if (excludeTaskId && task.TaskTemplateId && task.TaskTemplateId === excludeTaskId) {
          // Omit this task from our pick list
        } else {
          const option: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
          if (useAlternateTaskTemplateIdAsValue) {
            option.Value = task.AlternateTaskTemplateId;
          } else {
            option.Value = (task.TaskTemplateId || 0).toString();
          }
          option.DisplayText = task.Description;
          option.GroupText = list.Description;
          option.DisplayOrder = (list.DisplayOrder * 10000) + task.DisplayOrder;
          pickList.push(option);
        }
      });
    });
    return pickList;
  }

  templateTaskListFind(taskListId: number, data: m5.TaskListTemplateEditViewModel[]): m5.TaskListTemplateEditViewModel {
    let found: m5.TaskListTemplateEditViewModel = null;
    data.forEach(list => {
      if (list.TaskListTemplateId === taskListId) {
        found = list;
        return; // exit forEach
      }
    });
    return found;
  }

  templateTaskFind(taskId: number, data: m5.TaskListTemplateEditViewModel[]): m5.TaskTemplateEditViewModel {
    let found: m5.TaskTemplateEditViewModel = null;
    data.forEach(list => {
      list.Tasks.forEach(task => {
        if (task.TaskTemplateId === taskId) {
          found = task;
          return; // exit forEach
        }
      });
      if (found) {
        return; // exit forEach
      }
    });
    return found;
  }


  checkIfTaskStatusAllowsAction(
    taskLists: m5.TaskListEditViewModel[],
    requestedAction: "WorkBegins" | "WorkOutput" | "Review" | "ReviewSuccess" | "Finish"): TaskStatusAllowsActionResult {

    const result: TaskStatusAllowsActionResult = { requestedAction: requestedAction, success: true, errors: [], errorMessageTitle: "", errorMessageBody: "", errorMessageBodyHtml: "" };

    if (!taskLists || taskLists.length === 0) {
      return result;
    }

    // Possible required flags for task templates includes:
    // "B = Required Before Work Begins (The task is required to be done before work begins.  The context of what work beginning means is dependent on the owning object.  For cases that would mean before completing forms related to documenting the case.)" + Environment.NewLine +
    // "O = Required Before Work Output (The task is required to be done before work output.  The context of what work output means is dependent on the owning object.  For cases that would mean before creating a report for the case.)" + Environment.NewLine +
    // "R = Required Before Review (The task is required to be done before review.  The context of what review means is dependent on the owning object.  For cases that would mean before submitting for review.)" + Environment.NewLine +
    // "S = Required Before Review Success (The task is required to be done before review marked as successful.  The context of what review means is dependent on the owning object but implies only the reviewer can complete this task.  For cases that would mean before the reviewer marks the case as reviewed with success outcome.)" + Environment.NewLine +
    // "F = Required Before Finish (The task is required to be done before finish.  The context of what finish means is dependent on the owning object.  For cases that would mean before closing the case.)" );

    taskLists.forEach(list => {
      if (!list.Tasks || list.Tasks.length === 0) {
        return; // return = continue inside foreach
      }
      list.Tasks.forEach(task => {
        if (!task.ActualCloseDateTime) {
          const template = task.MetaData?.Properties?.Template as m5.TaskTemplateEditViewModel;
          // Some tasks are custom and do not have a template so make sure we have a template before checking template settings
          if (template) {
            if (Helper.equals(template.Required, "B", true) && requestedAction === "WorkBegins") {
              const message = `Task "${task.Description}" must be completed before work can begin.`;
              result.errors.push({ taskId: task.TaskId, description: task.Description, errorMessage: message, requiredFlag: template.Required, requiredReason: template.RequiredReason, task: task, template: template });
              result.success = false;
            } else if (Helper.equals(template.Required, "O", true) && requestedAction === "WorkOutput") {
              const message = `Task "${task.Description}" must be completed before work can be output.`;
              result.errors.push({ taskId: task.TaskId, description: task.Description, errorMessage: message, requiredFlag: template.Required, requiredReason: template.RequiredReason, task: task, template: template });
              result.success = false;
            } else if (Helper.equals(template.Required, "R", true) && requestedAction === "Review") {
              const message = `Task "${task.Description}" must be completed before review.`;
              result.errors.push({ taskId: task.TaskId, description: task.Description, errorMessage: message, requiredFlag: template.Required, requiredReason: template.RequiredReason, task: task, template: template });
              result.success = false;
            } else if (Helper.equals(template.Required, "S", true) && requestedAction === "ReviewSuccess") {
              const message = `Task "${task.Description}" must be completed before successful review.`;
              result.errors.push({ taskId: task.TaskId, description: task.Description, errorMessage: message, requiredFlag: template.Required, requiredReason: template.RequiredReason, task: task, template: template });
              result.success = false;
            } else if (Helper.equals(template.Required, "F", true) && requestedAction === "Finish") {
              const message = `Task "${task.Description}" must be completed before work can be finished.`;
              result.errors.push({ taskId: task.TaskId, description: task.Description, errorMessage: message, requiredFlag: template.Required, requiredReason: template.RequiredReason, task: task, template: template });
              result.success = false;
            }
          }
        }
      });
    });

    // Build a message to explain the reason we can't do the requested action
    if (result.errors.length > 0) {
      result.errorMessageBodyHtml += "<ul>\n";
      result.errors.forEach(error => {
        result.errorMessageBody += `${error.errorMessage} ${error.requiredReason}\n`;
        result.errorMessageBodyHtml += `<li>${error.errorMessage} ${error.requiredReason}</li>\n`;
      });
      result.errorMessageBodyHtml += "</ul>\n";
      result.errorMessageTitle = `${result.errors.length} Tasks Need To Be Completed`;
      if (result.errors.length === 1) {
        result.errorMessageTitle = `One Task Needs To Be Completed`;
      }
    } else if (!result.success) {
      result.errorMessageTitle = `Some Tasks Need To Be Completed`;
      result.errorMessageBody = "One or more tasks need to be completed before you can proceed.";
      result.errorMessageBodyHtml = "One or more tasks need to be completed before you can proceed.";
    }

    return result;

  }




  load(ownerResourceType: string, ownerResourceId: number, ownerResourceId2: string,
    templateOwnerResourceType: string, templateOwnerResourceId: number, templateOwnerResourceId2: string,
    reportErrors: boolean = true): Observable<m5.TaskListEditViewModel[]> {
    if (!ownerResourceType || (!ownerResourceId && !ownerResourceId2)) {
      Log.errorMessage("Unable to load task lists due to missing owner type or id.");
      return of([]);
    }
    const query: Query = new Query("DisplayOrder", Constants.RowsToReturn.All);
    if (ownerResourceId) {
      query.Filter = `OwnerResourceType == "${ownerResourceType}" && OwnerResourceId == ${ownerResourceId}`;
    } else {
      query.Filter = `OwnerResourceType == "${ownerResourceType}" && OwnerResourceId2 == "${ownerResourceId2}"`;
    }
    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<m5.TaskListEditViewModel[]>();
    const apiCall = ApiHelper.createApiCall(this.apiPropTaskList, ApiOperationType.List);
    this.apiService.execute(apiCall, query).subscribe((result: IApiResponseWrapperTyped<m5.TaskListEditViewModel[]>) => {
      if (result.Data.Success) {
        const data = result.Data.Data;
        this.sort(data);
        if (data && data.length > 0) {
          if (templateOwnerResourceType && (templateOwnerResourceId || templateOwnerResourceId2)) {
            this.templateLoad(templateOwnerResourceType, templateOwnerResourceId, templateOwnerResourceId2)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((taskListTemplates: m5.TaskListTemplateEditViewModel[]) => {
                this.templateAssign(data, taskListTemplates);
                subject.next(data);
                subject.complete();
              });
          } else {
            Log.warningMessage("No task template owner resource provided so unable to load task template information for the tasks loaded.");
            subject.next(data);
            subject.complete();
          }
        } else {
          subject.next(data);
          subject.complete();
        }
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        }
        subject.next([]);
        subject.complete();
      }
    });
    return subject.asObservable();
  }

  /**
   *
   * @param data
   * @param original
   * @param minimizeApiPayload
   * When saving and this value is false we call edit/put on each task list.
   * When this value is true we inspect objects to only save what has changed
   * and do that via merge instead of put request.
   * @returns
   */
  saveChanges(data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true,
    minimizeApiPayload: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[], results: IApiResponseWrapper[] }> {

    if (!data || data.length === 0) {
      Log.debugMessage("No task lists provided so nothing to save.");
      return (of({ data: [], original: [], results: [] }));
    }

    if (minimizeApiPayload && original && original.length === data.length) {
      return this.saveChangesMinimal(data, original, reportErrors);
    } else {
      return this.saveChangesFull(data, original, reportErrors);
    }

  }


  protected saveChangesFull(data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[], reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[], results: IApiResponseWrapper[] }> {

    if (!data || data.length === 0) {
      Log.debugMessage("No task lists provided so nothing to save.");
      return (of({ data: [], original: [], results: [] }));
    }

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskList, ApiOperationType.Edit);

    // Keep a list of observables from each api call so we can provided the needed aggregate response
    const observables: Observable<IApiResponseWrapperTyped<m5.TaskListEditViewModel>>[] = [];

    // Step through each task list and call edit
    data.forEach((taskList: m5.TaskListEditViewModel, index: number, tasksLists: m5.TaskListEditViewModel[]) => {
      const observable = this.apiService.execute(apiCall, taskList);
      observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskListEditViewModel>) => {
        if (result.Data.Success) {
          // Update our data array with the response
          data[index] = result.Data.Data;
          // Get the template from our original object's meta data
          const origIndex = original.findIndex(x => x.TaskListId === result.Data.Data.TaskListId);
          if (origIndex > -1) {
            data[index].MetaData.Properties = original[origIndex].MetaData.Properties;
          }
        } else if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        }
      });
      // Save our observable so we can aggregate our response
      observables.push(observable);
    });

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[], results: IApiResponseWrapper[] }>();

    // When all observables have completed then sort and make a copy of our data array and output
    // to the subject we expose observable to so consumers can get the data and original arrays.
    forkJoin(observables)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((results: IApiResponseWrapper[]) => {
        // The data array was updated in the individual observable subscriptions above
        this.sort(data);
        subject.next({ data: data, original: Helper.deepCopy(data), results: results });
        subject.complete();
      });

    return subject.asObservable();

  }


  protected saveChangesMinimal(data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[], results: IApiResponseWrapper[] }> {

    if (!data || data.length === 0) {
      Log.debugMessage("No task lists provided so nothing to save.");
      return (of({ data: [], original: [], results: [] }));
    }

    const observables: Observable<IApiResponseWrapper>[] = [];

    data.forEach(taskList => {
      // Check tasks
      if (taskList.Tasks && taskList.Tasks.length > 0) {
        taskList.Tasks.forEach(task => {
          // Check subtasks
          if (task.Subtasks && task.Subtasks.length > 0) {
            task.Subtasks.forEach(subtask => {
              // See if subtask changed
              const obsSubtask = this.saveChangesMinimalSubtask(subtask, data, original, reportErrors);
              if (obsSubtask) {
                observables.push(obsSubtask);
              }
            });
          }
          // See if task changed
          const obsTask = this.saveChangesMinimalTask(task, data, original, reportErrors);
          if (obsTask) {
            observables.push(obsTask);
          }
        });
      }
      // See if task list changed
      const obsTaskList = this.saveChangesMinimalTaskList(taskList, data, original, reportErrors);
      if (obsTaskList) {
        observables.push(obsTaskList);
      }
    });

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[], results: IApiResponseWrapper[] }>();

    // When all observables have completed then we can output our updated lists
    forkJoin(observables)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((results: IApiResponseWrapper[]) => {
        // The data and original arrays were updated in the individual helper methods called above
        this.sort(data);
        subject.next({ data: data, original: original, results: results });
        subject.complete();
      });

    return subject.asObservable();

  }


  /**
   * Save a subtask object if it's changed since last load.
   * @param subtask
   * @returns
   */
  protected saveChangesMinimalSubtask(subtask: m5.TaskSubtaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true): Observable<IApiResponseWrapper> {

    if (!subtask) {
      return null;
    }

    // Find obj in data list
    const dataIndex = this.taskFindIndex(data, subtask.TaskSubtaskId, true);
    if (!dataIndex || dataIndex.subtaskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find data index for subtask id ${subtask.TaskSubtaskId}.`);
      return null;
    }

    // Find obj in original list
    const origIndex = this.taskFindIndex(original, subtask.TaskSubtaskId, true);
    if (!origIndex || origIndex.subtaskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find original index for subtask id ${subtask.TaskSubtaskId}.`);
      return null;
    }
    const orig = original[origIndex.taskListIndex].Tasks[origIndex.taskIndex].Subtasks[origIndex.subtaskIndex];

    // See if the object changed
    if (Helper.objectEquals(orig, subtask, ["MetaData"])) {
      // No changes
      return null;
    }
    const diff = Helper.objectDiff(orig, subtask, "TaskSubtaskId", "TaskId", "MetaData");
    const properties: string[] = Helper.objectGetPropertyNameList(diff);
    if (Helper.arrayContentsMatch(properties, ["TaskSubtaskId", "TaskId", "MetaData"])) {
      // If our only properties are PK, FK and MetaData then our diff is empty in terms of data to merge
      return null;
    }

    // Our api route also requires the task list id but that's not part of the object so push it in now
    diff.TaskListId = original[origIndex.taskListIndex].TaskListId;
    // Drop any template references we pushed into meta data properties
    if (diff?.MetaData?.Properties) {
      diff.MetaData.Properties = null;
    }

    // Save the diff
    const apiCall = ApiHelper.createApiCall(this.apiPropSubtask, ApiOperationType.Merge);
    const observable = this.apiService.execute(apiCall, diff);
    observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskSubtaskEditViewModel>) => {
      if (result.Data.Success) {
        // Get the template from our original object's meta data
        result.Data.Data.MetaData.Properties = orig.MetaData.Properties;
        // Update our data and original objects with this updated object
        data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex].Subtasks[dataIndex.subtaskIndex] = result.Data.Data;
        original[origIndex.taskListIndex].Tasks[origIndex.taskIndex].Subtasks[origIndex.subtaskIndex] = Helper.deepCopy(result.Data.Data);
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    // Return the observable so we can know when it's complete
    return observable;

  }

  /**
   * Save a task object if it's changed since last load.
   * @param task
   * @returns
   */
  protected saveChangesMinimalTask(task: m5.TaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true): Observable<IApiResponseWrapper> {

    if (!task) {
      return null;
    }

    // Find obj in data list
    const dataIndex = this.taskFindIndex(data, task.TaskId, false);
    if (!dataIndex || dataIndex.taskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find data index for task id ${task.TaskId}.`);
      return null;
    }

    // Find obj in original list
    const origIndex = this.taskFindIndex(original, task.TaskId, false);
    if (!origIndex || origIndex.taskIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find original index for task id ${task.TaskId}.`);
      return null;
    }
    const orig = original[origIndex.taskListIndex].Tasks[origIndex.taskIndex];

    // See if the object changed (ignore subtasks since they are saved separately)
    if (Helper.objectEquals(orig, task, ["Subtasks", "MetaData"])) {
      // No changes
      return null;
    }
    const diff = Helper.objectDiff(orig, task, "TaskId", "TaskListId", "MetaData");
    delete diff.Subtasks;  // Subtasks are saved in their own method so remove from diff payload
    const properties: string[] = Helper.objectGetPropertyNameList(diff);
    if (Helper.arrayContentsMatch(properties, ["TaskId", "TaskListId", "MetaData"])) {
      // If our only properties are PK, FK and MetaData then our diff is empty in terms of data to merge
      return null;
    }
    if (Helper.arrayContentsMatch(properties, ["TaskId", "TaskListId", "Subtasks", "MetaData"])) {
      // If our only properties are PK, FK, Subtasks and MetaData then our diff is empty in terms of data to merge
      return null;
    }

    // Drop any template references we pushed into meta data properties
    if (diff?.MetaData?.Properties) {
      diff.MetaData.Properties = null;
    }

    // Save the diff
    const apiCall = ApiHelper.createApiCall(this.apiPropTask, ApiOperationType.Merge);
    const observable = this.apiService.execute(apiCall, diff);
    observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskEditViewModel>) => {
      if (result.Data.Success) {
        // Get the template from our original object's meta data
        result.Data.Data.MetaData.Properties = orig.MetaData.Properties;
        // Update our data and original objects with this updated object
        data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex] = result.Data.Data;
        original[origIndex.taskListIndex].Tasks[origIndex.taskIndex] = Helper.deepCopy(result.Data.Data);
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    // Return the observable so we can know when it's complete
    return observable;

  }


  /**
   * Save a task list object if it's changed since last load.
   * @param taskList
   * @returns
   */
  protected saveChangesMinimalTaskList(taskList: m5.TaskListEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true): Observable<IApiResponseWrapper> {

    if (!taskList) {
      return null;
    }

    // Find obj in data list
    const dataIndex = data.findIndex(x => x.TaskListId === taskList.TaskListId);
    if (dataIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find data index for task list id ${taskList.TaskListId}.`);
      return null;
    }

    // Find obj in original list
    const origIndex = original.findIndex(x => x.TaskListId === taskList.TaskListId);
    if (origIndex === -1) {
      Log.errorMessage(`Unable to save data because we could not find original index for task list id ${taskList.TaskListId}.`);
      return null;
    }
    const orig = original[origIndex];

    // See if the object changed (ignore tasks since they are saved separately)
    if (Helper.objectEquals(orig, taskList, ["Tasks", "MetaData"])) {
      // No changes
      return null;
    }
    const diff = Helper.objectDiff(orig, taskList, "TaskListId", "MetaData");
    delete diff.Tasks; // Tasks are saved in their own method so remove from diff payload
    const properties: string[] = Helper.objectGetPropertyNameList(diff);
    if (Helper.arrayContentsMatch(properties, ["TaskListId", "MetaData"])) {
      // If our only properties are PK and MetaData then our diff is empty in terms of data to merge
      return null;
    }
    if (Helper.arrayContentsMatch(properties, ["TaskListId", "Tasks", "MetaData"])) {
      // If our only properties are PK, Tasks and MetaData then our diff is empty in terms of data to merge
      return null;
    }

    // Drop any template references we pushed into meta data properties
    if (diff?.MetaData?.Properties) {
      diff.MetaData.Properties = null;
    }

    // Save the diff
    const apiCall = ApiHelper.createApiCall(this.apiPropTaskList, ApiOperationType.Merge);
    const observable = this.apiService.execute(apiCall, diff);
    observable.subscribe((result: IApiResponseWrapperTyped<m5.TaskListEditViewModel>) => {
      if (result.Data.Success) {
        // Get the template from our original object's meta data
        result.Data.Data.MetaData.Properties = orig.MetaData.Properties;
        // Update our data and original objects with this updated object
        data[dataIndex] = result.Data.Data;
        original[origIndex] = Helper.deepCopy(result.Data.Data);
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    // Return the observable so we can know when it's complete
    return observable;

  }

  taskListAddNew(
    ownerResourceType: string, ownerResourceId: number, ownerResourceId2: string,
    taskList: m5.TaskListEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!taskList) {
      Log.debugMessage("No task list object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    // Set some values we know we need
    taskList.OwnerResourceType = ownerResourceType;
    taskList.OwnerResourceId = ownerResourceId;
    taskList.OwnerResourceId2 = ownerResourceId2;
    taskList.DisplayOrder = 100;
    if (data.length > 0) {
      taskList.DisplayOrder = (data.slice(-1)[0].DisplayOrder + 100);
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskList, ApiOperationType.Add);
    this.apiService.execute(apiCall, taskList).subscribe((result: IApiResponseWrapperTyped<m5.TaskListEditViewModel>) => {
      if (result.Data.Success) {
        // Add to our list
        data.push(result.Data.Data);
        original.push(Helper.deepCopy(result.Data.Data));
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  taskListAddCopy(
    ownerResourceType: string, ownerResourceId: number, ownerResourceId2: string,
    taskList: m5.TaskListEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!taskList) {
      Log.debugMessage("No task list object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }

    // We may not have been handed a clean copy of an object so make a copy before
    // we start changing values.
    const copy = Helper.deepCopy(taskList);

    // Wipe out PK, FK, and meta data so working with a clean copy to add
    copy.TaskListId = Helper.randomInteger(true); // PK
    copy.MetaData = new m.MetaDataModel();
    if (copy.Tasks && copy.Tasks.length > 0) {
      copy.Tasks.forEach(task => {
        task.TaskId = Helper.randomInteger(true); // PK
        task.TaskListId = copy.TaskListId; // FK
        task.MetaData = new m.MetaDataModel();
        if (task.Subtasks && task.Subtasks.length > 0) {
          task.Subtasks.forEach(subtask => {
            subtask.TaskSubtaskId = Helper.randomInteger(true); // PK
            subtask.TaskId = task.TaskId; // FK
            subtask.MetaData = new m.MetaDataModel();
          });
        }
      });
    }

    // Now that we cleaned up the copy for adding we can use our add new method
    return this.taskListAddNew(ownerResourceType, ownerResourceId, ownerResourceId2, copy, data, original, reportErrors);

  }

  taskListDelete(
    taskList: m5.TaskListEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!taskList) {
      Log.debugMessage("No task list object provided so nothing to delete.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTaskList, ApiOperationType.Delete);
    this.apiService.execute(apiCall, taskList.TaskListId).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        // Delete from our list
        const dataIndex = data.findIndex(x => x.TaskListId === taskList.TaskListId);
        if (dataIndex > -1) {
          data.splice(dataIndex, 1);
        }
        const origIndex = original.findIndex(x => x.TaskListId === taskList.TaskListId);
        if (origIndex > -1) {
          original.splice(origIndex, 1);
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  taskAddNew(
    taskListId: number,
    task: m5.TaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!task) {
      Log.debugMessage("No task object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    const dataTaskListIndex = data.findIndex(x => x.TaskListId === taskListId);
    const origTaskListIndex = original.findIndex(x => x.TaskListId === taskListId);

    // Set some values we know we need
    task.TaskListId = taskListId;
    task.DisplayOrder = 100;
    if (dataTaskListIndex > -1) {
      if (data[dataTaskListIndex].Tasks.length > 0) {
        task.DisplayOrder = (data[dataTaskListIndex].Tasks.slice(-1)[0].DisplayOrder + 100);
      }
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTask, ApiOperationType.Add);
    this.apiService.execute(apiCall, task).subscribe((result: IApiResponseWrapperTyped<m5.TaskEditViewModel>) => {
      if (result.Data.Success) {
        // Add to our lists
        if (dataTaskListIndex > -1) {
          data[dataTaskListIndex].Tasks.push(result.Data.Data);
        }
        if (origTaskListIndex > -1) {
          original[origTaskListIndex].Tasks.push(Helper.deepCopy(result.Data.Data));
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  taskAddCopy(
    taskListId: number,
    task: m5.TaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!task) {
      Log.debugMessage("No task object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }

    // We may not have been handed a clean copy of an object so make a copy before
    // we start changing values.
    const copy = Helper.deepCopy(task);

    // Wipe out PK, FK, and meta data so working with a clean copy to add
    copy.TaskId = Helper.randomInteger(true); // PK
    copy.MetaData = new m.MetaDataModel();
    if (copy.Subtasks && copy.Subtasks.length > 0) {
      copy.Subtasks.forEach(subtask => {
        subtask.TaskSubtaskId = Helper.randomInteger(true); // PK
        subtask.TaskId = copy.TaskId; // FK
        subtask.MetaData = new m.MetaDataModel();
      });
    }

    // Now that we cleaned up the copy for adding we can use our add new method
    return this.taskAddNew(taskListId, copy, data, original, reportErrors);

  }

  taskDelete(
    task: m5.TaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!task) {
      Log.debugMessage("No task object provided so nothing to delete.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropTask, ApiOperationType.Delete);
    this.apiService.execute(apiCall, task).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        // Delete from our list
        const dataIndex = this.taskFindIndex(data, task.TaskId, false);
        if (dataIndex.taskIndex > -1) {
          data[dataIndex.taskListIndex].Tasks.splice(dataIndex.taskIndex, 1);
        }
        const origIndex = this.taskFindIndex(original, task.TaskId, false);
        if (origIndex.taskIndex > -1) {
          original[origIndex.taskListIndex].Tasks.splice(origIndex.taskIndex, 1);
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  subtaskAddNew(
    taskListId: number,
    taskId: number,
    subtask: m5.TaskSubtaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!subtask) {
      Log.debugMessage("No subtask object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    const dataTaskListIndex = this.taskFindIndex(data, taskId, false);
    const origTaskListIndex = this.taskFindIndex(original, taskId, false);

    // Set some values we know we need
    subtask.TaskId = taskId;
    (subtask as any).TaskListId = taskListId; // Needed for our api route
    subtask.DisplayOrder = 100;
    if (dataTaskListIndex.taskIndex > -1) {
      if (data[dataTaskListIndex.taskListIndex].Tasks[dataTaskListIndex.taskIndex].Subtasks.length > 0) {
        subtask.DisplayOrder = (data[dataTaskListIndex.taskListIndex].Tasks[dataTaskListIndex.taskIndex].Subtasks.slice(-1)[0].DisplayOrder + 100);
      }
    }

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropSubtask, ApiOperationType.Add);
    this.apiService.execute(apiCall, subtask).subscribe((result: IApiResponseWrapperTyped<m5.TaskSubtaskEditViewModel>) => {
      if (result.Data.Success) {
        // Add to our lists
        if (dataTaskListIndex.taskIndex > -1) {
          data[dataTaskListIndex.taskListIndex].Tasks[dataTaskListIndex.taskIndex].Subtasks.push(result.Data.Data);
        }
        if (origTaskListIndex.taskIndex > -1) {
          original[origTaskListIndex.taskListIndex].Tasks[origTaskListIndex.taskIndex].Subtasks.push(Helper.deepCopy(result.Data.Data));
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }

  subtaskAddCopy(
    taskListId: number,
    taskId: number,
    subtask: m5.TaskSubtaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!subtask) {
      Log.debugMessage("No subtask object provided so nothing to save.");
      return (of({ data: data || [], original: original || [] }));
    }

    // We may not have been handed a clean copy of an object so make a copy before
    // we start changing values.
    const copy = Helper.deepCopy(subtask);

    // Wipe out PK, FK, and meta data so working with a clean copy to add
    copy.TaskSubtaskId = Helper.randomInteger(true); // PK
    copy.MetaData = new m.MetaDataModel();

    // Now that we cleaned up the copy for adding we can use our add new method
    return this.subtaskAddNew(taskListId, taskId, copy, data, original, reportErrors);

  }

  subtaskDelete(
    subtask: m5.TaskSubtaskEditViewModel,
    data: m5.TaskListEditViewModel[],
    original: m5.TaskListEditViewModel[],
    reportErrors: boolean = true):
    Observable<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }> {

    if (!subtask) {
      Log.debugMessage("No subtask object provided so nothing to delete.");
      return (of({ data: data || [], original: original || [] }));
    }
    if (!data) {
      data = [];
    }
    if (!original) {
      original = [];
    }

    const dataIndex = this.taskFindIndex(data, subtask.TaskSubtaskId, true);
    if (dataIndex.subtaskIndex === -1) {
      Log.errorMessage(`Unable to delete because we could not find the object for subtask id ${subtask.TaskSubtaskId}.`);
      return (of({ data: data || [], original: original || [] }));
    }

    // Our api route needs task list  id which is not part of the subtask model so push it here
    (subtask as any).TaskListId = data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex].TaskListId;

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<{ data: m5.TaskListEditViewModel[], original: m5.TaskListEditViewModel[] }>();

    const apiCall = ApiHelper.createApiCall(this.apiPropSubtask, ApiOperationType.Delete);
    this.apiService.execute(apiCall, subtask).subscribe((result: IApiResponseWrapper) => {
      if (result.Data.Success) {
        // Delete from our list
        data[dataIndex.taskListIndex].Tasks[dataIndex.taskIndex].Subtasks.splice(dataIndex.subtaskIndex, 1);
        const origIndex = this.taskFindIndex(original, subtask.TaskId, true);
        if (origIndex.subtaskIndex > -1) {
          original[origIndex.taskListIndex].Tasks[origIndex.taskIndex].Subtasks.splice(origIndex.subtaskIndex, 1);
        }
        // Push to subscribers
        subject.next({ data: data, original: original });
        subject.complete();
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
      }
    });

    return subject.asObservable();

  }


  taskFindIndex(data: m5.TaskListEditViewModel[], taskOrSubtaskId: number, isSubtask: boolean): { taskListIndex: number; taskIndex: number; subtaskIndex: number } {
    const index: { taskListIndex: number; taskIndex: number; subtaskIndex: number } = { taskListIndex: -1, taskIndex: -1, subtaskIndex: -1 };
    data.forEach((list, taskListIndex, lists) => {
      list.Tasks.forEach((task, taskIndex, tasks) => {
        if (task.TaskId === taskOrSubtaskId && !isSubtask) {
          index.taskListIndex = taskListIndex;
          index.taskIndex = taskIndex;
          return; // exit forEach
        }
        if (task.Subtasks && task.Subtasks.length > 0) {
          task.Subtasks.forEach((subtask, subtaskIndex, subtasks) => {
            if (subtask.TaskSubtaskId === taskOrSubtaskId && isSubtask) {
              index.taskListIndex = taskListIndex;
              index.taskIndex = taskIndex;
              index.subtaskIndex = subtaskIndex;
              return; // exit forEach
            }
          });
        }
      });
      if (index.taskIndex > -1) {
        return; // exit forEach
      }
    });
    return index;
  }

  taskBuildPickList(excludeTaskId: number, data: m5.TaskListEditViewModel[]): m5core.PickListSelectionViewModel[] {
    const pickList: m5core.PickListSelectionViewModel[] = [];
    data.forEach(list => {
      list.Tasks.forEach(task => {
        if (excludeTaskId && task.TaskId && task.TaskId === excludeTaskId) {
          // Omit this task from our pick list
        } else {
          const option: m5core.PickListSelectionViewModel = new m5core.PickListSelectionViewModel();
          option.Value = (task.TaskId || 0).toString();
          option.DisplayText = task.Description;
          option.GroupText = list.Description;
          option.DisplayOrder = (list.DisplayOrder * 10000) + task.DisplayOrder;
          pickList.push(option);
        }
      });
    });
    return pickList;
  }

  taskListFind(taskListId: number, data: m5.TaskListEditViewModel[]): m5.TaskListEditViewModel {
    let found: m5.TaskListEditViewModel = null;
    data.forEach(list => {
      if (list.TaskListId === taskListId) {
        found = list;
        return; // exit forEach
      }
    });
    return found;
  }

  taskFind(taskId: number, data: m5.TaskListEditViewModel[]): m5.TaskEditViewModel {
    let found: m5.TaskEditViewModel = null;
    data.forEach(list => {
      list.Tasks.forEach(task => {
        if (task.TaskId === taskId) {
          found = task;
          return; // exit forEach
        }
      });
      if (found) {
        return; // exit forEach
      }
    });
    return found;
  }


}


/**
 * Interface returned from @see checkIfTaskStatusAllowsAction method.
 */
export interface TaskStatusAllowsActionResult {
  requestedAction: "WorkBegins" | "WorkOutput" | "Review" | "ReviewSuccess" | "Finish";
  success: boolean;
  errors: TaskStatusAllowsActionError[];
  errorMessageTitle: string;
  errorMessageBody: string;
  errorMessageBodyHtml: string;
}

/**
 * Interface of task that has error.  A collection of these is part of the @see TaskStatusAllowsActionResult interface.
 */
export interface TaskStatusAllowsActionError {
  taskId?: number;
  description?: string;
  errorMessage?: string;
  requiredFlag?: string;
  requiredReason?: string;
  task?: m5.TaskEditViewModel;
  template?: m5.TaskTemplateEditViewModel;
}
