import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { FormGroupFluentModel } from '../../form/form-fluent-model';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { EventModelTyped, ButtonItem, Action, EventModel } from '../../ux-models';
import { AlertItemType } from '../../alert/alert-manager';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputKeyValuePairListComponent),
  multi: true
};

@Component({
  selector: 'ib-input-key-value-pair-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-key-value-pair-list.component.html',
  styleUrls: ['./input-key-value-pair-list.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputKeyValuePairListComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() headerIcon: string = "";
  @Input() headerText: string = "";
  @Input() headerClass: string = "header";

  @Input() addButtonIcon: string = "";
  @Input() addButtonText: string = "";
  @Input() addButtonColor: "primary" | "secondary" | "success" | "warning" | "danger" | "info" | "light" | "dark" | "default" = "primary";
  @Input() addButtonSize: "" | "lg" | "sm" | "xs" = "xs";
  @Input() addButtonClass: string = "";

  @Input() canEdit: boolean = true;
  @Input() canCopy: boolean = true;
  @Input() canDelete: boolean = true;

  @Input() itemLabel: string = "";
  @Input() itemsLabel: string = "";
  @Input() itemClass: string = "";

  @Input() wrapperClass: string = "";
  @Input() itemsWrapperClass: string = "";
  @Input() modelIsJsonString: boolean = false;

  obj: { [key: string]: string } = {};
  actionButton: ButtonItem = null;

  /**
   * This Object reference set to public property so it can be accessed from the html template.
   */
  Object = Object;

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
    // super.ngOnInit();
    this.actionButton = this.getActionButton();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.actionButton = this.getActionButton();
    // if (changes.ngModel) {
    //  if (this.modelIsJsonString) {
    //    console.error("input json value change", changes.ngModel);
    //    this.list = JSON.parse(this.value);
    //  } else if (this.modelIsCsvString) {
    //    console.error("input csv value change", changes.ngModel);
    //    this.list = Helper.parseCsvString(this.value);
    //  }
    // }
    if (this.itemLabel && !this.itemsLabel) {
      this.itemsLabel = Helper.plural(this.itemLabel, 2);
    }
  }


  writeValue(value: any) {
    super.writeValue(value);
    if (this.modelIsJsonString) {
      // console.error("input json value change", value);
      try {
        this.obj = JSON.parse(value);
      } catch (ex) {
        this.obj = {};
        console.error("json parse error", value, ex);
      }
    } else {
      // console.error("input list value change", value);
    }
  }

  add($event) {
    this.itemModal("", "", -1, "add");
  }

  itemModal(key: string, value: any, index: number, mode: "add" | "edit" | "view") {

    const label = this.itemLabel || "Item";
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = label;
    if (mode === "add") {
      options.title = "Add " + label;
    } else if (mode === "edit") {
      options.title = "Edit " + label;
    }

    // Build the form
    const group = new FormGroupFluentModel("row");
    group.HasDescendantGroup("column", "", Constants.Layout.split2column)
      .HasInputString("Key", "Key", "Input", "Key", "F", true);
    group.LastControl().LabelLayout = "T";
    group.HasDescendantSiblingGroup("column", "", Constants.Layout.split2column)
      .HasInputString("Value", "Value", "Input", "Value", "F");
    group.LastControl().LabelLayout = "T";
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Input: { Key: string; Value: any } } = { Input: { Key: key, Value: value } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Input: { Key: string; Value: any } }>) => {
      // Logic is same for add or edit on kvp object
      if (this.modelIsJsonString) {
        this.obj[event.data.Input.Key] = event.data.Input.Value;
        this.value = JSON.stringify(this.obj);
      } else {
        if (!this.value) {
          this.value = {};
        }
        this.value[event.data.Input.Key] = event.data.Input.Value;
      }
      this.fireChange(null, null, true);
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }


  getActionButton(): ButtonItem {

    const label = this.itemLabel || "Item";
    const action = new ButtonItem("", "bars", "light", null);

    action.size = "xs";
    action.menuPlacement = ["bottom-right", "top-right"];
    action.options = [];
    if (this.canEdit) {
      action.options.push(new Action("edit", `Edit ${label}`, "pencil", "", ($event: EventModel) => {
        // console.error("edit", $event);
        // $event.data is the string key of the kvp
        if (this.modelIsJsonString) {
          this.itemModal($event.data, this.obj[$event.data], $event.cargo.itemIndex, "edit");
        } else {
          this.itemModal($event.data, this.value[$event.data], $event.cargo.itemIndex, "edit");
        }
      }));
    }
    if (this.canCopy) {
      action.options.push(new Action("copy", `Copy ${label}`, "copy", "", ($event: EventModel) => {
        // console.error("copy", $event);
        // $event.data is the string key of the kvp
        if (this.modelIsJsonString) {
          let newKey = `${$event.data}Copy`;
          let count = 2;
          while (Helper.objectHasProperties(this.obj, newKey)) {
            newKey = `${$event.data}Copy${count}`;
            count++;
          }
          this.obj[newKey] = this.obj[$event.data];
          this.value = JSON.stringify(this.obj);
        } else {
          let newKey = `${$event.data}Copy`;
          let count = 2;
          while (Helper.objectHasProperties(this.value, newKey)) {
            newKey = `${$event.data}Copy${count}`;
            count++;
          }
          this.value[newKey] = this.value[$event.data];
        }
        this.fireChange($event.event, null, true);
      }));
    }
    if (this.canDelete) {
      action.options.push(new Action("delete", `Delete ${label}`, "times", "", ($event: EventModel) => {
        // console.error("delete", $event);
        // $event.data is the string key of the kvp
        let message = `Delete this ${label}?`;
        if ($event.data) {
          message = `Delete ${label} ${Helper.left($event.data, 20, true)}?`;
        }
        const promise = this.uxService.modal.confirmDelete(message, null);
        promise.then((answer) => {
          if (this.modelIsJsonString) {
            delete this.obj[$event.data];
            this.value = JSON.stringify(this.obj);
          } else {
            delete this.value[$event.data];
          }
          this.fireChange($event.event, null, true);
        }, (cancelled) => {
          // No action
        });
      }));
    }

    return action;

  }


  trackByFn(index, item) {
    return index; // or item.id
  }

}
