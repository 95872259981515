import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'telephoneLink'
})
export class TelephoneLinkPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  transform(value: any, args?: any): any {

    if (!value) {
      return this.sanitizer.bypassSecurityTrustHtml("");
    }

    const html: string = `<a href="tel:${value}">${value}</a>`;

    return this.sanitizer.bypassSecurityTrustHtml(html);

  }

}
