import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { AssetService } from '../../../../../core-lib/src/lib/services/asset.service';
import { BaseComponent } from '../../../../../core-lib/src/lib/helpers/base-component';
import { takeUntil } from 'rxjs/operators';
import { EventModel, EventElementModel } from '../../ux-models';
import { Helper } from '../../../../../core-lib/src/lib/helpers/helper';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ib-input-asset-license-multiselect',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-asset-license-multiselect.component.html',
  styleUrls: ['./input-asset-license-multiselect.component.css']
})
export class InputAssetLicenseMultiselectComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() name: string = "Licenses";
  @Input() label: string = "Licenses";
  @Input() value: string[] = [];

  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;

  @Output() change: EventEmitter<EventModel> = new EventEmitter();

  licenses: m5.AssetLicenseViewModel[] = [];
  selectedLicenses: m5.AssetLicenseViewModel[] = [];

  constructor(protected assetService: AssetService) {
    super();
  }

  ngOnInit() {
    this.assetService.getAssetLicenses().pipe(takeUntil(this.ngUnsubscribe)).subscribe((assetLicenses) => {
      this.licenses = assetLicenses;
      this.updateSelectedLicenses();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && this.value) {
      this.updateSelectedLicenses();
    }
  }

  updateSelectedLicenses() {
    if (!this.licenses || this.licenses.length === 0) {
      this.selectedLicenses = [];
      return;
    }
    if (!this.value || this.value.length === 0) {
      this.selectedLicenses = [];
      return;
    }
    this.selectedLicenses = this.licenses.filter(x => this.value.includes(x.ExternalAssetLicenseId));
  }

  onChange($event) {
    // console.error("AllowedLicenses", $event, this.selectedLicenses);
    this.value = (<m5.AssetLicenseViewModel[]>$event.value).map(x => x.ExternalAssetLicenseId);
    const cargo: any = { selectedLicenses: this.selectedLicenses };
    const payload: EventModel = new EventModel("change", $event, this.value, new EventElementModel("input-asset-license-multiselect", null, this.label, this.label), cargo);
    this.change.emit(payload);
  }

}
