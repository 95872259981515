import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AlertItemType } from 'projects/common-lib/src/lib/alert/alert-manager';
import { EventModel } from 'projects/common-lib/src/lib/ux-models';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiCall, ApiOperationType, ApiProperties } from 'projects/core-lib/src/lib/api/ApiModels';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { AttachmentService } from 'projects/core-lib/src/lib/services/attachment.service';
import { createDefaultImageScrambler, getEditorDefaults } from 'lib/pintura/pintura';

@Component({
  selector: 'ib-attachment-edit-modal',
  templateUrl: './attachment-edit-modal.component.html',
  styleUrls: ['./attachment-edit-modal.component.css']
})
export class AttachmentEditModalComponent extends BaseComponent implements OnInit {
  @Input() attachmentToEdit: m5.AttachmentMetaDataViewModel = null;
  /** If true, there is no loading bar for the api call. */
  @Input() silentLoad: boolean = true;
  @Input() utils: Utils[];
  /** For the "redact" util: The amount to scramble the image pixels before blurring. */
  @Input() scrambleAmount: number = 10;
  /** For the 'redact' util: The amount of blur to apply to the scrambled image. */
  @Input() blurAmount: number = 10;

  /** The user hit 'Done' and their changes were successfully saved. */
  @Output() editSuccess: EventEmitter<EventModel> = new EventEmitter();
  /**
   * The user clicked the top left 'X' or hit 'Esc' on their keyboard.
   * The parent should null the attachmentToEdit onClose.
   * There is some kind of issue where after editing an image, you can't edit another one unless we follow
   * that process. My best guess is it's change detection oriented, but
   * still confusing how even when trying to edit a different image it wouldn't open.
   */
  @Output() close: EventEmitter<EventModel> = new EventEmitter();

  /** Passed to the edit modal so it has a src to edit. */
  attachmentViewUrl: string = "";

  /*
   * After editing the image we need to refresh the img elements but they won't refresh without us cache busting
   * the src so append a counter to the url.  Yes this will cache bust all pictures not just the one edited.
   */
  editImageCounter: number = 0;
  editImageOptions: any = {};

  // Note: the only thing we use these for is to get the apiCall.Token to append onto the end of the image url
  apiProperties: ApiProperties;
  apiCall: ApiCall;

  /** Contains the blur amount and scramble amount. */
  imageScrambler: any = {};

  constructor(
    protected appService: AppService,
    protected attachmentService: AttachmentService,
  ) {
    super();
    if (this.appService.isBrandReportCompiler) {
      this.utils = this.getUtilsNoDecorateOrFrame();
    } else {
      this.utils = this.getUtilsNoDecorate();
    }
  }

  ngOnInit(): void {
    this.apiProperties = Api.Attachment();
    this.apiCall = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
    this.editImageOptions = this.getEditorOptions();
    this.setEditImageOptionsUtils();
    this.imageScrambler = createDefaultImageScrambler({
      scrambleAmount: this.scrambleAmount,
      blurAmount: this.blurAmount
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attachmentToEdit && this.attachmentToEdit) {
      this.getAttachmentViewUrl();
    }

    if (changes.scrambleAmount || changes.blurAmount) {
      this.imageScrambler = createDefaultImageScrambler({
        scrambleAmount: this.scrambleAmount,
        blurAmount: this.blurAmount
      });
    }

    if (changes.utils) {
      this.setEditImageOptionsUtils();
    }

    // console.error("ngOnChanges editImageOptions: ", this.editImageOptions);
  }

  // ngOnDestroy(): void {
  //   //console.error("attachmentEditModal ngOnDestroy");
  // }

  // we could create additional inputs as needed and use them to adjust options if desired
  getEditorOptions(): any {

    const options = getEditorDefaults();
    // console.error("EditorOptions: ", options);
    return options;
  }

  /**
   * If the utils array is empty, the editor will just be a white screen without even a 'close' or 'done' button. We
   * need to make sure there is at least one value.
   */
  setEditImageOptionsUtils() {
    if ((this.utils && this.utils.length === 0) || !this.utils) {
      console.error("The utils array was empty which would cause the image editor to be a blank screen. Setting some default values.");
      this.utils = this.getUtilsNoDecorateOrFrame();
    }

    if (this.editImageOptions.utils) {
      this.editImageOptions.utils = this.utils;
    }
  }

  /** Fired when the image has finished processing. IE the user hit 'Done' */
  onProcess = ($event) => {
    // console.error("onProcess: ", $event);
    this.saveUpdatedImage(this.attachmentToEdit, $event.dest);
  };

  /**
   * User clicks 'Cancel' or presses 'Esc'.
   */
  onClose = () => {
    // console.error("onClose");
    this.clearModalState();
    const payload: EventModel = new EventModel("close", null, null, null, null);
    this.close.emit(payload);
  };

  saveUpdatedImage(attachment: m5.AttachmentMetaDataViewModel, file: Blob) {
    const urls = this.attachmentService.getUrls(attachment);
    const url = urls.uploadUrlReplace;
    // let url = this.attachmentService.getUploadUrlReplace(attachment);
    // url = ApiHelper.addQueryStringToUrl(url, `token=${this.apiCall.token}`);

    const form: FormData = new FormData();
    form.append("Data", JSON.stringify(attachment.AttachmentId));
    form.append("file", file, `${attachment.FriendlyName}.${attachment.FileType}`);

    ApiHelper.xhrPostForm(url, form).then(result => {
      this.appService.alertManager.addAlertMessage(AlertItemType.Success, "The image has been updated.", 2);
      // console.error("xhr post", result);
      // We need to refresh images to get the changes so call this method to rebuild our list of images
      // this.editImageCounter++;
      // this.load(true);
      // I couldn't access Data on the result object because it's type 'unknown' and I'm afraid to type it,
      // so I just return the 'attachment' that was edited so whoever is listening can know a new version of it
      // has been saved
      const payload: EventModel = new EventModel("editSuccess", null, attachment, null, null);
      this.editSuccess.emit(payload);
      this.clearModalState();
    }).catch(error => {
      this.appService.alertManager.addAlertMessage(AlertItemType.Danger, "There was an error saving the updated image.");
      console.error(error);
      this.clearModalState();
    });

  }

  getAttachmentViewUrl(): string {
    if (!this.attachmentToEdit) {
      return "";
    }
    const urls = this.attachmentService.getUrls(this.attachmentToEdit, this.editImageCounter);
    this.attachmentViewUrl = urls.viewUrl;
  }

  protected getUtilsNoDecorateOrFrame(): Utils[] {
    const utils: Utils[] = ["crop", "filter", "finetune", "annotate", "redact", "resize"];
    return utils;
  }

  protected getUtilsNoDecorate(): Utils[] {
    const utils: Utils[] = ["crop", "filter", "finetune", "annotate", "frame", "redact", "resize"];
    return utils;
  }


  /**
   * After updating versions, we are changing how we ensure the modal opens a second time. The way it used to work is
   * hitting close, pressing 'Esc' or hitting 'Done' would all fire the 'close' event, at which point we would
   * null some values and on top of that, pass an event to the parent and it would null a value. Otherwise you couldn't
   * edit another image because the modal wouldn't open. My best guess is it's change detection oriented, but
   * still confusing how even when trying to edit a different image it wouldn't open.
   *
   * After updating versions, this behavior continues but we are handling it slightly different.
   * On the new version, it no longer calls 'close' when 'Done' is clicked so we just make sure to call this
   * when we know the modal is closing, which is after 'update' or the 'close' event.
   */
  protected clearModalState() {
    this.attachmentToEdit = null;
    this.attachmentViewUrl = "";
  }

  #region; // Unused events
  /** Fired when image loading starts. */
  onLoadStart = ($event) => {
    // console.error("onLoadStart ", $event);
  };
  /** Fired when image loading is aborted. */
  onLoadAbort = ($event) => {
    // console.error("onLoadAbort ", $event);
  };
  /** Fired when an error is thrown during image load. */
  onLoadError = ($event) => {
    // console.error("onLoadError ", $event);
  };
  /** Fired when loading of the image progresses. */
  onLoadProgress = (task: any) => {
    // console.error("onLoadProgress: ", task);
  };
  /** Fired when an image has successfully loaded */
  onLoad = (imageReaderResult: any) => {
    // console.error("onLoad: ", imageReaderResult);
  };
  /** Fired when an image preview has been loaded. */
  onLoadPreview = ($event) => {
    // console.error("onLoadPreview ", $event);
  };
  /** User clicks 'Done'. Fired when the internal imageState is updated. */
  onUpdate = ($event) => {
    // console.error("onUpdate ", $event);
  };
  /** Fired when an image starts processing. */
  onProcessStart = ($event) => {
    // console.error("onProcessStart: ", $event);
  };
  /** Fired when image processing is aborted. */
  onProcessAbort = ($event) => {
    // console.error("onProcessAbort ", $event);
  };
  /** Fired when the image processing process throws an error. */
  onProcessError = ($event) => {
    // console.error("onProcessError ", $event);
  };
  /** Fired when the image processing makes progress. */
  onProcessProgress = ($event) => {
    // console.error("onProcessProgress ", $event);
  };
  #endregion;
}

/**
 * The different utils offered in the editor modal.
 */
export type Utils =
  /** Enables to manipulate the crop selection and image orientation. */
  "crop" |
  /** Enables applying color matrix filters to the preview image. */
  "filter" |
  /** Enables us to add controls to update color effect values. */
  "finetune" |
  /** Draw and add shapes in the image context. Shapes rotate with the image. */
  "annotate" |
  /** Draw and add shapes in the crop context. Shapes don't rotate with image. */
  "decorate" |
  /** Enables us to add controls to update the frame drawn on top of the image. */
  "frame" |
  /** Enable easy and secure censoring of information. (Drag box with blur effect.) */
  "redact" |
  /** Enable width and height input controls to enable the user to determine an image output size. */
  "resize";

// The following are utils that are offered by Pintura, but we would need to add additional code
// to make them work.
/** Enables trimming and clipping of videos. */
// "trim"
/** Interact with shapes rendered on top of the loaded image. */
// "retouch"
/** Drag and drop stickers onto the editor. */
// "sticker"
/** fill the background of transparent images with colors or images. */
// "fill"

