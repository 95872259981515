import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { AppConfigHelpLinkOther, IAppConfig } from 'projects/core-lib/src/lib/config/AppConfig';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { BaseService } from 'projects/core-lib/src/lib/services/base.service';
import { ModalService } from '../modal/modal.service';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ModalCommonOptions } from '../modal/modal-common-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { takeUntil } from 'rxjs/operators';
import { UxService } from './ux.service';
import Tourguide from "tourguidejs";


@Injectable({
  providedIn: 'root'
})
export class HelpService extends BaseService implements OnInit, OnDestroy {

  userHelpDisableAutoOpen: any = {};

  constructor(
    protected modal: ModalService,
    protected apiService: ApiService,
    protected appService: AppService,
    protected uxService: UxService) {

    super();

    // Services don't call ngOnInit automatically like components do.
    this.ngOnInit();

  }

  ngOnInit() {
    super.ngOnInit();
    this.appService.preferenceObjectGet(Constants.ContactPreference.HelpDisableAutoOpen).pipe(takeUntil(this.ngUnsubscribe)).subscribe((preference: any) => {
      if (preference) {
        this.userHelpDisableAutoOpen = preference;
      }
    });
  }

  // ngOnDestroy() {
  //  super.ngOnDestroy();
  // }


  public showHelpIndex(helpLink: m5web.HelpLinkEditViewModel): boolean {
    if (!this.appService.appInfoOrDefault?.Branding?.SupportUrlIndex) {
      return false;
    }
    if (helpLink && !helpLink.IncludeHelpIndex) {
      return false;
    }
    return true;
  }

  public getHelpIndexLink(): string {
    return this.appService.helpLinkAppendSupportToken(this.appService.appInfoOrDefault?.Branding?.SupportUrlIndex);
  }

  public showHelpOtherLinks(helpLink: m5web.HelpLinkEditViewModel): boolean {
    try {
      if (!AppConfig.supportUrlOther || AppConfig.supportUrlOther.length === 0) {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
    if (helpLink && !helpLink.IncludeSystemHelpLinks) {
      return false;
    }
    return true;
  }

  public showHelpContactSupport(helpLink: m5web.HelpLinkEditViewModel): boolean {
    if (!this.appService.appInfoOrDefault?.Branding?.SupportEmailAddress) {
      return false;
    }
    if (helpLink && !helpLink.IncludeContactSupport) {
      return false;
    }
    return true;
  }

  public getHelpContactSupportLink(): string {
    if (!this.appService.appInfoOrDefault?.Branding?.SupportEmailAddress) {
      return "";
    }
    return `mailto:${this.appService.appInfoOrDefault?.Branding?.SupportEmailAddress}`;
  }


  /**
   * Inspects AppConfig for other help links to see if they are valid
   * based on our version.
   * @returns An array of HelpLinkOther objects.
   */
  buildOtherHelpLinks(): AppConfigHelpLinkOther[] {

    const links: AppConfigHelpLinkOther[] = [];

    // See if we have other links and if they're valid based on our version
    try {
      if (!AppConfig.supportUrlOther || AppConfig.supportUrlOther.length === 0) {
        return links;
      }
    } catch (err) {
      console.log(err);
      return links;
    }

    AppConfig.supportUrlOther.forEach(link => {
      if (!link.startingVersion && !link.endingVersion) {
        // No version restrictions for this link
        links.push(link);
      } else {
        // Check to see if our version is valid for this link
        if (this.appService?.status?.currentState?.versionRunning) {
          if ((!link.startingVersion || link.startingVersion <= this.appService.status.currentState.versionRunning) &&
            (!link.endingVersion || link.endingVersion >= this.appService.status.currentState.versionRunning)) {
            // Our version is valid for this link
            links.push(link);
          }
        } else {
          // We don't know what our version is.  Show the link or not?
          links.push(link);
        }
      }
    });

    return links;

  }


  showMessage(helpTopic: m5web.HelpLinkEditViewModel, link: m5web.HelpLinkItemEditViewModel, supportDoNotShowAgain: boolean) {
    // console.error('message click', link, $event);
    const options = new ModalCommonOptions();
    if (Helper.equals(link.Message.WindowSize, "small", true)) {
      options.size = "small";
    } else if (Helper.equals(link.Message.WindowSize, "large", true)) {
      options.size = "large";
    } else if (Helper.equals(link.Message.WindowSize, "larger", true)) {
      options.size = "larger";
    } else if (Helper.equals(link.Message.WindowSize, "largest", true)) {
      options.size = "largest";
    }
    options.title = this.appService.labelMacroSubstitution(link.Message.Title);
    options.titleIcon = link.Message.TitleIcon;
    if (link.Message.TitleColor) {
      options.titleIconContextColor = link.Message.TitleColor;
      options.titleContextColor = link.Message.TitleColor;
    }
    options.message = this.appService.labelMacroSubstitution(link.Message.Text);
    if ((link.AutoOpen || link.AutoOpenWhenUrlContains) && link.AutoOpenCanBeDisabled && supportDoNotShowAgain) {
      options.featureToggleOn = false;
      options.featureToggleSupport = true;
      options.featureToggleLabel = "Don't Show This Again";
      options.featureToggleTooltip = "Do not automatically open this message again.";
      options.featureToggleEvent = (isFeatureToggleOn: boolean) => {
        this.appService.preferenceValueSet(Constants.ContactPreference.HelpDisableAutoOpen, `${helpTopic.Context}${link.HelpLinkItemId}`, isFeatureToggleOn);
        this.userHelpDisableAutoOpen[`${helpTopic.Context}${link.HelpLinkItemId}`] = isFeatureToggleOn;
      };
      if (this.userHelpDisableAutoOpen[`${helpTopic.Context}${link.HelpLinkItemId}`]) {
        options.featureToggleOn = true;
      }
    }
    if (link.Message.Movable) {
      this.uxService.modal.showSimpleOverlay(options);
    } else {
      this.uxService.modal.showSimpleModal(options);
    }
  }




  showTour(helpTopic: m5web.HelpLinkEditViewModel, link: m5web.HelpLinkItemEditViewModel, autoOpen?: boolean) {
    const stepsArray = [];
    let useImages = false;

    // Create step objects using stored settings from HelpLinkItemTourStepEditViewModel
    link.Tour.Steps.forEach(item => {
      item.Text = this.appService.labelMacroSubstitution(item.Text);
      item.Title = this.appService.labelMacroSubstitution(item.Title);
      const singleStep: any = this.createTourStep(item);
      if (singleStep.useImages) {
        useImages = true;
      }
      stepsArray.push(singleStep);
    });
    stepsArray.sort((a, b) => a.step < b.step ? -1 : a.step > b.step ? 1 : 0);

    if (autoOpen && link.AutoOpenCanBeDisabled) {
      const finalStep: any = {};
      finalStep.title = "";
      finalStep.content = "<input id=\"hideAutoShow\" type = \"checkbox\"> Don't Show This Again";
      finalStep.selector = "none";
      finalStep.actiontarget = {};
      finalStep.step = stepsArray.length;
      stepsArray.push(finalStep);
    }
    let dontShowAgain = false;
    let autoshowbox: HTMLInputElement;
    const context = helpTopic.Context;


    const tourguide = new Tourguide({
      // Define actions that we might want to use on a tour step
      onStep(step, type) {
        if (step.actiontarget.onStepClickTarget) {
          step.target.firstChild.click();
        }
        autoshowbox = <HTMLInputElement>document.getElementById("hideAutoShow");
        if (autoshowbox) {
          autoshowbox.addEventListener('change', function () {
            if (this.checked) {
              dontShowAgain = true;
            } else {
              dontShowAgain = false;
            }
          });
        }
      },
      onAction(step, e) {
        if (step.actiontarget.allowTargetClickEvent) {
          step.target.firstChild.click();
        }
        if (step.actiontarget.onTargetClickMoveToNextStep) {
          tourguide.next();
        }
      },
      onComplete() {
        if (dontShowAgain) {
          this.helpButton.doNotShowAgain(this.helpTopic, this.helpLink);
        }
      },
      root: document.body,
      preloadimages: useImages,
      steps: stepsArray,
      helpButton: this,
      helpTopic: helpTopic,
      helpLink: link
    });

    // Launch tour
    tourguide.start();
  }




  // Convert HelpLinkItemTourStepEditViewModel to Tourguide.js step format
  createTourStep(item: m5web.HelpLinkItemTourStepEditViewModel) {
    const singleStep: any = {};
    singleStep.useImages = false;

    // actiontarget defines which actions are active/allowed
    singleStep.actiontarget = {};
    singleStep.actiontarget.allowTargetClickEvent = item.AllowTargetClickEvent;
    singleStep.actiontarget.onTargetClickMoveToNextStep = item.OnTargetClickMoveToNextStep;
    singleStep.actiontarget.onStepClickTarget = item.OnStepClickTarget;

    singleStep.step = item.SequenceNumber;
    singleStep.title = item.Title;
    singleStep.content = item.Text;
    singleStep.selector = "";

    if (item.Selector) {
      singleStep.selector = item.Selector;
    }
    if (item.Image) {
      singleStep.useImages = true;
      singleStep.image = item.Image;
    }
    return singleStep;
  }


  doNotShowAgain(helpTopic: m5web.HelpLinkEditViewModel, link: any): void {
    this.appService.preferenceValueSet(Constants.ContactPreference.HelpDisableAutoOpen, `${helpTopic.Context}${link.HelpLinkItemId}`, true);
  };



}

