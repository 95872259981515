// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.dropzone {
  border-style: dashed;
  margin: 5px 0 5px 0;
}

  .dropzone .dz-message {
    font-size: 20px;
    min-height: 100px;
  }

.dropzone-tight {
  padding: 0;
}*/

/deep/ .dz-wrapper {
  border: 2px dashed black !important;
}

/deep/ .dz-message {
  /*border: 2px dashed rgb(0, 135, 247) !important;
  border-radius: 5px !important;*/
  color: black;
  border: none !important;
  margin: 2px !important;
  min-height: 100px !important;
  background-color: unset !important;
}

/*/deep/ .dz-image {
  width: 50px !important;
  height: 50px !important;
}*/
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/file/file-upload/file-upload.component.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;EAYE;;AAEF;EACE,mCAAmC;AACrC;;AAEA;EACE;iCAC+B;EAC/B,YAAY;EACZ,uBAAuB;EACvB,sBAAsB;EACtB,4BAA4B;EAC5B,kCAAkC;AACpC;;AAEA;;;EAGE","sourcesContent":["/*.dropzone {\r\n  border-style: dashed;\r\n  margin: 5px 0 5px 0;\r\n}\r\n\r\n  .dropzone .dz-message {\r\n    font-size: 20px;\r\n    min-height: 100px;\r\n  }\r\n\r\n.dropzone-tight {\r\n  padding: 0;\r\n}*/\r\n\r\n/deep/ .dz-wrapper {\r\n  border: 2px dashed black !important;\r\n}\r\n\r\n/deep/ .dz-message {\r\n  /*border: 2px dashed rgb(0, 135, 247) !important;\r\n  border-radius: 5px !important;*/\r\n  color: black;\r\n  border: none !important;\r\n  margin: 2px !important;\r\n  min-height: 100px !important;\r\n  background-color: unset !important;\r\n}\r\n\r\n/*/deep/ .dz-image {\r\n  width: 50px !important;\r\n  height: 50px !important;\r\n}*/\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
