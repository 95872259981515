// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fa-stack-top {
  top: .25em;
  line-height: inherit;
}

.fa-stack-bottom {}

.fa-stack-item {
  /*vertical-align: top;*/
  top: -0.45em;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-group {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-group-1x {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  top: -0.2em;
  width: 0.6em;
}

.fa-stack-text {
  font-weight: 900 !important;
  padding-left: 2px;
}`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/image/icon/icon.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,oBAAoB;AACtB;;AAEA,kBAAkB;;AAElB;EACE,uBAAuB;EACvB,YAAY;EACZ,OAAO;EACP,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB","sourcesContent":[".fa-stack-top {\r\n  top: .25em;\r\n  line-height: inherit;\r\n}\r\n\r\n.fa-stack-bottom {}\r\n\r\n.fa-stack-item {\r\n  /*vertical-align: top;*/\r\n  top: -0.45em;\r\n  left: 0;\r\n  position: absolute;\r\n  text-align: center;\r\n  width: 100%;\r\n}\r\n\r\n.fa-stack-group {\r\n  display: inline-block;\r\n  height: 2em;\r\n  line-height: 2em;\r\n  position: relative;\r\n  vertical-align: middle;\r\n  width: 2.5em;\r\n}\r\n\r\n.fa-stack-group-1x {\r\n  display: inline-block;\r\n  position: relative;\r\n  vertical-align: middle;\r\n  top: -0.2em;\r\n  width: 0.6em;\r\n}\r\n\r\n.fa-stack-text {\r\n  font-weight: 900 !important;\r\n  padding-left: 2px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
