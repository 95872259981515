// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.design-mode {
  border: 1px dashed red;
  /*margin: 1px; this jacks up our columns in a row*/
  padding: 15px 5px 5px 5px;
  min-height: 20px;
  position: relative;
}

.design-mode-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 0.5rem;
  font-size: 0.75rem;
  color: red;
  cursor: pointer;
}

.design-mode-icon-nav-group {
  position: absolute;
  top: -15px;
  right: -5px;
  line-height: 0.5rem;
  font-size: 0.75rem;
  color: red;
  cursor: pointer;
}

.design-mode-icon-relative {
  /*position: absolute;*/
  top: 0px;
  right: 0px;
  line-height: 0.5rem;
  font-size: 0.75rem;
  color: red;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/form-render/form-group-render/form-group-render.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kDAAkD;EAClD,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,QAAQ;EACR,UAAU;EACV,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB","sourcesContent":[".design-mode {\r\n  border: 1px dashed red;\r\n  /*margin: 1px; this jacks up our columns in a row*/\r\n  padding: 15px 5px 5px 5px;\r\n  min-height: 20px;\r\n  position: relative;\r\n}\r\n\r\n.design-mode-icon {\r\n  position: absolute;\r\n  top: 0px;\r\n  right: 0px;\r\n  line-height: 0.5rem;\r\n  font-size: 0.75rem;\r\n  color: red;\r\n  cursor: pointer;\r\n}\r\n\r\n.design-mode-icon-nav-group {\r\n  position: absolute;\r\n  top: -15px;\r\n  right: -5px;\r\n  line-height: 0.5rem;\r\n  font-size: 0.75rem;\r\n  color: red;\r\n  cursor: pointer;\r\n}\r\n\r\n.design-mode-icon-relative {\r\n  /*position: absolute;*/\r\n  top: 0px;\r\n  right: 0px;\r\n  line-height: 0.5rem;\r\n  font-size: 0.75rem;\r\n  color: red;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
