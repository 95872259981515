import { NgForm, AbstractControl } from "@angular/forms";
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import { Dictionary } from "projects/core-lib/src/lib/models/dictionary";

export class FormStatusModel {

  public get isPristine() {
    return this._isPristine;
  }

  public get isValid() {
    return this._isValid;
  }

  public get propertiesByErrorType() {
    return this._propertiesByErrorType;
  }

  public get customErrorMessages() {
    return this._customErrorMessages;
  }

  public get errors() {
    return this._errors;
  }

  /**
  When true the form status is pristine.  The opposite of this is dirty.
  */
  private _isPristine: boolean = true;

  /**
  When true the form is valid.  The opposite of this is invalid.
  */
  private _isValid: boolean = true;

  /**
  Dictionary of property names that have certain errors.  The dictionary key
  is the error type and value is a string array of properties with errors
  of that type.
  */
  private _propertiesByErrorType: Dictionary<string[]> = new Dictionary<string[]>();

  /**
  List of custom error messages.  Any errors that need to be manually reported
  are inserted into this array.
  */
  private _customErrorMessages: string[] = [];

  /**
  List of error messages for reporting.  This list gets cleared and
  recreated internally and should not be modified manually.  Any custom
  errors that need to be submitted via the addCustomErrorMessage() method
  and other errors should be reported by type and property name via the
  addError() method which will insert them into our propertiesByErrorType
  dictionary.
  */
  private _errors: string[] = [];

  constructor(pristine: boolean = true, valid: boolean = true, propertiesByErrorType: Dictionary<string[]> = null, customErrorMessages: string[] = [], errors: string[] = []) {
    this._isPristine = pristine;
    this._isValid = valid;
    this._propertiesByErrorType = propertiesByErrorType || new Dictionary<string[]>();
    this._customErrorMessages = customErrorMessages || [];
    this._errors = errors || [];
  }


  /// **
  // * Method for adding errors from a control.  This could be from input control,
  // * custom form control status change event, etc.  Anything that provides
  // * controlErrors as keyed error object.
  // * @param propertyName
  // * @param controlErrors
  // */
  // public addControlErrors(propertyName: string, controlErrors: { [key: string]: any }) {
  //  if (controlErrors) {
  //    if (controlErrors.required) {
  //      this.addError("required", propertyName);
  //    } else if (controlErrors.minlength) {
  //      this.addError("min", propertyName);
  //    } else if (controlErrors.maxlength) {
  //      this.addError("max", propertyName);
  //    } else if (controlErrors.pattern) {
  //      this.addError("format", propertyName);
  //    } else if (controlErrors.ngbDate) {
  //      this.addError("format", propertyName);
  //    } else {
  //      this.addError(JSON.stringify(controlErrors), propertyName);
  //      Log.errorMessage(`Form error could use some clean up.  The error type was reported as "${JSON.stringify(controlErrors)}".`);
  //    }
  //  }
  // }


  /// **
  // * Add an error to our dictionary of property names keyed by error type.
  // * This method prevents duplicate error messages from being reported
  // * and updates the array of reported error messages.
  // * @param type
  // * @param propertyName
  // */
  // public addError(type: string, propertyName: string) {

  //  if (!this.propertiesByErrorType) {
  //    this.propertiesByErrorType = new Dictionary<string[]>();
  //  }

  //  // Convert from pascal case to separate words for friendlier error reporting
  //  let formattedPropertyName = Helper.formatIdentifierWithSpaces(propertyName);
  //  if (this.propertiesByErrorType.containsKey(type)) {
  //    let values: string[] = this.propertiesByErrorType.item(type);
  //    if (!values.includes(formattedPropertyName)) {
  //      this.propertiesByErrorType.item(type).push(formattedPropertyName);
  //    }
  //  } else {
  //    this.propertiesByErrorType.add(type, [formattedPropertyName]);
  //  }

  //  // Update error message output
  //  this.prepareErrorReport();

  //  // We want our valid flag to be based on existence of reported errors
  //  // as we cannot say we're not valid without a reason we're not valid.
  //  this.isValid = (this.errors.length === 0);

  // }

  /// **
  // * Add a custom error message to our list of custom error messages.
  // * This method prevents duplicate error messages from being included
  // * and updates the array of reported error messages.
  // * @param errorMessage
  // */
  // public addCustomErrorMessage(errorMessage: string) {

  //  if (!this.customErrorMessages) {
  //    this.customErrorMessages = [];
  //  }

  //  if (!this.customErrorMessages.includes(errorMessage)) {
  //    this.customErrorMessages.push(errorMessage);
  //    // Update error message output
  //    this.prepareErrorReport();
  //  }

  //  // We want our valid flag to be based on existence of reported errors
  //  // as we cannot say we're not valid without a reason we're not valid.
  //  this.isValid = (this.errors.length === 0);

  // }

  /// **
  // * Merge another form status model into ours.  Often used when
  // * there are child form status objects we want to folded into
  // * our status object.
  // * @param status
  // */
  // public merge(status: FormStatusModel) {

  //  if (!status.isPristine) {
  //    this.isPristine = false;
  //  }

  //  if (status.propertiesByErrorType) {
  //    let keys: string[] = status.propertiesByErrorType.keys();
  //    keys.forEach(key => {
  //      let properties: string[] = status.propertiesByErrorType.item(key);
  //      properties.forEach(property => {
  //        this.addError(key, property);
  //      });
  //    });
  //  }

  //  if (status.customErrorMessages) {
  //    status.customErrorMessages.forEach(message => {
  //      this.addCustomErrorMessage(message);
  //    });
  //  }

  //  // Format error information
  //  this.prepareErrorReport();

  //  // We want our valid flag to be based on existence of reported errors
  //  // as we cannot say we're not valid without a reason we're not valid.
  //  this.isValid = (this.errors.length === 0);

  // }


  /// **
  // * Takes all errors from propertiesByErrorType dictionary
  // * and formats them for output display as string[] and merges
  // * in any custom error messages.  The resulting string[] is
  // * returned here and saved in the errors property.
  // */
  // public prepareErrorReport(): string[] {

  //  this.errors = [];

  //  if (this.propertiesByErrorType) {
  //    let keys: string[] = this.propertiesByErrorType.keys();
  //    keys.forEach(key => {
  //      // Convert from key to description.  Known keys are: "required", "min", "max", "format"
  //      let description = key;
  //      if (Helper.equals(description, "min")) {
  //        description = "too short";
  //      } else if (Helper.equals(description, "max")) {
  //        description = "too long";
  //      } else if (Helper.equals(description, "format")) {
  //        description = "invalid format";
  //      }
  //      // Get array of properties that have this error type
  //      let properties: string[] = this.propertiesByErrorType.item(key);
  //      if (properties.length === 1) {
  //        this.errors.push(`${properties[0]} is ${description}.`);
  //      } else if (properties.length > 1) {
  //        this.errors.push(`These items are ${description}: ${properties.join(", ")}.`);
  //      }
  //    });
  //  }

  //  if (this.customErrorMessages && this.customErrorMessages.length > 0) {
  //    this.errors.push(...this.customErrorMessages);
  //  }

  //  return this.errors;

  // }


  /// **
  // * Builds a FormStatusModel based on the supplied NgForm and optional dictionary of child form status objects.
  // * @param form - NgForm
  // * @param childFormStatus - Dictionary<FormStatusModel>
  // */
  // public static create(form: NgForm, status: FormStatusModel, childFormStatus: Dictionary<FormStatusModel>): FormStatusModel {

  //  let model = new FormStatusModel();

  //  if (!form && !status && (!childFormStatus || childFormStatus.count() === 0)) {
  //    return model;
  //  }

  //  if (form) {
  //    // See if our form is pristine
  //    model.isPristine = form.pristine; // form.form.pristine; // form.pristine;
  //    // Step through each control and check what errors need to be reported
  //    for (let propertyName in form.controls) {
  //      let control: AbstractControl = form.controls[propertyName];
  //      model.addControlErrors(propertyName, control.errors);
  //    }
  //  } else if (status) {
  //    model.merge(status);
  //  }

  //  // Capture any status and errors we can from child forms status objects
  //  if (childFormStatus && childFormStatus.count() > 0) {
  //    childFormStatus.values().forEach((child: FormStatusModel) => {
  //      model.merge(child);
  //    });
  //  }

  //  return model;

  // }


  /**
   * Determine if two FormStatusModel objects are equivalent.  This can be used to judge
   * if we should emit an event about a form status change or not.
   * @param status1
   * @param status2
   */
  public static equals(status1: FormStatusModel, status2: FormStatusModel): boolean {

    if (!status1 && !status2) {
      return true;
    } else if (status1 && !status2) {
      return false;
    } else if (!status1 && status2) {
      return false;
    }

    if (status1 && status2) {
      if (status1.isPristine !== status2.isPristine) {
        return false;
      } else if (status1.isValid !== status2.isValid) {
        return false;
      }
    }

    if (status1.errors && status2.errors && status1.errors.length !== status2.errors.length) {
      return false;
    }

    // Having done the checks that are faster than JSON string compare to avoid this expense when possible this is the last step
    // since the other checks can return false but this is the step that can definitively return true.
    return Helper.equals(JSON.stringify(status1), JSON.stringify(status2), true);

  }



}


/**
 * A model used for passing error messages or success messages to dynamic forms.
 * Typically saved as a property on the form data object so it's accessible in the
 * form for rendering.
 */
export class FormMessageModel {

  /**
  The type of message.  This can be used by the form to handle presentation of different
  message types using different ui elements.
  */
  public type: string = "";

  /**
  The message being passed.
  */
  public message: string = "";

  /**
  The language the message is presented in.
  */
  public language: string = "";

  /**
  For error message this indicates if the error is recoverable or not.
  */
  public canRecover: boolean = true;

  constructor(type: string = "", message: string = "", language: string = "", canRecover: boolean = true) {
    this.type = type;
    this.message = message;
    this.language = language;
    this.canRecover = canRecover;
  }

}
