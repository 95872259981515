import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgProgressModule } from 'ngx-progressbar';
import { CommonLibModule } from '../../common-lib.module';
import { AppRoutingModule } from 'projects/ib-app/src/app/app.routing.module';
import { ImageDockComponent } from '../../ux/nav/image-dock/image-dock.component';
import { NavService } from '../../ux/nav/nav.service';
import { DockPosition } from 'projects/core-lib/src/lib/config/AppConfig';
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import { MenuService } from 'projects/core-lib/src/lib/services/menu.service';
import { MenuItem } from 'primeng/api/menuitem';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { CoreLibModule } from 'projects/core-lib/src/lib/core-lib.module';
import { UserOptionsComponent } from '../../ux/user-options/user-options.component';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { ConfigHelpers } from 'projects/core-lib/src/lib/helpers/config-helpers';

@Component({
  standalone: true,
  imports: [NgProgressModule, CommonLibModule, AppRoutingModule, CommonModule, ImageDockComponent, CoreLibModule, UserOptionsComponent],
  selector: 'ib-layout-image-dock',
  templateUrl: './layout-image-dock.component.html',
  styleUrls: ['./layout-image-dock.component.css']
})
export class LayoutImageDockComponent extends BaseComponent implements OnInit {
  /**
   * The config can define a 'style' for the background image.
   * This is an object that we place on ngStyle so the correct css gets applied to the background image
   * to achieve this 'style'.
   */
  public backgroundImageNgStyle: { [key: string]: string } = {};

  /**
   * Typically this is the same as nav.themeClasses but if this is a standalone
   * component it may be a theme to match standalone component styling.
   */
  themeClasses: string = "";

  /** The edge of the screen where the dock will be positioned. */
  position: DockPosition = 'top';

  /** List of menu items which populates the dock. */
  menuItems: MenuItem[] = [];

  helpTopic: m5web.HelpLinkEditViewModel = null;

  /**
   * If true, an extended footer is added on top of the standard footer so the image dock can be placed at the bottom
   * and look like it's in the footer.
   */
  hasExtendedFooter: boolean = false;

  /**
   * Removes the top border from the standard footer and puts it on the extended footer.
   * Used when the theme is light, the footer is extended, and the image dock is at the bottom.
   */
  adjustLightThemeExtendedFooter: boolean = false;

  /* If true, we ignore the menu from the api and use the menu from the config. */
  alwaysUseHardCodedMenu: boolean = false;

  get showHelpMenu(): boolean {
    return this.nav.showHelpMenu;
  }

  constructor(
    public nav: NavService,
    protected menuService: MenuService,
    protected appService: AppService
  ) {
    super();
    this.themeClasses = this.nav.themeClasses;
    if (this.nav.theme === "light" && this.position === 'bottom' && this.hasExtendedFooter) {
      this.adjustLightThemeExtendedFooter = false;
    } else {
      this.adjustLightThemeExtendedFooter = true;
    }
    this.getConfigSettings();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Listen for menu changes
    this.menuService.primeMenuFeed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(menu => { 
      if (menu) {
        this.menuItems = [...menu];
      }
    });
  }

  getConfigSettings() {
    this.backgroundImageNgStyle = ConfigHelpers.getBackgroundImageStyle();
    this.position = ConfigHelpers.getImageDockPositionSetting();
    this.hasExtendedFooter = ConfigHelpers.getImageDockExtendedFooterSetting();
    this.alwaysUseHardCodedMenu = this.menuService.alwaysUseConfigMenu();
  }
}
