// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
  display: grid;
  gap: 2px; /* Adjust gap between squares */
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/grid-size-selector/grid-size-selector.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ,EAAE,+BAA+B;AAC3C","sourcesContent":[".grid {\r\n  display: grid;\r\n  gap: 2px; /* Adjust gap between squares */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
