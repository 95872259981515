// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Adding some of our own styling for a div wrapper we put around p-dock because p-dock
   is not fixed...but once we add 'fixed' as a style, we also need to define it's position.
   Added fixed because the dock would scroll with the page and it looked bad. Especially when
   in bottom position because you couldn't even see it if the page extended down. */
.dock-container-left {
  position: fixed;
  top: 50%;
  transform: translateY(-50%) translateY(-38px); /* 38px is the height of the footer. */
  z-index: 1000;
}
.dock-container-right {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%) translateY(-38px); /* 38px is the height of the footer. */
  z-index: 1000;
}
.dock-container-top {
  position: fixed;
  left: 50%;
  z-index: 1000;
} 
.dock-container-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 1000;
  margin-bottom: 44px; /** 38px footer + 6px for spacing */
}`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/nav/image-dock/image-dock.component.css"],"names":[],"mappings":"AAAA;;;mFAGmF;AACnF;EACE,eAAe;EACf,QAAQ;EACR,6CAA6C,EAAE,sCAAsC;EACrF,aAAa;AACf;AACA;EACE,eAAe;EACf,QAAQ;EACR,QAAQ;EACR,6CAA6C,EAAE,sCAAsC;EACrF,aAAa;AACf;AACA;EACE,eAAe;EACf,SAAS;EACT,aAAa;AACf;AACA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,aAAa;EACb,mBAAmB,EAAE,mCAAmC;AAC1D","sourcesContent":["/* Adding some of our own styling for a div wrapper we put around p-dock because p-dock\r\n   is not fixed...but once we add 'fixed' as a style, we also need to define it's position.\r\n   Added fixed because the dock would scroll with the page and it looked bad. Especially when\r\n   in bottom position because you couldn't even see it if the page extended down. */\r\n.dock-container-left {\r\n  position: fixed;\r\n  top: 50%;\r\n  transform: translateY(-50%) translateY(-38px); /* 38px is the height of the footer. */\r\n  z-index: 1000;\r\n}\r\n.dock-container-right {\r\n  position: fixed;\r\n  top: 50%;\r\n  right: 0;\r\n  transform: translateY(-50%) translateY(-38px); /* 38px is the height of the footer. */\r\n  z-index: 1000;\r\n}\r\n.dock-container-top {\r\n  position: fixed;\r\n  left: 50%;\r\n  z-index: 1000;\r\n} \r\n.dock-container-bottom {\r\n  position: fixed;\r\n  bottom: 0;\r\n  left: 50%;\r\n  z-index: 1000;\r\n  margin-bottom: 44px; /** 38px footer + 6px for spacing */\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
