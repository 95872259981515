// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unit-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.unit-input-group {
  flex: 1;
  min-width: 7em;
}

.time-input {
  text-align: center;
  flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-duration/input-duration.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,WAAW;AACb;;AAEA;EACE,OAAO;EACP,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".unit-inputs-wrapper {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  width: 100%;\r\n}\r\n\r\n.unit-input-group {\r\n  flex: 1;\r\n  min-width: 7em;\r\n}\r\n\r\n.time-input {\r\n  text-align: center;\r\n  flex: 1 1 auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
