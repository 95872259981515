import * as Enumerable from "linq";
import { format } from "date-fns";
import * as Handlerbars from 'handlebars';

import * as Constants from "projects/core-lib/src/lib/helpers/constants";

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { Routes } from "@angular/router";
import { CanDeactivateGuard } from "projects/core-lib/src/lib/services/can-deactivate-guard.service";
import { Dictionary } from "projects/core-lib/src/lib/models/dictionary";
import { Log, Helper } from "projects/core-lib/src/lib/helpers/helper";


export class HandlebarsHelpers {

  /**
  Cache of compiled handlebars templates to save compile time when same template is repeatedly referenced.
  */
  public static handlebarsTemplateCache: Dictionary<Handlebars.TemplateDelegate> = new Dictionary<Handlebars.TemplateDelegate>();

  /**
   * Resolves a template with handlebars using the provided context data with optional caching of compiled template for faster reuse.
   * @param template - template string with {{handlebars}} markers.
   * @param contextData - context data to use for resolving the template with data.
   * @param templateCacheKey - an optional template cache key to use with caching of compiled templates.  Highly recommended for faster performance.
   * @param options - optional handlebars runtime options.
   */
  public static handlebarsTemplateResolve(template: string, contextData: any, templateCacheKey: string = "", options?: Handlebars.RuntimeOptions): string {

    // Register our helpers if we have not already done that in this app.
    if (!HandlebarsHelpers.handlebarsHelpersHaveBeenRegistered) {
      HandlebarsHelpers.handlebarsRegisterHelpers();
    }

    let compiled: Handlebars.TemplateDelegate = null;

    // First check to see if we've already compiled this template and have it in cache
    if (templateCacheKey && HandlebarsHelpers.handlebarsTemplateCache.containsKey(templateCacheKey)) {
      compiled = HandlebarsHelpers.handlebarsTemplateCache.item(templateCacheKey);
      // console.error(`template from cache key ${templateCacheKey}`, compiled);
    } else {
      try {
        compiled = Handlebars.compile(template);
        if (templateCacheKey) {
          HandlebarsHelpers.handlebarsTemplateCache.add(templateCacheKey, compiled);
        }
      } catch (err) {
        Log.errorMessage(`Error compiling handlebars template: ${template}`);
        Log.errorMessage(err);
      }
      // console.error(`template compiled`, compiled);
    }

    let result: string = "";
    try {
      result = compiled(contextData, options);
    } catch (err) {
      Log.errorMessage(`Error using compiled handlebars template: ${template}`);
      Log.errorMessage(err);
    }
    // console.error("result", result);

    return result;

  }


  /**
  Flag if handlebars helpers have been registered or not so we can lazy register.
  */
  public static handlebarsHelpersHaveBeenRegistered: boolean = false;

  /**
   * Registers handlebars helpers.
   */
  public static handlebarsRegisterHelpers(): void {

    // Custom helpers

    HandlebarsHelpers.handlebarsRegisterHelpersComparison();
    HandlebarsHelpers.handlebarsRegisterHelpersDate();
    HandlebarsHelpers.handlebarsRegisterHelpersNumber();
    HandlebarsHelpers.handlebarsRegisterHelpersMath();

    HandlebarsHelpers.handlebarsHelpersHaveBeenRegistered = true;

  }


  public static handlebarsRegisterHelpersComparison(): void {

    Handlebars.registerHelper("eq", function (a, b, options: Handlerbars.HelperOptions) {
      if (a === b) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    });

    Handlebars.registerHelper("notEq", function (a, b, options: Handlerbars.HelperOptions) {
      if (a !== b) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }
    });

    Handlebars.registerHelper("ifCond", function (...args: any[]) {

      // Last parameter is our options object so actual input parameters is argument array length-1.
      const len: number = args.length - 1;
      const options: Handlerbars.HelperOptions = args[len];

      if (len === 0) {
        Log.errorMessage("ifCond handlebars helper requires at least one comparison to perform.");
        return options.inverse(this);
      }

      const expression: string = HandlebarsHelpers.simplifyExpression(args.slice(0, len));

      // Debug
      // Log.errorMessage(`Ready to evaluate ${expression} which was simplified from ${args.slice(0, len).join(' ')}`);

      let result: boolean = false;
      try {
        // eslint-disable-next-line no-eval
        result = eval(expression);
      } catch (err) {
        Log.errorMessage(`Error in expression ${expression} which was simplified from ${args.slice(0, len).join(' ')}`);
        Log.errorMessage(err);
      }

      if (result) {
        return options.fn(this);
      } else {
        return options.inverse(this);
      }

    });

    // Sometimes we want true/false not in a if block like ifCond above gives us ... for example
    // in custom forms we may decide if we're going to show something or not based on eval result
    // of true or false.  If true we return true; if false we return "" so we can do a simple
    // test for truthy/falsy rather than string comparison.
    Handlebars.registerHelper("eval", function (...args: any[]) {

      // Last parameter is our options object so actual input parameters is argument array length-1.
      const len: number = args.length - 1;
      const options: Handlerbars.HelperOptions = args[len];

      if (len === 0) {
        Log.errorMessage("eval handlebars helper requires at least one comparison to perform.");
        return "";
      }

      const expression: string = HandlebarsHelpers.simplifyExpression(args.slice(0, len));

      // Debug
      // Log.errorMessage(`Ready to evaluate ${expression} which was simplified from ${args.slice(0, len).join(' ')} (${JSON.stringify(args.slice(0, len))})`);

      let result: boolean = false;
      try {
        // eslint-disable-next-line no-eval
        result = eval(expression);
      } catch (err) {
        Log.errorMessage(`Error in expression ${expression} which was simplified from ${args.slice(0, len).join(' ')} (${JSON.stringify(args.slice(0, len))})`);
        Log.errorMessage(err);
      }

      if (result) {
        return "true";
      } else {
        return "";
      }

    });

  }


  protected static compareTwoValues(left: any, comparisonOperator: string, right: any): boolean {

    if (!comparisonOperator) {
      Log.errorMessage("Comparison helper requires second parameter to be comparison operator.");
    }

    if (comparisonOperator === "=" || comparisonOperator === "==" || comparisonOperator === "====") {
      return (left === right);
    }

    if (comparisonOperator === "!=" || comparisonOperator === "!==") {
      return (left !== right);
    }

    if (comparisonOperator === "<") {
      return (left < right);
    }

    if (comparisonOperator === "<=") {
      return (left <= right);
    }

    if (comparisonOperator === ">") {
      return (left > right);
    }

    if (comparisonOperator === ">=") {
      return (left >= right);
    }

    if (Helper.equals(comparisonOperator, "StartsWith", true)) {
      return Helper.startsWith(left, right, true);
    }

    if (Helper.equals(comparisonOperator, "EndsWith", true)) {
      return Helper.endsWith(left, right, true);
    }

    if (Helper.equals(comparisonOperator, "Contains", true)) {
      return Helper.contains(left, right, true);
    }

    if (Helper.equals(comparisonOperator, "TypeOf", true)) {
      return (typeof left === right);
    }

    if (Helper.equals(comparisonOperator, "True", true)) {
      if (left) {
        return true;
      } else {
        return false;
      }
    }

    if (Helper.equals(comparisonOperator, "False", true)) {
      if (!left) {
        return true;
      } else {
        return false;
      }
    }

    Log.errorMessage(`Comparison helper comparison operator ${comparisonOperator} is not supported.  Supported values include ===, !==, <, <=, >, >=, StartsWith, EndsWith, Contains, TypeOf, True, False.`);

    return false;

  }


  protected static isComparisonOperator(comparisonOperator: string): boolean {

    const operators: string[] = ["=", "==", "====", "!=", "!==", "<", "<=", ">", ">=", "StartsWith", "EndsWith", "Contains", "TypeOf", "True", "False"];

    let found = false;
    operators.forEach(op => {
      if (Helper.equals(op, comparisonOperator, true)) {
        found = true;
      }
    });

    return found;

  }

  protected static simplifyExpression(args: any[]): string {

    // Since context values are evaluated and then passed in as arguments the array of arguments will look like this:
    // ( ( 0 == 2 || 0 == 0 ) && 1 )
    // which we simplify to
    // (  (  false  ||  true  )  &&  true  )
    // which presumably our caller will then evaluate to true/false... the passed in values look like we could just
    // join and evaluate as-is but we have custom comparison operators beyond things like == we support
    // StartsWith, EndsWith, Contains, TypeOf, etc. so this method helps simplify the expression so it's eval ready.

    const len: number = args.length;

    if (len === 0) {
      Log.errorMessage("simplifyExpression helper requires at least one comparison to perform.");
      return "";
    }

    let expression: string = "";

    try {

      // Now loop through and simplify each comparison to a true or false
      for (let i = 0; i < len; i++) {

        // See if the argument is a logical grouping argument and, if so, push into our simplified expression as-is
        let arg = "";
        if (args[i] !== undefined && args[i] !== null) {
          arg = args[i].toString();
        }
        if (arg === "(" || arg === ")" || arg === "&" || arg === "&&" || arg === "|" || arg === "||" || arg === "!") {
          expression += ` ${arg} `;
        } else {
          // Not a logical grouping argument so let's peek forward and look for comparison operator
          // if no comparison operator then let's consider a single truthy/falsy value.
          if (len >= (i + 2) && HandlebarsHelpers.isComparisonOperator(args[i + 1])) {
            const part = HandlebarsHelpers.compareTwoValues(args[i], args[i + 1], args[i + 2]);
            i += 2;
            if (part) {
              expression += " true ";
            } else {
              expression += " false ";
            }
          } else {
            // No comparison operator involved so let's test this argument for truthy/falsy
            if (args[i]) {
              expression += " true ";
            } else {
              expression += " false ";
            }
          }
        }

      }

    } catch (err) {
      Log.errorMessage(`Error in simplifyExpression for arguments ${args.join(' ')}`);
      Log.errorMessage(err);
      return "";
    }

    return expression;

  }



  public static handlebarsRegisterHelpersDate(): void {


    /**
    Accepts date-fns format string for formatting current date time
    https://date-fns.org/v2.29.1/docs/format

    Token	Output
    Era	G..GGG	AD, BC
    GGGG	Anno Domini, Before Christ
    GGGGG	A, B
    Calendar year	y	44, 1, 1900, 2017
    yo	44th, 1st, 0th, 17th
    yy	44, 01, 00, 17
    yyy	044, 001, 1900, 2017
    yyyy	0044, 0001, 1900, 2017
    yyyyy	...
    Local week-numbering year	Y	44, 1, 1900, 2017
    Yo	44th, 1st, 1900th, 2017th
    YY	44, 01, 00, 17
    YYY	044, 001, 1900, 2017
    YYYY	0044, 0001, 1900, 2017
    YYYYY	...	3,5
    ISO week-numbering year	R	-43, 0, 1, 1900, 2017
    RR	-43, 00, 01, 1900, 2017	5,7
    RRR	-043, 000, 001, 1900, 2017	5,7
    RRRR	-0043, 0000, 0001, 1900, 2017	5,7
    RRRRR	...	3,5,7
    Extended year	u	-43, 0, 1, 1900, 2017
    uu	-43, 01, 1900, 2017
    uuu	-043, 001, 1900, 2017
    uuuu	-0043, 0001, 1900, 2017
    uuuuu	...	3,5
    Quarter (formatting)	Q	1, 2, 3, 4
    Qo	1st, 2nd, 3rd, 4th
    QQ	01, 02, 03, 04
    QQQ	Q1, Q2, Q3, Q4
    QQQQ	1st quarter, 2nd quarter, ...
    QQQQQ	1, 2, 3, 4
    Quarter (stand-alone)	q	1, 2, 3, 4
    qo	1st, 2nd, 3rd, 4th
    qq	01, 02, 03, 04
    qqq	Q1, Q2, Q3, Q4
    qqqq	1st quarter, 2nd quarter, ...
    qqqqq	1, 2, 3, 4
    Month (formatting)	M	1, 2, ..., 12
    Mo	1st, 2nd, ..., 12th
    MM	01, 02, ..., 12
    MMM	Jan, Feb, ..., Dec
    MMMM	January, February, ..., December
    MMMMM	J, F, ..., D
    Month (stand-alone)	L	1, 2, ..., 12
    Lo	1st, 2nd, ..., 12th
    LL	01, 02, ..., 12
    LLL	Jan, Feb, ..., Dec
    LLLL	January, February, ..., December
    LLLLL	J, F, ..., D
    Local week of year	w	1, 2, ..., 53
    wo	1st, 2nd, ..., 53th
    ww	01, 02, ..., 53
    ISO week of year	I	1, 2, ..., 53
    Io	1st, 2nd, ..., 53th
    II	01, 02, ..., 53
    Day of month	d	1, 2, ..., 31
    do	1st, 2nd, ..., 31st
    dd	01, 02, ..., 31
    Day of year	D	1, 2, ..., 365, 366
    Do	1st, 2nd, ..., 365th, 366th
    DD	01, 02, ..., 365, 366
    DDD	001, 002, ..., 365, 366
    DDDD	...
    Day of week (formatting)	E..EEE	Mon, Tue, Wed, ..., Sun
    EEEE	Monday, Tuesday, ..., Sunday
    EEEEE	M, T, W, T, F, S, S
    EEEEEE	Mo, Tu, We, Th, Fr, Sa, Su
    ISO day of week (formatting)	i	1, 2, 3, ..., 7
    io	1st, 2nd, ..., 7th
    ii	01, 02, ..., 07
    iii	Mon, Tue, Wed, ..., Sun
    iiii	Monday, Tuesday, ..., Sunday
    iiiii	M, T, W, T, F, S, S
    iiiiii	Mo, Tu, We, Th, Fr, Sa, Su
    Local day of week (formatting)	e	2, 3, 4, ...,
    eo	2nd, 3rd, ..., 1st
    ee	02, 03, ..., 01
    eee	Mon, Tue, Wed, ..., Sun
    eeee	Monday, Tuesday, ..., Sunday
    eeeee	M, T, W, T, F, S, S
    eeeeee	Mo, Tu, We, Th, Fr, Sa, Su
    Local day of week (stand-alone)	c	2, 3, 4, ...,
    co	2nd, 3rd, ..., 1st
    cc	02, 03, ..., 01
    ccc	Mon, Tue, Wed, ..., Sun
    cccc	Monday, Tuesday, ..., Sunday
    ccccc	M, T, W, T, F, S, S
    cccccc	Mo, Tu, We, Th, Fr, Sa, Su
    AM, PM	a..aa	AM, PM
    aaa	am, pm
    aaaa	a.m., p.m.
    aaaaa	a, p
    AM, PM, noon, midnight	b..bb	AM, PM, noon, midnight
    bbb	am, pm, noon, midnight
    bbbb	a.m., p.m., noon, midnight
    bbbbb	a, p, n, mi
    Flexible day period	B..BBB	at night, in the morning, ...
    BBBB	at night, in the morning, ...
    BBBBB	at night, in the morning, ...
    Hour [1-12]	h	1, 2, ..., 11, 12
    ho	1st, 2nd, ..., 11th, 12th
    hh	01, 02, ..., 11, 12
    Hour [0-23]	H	0, 1, 2, ..., 23
    Ho	0th, 1st, 2nd, ..., 23rd
    HH	00, 01, 02, ..., 23
    Hour [0-11]	K	1, 2, ..., 11, 0
    Ko	1st, 2nd, ..., 11th, 0th
    KK	01, 02, ..., 11, 00
    Hour [1-24]	k	24, 1, 2, ..., 23
    ko	24th, 1st, 2nd, ..., 23rd
    kk	24, 01, 02, ..., 23
    Minute	m	0, 1, ..., 59
    mo	0th, 1st, ..., 59th
    mm	00, 01, ..., 59
    Second	s	0, 1, ..., 59
    so	0th, 1st, ..., 59th
    ss	00, 01, ..., 59
    Fraction of second	S	0, 1, ...,
    SS	00, 01, ..., 99
    SSS	000, 001, ..., 999
    SSSS	...
    Timezone (ISO-8601 w/ Z)	X	-08, +0530, Z
    XX	-0800, +0530, Z
    XXX	-08:00, +05:30, Z
    XXXX	-0800, +0530, Z, +123456
    XXXXX	-08:00, +05:30, Z, +12:34:56
    Timezone (ISO-8601 w/o Z)	x	-08, +0530, +00
    xx	-0800, +0530, +0000
    xxx	-08:00, +05:30, +00:00
    xxxx	-0800, +0530, +0000, +123456
    xxxxx	-08:00, +05:30, +00:00, +12:34:56
    Timezone (GMT)	O...OOO	GMT-8, GMT+5:30, GMT+0
    OOOO	GMT-08:00, GMT+05:30, GMT+00:00
    Timezone (specific non-locat.)	z...zzz	GMT-8, GMT+5:30, GMT+0
    zzzz	GMT-08:00, GMT+05:30, GMT+00:00
    Seconds timestamp	t	512969520
    tt	...
    Milliseconds timestamp	T	512969520900
    TT	...
    Long localized date	P	04/29/1453
    PP	Apr 29, 1453
    PPP	April 29th, 1453
    PPPP	Friday, April 29th, 1453
    Long localized time	p	12:00 AM
    pp	12:00:00 AM
    ppp	12:00:00 AM GMT+2
    pppp	12:00:00 AM GMT+02:00
    Combination of date and time	Pp	04/29/1453, 12:00 AM
    PPpp	Apr 29, 1453, 12:00:00 AM
    PPPppp	April 29th, 1453 at ...
    PPPPpppp	Friday, April 29th, 1453 at ...
    */
    Handlebars.registerHelper("currentDateTime", function (formatPattern: string) {
      const date: Date = new Date();
      if (!formatPattern) {
        formatPattern = "yyyy-MM-dd";
      }
      return format(date, formatPattern);
    });

    Handlebars.registerHelper("currentDateTimeUtc", function (formatPattern: string) {
      const date: Date = new Date();
      const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
        date.getUTCDate(), date.getUTCHours(),
        date.getUTCMinutes(), date.getUTCSeconds()));
      if (!formatPattern) {
        formatPattern = "yyyy-MM-dd";
      }
      return format(utcDate, formatPattern);
    });

    Handlebars.registerHelper("formatDateTime", function (dateTime: any, formatPattern: string) {
      if (!dateTime) {
        return "";
      }
      const date: Date = new Date(dateTime);
      if (!formatPattern) {
        formatPattern = "yyyy-MM-dd";
      } else if (formatPattern.includes("D") || formatPattern.includes("Y")) {
        // Legacy formatting used YYYY for year and DD for date but new formatter doesn't like that since
        // those are used for other date formatting tokens.
        formatPattern = Helper.replaceAll(formatPattern, "D", "d");
        formatPattern = Helper.replaceAll(formatPattern, "Y", "y");
      }
      return format(date, formatPattern);
    });

  }





  public static handlebarsRegisterHelpersMath(): void {

    /**
    {{add 2 3}} => 5
    {{add "2" "3"}} => 5
    {{add "Jon" "Doe"}} => Jon Doe
    */
    Handlebars.registerHelper("add", function (a, b) {
      if (Helper.isNumeric(a) && Helper.isNumeric(b)) {
        return Number(a) + Number(b);
      }
      if (typeof a === 'string' && typeof b === 'string') {
        return a + b;
      }
      return '';
    });

  }



  public static handlebarsRegisterHelpersNumber(): void {

    Handlebars.registerHelper("toFixed", function (number, digits) {
      if (!Helper.isNumeric(number)) {
        number = 0;
      }
      if (!Helper.isNumeric(digits)) {
        digits = 0;
      }
      return Number(number).toFixed(digits);
    });

  }



}
