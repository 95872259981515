import { ApiProperties, ApiEndpoint, ApiDocumentation, ApiOperationType, CacheLevel, ApiDocPage, ApiDocTestFormProperty, ApiDocTestFormPropertyType, ApiRelationship } from "projects/core-lib/src/lib/api/ApiModels";
import { Log } from "projects/core-lib/src/lib/helpers/helper";

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";

import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";

import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as Enumerable from "linq";
import { ApiHelper } from "projects/core-lib/src/lib/api/ApiHelper";


export class ApiModuleProxy {


  // #region Helper Functions

  /**
  This method returns an array of all of the api properties methods available in this class.
  */
  public static GetListOfApiPropertiesMethods(): string[] {
    let list: string[] = [];
    list = Object.getOwnPropertyNames(ApiModuleProxy).filter(function (p) {
      return p !== "GetListOfApiPropertiesMethods" && p !== "GetApi" && p !== "getApiRelationships" && typeof ApiModuleProxy[p] === "function";
    });
    return list;
  }
  /**
  This method returns the ApiProperties object for the requested api name.  This can be used to
  get api properties object dynamically.
  */
  public static GetApi(apiName: string, version: number = AppConfig.apiVersion, suppressErrorReporting: boolean = false): ApiProperties {
    if (!version) {
      version = AppConfig.apiVersion;
    }
    try {
      const api: ApiProperties = ApiModuleProxy[apiName](version);
      // If we don't have an id we can populate it here since we accessed this by method name and
      // the method name is our default id name.
      if (!api.id) {
        api.id = apiName;
      }
      return api;
    } catch (err) {
      if (!suppressErrorReporting) {
        Log.errorMessage(`Exception getting api for ${apiName} with version ${version}`);
        Log.errorMessage(err);
      }
    }
  }


  public static getApiRelationships(): ApiRelationship[] {

    const relationships: ApiRelationship[] = [];

    // Start by stepping through our APIs and gathering the api name and any parent api reference
    const apiNames = ApiModuleProxy.GetListOfApiPropertiesMethods();
    for (const apiName of apiNames) {
      const api: ApiProperties = ApiModuleProxy.GetApi(apiName);
      if (!api) {
        console.error(`Unable to get relationships for unknown api ${apiName}`);
        break;
      }
      const relationship: ApiRelationship = new ApiRelationship();
      relationship.apiName = apiName;
      relationship.parent = api.parentApi;
      relationships.push(relationship);
    }

    // Now step through our relationship list and gather list of children for each api
    const linq = Enumerable.from(relationships);
    relationships.forEach((relationship: ApiRelationship, index: number, array: ApiRelationship[]) => {
      const children = linq.where(x => x.parent === relationship.apiName).toArray();
      relationship.children = Enumerable.from(children).select(x => x.apiName).toArray();
    });

    return relationships;

  }

  // #endregion Helper Functions



  // #region Core APIs

  public static Register(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("Register");
    api.version = version;
    api.documentation.objectDescription = "Proxy Register";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.ProxyRegistrationViewModel;
    api.documentation.requestAndResponseDataModelObject = new m.ProxyRegistrationViewModel();
    api.documentation.documentationUrlBase = "/proxy-registration/";
    api.documentation.securityAccessArea = "";
    api.useProxy = true;
    api.pathVariables = "{proxyDataSourceId}";
    api.pathModelProperties = "ProxyDataSourceId";
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.Register}`, ApiOperationType.Call));
    return api;
  };

  // #endregion



  // #region Stimulsoft APIs

  public static ReportStimulsoftViewerInit(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportStimulsoftViewerInit");
    api.version = version;
    api.documentation.objectDescription = "Report Stimulsoft Viewer Init";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/report/stimulsoft/viewer/init/";
    api.documentation.securityAccessArea = "";
    api.useProxy = true;
    api.pathVariables = ["{proxyDataSourceId}", "{queryEventId}"];
    api.pathModelProperties = ["ProxyDataSourceId", "QueryEventId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.Reports}/{queryEventId}/${m.RouteSegment.Stimulsoft}/${m.RouteSegment.View}/${m.RouteSegment.Init}`, ApiOperationType.Add));
    return api;
  };

  public static ReportStimulsoftViewerReport(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportStimulsoftViewerReport");
    api.version = version;
    api.documentation.objectDescription = "Report Stimulsoft Viewer Report";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/report/stimulsoft/viewer/report/";
    api.documentation.securityAccessArea = "";
    api.useProxy = true;
    api.pathVariables = ["{proxyDataSourceId}", "{queryEventId}"];
    api.pathModelProperties = ["ProxyDataSourceId", "QueryEventId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.Reports}/{queryEventId}/${m.RouteSegment.Stimulsoft}/${m.RouteSegment.View}/${m.RouteSegment.Reports}`, ApiOperationType.Add));
    return api;
  };

  public static ReportStimulsoftViewerEvent(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportStimulsoftViewerEvent");
    api.version = version;
    api.documentation.objectDescription = "Report Stimulsoft Viewer Event";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/report/stimulsoft/viewer/event/";
    api.documentation.securityAccessArea = "";
    api.useProxy = true;
    api.pathVariables = ["{proxyDataSourceId}", "{queryEventId}"];
    api.pathModelProperties = ["ProxyDataSourceId", "QueryEventId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.Reports}/{queryEventId}/${m.RouteSegment.Stimulsoft}/${m.RouteSegment.View}/${m.RouteSegment.Events}`, ApiOperationType.Add));
    return api;
  };

  public static ReportStimulsoftViewerBase(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportStimulsoftViewerBase");
    api.version = version;
    api.documentation.objectDescription = "Report Stimulsoft Viewer Base";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/report/stimulsoft/viewer/base/";
    api.documentation.securityAccessArea = "";
    api.useProxy = true;
    api.pathVariables = ["{proxyDataSourceId}", "{queryEventId}"];
    api.pathModelProperties = ["ProxyDataSourceId", "QueryEventId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.Reports}/{queryEventId}/${m.RouteSegment.Stimulsoft}/${m.RouteSegment.View}`, ApiOperationType.Add));
    return api;
  };

  public static ReportStimulsoftDesignerFromQuery(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("ReportStimulsoftDesignerFromQuery");
    api.version = version;
    api.documentation.objectDescription = "Report Stimulsoft Designer From Query";
    api.documentation.objectPrimaryKey = "";
    api.documentation.objectDescriptionPropertyNames = [];
    api.documentation.requestAndResponseDataModelDocumentationName = "";
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/report/stimulsoft/designer/query/";
    api.documentation.securityAccessArea = "";
    api.useProxy = true;
    api.pathVariables = ["{proxyDataSourceId}", "{queryId}", "{reportTemplateId}"];
    api.pathModelProperties = ["ProxyDataSourceId", "QueryId", "ReportTemplateId"];
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.Reports}/${m.RouteSegment.Stimulsoft}/${m.RouteSegment.Design}/{queryId}/{reportTemplateId}`, ApiOperationType.Add));
    return api;
  };

  // #endregion



  // #region Attachment APIs

  public static AttachmentView(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttachmentView");
    api.version = version;
    api.documentation.objectDescription = "Attachment";
    api.documentation.objectPrimaryKey = "AttachmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attachment;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/attachment/view/";
    api.pathVariables = ["{proxyDataSourceId}", "{ownerResourceType}", "{ownerResourceId}", "{ownerResourceCategory}", "{attachmentId}", "{slug}"];
    api.pathModelProperties = ["ProxyDataSourceId", "OwnerResourceType", "OwnerResourceId", "OwnerResourceCategory", "AttachmentId", "Slug"];
    api.useProxy = true;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.View}/${m.RouteSegment.Attachments}/{ownerResourceType}/{ownerResourceId}/{ownerResourceCategory}/{attachmentId}/{slug}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Attachment";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Attachment";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to view an attachment.  The slug parameter is optional.  If not a public attachment valid auth cookies are required.`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View Attachment";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AttachmentViewThumbnail(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttachmentViewThumbnail");
    api.version = version;
    api.documentation.objectDescription = "Attachment";
    api.documentation.objectPrimaryKey = "AttachmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attachment;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/attachment/view/thumbnail/";
    api.pathVariables = ["{proxyDataSourceId}", "{ownerResourceType}", "{ownerResourceId}", "{ownerResourceCategory}", "{attachmentId}", "{slug}"];
    api.pathModelProperties = ["ProxyDataSourceId", "OwnerResourceType", "OwnerResourceId", "OwnerResourceCategory", "AttachmentId", "Slug"];
    api.useProxy = true;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.View}/${m.RouteSegment.Attachments}/${m.RouteSegment.Thumbnail}/{ownerResourceType}/{ownerResourceId}/{ownerResourceCategory}/{attachmentId}/{slug}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Attachment Thumbnail";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Attachment Thumbnail";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to view an attachment thumbnail.  The slug parameter is optional.  If not a public attachment valid auth cookies are required.`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View Attachment Thumbnail";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AttachmentDownload(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttachmentDownload");
    api.version = version;
    api.documentation.objectDescription = "Attachment";
    api.documentation.objectPrimaryKey = "AttachmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attachment;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/attachment/download/";
    api.pathVariables = ["{proxyDataSourceId}", "{ownerResourceType}", "{ownerResourceId}", "{ownerResourceCategory}", "{attachmentId}", "{slug}"];
    api.pathModelProperties = ["ProxyDataSourceId", "OwnerResourceType", "OwnerResourceId", "OwnerResourceCategory", "AttachmentId", "Slug"];
    api.useProxy = true;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/${m.RouteSegment.Download}/${m.RouteSegment.Attachments}/{ownerResourceType}/{ownerResourceId}/{ownerResourceCategory}/{attachmentId}/{slug}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Attachment";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Attachment";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to download an attachment.  The slug parameter is optional.  If not a public attachment valid auth cookies are required.`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download Attachment";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };


  public static AttachmentViewPublic(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttachmentViewPublic");
    api.version = version;
    api.documentation.objectDescription = "Attachment";
    api.documentation.objectPrimaryKey = "AttachmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attachment;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/attachment/view/public/";
    api.pathVariables = ["{proxyDataSourceId}", "{partitionId}", "{ownerResourceType}", "{ownerResourceId}", "{ownerResourceCategory}", "{attachmentId}", "{slug}"];
    api.pathModelProperties = ["ProxyDataSourceId", "PartitionId", "OwnerResourceType", "OwnerResourceId", "OwnerResourceCategory", "AttachmentId", "Slug"];
    api.useProxy = true;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/{partitionId}/${m.RouteSegment.View}/${m.RouteSegment.Attachments}/{ownerResourceType}/{ownerResourceId}/{ownerResourceCategory}/{attachmentId}/{slug}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Public Attachment";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Public Attachment";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to view an attachment.  The slug parameter is optional.  If not a public attachment valid auth cookies are required.`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View Public Attachment";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AttachmentViewThumbnailPublic(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttachmentViewThumbnailPublic");
    api.version = version;
    api.documentation.objectDescription = "Attachment";
    api.documentation.objectPrimaryKey = "AttachmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attachment;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/attachment/view/thumbnail/public/";
    api.pathVariables = ["{proxyDataSourceId}", "{partitionId}", "{ownerResourceType}", "{ownerResourceId}", "{ownerResourceCategory}", "{attachmentId}", "{slug}"];
    api.pathModelProperties = ["ProxyDataSourceId", "PartitionId", "OwnerResourceType", "OwnerResourceId", "OwnerResourceCategory", "AttachmentId", "Slug"];
    api.useProxy = true;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/{partitionId}/${m.RouteSegment.View}/${m.RouteSegment.Attachments}/${m.RouteSegment.Thumbnail}/{ownerResourceType}/{ownerResourceId}/{ownerResourceCategory}/{attachmentId}/{slug}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "View Public Attachment Thumbnail";
    api.endpoints.slice(-1)[0].documentation.menuText = "View Public Attachment Thumbnail";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to view an attachment thumbnail.  The slug parameter is optional.  If not a public attachment valid auth cookies are required.`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "tv";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "View Public Attachment Thumbnail";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  public static AttachmentDownloadPublic(version: number = AppConfig.apiVersion): ApiProperties {
    const api = new ApiProperties("AttachmentDownloadPublic");
    api.version = version;
    api.documentation.objectDescription = "Attachment";
    api.documentation.objectPrimaryKey = "AttachmentId";
    api.documentation.requestAndResponseDataModelDocumentationName = Constants.DataModelName.Attachment;
    api.documentation.requestAndResponseDataModelObject = null;
    api.documentation.documentationUrlBase = "/attachment/download/public/";
    api.pathVariables = ["{proxyDataSourceId}", "{partitionId}", "{ownerResourceType}", "{ownerResourceId}", "{ownerResourceCategory}", "{attachmentId}", "{slug}"];
    api.pathModelProperties = ["ProxyDataSourceId", "PartitionId", "OwnerResourceType", "OwnerResourceId", "OwnerResourceCategory", "AttachmentId", "Slug"];
    api.useProxy = true;
    api.cacheName = null;
    api.endpoints.push(new ApiEndpoint(`/{proxyDataSourceId}/{partitionId}/${m.RouteSegment.Download}/${m.RouteSegment.Attachments}/{ownerResourceType}/{ownerResourceId}/{ownerResourceCategory}/{attachmentId}/{slug}`, ApiOperationType.Get));
    api.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
    api.endpoints.slice(-1)[0].documentation.title = "Download Public Attachment";
    api.endpoints.slice(-1)[0].documentation.menuText = "Download Public Attachment";
    api.endpoints.slice(-1)[0].documentation.overviewText = `This API can be used to download an attachment.  The slug parameter is optional.  If not a public attachment valid auth cookies are required.`;
    api.endpoints.slice(-1)[0].documentation.testButtonIcon = "cloud-download";
    api.endpoints.slice(-1)[0].documentation.testButtonText = "Download Public Attachment";
    api.endpoints.slice(-1)[0].documentation.testFormUseAnonymousObjectForPathModelProperties = true;
    return api;
  };

  // #endregion

}
