import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { EventModel, EventElementModel } from '../../../ux-models';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { UxService } from '../../../services/ux.service';

@Component({
  selector: 'ib-data-nav',
  templateUrl: './data-nav.component.html',
  styleUrls: ['./data-nav.component.css']
})
export class DataNavComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() data: any[] = [];
  @Input() propertyNameIcon: string = "";
  @Input() propertyNameLabel: string = "";
  @Input() propertyNameTooltip: string = "";
  @Input() propertyNameEnabled: string = "";
  @Input() propertyNameDisplayOrder: string = "";
  @Input() propertyNameId: string = "";
  @Input() propertyNameStatusIcon: string = "";
  @Input() propertyNameStatusTooltip: string = "";
  @Input() maximumLabelLength: number = 0;
  @Input() numberingStyle: string = "";
  @Input() numberingTemplate: string = "";
  @Input() maxHeight: number = null;
  @Input() autoSelect: boolean = true;
  @Input() selectedItem: any;
  @Input() dragDrop: boolean = false;
  @Input() containerClass: string = "";
  @Input() containerStyle: string = "";
  @Input() cursorStyle: "pointer" | "move" = "pointer";
  /**
   * If isDirty is true user will be prompted to discard before new item can be selected.
   */
  @Input() isDirty: boolean = false;
  @Output() change: EventEmitter<EventModel> = new EventEmitter();
  @Output() select: EventEmitter<EventModel> = new EventEmitter();

  iconObject: string = "";
  iconProperty: string = "";
  labelObject: string = "";
  labelProperty: string = "";
  tooltipObject: string = "";
  tooltipProperty: string = "";
  displayOrderObject: string = "";
  displayOrderProperty: string = "";
  idObject: string = "";
  idProperty: string = "";
  enabledObject: string = "";
  enabledProperty: string = "";
  statusIconObject: string = "";
  statusIconProperty: string = "";
  statusTooltipObject: string = "";
  statusTooltipProperty: string = "";
  /**
   * Used to make a copy of the data, so it can be returned in the $event.cargo object. This could allow
   * the user to know the original array order so they can adjust the currently displayed index if necessary.
   * IE: If Index 0 is displaying and you drag Index 1 to Index 0, then it will be displaying what was moved to
   * Index 0, but it should now be displaying Index 1, because that's what was showing before the drag drop.
   */
  dataCopy: any[] = [];

  // tooltipsEnabled: boolean = true;
  style: SafeStyle = null;

  constructor(
    protected appService: AppService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes.data && this.data && !this.selectedItem && this.data.length > 0 && this.autoSelect) {
    //  this.onSelect(null, this.data[0], 0);
    // }
    if (changes.propertyNameIcon && this.propertyNameIcon) {
      this.iconProperty = this.propertyNameIcon;
      if (this.iconProperty.includes(".")) {
        this.iconObject = Helper.left(this.iconProperty, this.iconProperty.indexOf("."));
        this.iconProperty = this.iconProperty.replace(this.iconObject + ".", "");
      }
    }
    if (changes.propertyNameLabel && this.propertyNameLabel) {
      this.labelProperty = this.propertyNameLabel;
      if (this.labelProperty.includes(".")) {
        this.labelObject = Helper.left(this.labelProperty, this.labelProperty.indexOf("."));
        this.labelProperty = this.labelProperty.replace(this.labelObject + ".", "");
      }
    }
    if (changes.propertyNameTooltip && this.propertyNameTooltip) {
      this.tooltipProperty = this.propertyNameTooltip;
      if (this.tooltipProperty.includes(".")) {
        this.tooltipObject = Helper.left(this.tooltipProperty, this.tooltipProperty.indexOf("."));
        this.tooltipProperty = this.tooltipProperty.replace(this.tooltipObject + ".", "");
      }
    }
    if (changes.propertyNameEnabled && this.propertyNameEnabled) {
      this.enabledProperty = this.propertyNameEnabled;
      if (this.enabledProperty.includes(".")) {
        this.enabledObject = Helper.left(this.enabledProperty, this.enabledProperty.indexOf("."));
        this.enabledProperty = this.enabledProperty.replace(this.enabledObject + ".", "");
      }
    }
    if (changes.propertyNameDisplayOrder && this.propertyNameDisplayOrder) {
      this.displayOrderProperty = this.propertyNameDisplayOrder;
      if (this.displayOrderProperty.includes(".")) {
        this.displayOrderObject = Helper.left(this.displayOrderProperty, this.displayOrderProperty.indexOf("."));
        this.displayOrderProperty = this.displayOrderProperty.replace(this.displayOrderObject + ".", "");
      }
    }
    if (changes.propertyNameId && this.propertyNameId) {
      this.idProperty = this.propertyNameId;
      if (this.idProperty.includes(".")) {
        this.idObject = Helper.left(this.idProperty, this.idProperty.indexOf("."));
        this.idProperty = this.idProperty.replace(this.idObject + ".", "");
      }
    }
    if (changes.propertyNameStatusIcon && this.propertyNameStatusIcon) {
      this.statusIconProperty = this.propertyNameStatusIcon;
      if (this.statusIconProperty.includes(".")) {
        this.statusIconObject = Helper.left(this.statusIconProperty, this.statusIconProperty.indexOf("."));
        this.statusIconProperty = this.statusIconProperty.replace(this.statusIconObject + ".", "");
      }
    }
    if (changes.propertyNameStatusTooltip && this.propertyNameStatusTooltip) {
      this.statusTooltipProperty = this.propertyNameStatusTooltip;
      if (this.statusTooltipProperty.includes(".")) {
        this.statusTooltipObject = Helper.left(this.statusTooltipProperty, this.statusTooltipProperty.indexOf("."));
        this.statusTooltipProperty = this.statusTooltipProperty.replace(this.statusTooltipObject + ".", "");
      }
    }

    // Set style
    let style: string = "";
    if (this.maxHeight) {
      style = `max-height: ${this.maxHeight}px; overflow-y: scroll;`;
    }
    if (this.containerStyle) {
      style += this.containerStyle;
    }
    this.style = this.sanitizer.bypassSecurityTrustStyle(style);

  }

  ngAfterViewInit() {
    // if (this.data && !this.selectedItem && this.data.length > 0 && this.autoSelect) {
    //  this.onSelect(null, this.data[0], 0);
    // }
  }

  toggleEnabled($event, item, index) {
    if (!this.enabledProperty) {
      return;
    }
    if (this.enabledObject) {
      item[this.enabledObject][this.enabledProperty] = !item[this.enabledObject][this.enabledProperty];
    } else {
      item[this.enabledProperty] = !item[this.enabledProperty];
    }
    this.onChange(null, item, index);
  }

  onSelect($event, item, index) {

    if (!item) {
      return;
    }

    // console.error("is dirty", this.isDirty);

    if (!this.isDirty) {
      this.selected($event, item, index);
      return;
    }

    // Before selecting this item see if isDirty is true and, if so, ask before we proceed
    const promise = this.uxService.modal.confirmUnsavedChanges();
    promise.then((answer) => {
      this.selected($event, item, index);
    }, (cancelled) => {
      // No action
    });

  }


  selected($event, item, index) {

    if (!item) {
      return;
    }

    this.selectedItem = item;
    // if (this.propertyNameId) {
    //  this.selectedItemId = item[this.propertyNameId];
    // }

    const cargo: any = { data: this.data, index: index };

    // Fire registered event handler(s)
    const payload: EventModel = new EventModel("click", $event, item, new EventElementModel("nav-item"), cargo);
    this.select.emit(payload);

    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    } catch (err) {
      // Log.errorMessage(err);
    }

  }


  isActive(item: any) {
    if (!this.selectedItem) {
      return false;
    }
    // if (this.propertyNameId) {
    //  if (this.selectedItemId === item[this.propertyNameId]) {
    //    return true;
    //  }
    // } else {
    //  if (this.selectedItem === item) {
    //    return true;
    //  }
    // }
    if (this.selectedItem === item) {
      return true;
    }
    return false;
  }

  getEnabled(item: any): boolean {
    if (!this.propertyNameEnabled || !item) {
      return true;
    }
    if (this.enabledObject) {
      return item[this.enabledObject][this.enabledProperty];
    } else {
      return item[this.enabledProperty];
    }
  }

  getIcon(item: any): string {
    if (!this.propertyNameIcon || !item) {
      return "";
    }
    if (this.iconObject) {
      return item[this.iconObject][this.iconProperty];
    } else {
      return item[this.iconProperty];
    }
  }

  getStatusIcon(item: any): string {
    if (!this.propertyNameStatusIcon || !item) {
      return "";
    }
    if (this.statusIconObject) {
      return item[this.statusIconObject][this.statusIconProperty];
    } else {
      // console.error(this.getLabel(item), item[this.statusIconProperty]);
      return item[this.statusIconProperty];
    }
  }

  getLabel(item: any): string {
    if (!this.propertyNameLabel || !item) {
      return "";
    }
    let label: string = "";
    if (this.labelObject) {
      label = item[this.labelObject][this.labelProperty];
    } else {
      label = item[this.labelProperty];
    }
    if (this.maximumLabelLength) {
      label = Helper.left(label, this.maximumLabelLength, true);
    }
    if (this.numberingStyle) {
      let number = this.getItemNumber(item);
      if (number) {
        if (this.numberingTemplate && Helper.contains(this.numberingTemplate, "#")) {
          number = Helper.replaceAll(this.numberingTemplate, "#", number);
        }
        label += ` (${number})`;
      }
    }
    return label;
  }

  getTooltip(item: any): string {
    if (this.propertyNameTooltip && item) {
      let tip = "";
      if (this.tooltipObject) {
        tip = item[this.tooltipObject][this.tooltipProperty];
      } else {
        tip = item[this.tooltipProperty];
      }
      if (tip) {
        return tip;
      }
    }
    if (!this.propertyNameLabel || !item) {
      return "                 ";
    }
    let label: string = "";
    if (this.labelObject) {
      label = item[this.labelObject][this.labelProperty];
    } else {
      label = item[this.labelProperty];
    }
    if (this.numberingStyle) {
      let number = this.getItemNumber(item);
      if (number) {
        if (this.numberingTemplate && Helper.contains(this.numberingTemplate, "#")) {
          number = Helper.replaceAll(this.numberingTemplate, "#", number);
        }
        label += ` (${number})`;
      }
    }
    if (!label) {
      return "                 ";
    }
    return label;
  }

  getStatusTooltip(item: any): string {
    if (this.propertyNameStatusTooltip && item) {
      let tip = "";
      if (this.statusTooltipObject) {
        tip = item[this.statusTooltipObject][this.statusTooltipProperty];
      } else {
        tip = item[this.statusTooltipProperty];
      }
      if (tip) {
        return tip;
      }
    }
    return "";
  }

  getItemNumber(item: any): string {
    if (!this.numberingStyle || !item) {
      return "";
    }
    if (this.propertyNameEnabled) {
      // If not enabled we don't assign a number
      if (!this.getEnabled(item)) {
        return "";
      }
    }
    let pos: number = 0;
    let counter: number = 0;
    this.data.forEach((one: any, index: number) => {
      // Every enabled item gets a number
      if (this.propertyNameEnabled) {
        if (this.getEnabled(one)) {
          counter++;
        }
      } else {
        counter++;
      }
      // If this is the item we're looking for then grab our number
      if (this.propertyNameId) {
        if (this.idObject) {
          if (one[this.idObject][this.idProperty] === item[this.idObject][this.idProperty]) {
            pos = counter;
          }
        } else {
          if (one[this.idProperty] === item[this.idProperty]) {
            pos = counter;
          }
        }
      } else if (one === item) {
        pos = counter;
      }
    });
    let number: string = pos.toString();
    if (this.numberingStyle === "A") {
      number = String.fromCharCode(64 + pos);
    } else if (this.numberingStyle === "a") {
      number = String.fromCharCode(96 + pos);
    } else if (this.numberingStyle === "I") {
      number = Helper.toRomanNumeral(pos);
    } else if (this.numberingStyle === "i") {
      number = Helper.toRomanNumeral(pos).toLowerCase();
    }
    return number;
  }

  onChange($event: any, item: any, index: number) {

    const cargo: any = { data: this.data, index: index, originalData: this.dataCopy };

    // Fire registered event handler(s)
    const payload: EventModel = new EventModel("change", $event, item, new EventElementModel("nav-item"), cargo);
    this.change.emit(payload);

    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    } catch (err) {
      // Log.errorMessage(err);
    }

  }

  drop(event: CdkDragDrop<string[]>) {
    // console.error("drop", event, this.data[event.previousIndex], this.data[event.currentIndex]);
    const item = this.data[event.previousIndex];

    // Copy the data before we change the array order
    this.dataCopy = Helper.deepCopy(this.data);

    this.data = Helper.arrayElementMove(this.data, event.previousIndex, event.currentIndex, this.propertyNameDisplayOrder)?.allItems;

    if (event.previousIndex === event.currentIndex) {
      // Nothing changed, they just dragged it but set it back in the same spot.
    } else {
      this.onChange(null, item, event.previousIndex);
    }
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

}
