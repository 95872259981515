import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { ButtonItem, EventModel } from '../../ux-models';
import { UxService } from '../../services/ux.service';
import { MultiSelectComponent } from './multiselect';
import { StateIcon } from '../../image/icon/icon.component';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputMultiselectComponent),
  multi: true
};

@Component({
  selector: 'ib-input-multiselect',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-multiselect.component.html',
  styleUrls: ['./input-multiselect.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputMultiselectComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit {

  // Note that we have several @Input() and @Output() declarations in the base class.

  /**
   * The max number of labels that will display in the bar. If more labels are selected, they will
   * not be shown.
   */
  @Input() public maxSelectedLabels: number = 20;
  /**
   * Label to display after exceeding max selected labels. Must be in this format:
   * "{0} your phrase here" and the {0} will be replaced with the number of items selected.
   */
  @Input() public selectedItemsLabel: string = "{0} items selected";
  /**
   * After the user selects this many items the rest will be disabled.
   */
  @Input() public selectionLimit: number = null;
  @Input() public layoutClass: string = "ui-multiselect-normal";

  @Input() headerIcon: string = "";
  @Input() headerText: string = "";
  @Input() headerActionButton: ButtonItem = null;
  @Input() footerActionButton: ButtonItem = null;
  /** If this is incremented, the multiselect will be closed. */
  @Input() closeCounter: number = 0;

  // @Input() actionIcon: string = "";
  // @Input() actionTooltip: string = "";
  // @Output() onAction: EventEmitter<any> = new EventEmitter();

  /**
  If we want to have a badge after the label showing the count of selected items then we assign that color now.
  */
  @Input() selectionCountBadgeColor: "" | "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "light" | "dark" = "";

  @Input() modelIsJsonString: boolean = false;
  @Input() modelIsCsvString: boolean = false;


  @Input() selectedItemsWordwrap: boolean = true;


  @Input() display: "comma" | "chip" = "comma";
  /** If true, there will be a toggle in the header */
  @Input() enableHeaderToggle: boolean = false;
  @Input() toggleColor: string = "#007bff";
  @Input() toggleSize: string = "1.5em";

  // I honestly don't know what default values to give these. The original intent was for 'All' or 'Any' but
  // I want to leave it open for any kind of toggle label.
  /** The label when the state is 'true'. The same label can be provided for enabled and disabled if you dont want it to change when toggled.*/
  @Input() toggleEnabledLabel: string = "";
  /** The label when the state is 'false'. The same label can be provided for enabled and disabled if you dont want it to change when toggled.*/
  @Input() toggleDisabledLabel: string = "";
  @Input() toggleState: boolean = true;
  /** If the data emitted is true, it means the toggle is enabled. */
  @Output() toggleStateChange: EventEmitter<EventModel> = new EventEmitter();
  toggleLabel: string = "";
  featureToggleStateIcons: StateIcon[] = [{ state: "true", icon: "toggle-on" }, { state: "false", icon: "toggle-off" }];

  multiSelectClasses: string = "";


  list: string[] = [];

  @ViewChild(MultiSelectComponent, { static: false }) multiSelect: MultiSelectComponent;

  constructor(
    protected apiService: ApiService,
    protected uxService: UxService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(apiService, uxService);
    // For multi-select we default this include none value to false
    this.optionsIncludeNone = false;
    // This ui component wants SelectItem[] array
    this.mapPickListToOptions = true;
    // Flag that we're using primeng for this input
    this.inputLibrary = "primeng";
  }

  ngOnInit() {
    // For multi-select we default this include none value to false
    this.optionsIncludeNone = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    // this.configure();
    if (this.size === "small") {
      this.multiSelectClasses = "p-multiselect-small";
    } else {
      this.multiSelectClasses = "";
    }

    if (changes.closeCounter && this.closeCounter > 0) {
      this.multiSelect.hide();
    }

    if (changes.toggleState || changes.toggleEnabledLabel || changes.toggleDisabledLabel) {
      this.setToggleLabelByState();
    }
  }

  ngAfterViewInit(): void {
    if (this.enableHeaderToggle && (this.headerText || this.headerIcon)) {
      console.error("Multiselect header toggle cannot be used with header text or icon.");
    }
  }

  showHeader() {
    if (this.headerIcon || this.headerText || this.headerActionButton) {
      return true;
    }
    return false;
  }

  writeValue(value: any) {
    super.writeValue(value);
    if (this.modelIsJsonString) {
      // console.error("input json value change", value);
      this.list = JSON.parse(value);
    } else if (this.modelIsCsvString) {
      // console.error("input csv value change", value);
      this.list = Helper.parseCsvString(value);
    } else if (this.optionsValueIsInteger) {
      // Multi-select needs integer values cast to strings for the pick list to work
      if (Helper.isArray(value)) {
        this.innerValue = (value as any[]).map(x => x.toString());
      }
    } else {
      // console.error("input list value change", value);
    }
  }


  fireChange($event, inputControl) {
    super.fireChange($event, inputControl);
    if (this.modelIsJsonString) {
      this.value = JSON.stringify(this.list);
    } else if (this.modelIsCsvString) {
      this.value = Helper.buildCsvString(this.list);
    }
  }

  // fireAction($event) {
  //  this.onAction.emit($event);
  // }

  protected onPickListReady() {
    super.onPickListReady();
    // When pick lists are loaded we need to fire change detection
    this.changeDetectorRef.detectChanges();
  }

  toggleClick() {
    this.toggleState = !this.toggleState;
    this.setToggleLabelByState();
    this.emitToggleStateChange();
  }

  setToggleLabelByState() {
    if (this.toggleState) {
      this.toggleLabel = this.toggleEnabledLabel;
    } else {
      this.toggleLabel = this.toggleDisabledLabel;

    }
  }

  emitToggleStateChange() {
    const event = new EventModel("change", null, this.toggleState, null, null);
    this.toggleStateChange.emit(event);
  }

}
