// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { Helper, Log } from "projects/core-lib/src/lib/helpers/helper";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";


/**
This class holds information about API models, methods, and documentation that belong to a certain object.
*/
export class ApiProperties {

  /**
  The id of the ApiProperties object.  This is typically the same as the method name in the Api class that
  created the object.  It used when checking an object dynamically like when checking to see if the api
  should be included or excluded from our api documentation.
  */
  public id: string = "";

  /** *
  The api version number for this api object.  Different versions may support different endpoints, have different
  data models, etc.
  */
  public version: number = AppConfig.apiVersion;

  /** *
  A list of versions that support this api.  Note that the models and endpoints may differ for different versions
  and compatibility between the versions listed here is not implied.  Only that the api has support an implementation
  for the versions listed here.  Instantiating an ApiProperties object for the desired version will reveal the
  models and endpoints supported for that version.
  */
  public supportedVersions: number[] = [5];

  /**
  Documentation that is common across all endpoints.  These values will be used for all endpoints unless
  the endpoint defines its own value in its documentation.
  */
  public documentation: ApiDocumentationCommon = new ApiDocumentationCommon();

  /**
  A collection of api endpoint objects that are available through this api.
  */
  public endpoints: ApiEndpoint[] = [];

  /**
   * When true use proxy url instead of api url.
   */
  public useProxy: boolean = false;

  /**
   * When true the http request includes credentials.
   */
  public withCredentials: boolean = false;

  /**
  A string or array of strings that are path variables to replace in the API URL strings.  This typically does not
  include the standard list query string values (i.e. page, size, sort, filter, q, expand).
  */
  public pathVariables: string | string[] = "";

  /**
  True if PathVariables is an array; otherwise, false.
  */
  get pathVariablesIsArray(): boolean {
    return Helper.isArray(this.pathVariables);
  }

  /**
  A string or array of strings that are object model property names that map one-to-one to PathVariables so the API
  URL can have the variables populated with values from the object.
  */
  public pathModelProperties: string | string[] = "";

  /**
  True if pathModelProperties is an array; otherwise, false.
  */
  get pathModelPropertiesIsArray(): boolean {
    return Helper.isArray(this.pathModelProperties);
  }

  /**
  The cache category to use for this object.  If null no caching will be performed.
  */
  public cacheName: string = "";
  /**
   * The cache level for this api.
   */
  public cacheLevel: CacheLevel = CacheLevel.Volatile;

  /**
   * List of pick list id values impacted by this api.  When populated any write operations will dump cache
   * for these pick list ids so they can be reloaded on the next reference.
   */
  public impactedPickListIds: string[] = [];

  /**
  If the api is specific to certain brands this can hide it from things like docs when not appropriate.
  */
  public brands: string[] = [];

  /**
  If the api is specific to certain modules this can hide it from things like docs when not appropriate.
  */
  public modules: string[] = [];

  /**
  If the api has a parent api this is a reference to that parent.  For example, the parent for notification
  group details is the notification group.  When doing automated testing it's not possible to add a
  notification group detail record without a notification group record so having a reference to the parent
  api allows us to pursue automated testing for that scenario.
  */
  public parentApi: string = null;

  /**
  If the api should be excluded from automated testing this should be set to true.
  */
  public excludeFromAutomatedTesting: boolean = false;

  /**
  Helper method to populate api endpoints
  */
  public useStandardEndpoints = (route: string, listModelObject: any = null, listModelDocName: string = null,
    supportCopy: boolean = false, supportExport: boolean = false, supportImport: boolean = false): void => {
    // Validate route
    if (!route) {
      route = "";
    }
    if (!Helper.startsWith(route, "/")) {
      route = "/" + route;
    }
    if (Helper.endsWith(route, "/")) {
      route = Helper.left(route, route.length - 1);
    }
    // Raise possible errors with an api using this helper method
    let variableName: string = "";
    if (this.pathVariablesIsArray) {
      variableName = this.pathVariables.slice(-1)[0];
    } else {
      variableName = <string>this.pathVariables;
    }
    let propertyName: string = "";
    if (this.pathModelPropertiesIsArray) {
      propertyName = this.pathModelProperties.slice(-1)[0];
    } else {
      propertyName = <string>this.pathModelProperties;
    }
    // Now add endpoints supported by this helper method
    this.endpoints.push(new ApiEndpoint(route + (Helper.contains(route, "?") ? "&" : "?") + "page={page}&size={size}&sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}", ApiOperationType.List));
    if (listModelObject || listModelDocName) {
      this.endpoints.slice(-1)[0].documentation = new ApiDocumentation();
      if (listModelObject) {
        this.endpoints.slice(-1)[0].documentation.responseDataModelObject = listModelObject;
      }
      if (listModelDocName) {
        this.endpoints.slice(-1)[0].documentation.responseDataModelDocumentationName = listModelDocName;
      }
    }
    this.endpoints.push(new ApiEndpoint(`${route}/${variableName}`, ApiOperationType.Get));
    this.endpoints.push(new ApiEndpoint(route, ApiOperationType.Add));
    this.endpoints.push(new ApiEndpoint(`${route}/${variableName}`, ApiOperationType.Edit));
    this.endpoints.push(new ApiEndpoint(`${route}/${variableName}`, ApiOperationType.Merge));
    this.endpoints.slice(-1)[0].documentation = ApiDocumentation.getMergeDocumentationObject(this.documentation.responseDataModelObject, propertyName);
    this.endpoints.push(new ApiEndpoint(`${route}/${variableName}`, ApiOperationType.Patch));
    this.endpoints.slice(-1)[0].documentation = ApiDocumentation.getPatchDocumentationObject(this.documentation.responseDataModelObject, propertyName);
    this.endpoints.push(new ApiEndpoint(`${route}/${variableName}`, ApiOperationType.Delete));
    if (supportCopy) {
      this.endpoints.push(new ApiEndpoint(`${route}${(Helper.contains(route, "?") ? "&" : "?")}source=${variableName}`, ApiOperationType.Copy));
    }
    if (supportExport) {
      this.endpoints.push(new ApiEndpoint(route + `/${m.RouteSegment.Export}${(Helper.contains(route, "?") ? "&" : "?")}sort={sort}&filterId={filterId}&filter={filter}&q={q}&expand={expand}`, ApiOperationType.Export));
    }
    if (supportImport) {
      this.endpoints.push(new ApiEndpoint(route + `/${m.RouteSegment.Import}`, ApiOperationType.Import));
    }
  };


  constructor(id: string) {
    if (id) {
      this.id = id;
    } else {
      this.id = "Unknown";
      console.error("ApiProperties object created without an id value which is required for proper operations.");
    }
  }



}

export enum ApiOperationType {
  Add,
  Edit,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Patch,
  Merge,
  Delete,
  Get,
  List,
  Copy,
  Export,
  Import,
  Call,
  Report
}

export enum ApiMethodType {
  Get,
  Post,
  Put,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Patch,
  Merge,
  Delete
}

export class ApiEndpoint {

  /**
  The id for this endpoint.  This is most helpful when there are multiple endpoints of the same operation type.
  For example, there may be multiple function calls or reports available for a given api.  If specified, this should
  match the back-end api endpoint id used for things like logging, referencing api configurations, etc.
  */
  public id: string = "";

  /**
  The path fragment of the url for this endpoint.
  */
  public path: string = "";

  /**
  The api endpoint operation type.
  */
  public type: ApiOperationType;

  /**
  The api endpoint method type.
  */
  public method: ApiMethodType;

  /**
  If the path has variables that are not defined in ApiProperties and are not default
  variables used in list operation types they are specified here wrapped in {} parameter markers.
  e.g. ["{assetId}", "{language}", "{draft}", "{newVersion}", "{copyFeedback}"]
  */
  public pathVariables: string[] = [];
  /**
  If path variables exist this is the companion array of path model properties that map to those variables.
  e.g. ["AssetId", "Language", "Draft", "NewVersion", "CopyFeedback"]
  */
  public pathModelProperties: string[] = [];

  /**
  For api endpoints that want to have customized documentation in the api docs this object holds the information
  that will help facilitate that.
  */
  public documentation: ApiDocumentation;

  /**
  If the api endpoint should be excluded from automated testing this should be set to true.
  */
  public excludeFromAutomatedTesting: boolean = false;

  constructor(path: string, type: ApiOperationType, id?: string, method?: ApiMethodType) {
    this.path = path;
    this.type = type;
    if (id) {
      this.id = id;
    }
    if (method) {
      this.method = method;
    } else {
      switch (this.type) {
        case ApiOperationType.Add:
          this.method = ApiMethodType.Post;
          break;
        case ApiOperationType.Edit:
          this.method = ApiMethodType.Put;
          break;
        case ApiOperationType.Patch:
          this.method = ApiMethodType.Patch;
          break;
        case ApiOperationType.Merge:
          this.method = ApiMethodType.Merge;
          break;
        case ApiOperationType.Delete:
          this.method = ApiMethodType.Delete;
          break;
        case ApiOperationType.Get:
          this.method = ApiMethodType.Get;
          break;
        case ApiOperationType.List:
          this.method = ApiMethodType.Get;
          break;
        case ApiOperationType.Copy:
          this.method = ApiMethodType.Post;
          break;
        case ApiOperationType.Export:
          this.method = ApiMethodType.Get;
          break;
        case ApiOperationType.Import:
          this.method = ApiMethodType.Post;
          break;
        case ApiOperationType.Call:
          this.method = ApiMethodType.Post;
          break;
        case ApiOperationType.Report:
          this.method = ApiMethodType.Get;
          break;
        default:
          Log.errorMessage(`Not able to parse api endpoint operation type ${this.type} to api endpoint method type.`);
          break;
      }
    }
  }

}

/**
Legacy ApiMethod ... moving toward ApiEndpoint
*/
export class ApiMethod {
  Path: string = "";
  Method: string = "";
  Type: string = "";
  constructor(path: string = "", method: string = "", methodType: string = "") {
    this.Path = path || "";
    this.Method = method || "";
    this.Type = methodType || "";
  }
}



/**
Basic api documentation common to api object documentation and api endpoint documentation.
*/
export class ApiDocumentationCommon {

  private _documentationUrlBase: string = "";
  public get documentationUrlBase(): string {
    return this._documentationUrlBase;
  }
  public set documentationUrlBase(value: string) {
    // Force us to end with trailing slash so we have a common format
    if (!Helper.endsWith(value, "/")) {
      value += "/";
    }
    this._documentationUrlBase = value;
  }

  /**
  When true the api is read-only for all but system administrators.
  */
  public readOnly: boolean = false;


  // objectName, objectDescription, objectDescriptionPlural are all helpers for various scenarios
  // in documentation, logging, etc. but we don't want to get fanatical about all three of them
  // needing to be assigned for every object so generally speaking if you give us one of them
  // we can figure out how to give some meaning value for all three which is why they are stored
  // in private vars and use getters and setters

  private _objectDescription: string = "";
  public get objectDescription(): string {
    if (this._objectDescription) {
      return this._objectDescription;
    } else if (this._objectName) {
      return Helper.formatIdentifierWithSpaces(this._objectName);
    } else if (this._objectDescriptionPlural) {
      return this._objectDescriptionPlural;
    } else {
      return "";
    }
  }
  public set objectDescription(description: string) {
    this._objectDescription = description;
  }

  // We offer a default plural description so the plural description is a private var with get/set methods
  private _objectDescriptionPlural: string = "";
  /**
  A user friendly plural description of the object.
  */
  public get objectDescriptionPlural(): string {
    if (this._objectDescriptionPlural) {
      return this._objectDescriptionPlural;
    }
    let description = this._objectDescription;
    if (!description) {
      description = Helper.formatIdentifierWithSpaces(this._objectName);
    }
    return Helper.plural(description);
  }
  public set objectDescriptionPlural(pluralDescription: string) {
    this._objectDescriptionPlural = pluralDescription;
  }

  // We offer a default object name of removing spaces from the description so the object name is a private var with get/set methods
  private _objectName: string = "";
  /**
  The object name.  Defaults to the Description with spaces removed.  For example, a description of "Credit Card" will have a default object name of "CreditCard".
  */
  public get objectName(): string {
    if (this._objectName) {
      return this._objectName;
    } else {
      return Helper.replaceAll(this._objectDescription, " ", ""); // this.Description.replace(" ", "");
    }
  }
  public set objectName(name: string) {
    this._objectName = name;
  }

  public objectPrimaryKey: string | string[] = "";

  public objectDescriptionPropertyNames: string[] = [];



  // Use private with getter and setter for security access area so we can render a best guess if not set
  private _securityAccessArea: string = "";
  /**
  The object name.  Defaults to the Description with spaces removed.  For example, a description of "Credit Card" will have a default object name of "CreditCard".
  */
  public get securityAccessArea(): string {
    if (this._securityAccessArea) {
      return this._securityAccessArea;
    } else {
      let guess: string = Helper.getFirstDefinedString(this.objectName, this.responseDataModelDocumentationName, this._objectDescription);
      guess = Helper.replaceAll(guess, " ", "");
      // public static Contact: string = "_Contact-CONTACT";
      // public static ContactList: string = "_ContactListViewModel~ContactListViewModel";
      if (guess && guess.startsWith("_")) {
        if (guess.indexOf("-") > -1) {
          guess = guess.substr(guess.indexOf("-") + 1);
        } else if (guess.indexOf("~") > -1) {
          guess = guess.substr(guess.indexOf("~") + 1);
          guess = Helper.replaceAll(guess, "ListViewModel", "");
          guess = Helper.replaceAll(guess, "EditViewModel", "");
          guess = Helper.replaceAll(guess, "ViewModel", "");
          guess = Helper.replaceAll(guess, "Model", "");
        }
      }
      return guess;
    }
  }
  public set securityAccessArea(accessArea: string) {
    this._securityAccessArea = accessArea;
  }



  public requestDataModelDocumentationName: string = "";
  public responseDataModelDocumentationName: string = "";
  public set requestAndResponseDataModelDocumentationName(name: string) {
    this.requestDataModelDocumentationName = name;
    this.responseDataModelDocumentationName = name;
  }

  public requestDataModelIsNull: boolean = false;
  public requestDataModelObject: any;
  public requestDataModelObjectFromDataModelDocumentation: boolean = false;
  public responseDataModelIsNull: boolean = false;
  private _responseDataModelObject: any;
  public get responseDataModelObject(): any {
    if (this._responseDataModelObject) {
      return this._responseDataModelObject;
    } else {
      return this.requestDataModelObject;
    }
  }
  public set responseDataModelObject(responseObject: any) {
    this._responseDataModelObject = responseObject;
  }
  public responseDataModelObjectFromDataModelDocumentation: boolean = false;
  public set requestAndResponseDataModelObject(object: any) {
    this.requestDataModelObject = object;
    this._responseDataModelObject = object;
  }

}


/**
For api endpoints that want to have customized documentation in the api docs this object holds the information
that will help facilitate that.
*/
export class ApiDocumentation extends ApiDocumentationCommon {

  public menuText: string = "";
  public title: string = "";

  public showOverview: boolean = true;
  public overviewText: string = "";
  public overviewTemplateUrl: string = "";
  public showOverviewRequestDataModel: boolean = false;
  public showOverviewResponseDataModel: boolean = true;

  public showApiEndpointConfig: boolean = true;
  public apiEndpointConfigSampleRequestObjectPropertyName: string = "";
  public apiEndpointConfigSampleResponseObjectPropertyName: string = "";

  public showFormat: boolean = true;
  public formatTemplateUrl: string = "";

  public showResultCodes: boolean = true;
  public resultCodesText: string = "";
  public resultCodesTemplateUrl: string = "";

  public showTest: boolean = true;

  /**
  When true the api documentation test form should suppress prompting for authentication headers.
  */
  public testFormSuppressAuthenticationHeaders: boolean = false;

  /**
  When true the api documentation test form should suppress prompting for encryption headers.
  */
  public testFormSuppressEncryptionHeaders: boolean = false;

  /**
  When true the api documentation test form should suppress content type headers.
  */
  public testFormSuppressContentTypeHeaders: boolean = false;

  /**
  When true the api documentation test form should suppress api version headers.
  */
  public testFormSuppressApiVersionHeaders: boolean = false;

  /**
  When true the api documentation test form should suppress local device headers.
  */
  public testFormSuppressLocalDeviceHeaders: boolean = false;

  /**
  When true the api documentation test form should suppress prompting for properties documented as
  owner keys in the data model as part of a get operation.
  */
  public testFormSuppressPromptForOwnerKey: boolean = false;

  /**
  When true the api documentation test form should use an anonymous object with path model properties
  for collecting test information.
  */
  public testFormUseAnonymousObjectForPathModelProperties: boolean = false;

  /**
  If true the api documentation test form path model properties are not included as part of the payload used
  for non-GET requests.  This is most often the case for PATCH and MERGE operations where the id values in the
  api endpoint path are N/A for the payload.
  When false the property is available for both url path value substitution and included in the request data payload provided in non-GET scenarios.
  */
  public testFormPathModelPropertiesExcludedFromPayload: boolean = false;

  /**
  When true the api documentation test form should expose an input control to allow free form
  entry of query string parameters.
  */
  public testFormAllowFreeFormQueryString: boolean = false;

  /**
  A tooltip to use when providing free form query string input on the test form.
  */
  public testFormAllowFreeFormQueryStringTooltip: string = "";

  /**
  When provided this is a collection of objects with property name, type, and input control width
  for scenarios where complete control over the test form is desired or there is no data model
  documentation to pull from and simply using testFormUseAnonymousObjectForPathModelProperties
  does not accomplish the desired results.
  */
  public testFormProperties: ApiDocTestFormProperty[] = [];

  /**
  When true do not build a test form.  Only use the json editor control for testing.
  */
  public testFormJsonOnly: boolean = false;

  /**
  When true do not offer json input only use the test form.
  */
  public testFormNoJson: boolean = false;

  /**
  For report operation types if there is a property that will be triggering a download then
  specifying that here enables the action button on the test form to decide if it should
  execute the api as normal or assign the url as a new window location so the file can be
  downloaded.
  */
  public pathModelPropertyTriggeringDownload: string = "";

  public testButtonIcon: string = "";
  public testButtonText: string = "";
  public testButtonContextColor: string = "";
  public testButton2Icon: string = "";
  public testButton2Text: string = "";
  public testButton2ContextColor: string = "";
  public testButton2Action: string = "";

  public pages: ApiDocPage[] = [];

  /**
  Helper to return doc object for merge operation.
  */
  public static getMergeDocumentationObject(responseObject: any, keyLabel: string, keyType: ApiDocTestFormPropertyType = ApiDocTestFormPropertyType.Number): ApiDocumentation {
    // Merge has same doc layout as patch except request format is empty object
    const doc = ApiDocumentation.getPatchDocumentationObject(responseObject, keyLabel, keyType);
    doc.requestDataModelObject = {};
    return doc;
  }

  /**
  Helper to return doc object for patch operation.
  */
  public static getPatchDocumentationObject(responseObject: any, keyLabel: string, keyType: ApiDocTestFormPropertyType = ApiDocTestFormPropertyType.Number): ApiDocumentation {

    const patchMergeForm: ApiDocTestFormProperty[] = [];
    patchMergeForm.push(new ApiDocTestFormProperty(keyLabel, keyType));
    patchMergeForm.push(new ApiDocTestFormProperty("Body", ApiDocTestFormPropertyType.Json, {}));
    // patchMergeForm.slice(-1)[0].height = 10;
    patchMergeForm.slice(-1)[0].isHttpRequestBody = true;

    const doc: ApiDocumentation = new ApiDocumentation();
    doc.requestDataModelObject = [new Patch(), new Patch()];
    doc.responseDataModelObject = responseObject;
    doc.testFormProperties = patchMergeForm;
    doc.testFormNoJson = true;

    return doc;

  }

}

export class ApiDocPage {
  public title: string = "";
  public text: string = "";
  public templateUrl: string = "";
  constructor(title: string = "", text: string = "", templateUrl: string = "") {
    this.title = title || "";
    this.text = text || "";
    this.templateUrl = templateUrl || "";
  }
}

export class ApiDocTestFormProperty {
  public name: string = "";
  public type: ApiDocTestFormPropertyType = ApiDocTestFormPropertyType.Text;
  public label: string = "";
  public inputWidth: ApiDocTestFormPropertyWidth = ApiDocTestFormPropertyWidth.Normal;
  public inputOptions: string[] = [];
  public defaultValue: any = null;
  public isHttpRequestBody: boolean = false;
  public height: number = 5; // for text areas this is the number of rows of text
  /**
  If true then this property is not included as part of the request data object and is only used for url path value substitution.
  When false the property is available for both url path value substitution and included in the request data payload provided in non-GET scenarios.
  */
  public isPathPropertyOnly: boolean = false;
  constructor(name: string = "", type: ApiDocTestFormPropertyType = ApiDocTestFormPropertyType.Text, defaultValue: any = null, label: string = "", inputWidth: ApiDocTestFormPropertyWidth = null, inputOptions: string[] = []) {
    this.name = name || "";
    this.type = type || ApiDocTestFormPropertyType.Text;
    this.defaultValue = defaultValue;
    this.label = label || Helper.formatIdentifierWithSpaces(name);
    if (this.type === ApiDocTestFormPropertyType.Number) {
      // Default input control width for numbers is short
      this.inputWidth = inputWidth || ApiDocTestFormPropertyWidth.Short;
    } else {
      this.inputWidth = inputWidth || ApiDocTestFormPropertyWidth.Normal;
    }
    this.inputOptions = inputOptions || [];
  }
}

export enum ApiDocTestFormPropertyType {
  Text,
  TextArea,
  Json,
  Number,
  Bool,
  Select
}

export enum ApiDocTestFormPropertyWidth {
  Normal,
  Medium,
  Short,
  Tiny
}

export class ApiDocDataModel {
  public Name: string = "";
  public Description: string = "";
  public Notes: string = "";
  public Category: string = "";
  public Legacy: boolean = false;
  public Columns: ApiDocDataModelColumn[] = [];
  public RawName: string = "";
}

export class ApiDocDataModelColumn {
  public Name: string = "";
  public DataType: string = "";
  public SubDataType: string = "";
  public DefaultValue: string = "";
  public ColumnProperty: number = 0;
  public IsPrimaryKey: boolean = false;
  public IsNaturalPrimaryKey: boolean = false;
  public IsForeignKey: boolean = false;
  public IsOwnerKey: boolean = false;
  public IsSurrogateKey: boolean = false;
  public NotNull: boolean = false;
  public Unique: boolean = false;
  public CheckConstraint: string = "";
  public Description: string = "";
  public Notes: string = "";
  public AdditionalNotes: string = "";
  public Validation: number = 0;
  public ColumnCheck: any = null;
  public Properties: ApiDocDataModelColumn[] = [];
  public PropertiesLazyLoaded: boolean = false;
  public PropertiesCollapsed: boolean = true;
  public Options: any[] = [];
  public IgnoreOptionsListWhenCheckingValidity: boolean = false;
  public OptionsListAsDescription: string = "";
  public Attributes: string = "";
  public MaximumLength: number = 0;
  public UnrestrictedBeginningCharacterCount: number = null;
  public UnrestrictedEndingCharacterCount: number = null;
  public IsReadOnly: boolean = false;
  public IsFileContentsBase64: boolean = false;
  public IsMetaData: boolean = false;
  public IsObject: boolean = false;
  public IsCollection: boolean = false;
  public IsBoolean: boolean = false;
  public IsDateTime: boolean = false;
  public IsRowVersion: boolean = false;
  public IsNumeric: boolean = false;
  public IsInteger: boolean = false;
  public IsFloat: boolean = false;
  public IsString: boolean = false;
  public IsChar: boolean = false;
  public IsVarCharMax: boolean = false;
  public IsText: boolean = false;
  public IsBinary: boolean = false;
  public RawName: string = "";
}

export class ApiRelationship {
  public apiName: string = "";
  public parent: string = "";
  public children: string[] = [];
}

export class ApiDocTestResult {
  public requestMethod: string = "";
  public requestUrl: string = "";
  public requestHeaders: any = null;
  public requestData: any = null;
  public responseStatus: number = 0;
  public responseStatusText: string = "";
  public responseHeaders: any = null;
  public responseData: any = null;
}

export enum CacheLevel {
  Static = 1,
  PseudoStatic = 2,
  ChangesInfrequently = 5,
  ChangesOften = 8,
  Volatile = 10,
  None = 999
}

export class ApiHost {
  description: string = "";
  code: string = "";
  url: string = "";
  proxyUrl?: string = "";
  proxyDataSourceId?: string = "";
  isProduction: boolean = false;
  isSandbox: boolean = false;
  comments: string = "";
}




export class ApiCall {

  /**
  Uniquely identifies a specific api call object.
  */
  id: string = Helper.createBase36Guid();
  baseUrl: string = "";
  fragmentUrl: string = "";
  url: string = "";

  /**
  The api operation type
  */
  type: ApiOperationType;
  typeName: string;
  endpointId: string;

  /**
  The api method type.
  */
  method: ApiMethodType;
  methodName: string;

  version: number = 5;
  usingProxy: boolean = false;
  withCredentials: boolean = false;
  token: string = "";
  partnerToken: string = "";
  apiKey: string = "";
  language: string = "";
  meta: string[] = [];
  trace: boolean = false;
  overwriteChanges: boolean = false;
  httpResponseOverride: boolean = false;
  httpMethodOverride: boolean = false;
  // localIpAddress: string = "";
  localDeviceId: string = "";
  responseProperties: string = "";
  encryptionKeyTag: string = "";
  encryptedProperties: string = "";
  pathVariables: string[] = [];
  pathModelProperties: string[] = [];
  objectName: string = "";
  objectPrimaryKey: string | string[];
  objectShortDescription: string = "";
  cacheName: string;
  cacheLevel: CacheLevel = CacheLevel.None;
  /**
   * Optional function which accepts an IApiResponseWrapper interface and returns a CacheLevel
   * enum setting for the cache level to utilize.  If CacheLevel.None is returned then the
   * response will not be cached.  This can be utilized to adjust the cache level dynamically
   * based on the response.
   */
  cacheLevelCheck: (response: IApiResponseWrapper) => CacheLevel;
  cacheUseStorage: boolean = false;
  /**
   * We typically build the cache key based on object type and keys but in some cases want to use custom cache keys.
   */
  cacheKey: string = "";
  /**
   * We have object version tracking so it's not really acceptable to use a cached value when editing
   * an object.  If we're loading an object for edit purposes we tell it to ignore the cache for the
   * load operation so we have the right version stamp on the object.
   */
  cacheIgnoreOnRead: boolean = false;
  /**
   * If we want to ignore caching of a write operation set this to true.
   */
  cacheIgnoreOnWrite: boolean = false;
  /**
   * List of pick list id values impacted by this api.  When populated any write operations will dump cache
   * for these pick list ids so they can be reloaded on the next reference.
   */
  impactedPickListIds: string[] = [];
  silent: boolean = false;
  redirectToLoginOnAuthenticationErrors: boolean = true;
}

/**
Used in our endpoint documentation
*/
export class ApiEndpointViewModel {
  apiName: string = "";
  objectName: string = "";
  objectDescription: string = "";
  endpointDescription: string = "";
  documentationUrl: string = "";
  path: string = "";
  operationType: ApiOperationType = null;
  operationId: string = "";
  method: string = "";
  type: string = "";
}

/**
 * Used in api docs
 **/
export interface DataModelList {
  Name: string;
  Type: string;
  Category: string;
}


export interface DataModel {
  Name: string;
  Description: string;
  Notes: string;
  Category: string;
  Legacy: boolean;
  Columns: DataModelProperty[];
  RawName: string;
}

export interface DataModelProperty {
  Name: number;
  NameAlias: string;
  DataType: string;
  DataTypeType: number;
  DataTypeCode: number;
  SubDataType: string;
  DefaultValue: string;
  ColumnProperty: number;
  IsPrimaryKey: boolean;
  IsNaturalPrimaryKey: boolean;
  IsForeignKey: boolean;
  IsOwnerKey: boolean;
  IsOwnersOwnerKey: boolean;
  IsSurrogateKey: boolean;
  NotNull: boolean;
  Unique: boolean;
  CheckConstraint: string;
  Description: string;
  Notes: string;
  AdditionalNotes: string;
  Validation: number;
  ColumnCheck: null,
  Properties: DataModelProperty[];
  PropertiesLazyLoaded: boolean;
  PropertiesCollapsed: boolean;
  MappedName: string;
  Flagged: boolean;
  VersionConflict: number;
  Options: DataModelPropertyOption[];
  IgnoreOptionsListWhenCheckingValidity: boolean;
  OptionsListAsDescription: string;
  Attributes: string;
  MaximumLength: number;
  UnrestrictedBeginningCharacterCount: number;
  UnrestrictedEndingCharacterCount: number;
  IsReadOnly: boolean;
  IsFileContentsBase64: boolean;
  IsMetaData: boolean;
  IsObject: boolean;
  IsCollection: boolean;
  IsBoolean: boolean;
  IsDateTime: boolean;
  IsRowVersion: boolean;
  IsNumeric: boolean;
  IsInteger: boolean;
  IsInteger64: boolean;
  IsInteger32: boolean;
  IsInteger16: boolean;
  IsInteger8: boolean;
  IsFloat: boolean;
  IsChar: boolean;
  IsVarCharMax: boolean;
  IsText: boolean;
  IsString: boolean;
  IsBinary: boolean;
  RawName: string;
  OriginalColumn: DataModelProperty;
  OriginalColumnMajorChange: number;
  TransformSqlOutToModel: string;
  TransformSqlInFromModel: string;
  CanInsert: boolean;
  CanInsertOnSync: boolean;
  CanUpdate: boolean;
  CanUpdateOnSync: boolean;
}

export interface DataModelPropertyOption {
  Value: string;
  Label: string;
  Description: string;
  Default: boolean;
}




export interface IApiResponseScope {
  TotalResults: number;
  IncludedResults: number;
  PageNumber: number;
  PageSize: number;
  PageCount: number;
  Sort: string;
  Filter: string;
  FilterId: number;
  Q: string;
  Expand: string;
}

export class ApiResponseScope implements IApiResponseScope {
  TotalResults: number = 0;
  IncludedResults: number = 0;
  PageNumber: number = 1;
  PageSize: number = 10;
  PageCount: number = 1;
  Sort: string = "";
  Filter: string = "";
  FilterId: number = null;
  Q: string = "";
  Expand: string = "";
}

export interface IApiResponseLink {
  Rel: string;
  Url: string;
  Method: string;
}

export class ApiResponseLink implements IApiResponseLink {
  Rel: string;
  Url: string;
  Method: string;
}

export interface IApiResponseError {
  ResultCode: number;
  Type: string;
  Subtype: string;
  Reference: string;
  Message: string;
  Details: string;
  Warning: boolean;
}

export class ApiResponseError implements IApiResponseError {
  ResultCode: number;
  Type: string;
  Subtype: string;
  Reference: string;
  Message: string;
  Details: string;
  Warning: boolean;
}

export interface IApiResponseTyped<TData> {
  Success: boolean;
  ResultCode: number;
  ResultText: string;
  Message: string;
  Scope: IApiResponseScope;
  Data: TData;
  Meta: any;
  Links: IApiResponseLink[];
  Errors: IApiResponseError[];
  TimeStamp: string;
  Trace: string[];
}

export interface IApiResponse {
  Success: boolean;
  ResultCode: number;
  ResultText: string;
  Message: string;
  Scope: IApiResponseScope;
  Data: any;
  Meta: any;
  Links: IApiResponseLink[];
  Errors: IApiResponseError[];
  TimeStamp: string;
  Trace: string[];
}

export class ApiResponse implements IApiResponse {
  Success: boolean;
  ResultCode: number;
  ResultText: string;
  Message: string;
  Scope: IApiResponseScope;
  Data: any;
  Meta: any;
  Links: IApiResponseLink[] = [];
  Errors: IApiResponseError[] = [];
  TimeStamp: string;
  Trace: string[] = [];
}

export interface IApiRequestInformation {
  Url: string;
  Method: string;
  Headers: any;
  Data: any;
}

export class ApiRequestInformation implements IApiRequestInformation {
  Url: string;
  Method: string;
  Headers: any;
  Data: any;
}

export interface IApiResponseWrapperTyped<TData> {
  Data: IApiResponseTyped<TData>;
  Status: number;
  StatusText: string;
  Headers: any;
  Request: IApiRequestInformation;
}

export interface IApiResponseWrapper {
  Data: IApiResponse;
  Status: number;
  StatusText: string;
  Headers: any;
  Request: IApiRequestInformation;
}

export class ApiResponseWrapper implements IApiResponseWrapper {
  Data: IApiResponse;
  Status: number;
  StatusText: string;
  Headers: any;
  Request: IApiRequestInformation;
}

export class Patch {
  op: string = "";
  from: string;
  path: string = "";
  value: string = "";
}

// export interface IMetaData {
//   AddedDateTime: Date;
//   AddedByContactId: number;
//   AddedByContactName: string;
//   LastEditedDateTime: Date;
//   LastEditedByContactId: number;
//   LastEditedByContactName: string;
//   MarkedForDeletionDateTime: Date;
//   MarkedForDeletionByContactId: number;
//   MarkedForDeletionByContactName: string;
//   CurrentRowVersion: string;
//   UtcOffset: number;
//   TimeZoneDescription: string;
// }

// // Our models are begging to use a base class like this but we use the model
// // object in our API documentation and we need the property order to be a
// // little more logically laid out in the documentation ... so copy & paste.
// export class MetaData implements IMetaData {
//   AddedDateTime: Date = null;
//   AddedByContactId: number = 0;
//   AddedByContactName: string = "";
//   LastEditedDateTime: Date = null;
//   LastEditedByContactId: number = 0;
//   LastEditedByContactName: string = "";
//   MarkedForDeletionDateTime: Date = null;
//   MarkedForDeletionByContactId: number = 0;
//   MarkedForDeletionByContactName: string = "";
//   CurrentRowVersion: string = "";
//   UtcOffset: number = 0;
//   TimeZoneDescription: string = "UTC/GMT (+00:00)";
// }




export interface IQuery {
  Page: number;
  Size: number;
  Sort: string;
  FilterId: number;
  Filter: string;
  Q: string;
  Expand: string;
}

export class Query implements IQuery {
  Page: number = 1;
  Size: number = Constants.RowsToReturn.Default;
  Sort: string = "";
  FilterId: number = null;
  Filter: string = "";
  Q: string = "";
  Expand: string = "";
  constructor(defaultSort: string = "", size: number = Constants.RowsToReturn.Default) {
    this.Sort = defaultSort || "";
    if (size) {
      this.Size = size;
    }
  }
}

export class ContactChildQuery extends Query implements IQuery {
  public static ContactSort: string = "ContactName,LastName,FirstName";
  public static InventorySort: string = "Description,TrackingNumber";
  public static RoleSort: string = "RoleId";
  public static WorkScheduleSort: string = "ValidStartDateTime";
  public static WorkScheduleExceptionSort: string = "ExceptionStartDateTime";
  public static ExternalAuthenticationSort: string = "AuthenticationService";
  public static AssetAccessLogSort: string = "-AccessDateTime";
  ContactId: number = null;
  ParentContactId: number = null;
}

export class PaymentProviderChildQuery extends Query implements IQuery {
  public static SupportedCardTypeSort: string = "PaymentMethodCardTypeId";
  public static SelectionRuleSort: string = "RuleProcessingOrder";
  public static PaymentProviderTriggerSort: string = "TriggerOrder";
  PaymentProviderId: number = null;
}

export class AssetChildQuery extends Query implements IQuery {
  public static TagSort: string = "Tag";
  public static ReferenceSort: string = "Title";
  public static RelatedSort: string = "Title";
  public static SelectionSort: string = "Description";
  public static VisibilitySort: string = "VisibilityType";
  public static AccessLogSort: string = "-AccessDateTime";
  public static FeedbackSort: string = "-FeedbackDateTime";
  public static FileSort: string = "FileName";
  AssetId: number = null;
}

export class CaseChildQuery extends Query implements IQuery {
  public static TagSort: string = "Tag";
  public static FeedbackSort: string = "-FeedbackDateTime";
  public static FileSort: string = "FileName";
  CaseId: number = null;
  CaseTaskListId: number = null;
  CaseTaskId: number = null;
}

export class CaseTemplateChildQuery extends Query implements IQuery {
  public static TagSort: string = "Tag";
  public static FeedbackSort: string = "-FeedbackDateTime";
  public static FileSort: string = "FileName";
  CaseTemplateId: number = null;
  CaseTemplateTaskListId: number = null;
  CaseTemplateTaskId: number = null;
}

export class ReportCompilerFileChildQuery extends Query implements IQuery {
  public static NoteSort: string = "-NoteDateTime";
  public static AttachmentSort: string = "FileName";
  FileId: number = null;
  FileNoteId: number = null;
}

export class ReportCompilerFileTemplateChildQuery extends Query implements IQuery {
  public static NoteSort: string = "-NoteDateTime";
  public static AttachmentSort: string = "FileName";
  FileTemplateId: number = null;
  FileTemplateTaskListId: number = null;
}

export class AttributeSetChildQuery extends Query implements IQuery {
  public static ConfigurationSort: string = "AttributeName";
  public static RawAttributeSort: string = "Value001";
  public static AttributeSort: string = "AttributeId";
  AttributeSetId: number = null;
}

export class PartitionChildQuery extends Query implements IQuery {
  public static ServiceSort: string = "Description";
  public static DomainSort: string = "Description";
  public static ApiAccessSort: string = "Description";
  public static ApiAccessClientSort: string = "Description";
  PartitionId: number = null;
}

export class PickListListQuery extends Query implements IQuery {
  PickListCategoryId: number = null;
  Sort: string = "DisplayOrder";
}

export class PickListValueListQuery extends Query implements IQuery {
  PickListId: string = null;
  Sort: string = "DisplayText";
}

export class InputOptionsListQuery {
  ObjectName: string = "";
  PropertyName: string = "";
}

export class InputPickListQuery {
  PickListId: string = "";
}

export class CustomPickListQuery {
  CustomPickListPrefix: string = "";
}

export class RoleDetailListQuery extends Query implements IQuery {
  RoleId: number = null;
  Sort: string = "SecurityArea";
}



export class FormControlGroupListQuery extends Query implements IQuery {
  FormId: number = null;
  Sort: string = "Description";
}

export class FormControlListQuery extends Query implements IQuery {
  FormId: number = null;
  FormControlGroupId: number = null;
  Sort: string = "ControlOrder";
}


