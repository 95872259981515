// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-input {
  margin-left: -0.25rem;
}
.form-check-label {
  margin-left: 1rem;
}

.hover-outline:hover {
  border-color: #505050;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-checkbox/input-checkbox.component.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".form-check-input {\r\n  margin-left: -0.25rem;\r\n}\r\n.form-check-label {\r\n  margin-left: 1rem;\r\n}\r\n\r\n.hover-outline:hover {\r\n  border-color: #505050;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
