// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check {
  margin-top: 8px;
}

.form-check-input {
  margin-left: -0.25rem;
}

.form-check-input-inline {
  margin-left: 0;
}

.form-check-label {
  margin-left: 1rem;
}

.form-check-label-inline {
  margin-left: 0.2rem;
}
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/input/input-radio/input-radio.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".form-check {\r\n  margin-top: 8px;\r\n}\r\n\r\n.form-check-input {\r\n  margin-left: -0.25rem;\r\n}\r\n\r\n.form-check-input-inline {\r\n  margin-left: 0;\r\n}\r\n\r\n.form-check-label {\r\n  margin-left: 1rem;\r\n}\r\n\r\n.form-check-label-inline {\r\n  margin-left: 0.2rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
