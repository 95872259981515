import { Injectable } from '@angular/core';
import { Helper, Log } from "../helpers/helper";
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { Api } from '../api/Api';
import { ApiHelper } from '../api/ApiHelper';
import { ApiOperationType } from '../api/ApiModels';
import { ApiService } from '../api/api.service';
import { App } from '../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class AppAnalyticsService {

  /*
   * Settings passed in from the user object when it is set.  This provides information
   * about user preferences for the type and depth of analytics to include.
   */
  settings: m5sec.UserSettingsApplicationSecurityPreferences = null;

  /*
   * Default settings to use when no user settings are available.
   */
  defaultSettings: m5sec.UserSettingsApplicationSecurityPreferences = null;

  /*
   * Our last known inspectlet session id.
   */
  lastKnownInspectletSessionId: number = null;


  googleAnalyticsDisabled = false;
  inspectletDisabled = false;


  constructor(
    protected apiService: ApiService
  ) {
    this.defaultSettings = new m5sec.UserSettingsApplicationSecurityPreferences();
    this.defaultSettings.ExternalErrorTracking = "Anonymous";
    this.defaultSettings.ExternalAnalyticsForUsage = "Anonymous";
    this.defaultSettings.ExternalAnalyticsForScreenCapture = "None";
    this.defaultSettings.InAppAnalyticsForUsage = "Anonymous";
    this.settings = Helper.deepCopy(this.defaultSettings);
  }


  public isGoogleAnalyticsConfigured(): boolean {

    // See if Google Analytics is configured
    if (!AppConfig?.analytics?.googleAnalytics?.trackingId) {
      return false;
    }

    // See if Google Analytics is enabled
    if (!AppConfig?.analytics?.googleAnalytics?.enabled) {
      return false;
    }

    return true;

  }


  public isInAppAnalyticsEnabled(): boolean {
    if (!AppConfig?.analytics?.inAppAnalytics?.enabled) {
      return false;
    }

    return true;
  }


  public isGoogleAnalyticsAvailable(): boolean {

    // See if Google Analytics is configured
    if (!AppConfig?.analytics?.googleAnalytics?.trackingId) {
      return false;
    }

    // See if Google Analytics is enabled
    if (!AppConfig?.analytics?.googleAnalytics?.enabled) {
      return false;
    }

    // See if we're localhost and if localhost is blocked from analytics
    if (this.isLocalHostAndLocalHostBlocked()) {
      return false;
    }

    // See if we're always on
    if (AppConfig.analytics.googleAnalytics.alwaysOn) {
      return true;
    }

    // Check our opt-in/out status us
    if (Helper.equals(this.settings.ExternalAnalyticsForUsage, "None", true)) {
      return false;
    }

    return true;

  }


  public isInspectletConfigured(): boolean {

    // See if Inspectlet is configured
    if (!AppConfig?.analytics?.inspectlet?.applicationId) {
      return false;
    }

    // See if Inspectlet is enabled
    if (!AppConfig?.analytics?.inspectlet?.enabled) {
      return false;
    }

    return true;

  }


  public isInspectletAvailable(): boolean {

    // See if Inspectlet is configured
    if (!AppConfig?.analytics?.inspectlet?.applicationId) {
      return false;
    }

    // See if Inspectlet is enabled
    if (!AppConfig?.analytics?.inspectlet?.enabled) {
      return false;
    }

    // See if we're localhost and if localhost is blocked from analytics
    if (this.isLocalHostAndLocalHostBlocked()) {
      return false;
    }

    // See if we're always on
    if (AppConfig.analytics.inspectlet.alwaysOn) {
      return true;
    }

    // Check our opt-in/out status us
    if (Helper.equals(this.settings.ExternalAnalyticsForScreenCapture, "None", true)) {
      return false;
    }

    return true;

  }


  public isLocalHostAndLocalHostBlocked(): boolean {
    if ((location.host.indexOf("localhost") > -1 || location.host.indexOf("localtest.me") > -1) && !AppConfig.analytics.includeLocalhost) {
      return true;
    }
    return false;
  }


  public setPath(path: string): void {
    if (this.isGoogleAnalyticsAvailable()) {
      (window as any).gtag("config", AppConfig.analytics.googleAnalytics.trackingId, { "page_path": path });
    }
  }



  public setUser(user: m5sec.AuthenticatedUserViewModel): void {

    if (!user) {
      return;
    }

    // Save user preferences for analytics so we can decide what to include
    this.settings = user?.Settings?.ApplicationSecurityPreferences;
    if (!this.settings) {
      this.settings = Helper.deepCopy(this.defaultSettings);
    }

    // Check our opt-in/out status us
    if (Helper.equals(this.settings.ExternalAnalyticsForUsage, "None", true)) {
      // Google Analytics mechanism for disabling analytics.
      // Per Google: "When Google Analytics attempts to set a cookie or send data back to the Google Analytics servers,
      // it will first check if this property is set, and will take no action if the value is set to true."
      // window['ga-disable-GA_MEASUREMENT_ID'] = true;
      window[`ga-disable-${AppConfig.analytics.googleAnalytics.trackingId}`] = true;
      this.googleAnalyticsDisabled = true;
      Log.debugMessage(`Google Analytics disabled by user preference being set to none.`);
    } else if (this.googleAnalyticsDisabled && this.isGoogleAnalyticsConfigured()) {
      // This was disabled but now it is enabled again
      window[`ga-disable-${AppConfig.analytics.googleAnalytics.trackingId}`] = false;
      this.googleAnalyticsDisabled = false;
      Log.debugMessage(`Google Analytics enabled by user preference being set to ${this.settings.ExternalAnalyticsForUsage}.`);
    }

    // We can't try to interact with the script in index.html unless it's configured, otherwise these
    // window variables won't be available and the js won't run
    if (this.isInspectletConfigured()) {
      if (Helper.equals(this.settings.ExternalAnalyticsForScreenCapture, "None", true)) {
        (window as any).__insp["wid"] = -1;
        // console.error((window as any).__insp);
        this.inspectletDisabled = true;
        Log.debugMessage(`Inspectlet disabled by user preference being set to none.`);
      } else if (this.inspectletDisabled && this.isInspectletConfigured()) {
        //  __insp.push(['wid', AppConfig.analytics.inspectlet.applicationId]);
        (window as any).__insp['wid'] = AppConfig.analytics.inspectlet.applicationId;
        // console.error((window as any).__insp);
        this.inspectletDisabled = false;
        Log.debugMessage(`Inspectlet enabled by user preference being set to ${this.settings.ExternalAnalyticsForScreenCapture}.`);
      }
    }

    const id = `${user.ContactId} [P${user.PartitionId}]`;
    const name = `${user.ContactName} (${user.ContactId}) [P${user.PartitionId}]`;

    if (this.isGoogleAnalyticsAvailable()) {
      if (Helper.equals(this.settings.ExternalAnalyticsForUsage, "Identifier", true)) {
        (window as any).gtag("config", AppConfig.analytics.googleAnalytics.trackingId, { "user_id": id });
        Log.debugMessage(`Google Analytics user_id set to "${id}" (configured for identifier)`);
      } else if (Helper.equals(this.settings.ExternalAnalyticsForUsage, "Name", true)) {
        // Drop Name support for better PII protection
        // (window as any).gtag("config", AppConfig.analytics.googleAnalytics.trackingId, { "user_id": name });
        // Log.debugMessage(`Google Analytics user_id set to "${name}" (configured for name)`);
        (window as any).gtag("config", AppConfig.analytics.googleAnalytics.trackingId, { "user_id": id });
        Log.debugMessage(`Google Analytics user_id set to "${id}" (configured for identifier)`);
      }
    }

    if (this.isInspectletAvailable()) {
      if (Helper.equals(this.settings.ExternalAnalyticsForScreenCapture, "Identifier", true)) {
        (window as any).__insp.push(['identify', id]);
        Log.debugMessage(`Inspectlet identify set to "${id}" (configured for identifier)`);
      } else if (Helper.equals(this.settings.ExternalAnalyticsForScreenCapture, "Name", true)) {
        // Drop Name support for better PII protection
        // (window as any).__insp.push(['identify', name]);
        // Log.debugMessage(`Inspectlet identify set to "${name}" (configured for name)`);
        (window as any).__insp.push(['identify', id]);
        Log.debugMessage(`Inspectlet identify set to "${id}" (configured for identifier)`);
      }
      // Update inspectlet session id
      this.updateInspectletSessionId();
    }

  }


  public setVersion(version: string): void {

    if (!version) {
      return;
    }

    if (this.isGoogleAnalyticsAvailable()) {
      (window as any).gtag("config", AppConfig.analytics.googleAnalytics.trackingId, { "version": version });
      Log.debugMessage(`Google Analytics version set to "${version}"`);
    }

    if (this.isInspectletAvailable()) {
      (window as any).__insp.push(['tagSession', { version: version }]);
      Log.debugMessage(`Inspectlet version set to "${version}"`);
      // Update inspectlet session id
      this.updateInspectletSessionId();
    }

  }


  public installAnalytics(): void {

    // Current analytics options of Google and Inspectlet have scripts loaded in index.html
    // so they are technically already installed we just do some configuration here.
    this.setMetaData();

    // Update inspectlet session id
    this.updateInspectletSessionId();

  }


  public updateInspectletSessionId() {
    if (this.isInspectletAvailable()) {
      const inspectletSessionId: number = (window as any).__insp.sid;
      if (inspectletSessionId && this.lastKnownInspectletSessionId !== inspectletSessionId) {
        this.lastKnownInspectletSessionId = inspectletSessionId;
        // TODO should we push this session id somewhere or make it available on help menu?
        Log.debugMessage(`Inspectlet session: https://www.inspectlet.com/dashboard/watchsession/${AppConfig.analytics.inspectlet.applicationId}/${inspectletSessionId}?pn=1&live=true`);
      }
    }
  }


  public setMetaData(): void {

    if (!AppConfig?.analytics?.metaData) {
      return;
    }

    const metaData: any = {};

    for (const key in AppConfig.analytics.metaData) {
      if (Object.hasOwnProperty.call(AppConfig.analytics.metaData, key)) {
        try {
          let value: any = AppConfig.analytics.metaData[key];
          if (typeof value === "string") {
            if (Helper.contains(value, "{", true)) {
              value = Helper.stringFormat(value.toString(), AppConfig);
            }
          }
          metaData[key] = value;
        } catch (error) {
          Log.errorMessage(error);
        }
      }
    }

    if (Helper.isEmpty(metaData)) {
      return;
    }

    if (this.isGoogleAnalyticsAvailable()) {
      (window as any).gtag("config", AppConfig.analytics.googleAnalytics.trackingId, metaData);
      Log.debugMessage(`Google Analytics metadata set to ${JSON.stringify(metaData)}`);
    }

    if (this.isInspectletAvailable()) {
      (window as any).__insp.push(['tagSession', metaData]);
      Log.debugMessage(`Inspectlet metadata set to ${JSON.stringify(metaData)}`);
    }

  }



  /**
   * This will take the passed in data and transfer it into a LogEditViewModel and then make a silent api call to
   * log the analytic. This uses object literals and destructuring, which allows it to be called like this,
   * where the properties have intellisense and typing (due to the interface defined below):
   * this.appService.analytics.logAnalytic({contactId: this.appService.user.ContactId, process: "Report Parser",
   *                                        logInformation: "OnInit"});
   * @param IInAppAnalyticsLog object literal with properties that belong in a LogEditViewModel. Each is optional.
   * @returns
   */
  public logAnalytic({ sourceType = "", sourceId = null, sourceId2 = null, sessionId = "", contactId = null,
                       category = "", process = "", subProcess = "", logInformation = "", logDetail = "",
                       resultCode = 0 }: IInAppAnalyticsLog) {


    if (!this.isInAppAnalyticsEnabled()) {
      return;
    }

    // If this is 'none', then the user opted out so don't send any info.
    if (Helper.equals(this.settings.InAppAnalyticsForUsage, "none", true)) {
      return;
    }

    const log = new m5.LogEditViewModel();
    log.SourceType = sourceType;
    log.SourceId = sourceId;
    log.SourceId2 = sourceId2;
    log.SessionId = sessionId;
    log.ContactId = contactId;
    log.Category = category;
    log.Process = process;
    log.Subprocess = subProcess;
    log.LogInformation = logInformation;
    log.LogDetail = logDetail;
    log.ResultCode = resultCode;

    // Every parameter is optional, so if nothing was passed in, just exit.
    if (Helper.objectEquals(log, new m5.LogEditViewModel())) {
      // console.log('123 analytics exit because nothing was passed in');
      return;
    }

    // Default settings
    log.LogDateTime = new Date();
    log.LogType = "A";

    // If they opted in as 'anonymous', make sure they stay anonymous
    if (Helper.equals(this.settings.InAppAnalyticsForUsage, "anonymous", true)) {
      log.ContactId = null;
    }

    // console.log('123 analytics log before api call: ', log);

    const apiProp = Api.Log();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
    apiCall.silent = true;

    this.apiService.execute(apiCall, log).subscribe((response) => {
      // No errors if not successful
      // console.log('123 analytics log response: ', response);
    });
  }
}


interface IInAppAnalyticsLog {
  sourceType?: string;
  sourceId?: number;
  sourceId2?: string;
  sessionId?: string;
  contactId?: number;
  category?: string;
  process?: string;
  subProcess?: string;
  logInformation?: string;
  logDetail?: string;
  resultCode?: number;
}
