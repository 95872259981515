import { ErrorHandler, Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const AppConfig: IAppConfig;
import { IAppConfig } from "projects/core-lib/src/lib/config/AppConfig";
import { TrackJS, TrackJSInstallOptions } from "trackjs";
import { Log, Helper } from '../helpers/helper';
import { AppAnalyticsService } from './app-analytics.service';
import { GlobalErrorHandler } from './global-error-handler';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    protected error: ErrorHandler, // this will resolve to GlobalErrorHandler (see app module provides collection)
    protected analytics: AppAnalyticsService) {
  }

  /**
   * This should return a promise.
   * https://www.tektutorialshub.com/angular/angular-how-to-use-app-initializer/
   */
  Init() {

    return new Promise<void>((resolve, reject) => {

      // console.error("AppInitService.init() called");

      // Install TrackJS
      (this.error as GlobalErrorHandler).installErrorHandler();

      // Install Analytics
      this.analytics.installAnalytics();

      // console.error("AppInitService.init() done");
      resolve();

    });
  }


}
