import { Injectable } from '@angular/core';
import { ApiCall, ApiOperationType, CacheLevel } from 'projects/core-lib/src/lib/api/ApiModels';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { BaseService } from './base.service';
// import { ReturnType } from '@ngx-cache/core/src/models/return-type';
import { NgForage, Driver, NgForageCache, NgForageConfig, CachedItem, InstanceFactory } from 'ngforage';
import { ApiHelper } from '../api/ApiHelper';

@Injectable({
  providedIn: 'root'
})
export class AppCacheService extends BaseService {

  /**
   *  Max entries allowed in the cache. We did some testing and looked at the memory footprint for this
   *  and 100 is a good default. It will vary greatly depending on what is getting cached, but should be
   *  within 1 to 5 MB which we felt was acceptable.
   */
  public maxEntries: number = 100;

  /** This is the local cache. On page refresh, it will lose its data as the class is reset, but that is fine. */
  private localCache: Map<string, LocalCache> = new Map<string, LocalCache>();


  constructor(
    protected readonly ngf: NgForage,
    protected readonly ngcache: NgForageCache) {

    super();

    // console.error("NgForageFactory");
    // console.error("ngcache", this.ngcache);
    /// / @ts-ignore
    // const instance = new InstanceFactory(this.ngcache);
    // console.error(instance);
    /// / @ts-ignore
    // this.ngf = new NgForage({}, instance);
    // console.error("fact", (this.ngf as any).fact);

  }

  public cacheLevelToLifeSpanMilliseconds(cacheLevel: CacheLevel): number {
    switch (cacheLevel) {
      case CacheLevel.None:
        return (10 * 1000); // 10 seconds
      case CacheLevel.Volatile:
        return (5 * 60 * 1000); // 5 minutes
      case CacheLevel.ChangesOften:
        return (15 * 60 * 1000); // 15 minutes
      case CacheLevel.ChangesInfrequently:
        return (2 * 60 * 60 * 1000); // 2 hours
      case CacheLevel.PseudoStatic:
        return (6 * 60 * 60 * 1000); // 6 hours
      case CacheLevel.Static:
        // return Number.MAX_VALUE;
        return (30 * 24 * 60 * 60 * 1000); // 30 days
      default:
        Log.errorMessage(`Unsupported cache level: ${cacheLevel}`);
        return (10 * 1000); // 10 seconds
    }
  }


  public cachePutValue<T>(cacheName: string, key: string, value: T, cacheLevel: CacheLevel): boolean {

    if (cacheLevel === CacheLevel.None) {
      return true;
    }

    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);

    if (!lookupKey) {
      Log.errorMessage("CacheName and Key missing. Unable to add to cache.");
      return false;
    }

    try {

      const cache: LocalCache = {
        data: value,
        asOf: Date.now(),
        ttl: this.cacheLevelToLifeSpanMilliseconds(cacheLevel)
      };

      return this.set(lookupKey, cache);

    } catch (err) {
      Log.errorMessage(err);
      return false;
    }
  }


  public cacheGetKeys(): string[] {
    try {

      const keys: string[] = Array.from(this.localCache.keys());

      if (Helper.isArray(keys)) {
        return keys;
      } else {
        return [];
      }
    } catch (err) {
      Log.errorMessage('Error retrieving cache keys: ', err);
      return [];
    }
  }


  public cacheGetLength(): number {
    return this.cacheGetKeys().length;
  }


  public cacheKeyExists(cacheName: string, key: string): boolean {
    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);
    return this.localCache.has(lookupKey);
  }


  /**
   * This returns the value associated with the "data" property of the Cache. The 'Cache' interface
   * is the "Value" portion of the localCache Map <string, Cache> but this only returns the data property, not the
   * whole Cache object.
   */
  public cacheGetValue<T>(cacheName: string, key: string, defaultValue: T = null): T {

    if (!this.cacheKeyExists(cacheName, key)) {
      return defaultValue;
    }

    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);

    try {
      const cache: LocalCache = this.get(lookupKey);

      // If cache was expired, get() deletes it and returns undefined. Exit here so we don't attempt to
      // pull 'data' from undefined.
      if (Helper.isUndefinedOrNull(cache)) {
        return defaultValue;
      }

      const value = cache.data;

      if (value) {
        return value;
      } else {
        return defaultValue;
      }

    } catch (err) {
      Log.errorMessage(err);
      return defaultValue;
    }
  }


  /**
   * Removes all cached items that start with the passed in cache name.
   * @param cacheName
   */
  public cacheRemoveAllByCacheName(cacheName: string) {

    const cacheKeyArr = this.cacheGetKeys();
    const length = cacheKeyArr.length;

    if (length === 0) {
      return;
    }

    for (let i = 0; i < length; i++) {
      if (Helper.startsWith(cacheKeyArr[i], cacheName, true)) {

        const nameAndKey = cacheKeyArr[i].split("~|~");
        const key = nameAndKey[1];

        if (this.localCache.has(`${cacheName}~|~${key}`)) {
          this.localCache.delete(`${cacheName}~|~${key}`);
        }
      }
    }
  }


  /**
   * Removes item from the cache with the asociated cacheName + key
   * @param cacheName
   * @param key
   * @param startsWith deprecated = leaving this here and will monitor/cleanup as needed
   * @returns
   */
  public cacheRemoveValue(cacheName: string, key: string, startsWith: boolean = false, contains: boolean = false): void {

    if (this.localCache.size === 0) {
      return;
    }

    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);

    if (contains) {
      this.localCache.forEach((cacheValue: LocalCache, cacheKey: string) => {
        if (Helper.contains(cacheKey, lookupKey, true)) {
          this.localCache.delete(cacheKey);
        }
      });
    } else if (startsWith) {
      this.localCache.forEach((cacheValue: LocalCache, cacheKey: string) => {
        if (Helper.startsWith(cacheKey, lookupKey, true)) {
          this.localCache.delete(cacheKey);
        }
      });
    } else {
      if (this.localCache.has(lookupKey)) {
        this.localCache.delete(lookupKey);
      }
    }

    return;
  }


  public cacheRemoveValueBasedOnApiCall(api: ApiCall, inputData: any, currentUserPartitionId: number) {

    let cacheKey: string = "";
    if (api.type === ApiOperationType.List) {
      cacheKey = ApiHelper.buildApiAbsoluteUrl(api, inputData);
      if (currentUserPartitionId) {
        cacheKey = `P${currentUserPartitionId}-${cacheKey}`;
      }
    } else if (api.type === ApiOperationType.Get) {
      cacheKey = ApiHelper.buildCacheKey(api, inputData, currentUserPartitionId);
    } else if (api.type === ApiOperationType.Add ||
      api.type === ApiOperationType.Edit ||
      api.type === ApiOperationType.Patch ||
      api.type === ApiOperationType.Merge ||
      api.type === ApiOperationType.Copy ||
      api.type === ApiOperationType.Delete) {
      cacheKey = ApiHelper.buildCacheKey(api, inputData, currentUserPartitionId);
    }

    if (!cacheKey) {
      return;
    }

    if (api.cacheUseStorage) {
      this.storedCacheRemoveValue(api.cacheName, cacheKey);
    } else {
      this.cacheRemoveValue(api.cacheName, cacheKey);
    }

  }


  /**
   * Removes all items from cache
   */
  public cacheClear(): void {
    this.localCache.clear();
  }


  // public getItem<T = any>(key: string): Promise<T> {
  //  return this.ngf.getItem<T>(key);
  // }

  public storedCachePutValue<T>(cacheName: string, key: string, value: T, cacheLevel: CacheLevel): Promise<T> {
    if (cacheLevel === CacheLevel.None) {
      return new Promise<T>((resolve, reject) => {
        resolve(value);
      });
    }
    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);
    try {
      const lifeSpan: number = this.cacheLevelToLifeSpanMilliseconds(cacheLevel);
      // console.error(`ready to cache for ${lifeSpan} ms: ${cacheName}~|~${key}`, value);
      return this.ngcache.setCached(lookupKey, value, lifeSpan);
    } catch (err) {
      Log.errorMessage(err);
      return new Promise<T>((resolve, reject) => {
        resolve(value);
      });
    }
  }


  public storedCacheGetValue<T = any>(cacheName: string, key: string): Promise<T | null> {
    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);
    return this.ngcache.getCached<T>(lookupKey)
      .then((r: CachedItem<T>) => {
        if (!r.hasData || r.expired) {
          return null;
        }
        return r.data;
      });
  }

  public storedCacheRemoveValue(cacheName: string, key: string, startsWith: boolean = false, contains: boolean = false): void {
    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);
    if (contains) {
      this.ngcache.keys().then(keys => {
        keys.forEach(cachedKey => {
          if (Helper.contains(cachedKey, lookupKey, true)) {
            //console.error(`removing cache key ${cachedKey} because it contains ${lookupKey}.`);
            this.ngcache.removeCached(cachedKey);
          }
        });
      });
    } else if (startsWith) {
      this.ngcache.keys().then(keys => {
        keys.forEach(cachedKey => {
          if (Helper.startsWith(cachedKey, lookupKey, true)) {
            this.ngcache.removeCached(cachedKey);
          }
        });
      });
    } else {
      this.ngcache.removeCached(lookupKey);
    }
    return;
  }

  /**
   * Checks if the stored cache has a specific key.
   * @param cacheName - The name of the cache.
   * @param key - The key to check.
   * @param startsWith - Optional. Specifies whether the key should start with the given value. Default is false.
   * @param contains - Optional. Specifies whether the key should contain the given value. Default is false.
   * @returns A promise that resolves to a boolean indicating whether the cache has the specified key.
   */
  public storedCacheHasKey(cacheName: string, key: string, startsWith: boolean = false, contains: boolean = false): Promise<boolean> {
    const lookupKey: string = this.buildCacheKeyLookupValue(cacheName, key);
    return this.ngcache.keys().then(keys => {
      return keys.some(x => Helper.equals(x, lookupKey, true));
    });
  }


  public storedCacheGetLength(): Promise<number> {
    return this.ngcache.length();
  }

  public storedCacheGetKeys(): Promise<string[]> {
    return this.ngcache.keys();
  }

  public storedCacheClear(): Promise<void> {
    return this.ngcache.clear();
  }


  public buildCacheKeyLookupValue(cacheName: string, key: string): string {
    if (cacheName && key) {
      return `${cacheName}~|~${key}`;
    } else if (!cacheName && key) {
      return key;
    } else if (cacheName && !key) {
      return cacheName;
    }
    return "";
  }


  /**
   * When the app loads it notes the version it is running via the
   * AppStatusService and when that version is first noted this method
   * is called so the cache engine can decide if certain cache
   * elements like long running pick lists need to be dumped.
   * @param version The app version currently running.
   */
  public versionCheck(version: string) {
    // Get the last known app version
    const lastVersion = Helper.localStorageGet("AppVersion", "");
    // If we don't have a last known app version or it doesn't match our current version
    // then we dump the stored cache since we stick some pretty static things there like
    // widgets, pick lists, etc. and a version change may mean those need to be refreshed.
    if (!lastVersion || lastVersion !== version) {
      Log.debug("cache", "Cache Dump", `New version "${version}" detected so dumping stored cache.  Last version was "${lastVersion}".`);
      this.storedCacheClear();
      Helper.localStorageSave("AppVersion", version);
    }
  }


  /**
   * Returns the cache for the passed in key. Any time a cache is retrieved, it is deleted from the Map
   * and re-inserted at the back. If it is expired, it is deleted and we return undefined.
   * Idea from https://medium.com/sparkles-blog/a-simple-lru-cache-in-typescript-cba0d9807c40
   * @param key `${cacheName}~|~${key}`
   * @returns cache
   */
  protected get(key: string): LocalCache {
    const hasKey = this.localCache.has(key);

    let cache: LocalCache;

    if (hasKey) {

      // peek the entry
      cache = this.localCache.get(key);
      const isExpired = this.isExpired(cache);

      if (isExpired && this.localCache.size > 0) {
        this.localCache.delete(key);
        return undefined;
      }

      // delete and reinsert
      this.localCache.delete(key);
      this.localCache.set(key, cache);
    }

    return cache;
  }


  /**
   * Adds the passed in key and value to the localCache. If the cache is full, it removes the
   * least-recently used item in the cache.
   * Idea from https://medium.com/sparkles-blog/a-simple-lru-cache-in-typescript-cba0d9807c40
   * @param key `${cacheName}~|~${key}`
   * @param value
   * @returns true if the key and value pair were added to the cache
   */
  protected set(key: string, value: LocalCache): boolean {

    if (this.localCache.size >= this.maxEntries) {
      const keyToDelete = this.localCache.keys().next().value;
      this.localCache.delete(keyToDelete);
    }

    try {
      this.localCache.set(key, value);

      // console.log('cache-- LocalCache: ', this.localCache);

      return true;
    } catch (error) {
      console.error("Failed to set value in cache: ", error);
      return false;
    }
  }


  /**
   * True if the passed in cache is expired
   * @param cache
   * @returns
   */
  protected isExpired(cache: LocalCache): boolean {
    const now: number = Date.now();
    const expiredTime: number = cache.asOf + cache.ttl;

    // If we haven't reached 'now' yet, then it's not expired
    return expiredTime < now;
  }


  /**
   * Clears all items from the cache.
   */
  protected clear(): void {

    if (this.localCache.size === 0) {
      // this.empty.emit()
      return;
    }

    this.localCache.clear();
  }

}

export interface LocalCache {

  /** The actual data or 'value' being stored with the associated Key */
  data: any;

  /** Time local cache value was created represented in milliseconds */
  asOf: number;

  /** Time To Live for local cache represented in milliseconds */
  ttl: number;
}

