// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  list-style: none;
  display: inline-block !important;
  transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -webkit-transform: rotate(5deg);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.nav.cdk-drop-list-dragging .nav:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-placeholder {
  background: #e0e0e0; /*#ccc;*/
  border: dashed 1px black; /*#999;*/
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/*
https://stackoverflow.com/questions/21212605/how-can-i-easily-duplicate-the-trello-style-of-drag-and-drop-of-cards-kanban-s

.tilt.right {
  transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
}

.tilt.left {
  transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
}
*/
`, "",{"version":3,"sources":["webpack://./projects/common-lib/src/lib/ux/nav/data-nav/data-nav.component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,qHAAqH;EACrH,gBAAgB;EAChB,gCAAgC;EAChC,uBAAuB;EACvB,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,mBAAmB,EAAE,QAAQ;EAC7B,wBAAwB,EAAE,QAAQ;EAClC,gBAAgB;EAChB,sDAAsD;AACxD;;AAEA;;;;;;;;;;;;;;CAcC","sourcesContent":[".cdk-drag-preview {\r\n  box-sizing: border-box;\r\n  border-radius: 4px;\r\n  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);\r\n  list-style: none;\r\n  display: inline-block !important;\r\n  transform: rotate(5deg);\r\n  -moz-transform: rotate(5deg);\r\n  -webkit-transform: rotate(5deg);\r\n}\r\n\r\n.cdk-drag-animating {\r\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\r\n}\r\n\r\n.nav.cdk-drop-list-dragging .nav:not(.cdk-drag-placeholder) {\r\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\r\n}\r\n\r\n.drop-placeholder {\r\n  background: #e0e0e0; /*#ccc;*/\r\n  border: dashed 1px black; /*#999;*/\r\n  min-height: 60px;\r\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\r\n}\r\n\r\n/*\r\nhttps://stackoverflow.com/questions/21212605/how-can-i-easily-duplicate-the-trello-style-of-drag-and-drop-of-cards-kanban-s\r\n\r\n.tilt.right {\r\n  transform: rotate(3deg);\r\n  -moz-transform: rotate(3deg);\r\n  -webkit-transform: rotate(3deg);\r\n}\r\n\r\n.tilt.left {\r\n  transform: rotate(-3deg);\r\n  -moz-transform: rotate(-3deg);\r\n  -webkit-transform: rotate(-3deg);\r\n}\r\n*/\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
